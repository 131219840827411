import React from 'react';
import { Banner, BlockStack, Button, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PlanThresholdDetailsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { PlanLevelDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';

type DowngradePlanBannerProps = {
  currentPlan?: PlanThresholdDetailsDto;
  suggestedPlan?: PlanThresholdDetailsDto;
  togglePlanModal: (level?: PlanLevelDtoEnum) => void;
};
export const ReachedLimitBanner: React.FC<DowngradePlanBannerProps> = ({
  currentPlan,
  suggestedPlan,
  togglePlanModal,
}) => {
  const [i18n] = useI18n();
  return (
    <Banner
      tone='warning'
      title={i18n.translate('BannerTitle', {
        limit: currentPlan?.maxPublishedPromotions as number,
      })}
    >
      <BlockStack gap='200' inlineAlign='start'>
        <Text as='p'>
          {i18n.translate('BannerDescription', {
            currentLimit: currentPlan?.maxPublishedPromotions,
            currentPlan: currentPlan?.name,
            suggestedPlan: suggestedPlan?.name,
            suggestedLimit: suggestedPlan?.maxPublishedPromotions,
          })}
        </Text>
        <Button
          onClick={() =>
            togglePlanModal(suggestedPlan?.level as PlanLevelDtoEnum)
          }
        >
          {i18n.translate('UpgradeTo', {
            plan: suggestedPlan?.name || '',
          })}
        </Button>
      </BlockStack>
    </Banner>
  );
};
