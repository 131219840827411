import React, { useCallback, useEffect, useState } from 'react';
import { Link } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { UploadFileModal } from 'core/components';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';
import { debounce } from 'lodash';
import { usePromotionListImportTrack } from './hooks/usePromotionListImportTrack';
import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
type ImportPromotionsModalProps = {
  isOpen: boolean;
  isImporting: boolean;
  handleRefreshList: () => void;
  setIsImporting: (value: boolean) => void;
  onClose: () => void;
};
export const ImportPromotionsModal: React.FC<ImportPromotionsModalProps> = ({
  isOpen,
  isImporting,
  handleRefreshList,
  setIsImporting,
  onClose,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const { importPromotionList, importPromotionListData } =
    useConfigurePromotions();
  const {
    promotionListImportTrackData,
    promotionListImportTrackError,
    refetchPromotionListImportTrack,
  } = usePromotionListImportTrack(importPromotionListData?.trackingId);
  const [trackCount, setTrackCount] = useState<number>(0);

  const handleImportPromotionCodes = useCallback(
    (value: File[], overwrite?: boolean) => {
      const file = value[0];
      const blob = new Blob([file], { type: file.type });
      const formData = new FormData();
      formData.append('file', blob, file.name);
      importPromotionList(formData, overwrite, setIsImporting);
    },
    []
  );

  const handleDebaunceStatuses = useCallback(
    debounce(() => {
      refetchPromotionListImportTrack().then(() => {
        setTrackCount((prev) => (prev !== -1 ? prev + 1 : prev));
      });
    }, 3000),
    [setTrackCount, isImporting]
  );

  const handleFinishImporting = useCallback((canceled: boolean) => {
    onClose();
    setIsImporting(false);
    if (!canceled) {
      handleRefreshList();
      dispatch(
        setToastMessages({
          error: false,
          message: 'PromotionsImported',
        })
      );
    }
  }, []);

  const onCancelImporting = useCallback(() => {
    setTrackCount(-1);
    onClose();
  }, []);

  useEffect(() => {
    if (promotionListImportTrackData) {
      if (promotionListImportTrackData.isFinished || trackCount === -1) {
        handleFinishImporting(trackCount === -1);
      } else {
        handleDebaunceStatuses();
      }
    }
  }, [promotionListImportTrackData, trackCount]);

  useEffect(() => {
    promotionListImportTrackError && setIsImporting(false);
  }, [promotionListImportTrackError]);

  return isOpen ? (
    <UploadFileModal
      isOpen={isOpen}
      fileFormat='.dnpromotions'
      title={i18n.translate('ImportTitle')}
      subtitle={i18n.translate('ImportSubtitle', {
        learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
      })}
      uploadIsLoading={isImporting}
      loadingState={isImporting ? i18n.translate('LoadingTitle') : undefined}
      checkboxLabel={i18n.translate('ImportCheckbox')}
      onCancel={onCancelImporting}
      onClose={onClose}
      onUploadFiles={handleImportPromotionCodes}
    />
  ) : null;
};
