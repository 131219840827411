import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Badge,
  Banner,
  Bleed,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Icon,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { DiscountIcon, CheckIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { GetCurrentPlanResponseDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  PlanIntervalDtoEnum,
  PlanThresholdTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { formatTimeDistance } from 'core/utils';
import { format } from 'date-fns';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useConfigureSettings } from 'features/settings/hooks/useConfigureSettings';
import { ChangePlanConfigDto } from '../../Plan';
import moment from 'moment';
import { ThresholdUsage } from './components/ThresholdUsage/ThresholdUsage';
import { SettingsModalSubPathsEnums } from 'pages/enums/PagesEnums';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
import { ChartuUnitOfMeasurementEnum } from 'pages';

type UserSubscriptionPlanProps = {
  currentPlanData?: GetCurrentPlanResponseDto | null;
  isMaxModal: boolean;
  setChangePlanConfig: (data: ChangePlanConfigDto) => void;
  refetchCurrentPlanData: () => void;
};
export const UserSubscriptionPlan: React.FC<UserSubscriptionPlanProps> = ({
  currentPlanData,
  isMaxModal,
  setChangePlanConfig,
  refetchCurrentPlanData,
}) => {
  const [i18n] = useI18n();
  const navigate = useNavigate();
  const { formatValue } = useNumericFormatter();
  const {
    resumeSubscription,
    resumeSubscriptionIsLoading,
    selectSubscription,
    selectSubscriptionData,
    selectSubscriptionIsLoading,
  } = useConfigureSettings();
  const [, setSearchParams] = useSearchParams();

  const { billing, plan } = currentPlanData as GetCurrentPlanResponseDto;

  const getOrdinalSuffix = useCallback((day: number): string => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }, []);

  const nextBillingCycleDate = useMemo(() => {
    const date = new Date(billing?.endDate || 0);
    const oneDayInMillis = 24 * 60 * 60 * 1000;
    date.setTime(date.getTime() + oneDayInMillis);
    return date.getTime();
  }, [billing?.endDate]);

  const isDiscountApplied = useMemo(() => {
    if (!plan?.price?.discount) return null;
    return !!plan?.price?.discount.isDiscountApplied;
  }, [plan?.price?.discount]);

  const formatTrialDate = useCallback((timestamp: number): string => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;
    const formattedDate = format(date, `MMMM yyyy 'at' HH:mm`);

    return `${dayWithSuffix} ${formattedDate}`;
  }, []);

  const navigateThrough = useCallback(
    (subPath: string) => {
      setSearchParams((params) => {
        params.set('subPath', subPath);
        return params;
      });
    },
    [setSearchParams]
  );

  const selectCurrentSubscription = useCallback(
    () =>
      selectSubscription({
        interval: plan?.interval,
        level: plan?.level,
        thresholdType: plan?.thresholdType,
      }),
    [plan]
  );

  const handleResumeSubscription = useCallback(
    () => resumeSubscription(refetchCurrentPlanData),
    [resumeSubscription]
  );

  const sendMessageToMainApp = useCallback((message: any) => {
    window.opener?.postMessage(message, location.origin);
  }, []);

  useEffect(() => {
    if (selectSubscriptionData?.redirectUri) {
      isMaxModal
        ? sendMessageToMainApp({
            type: 'NAVIGATE_TO_PLAN_PAYMENT',
            redirectUri: selectSubscriptionData?.redirectUri,
          })
        : navigate(
            '/shopifyRedirect?redirectUri=' +
              selectSubscriptionData?.redirectUri
          );
    }
  }, [selectSubscriptionData]);

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='center'>
          <BlockStack gap='100'>
            <Text as='h2' fontWeight='semibold'>
              {i18n.translate('PlanDetail')}
            </Text>
            <Text tone='subdued' as='p'>
              {i18n.translate('PlanDetailSubtitle')}
            </Text>
          </BlockStack>
          {plan && (
            <Button
              onClick={() =>
                navigateThrough(SettingsModalSubPathsEnums.PickYourPlan)
              }
            >
              {i18n.translate('ChangePlan')}
            </Button>
          )}
        </InlineStack>
        <Box
          borderColor='border'
          borderWidth='025'
          borderRadius='200'
          padding='400'
        >
          {!plan?.name ? (
            <BlockStack inlineAlign='start' gap='400'>
              <Text as='h1' variant='headingLg' fontWeight='semibold'>
                {i18n.translate('NoPlanSelected')}
              </Text>
              <Banner tone='warning'>{i18n.translate('NoPlanWarning')}</Banner>
              <ButtonGroup>
                {currentPlanData?.plan?.previousPlan && (
                  <Button
                    loading={selectSubscriptionIsLoading}
                    onClick={selectCurrentSubscription}
                    variant='primary'
                  >
                    {i18n.translate('ReactivatePrevPlan', {
                      prevPlan: currentPlanData?.plan?.previousPlan
                        ?.name as string,
                    })}
                  </Button>
                )}
                <Button
                  onClick={() =>
                    navigateThrough(SettingsModalSubPathsEnums.PickYourPlan)
                  }
                  variant={
                    currentPlanData?.plan?.previousPlan
                      ? 'secondary'
                      : 'primary'
                  }
                >
                  {i18n.translate('ExplorePlans')}
                </Button>
              </ButtonGroup>
            </BlockStack>
          ) : (
            <BlockStack gap='400'>
              <BlockStack inlineAlign='start' gap='200'>
                <Box width='100%'>
                  <InlineStack align='space-between' blockAlign='end'>
                    <InlineStack gap='200'>
                      <Text as='h1' variant='headingLg' fontWeight='semibold'>
                        {plan.name}
                      </Text>
                      {plan.cancellationDate && (
                        <Badge tone='warning'>
                          {i18n.translate('Cancelled')}
                        </Badge>
                      )}
                      {plan.trialEndDate && (
                        <Text as='span' tone='subdued'>
                          {i18n.translate('FreeTrial')}
                        </Text>
                      )}
                    </InlineStack>
                    <InlineStack gap='100'>
                      {isDiscountApplied && (
                        <Text
                          textDecorationLine='line-through'
                          as='span'
                          tone='subdued'
                        >
                          ${plan.price?.regularPrice}
                        </Text>
                      )}

                      <Text as='h1' variant='headingLg' fontWeight='semibold'>
                        $
                        {isDiscountApplied
                          ? plan.price?.discount?.discountedPrice
                          : plan.price?.regularPrice}
                      </Text>
                      <Text as='span' tone='subdued'>
                        {i18n.translate('USDInterval', {
                          interval: i18n.translate(
                            plan.interval === PlanIntervalDtoEnum.ANNUAL
                              ? 'Year'
                              : 'Month'
                          ),
                        })}
                      </Text>
                    </InlineStack>
                  </InlineStack>
                </Box>
                {isDiscountApplied && (
                  <InlineStack blockAlign='center'>
                    <Icon source={CheckIcon} tone='success' />
                    <Text tone='success' as='p'>
                      {i18n.translate('DiscountFor', {
                        name: (
                          <Text as='span' fontWeight='medium'>
                            {plan.price?.discount?.name}
                          </Text>
                        ),
                        percentage: formatValue(
                          plan.price?.discount?.discountPercentage,
                          ChartuUnitOfMeasurementEnum.PERCENTAGE
                        ),
                      })}
                    </Text>
                  </InlineStack>
                )}
              </BlockStack>
              {plan.cancellationDate && (
                <Banner tone='warning'>
                  <BlockStack gap='200' inlineAlign='start'>
                    <Text as='p'>
                      {i18n.translate('SubscriptionIsCancelled', {
                        endsIn: (
                          <Text fontWeight='semibold' as='span'>
                            {i18n.translate('DeactivatedIn', {
                              date: formatTimeDistance(plan.cancellationDate),
                            })}
                          </Text>
                        ),
                        endDate: format(
                          plan.cancellationDate,
                          "'on' MMMM d, yyyy, 'at' h:mm a"
                        ),
                      })}
                    </Text>
                    <Button
                      onClick={handleResumeSubscription}
                      loading={resumeSubscriptionIsLoading}
                      variant='primary'
                    >
                      {i18n.translate('ResumeSubscription')}
                    </Button>
                  </BlockStack>
                </Banner>
              )}
              {!plan.trialEndDate && isDiscountApplied === false && (
                <Banner
                  icon={DiscountIcon}
                  action={{
                    content: i18n.translate('ApplyDiscountNow'),
                    onAction: selectCurrentSubscription,
                    loading: selectSubscriptionIsLoading,
                  }}
                  tone='success'
                >
                  <Text as='p'>
                    {i18n.translate('DiscountEligible', {
                      name: plan.price?.discount?.name,
                      regular: formatValue(
                        plan.price?.regularPrice,
                        ChartuUnitOfMeasurementEnum.CURRENCY
                      ),
                      nowJust: (
                        <Text fontWeight='semibold' as='span'>
                          {i18n.translate('NowJust', {
                            price: formatValue(
                              plan.price?.discount?.discountedPrice,
                              ChartuUnitOfMeasurementEnum.CURRENCY
                            ),
                          })}
                        </Text>
                      ),
                    })}
                  </Text>
                  <Text as='p'>
                    {i18n.translate('ApplyYourDiscountNow', {
                      percentage: formatValue(
                        plan.price?.discount?.discountPercentage,
                        ChartuUnitOfMeasurementEnum.PERCENTAGE
                      ),
                    })}
                  </Text>
                </Banner>
              )}
              {plan.trialEndDate && (
                <Banner>
                  <Text as='p'>
                    {i18n.translate('FreeTrialEndIn', {
                      endsIn: (
                        <Text fontWeight='bold' as='span'>
                          {i18n.translate('EndsIn', {
                            date: formatTimeDistance(plan.trialEndDate),
                          })}
                        </Text>
                      ),
                      endDate: formatTrialDate(plan.trialEndDate as number),
                    })}
                  </Text>
                  <Text as='p'>
                    {i18n.translate('BilledInfo', {
                      interval: i18n.translate(
                        plan.interval === PlanIntervalDtoEnum.ANNUAL
                          ? 'Year'
                          : 'Month'
                      ),
                      price: formatValue(
                        plan.price?.regularPrice,
                        ChartuUnitOfMeasurementEnum.CURRENCY
                      ),
                    })}
                  </Text>
                </Banner>
              )}
              {plan.downgradedPlan && (
                <Banner tone='warning'>
                  {i18n.translate('DowngradingInfo', {
                    downgraded: (
                      <Text fontWeight='medium' as='span'>
                        {i18n.translate('DowngradedPlan', {
                          plan: plan.downgradedPlan.name,
                        })}
                      </Text>
                    ),
                    date: moment(nextBillingCycleDate).format(
                      'MMMM D, YYYY [at] h:mm A'
                    ),
                    nextCycleDate: (
                      <Text fontWeight='medium' as='span'>
                        {i18n.translate('NextBillingCycle', {
                          date: formatTimeDistance(nextBillingCycleDate),
                        })}
                      </Text>
                    ),
                    publishedPromotions:
                      plan.downgradedPlan.publishedPromotionsLimit,
                    unpublished: (
                      <Text fontWeight='medium' as='span'>
                        {i18n.translate('Unpublished')}
                      </Text>
                    ),
                  })}
                </Banner>
              )}
            </BlockStack>
          )}
        </Box>
        {plan?.name && (
          <Box borderColor='border' borderWidth='025' borderRadius='200'>
            <ThresholdUsage
              currentPlanData={currentPlanData}
              setChangePlanConfig={setChangePlanConfig}
            />
          </Box>
        )}
        {plan?.thresholdType === PlanThresholdTypeDtoEnum.SESSION && (
          <Banner>
            <BlockStack inlineAlign='start' gap='200'>
              <Text as='p'>
                {i18n.translate('OrderBasedAdvice', {
                  learnMore: <Link>{i18n.translate('LearnMore')}</Link>,
                })}
              </Text>
              <Button>{i18n.translate('SwitchOrderBased')}</Button>
            </BlockStack>
          </Banner>
        )}

        {!plan?.name && (
          <Bleed marginBlockEnd='400' marginInline='400'>
            <Box background='bg-surface-secondary' padding='400'>
              <Text as='p' tone='subdued'>
                {i18n.translate('NeedAssistace', {
                  requestSupport: (
                    <Link>{i18n.translate('RequestSupport')}</Link>
                  ),
                })}
              </Text>
            </Box>
          </Bleed>
        )}
      </BlockStack>
    </Card>
  );
};
