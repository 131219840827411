import React, { useCallback, useEffect, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoTriggerReferrerFilterDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { useReferrers } from 'core/hooks/useReferrers';
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  ChoiceList,
  Collapsible,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { FilterTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';
import { cloneDeep } from 'lodash';

type SettingReferrerFilterProps = {
  referrerFilter?: OptionDtoTriggerReferrerFilterDto;
  showValidation: boolean;
  updateSettingsState: (data: OptionDtoTriggerReferrerFilterDto) => void;
  onFormValidityChange(formIsValid: boolean): void;
};

export const SettingReferrerFilter: React.FC<SettingReferrerFilterProps> = ({
  referrerFilter,
  showValidation,
  updateSettingsState,
  onFormValidityChange,
}) => {
  const shouldSkip = useMemo(
    () => !referrerFilter?.enabled,
    [referrerFilter?.enabled]
  );

  const [i18n] = useI18n();

  const { referrerList: availableReferrers } = useReferrers(shouldSkip);

  const referrersList = useMemo(
    () => availableReferrers?.map((referrer) => referrer.name),
    [availableReferrers]
  );

  const isError = useMemo(
    () =>
      referrerFilter?.enabled &&
      (!referrerFilter.value?.referrers?.length ||
        !referrerFilter.value.domainNames?.length),
    [referrerFilter]
  );

  const filterOptions = useMemo(
    () =>
      Object.values(FilterTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );

  useEffect(() => {
    onFormValidityChange(!isError);
  }, [isError]);

  const handleTagSelection = useCallback(
    (fieldName: string, tags: string[]) => {
      const cloneReferrerFilter = cloneDeep(referrerFilter);
      updateSettingsState({
        ...cloneReferrerFilter,
        value: {
          ...cloneReferrerFilter?.value,
          [fieldName]: tags,
        },
      });
    },
    [referrerFilter, updateSettingsState]
  );

  return (
    <div className='SettingReferrerFilter'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack blockAlign='start' wrap={false} align='space-between'>
            <Box width='70%'>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text fontWeight='semibold' as='h2'>
                    {i18n.translate('ReferrerFilter')}
                  </Text>
                  <Badge tone={referrerFilter?.enabled ? 'success' : 'enabled'}>
                    {i18n.translate(referrerFilter?.enabled ? 'On' : 'Off')}
                  </Badge>
                </InlineStack>
                <Text as='p' tone='subdued'>
                  {i18n.translate('ReferrerFilterSubtitle')}
                </Text>
              </BlockStack>
            </Box>
            <Button
              onClick={() =>
                updateSettingsState({
                  ...referrerFilter,
                  enabled: !referrerFilter?.enabled,
                })
              }
            >
              {i18n.translate(referrerFilter?.enabled ? 'TurnOff' : 'TurnOn')}
            </Button>
          </InlineStack>
          <Collapsible id='collapsible' open={!!referrerFilter?.enabled}>
            <BlockStack gap='400'>
              <ChoiceList
                title=''
                selected={[referrerFilter?.value?.type as string]}
                choices={filterOptions}
                onChange={(value: FilterTypeDtoEnum[]) => {
                  updateSettingsState({
                    ...referrerFilter,
                    value: {
                      ...referrerFilter?.value,
                      type: value[0],
                    },
                  });
                }}
              />
              <MultiselectTagCombobox
                id='SelectReferrers'
                tagsInside
                label={i18n.translate('SelectReferrers')}
                suggestions={referrersList as string[]}
                placeholder={i18n.translate('SelectReferrersPlaceHolder')}
                requiredIndicator={showValidation}
                labelHidden={false}
                selectedTags={referrerFilter?.value?.referrers || []}
                withAddFunc={false}
                setSelectedTags={(tags: string[]) =>
                  handleTagSelection('referrers', tags)
                }
              />
              <MultiselectTagCombobox
                id='SelectDomains'
                tagsInside
                label={i18n.translate('SelectDomains')}
                suggestions={referrersList as string[]}
                placeholder={i18n.translate('SelectDomainsPlaceHolder')}
                requiredIndicator={showValidation}
                labelHidden={false}
                selectedTags={referrerFilter?.value?.domainNames || []}
                setSelectedTags={(tags: string[]) =>
                  handleTagSelection('domainNames', tags)
                }
                helpText={i18n.translate('SelectDomainsHelp')}
              />
              <Bleed marginBlockEnd='400' marginInline='400'>
                <Box background='bg-surface-secondary' padding='400'>
                  <Text as='p' tone='subdued'>
                    {i18n.translate('FilterIsNotApplied', {
                      readArticle: <Link>{i18n.translate('readArticle')}</Link>,
                    })}
                  </Text>
                </Box>
              </Bleed>
            </BlockStack>
          </Collapsible>
        </BlockStack>
      </Card>
    </div>
  );
};
