export const getVideoThumbnail = (
  videoUrl: string,
  quality: 'default' | 'hq' = 'default'
): string => {
  let videoId: string | undefined;

  // Handle YouTube URL
  if (videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')) {
    videoId = videoUrl.includes('v=')
      ? videoUrl.split('v=')[1]?.split('&')[0]
      : videoUrl.split('.be/')[1];

    if (!videoId) {
      throw new Error('Invalid YouTube URL');
    }

    const qualityPath = quality === 'hq' ? 'hqdefault.jpg' : '0.jpg';
    return `https://img.youtube.com/vi/${videoId}/${qualityPath}`;
  }

  // Handle Loom URL
  else if (videoUrl.includes('loom.com')) {
    videoId = videoUrl.split('/share/')[1];

    if (!videoId) {
      throw new Error('Invalid Loom URL');
    }

    return `https://cdn.loom.com/sessions/thumbnails/${videoId}-00001.jpg`;
  }

  throw new Error('Unsupported video platform');
};
