import { useI18n } from '@shopify/react-i18n';
import { formatNumber, formatPercentage } from 'core/utils';
import { ChartuUnitOfMeasurementEnum } from 'pages';

/**
 * Custom hook to format values based on the unit of measurement.
 * @returns A function to format a given amount based on the type.
 */
export const useNumericFormatter = () => {
  const [i18n] = useI18n();

  const formatValue = (
    amount: number | null | undefined,
    type: ChartuUnitOfMeasurementEnum
  ): string => {
    if (!amount) {
      // Handle null or undefined amounts
      return '0';
    }
    switch (type) {
      case ChartuUnitOfMeasurementEnum.PERCENTAGE:
        return formatPercentage(i18n, amount);
      case ChartuUnitOfMeasurementEnum.CURRENCY:
        return i18n.formatCurrency(amount);
      case ChartuUnitOfMeasurementEnum.NUMBER:
        return formatNumber(i18n, amount);
      default:
        return amount.toString(); // fallback in case of an unknown type
    }
  };

  return { formatValue };
};
