import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { Banner, Button, Icon, IconSource, Thumbnail } from '@shopify/polaris';
import {
  SearchIcon,
  XSmallIcon,
  EditIcon,
  AlertCircleIcon,
  HideIcon,
  ViewIcon,
  DragHandleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@shopify/polaris-icons';
import { ResourcePickerAdapter } from 'core/components/ResourcePickerAdapter';
import classNames from 'classnames';
import './SearchFieldWithGrayBox.scss';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import {
  ShopifyObjectDto,
  ShopifyObjectTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { ShopifyObjectTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { MergedSelectedGrayBoxElementsProps } from 'features/promotions/components/SelectedOfferTypeSettings/components/RulesSection/components/RuleComponent/RuleComponent';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import {
  Product,
  ProductVariant,
  Resource,
  ResourcePickerOptions,
  ResourcePickerResourceType,
  ResourceSelection,
} from 'core/api/appBridge';
import { useIsDebugOrLocal } from 'core/hooks';
import { ResourcePickerCustom } from '../ResourcePickerAdapter/components/ResourcePickerCustom';

export type ResourceSelectionProps = ResourceSelection<
  ResourcePickerOptions['type']
> & {
  featuredImageUrl: string;
  subtitle?: string;
};

export enum GrayBoxResourceTypeEnum {
  Product = 'Product',
  Collection = 'Collection',
}

export type GrayBoxResourceType = 'Product' | 'Collection';

type SelectSectionProps = {
  selection: ResourceSelectionProps[];
};

type SearchFieldWithGrayBoxProps = {
  selectList: ResourceSelectionProps[];
  mergedSelectedGrayBoxElements?: MergedSelectedGrayBoxElementsProps[];
  resourceType: GrayBoxResourceType;
  showVariants?: boolean;
  isFreeGiftSearchField?: boolean;
  isDiscountLinkSearchField?: boolean;
  onSelectedChange: (list: ResourceSelectionProps[]) => void;
  onFormValidityChange?(isFormInvalid: boolean | null): void;
  getOnMount?: (list: ResourceSelectionProps[]) => void;
  changeSuggestedItemOrder?: (startIndex: number, endIndex: number) => void;
  searchFieldType?: string;
  preventFromOpening?: boolean;
  isCrossSell?: boolean;
  productVariantId?: string;
  showLabel?: boolean;
  isLimitReached?: boolean;
  isFBTtype?: boolean;
  isOrderBumpType?: boolean;
  ignoreValidation?: boolean;
  uniqueLabel?: string;
};

export const SearchFieldWithGrayBox: React.FC<SearchFieldWithGrayBoxProps> = (
  props
) => {
  const {
    resourceType,
    showVariants,
    isFreeGiftSearchField,
    isDiscountLinkSearchField,
    selectList,
    onSelectedChange,
    onFormValidityChange,
    searchFieldType,
    changeSuggestedItemOrder,
    preventFromOpening,
    isCrossSell,
    productVariantId,
    showLabel,
    isLimitReached,
    isFBTtype,
    getOnMount,
    mergedSelectedGrayBoxElements,
    isOrderBumpType,
    ignoreValidation,
    uniqueLabel,
  } = props;
  const { getLookupShopifyDetailedObjectsDetails } = useConfigureOffers();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [i18n] = useI18n();
  const [showGrayBox, setShowGrayBox] = useState<boolean>(false);
  const [selectedGrayBoxElements, setSelectedGrayBoxElements] =
    useState<ResourceSelectionProps[]>(selectList);
  const [showMoreThanFiveTags, setShowMoreThanFiveTags] =
    useState<boolean>(false);
  const [
    showMoreThanFiveSelectedVariants,
    setShowMoreThanFiveSelectedVariants,
  ] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hoveredPriority, setHoveredPriority] = useState<string | null>(null);

  const extraValidityCondition = useMemo(
    () => (isFBTtype ? isLimitReached : !selectedGrayBoxElements?.length),
    [isFBTtype, selectedGrayBoxElements, isLimitReached]
  );

  const formIsInvalid = useMemo(
    () =>
      !!extraValidityCondition &&
      !isLoading &&
      !isOrderBumpType &&
      !ignoreValidation,
    [extraValidityCondition, isLoading, isOrderBumpType, ignoreValidation]
  );

  const listForPreview = useMemo(
    () =>
      isFBTtype
        ? (mergedSelectedGrayBoxElements as ResourceSelectionProps[])
        : selectedGrayBoxElements,
    [isFBTtype, mergedSelectedGrayBoxElements, selectedGrayBoxElements]
  );

  useEffect(() => {
    onFormValidityChange?.(formIsInvalid);
    return () => {
      // change in future
      onFormValidityChange?.(null);
    };
  }, [formIsInvalid, onFormValidityChange]);

  const toggleShowGrayBoxState = useCallback(
    (breakCondition?: boolean) => {
      if (!selectedGrayBoxElements?.length || breakCondition) {
        setShowGrayBox((prevState: boolean) => !prevState);
      }
    },
    [selectedGrayBoxElements, setShowGrayBox]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }
      changeSuggestedItemOrder?.(result.source.index, result.destination.index);
    },
    [changeSuggestedItemOrder]
  );

  const onSelection = useCallback(
    (data: SelectSectionProps) => {
      setShowGrayBox(false);
      setSelectedGrayBoxElements([...data.selection]);
      onSelectedChange(data.selection);
    },
    [onSelectedChange]
  );

  const removeSelectedTag = useCallback(
    (el: ResourceSelectionProps) => {
      const list = selectedGrayBoxElements?.filter(
        (element: ResourceSelectionProps) => element.id !== el.id
      );
      setSelectedGrayBoxElements(list);
      onSelectedChange(list);
    },
    [setSelectedGrayBoxElements, selectedGrayBoxElements, onSelectedChange]
  );

  const toggleTagsVisibilityState = useCallback(() => {
    setShowMoreThanFiveTags((prevState: boolean) => !prevState);
  }, [setShowMoreThanFiveTags]);

  const currentResourceType = useMemo(
    () =>
      isFreeGiftSearchField || isOrderBumpType
        ? resourceType === GrayBoxResourceTypeEnum.Product && productVariantId
          ? ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
          : resourceType.toUpperCase()
        : resourceType === GrayBoxResourceTypeEnum.Product && !!showVariants
        ? ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
        : resourceType.toUpperCase(),
    [
      resourceType,
      showVariants,
      productVariantId,
      isFreeGiftSearchField,
      isOrderBumpType,
    ]
  );

  const label = useMemo(
    () =>
      resourceType === GrayBoxResourceTypeEnum.Product &&
      showVariants &&
      !isOrderBumpType
        ? i18n.translate('ProductVariants')
        : resourceType === GrayBoxResourceTypeEnum.Product && !showVariants
        ? i18n.translate('Products')
        : resourceType === GrayBoxResourceTypeEnum.Product &&
          showVariants &&
          isOrderBumpType
        ? i18n.translate('Product')
        : i18n.translate('Collections'),
    [resourceType, showVariants, isOrderBumpType]
  );

  useEffect(() => {
    if (selectList?.length) {
      setIsLoading(true);
      setShowGrayBox(false);
      getLookupShopifyDetailedObjectsDetails(
        {
          type: currentResourceType as ShopifyObjectTypeDto,
          objects: selectList as unknown as ShopifyObjectDto[],
        },
        setIsLoading
      ).then((res) => {
        getOnMount?.(res as ResourceSelectionProps[]);
        setSelectedGrayBoxElements(res as ResourceSelectionProps[]);
      });
    } else {
      setShowGrayBox(false);
    }

    return () => {
      setShowGrayBox(false);
      setSelectedGrayBoxElements([]);
      setShowMoreThanFiveTags(false);
      setShowMoreThanFiveSelectedVariants(false);
      setIsLoading(false);
    };
  }, [
    currentResourceType,
    productVariantId,
    getLookupShopifyDetailedObjectsDetails,
    getOnMount,
  ]);

  const helpTextLabel: string = useMemo(() => {
    switch (resourceType) {
      case GrayBoxResourceTypeEnum.Collection:
        return isFreeGiftSearchField
          ? 'FreeGiftSearchFieldHelpText'
          : isDiscountLinkSearchField
          ? 'DiscountLinkSearchFieldHelpText'
          : 'SearchFieldHelpText';
      case GrayBoxResourceTypeEnum.Product:
        return isFreeGiftSearchField
          ? 'FreeGiftSearchFieldHelpText2'
          : isDiscountLinkSearchField
          ? 'DiscountLinkSearchFieldHelpText'
          : 'SearchFieldHelpText2';
      default:
        return 'SearchFieldHelpText';
    }
  }, [resourceType, isFreeGiftSearchField]);

  const warningTextLabel: string = useMemo(() => {
    switch (resourceType) {
      case GrayBoxResourceTypeEnum.Collection:
        return 'SearchFieldWarningText';
      case GrayBoxResourceTypeEnum.Product:
        if (showVariants && !isFreeGiftSearchField) {
          return 'SearchFieldWarningText3';
        } else {
          return 'SearchFieldWarningText2';
        }
      default:
        return 'SearchFieldWarningText';
    }
  }, [resourceType, isFreeGiftSearchField, showVariants]);

  const placeholderTextLabel: string = useMemo(() => {
    switch (resourceType) {
      case GrayBoxResourceTypeEnum.Collection:
        return `SearchFieldPlaceholder${searchFieldType || ''}`;
      case GrayBoxResourceTypeEnum.Product:
        if (showVariants) {
          return `SearchFieldPlaceholderProductVariants${
            searchFieldType || ''
          }`;
        } else {
          return `SearchFieldPlaceholderProducts${searchFieldType || ''}`;
        }
      default:
        return `SearchFieldPlaceholder${searchFieldType || ''}`;
    }
  }, [resourceType, showVariants, searchFieldType]);

  const selectedVariants: Resource[] = useMemo(
    () =>
      selectedGrayBoxElements?.map((el: any) => ({
        id: el.id,
        variants:
          (el as Product).variants?.map((variant: Partial<ProductVariant>) => ({
            id: variant.id || '',
          })) || [],
      })),
    [selectedGrayBoxElements]
  );

  const showMoreVariantsButton = useMemo(
    () =>
      listForPreview?.length > 5 ? (
        <Button
          icon={showMoreThanFiveSelectedVariants ? <HideIcon /> : <ViewIcon />}
          onClick={() =>
            setShowMoreThanFiveSelectedVariants(
              (prevState: boolean) => !prevState
            )
          }
          variant='plain'
        >
          {showMoreThanFiveSelectedVariants
            ? i18n.translate('HideButton') || 'Collapse items list'
            : `${i18n.translate('Show') || 'Show'} ${
                listForPreview?.length - 5
              } ${i18n.translate('More') || 'more'} ${
                listForPreview?.length - 5 > 1
                  ? i18n.translate('Items') || 'items'
                  : i18n.translate('Item') || 'item'
              }`}
        </Button>
      ) : null,
    [i18n, listForPreview?.length, showMoreThanFiveSelectedVariants]
  );

  const emptySearchFieldPlaceholder = useMemo(
    () =>
      !selectedGrayBoxElements?.length && (
        <>
          <Icon tone='base' source={SearchIcon as IconSource} />
          <p className='ShopifyAppBridgeModalInitiatorSearchFieldPlaceholder'>
            {i18n.translate(placeholderTextLabel) ||
              'Click to select one or more collections to exclude'}
          </p>
        </>
      ),
    [selectedGrayBoxElements, i18n, placeholderTextLabel]
  );

  const searchFieldFilledWithTags = useMemo(
    () =>
      selectedGrayBoxElements?.length ? (
        <>
          <div className='TagsContainer'>
            {selectedGrayBoxElements
              .filter((_el: ResourceSelectionProps, index: number) =>
                !showMoreThanFiveTags ? index < 5 : true
              )
              .map((el: ResourceSelectionProps) => (
                <div
                  className='ShopifyAppBridgeModalInitiatorSearchFieldTag'
                  key={el.id}
                >
                  <span>{el.title}</span>
                  <Button
                    icon={XSmallIcon as IconSource}
                    onClick={() => removeSelectedTag(el)}
                  />
                </div>
              ))}
            {selectedGrayBoxElements?.length > 5 && (
              <div
                className='ShopifyAppBridgeModalInitiatorSearchFieldTag ShowMore'
                onClick={toggleTagsVisibilityState}
              >
                {!showMoreThanFiveTags ? '' : i18n.translate('Hide') || 'Hide'}
                {` ${selectedGrayBoxElements?.length - 5}${
                  (!showMoreThanFiveTags
                    ? ' ' + (i18n.translate('More') || 'more') + ' '
                    : ' ') +
                  (selectedGrayBoxElements?.length - 5 > 1
                    ? i18n.translate('Items') || 'items'
                    : i18n.translate('Item') || 'item')
                }`}
              </div>
            )}
          </div>
          <Button
            icon={EditIcon as IconSource}
            onClick={() => toggleShowGrayBoxState(true)}
          />
        </>
      ) : null,
    [
      selectedGrayBoxElements,
      showMoreThanFiveTags,
      removeSelectedTag,
      toggleShowGrayBoxState,
      toggleTagsVisibilityState,
      i18n,
    ]
  );

  const getResourceType = useCallback(
    (grayBoxType: GrayBoxResourceType): ResourcePickerResourceType => {
      switch (grayBoxType) {
        case GrayBoxResourceTypeEnum.Product:
          return 'product';
        case GrayBoxResourceTypeEnum.Collection:
          return 'collection';
        default:
          return 'variant';
      }
    },
    []
  );

  const selectedVariantsPreview = useMemo(
    () =>
      !!showVariants && listForPreview?.length ? (
        !isFBTtype ? (
          <div className='SelectedVariantsContainer'>
            <h3>{i18n.translate('SelectedVariants')}</h3>
            {listForPreview
              .filter((_el: ResourceSelectionProps, index: number) =>
                !showMoreThanFiveSelectedVariants ? index < 5 : true
              )
              .map((el: ResourceSelectionProps) => (
                <div className='SelectedVariantTile' key={el.id}>
                  {el.featuredImageUrl ? (
                    <Thumbnail
                      source={el.featuredImageUrl}
                      alt={el.title || ''}
                      size='small'
                    />
                  ) : null}
                  <p className='SelectedVariantTitle'>
                    {el.title}
                    {!isFreeGiftSearchField && !isOrderBumpType && (
                      <span>
                        {(el as Product).variants?.map(
                          (variant: Partial<ProductVariant>, index: number) => (
                            <span key={variant.id}>
                              {index > 0 ? ', ' : ''}
                              {variant.title}
                            </span>
                          )
                        )}
                      </span>
                    )}
                  </p>
                </div>
              ))}
            {showMoreVariantsButton}
          </div>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <div className='SelectedVariantsContainer'>
              <h3>{i18n.translate('SelectedProductsAndVariants')}</h3>
              <Droppable droppableId='selected-variants-list'>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {listForPreview
                      .filter((_el: ResourceSelectionProps, index: number) =>
                        !showMoreThanFiveSelectedVariants ? index < 5 : true
                      )
                      .map(
                        (
                          el: ResourceSelectionProps,
                          index: number,
                          arr: ResourceSelectionProps[]
                        ) => (
                          <Draggable
                            key={el.id}
                            draggableId={el.id.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className={classNames('SelectedVariantTile', {
                                  Hover: hoveredPriority === el.id,
                                })}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onPointerEnter={() => setHoveredPriority(el.id)}
                                onPointerLeave={() => setHoveredPriority(null)}
                              >
                                {el.featuredImageUrl ? (
                                  <Thumbnail
                                    source={el.featuredImageUrl}
                                    alt={el.title || ''}
                                    size='small'
                                  />
                                ) : null}
                                <p className='SelectedVariantTitle'>
                                  {el.title}
                                  <span>{el.subtitle}</span>
                                </p>
                                {hoveredPriority === el.id && (
                                  <div className='dragHandleMinor'>
                                    <Icon
                                      source={DragHandleIcon as IconSource}
                                      tone='subdued'
                                    />
                                  </div>
                                )}
                                {hoveredPriority === el.id && (
                                  <div className='orderBtns'>
                                    <div
                                      className='btn'
                                      onClick={() =>
                                        index !== 0 &&
                                        changeSuggestedItemOrder?.(
                                          index,
                                          index - 1
                                        )
                                      }
                                    >
                                      <Icon
                                        source={ChevronUpIcon as IconSource}
                                        tone={index !== 0 ? 'base' : 'subdued'}
                                      />
                                    </div>
                                    <div
                                      className='btn'
                                      onClick={() =>
                                        arr.length - 1 !== index &&
                                        changeSuggestedItemOrder?.(
                                          index,
                                          index + 1
                                        )
                                      }
                                    >
                                      <Icon
                                        source={ChevronDownIcon as IconSource}
                                        tone={
                                          arr.length - 1 !== index
                                            ? 'base'
                                            : 'subdued'
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              {showMoreVariantsButton}
            </div>
          </DragDropContext>
        )
      ) : null,
    [
      listForPreview,
      isFreeGiftSearchField,
      isOrderBumpType,
      i18n,
      showVariants,
      showMoreThanFiveSelectedVariants,
      showMoreVariantsButton,
      isFBTtype,
      hoveredPriority,
    ]
  );

  return (
    <>
      <div
        className={classNames('ShopifyAppBridgeModalInitiatorContainer', {
          Invalid: formIsInvalid,
          isCrossSell,
        })}
      >
        {showLabel && <div className='label'>{uniqueLabel || label}</div>}
        <div
          className={classNames('ShopifyAppBridgeModalInitiatorSearchField', {
            ShopifyAppBridgeModalInitiatorSearchFieldFilled:
              selectedGrayBoxElements?.length,
          })}
          onClick={() => toggleShowGrayBoxState()}
        >
          {emptySearchFieldPlaceholder}
          {searchFieldFilledWithTags}
        </div>
        {isCrossSell ? (
          !formIsInvalid && <p>{i18n.translate(helpTextLabel)}</p>
        ) : !isFBTtype ? (
          <p>
            {formIsInvalid ? (
              <Icon source={AlertCircleIcon as IconSource} tone='critical' />
            ) : null}
            {i18n.translate(formIsInvalid ? warningTextLabel : helpTextLabel)}
          </p>
        ) : null}
        {isFBTtype && isLimitReached && showVariants && (
          <Banner tone='info'>
            <p className='header'>{i18n.translate('LimitHeader')}</p>
            <p className='body'>{i18n.translate('LimitBody')}</p>
          </Banner>
        )}
        {selectedVariantsPreview}
      </div>
      {showGrayBox && !preventFromOpening && !isDebugOrLocal && (
        <ResourcePickerAdapter
          type={getResourceType(resourceType)}
          onCancel={() => setShowGrayBox(false)}
          onSelection={onSelection}
          selectionIds={selectedVariants}
          showVariants={
            !!(showVariants && !isFreeGiftSearchField && !isOrderBumpType)
          }
          multiple={
            (resourceType === GrayBoxResourceTypeEnum.Product &&
              (isFreeGiftSearchField || isOrderBumpType)) ||
            ((resourceType === GrayBoxResourceTypeEnum.Collection ||
              resourceType === GrayBoxResourceTypeEnum.Product) &&
              isDiscountLinkSearchField)
              ? false
              : true
          }
        />
      )}
      {showGrayBox && !preventFromOpening && isDebugOrLocal && (
        <ResourcePickerCustom
          open={showGrayBox && !preventFromOpening}
          type={getResourceType(resourceType)}
          onCancel={() => setShowGrayBox(false)}
          onSelection={onSelection}
          selectionIds={selectedVariants}
          showVariants={
            !!(showVariants && !isFreeGiftSearchField && !isOrderBumpType)
          }
          multiple={
            (resourceType === GrayBoxResourceTypeEnum.Product &&
              (isFreeGiftSearchField || isOrderBumpType)) ||
            ((resourceType === GrayBoxResourceTypeEnum.Collection ||
              resourceType === GrayBoxResourceTypeEnum.Product) &&
              isDiscountLinkSearchField)
              ? false
              : true
          }
        />
      )}
    </>
  );
};
