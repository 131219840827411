import { emptySplitAdminPromotionsApi as api } from './emptyAdminPromotionsApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPromotionsV6LookupCountries: build.query<
      GetPromotionsV6LookupCountriesApiResponse,
      GetPromotionsV6LookupCountriesApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/countries`,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
    getPromotionsV6LookupShippingCountries: build.query<
      GetPromotionsV6LookupShippingCountriesApiResponse,
      GetPromotionsV6LookupShippingCountriesApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/shippingCountries`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6LookupReferrers: build.query<
      GetPromotionsV6LookupReferrersApiResponse,
      GetPromotionsV6LookupReferrersApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/referrers`,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
    postPromotionsV6LookupShopifyDetailedObjects: build.mutation<
      PostPromotionsV6LookupShopifyDetailedObjectsApiResponse,
      PostPromotionsV6LookupShopifyDetailedObjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/shopifyDetailedObjects`,
        method: 'POST',
        body: queryArg.query,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6LookupSmartTagsExample: build.query<
      GetPromotionsV6LookupSmartTagsExampleApiResponse,
      GetPromotionsV6LookupSmartTagsExampleApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/smartTags/example`,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
    getPromotionsV6LookupSmartTagsById: build.query<
      GetPromotionsV6LookupSmartTagsByIdApiResponse,
      GetPromotionsV6LookupSmartTagsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/smartTags/${queryArg.id}`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6LookupTags: build.query<
      GetPromotionsV6LookupTagsApiResponse,
      GetPromotionsV6LookupTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/tags`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { category: queryArg.category },
      }),
    }),
    getPromotionsV6LookupShippingRates: build.query<
      GetPromotionsV6LookupShippingRatesApiResponse,
      GetPromotionsV6LookupShippingRatesApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/shippingRates`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6LookupMarkets: build.query<
      GetPromotionsV6LookupMarketsApiResponse,
      GetPromotionsV6LookupMarketsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/markets`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6LookupAppBridgeResourcesCollections: build.query<
      GetPromotionsV6LookupAppBridgeResourcesCollectionsApiResponse,
      GetPromotionsV6LookupAppBridgeResourcesCollectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/appBridgeResources/collections`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6LookupAppBridgeResourcesProducts: build.query<
      GetPromotionsV6LookupAppBridgeResourcesProductsApiResponse,
      GetPromotionsV6LookupAppBridgeResourcesProductsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/appBridgeResources/products`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6LookupAppBridgeResourcesProductVariants: build.query<
      GetPromotionsV6LookupAppBridgeResourcesProductVariantsApiResponse,
      GetPromotionsV6LookupAppBridgeResourcesProductVariantsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/appBridgeResources/productVariants`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6LookupShopifyDiscountCodesCodes: build.query<
      GetPromotionsV6LookupShopifyDiscountCodesCodesApiResponse,
      GetPromotionsV6LookupShopifyDiscountCodesCodesApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/shopifyDiscountCodes/codes`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { search: queryArg.search },
      }),
    }),
    getPromotionsV6LookupShopifyDiscountCodesGroups: build.query<
      GetPromotionsV6LookupShopifyDiscountCodesGroupsApiResponse,
      GetPromotionsV6LookupShopifyDiscountCodesGroupsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/shopifyDiscountCodes/groups`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { search: queryArg.search },
      }),
    }),
    getPromotionsV6LookupShopifyDiscountCodesApps: build.query<
      GetPromotionsV6LookupShopifyDiscountCodesAppsApiResponse,
      GetPromotionsV6LookupShopifyDiscountCodesAppsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/lookup/shopifyDiscountCodes/apps`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { search: queryArg.search },
      }),
    }),
    getPromotionsV6PromotionById: build.query<
      GetPromotionsV6PromotionByIdApiResponse,
      GetPromotionsV6PromotionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}`,
        headers: {
          'Accept-Language': queryArg['Accept-Language'],
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    deletePromotionsV6PromotionById: build.mutation<
      DeletePromotionsV6PromotionByIdApiResponse,
      DeletePromotionsV6PromotionByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}`,
        method: 'DELETE',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6PromotionByIdOffers: build.query<
      GetPromotionsV6PromotionByIdOffersApiResponse,
      GetPromotionsV6PromotionByIdOffersApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/offers`,
        headers: {
          'Accept-Language': queryArg['Accept-Language'],
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          page: queryArg.page,
          itemsPerPage: queryArg.itemsPerPage,
          sortDirection: queryArg.sortDirection,
          search: queryArg.search,
        },
      }),
    }),
    postPromotionsV6PromotionByIdOffers: build.mutation<
      PostPromotionsV6PromotionByIdOffersApiResponse,
      PostPromotionsV6PromotionByIdOffersApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/offers`,
        method: 'POST',
        body: queryArg.initializeOfferRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionList: build.mutation<
      PostPromotionsV6PromotionListApiResponse,
      PostPromotionsV6PromotionListApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/list`,
        method: 'POST',
        body: queryArg.getPromotionsRequestDto,
        headers: {
          'Accept-Language': queryArg['Accept-Language'],
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionExport: build.mutation<
      PostPromotionsV6PromotionExportApiResponse,
      PostPromotionsV6PromotionExportApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/export`,
        method: 'POST',
        body: queryArg.getPromotionsRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionImport: build.mutation<
      PostPromotionsV6PromotionImportApiResponse,
      PostPromotionsV6PromotionImportApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/import`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          overwriteSameTokenPromotions: queryArg.overwriteSameTokenPromotions,
        },
      }),
    }),
    getPromotionsV6PromotionImportById: build.query<
      GetPromotionsV6PromotionImportByIdApiResponse,
      GetPromotionsV6PromotionImportByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/import/${queryArg.id}`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionOperationProgress: build.mutation<
      PostPromotionsV6PromotionOperationProgressApiResponse,
      PostPromotionsV6PromotionOperationProgressApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/operationProgress`,
        method: 'POST',
        body: queryArg.getPromotionOperationProgressRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdSchedule: build.mutation<
      PostPromotionsV6PromotionByIdScheduleApiResponse,
      PostPromotionsV6PromotionByIdScheduleApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/schedule`,
        method: 'POST',
        body: queryArg.schedulePromotionRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdPublish: build.mutation<
      PostPromotionsV6PromotionByIdPublishApiResponse,
      PostPromotionsV6PromotionByIdPublishApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/publish`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdUnpublish: build.mutation<
      PostPromotionsV6PromotionByIdUnpublishApiResponse,
      PostPromotionsV6PromotionByIdUnpublishApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/unpublish`,
        method: 'POST',
        headers: {
          'Accept-Language': queryArg['Accept-Language'],
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdArchive: build.mutation<
      PostPromotionsV6PromotionByIdArchiveApiResponse,
      PostPromotionsV6PromotionByIdArchiveApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/archive`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdDuplicate: build.mutation<
      PostPromotionsV6PromotionByIdDuplicateApiResponse,
      PostPromotionsV6PromotionByIdDuplicateApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/duplicate`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdPause: build.mutation<
      PostPromotionsV6PromotionByIdPauseApiResponse,
      PostPromotionsV6PromotionByIdPauseApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/pause`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdResume: build.mutation<
      PostPromotionsV6PromotionByIdResumeApiResponse,
      PostPromotionsV6PromotionByIdResumeApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/resume`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdRollback: build.mutation<
      PostPromotionsV6PromotionByIdRollbackApiResponse,
      PostPromotionsV6PromotionByIdRollbackApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/rollback`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdRestore: build.mutation<
      PostPromotionsV6PromotionByIdRestoreApiResponse,
      PostPromotionsV6PromotionByIdRestoreApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/restore`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionBatch: build.mutation<
      PostPromotionsV6PromotionBatchApiResponse,
      PostPromotionsV6PromotionBatchApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/batch`,
        method: 'POST',
        body: queryArg.promotionBatchOperationRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6Promotion: build.mutation<
      PostPromotionsV6PromotionApiResponse,
      PostPromotionsV6PromotionApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion`,
        method: 'POST',
        body: queryArg.createPromotionRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionTest: build.mutation<
      PostPromotionsV6PromotionTestApiResponse,
      PostPromotionsV6PromotionTestApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/test`,
        method: 'POST',
        body: queryArg.createTestPromotionRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putPromotionsV6PromotionByIdDetails: build.mutation<
      PutPromotionsV6PromotionByIdDetailsApiResponse,
      PutPromotionsV6PromotionByIdDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/details`,
        method: 'PUT',
        body: queryArg.updatePromotionDetailsRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6PromotionByIdTest: build.query<
      GetPromotionsV6PromotionByIdTestApiResponse,
      GetPromotionsV6PromotionByIdTestApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/test`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdDiscardUnpublished: build.mutation<
      PostPromotionsV6PromotionByIdDiscardUnpublishedApiResponse,
      PostPromotionsV6PromotionByIdDiscardUnpublishedApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/discardUnpublished`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6PromotionOffersById: build.query<
      GetPromotionsV6PromotionOffersByIdApiResponse,
      GetPromotionsV6PromotionOffersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/offers/${queryArg.id}`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putPromotionsV6PromotionOffersById: build.mutation<
      PutPromotionsV6PromotionOffersByIdApiResponse,
      PutPromotionsV6PromotionOffersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/offers/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6PromotionOffersTemplate: build.query<
      GetPromotionsV6PromotionOffersTemplateApiResponse,
      GetPromotionsV6PromotionOffersTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/offers/template`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { type: queryArg['type'] },
      }),
    }),
    getPromotionsV6PromotionOffersTemplateList: build.query<
      GetPromotionsV6PromotionOffersTemplateListApiResponse,
      GetPromotionsV6PromotionOffersTemplateListApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/offers/template/list`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6PromotionByIdOffersTemplateList: build.query<
      GetPromotionsV6PromotionByIdOffersTemplateListApiResponse,
      GetPromotionsV6PromotionByIdOffersTemplateListApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/offers/template/list`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6PromotionByIdOffersTemplateListAndOfferId: build.query<
      GetPromotionsV6PromotionByIdOffersTemplateListAndOfferIdApiResponse,
      GetPromotionsV6PromotionByIdOffersTemplateListAndOfferIdApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/offers/template/list/${queryArg.offerId}`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    deletePromotionsV6PromotionByIdOffersAndOfferId: build.mutation<
      DeletePromotionsV6PromotionByIdOffersAndOfferIdApiResponse,
      DeletePromotionsV6PromotionByIdOffersAndOfferIdApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/offers/${queryArg.offerId}`,
        method: 'DELETE',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdOffersAndOfferIdDuplicate: build.mutation<
      PostPromotionsV6PromotionByIdOffersAndOfferIdDuplicateApiResponse,
      PostPromotionsV6PromotionByIdOffersAndOfferIdDuplicateApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/offers/${queryArg.offerId}/duplicate`,
        method: 'POST',
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putPromotionsV6PromotionOffersByIdType: build.mutation<
      PutPromotionsV6PromotionOffersByIdTypeApiResponse,
      PutPromotionsV6PromotionOffersByIdTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/offers/${queryArg.id}/type`,
        method: 'PUT',
        body: queryArg.changeOfferTypeRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdOffersAndOfferIdCombinationsSummary:
      build.mutation<
        PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsSummaryApiResponse,
        PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/promotions/v6/promotion/${queryArg.id}/offers/${queryArg.offerId}/combinations/summary`,
          method: 'POST',
          body: queryArg.excludedCombinationsDto,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
            'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
          },
        }),
      }),
    postPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOffers:
      build.mutation<
        PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOffersApiResponse,
        PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOffersApiArg
      >({
        query: (queryArg) => ({
          url: `/promotions/v6/promotion/${queryArg.id}/offers/${queryArg.offerId}/combinations/offers`,
          method: 'POST',
          body: queryArg.getSelectableCombinationsListRequestDto,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
            'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
          },
        }),
      }),
    postPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOfType:
      build.mutation<
        PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOfTypeApiResponse,
        PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOfTypeApiArg
      >({
        query: (queryArg) => ({
          url: `/promotions/v6/promotion/${queryArg.id}/offers/${queryArg.offerId}/combinations/ofType`,
          method: 'POST',
          body: queryArg.getOfferCombinationsListRequestDto,
          headers: {
            'Accept-Language': queryArg['Accept-Language'],
            'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
            'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
          },
        }),
      }),
    getPromotionsV6PromotionByIdSettings: build.query<
      GetPromotionsV6PromotionByIdSettingsApiResponse,
      GetPromotionsV6PromotionByIdSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putPromotionsV6PromotionByIdSettings: build.mutation<
      PutPromotionsV6PromotionByIdSettingsApiResponse,
      PutPromotionsV6PromotionByIdSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings`,
        method: 'PUT',
        body: queryArg.promotionSettingsDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putPromotionsV6PromotionByIdSettingsTriggerType: build.mutation<
      PutPromotionsV6PromotionByIdSettingsTriggerTypeApiResponse,
      PutPromotionsV6PromotionByIdSettingsTriggerTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/triggerType`,
        method: 'PUT',
        body: queryArg.updatePromotionTriggerTypeRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putPromotionsV6PromotionByIdSettingsBudget: build.mutation<
      PutPromotionsV6PromotionByIdSettingsBudgetApiResponse,
      PutPromotionsV6PromotionByIdSettingsBudgetApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/budget`,
        method: 'PUT',
        body: queryArg.triggerMaximumUsesFilterDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdSettingsDiscountLinkPreview: build.mutation<
      PostPromotionsV6PromotionByIdSettingsDiscountLinkPreviewApiResponse,
      PostPromotionsV6PromotionByIdSettingsDiscountLinkPreviewApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/discountLink/preview`,
        method: 'POST',
        body: queryArg.triggerDiscountLinkDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getPromotionsV6PromotionByIdSettingsDiscountLinkList: build.query<
      GetPromotionsV6PromotionByIdSettingsDiscountLinkListApiResponse,
      GetPromotionsV6PromotionByIdSettingsDiscountLinkListApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/discountLink/list`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdSettingsDiscountLink: build.mutation<
      PostPromotionsV6PromotionByIdSettingsDiscountLinkApiResponse,
      PostPromotionsV6PromotionByIdSettingsDiscountLinkApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/discountLink`,
        method: 'POST',
        body: queryArg.triggerDiscountLinkDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putPromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkId:
      build.mutation<
        PutPromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdApiResponse,
        PutPromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/promotions/v6/promotion/${queryArg.id}/settings/discountLink/${queryArg.discountLinkId}`,
          method: 'PUT',
          body: queryArg.triggerDiscountLinkDto,
          headers: {
            'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
            'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
          },
        }),
      }),
    deletePromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkId:
      build.mutation<
        DeletePromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdApiResponse,
        DeletePromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdApiArg
      >({
        query: (queryArg) => ({
          url: `/promotions/v6/promotion/${queryArg.id}/settings/discountLink/${queryArg.discountLinkId}`,
          method: 'DELETE',
          headers: {
            'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
            'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
          },
        }),
      }),
    postPromotionsV6PromotionByIdSettingsPromotionCodeList: build.mutation<
      PostPromotionsV6PromotionByIdSettingsPromotionCodeListApiResponse,
      PostPromotionsV6PromotionByIdSettingsPromotionCodeListApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/promotionCode/list`,
        method: 'POST',
        body: queryArg.getPromotionCodesForPromotionRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdSettingsPromotionCodeGenerate: build.mutation<
      PostPromotionsV6PromotionByIdSettingsPromotionCodeGenerateApiResponse,
      PostPromotionsV6PromotionByIdSettingsPromotionCodeGenerateApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/promotionCode/generate`,
        method: 'POST',
        body: queryArg.generatePromotionCodesRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    putPromotionsV6PromotionByIdSettingsPromotionCodeBatch: build.mutation<
      PutPromotionsV6PromotionByIdSettingsPromotionCodeBatchApiResponse,
      PutPromotionsV6PromotionByIdSettingsPromotionCodeBatchApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/promotionCode/batch`,
        method: 'PUT',
        body: queryArg.batchPromotionCodesOperationRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdSettingsPromotionCodeExport: build.mutation<
      PostPromotionsV6PromotionByIdSettingsPromotionCodeExportApiResponse,
      PostPromotionsV6PromotionByIdSettingsPromotionCodeExportApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/promotionCode/export`,
        method: 'POST',
        body: queryArg.getPromotionCodesForPromotionRequestDto,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postPromotionsV6PromotionByIdSettingsPromotionCodeImport: build.mutation<
      PostPromotionsV6PromotionByIdSettingsPromotionCodeImportApiResponse,
      PostPromotionsV6PromotionByIdSettingsPromotionCodeImportApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/promotion/${queryArg.id}/settings/promotionCode/import`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: { overwriteExistingCodes: queryArg.overwriteExistingCodes },
      }),
    }),
    getPromotionsV6Version: build.query<
      GetPromotionsV6VersionApiResponse,
      GetPromotionsV6VersionApiArg
    >({
      query: (queryArg) => ({
        url: `/promotions/v6/version`,
        headers: { 'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as adminPromotionsApi };
export type GetPromotionsV6LookupCountriesApiResponse =
  /** status 200 Success */ CountryLookupDto[];
export type GetPromotionsV6LookupCountriesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
};
export type GetPromotionsV6LookupShippingCountriesApiResponse =
  /** status 200 Success */ ShippingCountryLookupDto[];
export type GetPromotionsV6LookupShippingCountriesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupReferrersApiResponse =
  /** status 200 Success */ ReferrerLookupDto[];
export type GetPromotionsV6LookupReferrersApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
};
export type PostPromotionsV6LookupShopifyDetailedObjectsApiResponse =
  /** status 200 Success */ ShopifyDetailedObjectDto[];
export type PostPromotionsV6LookupShopifyDetailedObjectsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  query: Query;
};
export type GetPromotionsV6LookupSmartTagsExampleApiResponse =
  /** status 200 Success */ SmartTag[];
export type GetPromotionsV6LookupSmartTagsExampleApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
};
export type GetPromotionsV6LookupSmartTagsByIdApiResponse =
  /** status 200 Success */ OfferSmartTags[];
export type GetPromotionsV6LookupSmartTagsByIdApiArg = {
  /** Identifier of promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupTagsApiResponse =
  /** status 200 Success */ string[];
export type GetPromotionsV6LookupTagsApiArg = {
  category?: TagCategoryDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupShippingRatesApiResponse =
  /** status 200 Success */ ShippingRateLookupDto[];
export type GetPromotionsV6LookupShippingRatesApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupMarketsApiResponse =
  /** status 200 Success */ MarketLookupDto[];
export type GetPromotionsV6LookupMarketsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupAppBridgeResourcesCollectionsApiResponse =
  /** status 200 Success */ ShopifyCollectionAppBridgeResourceDto[];
export type GetPromotionsV6LookupAppBridgeResourcesCollectionsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupAppBridgeResourcesProductsApiResponse =
  /** status 200 Success */ ShopifyProductAppBridgeResourceDto[];
export type GetPromotionsV6LookupAppBridgeResourcesProductsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupAppBridgeResourcesProductVariantsApiResponse =
  /** status 200 Success */ ShopifyProductVariantAppBridgeResourceDto[];
export type GetPromotionsV6LookupAppBridgeResourcesProductVariantsApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupShopifyDiscountCodesCodesApiResponse =
  /** status 200 Success */ ShopifyDiscountCodeLookupDto[];
export type GetPromotionsV6LookupShopifyDiscountCodesCodesApiArg = {
  search?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupShopifyDiscountCodesGroupsApiResponse =
  /** status 200 Success */ ShopifyDiscountCodeLookupDto[];
export type GetPromotionsV6LookupShopifyDiscountCodesGroupsApiArg = {
  search?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6LookupShopifyDiscountCodesAppsApiResponse =
  /** status 200 Success */ ShopifyDiscountCodeAppLookupDto[];
export type GetPromotionsV6LookupShopifyDiscountCodesAppsApiArg = {
  search?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6PromotionByIdApiResponse =
  /** status 200 Success */ PromotionOverviewDtoRead;
export type GetPromotionsV6PromotionByIdApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Language in which translated data should be provided */
  'Accept-Language'?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type DeletePromotionsV6PromotionByIdApiResponse =
  /** status 204 No Content */ void;
export type DeletePromotionsV6PromotionByIdApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6PromotionByIdOffersApiResponse =
  /** status 200 Success */ GetPromotionOverviewOffersResponseDto;
export type GetPromotionsV6PromotionByIdOffersApiArg = {
  /** Identifier of the promotion */
  id: string;
  page?: number;
  itemsPerPage?: number;
  sortDirection?: SortDirectionDto;
  search?: string;
  /** Language in which translated data should be provided */
  'Accept-Language'?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdOffersApiResponse =
  /** status 201 Created */ EntityIdentifierDto;
export type PostPromotionsV6PromotionByIdOffersApiArg = {
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  initializeOfferRequestDto: InitializeOfferRequestDto;
};
export type PostPromotionsV6PromotionListApiResponse =
  /** status 200 Success */ GetPromotionsResponseDto;
export type PostPromotionsV6PromotionListApiArg = {
  /** Language in which translated data should be provided */
  'Accept-Language'?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  getPromotionsRequestDto: GetPromotionsRequestDto;
};
export type PostPromotionsV6PromotionExportApiResponse =
  /** status 200 Success */ GetPromotionsResponseDto;
export type PostPromotionsV6PromotionExportApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  getPromotionsRequestDto: GetPromotionsRequestDto;
};
export type PostPromotionsV6PromotionImportApiResponse =
  /** status 200 Success */ ImportPromotionsResponseDto;
export type PostPromotionsV6PromotionImportApiArg = {
  overwriteSameTokenPromotions?: boolean;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: {
    file?: Blob;
  };
};
export type GetPromotionsV6PromotionImportByIdApiResponse =
  /** status 200 Success */ TrackImportStatusResponseDto;
export type GetPromotionsV6PromotionImportByIdApiArg = {
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionOperationProgressApiResponse =
  /** status 200 Success */ PromotionOperationProgressDto[];
export type PostPromotionsV6PromotionOperationProgressApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  getPromotionOperationProgressRequestDto: GetPromotionOperationProgressRequestDto;
};
export type PostPromotionsV6PromotionByIdScheduleApiResponse =
  /** status 204 No Content */ EntityIdentifierDto;
export type PostPromotionsV6PromotionByIdScheduleApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  schedulePromotionRequestDto: SchedulePromotionRequestDto;
};
export type PostPromotionsV6PromotionByIdPublishApiResponse =
  /** status 204 No Content */ void;
export type PostPromotionsV6PromotionByIdPublishApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdUnpublishApiResponse =
  /** status 204 No Content */ void;
export type PostPromotionsV6PromotionByIdUnpublishApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Language in which translated data should be provided */
  'Accept-Language'?: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdArchiveApiResponse =
  /** status 204 No Content */ void;
export type PostPromotionsV6PromotionByIdArchiveApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdDuplicateApiResponse =
  /** status 201 Created */ void;
export type PostPromotionsV6PromotionByIdDuplicateApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdPauseApiResponse =
  /** status 202 Accepted */ void;
export type PostPromotionsV6PromotionByIdPauseApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdResumeApiResponse =
  /** status 202 Accepted */ void;
export type PostPromotionsV6PromotionByIdResumeApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdRollbackApiResponse =
  /** status 202 Accepted */ void;
export type PostPromotionsV6PromotionByIdRollbackApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdRestoreApiResponse =
  /** status 204 No Content */ void;
export type PostPromotionsV6PromotionByIdRestoreApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionBatchApiResponse =
  /** status 200 Success */ PromotionBatchOperationResponseDto;
export type PostPromotionsV6PromotionBatchApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  promotionBatchOperationRequestDto: PromotionBatchOperationRequestDto;
};
export type PostPromotionsV6PromotionApiResponse =
  /** status 200 Success */ CreatePromotionResultDto;
export type PostPromotionsV6PromotionApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  createPromotionRequestDto: CreatePromotionRequestDto;
};
export type PostPromotionsV6PromotionTestApiResponse =
  /** status 200 Success */ CreatePromotionResultDto;
export type PostPromotionsV6PromotionTestApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  createTestPromotionRequestDto: CreateTestPromotionRequestDto;
};
export type PutPromotionsV6PromotionByIdDetailsApiResponse =
  /** status 204 No Content */ void;
export type PutPromotionsV6PromotionByIdDetailsApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  updatePromotionDetailsRequestDto: UpdatePromotionDetailsRequestDto;
};
export type GetPromotionsV6PromotionByIdTestApiResponse =
  /** status 200 Success */ GeneratePromotionTestLinkResponseDto;
export type GetPromotionsV6PromotionByIdTestApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdDiscardUnpublishedApiResponse =
  /** status 204 No Content */ void;
export type PostPromotionsV6PromotionByIdDiscardUnpublishedApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6PromotionOffersByIdApiResponse =
  /** status 200 Success */ GetPromotionOfferResponseDto;
export type GetPromotionsV6PromotionOffersByIdApiArg = {
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutPromotionsV6PromotionOffersByIdApiResponse =
  /** status 204 No Content */ void;
export type PutPromotionsV6PromotionOffersByIdApiArg = {
  /** Identifier of offer */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body:
    | SaveOfferRequestBase
    | SaveOfferRequestDtoOfferBogoDto
    | SaveOfferRequestDtoOfferBundleOrderDiscountDto
    | SaveOfferRequestDtoOfferCrossSellDto
    | SaveOfferRequestDtoOfferFreeShippingDto
    | SaveOfferRequestDtoOfferFrequentlyBoughtTogetherDto
    | SaveOfferRequestDtoOfferGiftDto
    | SaveOfferRequestDtoOfferMultiTierBogoDto
    | SaveOfferRequestDtoOfferOrderBumpDto
    | SaveOfferRequestDtoOfferSalesDto
    | SaveOfferRequestDtoOfferShopifyDiscountCodeDto
    | SaveOfferRequestDtoOfferTieredSpendXGetYDto
    | SaveOfferRequestDtoOfferVolumeDiscountDto;
};
export type GetPromotionsV6PromotionOffersTemplateApiResponse =
  /** status 200 Success */ GetPromotionOfferTemplateResponseDto;
export type GetPromotionsV6PromotionOffersTemplateApiArg = {
  /** Type of the offer */
  type: OfferTypeDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6PromotionOffersTemplateListApiResponse =
  /** status 200 Success */ GetOfferTemplateListResponseDto;
export type GetPromotionsV6PromotionOffersTemplateListApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6PromotionByIdOffersTemplateListApiResponse =
  /** status 200 Success */ GetOfferTemplateListResponseDto;
export type GetPromotionsV6PromotionByIdOffersTemplateListApiArg = {
  /** Identifier of promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetPromotionsV6PromotionByIdOffersTemplateListAndOfferIdApiResponse =
  /** status 200 Success */ GetOfferTemplateListResponseDto;
export type GetPromotionsV6PromotionByIdOffersTemplateListAndOfferIdApiArg = {
  /** Identifier of promotion */
  id: string;
  /** Identifier of offer */
  offerId: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type DeletePromotionsV6PromotionByIdOffersAndOfferIdApiResponse =
  /** status 204 No Content */ void;
export type DeletePromotionsV6PromotionByIdOffersAndOfferIdApiArg = {
  /** Identifier of promotion */
  id: string;
  /** Identifier of offer */
  offerId: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdOffersAndOfferIdDuplicateApiResponse =
  /** status 200 Success */ EntityIdentifierDto;
export type PostPromotionsV6PromotionByIdOffersAndOfferIdDuplicateApiArg = {
  /** Identifier of promotion */
  id: string;
  /** Identifier of offer */
  offerId: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutPromotionsV6PromotionOffersByIdTypeApiResponse =
  /** status 204 No Content */ void;
export type PutPromotionsV6PromotionOffersByIdTypeApiArg = {
  /** Identifier of offer */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  changeOfferTypeRequestDto: ChangeOfferTypeRequestDto;
};
export type PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsSummaryApiResponse =
  /** status 200 Success */ OfferCombinationsDetailsDto;
export type PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsSummaryApiArg =
  {
    /** Identifier of promotion */
    id: string;
    /** Identifier of offer */
    offerId: string;
    /** Language in which translated data should be provided */
    'Accept-Language'?: string;
    /** Name of the app to authenticate against */
    'X-LimoniApps-AppName': string;
    /** Secret of the app */
    'X-LimoniApps-AppSecret': string;
    excludedCombinationsDto: ExcludedCombinationsDto;
  };
export type PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOffersApiResponse =
  /** status 200 Success */ PagedResponseDtoOfferSelectableCombinationListDtoGuid;
export type PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOffersApiArg =
  {
    /** Identifier of promotion */
    id: string;
    /** Identifier of offer */
    offerId: string;
    /** Language in which translated data should be provided */
    'Accept-Language'?: string;
    /** Name of the app to authenticate against */
    'X-LimoniApps-AppName': string;
    /** Secret of the app */
    'X-LimoniApps-AppSecret': string;
    getSelectableCombinationsListRequestDto: GetSelectableCombinationsListRequestDto;
  };
export type PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOfTypeApiResponse =
  /** status 200 Success */ PagedResponseDtoOfferCombinationListDto;
export type PostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOfTypeApiArg =
  {
    /** Identifier of offer */
    offerId: string;
    id: string;
    /** Language in which translated data should be provided */
    'Accept-Language'?: string;
    /** Name of the app to authenticate against */
    'X-LimoniApps-AppName': string;
    /** Secret of the app */
    'X-LimoniApps-AppSecret': string;
    getOfferCombinationsListRequestDto: GetOfferCombinationsListRequestDto;
  };
export type GetPromotionsV6PromotionByIdSettingsApiResponse =
  /** status 200 Success */ PromotionSettingsDto;
export type GetPromotionsV6PromotionByIdSettingsApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PutPromotionsV6PromotionByIdSettingsApiResponse =
  /** status 200 Success */ CreatePromotionResultDto;
export type PutPromotionsV6PromotionByIdSettingsApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  promotionSettingsDto: PromotionSettingsDto;
};
export type PutPromotionsV6PromotionByIdSettingsTriggerTypeApiResponse =
  /** status 204 No Content */ void;
export type PutPromotionsV6PromotionByIdSettingsTriggerTypeApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  updatePromotionTriggerTypeRequestDto: UpdatePromotionTriggerTypeRequestDto;
};
export type PutPromotionsV6PromotionByIdSettingsBudgetApiResponse =
  /** status 204 No Content */ void;
export type PutPromotionsV6PromotionByIdSettingsBudgetApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  triggerMaximumUsesFilterDto: TriggerMaximumUsesFilterDto;
};
export type PostPromotionsV6PromotionByIdSettingsDiscountLinkPreviewApiResponse =
  /** status 200 Success */ TriggerDiscountLinkPreviewDto;
export type PostPromotionsV6PromotionByIdSettingsDiscountLinkPreviewApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  triggerDiscountLinkDto: TriggerDiscountLinkDto;
};
export type GetPromotionsV6PromotionByIdSettingsDiscountLinkListApiResponse =
  /** status 200 Success */ GetPromotionDiscountLinksResponseDto;
export type GetPromotionsV6PromotionByIdSettingsDiscountLinkListApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostPromotionsV6PromotionByIdSettingsDiscountLinkApiResponse =
  /** status 201 Created */ TriggerDiscountLinkDto;
export type PostPromotionsV6PromotionByIdSettingsDiscountLinkApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  triggerDiscountLinkDto: TriggerDiscountLinkDto;
};
export type PutPromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdApiResponse =
  /** status 200 Success */ TriggerDiscountLinkDto;
export type PutPromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdApiArg =
  {
    /** Identifier of the promotion */
    id: string;
    /** Identifier of the discount link */
    discountLinkId: string;
    /** Name of the app to authenticate against */
    'X-LimoniApps-AppName': string;
    /** Secret of the app */
    'X-LimoniApps-AppSecret': string;
    triggerDiscountLinkDto: TriggerDiscountLinkDto;
  };
export type DeletePromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdApiResponse =
  /** status 204 No Content */ void;
export type DeletePromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdApiArg =
  {
    /** Identifier of the promotion */
    id: string;
    /** Identifier of the discount link */
    discountLinkId: string;
    /** Name of the app to authenticate against */
    'X-LimoniApps-AppName': string;
    /** Secret of the app */
    'X-LimoniApps-AppSecret': string;
  };
export type PostPromotionsV6PromotionByIdSettingsPromotionCodeListApiResponse =
  /** status 200 Success */ GetPromotionCodesForPromotionResponseDto;
export type PostPromotionsV6PromotionByIdSettingsPromotionCodeListApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  getPromotionCodesForPromotionRequestDto: GetPromotionCodesForPromotionRequestDto;
};
export type PostPromotionsV6PromotionByIdSettingsPromotionCodeGenerateApiResponse =
  /** status 204 No Content */ void;
export type PostPromotionsV6PromotionByIdSettingsPromotionCodeGenerateApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  generatePromotionCodesRequestDto: GeneratePromotionCodesRequestDto;
};
export type PutPromotionsV6PromotionByIdSettingsPromotionCodeBatchApiResponse =
  /** status 200 Success */ void | /** status 204 No Content */ void;
export type PutPromotionsV6PromotionByIdSettingsPromotionCodeBatchApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  batchPromotionCodesOperationRequestDto: BatchPromotionCodesOperationRequestDto;
};
export type PostPromotionsV6PromotionByIdSettingsPromotionCodeExportApiResponse =
  /** status 200 Success */ void;
export type PostPromotionsV6PromotionByIdSettingsPromotionCodeExportApiArg = {
  /** Identifier of the promotion */
  id: string;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  getPromotionCodesForPromotionRequestDto: GetPromotionCodesForPromotionRequestDto;
};
export type PostPromotionsV6PromotionByIdSettingsPromotionCodeImportApiResponse =
  /** status 204 No Content */ void;
export type PostPromotionsV6PromotionByIdSettingsPromotionCodeImportApiArg = {
  /** Identifier of the promotion */
  id: string;
  overwriteExistingCodes?: boolean;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: {
    file?: Blob;
  };
};
export type GetPromotionsV6VersionApiResponse = unknown;
export type GetPromotionsV6VersionApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
};
export type CountryLookupDto = {
  id?: number;
  code?: string;
  name?: string;
};
export type ShippingCountryProvinceLookupDto = {
  id?: string;
  code?: string;
  name?: string;
};
export type ShippingCountryLookupDto = {
  id?: string;
  code?: string;
  name?: string;
  provinces?: ShippingCountryProvinceLookupDto[];
};
export type ReferrerLookupDto = {
  id?: number;
  name?: string;
};
export type ShopifyDetailedObjectVariantProductDto = {
  id?: string;
};
export type ShopifyDetailedObjectVariantDto = {
  id?: string;
  title?: string;
  imageUrl?: string | null;
  product?: ShopifyDetailedObjectVariantProductDto;
  salesChannels?: string[];
};
export type ShopifyProductStatusDto = ('ACTIVE' | 'ARCHIVED' | 'DRAFT') | null;
export type ShopifyDetailedObjectDto = {
  id?: string;
  title?: string;
  handle?: string;
  imageUrl?: string | null;
  featuredImageUrl?: string | null;
  variants?: ShopifyDetailedObjectVariantDto[] | null;
  salesChannels?: string[];
  status?: ShopifyProductStatusDto;
};
export type PromotionsValidationErrorCode =
  | (
      | 'TAG_INVALID'
      | 'PROMOTION_TOKEN_ALREADY_TAKEN'
      | 'TRIGGER_INTENDED_AUDIENCE_TAGS_ARE_MISSING'
      | 'TRIGGER_MAXIMUM_USES_LIMITED_USE_BY_ALL_HAS_INVALID_VALUE'
      | 'TRIGGER_MAXIMUM_USES_LIMITED_USE_BY_ALL_IS_MISSING'
      | 'TRIGGER_EXCLUSIVITY_PRIORITY_IS_MISSING'
      | 'TRIGGER_EXCLUSIVITY_PRIORITY_IS_INVALID'
      | 'TRIGGER_GEOLOCATION_FILTER_COUNTRIES_ARE_MISSING'
      | 'TRIGGER_REFERRER_FILTER_REFERRERS_ARE_MISSING'
      | 'TRIGGER_REFERRER_FILTER_DOMAIN_NAMES_ARE_MISSING'
      | 'TRIGGER_VISITOR_BEHAVIOR_TIME_SPENT_ON_PAGE_HAS_INVALID_VALUE'
      | 'TRIGGER_VISITOR_BEHAVIOR_NUMBER_OF_PAGES_VISITED_HAS_INVALID_VALUE'
      | 'TRIGGER_VISITOR_BEHAVIOR_PERCENTAGE_OF_PAGE_VIEWED_HAS_INVALID_VALUE'
      | 'TRIGGER_VISITOR_BEHAVIOR_AT_LEAST_ONE_IS_MISSING'
      | 'TRIGGER_DISCOUNT_LINK_BLOG_POST_IS_MISSING'
      | 'TRIGGER_DISCOUNT_LINK_BLOG_POST_BLOG_IS_MISSING'
      | 'TRIGGER_DISCOUNT_LINK_BLOG_POST_BLOG_IS_INVALID'
      | 'TRIGGER_DISCOUNT_LINK_BLOG_POST_POST_IS_MISSING'
      | 'TRIGGER_DISCOUNT_LINK_BLOG_POST_POST_IS_INVALID'
      | 'TRIGGER_DISCOUNT_LINK_PRODUCT_IS_MISSING'
      | 'TRIGGER_DISCOUNT_LINK_COLLECTION_IS_MISSING'
      | 'TRIGGER_DISCOUNT_LINK_CUSTOM_PAGE_IS_MISSING'
      | 'TRIGGER_DISCOUNT_LINK_CUSTOM_PAGE_IS_INVALID'
      | 'TRIGGER_DISCOUNT_LINK_UTM_PARAMETERS_OPTION_IS_MISSING'
      | 'TRIGGER_DISCOUNT_LINK_VANITY_LINK_OPTION_IS_MISSING'
      | 'TRIGGER_DISCOUNT_LINK_MISSING_VANITY_LINK_PATH'
      | 'TRIGGER_DISCOUNT_LINK_INVALID_VANITY_LINK_PATH'
      | 'OFFER_TYPE_NOT_SUPPORTED'
      | 'OFFER_SALES_TYPE_NOT_CONSISTENT_WITH_OFFER_TYPE'
      | 'OFFER_SALES_PERCENTAGE_INVALID_VALUE'
      | 'OFFER_SALES_FIXED_AMOUNT_INVALID_VALUE'
      | 'OFFER_SALES_FIXED_PRICE_INVALID_VALUE'
      | 'OFFER_PRODUCTS_APPLIED_NOT_ENOUGH_COLLECTIONS_PROVIDED'
      | 'OFFER_PRODUCTS_APPLIED_NOT_ENOUGH_PRODUCTS_PROVIDED'
      | 'OFFER_PRODUCTS_APPLIED_NOT_ENOUGH_VARIANTS_PROVIDED'
      | 'OFFER_PRODUCTS_LIMIT_PER_ORDER_INVALID_VALUE'
      | 'OFFER_PRODUCTS_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED'
      | 'OFFER_CART_CONTENT_RULE_AT_LEAST_INVALID_VALUE'
      | 'OFFER_CART_CONTENT_RULE_AT_MOST_INVALID_VALUE'
      | 'OFFER_CART_CONTENT_RULE_BETWEEN_INVALID_VALUE'
      | 'OFFER_CART_CONTENT_RULE_NOT_ENOUGH_COLLECTIONS_PROVIDED'
      | 'OFFER_CART_CONTENT_RULE_NOT_ENOUGH_PRODUCTS_PROVIDED'
      | 'OFFER_CART_CONTENT_RULE_NOT_ENOUGH_VARIANTS_PROVIDED'
      | 'OFFER_CART_CONTENT_RULE_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED'
      | 'OFFER_PURCHASE_REQUIREMENTS_CART_VALUE_INVALID_AMOUNT_OF_RULES'
      | 'OFFER_CART_VALUE_RULE_AT_LEAST_INVALID_VALUE'
      | 'OFFER_CART_VALUE_RULE_BETWEEN_INVALID_VALUE'
      | 'OFFER_CART_VALUE_RULE_AT_MOST_INVALID_VALUE'
      | 'OFFER_CART_VALUE_RULE_NOT_ENOUGH_COLLECTIONS_PROVIDED'
      | 'OFFER_CART_VALUE_RULE_NOT_ENOUGH_PRODUCTS_PROVIDED'
      | 'OFFER_CART_VALUE_RULE_NOT_ENOUGH_VARIANTS_PROVIDED'
      | 'OFFER_CART_VALUE_RULE_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED'
      | 'OFFER_EXCEPTIONS_CART_CONTAINS_BEHAVIOR_VALUE_IS_MISSING'
      | 'OFFER_CART_RULES_INVALID_AMOUNT_OF_RULES'
      | 'OFFER_CART_RULES_DUPLICATES_ARE_NOT_ACCEPTED'
      | 'OFFER_SHOPIFY_DISCOUNT_CODE_CODE_NOT_FOUND'
      | 'OFFER_SHOPIFY_DISCOUNT_CODE_GROUP_NOT_FOUND'
      | 'OFFER_SHOPIFY_DISCOUNT_CODE_APP_NOT_FOUND'
      | 'OFFER_COMBINATIONS_NOT_SUPPORTED'
      | 'LOOKUP_SHOPIFY_OBJECT_TYPE_NOT_SUPPORTED'
      | 'LOOKUP_SHOPIFY_OBJECT_AT_LEAST_ONE_REQUIRED'
      | 'ACCEPT_LANGUAGE_INVALID'
      | 'OFFER_TIERS_INVALID_AMOUNT'
      | 'OFFER_TIERS_INVALID_REQUIREMENT_VALUE'
      | 'OFFER_TIERS_INVALID_ENTITLED_VALUE'
      | 'OFFER_TIERS_INVALID_AFFECTED_ITEMS_VALUE'
      | 'OFFER_TIERS_HIGHER_DOES_NOT_HAVE_GREATER_REQUIREMENT_VALUE'
      | 'OFFER_GIFT_AMOUNT_INVALID_VALUE'
      | 'OFFER_GIFT_LIMIT_INVALID_VALUE'
      | 'OFFER_GIFT_LIMIT_IS_NOT_GREATER_OR_EQUAL_AMOUNT_VALUE'
      | 'OFFER_FREE_SHIPPING_FLAT_FEE_SHIPPING_RATE_INVALID'
      | 'OFFER_FREE_SHIPPING_SHIPPING_COUNTRIES_ARE_MISSING'
      | 'OFFER_FREE_SHIPPING_SHIPPING_RATE_THRESHOLD_INVALID'
      | 'OFFER_BOGO_CUSTOM_RULE_INVALID_REQUIRED_AMOUNT'
      | 'OFFER_BOGO_CUSTOM_RULE_INVALID_AMOUNT_OF_PRODUCTS'
      | 'OFFER_BOGO_CUSTOM_RULE_INVALID_FIXED_AMOUNT'
      | 'OFFER_BOGO_CUSTOM_RULE_INVALID_PERCENTAGE'
      | 'OFFER_BOGO_INVALID_LIMIT'
      | 'OFFER_CROSS_SELL_RULE_INVALID_COUNT'
      | 'OFFER_CROSS_SELL_RULE_INVALID_PERCENTAGE'
      | 'OFFER_CROSS_SELL_RULE_INVALID_FIXED_AMOUNT'
      | 'OFFER_CROSS_SELL_RULE_AT_LEAST_INVALID_VALUE'
      | 'OFFER_CROSS_SELL_RULE_AT_MOST_INVALID_VALUE'
      | 'OFFER_CROSS_SELL_RULE_BETWEEN_INVALID_VALUE'
      | 'OFFER_CROSS_SELL_RULE_NOT_ENOUGH_COLLECTIONS_PROVIDED'
      | 'OFFER_CROSS_SELL_RULE_NOT_ENOUGH_PRODUCTS_PROVIDED'
      | 'OFFER_CROSS_SELL_RULE_NOT_ENOUGH_VARIANTS_PROVIDED'
      | 'OFFER_CROSS_SELL_RULE_EXCLUSIONS_NOT_ENOUGH_COLLECTIONS_PROVIDED'
      | 'OFFER_SUMMARY_NOT_SUPPORTED'
      | 'OFFER_TYPE_LIMIT_EXCEEDED'
      | 'OFFER_FREE_SHIPPING_SHIPPING_RATE_NAME_INVALID'
      | 'TRIGGER_DISCOUNT_LINK_VANITY_LINK_PATH_ALREADY_USED'
      | 'TRIGGER_DISCOUNT_LINK_UTM_PARAMETERS_EXCEEDS_MAX_LENGTH'
      | 'TRIGGER_DISCOUNT_LINK_VANITY_LINK_EXCEEDS_MAX_LENGTH'
      | 'OFFER_SUGGESTED_RULES_MISSING_SUGGESTED_PRODUCTS'
      | 'OFFER_SUGGESTED_RULES_SUGGESTED_ITEMS_LIMIT_EXCEEDED'
      | 'OFFER_SUGGESTED_RULES_PRIORITIES_INVALID_RULES'
      | 'OFFER_SUGGESTED_RULES_LIMIT_EXCEEDED'
      | 'OFFER_SUGGESTED_RULES_ONLY_CART_CONTENT_RULES_ARE_ALLOWED'
      | 'OFFER_SUGGESTED_RULES_PRIORITIES_DUPLICATED_PRIORITY_VALUE'
      | 'OFFER_SUGGESTED_RULES_INVALID_AMOUNT_OF_CONDITIONS'
      | 'OFFER_SUGGESTED_RULES_INVALID_AMOUNT_OF_GROUPS'
      | 'OFFER_SUGGESTED_RULES_AT_LEAST_ONE_RULE_NEEDS_TO_BE_ENABLED'
      | 'PROMOTION_NAME_CANNOT_BE_EMPTY'
      | 'PROMOTION_TOKEN_INVALID'
      | 'OFFER_NOT_SUPPORTED_IN_GIVEN_PROMOTION_TYPE'
      | 'TRIGGER_VISITOR_BEHAVIOR_VALUE_MISSING'
      | 'TRIGGER_MAXIMUM_USES_VALUE_MISSING'
      | 'TRIGGER_EXCLUSIVITY_VALUE_MISSING'
      | 'TRIGGER_GEOLOCATION_FILTER_VALUE_MISSING'
      | 'TRIGGER_REFERRER_FILTER_VALUE_MISSING'
      | 'PROMOTION_SETTINGS_CANNOT_BE_EDITED'
      | 'PROMOTION_TRIGGER_TYPE_NOT_SUPPORTED'
      | 'PROMOTION_BATCH_OPERATION_MISSING_PROMOTIONS'
      | 'PROMOTION_GET_OPERATION_PROGRESS_MISSING_PROMOTIONS'
      | 'PROMOTION_SCHEDULE_START_DATE_CANNOT_BE_REMOVED'
      | 'PROMOTION_SCHEDULE_INVALID_START_DATE'
      | 'PROMOTION_SCHEDULE_INVALID_END_DATE'
      | 'OFFER_CANNOT_BE_DUPLICATED_DUE_TO_TYPE_LIMIT'
      | 'OFFER_COMBINATIONS_MISSING_SETUP'
      | 'OFFER_SUGGESTED_RULES_NOT_SUPPORTED'
      | 'OFFER_SUGGESTED_RULES_MISSING_AI_RULE_ENABLED_STATE'
      | 'OFFER_SUGGESTED_RULES_PRIORITIES_MISSING'
      | 'TRIGGER_MAXIMUM_USES_ORDER_CAP_HAS_INVALID_VALUE'
      | 'TRIGGER_COUNTDOWN_CLOCK_FIXED_CLOCK_TIME_MISSING'
      | 'TIMESPAN_INVALID'
      | 'TRIGGER_COUNTDOWN_CLOCK_VALUE_MISSING'
      | 'TRIGGER_PROMOTION_CODES_GENERATION_SETUP_MISSING'
      | 'TRIGGER_PROMOTION_CODES_INVALID_MAXIMUM_USES'
      | 'TRIGGER_PROMOTION_CODES_INVALID_MANUAL_CODES_AMOUNT'
      | 'TRIGGER_PROMOTION_CODES_LIMIT_REACHED'
      | 'TRIGGER_PROMOTION_CODES_CODE_ALREADY_EXISTS'
      | 'TRIGGER_PROMOTION_CODES_INVALID_AUTOMATIC_CODES_AMOUNT'
      | 'TRIGGER_PROMOTION_CODES_FILE_MISSING'
      | 'TRIGGER_PROMOTION_CODES_FILE_INVALID_TYPE'
      | 'TRIGGER_PROMOTION_CODES_FILE_BROKEN'
      | 'TRIGGER_PROMOTION_CODES_INVALID'
      | 'OFFER_MINIMUM_SPEND_VALUE_MISSING'
      | 'OFFER_MINIMUM_SPEND_VALUE_INVALID'
      | 'OFFER_DISCOUNT_LIMIT_LIMIT_VALUE_INVALID'
      | 'OFFER_GIFT_MISSING_COLLECTIONS'
      | 'OFFER_GIFT_MISSING_PRODUCTS'
      | 'OFFER_GIFT_MISSING_PRODUCT_VARIANTS'
      | 'OFFER_GIFT_RULE_MISSING'
      | 'TRIGGER_PROMOTION_CODES_INVALID_PREFIX_OR_SUFFIX'
      | 'OFFER_ORDER_DISCOUNT_INVALID_VALUE'
      | 'OFFER_CART_CONTENT_RULE_EXACTLY_INVALID_VALUE'
      | 'OFFER_CROSS_SELL_RULE_EXACTLY_INVALID_VALUE'
      | 'OFFER_CART_VALUE_RULE_EXACTLY_INVALID_VALUE'
      | 'OFFER_CART_RULES_INVALID_AMOUNT_OF_CONNECTORS'
      | 'OFFER_CART_CONTENT_RULE_INVALID_NAME_FILTER_LENGTH'
      | 'OFFER_CART_VALUE_RULE_MISSING_COMPARE_AT_PRICE_SETTING'
      | 'OFFER_CART_VALUE_RULE_INVALID_NAME_FILTER_LENGTH'
      | 'OFFER_PRODUCTS_INVALID_NAME_FILTER_LENGTH'
      | 'OFFER_PRODUCTS_MISSING_COMPARE_AT_PRICE_SETTING'
      | 'PROMOTION_CANNOT_BE_EDITED'
      | 'PROMOTION_CANNOT_BE_PUBLISHED_MISSING_OFFERS'
      | 'OFFER_FREE_SHIPPING_SHIPPING_RATES_MISSING'
      | 'PROMOTION_CANNOT_BE_PUBLISHED_THRESHOLD_REACHED'
      | 'TRIGGER_MARKET_FILTER_VALUE_MISSING'
      | 'TRIGGER_MARKET_FILTER_INVALID_AMOUNT'
      | 'TRIGGER_PURCHASE_HISTORY_FILTER_VALUE_MISSING'
      | 'TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_MISSING_FROM'
      | 'TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_INVALID_FROM'
      | 'TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_MISSING_TO'
      | 'TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_INVALID_TO'
      | 'TRIGGER_PURCHASE_HISTORY_FILTER_ORDERS_PLACED_INVALID_BETWEEN'
      | 'PROMOTIONS_IMPORT_FILE_BROKEN'
      | 'OFFER_DISCOUNT_LIMIT_CAP_VALUE_INVALID'
      | 'PROMOTION_SCHEDULE_CANNOT_BE_SET_FOR_SHOPIFY_DISCOUNT_CODE_PROMOTION'
      | 'OFFER_GIFT_INVALID_DEFAULT_GIFT_RULE'
      | 'APP_EMBED_IS_DISABLED'
    )
  | null;
export type ValidationFailedResponseDto = {
  errors?: PromotionsValidationErrorCode[];
};
export type ValidationFailedResponseDtoRead = {
  errors?: PromotionsValidationErrorCode[];
  relatedItems?: string[];
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type ShopifyObjectTypeDto =
  | ('COLLECTION' | 'PRODUCT' | 'PRODUCT_VARIANT')
  | null;
export type ShopifyObjectDto = {
  id?: string;
  productId?: string | null;
};
export type Query = {
  type?: ShopifyObjectTypeDto;
  objects?: ShopifyObjectDto[];
};
export type SmartTagType =
  | (
      | 'DISCOUNT_CODE'
      | 'DISCOUNT'
      | 'DISCOUNT_PERCENTAGE'
      | 'DISCOUNT_PERCENTAGE_TIER1'
      | 'DISCOUNT_PERCENTAGE_TIER2'
      | 'DISCOUNT_PERCENTAGE_TIER3'
      | 'DISCOUNT_PERCENTAGE_TIER4'
      | 'DISCOUNT_PERCENTAGE_TIER5'
      | 'DISCOUNT_PERCENTAGE_HIGHEST_TIER'
      | 'DISCOUNT_AMOUNT'
      | 'DISCOUNT_AMOUNT_TIER1'
      | 'DISCOUNT_AMOUNT_TIER2'
      | 'DISCOUNT_AMOUNT_TIER3'
      | 'DISCOUNT_AMOUNT_TIER4'
      | 'DISCOUNT_AMOUNT_TIER5'
      | 'DISCOUNT_AMOUNT_HIGHEST_TIER'
      | 'MAXIMUM_DISCOUNT'
      | 'TOTAL_DISCOUNT_PERCENTAGE'
      | 'TOTAL_DISCOUNT_AMOUNT'
      | 'OFFER_TOTAL_DISCOUNT_PERCENTAGE'
      | 'OFFER_TOTAL_DISCOUNT_AMOUNT'
      | 'DISCOUNTED_PRICE'
      | 'DISCOUNTED_PRICE_TIER1'
      | 'DISCOUNTED_PRICE_TIER2'
      | 'DISCOUNTED_PRICE_TIER3'
      | 'DISCOUNTED_PRICE_TIER4'
      | 'DISCOUNTED_PRICE_TIER5'
      | 'DISCOUNTED_PRICE_HIGHEST_TIER'
      | 'ORIGINAL_PRICE'
      | 'ORIGINAL_COMPAREAT_PRICE'
      | 'FIXED_PRICE'
      | 'FIXED_PRICE_UNITS'
      | 'MINIMUM_CART_VALUE'
      | 'MAXIMUM_CART_VALUE'
      | 'MINIMUM_CART_PRODUCTS'
      | 'MINIMUM_CART_PRODUCTS_INCLUDING_ENTITLED'
      | 'MAXIMUM_CART_PRODUCTS'
      | 'AMOUNT_LEFT_BEFORE_DISCOUNT'
      | 'PRODUCTS_LEFT_BEFORE_DISCOUNT'
      | 'PRODUCTS_LEFT_BEFORE_DISCOUNT_INCLUDING_ENTITLED'
      | 'NEXT_GOAL_DISCOUNT'
      | 'NEXT_GOAL_DISCOUNT_PERCENTAGE'
      | 'NEXT_GOAL_DISCOUNT_AMOUNT'
      | 'STARTS_AT_DATE'
      | 'START_AT_TIME'
      | 'EXPIRES_AT_DATE'
      | 'EXPIRES_AT_TIME'
      | 'ENDS_AT_DATE'
      | 'ENDS_AT_TIME'
      | 'TIMER'
      | 'COUNTRY'
      | 'MAXIMUM_DISCOUNTED_ITEMS'
      | 'MAXIMUM_GIFTS'
      | 'MINIMUM_PREREQUISITE_PRODUCTS'
      | 'MINIMUM_PREREQUISITE_PRODUCTS_TIER1'
      | 'MINIMUM_PREREQUISITE_PRODUCTS_TIER2'
      | 'MINIMUM_PREREQUISITE_PRODUCTS_TIER3'
      | 'MINIMUM_PREREQUISITE_PRODUCTS_TIER4'
      | 'MINIMUM_PREREQUISITE_PRODUCTS_TIER5'
      | 'MAXIMUM_PREREQUISITE_PRODUCTS'
      | 'MAXIMUM_PREREQUISITE_PRODUCTS_TIER1'
      | 'MAXIMUM_PREREQUISITE_PRODUCTS_TIER2'
      | 'MAXIMUM_PREREQUISITE_PRODUCTS_TIER3'
      | 'MAXIMUM_PREREQUISITE_PRODUCTS_TIER4'
      | 'MAXIMUM_PREREQUISITE_PRODUCTS_TIER5'
      | 'DISCOUNTED_INSTALLMENTS_4PERIODS'
      | 'DISCOUNTED_INSTALLMENTS_3PERIODS'
      | 'DISCOUNTED_INSTALLMENTS_4PERIODS_TOTAL'
      | 'DISCOUNTED_INSTALLMENTS_3PERIODS_TOTAL'
      | 'REMAINING_DISCOUNT_CODES'
      | 'TOTAL_DISCOUNT_CODES'
      | 'USED_DISCOUNT_CODES'
    )
  | null;
export type SmartTagGroup =
  | (
      | 'DISCOUNT'
      | 'PRICE'
      | 'GOALS'
      | 'TIME'
      | 'LINK'
      | 'LIMITS'
      | 'INSTALLMENTS'
      | 'DISCOUNT_CODES'
      | 'OTHER'
    )
  | null;
export type SmartTagValueType =
  | ('MONEY' | 'DATE' | 'TEXT' | 'PERCENTAGE' | 'NUMBER' | 'URL')
  | null;
export type SmartTagMoneyValue = {
  amount?: number;
  currencyCode?: string;
  currencySymbol?: string;
  type?: SmartTagValueType;
};
export type SmartTagDateValue = {
  date?: number;
  type?: SmartTagValueType;
};
export type SmartTagTextValue = {
  text?: string;
  type?: SmartTagValueType;
};
export type SmartTagPercentageValue = {
  type?: SmartTagValueType;
  percentage?: number;
};
export type SmartTagNumberValue = {
  type?: SmartTagValueType;
  number?: number;
};
export type SmartTagUrlValue = {
  path?: string;
  type?: SmartTagValueType;
};
export type SmartTag = {
  id?: SmartTagType;
  category?: SmartTagGroup;
  valueType?: SmartTagValueType;
  moneyValue?: SmartTagMoneyValue;
  dateValue?: SmartTagDateValue;
  textValue?: SmartTagTextValue;
  percentageValue?: SmartTagPercentageValue;
  numberValue?: SmartTagNumberValue;
  urlValue?: SmartTagUrlValue;
};
export type OfferSmartTags = {
  offerId?: string;
  smartTags?: SmartTag[];
};
export type TagCategoryDto = ('PROMOTION' | 'CUSTOMER') | null;
export type ShippingRateLookupDto = {
  id?: string;
  title?: string;
  handle?: string;
};
export type MarketLookupDto = {
  id?: string;
  name?: string;
};
export type ShopifyCollectionAppBridgeResourceDto = {
  id?: string;
  title?: string;
  handle?: string;
  description?: string;
  descriptionHtml?: string;
  updatedAt?: number;
  productsCount?: number;
  templateSuffix?: string;
  imageUrl?: string | null;
};
export type ShopifyProductStatus = ('ACTIVE' | 'ARCHIVED' | 'DRAFT') | null;
export type ShopifyInventoryPolicy = ('CONTINUE' | 'DENY') | null;
export type ShopifyProductVariantProductRelationAppBridgeResourceDto = {
  id?: string;
};
export type ShopifyProductVariantSelectedOptionAppBridgeResourceDto = {
  value?: string;
};
export type ShopifyWeightUnit =
  | ('GRAMS' | 'KILOGRAMS' | 'OUNCES' | 'POUNDS')
  | null;
export type ShopifyProductVariantAppBridgeResourceDto = {
  id?: string;
  availableForSale?: boolean;
  barcode?: string;
  compareAtPrice?: number | null;
  createdAt?: number;
  displayName?: string;
  inventoryPolicy?: ShopifyInventoryPolicy;
  inventoryQuantity?: number;
  position?: number;
  price?: string;
  product?: ShopifyProductVariantProductRelationAppBridgeResourceDto;
  requiresShipping?: boolean;
  selectedOptions?:
    | ShopifyProductVariantSelectedOptionAppBridgeResourceDto[]
    | null;
  sku?: string;
  taxable?: boolean;
  title?: string;
  updatedAt?: number;
  weight?: number;
  weightUnit?: ShopifyWeightUnit;
  imageUrl?: string | null;
};
export type ShopifyProductAppBridgeResourceProductOptionDto = {
  id?: string;
  name?: string;
  position?: number;
  values?: string[];
};
export type ShopifyProductAppBridgeResourceDto = {
  id?: string;
  description?: string;
  descriptionHtml?: string;
  title?: string;
  handle?: string;
  tags?: string[];
  vendor?: string;
  status?: ShopifyProductStatus;
  createdAt?: number;
  updatedAt?: number;
  publishedAt?: number | null;
  featuredImageUrl?: string | null;
  hasOnlyDefaultVariant?: boolean;
  templateSuffix?: string;
  totalInventory?: number;
  tracksInventory?: boolean;
  variants?: ShopifyProductVariantAppBridgeResourceDto[];
  options?: ShopifyProductAppBridgeResourceProductOptionDto[];
};
export type ShopifyDiscountCodeTargetTypeDto =
  | ('LINE_ITEM' | 'SHIPPING_LINE')
  | null;
export type ShopifyDiscountCodeValueTypeDto =
  | ('FIXED_AMOUNT' | 'PERCENTAGE')
  | null;
export type OfferCombinationTypeDto = ('PRODUCT' | 'ORDER' | 'SHIPPING') | null;
export type ShopifyDiscountCodeLookupDto = {
  id?: string;
  title?: string;
  value?: number;
  targetType?: ShopifyDiscountCodeTargetTypeDto;
  valueType?: ShopifyDiscountCodeValueTypeDto;
  combinationType?: OfferCombinationTypeDto;
};
export type ShopifyDiscountCodeAppLookupDto = {
  id?: string;
  title?: string;
};
export type PromotionStatusDto =
  | (
      | 'PUBLISHED'
      | 'SCHEDULED'
      | 'EXPIRED'
      | 'DRAFT'
      | 'IN_PROGRESS_PUBLISHING'
      | 'IN_PROGRESS_UNPUBLISHING'
      | 'IN_PROGRESS_ROLLING_BACK'
      | 'USED'
      | 'ARCHIVED'
      | 'PUBLISHED_EDITED'
    )
  | null;
export type PromotionOverviewDetailsDto = {
  name?: string;
  token?: string;
  description?: string;
  tags?: string[];
};
export type PromotionTypeDto =
  | (
      | 'MARKDOWN'
      | 'SPECIAL_OFFER'
      | 'CROSS_SELL_UP_SELL'
      | 'SHOPIFY_DISCOUNT_CODE'
    )
  | null;
export type TriggerTypeDto = ('PUBLIC' | 'PRIVATE') | null;
export type OptionDtoInt64 = {
  enabled?: boolean;
  value?: number;
};
export type OptionDtoDecimal = {
  enabled?: boolean;
  value?: number;
};
export type TriggerOverviewMaximumUsesCodeLimitDto = {
  code?: string;
  limit?: number;
};
export type OptionDtoTriggerOverviewMaximumUsesCodeLimitDto = {
  enabled?: boolean;
  value?: TriggerOverviewMaximumUsesCodeLimitDto;
};
export type TriggerOverviewMaximumUsesDto = {
  limitedUseByAll?: OptionDtoInt64;
  orderCap?: OptionDtoDecimal;
  limitedUsePerCustomerEnabled?: boolean;
  limitDependsOnAppCode?: boolean;
  limitOfSingleUsePerCode?: boolean;
  limitOfCode?: OptionDtoTriggerOverviewMaximumUsesCodeLimitDto;
  limitReached?: boolean;
};
export type TriggerOverviewMaximumUsesDtoRead = {
  limitedUseByAll?: OptionDtoInt64;
  orderCap?: OptionDtoDecimal;
  limitedUsePerCustomerEnabled?: boolean;
  limitDependsOnAppCode?: boolean;
  limitOfSingleUsePerCode?: boolean;
  limitOfCode?: OptionDtoTriggerOverviewMaximumUsesCodeLimitDto;
  limitReached?: boolean;
  hasAnyLimits?: boolean;
};
export type TriggerCountdownClockEndModeDto =
  | ('PROMOTION_END' | 'FIXED')
  | null;
export type FixedClockTimeTypeDto = ('DAYS' | 'HOURS' | 'MINUTES') | null;
export type FixedClockTimeDto = {
  type?: FixedClockTimeTypeDto;
  value?: number;
};
export type PromotionOverviewCountdownClockDto = {
  mode?: TriggerCountdownClockEndModeDto;
  fixedClockTime?: FixedClockTimeDto;
};
export type TriggerDiscountLinkPreviewDto = {
  display?: string;
  copy?: string;
  count?: number;
};
export type PromotionSalesChannelDto = ('ALL' | 'ONLINE_STORE') | null;
export type PromotionOverviewPromotionCodesDto = {
  count?: number | null;
  singleCode?: string | null;
};
export type TriggerVisitorBehaviorCriteriaTypeDto =
  | ('ALL' | 'AT_LEAST_ONE')
  | null;
export type OptionDtoInt32 = {
  enabled?: boolean;
  value?: number;
};
export type TriggerVisitorBehaviorsDto = {
  criteriaType?: TriggerVisitorBehaviorCriteriaTypeDto;
  timeSpentOnPage?: OptionDtoInt32;
  numberOfPagesVisited?: OptionDtoInt32;
  percentageOfPageViewed?: OptionDtoInt32;
  exitIntentEnabled?: boolean;
};
export type OptionDtoTriggerVisitorBehaviorsDto = {
  enabled?: boolean;
  value?: TriggerVisitorBehaviorsDto;
};
export type FilterTypeDto = ('INCLUDED' | 'EXCLUDED') | null;
export type TriggerGeolocationFilterDto = {
  type?: FilterTypeDto;
  countries?: string[];
};
export type OptionDtoTriggerGeolocationFilterDto = {
  enabled?: boolean;
  value?: TriggerGeolocationFilterDto;
};
export type TriggerReferrerFilterDto = {
  type?: FilterTypeDto;
  referrers?: string[];
  domainNames?: string[];
};
export type OptionDtoTriggerReferrerFilterDto = {
  enabled?: boolean;
  value?: TriggerReferrerFilterDto;
};
export type TriggerMarketFilterDto = {
  type?: FilterTypeDto;
  marketIds?: string[];
};
export type OptionDtoTriggerMarketFilterDto = {
  enabled?: boolean;
  value?: TriggerMarketFilterDto;
};
export type TriggerPurchaseHistoryOrdersPlacedTypeDto =
  | ('ANY' | 'AT_LEAST' | 'AT_MOST' | 'BETWEEN')
  | null;
export type TriggerPurchaseHistoryFilterDto = {
  type?: TriggerPurchaseHistoryOrdersPlacedTypeDto;
  from?: number | null;
  to?: number | null;
};
export type TriggerIntendedAudienceTypeDto =
  | (
      | 'GUESTS_ONLY'
      | 'CUSTOMERS_ONLY'
      | 'GUESTS_AND_SELECTED_CUSTOMERS'
      | 'B2B_CUSTOMERS_ONLY'
    )
  | null;
export type IntendedAudienceTagTypeDto = ('ALL' | 'INCLUDE' | 'EXCLUDE') | null;
export type TriggerIntendedAudienceDto = {
  type?: TriggerIntendedAudienceTypeDto;
  tagType?: IntendedAudienceTagTypeDto;
  tags?: string[] | null;
};
export type TriggerCustomerFilterDto = {
  purchaseHistoryFilter?: TriggerPurchaseHistoryFilterDto;
  intendedAudienceFilter?: TriggerIntendedAudienceDto;
};
export type OptionDtoTriggerCustomerFilterDto = {
  enabled?: boolean;
  value?: TriggerCustomerFilterDto;
};
export type PromotionFiltersSettingsDto = {
  visitorBehavior?: OptionDtoTriggerVisitorBehaviorsDto;
  geolocationFilter?: OptionDtoTriggerGeolocationFilterDto;
  referrerFilter?: OptionDtoTriggerReferrerFilterDto;
  marketFilter?: OptionDtoTriggerMarketFilterDto;
  customerFilter?: OptionDtoTriggerCustomerFilterDto;
};
export type PromotionOverviewSettingsDto = {
  isReadOnly?: boolean;
  promotionType?: PromotionTypeDto;
  triggerType?: TriggerTypeDto;
  maximumUses?: TriggerOverviewMaximumUsesDto;
  exclusivityFilter?: number | null;
  countdownClock?: PromotionOverviewCountdownClockDto;
  discountLink?: TriggerDiscountLinkPreviewDto;
  shopifyDiscountCode?: string | null;
  shopifyDiscountGroup?: string | null;
  shopifyDiscountApp?: string | null;
  salesChannel?: PromotionSalesChannelDto;
  promotionCodes?: PromotionOverviewPromotionCodesDto;
  filters?: PromotionFiltersSettingsDto;
};
export type PromotionOverviewSettingsDtoRead = {
  isReadOnly?: boolean;
  promotionType?: PromotionTypeDto;
  triggerType?: TriggerTypeDto;
  maximumUses?: TriggerOverviewMaximumUsesDtoRead;
  exclusivityFilter?: number | null;
  countdownClock?: PromotionOverviewCountdownClockDto;
  discountLink?: TriggerDiscountLinkPreviewDto;
  shopifyDiscountCode?: string | null;
  shopifyDiscountGroup?: string | null;
  shopifyDiscountApp?: string | null;
  salesChannel?: PromotionSalesChannelDto;
  promotionCodes?: PromotionOverviewPromotionCodesDto;
  filters?: PromotionFiltersSettingsDto;
};
export type PromotionOverviewScheduleDto = {
  startsAt?: number | null;
  endsAt?: number | null;
};
export type PromotionOperationProgressDto = {
  id?: string;
  status?: PromotionStatusDto;
  estimatedTimeLeft?: number;
  progress?: number;
  isPaused?: boolean;
};
export type PlanLevelDto =
  | (
      | 'DEVELOPMENT'
      | 'STARTER_1'
      | 'STARTER_2'
      | 'STARTER_3'
      | 'STARTER_4'
      | 'PRO_1'
      | 'PRO_2'
      | 'PRO_3'
      | 'PRO_4'
      | 'PLUS_1'
      | 'PLUS_2'
      | 'PLUS_3'
      | 'PLUS_4'
      | 'ENTERPRISE'
    )
  | null;
export type PlanThresholdDetailsDto = {
  level?: PlanLevelDto;
  name?: string;
  maxPublishedPromotions?: number;
};
export type PlansDetailsDto = {
  currentPlan?: PlanThresholdDetailsDto;
  downgradedPlan?: PlanThresholdDetailsDto;
  suggestedUpgradePlan?: PlanThresholdDetailsDto;
  nextBillingCycleStartsAt?: number | null;
  publishedPromotionsLimitReached?: boolean;
};
export type PromotionOperationTypeDto =
  | (
      | 'PUBLISH'
      | 'UNPUBLISH'
      | 'CONFIRM_UNPUBLISHED_CHANGES'
      | 'DISCARD_UNPUBLISHED_CHANGES'
      | 'ARCHIVE'
      | 'DUPLICATE'
      | 'RESTORE'
      | 'DELETE'
      | 'MOVE_TO_DRAFT'
    )
  | null;
export type PromotionOverviewDto = {
  status?: PromotionStatusDto;
  details?: PromotionOverviewDetailsDto;
  settings?: PromotionOverviewSettingsDto;
  schedule?: PromotionOverviewScheduleDto;
  operationProgress?: PromotionOperationProgressDto;
  plans?: PlansDetailsDto;
  offerLimitReached?: boolean;
  timezone?: string;
  isTestingEnabled?: boolean;
  viewPromotionUrl?: string | null;
  isReadOnly?: boolean;
  shouldBeMovedToDraftForEditing?: boolean;
  createdAt?: number;
  modifiedAt?: number;
  publishedAt?: number | null;
  modifiedBy?: string;
  usedDate?: number | null;
  possibleOperations?: PromotionOperationTypeDto[];
  insightsAvailable?: boolean;
};
export type PromotionOverviewDtoRead = {
  status?: PromotionStatusDto;
  details?: PromotionOverviewDetailsDto;
  settings?: PromotionOverviewSettingsDtoRead;
  schedule?: PromotionOverviewScheduleDto;
  operationProgress?: PromotionOperationProgressDto;
  plans?: PlansDetailsDto;
  offerLimitReached?: boolean;
  timezone?: string;
  isTestingEnabled?: boolean;
  viewPromotionUrl?: string | null;
  isReadOnly?: boolean;
  shouldBeMovedToDraftForEditing?: boolean;
  createdAt?: number;
  modifiedAt?: number;
  publishedAt?: number | null;
  modifiedBy?: string;
  usedDate?: number | null;
  possibleOperations?: PromotionOperationTypeDto[];
  insightsAvailable?: boolean;
};
export type OfferTypeDto =
  | (
      | 'SALES_PERCENTAGE'
      | 'SALES_FIXED_AMOUNT'
      | 'SALES_FIXED_PRICE'
      | 'GIFT_AUTOMATIC'
      | 'GIFT_MANUAL'
      | 'CROSS_SELL'
      | 'TIERED_SPEND_X_GET_Y'
      | 'TIERED_BOGO'
      | 'BOGO_REGULAR'
      | 'FREE_SHIPPING'
      | 'SHIPPING_PERCENTAGE'
      | 'SHIPPING_FIXED_AMOUNT'
      | 'SHOPIFY_DISCOUNT_CODE_REGULAR'
      | 'SHOPIFY_DISCOUNT_CODE_GROUP'
      | 'SHOPIFY_DISCOUNT_CODE_APP'
      | 'VOLUME_DISCOUNT'
      | 'FREQUENTLY_BOUGHT_TOGETHER'
      | 'ORDER_BUMP'
      | 'BOGO_STRICT_MATCH'
      | 'BUNDLE_ORDER_DISCOUNT'
    )
  | null;
export type PromotionOverviewOfferDto = {
  id?: string;
  title?: string;
  token?: string;
  type?: OfferTypeDto;
  combinationType?: OfferCombinationTypeDto;
};
export type GetPromotionOverviewOffersResponseDto = {
  totalItems?: number;
  items?: PromotionOverviewOfferDto[];
  offerLimitReached?: boolean;
};
export type SortDirectionDto = ('ASC' | 'DESC') | null;
export type EntityIdentifierDto = {
  id?: string;
};
export type InitializeOfferRequestDto = {
  type?: OfferTypeDto;
};
export type PagedResponseDtoPromotionOverviewOfferDto = {
  totalItems?: number;
  items?: PromotionOverviewOfferDto[];
};
export type PromotionListItemDto = {
  id?: string;
  lastModifiedAt?: number;
  startsAt?: number | null;
  endsAt?: number | null;
  status?: PromotionStatusDto;
  type?: PromotionTypeDto;
  triggerType?: TriggerTypeDto;
  name?: string;
  tags?: string[];
  token?: string;
  offers?: PagedResponseDtoPromotionOverviewOfferDto;
  operationProgress?: PromotionOperationProgressDto;
  possibleOperations?: PromotionOperationTypeDto[];
};
export type GetPromotionsResponseDto = {
  totalItems?: number;
  items?: PromotionListItemDto[];
  isPromotionEngineEnabled?: boolean;
  timezone?: string;
  plans?: PlansDetailsDto;
};
export type PromotionListSortFieldDto =
  | (
      | 'NAME'
      | 'TOKEN'
      | 'STATUS'
      | 'SCHEDULE'
      | 'TRIGGER_TYPE'
      | 'PROMOTION_TYPE'
      | 'OFFERS'
      | 'MODIFICATION_DATE'
    )
  | null;
export type GetPromotionsRequestDto = {
  search?: string | null;
  page?: number;
  itemsPerPage?: number | null;
  sortDirection?: SortDirectionDto;
  selectedItems?: string[] | null;
  statuses?: PromotionStatusDto[] | null;
  tags?: string[] | null;
  sortBy?: PromotionListSortFieldDto;
};
export type ImportPromotionsResponseDto = {
  trackingId?: string;
};
export type TrackImportStatusResponseDto = {
  isFinished?: boolean;
};
export type GetPromotionOperationProgressRequestDto = {
  promotionIds?: string[];
};
export type OptionDtoNullableDateTime = {
  enabled?: boolean;
  value?: number | null;
};
export type SchedulePromotionRequestDto = {
  startDate?: OptionDtoNullableDateTime;
  endsDate?: OptionDtoNullableDateTime;
};
export type PromotionBatchOperationResponseDto = {
  promotions?: PromotionListItemDto[];
  operationType?: PromotionOperationTypeDto;
};
export type PromotionBatchOperationRequestDto = {
  promotionsIds?: string[];
  operationType?: PromotionOperationTypeDto;
};
export type CreatePromotionResultDto = {
  promotionId?: string;
};
export type CreatePromotionRequestDto = {
  name?: string;
  type?: PromotionTypeDto;
  triggerType?: TriggerTypeDto;
};
export type TestPromotionTypeDto =
  | ('FREE_GIFT' | 'FREE_SHIPPING' | 'EARLY_BIRD')
  | null;
export type CreateTestPromotionRequestDto = {
  type?: TestPromotionTypeDto;
};
export type UpdatePromotionDetailsRequestDto = {
  name?: string;
  description?: string;
  tags?: string[];
  token?: string;
};
export type GeneratePromotionTestLinkResponseDto = {
  link?: string;
};
export type OfferCombinationStackingBehaviorDto =
  | ('LARGEST_ONLY' | 'STACK')
  | null;
export type OfferCombinationDto = {
  enabled?: boolean;
  stackingBehavior?: OfferCombinationStackingBehaviorDto;
};
export type OfferCombinationsDto = {
  product?: OfferCombinationDto;
  order?: OfferCombinationDto;
  shipping?: OfferCombinationDto;
  excludedOffersIds?: string[] | null;
};
export type OfferRuleTypeDto =
  | ('MINIMUM_ITEMS_QUANTITY' | 'MINIMUM_PURCHASE_AMOUNT')
  | null;
export type OfferRuleValueTypeDto =
  | ('AT_LEAST' | 'AT_MOST' | 'BETWEEN' | 'EXACTLY')
  | null;
export type OfferRuleValueDto = {
  type?: OfferRuleValueTypeDto;
  from?: number | null;
  to?: number | null;
  exactly?: number | null;
};
export type OfferTargetTypeDto =
  | ('ALL' | 'COLLECTIONS' | 'PRODUCTS' | 'VARIANTS')
  | null;
export type OfferCartContentRequirementProductMatchTypeDto =
  | ('SAME_PRODUCT' | 'SAME_VARIANT')
  | null;
export type OptionDtoOfferCartContentRequirementProductMatchTypeDto = {
  enabled?: boolean;
  value?: OfferCartContentRequirementProductMatchTypeDto;
};
export type OfferRuleAppliesToDto = {
  type?: OfferTargetTypeDto;
  collections?: ShopifyObjectDto[] | null;
  products?: ShopifyObjectDto[] | null;
  variants?: ShopifyObjectDto[] | null;
  productMatchType?: OptionDtoOfferCartContentRequirementProductMatchTypeDto;
};
export type OptionDtoListShopifyObjectDto = {
  enabled?: boolean;
  value?: ShopifyObjectDto[] | null;
};
export type OfferPrerequisiteEntitledExclusionsDto = {
  collections?: OptionDtoListShopifyObjectDto;
};
export type OptionDtoString = {
  enabled?: boolean;
  value?: string | null;
};
export type OfferPrerequisiteEntitledNameFiltersDto = {
  productNameFilter?: OptionDtoString;
  productVariantNameFilter?: OptionDtoString;
};
export type OfferPrerequisiteEntitledPurchaseTypeDto =
  | (
      | 'ONE_TIME_PURCHASE'
      | 'SUBSCRIPTION'
      | 'ONE_TIME_PURCHASE_AND_SUBSCRIPTION'
    )
  | null;
export type OfferPrerequisiteEntitledCompareAtPriceBehaviorDto =
  | (
      | 'VARIANTS_WITHOUT_COMPARE_AT_PRICE'
      | 'VARIANTS_WITH_COMPARE_AT_PRICE'
      | 'ALL_VARIANTS'
    )
  | null;
export type OfferPrerequisiteEntitledAdvancedSettingsDto = {
  purchaseType?: OfferPrerequisiteEntitledPurchaseTypeDto;
  compareAtPrice?: OfferPrerequisiteEntitledCompareAtPriceBehaviorDto;
};
export type OfferCartRuleDto = {
  type?: OfferRuleTypeDto;
  value?: OfferRuleValueDto;
  appliesTo?: OfferRuleAppliesToDto;
  exclusions?: OfferPrerequisiteEntitledExclusionsDto;
  nameFilters?: OfferPrerequisiteEntitledNameFiltersDto;
  advancedSettings?: OfferPrerequisiteEntitledAdvancedSettingsDto;
  id?: string;
  applyAfterDiscounts?: boolean | null;
};
export type OfferCartRuleGroupDto = {
  order?: number;
  rules?: OfferCartRuleDto[];
};
export type OfferCartRuleGroupConnectorDto = ('AND' | 'BUT_NOT') | null;
export type OfferCartRulesDto = {
  applySameSetAsTargetProducts?: boolean | null;
  groups?: OfferCartRuleGroupDto[] | null;
  connectors?: OfferCartRuleGroupConnectorDto[];
};
export type OfferMinimumSpendDto = {
  value?: number;
  applyAfterDiscounts?: boolean;
};
export type OptionDtoOfferMinimumSpendDto = {
  enabled?: boolean;
  value?: OfferMinimumSpendDto;
};
export type OfferBaseDto = {
  combinations?: OfferCombinationsDto;
  cartRules?: OfferCartRulesDto;
  minimumSpend?: OptionDtoOfferMinimumSpendDto;
  combinationType?: OfferCombinationTypeDto;
};
export type OfferBogoTypeDto =
  | ('ONE_PLUS_ONE' | 'ONE_PLUS_HALF' | 'THREE_FOR_TWO' | 'CUSTOM')
  | null;
export type OfferBogoCustomRuleTypeDto = ('PERCENTAGE' | 'FIXED_AMOUNT') | null;
export type OfferBogoCustomRuleDto = {
  type?: OfferBogoCustomRuleTypeDto;
  percentage?: number | null;
  fixedAmount?: number | null;
  amountOfProducts?: number | null;
  requiredAmount?: number | null;
};
export type OfferBogoSpecificationDto = {
  type?: OfferBogoTypeDto;
  customRule?: OfferBogoCustomRuleDto;
  limit?: OptionDtoInt64;
};
export type OptionDtoNullableDecimal = {
  enabled?: boolean;
  value?: number | null;
};
export type OptionDtoNullableInt32 = {
  enabled?: boolean;
  value?: number | null;
};
export type OfferDiscountLimitDto = {
  discountCap?: OptionDtoNullableDecimal;
  discountedItemsLimit?: OptionDtoNullableInt32;
};
export type OfferPrerequisiteEntitledAppliesToDto = {
  type?: OfferTargetTypeDto;
  collections?: ShopifyObjectDto[] | null;
  products?: ShopifyObjectDto[] | null;
  variants?: ShopifyObjectDto[] | null;
  productMatchType?: OptionDtoOfferCartContentRequirementProductMatchTypeDto;
};
export type OfferProductLimitDto = {
  perOrder?: OptionDtoNullableInt32;
};
export type OfferProductsDto = {
  appliesTo?: OfferPrerequisiteEntitledAppliesToDto;
  exclusions?: OfferPrerequisiteEntitledExclusionsDto;
  limit?: OfferProductLimitDto;
  nameFilters?: OfferPrerequisiteEntitledNameFiltersDto;
  advancedSettings?: OfferPrerequisiteEntitledAdvancedSettingsDto;
};
export type OfferBogoDto = OfferBaseDto & {
  specification?: OfferBogoSpecificationDto;
  discountLimit?: OfferDiscountLimitDto;
  products?: OfferProductsDto;
};
export type OfferOrderDiscountSpecificationDto = {
  fixedAmount?: number;
};
export type OfferBundleOrderDiscountDto = OfferBaseDto & {
  discountSpecification?: OfferOrderDiscountSpecificationDto;
  discountLimit?: OfferDiscountLimitDto;
};
export type OfferCrossSellRequirementRuleTypeDto =
  | ('PERCENTAGE' | 'FIXED_AMOUNT')
  | null;
export type OfferCrossSellRequirementRuleDto = {
  type?: OfferCrossSellRequirementRuleTypeDto;
  value?: OfferRuleValueDto;
  appliesTo?: OfferRuleAppliesToDto;
  exclusions?: OfferPrerequisiteEntitledExclusionsDto;
  nameFilters?: OfferPrerequisiteEntitledNameFiltersDto;
  advancedSettings?: OfferPrerequisiteEntitledAdvancedSettingsDto;
  percentage?: number | null;
  fixedAmount?: number | null;
  count?: number | null;
};
export type OfferCrossSellSpecificationDto = {
  rule?: OfferCrossSellRequirementRuleDto;
};
export type OfferLevelDto = ('LINE' | 'ORDER') | null;
export type OfferCrossSellDto = OfferBaseDto & {
  specification?: OfferCrossSellSpecificationDto;
  level?: OfferLevelDto;
  products?: OfferProductsDto;
  discountLimit?: OfferDiscountLimitDto;
};
export type OfferFreeShippingRateTypeDto =
  | ('FREE_SHIPPING' | 'FLAT_FEE_SHIPPING_RATE')
  | null;
export type OfferFreeShippingSpecificationDto = {
  shippingRateType?: OfferFreeShippingRateTypeDto;
  flatFeeShippingRate?: number | null;
  shippingRateName?: string;
};
export type OfferShippingRateTypeDto =
  | ('ALL' | 'ALL_THRESHOLD' | 'SELECTED' | 'SELECTED_OTHER_OFFER')
  | null;
export type ShippingCountryDto = {
  id?: string;
  provinces?: string[];
};
export type OptionDtoShippingCountryDto = {
  enabled?: boolean;
  value?: ShippingCountryDto[] | null;
};
export type OfferShippingDiscountSpecificationDto = {
  percentageDiscount?: number | null;
  fixedAmountDiscount?: number | null;
  shippingRateType?: OfferShippingRateTypeDto;
  selectedShippingRates?: string[] | null;
  shippingRateThreshold?: number | null;
  shippingCountries?: OptionDtoShippingCountryDto;
};
export type OfferFreeShippingDto = OfferBaseDto & {
  freeShippingSpecification?: OfferFreeShippingSpecificationDto;
  discountSpecification?: OfferShippingDiscountSpecificationDto;
};
export type OfferRuleConditionGroupConnectorDto = ('AND' | 'BUT_NOT') | null;
export type OfferSuggestedRuleConditionDto = {
  value?: OfferRuleValueDto;
  appliesTo?: OfferRuleAppliesToDto;
  exclusions?: OfferPrerequisiteEntitledExclusionsDto;
  nameFilters?: OfferPrerequisiteEntitledNameFiltersDto;
  advancedSettings?: OfferPrerequisiteEntitledAdvancedSettingsDto;
  type?: OfferRuleTypeDto;
  applyAfterDiscounts?: boolean | null;
};
export type OfferSuggestedRuleConditionGroupDto = {
  connector?: OfferRuleConditionGroupConnectorDto;
  conditions?: OfferSuggestedRuleConditionDto[];
};
export type OfferSuggestedRuleSuggestedItemDto = {
  item?: ShopifyObjectDto;
  itemType?: ShopifyObjectTypeDto;
  order?: number;
};
export type OfferProposedItemActionTypeDto =
  | ('PROPOSE_IN_BUILDING_BLOCKS' | 'AUTO_ADD')
  | null;
export type OfferSuggestedRuleProposedItemDto = {
  product?: ShopifyObjectDto;
  productVariant?: ShopifyObjectDto;
};
export type OfferSuggestedRuleDto = {
  id?: string;
  enabled?: boolean;
  name?: string | null;
  groups?: OfferSuggestedRuleConditionGroupDto[];
  suggestedItems?: OfferSuggestedRuleSuggestedItemDto[] | null;
  actionType?: OfferProposedItemActionTypeDto;
  proposedItem?: OfferSuggestedRuleProposedItemDto;
};
export type OfferSuggestedRuleTypeDto =
  | ('USER_DEFINED' | 'AI_SUGGESTED')
  | null;
export type OfferSuggestedRulePriorityDto = {
  ruleId?: string;
  enabled?: boolean | null;
  name?: string | null;
  ruleType?: OfferSuggestedRuleTypeDto;
  priority?: number;
};
export type OfferSuggestedProductsDisplayOrderDto =
  | ('CUSTOM' | 'PRICE' | 'INVENTORY')
  | null;
export type OfferSuggestedAdvancedSetupDto = {
  displayOrder?: OfferSuggestedProductsDisplayOrderDto;
  disableInCartProductsSuggestions?: boolean;
  hideProductInCart?: boolean | null;
};
export type OfferFrequentlyBoughtTogetherDto = OfferBaseDto & {
  aiSuggestionsEnabled?: boolean;
  rules?: OfferSuggestedRuleDto[];
  priorities?: OfferSuggestedRulePriorityDto[];
  advancedSetup?: OfferSuggestedAdvancedSetupDto;
};
export type OfferGiftTypeDto =
  | ('COLLECTIONS' | 'PRODUCT' | 'PRODUCT_VARIANT')
  | null;
export type OfferDefaultGiftRuleDto =
  | (
      | 'MANUAL_BY_CUSTOMER'
      | 'AUTOMATIC_FIRST_VARIANT'
      | 'AUTOMATIC_SELECTED_VARIANT'
    )
  | null;
export type OfferFreeGiftRuleDto = {
  amount?: number;
  type?: OfferGiftTypeDto;
  products?: ShopifyObjectDto[] | null;
  productVariants?: ShopifyObjectDto[] | null;
  collections?: ShopifyObjectDto[] | null;
  defaultGiftRule?: OfferDefaultGiftRuleDto;
};
export type OfferGiftSpecificationAdvancedDto = {
  addGiftAutomaticallyWhenCriteriaMet?: boolean;
  removeGiftAutomaticallyWhenCriteriaNotMet?: boolean;
  removeExcessGiftsAutomatically?: boolean | null;
};
export type OfferGiftSpecificationDto = {
  rule?: OfferFreeGiftRuleDto;
  limit?: OptionDtoInt32;
  advancedOptions?: OfferGiftSpecificationAdvancedDto;
};
export type OfferGiftDto = OfferBaseDto & {
  specification?: OfferGiftSpecificationDto;
};
export type OfferTieredDiscountTypeDto = ('PERCENTAGE' | 'FIXED_AMOUNT') | null;
export type OfferTierDto = {
  requirementPreciseValue?: number | null;
  requirementWholeValue?: number | null;
  entitledPreciseValue?: number | null;
  entitledWholeValue?: number | null;
  affectedItems?: number | null;
};
export type OfferMultiTierBogoDto = OfferBaseDto & {
  type?: OfferTieredDiscountTypeDto;
  tiers?: OfferTierDto[] | null;
  discountLimit?: OfferDiscountLimitDto;
  products?: OfferProductsDto;
};
export type OfferOrderBumpDto = OfferBaseDto & {
  rules?: OfferSuggestedRuleDto[];
  priorities?: OfferSuggestedRulePriorityDto[];
  advancedSetup?: OfferSuggestedAdvancedSetupDto;
};
export type OfferSalesDiscountTypeDto =
  | ('PERCENTAGE' | 'FIXED_AMOUNT' | 'FIXED_PRICE_PER_UNIT')
  | null;
export type OfferSalesDiscountSpecificationDto = {
  type?: OfferSalesDiscountTypeDto;
  percentage?: number | null;
  fixedPricePerUnit?: number | null;
  fixedAmount?: number | null;
};
export type OfferSalesDto = OfferBaseDto & {
  specification?: OfferSalesDiscountSpecificationDto;
  level?: OfferLevelDto;
  products?: OfferProductsDto;
  discountLimit?: OfferDiscountLimitDto;
};
export type OfferShopifyDiscountCodeDto = OfferBaseDto & {
  code?: string | null;
  group?: string | null;
  app?: string | null;
};
export type OfferTieredSpendXGetYDto = OfferBaseDto & {
  type?: OfferTieredDiscountTypeDto;
  tiers?: OfferTierDto[] | null;
  level?: OfferLevelDto;
  products?: OfferProductsDto;
  discountLimit?: OfferDiscountLimitDto;
};
export type OfferVolumeDiscountDto = OfferBaseDto & {
  type?: OfferTieredDiscountTypeDto;
  tiers?: OfferTierDto[] | null;
  products?: OfferProductsDto;
};
export type GetPromotionOfferResponseDto = {
  offer?:
    | OfferBogoDto
    | OfferBundleOrderDiscountDto
    | OfferCrossSellDto
    | OfferFreeShippingDto
    | OfferFrequentlyBoughtTogetherDto
    | OfferGiftDto
    | OfferMultiTierBogoDto
    | OfferOrderBumpDto
    | OfferSalesDto
    | OfferShopifyDiscountCodeDto
    | OfferTieredSpendXGetYDto
    | OfferVolumeDiscountDto;
  type?: OfferTypeDto;
  promotionType?: PromotionTypeDto;
  minimumSpendSupported?: boolean;
  eligibleForSubscription?: boolean;
  isNew?: boolean;
  isShopifyPlus?: boolean;
};
export type SaveOfferRequestBase = {
  type?: OfferTypeDto;
};
export type SaveOfferRequestDtoOfferBogoDto = SaveOfferRequestBase & {
  offer?: OfferBogoDto;
};
export type SaveOfferRequestDtoOfferBundleOrderDiscountDto =
  SaveOfferRequestBase & {
    offer?: OfferBundleOrderDiscountDto;
  };
export type SaveOfferRequestDtoOfferCrossSellDto = SaveOfferRequestBase & {
  offer?: OfferCrossSellDto;
};
export type SaveOfferRequestDtoOfferFreeShippingDto = SaveOfferRequestBase & {
  offer?: OfferFreeShippingDto;
};
export type SaveOfferRequestDtoOfferFrequentlyBoughtTogetherDto =
  SaveOfferRequestBase & {
    offer?: OfferFrequentlyBoughtTogetherDto;
  };
export type SaveOfferRequestDtoOfferGiftDto = SaveOfferRequestBase & {
  offer?: OfferGiftDto;
};
export type SaveOfferRequestDtoOfferMultiTierBogoDto = SaveOfferRequestBase & {
  offer?: OfferMultiTierBogoDto;
};
export type SaveOfferRequestDtoOfferOrderBumpDto = SaveOfferRequestBase & {
  offer?: OfferOrderBumpDto;
};
export type SaveOfferRequestDtoOfferSalesDto = SaveOfferRequestBase & {
  offer?: OfferSalesDto;
};
export type SaveOfferRequestDtoOfferShopifyDiscountCodeDto =
  SaveOfferRequestBase & {
    offer?: OfferShopifyDiscountCodeDto;
  };
export type SaveOfferRequestDtoOfferTieredSpendXGetYDto =
  SaveOfferRequestBase & {
    offer?: OfferTieredSpendXGetYDto;
  };
export type SaveOfferRequestDtoOfferVolumeDiscountDto = SaveOfferRequestBase & {
  offer?: OfferVolumeDiscountDto;
};
export type GetPromotionOfferTemplateResponseDto = {
  template?:
    | OfferBogoDto
    | OfferBundleOrderDiscountDto
    | OfferCrossSellDto
    | OfferFreeShippingDto
    | OfferFrequentlyBoughtTogetherDto
    | OfferGiftDto
    | OfferMultiTierBogoDto
    | OfferOrderBumpDto
    | OfferSalesDto
    | OfferShopifyDiscountCodeDto
    | OfferTieredSpendXGetYDto
    | OfferVolumeDiscountDto;
};
export type PlanNameDto = {
  level?: PlanLevelDto;
  name?: string;
};
export type OfferTemplateListDto = {
  type?: OfferTypeDto;
  available?: boolean;
  isNew?: boolean;
  isSupportedByCurrentPlan?: boolean;
  planSupportingType?: PlanNameDto;
  isHidden?: boolean;
  isShopifyPlus?: boolean;
  videoUrl?: string;
  promotionTypes?: PromotionTypeDto[];
};
export type GetOfferTemplateListResponseDto = {
  promotionType?: PromotionTypeDto;
  availablePromotionTypes?: PromotionTypeDto[] | null;
  templates?: OfferTemplateListDto[];
};
export type ChangeOfferTypeRequestDto = {
  type?: OfferTypeDto;
};
export type OfferRelationalCombinationTypeDto =
  | ('PRODUCT' | 'OTHER_PRODUCT' | 'ORDER' | 'OTHER_ORDER' | 'SHIPPING')
  | null;
export type OfferCombinationDetailsDto = {
  type?: OfferRelationalCombinationTypeDto;
  stackingBehavior?: OfferCombinationStackingBehaviorDto;
  enabled?: boolean;
  isEnforced?: boolean;
  combinedCount?: number;
};
export type OfferCombinationsExcludedOfferDto = {
  id?: string;
  title?: string;
  token?: string;
};
export type OfferCombinationsDetailsDto = {
  currentCombinationType?: OfferCombinationTypeDto;
  availableCombinations?: OfferCombinationDetailsDto[];
  excludedOffers?: OfferCombinationsExcludedOfferDto[];
};
export type ExcludedCombinationsDto = {
  excludedOffersIds?: string[] | null;
  level?: OfferLevelDto;
};
export type OfferSelectableCombinationListDtoGuid = {
  id?: string;
  selected?: boolean;
  title?: string;
  promotionTitle?: string | null;
  type?: OfferCombinationTypeDto;
  isGroup?: boolean;
};
export type PagedResponseDtoOfferSelectableCombinationListDtoGuid = {
  totalItems?: number;
  items?: OfferSelectableCombinationListDtoGuid[];
};
export type GetSelectableCombinationsListRequestDto = {
  search?: string | null;
  page?: number;
  itemsPerPage?: number | null;
  sortDirection?: SortDirectionDto;
  excludedCombinations?: ExcludedCombinationsDto;
};
export type OfferCombinationListItemTypeDto =
  | ('DISCOUNT_NINJA_OFFER' | 'SHOPIFY_DISCOUNT_CODE')
  | null;
export type OfferCombinationListDto = {
  id?: string;
  type?: OfferCombinationListItemTypeDto;
  title?: string;
  offerType?: OfferTypeDto;
  promotionTitle?: string | null;
  combinesWith?: OfferCombinationTypeDto[];
  combinationType?: OfferCombinationTypeDto;
  isGroup?: boolean;
};
export type PagedResponseDtoOfferCombinationListDto = {
  totalItems?: number;
  items?: OfferCombinationListDto[];
};
export type GetOfferCombinationsListRequestDto = {
  search?: string | null;
  page?: number;
  itemsPerPage?: number | null;
  sortDirection?: SortDirectionDto;
  type?: OfferRelationalCombinationTypeDto;
  excludedCombinations?: ExcludedCombinationsDto;
};
export type TriggerMaximumUsesFilterDto = {
  limitedUseByAll?: OptionDtoInt64;
  orderCap?: OptionDtoDecimal;
  limitedUsePerCustomerEnabled?: boolean;
};
export type TriggerExclusivityDto = {
  priority?: number | null;
};
export type OptionDtoTriggerExclusivityDto = {
  enabled?: boolean;
  value?: TriggerExclusivityDto;
};
export type TriggerCountdownClockDto = {
  mode?: TriggerCountdownClockEndModeDto;
  fixedClockTime?: FixedClockTimeDto;
};
export type OptionDtoTriggerCountdownClockDto = {
  enabled?: boolean;
  value?: TriggerCountdownClockDto;
};
export type PromotionSettingsShopifyDiscountCodeDto = {
  code?: string | null;
  app?: string | null;
  group?: string | null;
  offerId?: string | null;
};
export type PromotionCodeMultipleMode = ('OFF' | 'SINGLE' | 'MULTIPLE') | null;
export type TriggerPromotionSettingsDto = {
  triggerType?: TriggerTypeDto;
  singlePromotionCode?: string;
  promotionCodeMultipleMode?: PromotionCodeMultipleMode;
  defaultLink?: TriggerDiscountLinkPreviewDto;
  discountLinksCount?: number;
  promotionCodesCount?: number;
};
export type PromotionSettingsDto = {
  promotionType?: PromotionTypeDto;
  token?: string | null;
  maximumUses?: TriggerMaximumUsesFilterDto;
  exclusivity?: OptionDtoTriggerExclusivityDto;
  countdownClock?: OptionDtoTriggerCountdownClockDto;
  shopifyDiscountCode?: PromotionSettingsShopifyDiscountCodeDto;
  promotionUseCount?: number | null;
  shopDomain?: string | null;
  salesChannel?: PromotionSalesChannelDto;
  filters?: PromotionFiltersSettingsDto;
  trigger?: TriggerPromotionSettingsDto;
};
export type UpdatePromotionTriggerTypeRequestDto = {
  triggerType?: TriggerTypeDto;
};
export type TriggerDiscountLinkLandingPageTypeDto =
  | ('HOME_PAGE' | 'COLLECTION' | 'PRODUCT' | 'CUSTOM_PAGE' | 'BLOG_POST')
  | null;
export type TriggerDiscountLinkBlogPostDto = {
  blog?: string;
  post?: string;
};
export type TriggerDiscountLinkUtmParametersDto = {
  enabled?: boolean;
  source?: string | null;
  medium?: string | null;
  campaign?: string | null;
  term?: string | null;
  content?: string | null;
};
export type TriggerDiscountLinkVanityLinkDto = {
  enabled?: boolean;
  path?: string | null;
};
export type TriggerDiscountLinkDto = {
  id?: string | null;
  landingPageType?: TriggerDiscountLinkLandingPageTypeDto;
  product?: ShopifyObjectDto;
  collection?: ShopifyObjectDto;
  blogPost?: TriggerDiscountLinkBlogPostDto;
  customPage?: string | null;
  utmParametersOption?: TriggerDiscountLinkUtmParametersDto;
  vanityLinkOption?: TriggerDiscountLinkVanityLinkDto;
  notes?: string | null;
  linkPreview?: TriggerDiscountLinkPreviewDto;
  vanityLinkPreview?: TriggerDiscountLinkPreviewDto;
};
export type GetPromotionDiscountLinksResponseDto = {
  default?: TriggerDiscountLinkPreviewDto;
  links?: TriggerDiscountLinkDto[];
};
export type PromotionCodeStatusDto = ('ACTIVE' | 'USED') | null;
export type PromotionCodeDto = {
  id?: string;
  code?: string;
  status?: PromotionCodeStatusDto;
  usageCount?: number | null;
  usageLimit?: number;
  usedInOrder?: string | null;
  usedByCustomer?: string | null;
};
export type GetPromotionCodesForPromotionResponseDto = {
  totalItems?: number;
  items?: PromotionCodeDto[];
  globalTotalRemaining?: number;
  globalTotalLimit?: number;
  totalInPromotion?: number;
  totalActive?: number;
  totalUsed?: number;
  importFileTemplateUrl?: string;
  promotionCodeFieldWidgetEnabled?: boolean;
};
export type PromotionCodeForPromotionListSortFieldDto =
  | ('STATUS' | 'CODE' | 'USAGE')
  | null;
export type GetPromotionCodesForPromotionRequestDto = {
  search?: string | null;
  page?: number;
  itemsPerPage?: number | null;
  sortDirection?: SortDirectionDto;
  selectedItems?: string[] | null;
  status?: PromotionCodeStatusDto;
  sortBy?: PromotionCodeForPromotionListSortFieldDto;
};
export type PromotionCodeComplexityDto =
  | ('SIMPLE' | 'MEDIUM' | 'ADVANCED')
  | null;
export type GeneratePromotionCodesAutomaticallyRequestDto = {
  maximumUses?: number;
  prefix?: string;
  complexity?: PromotionCodeComplexityDto;
  suffix?: string;
  amount?: number;
};
export type GeneratePromotionCodesManuallyRequestDto = {
  maximumUses?: number;
  codes?: string[];
};
export type GeneratePromotionCodesRequestDto = {
  automatic?: GeneratePromotionCodesAutomaticallyRequestDto;
  manual?: GeneratePromotionCodesManuallyRequestDto;
};
export type PromotionCodesBatchOperationTypeDto = 'REMOVE' | null;
export type BatchPromotionCodesOperationRequestDto = {
  operationType?: PromotionCodesBatchOperationTypeDto;
  ids?: string[];
};
export const {
  useGetPromotionsV6LookupCountriesQuery,
  useGetPromotionsV6LookupShippingCountriesQuery,
  useGetPromotionsV6LookupReferrersQuery,
  usePostPromotionsV6LookupShopifyDetailedObjectsMutation,
  useGetPromotionsV6LookupSmartTagsExampleQuery,
  useGetPromotionsV6LookupSmartTagsByIdQuery,
  useGetPromotionsV6LookupTagsQuery,
  useGetPromotionsV6LookupShippingRatesQuery,
  useGetPromotionsV6LookupMarketsQuery,
  useGetPromotionsV6LookupAppBridgeResourcesCollectionsQuery,
  useGetPromotionsV6LookupAppBridgeResourcesProductsQuery,
  useGetPromotionsV6LookupAppBridgeResourcesProductVariantsQuery,
  useGetPromotionsV6LookupShopifyDiscountCodesCodesQuery,
  useGetPromotionsV6LookupShopifyDiscountCodesGroupsQuery,
  useGetPromotionsV6LookupShopifyDiscountCodesAppsQuery,
  useGetPromotionsV6PromotionByIdQuery,
  useDeletePromotionsV6PromotionByIdMutation,
  useGetPromotionsV6PromotionByIdOffersQuery,
  usePostPromotionsV6PromotionByIdOffersMutation,
  usePostPromotionsV6PromotionListMutation,
  usePostPromotionsV6PromotionExportMutation,
  usePostPromotionsV6PromotionImportMutation,
  useGetPromotionsV6PromotionImportByIdQuery,
  usePostPromotionsV6PromotionOperationProgressMutation,
  usePostPromotionsV6PromotionByIdScheduleMutation,
  usePostPromotionsV6PromotionByIdPublishMutation,
  usePostPromotionsV6PromotionByIdUnpublishMutation,
  usePostPromotionsV6PromotionByIdArchiveMutation,
  usePostPromotionsV6PromotionByIdDuplicateMutation,
  usePostPromotionsV6PromotionByIdPauseMutation,
  usePostPromotionsV6PromotionByIdResumeMutation,
  usePostPromotionsV6PromotionByIdRollbackMutation,
  usePostPromotionsV6PromotionByIdRestoreMutation,
  usePostPromotionsV6PromotionBatchMutation,
  usePostPromotionsV6PromotionMutation,
  usePostPromotionsV6PromotionTestMutation,
  usePutPromotionsV6PromotionByIdDetailsMutation,
  useGetPromotionsV6PromotionByIdTestQuery,
  usePostPromotionsV6PromotionByIdDiscardUnpublishedMutation,
  useGetPromotionsV6PromotionOffersByIdQuery,
  usePutPromotionsV6PromotionOffersByIdMutation,
  useGetPromotionsV6PromotionOffersTemplateQuery,
  useGetPromotionsV6PromotionOffersTemplateListQuery,
  useGetPromotionsV6PromotionByIdOffersTemplateListQuery,
  useGetPromotionsV6PromotionByIdOffersTemplateListAndOfferIdQuery,
  useDeletePromotionsV6PromotionByIdOffersAndOfferIdMutation,
  usePostPromotionsV6PromotionByIdOffersAndOfferIdDuplicateMutation,
  usePutPromotionsV6PromotionOffersByIdTypeMutation,
  usePostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsSummaryMutation,
  usePostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOffersMutation,
  usePostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOfTypeMutation,
  useGetPromotionsV6PromotionByIdSettingsQuery,
  usePutPromotionsV6PromotionByIdSettingsMutation,
  usePutPromotionsV6PromotionByIdSettingsTriggerTypeMutation,
  usePutPromotionsV6PromotionByIdSettingsBudgetMutation,
  usePostPromotionsV6PromotionByIdSettingsDiscountLinkPreviewMutation,
  useGetPromotionsV6PromotionByIdSettingsDiscountLinkListQuery,
  usePostPromotionsV6PromotionByIdSettingsDiscountLinkMutation,
  usePutPromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdMutation,
  useDeletePromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdMutation,
  usePostPromotionsV6PromotionByIdSettingsPromotionCodeListMutation,
  usePostPromotionsV6PromotionByIdSettingsPromotionCodeGenerateMutation,
  usePutPromotionsV6PromotionByIdSettingsPromotionCodeBatchMutation,
  usePostPromotionsV6PromotionByIdSettingsPromotionCodeExportMutation,
  usePostPromotionsV6PromotionByIdSettingsPromotionCodeImportMutation,
  useGetPromotionsV6VersionQuery,
} = injectedRtkApi;
