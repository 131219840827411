import {
  BlockStack,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris';
import React, { useMemo } from 'react';
type CardsSkeletonProps = {
  cardsCount: number;
  size?: 'small' | 'medium' | 'large';
};
export const CardsSkeleton: React.FC<CardsSkeletonProps> = ({
  cardsCount,
  size = 'large',
}) => {
  const sizeLevel = useMemo(() => {
    switch (size) {
      case 'small':
        return 1;
      case 'medium':
        return 2;
      case 'large':
        return 3;
    }
  }, [size]);

  const cardsArray = Array.from({ length: cardsCount }, (_, i) => i);
  return (
    <BlockStack gap='600'>
      {cardsArray.map((card) => (
        <Card key={card}>
          <BlockStack gap='400'>
            <SkeletonDisplayText size='small' />
            <SkeletonBodyText lines={sizeLevel} />
            {size !== 'small' && <SkeletonBodyText lines={sizeLevel} />}
          </BlockStack>
        </Card>
      ))}
    </BlockStack>
  );
};
