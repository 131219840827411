import { BlockStack } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { SearchFieldWithTags, tagDataProps } from 'core/components';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import React, { useCallback, useEffect, useState } from 'react';
import './DoNotCombineWith.scss';
import { SelectOffersModal } from './components/SelectOffersModal/SelectOffersModal';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import { useAppSelector } from 'core/hooks';
import {
  OfferCombinationsDto,
  OfferCombinationsExcludedOfferDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { isEqual } from 'lodash';

enum SearchFieldEnum {
  OFFER = 'OFFER',
  DISCOUNT = 'DISCOUNT',
}

type DoNotCombineWithProps = {
  showDoNotCombine: boolean;
  tempExcludedOffers: OfferCombinationsExcludedOfferDto[];
  hasBeenUpdated: number;
  onCombinationsDraftStateUpdate: (data: OfferCombinationsDto) => void;
  setHasBeenUpdated: React.Dispatch<React.SetStateAction<number>>;
};

export type SearchField = 'DISCOUNT' | 'OFFER';

export const DoNotCombineWith: React.FC<DoNotCombineWithProps> = (props) => {
  const {
    showDoNotCombine,
    tempExcludedOffers,
    hasBeenUpdated,
    onCombinationsDraftStateUpdate,
    setHasBeenUpdated,
  } = props;

  const [i18n] = useI18n();
  const { notCombinedWithList } = useAppSelector((state) => state.offersWizard);
  const { fetchCombinationsOffersOnPickerClick } = useConfigureOffers();

  const [selectedOffers, setSelectedOffers] = useState<tagDataProps[]>([]);
  const [selectedOffersTemp, setSelectedOffersTemp] = useState<tagDataProps[]>(
    []
  );

  const [isSelectOffersOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isDiscountCodesOpen, setIsDiscountCodesOpen] =
    useState<boolean>(false);

  const handleOpenSelectOffers = useCallback(() => {
    setIsSelectOpen((isOpen: boolean) => !isOpen);
    fetchCombinationsOffersOnPickerClick();
  }, [setIsSelectOpen, fetchCombinationsOffersOnPickerClick]);

  const handleOpenDiscountCodes = useCallback(() => {
    setIsDiscountCodesOpen((isOpen: boolean) => !isOpen);
  }, [setIsDiscountCodesOpen]);

  const handleSaveOffers = useCallback(() => {
    setSelectedOffers(selectedOffersTemp);
    setHasBeenUpdated((prev) => prev + 1);
    handleOpenSelectOffers();
  }, [
    selectedOffersTemp,
    setSelectedOffers,
    setHasBeenUpdated,
    handleOpenSelectOffers,
  ]);

  const handleSaveDiscounts = useCallback(() => {
    handleOpenDiscountCodes();
  }, [handleOpenDiscountCodes]);

  const removeTag = useCallback(
    (tag: tagDataProps, type: SearchField) => {
      if (type === SearchFieldEnum.OFFER) {
        setSelectedOffers((prev: tagDataProps[]) =>
          prev.filter((ele: tagDataProps) => ele.id !== tag.id)
        );
        setHasBeenUpdated((prev) => prev + 1);
      }
    },
    [setSelectedOffers, setHasBeenUpdated]
  );

  useEffect(() => {
    setSelectedOffers(
      tempExcludedOffers.map((offer) => ({
        id: offer.id,
        name: offer.title,
      }))
    );
  }, [tempExcludedOffers]);

  useEffect(() => {
    hasBeenUpdated &&
      onCombinationsDraftStateUpdate({
        excludedOffersIds: showDoNotCombine
          ? selectedOffers.map((offer) => offer.id as string)
          : [],
      });
  }, [hasBeenUpdated]);

  return showDoNotCombine ? (
    <BlockStack>
      <SearchFieldWithTags
        type='OFFER'
        data={selectedOffers}
        title={i18n.translate('DoNotCombineWith.SelectedOffers')}
        placeholder={i18n.translate('DoNotCombineWith.Offers.Placeholder')}
        openAction={handleOpenSelectOffers}
        removeTag={removeTag}
      />
      <ModalCustom
        isOpen={isSelectOffersOpen}
        buttons={
          notCombinedWithList?.offers?.items?.length
            ? [
                {
                  content: i18n.translate('DoNotCombineWith.Discard'),
                  primary: false,
                  disabled: isEqual(selectedOffers, selectedOffersTemp),
                  action: handleOpenSelectOffers,
                },
                {
                  content: i18n.translate('DoNotCombineWith.Save'),
                  primary: true,
                  disabled: selectedOffersTemp.length === 0,
                  action: handleSaveOffers,
                },
              ]
            : [
                {
                  content: i18n.translate('DoNotCombineWith.Close'),
                  primary: false,
                  action: handleOpenSelectOffers,
                },
              ]
        }
        modalClass='SelectOfferModal'
        bottomSectionText={
          notCombinedWithList?.offers?.items?.length
            ? `${selectedOffersTemp.length} ${i18n.translate(
                'DoNotCombineWith.ItemsSelected'
              )}`
            : ''
        }
        title={i18n.translate('DoNotCombineWith.Offers.Title')}
        onClose={handleOpenSelectOffers}
      >
        <SelectOffersModal
          onSelectedListChange={setSelectedOffersTemp}
          initialSelectedOffers={selectedOffers}
        ></SelectOffersModal>
      </ModalCustom>
      <ModalCustom
        title={i18n.translate('DoNotCombineWith.Discounts.Title')}
        buttons={
          notCombinedWithList?.shopifyDiscountCodes?.items?.length
            ? [
                {
                  content: i18n.translate('DoNotCombineWith.Discard'),
                  primary: false,
                  action: handleOpenDiscountCodes,
                },
                {
                  content: i18n.translate('DoNotCombineWith.Save'),
                  primary: true,
                  action: handleSaveDiscounts,
                },
              ]
            : [
                {
                  content: i18n.translate('DoNotCombineWith.Close'),
                  primary: false,
                  action: handleOpenDiscountCodes,
                },
              ]
        }
        modalClass='SelectOfferModal'
        isOpen={isDiscountCodesOpen}
        onClose={handleOpenDiscountCodes}
      />
    </BlockStack>
  ) : null;
};
