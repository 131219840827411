export enum DiscountOfferTabsEnum {
  Discount = 'Discount',
  Products = 'Products',
  Limit = 'Limit',
  CartRules = 'Cart rules',
  Combinations = 'Combinations',
  Advanced = 'Advanced',
}

export enum SpendXGetYTieredOfferTabsEnum {
  Discount = 'Discount',
  Tiers = 'Tiers',
  Products = 'Products',
  CartRules = 'Cart rules',
  Limit = 'Limit',
  Combinations = 'Combinations',
  Advanced = 'Advanced',
}

export enum VolumeOfferTabsEnum {
  Discount = 'Discount',
  Tiers = 'Tiers',
  Products = 'Products',
  Combinations = 'Combinations',
  Advanced = 'Advanced',
}

export enum FrequentlyBoughtTogetherTabsEnum {
  Rules = 'Rules',
  RulePriority = 'Rule priority',
  Advanced = 'Advanced',
}

export enum FreeShippingForSomeZonesOfferTabsEnum {
  Shipping = 'Shipping',
  ShopifyDiscountCode = 'Shopify discount code',
  CartRules = 'Cart rules',
  Combinations = 'Combinations',
  Advanced = 'Advanced',
}
export enum PerProductShippingFeeOfferTabsEnum {
  Shipping = 'Shipping',
  Products = 'Products',
  CartRules = 'Cart rules',
  Combinations = 'Combinations',
  Advanced = 'Advanced',
}

export enum FreeShippingToAnyCountryOfferTabsEnum {
  Shipping = 'Shipping',
  CartRules = 'Cart rules',
  Combinations = 'Combinations',
  Advanced = 'Advanced',
}

export enum ShopifyDiscountCodeOfferTabsEnum {
  ShopifyDiscountCode = 'Shopify discount code',
  Discount = 'Discount',
  Advanced = 'Advanced',
}

export enum GiftOfferTabsEnum {
  FreeGift = 'Free gift',
  CartRules = 'Cart rules',
  Limit = 'Limit',
  Combinations = 'Combinations',
  Advanced = 'Advanced',
}

export enum RegularBogoOfferTabsEnum {
  Offer = 'Offer',
  Products = 'Products',
  Limit = 'Limit',
  Combinations = 'Combinations',
  Advanced = 'Advanced',
}

export enum CrossSellOfferTabsEnum {
  Offer = 'Offer',
  Products = 'Products',
  CartRules = 'Cart rules',
  Limit = 'Limit',
  Combinations = 'Combinations',
  Advanced = 'Advanced',
}
export enum BundleOfferTabsEnum {
  CartRules = 'Cart rules',
  Combinations = 'Combinations',
  ExclusionRules = 'Exclusion Rules',
}
