import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { MandatoryCheckboxes } from './components/MandatoryCheckboxes/MandatoryCheckboxes';
import { RefreshBehavior } from './components/RefreshBehavior/RefreshBehavior';
import { CartBehaviorSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { SettingsSavebarRef } from 'features/settings/Settings';

export const CartBehavior = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();

  const {
    cartBehaviorDataChange,
    cartBehaviorDataIsFetching,
    cartBehaviorData,
  } = useConfigureSettings(SettingsFetchTypeEnum.CART_BEHAVIOR);
  const [cartBehaviorState, setCartBehaviorState] =
    useState<CartBehaviorSettingsDto>({});
  const [savedData, setSavedData] = useState<CartBehaviorSettingsDto>({});
  const dispatch = useDispatch();

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(cartBehaviorState);
    return cartBehaviorDataChange(cartBehaviorState, onSuccess);
  }, [cartBehaviorState]);

  const handleUpdateCartBehavior = useCallback(
    (
      field: keyof CartBehaviorSettingsDto,
      data: CartBehaviorSettingsDto[keyof CartBehaviorSettingsDto]
    ) => setCartBehaviorState((prev) => ({ ...prev, [field]: data })),
    [setCartBehaviorState]
  );

  useImperativeHandle(ref, () => ({
    discardChanges: () => setCartBehaviorState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, cartBehaviorState)));
  }, [cartBehaviorState, savedData]);

  useEffect(() => {
    if (!isEmpty(cartBehaviorData)) {
      setCartBehaviorState(cartBehaviorData);
      setSavedData({ ...cartBehaviorData });
    }
  }, [cartBehaviorData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div>
      {!cartBehaviorDataIsFetching && cartBehaviorData ? (
        <Page title={i18n.translate(`CartBehavior`)}>
          <BlockStack gap='600'>
            <RefreshBehavior
              cartBehaviorState={cartBehaviorState}
              handleUpdateCartBehavior={handleUpdateCartBehavior}
            />
            <MandatoryCheckboxes
              selector={cartBehaviorState.requiredCheckboxesCssSelector}
              setSelector={(value) =>
                handleUpdateCartBehavior('requiredCheckboxesCssSelector', value)
              }
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </div>
  );
});
CartBehavior.displayName = 'CartBehavior';
