import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { SubscriptionProductsCheckout } from './components/SubscriptionProductsCheckout/SubscriptionProductsCheckout';
import { PixelEventIntegrations } from './components/PixelEventIntegrations/PixelEventIntegrations';
import { MicroData } from './components/MicroData/MicroData';

import {
  IntegrationsSettingsDto,
  IntegrationsSettingsDtoRead,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { useDispatch } from 'react-redux';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { DeveloperHub } from './components/DeveloperHub/DeveloperHub';
import { SettingsSavebarRef } from 'features/settings/Settings';

export const Integrations = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const {
    saveIntegrationsSettings,
    integrationsDataIsFetching,
    integrationsData,
  } = useConfigureSettings(SettingsFetchTypeEnum.INTEGRATIONS);
  const [integrationState, setIntegrationsState] =
    useState<IntegrationsSettingsDtoRead>({});
  const [savedData, setSavedData] = useState<IntegrationsSettingsDtoRead>({});
  const dispatch = useDispatch();

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(integrationState);
    return saveIntegrationsSettings(integrationState, onSuccess);
  }, [integrationState]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => setIntegrationsState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, integrationState)));
  }, [integrationState, savedData]);

  useEffect(() => {
    if (!isEmpty(integrationsData)) {
      setIntegrationsState(integrationsData);
      setSavedData(integrationsData);
    }
    !isEmpty(integrationsData) && setIntegrationsState(integrationsData);
  }, [integrationsData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  const handleIntegrationsLocaleState = useCallback(
    (section: string, value: string | boolean, field?: string) => {
      if (field) {
        setIntegrationsState((prevState: IntegrationsSettingsDto) => ({
          ...prevState,
          [section]: {
            ...(prevState[section as keyof typeof prevState] || {}),
            [field]: value,
          },
        }));
      } else {
        setIntegrationsState((prevState) => ({
          ...prevState,
          [section]: value,
        }));
      }
    },
    [integrationState]
  );

  return (
    <>
      {!integrationsDataIsFetching && integrationState ? (
        <Page title={i18n.translate('Integrations')}>
          <BlockStack gap='600'>
            <DeveloperHub />
            <SubscriptionProductsCheckout
              data={integrationState.subscriptionProductsCheckoutSettings}
              section={'subscriptionProductsCheckoutSettings'}
              handleIntegrationsLocaleState={handleIntegrationsLocaleState}
            />
            <PixelEventIntegrations
              data={integrationState.pixelEventsSettings}
              section='pixelEventsSettings'
              handleIntegrationsLocaleState={handleIntegrationsLocaleState}
            />
            <MicroData
              data={integrationState.microdataSettings}
              section='microdataSettings'
              handleIntegrationsLocaleState={handleIntegrationsLocaleState}
            />
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
Integrations.displayName = 'Integrations';
