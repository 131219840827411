import React, { useCallback, useMemo, useState } from 'react';
import './ToggleWidgetManually.scss';
import {
  Badge,
  Banner,
  BlockStack,
  Button,
  ButtonGroup,
  Checkbox,
  ChoiceList,
  InlineStack,
  Link,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  InstallWidgetRequestDto,
  WidgetInstallationCustomPropertiesDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  WidgetInstallationCustomWidgetCssSelectorDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { ComboboxOption } from 'core/components';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { setIsRefreshModalOpen } from 'core/store/settingsSlice';
import { useAppDispatch } from 'core/hooks';

type ToggleWidgetManuallyProps = {
  installationLink?: string;
  installationMode?: WidgetInstallationModeDtoEnum;
  widgetType: WidgetTypeDtoEnum;
  onClose: () => void;
  refetchWidget: () => void;
};
const ToggleWidgetManually: React.FC<ToggleWidgetManuallyProps> = ({
  installationLink,
  installationMode,
  widgetType,
  onClose,
  refetchWidget,
}) => {
  //Mocked
  const widgetInstallationAssetsData: any = [];
  const widgetInstallationAssetsIsFetching = false;
  const {
    // widgetInstallationAssetsData,
    // widgetInstallationAssetsIsFetching,
    turnWidgetOnManuallyIsLoading,
    turnWidgetOnManually,
  } = useConfigureSettings(SettingsFetchTypeEnum.TOGGLE_WIDGET_MANUALLY);
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const [requestSetup, setRequestSetup] = useState<InstallWidgetRequestDto>({
    type: widgetType,
    status:
      installationMode === WidgetInstallationModeDtoEnum.CUSTOM
        ? WidgetStatusDtoEnum.ENABLED_VIA_CODE
        : WidgetStatusDtoEnum.ENABLED,
  });

  const [assetAgreed, setAssetAgreed] = useState<boolean>(false);
  const [isAssetValid, setIsAssetValid] = useState<boolean>(true);

  const statuses =
    installationMode === WidgetInstallationModeDtoEnum.APP_BLOCKS
      ? [
          WidgetStatusDtoEnum.ENABLED,
          WidgetStatusDtoEnum.ENABLED_VIA_CODE,
          WidgetStatusDtoEnum.ENABLED_VIA_CSS,
        ]
      : [
          WidgetStatusDtoEnum.ENABLED_VIA_CODE,
          WidgetStatusDtoEnum.ENABLED_VIA_CSS,
        ];

  const statusOptions = useMemo(() => {
    return statuses.map((value) => ({
      label: (
        <InlineStack gap='200'>
          {i18n.translate(value)}
          {((value === WidgetStatusDtoEnum.ENABLED_VIA_CODE &&
            installationMode === WidgetInstallationModeDtoEnum.CUSTOM) ||
            (value === WidgetStatusDtoEnum.ENABLED &&
              installationMode ===
                WidgetInstallationModeDtoEnum.APP_BLOCKS)) && (
            <Badge>{i18n.translate('Recommended')}</Badge>
          )}
        </InlineStack>
      ),
      value: value,
    }));
  }, [statuses, installationMode]);

  const assetOptions = useMemo(
    () =>
      (widgetInstallationAssetsData
        ? widgetInstallationAssetsData?.map((asset: any) => ({
            label: asset.name,
            value: asset.id,
          }))
        : []) as ComboboxOption[],
    [widgetInstallationAssetsData]
  );
  const cssSelectorOptions = useMemo(
    () =>
      Object.values(WidgetInstallationCustomWidgetCssSelectorDtoEnum)?.map(
        (value) => ({
          label: i18n.translate(value),
          value: value,
        })
      ),
    [i18n]
  );
  const updateCustomProperties = useCallback(
    (
      field: keyof WidgetInstallationCustomPropertiesDto,
      data: WidgetInstallationCustomPropertiesDto[keyof WidgetInstallationCustomPropertiesDto]
    ) => {
      setRequestSetup((prev) => ({
        ...prev,
        customProperties: {
          ...prev.customProperties,
          [field]: data,
        },
      }));
    },
    [setRequestSetup]
  );

  const sendSaveRequest = useCallback(async () => {
    setIsAssetValid(true);
    turnWidgetOnManually(requestSetup)
      .then(() => {
        onClose();
        refetchWidget();
      })
      .catch(() => setIsAssetValid(false));
  }, [requestSetup, turnWidgetOnManually]);

  const openThemeEditor = useCallback(() => {
    window.open(installationLink, '_blank');
    onClose();
    dispatch(setIsRefreshModalOpen(true));
  }, [installationLink, dispatch]);

  return (
    <div className='ToggleWidgetManually'>
      <div className='ToggleWidgetModalContent'>
        <BlockStack gap='300'>
          <ChoiceList
            title=''
            choices={statusOptions}
            selected={[requestSetup.status as WidgetStatusDtoEnum]}
            onChange={(value) =>
              setRequestSetup({
                ...requestSetup,
                status: value[0] as WidgetStatusDtoEnum,
              })
            }
          />
          {requestSetup.status === WidgetStatusDtoEnum.ENABLED_VIA_CODE && (
            <>
              <Banner tone='info'>
                {i18n.translate('EditCodeBanner')}
                <Link
                  target='_blank'
                  url='https://support.discountninja.io/en/collections/1430668-setting-up-dynamic-pricing'
                >
                  {i18n.translate('ReadDocumentation')}
                </Link>
              </Banner>
              <Checkbox
                label={i18n.translate('AssetAgreement')}
                checked={assetAgreed}
                onChange={setAssetAgreed}
              />
              <SelectOptions
                options={assetOptions}
                isLoading={widgetInstallationAssetsIsFetching}
                onOptionSelect={(value) => {
                  setIsAssetValid(true);
                  updateCustomProperties('selectedAssetId', value);
                }}
                selectedOption={
                  requestSetup.customProperties?.selectedAssetId || ''
                }
                label={i18n.translate('ThemeAsset')}
                helpText={isAssetValid ? i18n.translate('SelectTheAsset') : ''}
                placeholder={i18n.translate('Select')}
                disabled={!assetAgreed}
                error={
                  isAssetValid
                    ? false
                    : `${
                        i18n.translate('AssetErrorPart1') +
                        i18n.translate(widgetType) +
                        i18n.translate('AssetErrorPart2') +
                        widgetInstallationAssetsData?.find(
                          (asset: any) =>
                            asset.id ===
                            requestSetup.customProperties?.selectedAssetId
                        )?.name
                      }`
                }
              />
            </>
          )}
          {requestSetup.status === WidgetStatusDtoEnum.ENABLED_VIA_CSS && (
            <>
              <SelectOptions
                options={cssSelectorOptions}
                onOptionSelect={(value) =>
                  updateCustomProperties('cssSelector', value)
                }
                selectedOption={
                  requestSetup.customProperties?.cssSelector || ''
                }
                label={i18n.translate('CSSSelector')}
                placeholder={i18n.translate('Select')}
              />
              {requestSetup.customProperties?.cssSelector ===
                WidgetInstallationCustomWidgetCssSelectorDtoEnum.OTHER && (
                <>
                  <TextField
                    label={i18n.translate('CustomSelector')}
                    autoComplete=''
                    value={
                      requestSetup.customProperties.customCssSelector || ''
                    }
                    onChange={(value) =>
                      updateCustomProperties('customCssSelector', value)
                    }
                  />
                  <div className='ListOfNonCss'>
                    <p>{i18n.translate('NonCssSelecorTitle')}</p>
                    <ul>
                      <li>
                        <span>{'[[WRAP]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor1')}
                      </li>
                      <li>
                        <span>{'[[PREPEND]], [[APPEND]] '}</span>
                        {i18n.translate('NonCssSelecor2')}
                      </li>
                      <li>
                        <span>{'[[BEFORE]], [[AFTER]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor3')}
                      </li>
                      <li>
                        <span>{'[[REPLACE]], [[REPLACECONTENT]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor4')}
                      </li>
                      <li>
                        <span>{'[[CARTITEMKEY]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor5')}
                      </li>
                      <li>
                        <span>{'[[HANDLE:href]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor6')}
                      </li>
                      <li>
                        <span>{'[[LEFT]], [[CENTER]], [[RIGHT]] '}</span>{' '}
                        {i18n.translate('NonCssSelecor7')}
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </>
          )}
          {requestSetup.status === WidgetStatusDtoEnum.ENABLED && (
            <Banner tone='info'>
              {i18n.translate('AppBlockBanner')}
              <Link>{i18n.translate('ReadDocumentation')}</Link>
            </Banner>
          )}
        </BlockStack>
      </div>
      <div className='ModalFooterActions'>
        <ButtonGroup>
          <Button onClick={onClose}>{i18n.translate('Cancel')}</Button>
          <Button
            onClick={
              requestSetup.status === WidgetStatusDtoEnum.ENABLED
                ? openThemeEditor
                : sendSaveRequest
            }
            variant='primary'
            loading={turnWidgetOnManuallyIsLoading}
          >
            {i18n.translate(`${requestSetup.status}_BUTTON`)}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default ToggleWidgetManually;
