import React from 'react';
import {
  Badge,
  Box,
  Icon,
  IconSource,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { ChevronRightIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';

type NavigationBoxProps = {
  icon?: IconSource;
  title: string;
  tag?: string;
  btnContent: string;
  link: string;
};
export const NavigationBox: React.FC<NavigationBoxProps> = ({
  icon,
  title,
  tag,
  btnContent,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <Box
      borderColor='border'
      borderWidth='025'
      borderRadius='200'
      padding='300'
    >
      <InlineStack align='space-between' blockAlign='center'>
        <InlineStack gap='200'>
          {icon && <Icon tone='base' source={icon}></Icon>}
          <Text fontWeight='medium' as='p'>
            {title}
          </Text>
          {tag && <Badge>{tag}</Badge>}
        </InlineStack>
        <Link removeUnderline onClick={() => navigate(link)}>
          <InlineStack gap='050' blockAlign='center'>
            {btnContent}
            <Icon source={ChevronRightIcon}></Icon>
          </InlineStack>
        </Link>
      </InlineStack>
    </Box>
  );
};
