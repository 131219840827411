import React from 'react';
import { Box, InlineStack } from '@shopify/polaris';
import { CustomSekeletonBox } from 'core/components';

export const OverviewOfferListSkeleton = () => {
  return (
    <>
      {[1, 2, 3].map((value) => (
        <Box
          key={value}
          padding='400'
          borderBlockEndWidth='025'
          borderColor='border-brand'
        >
          <InlineStack wrap={false} align='space-between' blockAlign='center'>
            <Box width='75%'>
              <InlineStack align='start' wrap={false} gap='300'>
                <CustomSekeletonBox height='18px' width='18px' />
                <InlineStack align='start' wrap={false} gap='200'>
                  <Box minWidth='56px'>
                    <CustomSekeletonBox height='8px' width='56px' />
                  </Box>
                  <CustomSekeletonBox height='8px' width='233px' />
                </InlineStack>
              </InlineStack>
            </Box>
            <InlineStack wrap={false} gap='300' blockAlign='center'>
              <CustomSekeletonBox height='8px' width='56px' />
            </InlineStack>
          </InlineStack>
        </Box>
      ))}
    </>
  );
};
