import React, { useCallback, useMemo, useState } from 'react';
import './SettingsNavbar.scss';
import { Navigation, Text } from '@shopify/polaris';
import Logo from './assets/Avatar.svg';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import { ItemProps } from '@shopify/polaris/build/ts/src/components/Navigation/types';
import { useAppDispatch, useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import { SettingsConfirmationModal } from '../SettingsConfirmationModal/SettingsConfirmationModal';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { getSettingsNavbarIcon, settingNavbarPathes } from './utils/utils';

export const SettingsNavbar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [i18n] = useI18n();
  const { isUnsavedChanges } = useAppSelector((state) => state.settings);
  const isDebugOrLocal = useIsDebugOrLocal();
  const [pendingTabChange, setPendingTabChange] = useState<{
    tabPath: string;
    firstSubTabPath?: string;
  } | null>(null);
  const [modalActive, setModalActive] = useState<boolean>(false);

  const handleTabChange = useCallback(
    (tabPath: string, firstSubTabPath?: string) => {
      dispatch(setIsUnsavedChanges(false));
      searchParams.set('path', tabPath);
      if (firstSubTabPath) {
        searchParams.set('subPath', firstSubTabPath);
      } else {
        searchParams.delete('subPath');
      }
      setSearchParams(searchParams);
    },
    [searchParams, dispatch, setSearchParams]
  );

  const handleTabClick = useCallback(
    async (tabPath: string, firstSubTabPath?: string) => {
      if (!isDebugOrLocal && isUnsavedChanges) {
        if (location.pathname.includes(`/${NavPaths.Settings}`)) {
          await shopify.saveBar.leaveConfirmation();
          handleTabChange(tabPath, firstSubTabPath);
        } else {
          setPendingTabChange({ tabPath, firstSubTabPath });
          setModalActive(true);
        }
      } else {
        handleTabChange(tabPath, firstSubTabPath);
      }
    },
    [isUnsavedChanges, isDebugOrLocal, location]
  );

  const handleModalClose = useCallback(() => {
    setModalActive(false);
    setPendingTabChange(null);
  }, []);

  const handleLeavePage = useCallback(() => {
    if (pendingTabChange) {
      handleTabChange(
        pendingTabChange.tabPath,
        pendingTabChange.firstSubTabPath
      );
    }
    setModalActive(false);
    setPendingTabChange(null);
  }, [pendingTabChange]);

  const shopSettingsTabs: ItemProps[] = useMemo(() => {
    const currentPath = searchParams.get('path');
    return settingNavbarPathes.map((path) => ({
      label: i18n.translate(path),
      accessibilityLabel: path,
      icon: () => getSettingsNavbarIcon(path, currentPath !== path),
      onClick: () => handleTabClick(path),
      selected: currentPath === path,
    }));
  }, [searchParams, handleTabClick, i18n]);

  return (
    <>
      <div className='SettingsNavBar'>
        <Navigation location='?modal=settings&path=account'>
          <div className='navbarLogo'>
            <img src={Logo} alt='discount-ninja logo' />
            <Text variant='headingMd' as='h6'>
              {i18n.translate(`LogoTitle`)}
            </Text>
          </div>
          <Navigation.Section items={shopSettingsTabs} />
        </Navigation>
      </div>
      <SettingsConfirmationModal
        modalActive={modalActive}
        handleModalClose={handleModalClose}
        handleLeavePage={handleLeavePage}
      />
    </>
  );
};
