import React, { useMemo } from 'react';
import {
  Badge,
  Bleed,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { CheckSmallIcon } from '@shopify/polaris-icons';
import {
  PlanDiscountDto,
  PlanDtoRead,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import {
  PlanFeatureDtoEnum,
  PlanIntervalDtoEnum,
  PlanLevelDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { formatNumber } from 'core/utils';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
import { ChartuUnitOfMeasurementEnum } from 'pages';

type PlanCardProps = {
  planData: PlanDtoRead;
  discount?: PlanDiscountDto | null;
  isSelected: boolean;
  interval: PlanIntervalDtoEnum;
  isFreeTrialAvailable: boolean;
  onSelectPlan: (value: PlanLevelDtoEnum) => void;
};
export const PlanCard: React.FC<PlanCardProps> = ({
  planData,
  discount,
  isSelected,
  interval,
  isFreeTrialAvailable,
  onSelectPlan,
}) => {
  const [i18n] = useI18n();
  const { formatValue } = useNumericFormatter();

  const { pricing, threshold, comparisonDetails, features } = planData;

  const isAnnual = interval === PlanIntervalDtoEnum.ANNUAL;
  const isDiscounted = useMemo(
    () => !!(pricing?.intervalPrices?.discounted && discount),
    [pricing?.intervalPrices?.discounted, discount]
  );

  const feautureList = useMemo(
    () =>
      [
        i18n.translate('MaxPublishedPromotions', {
          amount: planData.publishedPromotionsLimit,
          publishedPromotions: <u>{i18n.translate('PublishedPromotions')}</u>,
        }),
        i18n.translate('OrdersPerPeriod', {
          amount: formatNumber(
            i18n,
            isAnnual ? threshold?.perYear : threshold?.perMonth
          ),
          period: i18n.translate(isAnnual ? 'Year' : 'Month'),
          discountedOrders: <u>{i18n.translate('DiscountedOrders')}</u>,
        }),
        i18n.translate('PerAdditionalOrder', {
          amount: formatValue(
            isAnnual
              ? pricing?.intervalPrices?.regular?.annualOverageUnitPrice
              : pricing?.intervalPrices?.regular?.monthlyOverageUnitPrice,
            ChartuUnitOfMeasurementEnum.CURRENCY
          ),
        }),
        i18n.translate(
          comparisonDetails?.platform?.apiAccess
            ? 'CustomizeAndExtend'
            : 'CustomizeWidgets'
        ),
        i18n.translate(
          features?.includes(PlanFeatureDtoEnum.NINJA_CART_PRO)
            ? 'NinjaCartPro'
            : 'NinjaCart'
        ),
      ].map((value, index) => (
        <InlineStack key={index} align='start'>
          <Box>
            <Icon source={CheckSmallIcon} />
          </Box>
          <Text as='p' tone='subdued'>
            {value}
          </Text>
        </InlineStack>
      )),
    [
      planData.publishedPromotionsLimit,
      isAnnual,
      threshold,
      pricing,
      comparisonDetails,
      features,
    ]
  );

  return (
    <Box width='100%'>
      <Card>
        <BlockStack gap='400'>
          {isDiscounted && (
            <Bleed marginBlockStart='400' marginInline='400'>
              <Box
                paddingInline='400'
                paddingBlock='200'
                background='bg-surface-success'
              >
                <Text
                  tone='success'
                  as='p'
                  fontWeight='medium'
                  alignment='center'
                >
                  {i18n.translate('DiscountEnabled', {
                    percentage: formatValue(
                      discount?.discountPercentage,
                      ChartuUnitOfMeasurementEnum.PERCENTAGE
                    ),
                  })}
                </Text>
              </Box>
            </Bleed>
          )}
          <InlineStack gap='200' align='start'>
            <Text as='h2' fontWeight='semibold' variant='headingLg'>
              {planData.name}
            </Text>
            {isSelected && <Badge>{i18n.translate('Current')}</Badge>}
          </InlineStack>
          <BlockStack gap='100'>
            <Text variant='bodySm' as='p' tone='subdued'>
              {i18n.translate('StartingAt')}
            </Text>
            <Text as='h1' id='PlanMonthPrice' variant='heading2xl'>
              <Text
                as='span'
                tone={isDiscounted ? 'subdued' : 'base'}
                fontWeight={isDiscounted ? 'regular' : 'bold'}
                textDecorationLine={isDiscounted ? 'line-through' : undefined}
              >
                $
                {isAnnual
                  ? pricing?.intervalPrices?.regular?.annualPlanPerMonthPrice
                  : pricing?.intervalPrices?.regular?.monthlyPlanPerMonthPrice}
              </Text>
              {isDiscounted && (
                <Text as='span' tone='critical'>
                  $
                  {isAnnual
                    ? pricing?.intervalPrices?.discounted
                        ?.annualPlanPerMonthPrice
                    : pricing?.intervalPrices?.discounted
                        ?.monthlyPlanPerMonthPrice}
                </Text>
              )}

              <Text as='span' tone='subdued' variant='bodySm'>
                {i18n.translate('USDMonth')}
              </Text>
            </Text>
            <InlineStack
              gap='100'
              align='start'
              blockAlign='center'
              wrap={false}
            >
              <Text
                variant='bodySm'
                tone='base'
                as='p'
                textDecorationLine={isDiscounted ? 'line-through' : undefined}
              >
                $
                {formatNumber(
                  i18n,
                  isAnnual
                    ? pricing?.intervalPrices?.regular?.annualPlanPerYearPrice
                    : pricing?.intervalPrices?.regular?.monthlyPlanPerYearPrice
                )}
              </Text>
              {isDiscounted && (
                <Text tone='critical' as='p' variant='bodySm'>
                  $
                  {formatNumber(
                    i18n,
                    isAnnual
                      ? pricing?.intervalPrices?.discounted
                          ?.annualPlanPerYearPrice
                      : pricing?.intervalPrices?.discounted
                          ?.monthlyPlanPerYearPrice
                  )}
                </Text>
              )}
              <Text as='p' tone='subdued' variant='bodySm'>
                {i18n.translate('USDYear')}
              </Text>
              {isAnnual && (
                <Text
                  variant='bodySm'
                  as='p'
                  tone='success'
                  fontWeight='medium'
                  truncate
                >
                  {i18n.translate('SavePercentage', {
                    amount: formatValue(
                      pricing?.intervalPrices?.savingPercentage,
                      ChartuUnitOfMeasurementEnum.PERCENTAGE
                    ),
                  })}
                </Text>
              )}
            </InlineStack>
          </BlockStack>
          <Button
            onClick={() => onSelectPlan(planData.level as PlanLevelDtoEnum)}
            disabled={isSelected}
            variant='primary'
          >
            {isFreeTrialAvailable
              ? i18n.translate('StartFreeTrial')
              : i18n.translate('SelectPlan', {
                  plan: planData.name as string,
                })}
          </Button>

          <BlockStack gap='100'>{feautureList}</BlockStack>
        </BlockStack>
      </Card>
    </Box>
  );
};
