import {
  OfferShippingRateTypeDto,
  useGetPromotionsV6LookupShippingRatesQuery,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferShippingRateTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import resolveEnvVar from 'env-var-resolver';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
export const useShippingRates = (
  deliveryMethodsOption?: OfferShippingRateTypeDto
) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const shouldSkip =
    deliveryMethodsOption !== OfferShippingRateTypeDtoEnum.SELECTED &&
    deliveryMethodsOption !== OfferShippingRateTypeDtoEnum.SELECTED_OTHER_OFFER;
  const { data: shippingRatesListData, isLoading: shippingRatesListIsLoading } =
    useGetPromotionsV6LookupShippingRatesQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: shouldSkip }
    );

  return { shippingRatesListData, shippingRatesListIsLoading };
};
