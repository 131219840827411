import { Dispatch, SetStateAction, useCallback } from 'react';
import {
  SchedulePromotionRequestDto,
  CreatePromotionRequestDto,
  GetPromotionsRequestDto,
  OfferTypeDto,
  PromotionSettingsDto,
  UpdatePromotionDetailsRequestDto,
  useDeletePromotionsV6PromotionByIdMutation,
  useGetPromotionsV6PromotionByIdOffersTemplateListQuery,
  useGetPromotionsV6PromotionByIdSettingsQuery,
  useGetPromotionsV6PromotionOffersTemplateListQuery,
  usePostPromotionsV6PromotionByIdArchiveMutation,
  usePostPromotionsV6PromotionByIdDuplicateMutation,
  usePostPromotionsV6PromotionByIdScheduleMutation,
  usePostPromotionsV6PromotionByIdOffersMutation,
  usePostPromotionsV6PromotionListMutation,
  usePostPromotionsV6PromotionMutation,
  usePutPromotionsV6PromotionByIdDetailsMutation,
  usePutPromotionsV6PromotionByIdSettingsMutation,
  usePutPromotionsV6PromotionOffersByIdTypeMutation,
  usePostPromotionsV6PromotionByIdUnpublishMutation,
  usePostPromotionsV6PromotionBatchMutation,
  PromotionBatchOperationRequestDto,
  usePostPromotionsV6PromotionOperationProgressMutation,
  usePostPromotionsV6PromotionByIdPauseMutation,
  usePostPromotionsV6PromotionByIdResumeMutation,
  usePostPromotionsV6PromotionByIdRollbackMutation,
  usePostPromotionsV6PromotionByIdRestoreMutation,
  useGetPromotionsV6PromotionByIdOffersTemplateListAndOfferIdQuery,
  useGetPromotionsV6PromotionByIdSettingsDiscountLinkListQuery,
  usePostPromotionsV6PromotionByIdSettingsDiscountLinkMutation,
  TriggerDiscountLinkDto,
  usePutPromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdMutation,
  useDeletePromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdMutation,
  usePostPromotionsV6PromotionByIdSettingsPromotionCodeGenerateMutation,
  GeneratePromotionCodesRequestDto,
  usePutPromotionsV6PromotionByIdSettingsPromotionCodeBatchMutation,
  BatchPromotionCodesOperationRequestDto,
  usePostPromotionsV6PromotionByIdSettingsPromotionCodeImportMutation,
  usePostPromotionsV6PromotionByIdSettingsPromotionCodeListMutation,
  useGetPromotionsV6PromotionByIdTestQuery,
  usePostPromotionsV6PromotionByIdPublishMutation,
  usePostPromotionsV6PromotionByIdDiscardUnpublishedMutation,
  GetPromotionCodesForPromotionRequestDto,
  useGetPromotionsV6LookupMarketsQuery,
  usePostPromotionsV6PromotionTestMutation,
  TestPromotionTypeDto,
  usePostPromotionsV6PromotionImportMutation,
  usePutPromotionsV6PromotionByIdSettingsTriggerTypeMutation,
  TriggerTypeDto,
  useGetPromotionsV6PromotionByIdQuery,
  usePutPromotionsV6PromotionByIdSettingsBudgetMutation,
  TriggerMaximumUsesFilterDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useAppDispatch } from 'core/hooks';
import { Params, useParams } from 'react-router-dom';
import {
  setSelectedOfferType,
  setToastMessages,
} from 'core/store/offersWizardSlice';
import { OfferTemplatesList } from '../components/CreatePromotionModal/consts/OfferTemplatesList';
import { OfferTypesFromCatalogDataType } from '../components/SelectedOfferTypeSettings/types/OfferTypesFromCatalogTypes';

const APP_NAME = process.env.REACT_APP_APP_NAME || '';

export type ImportPromotionCodesBodyDto = {
  contentType?: string;
  contentDisposition?: string;
  headers?: {
    [key: string]: string[];
  };
  length?: number;
  name?: string;
};

export enum PromotionFetchTypeEnum {
  PROMOTION_OVERVIEW = 'PROMOTION_OVERVIEW',
  PROMOTION_SETTINGS = 'PROMOTION_SETTINGS',
  DISCOUNT_LINKS = 'DISCOUNT_LINKS',
  PROMOTION_CODES = 'PROMOTION_CODES',
  PROMOTION_TESTING = 'PROMOTION_TESTING',
  CREATE_PROMOTION = 'CREATE_PROMOTION',
  CREATE_TEST_PROMOTION = 'CREATE_TEST_PROMOTION',
  CHANGE_OFFER_TEMPLATE = 'CHANGE_OFFER_TEMPLATE',
  CREATE_NEW_OFFER = 'CREATE_NEW_OFFER',
  MARKETS = 'MARKETS',
}

export const useConfigurePromotions = (
  fetchType?: PromotionFetchTypeEnum,
  maxModalParams?: Params<string> | null
) => {
  const dispatch = useAppDispatch();
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const globalParams = useParams();
  const params = maxModalParams ? maxModalParams : globalParams;

  const {
    data: promotionOverviewData,
    isFetching: promotionOverviewIsFetching,
    refetch: refetchPromotionOverviewData,
  } = useGetPromotionsV6PromotionByIdQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      'Accept-Language': navigator.language || 'en',
      id: params.promotionId as string,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: fetchType !== PromotionFetchTypeEnum.PROMOTION_OVERVIEW,
    }
  );

  const [
    changePromotionTriggerTypeMutation,
    {
      error: changePromotionTriggerTypeError,
      isLoading: changePromotionTriggerTypeIsLoading,
    },
  ] = usePutPromotionsV6PromotionByIdSettingsTriggerTypeMutation();

  const changePromotionTriggerType = useCallback(
    async (type: TriggerTypeDto, onSuccess: () => void) => {
      await changePromotionTriggerTypeMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        updatePromotionTriggerTypeRequestDto: {
          triggerType: type,
        },
      })
        .unwrap()
        .then(onSuccess)
        .catch((err) => null);
    },
    [changePromotionTriggerTypeMutation, params]
  );

  const [
    changePromotionBudgetMutation,
    {
      error: changePromotionBudgetError,
      isLoading: changePromotionBudgetIsLoading,
    },
  ] = usePutPromotionsV6PromotionByIdSettingsBudgetMutation();

  const changePromotionBudget = useCallback(
    async (data: TriggerMaximumUsesFilterDto, onSuccess: () => void) => {
      await changePromotionBudgetMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        triggerMaximumUsesFilterDto: data,
      })
        .unwrap()
        .then(onSuccess)
        .catch((err) => null);
    },
    [changePromotionBudgetMutation, params]
  );

  const [
    importPromotionListMutation,
    {
      data: importPromotionListData,
      isLoading: importPromotionListIsLoading,
      error: importPromotionListError,
    },
  ] = usePostPromotionsV6PromotionImportMutation();

  const importPromotionList = useCallback(
    async (
      file: any,
      overwrite?: boolean,
      setIsImporting?: (value: boolean) => void
    ) => {
      setIsImporting?.(true);
      await importPromotionListMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: file,
        overwriteSameTokenPromotions: overwrite,
      })
        .unwrap()
        .catch((err) => {
          setIsImporting?.(false);
        });
    },
    [importPromotionListMutation, params]
  );

  const {
    data: marketListData,
    isFetching: marketListFetching,
    error: marketListError,
    refetch: refetchMarketList,
  } = useGetPromotionsV6LookupMarketsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      skip: fetchType !== PromotionFetchTypeEnum.MARKETS,
    }
  );

  const {
    data: promotionTestLinkData,
    isFetching: promotionTestLinkIsFetching,
    error: promotionTestLinkError,
    refetch: refetchpromotionTestLink,
  } = useGetPromotionsV6PromotionByIdTestQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: params.promotionId || '',
    },
    {
      skip:
        fetchType !== PromotionFetchTypeEnum.PROMOTION_TESTING ||
        !params.promotionId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    getPromotionCodesListMutation,
    {
      error: promotionCodesListError,
      isLoading: promotionCodesListIsLoading,
      data: promotionCodesListData,
    },
  ] = usePostPromotionsV6PromotionByIdSettingsPromotionCodeListMutation();

  const getPromotionCodesList = useCallback(
    async (setup: GetPromotionCodesForPromotionRequestDto) => {
      await getPromotionCodesListMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        getPromotionCodesForPromotionRequestDto: setup,
      })
        .unwrap()
        .catch((err) => null);
    },
    [getPromotionCodesListMutation, params]
  );

  const [
    generatePromotionCodesMutation,
    {
      data: generatePromotionCodesData,
      isLoading: generatePromotionCodesIsLoading,
      error: generatePromotionCodesError,
    },
  ] = usePostPromotionsV6PromotionByIdSettingsPromotionCodeGenerateMutation();

  const generatePromotionCodes = useCallback(
    async (data: GeneratePromotionCodesRequestDto, onSuccess: () => void) => {
      await generatePromotionCodesMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        generatePromotionCodesRequestDto: data,
      })
        .unwrap()
        .then(() => {
          onSuccess();
          dispatch(
            setToastMessages({
              error: false,
              message: 'PromotionCodesGenerated',
            })
          );
        })
        .catch((err) => null);
    },
    [generatePromotionCodesMutation, params]
  );

  const [
    batchPromotionCodesMutation,
    {
      data: batchPromotionCodesData,
      isLoading: batchPromotionCodesIsLoading,
      error: batchPromotionCodesError,
    },
  ] = usePutPromotionsV6PromotionByIdSettingsPromotionCodeBatchMutation();

  const batchPromotionCodes = useCallback(
    async (data: BatchPromotionCodesOperationRequestDto) => {
      batchPromotionCodesMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        batchPromotionCodesOperationRequestDto: data,
      }).catch((err) => null);
    },
    [batchPromotionCodesMutation, params]
  );

  const [
    importPromotionCodesMutation,
    {
      data: importPromotionCodesData,
      isLoading: importPromotionCodesIsLoading,
      error: importPromotionCodesError,
    },
  ] = usePostPromotionsV6PromotionByIdSettingsPromotionCodeImportMutation();

  const importPromotionCodes = useCallback(
    async (file: any, overwrite?: boolean) => {
      await importPromotionCodesMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        body: file,
        overwriteExistingCodes: overwrite,
      })
        .unwrap()
        .catch((err) => null);
    },
    [batchPromotionCodesMutation, params]
  );

  const {
    data: offersTemplateListData,
    isFetching: offersTemplateListIsFetching,
    error: offersTemplateListError,
    refetch: refetchOfferTemplateListData,
  } = useGetPromotionsV6PromotionOffersTemplateListQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: fetchType !== PromotionFetchTypeEnum.CREATE_PROMOTION }
  );

  const {
    data: promotionTemplateListData,
    isFetching: promotionTemplateListIsFetching,
    error: promotionTemplateListError,
    refetch: refetchPromotionTemplateListData,
  } = useGetPromotionsV6PromotionByIdOffersTemplateListQuery(
    {
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      'X-LimoniApps-AppName': APP_NAME,
      id: params.promotionId || '',
    },
    {
      skip:
        fetchType !== PromotionFetchTypeEnum.CREATE_NEW_OFFER ||
        !params.promotionId,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    data: changeOfferTemplateListData,
    isFetching: changeOfferTemplateListIsFetching,
    error: changeOfferTemplateListDataError,
    refetch: refetchChangeOfferTemplateListData,
  } = useGetPromotionsV6PromotionByIdOffersTemplateListAndOfferIdQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: params.promotionId || '',
      offerId: params.id || '',
    },
    {
      skip:
        fetchType !== PromotionFetchTypeEnum.CHANGE_OFFER_TEMPLATE ||
        !params.promotionId,
    }
  );

  const {
    data: promotionSettingsData,
    isFetching: promotionSettingsIsFetching,
    error: promotionSettingsError,
    refetch: refetchPromotionSettingsData,
  } = useGetPromotionsV6PromotionByIdSettingsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: params.promotionId || '',
    },
    {
      refetchOnMountOrArgChange: true,
      skip:
        fetchType !== PromotionFetchTypeEnum.PROMOTION_SETTINGS ||
        !params.promotionId,
    }
  );

  const {
    data: discountLinkListData,
    isFetching: discountLinkListIsFetching,
    error: discountLinkListDataError,
    refetch: refetchDiscountLinkList,
  } = useGetPromotionsV6PromotionByIdSettingsDiscountLinkListQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: params.promotionId || '',
    },
    {
      skip:
        fetchType !== PromotionFetchTypeEnum.DISCOUNT_LINKS ||
        !params.promotionId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    createDiscountLinkMutation,
    {
      data: createDiscountLinkData,
      isLoading: createDiscountLinkIsLoading,
      error: createDiscountLinkError,
    },
  ] = usePostPromotionsV6PromotionByIdSettingsDiscountLinkMutation();

  const createDiscountLink = useCallback(
    async (data: TriggerDiscountLinkDto) => {
      await createDiscountLinkMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        triggerDiscountLinkDto: data,
      })
        .unwrap()
        .then(() =>
          dispatch(
            setToastMessages({
              error: false,
              message: 'DiscountLinkCreated',
            })
          )
        )
        .catch((err) => null);
    },
    [createDiscountLinkMutation, params]
  );

  const [
    updateDiscountLinkMutation,
    {
      data: updateDiscountLinkData,
      isLoading: updateDiscountLinkIsLoading,
      error: updateDiscountLinkError,
    },
  ] =
    usePutPromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdMutation();

  const updateDiscountLink = useCallback(
    async (data: TriggerDiscountLinkDto, linkId: string) => {
      await updateDiscountLinkMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        triggerDiscountLinkDto: data,
        discountLinkId: linkId,
      })
        .unwrap()
        .then(() =>
          dispatch(
            setToastMessages({
              error: false,
              message: 'DiscountLinkUpdated',
            })
          )
        )
        .catch((err) => null);
    },
    [updateDiscountLinkMutation, params]
  );

  const [
    deleteDiscountLinkMutation,
    {
      data: deleteDiscountLinkData,
      isLoading: deleteDiscountLinkIsLoading,
      error: deleteDiscountLinkError,
    },
  ] =
    useDeletePromotionsV6PromotionByIdSettingsDiscountLinkAndDiscountLinkIdMutation();

  const deleteDiscountLink = useCallback(
    async (linkId: string) => {
      await deleteDiscountLinkMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        discountLinkId: linkId,
      })
        .unwrap()
        .then(() =>
          dispatch(
            setToastMessages({
              error: false,
              message: 'DiscountLinkDeleted',
            })
          )
        )
        .catch((err) => null);
    },
    [deleteDiscountLinkMutation, params]
  );

  const [
    updatePromotionSettingsMutation,
    {
      isLoading: updatePromotionSettingsIsLoading,
      error: updatePromotionSettingsError,
      isError: updatePromotionSettingsIsError,
    },
  ] = usePutPromotionsV6PromotionByIdSettingsMutation();

  const updatePromotionSettings = useCallback(
    async (
      promotionId: string,
      body: PromotionSettingsDto,
      setIsUpdating?: Dispatch<SetStateAction<boolean>>,
      onSuccess?: () => void
    ) => {
      setIsUpdating?.(true);
      await updatePromotionSettingsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: promotionId,
        promotionSettingsDto: body,
      })
        .unwrap()
        .then(() => {
          onSuccess?.();
          dispatch(
            setToastMessages({
              error: false,
              message: 'PromotionSettingsUpdated',
            })
          );
        })
        .catch(() => {
          setIsUpdating?.(false);
        });
    },
    [updatePromotionSettingsMutation]
  );

  const [
    createPromotionMutation,
    {
      data: createPromotionData,
      isLoading: createPromotionIsLoading,
      error: createPromotionError,
    },
  ] = usePostPromotionsV6PromotionMutation();

  const createPromotion = useCallback(
    async (data: CreatePromotionRequestDto) => {
      await createPromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        createPromotionRequestDto: data,
      })
        .unwrap()
        .then((response) => {
          response.promotionId &&
            dispatch(
              setToastMessages({
                error: false,
                message: 'PromotionCreated',
              })
            );
        })
        .catch((err) => null);
    },
    [createPromotionMutation, dispatch]
  );

  const [
    createTestPromotionMutation,
    {
      data: createTestPromotionData,
      isLoading: createTestPromotionIsLoading,
      error: createTestPromotionError,
    },
  ] = usePostPromotionsV6PromotionTestMutation();

  const createTestPromotion = useCallback(
    async (type: TestPromotionTypeDto) => {
      await createTestPromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        createTestPromotionRequestDto: { type },
      })
        .unwrap()
        .then((response) => {
          if (response.promotionId) {
            dispatch(
              setToastMessages({
                error: false,
                message: 'TestPromotionCreated',
              })
            );
            dispatch(
              setToastMessages({
                error: false,
                message: 'OfferCreated',
              })
            );
          }
        })
        .catch((err) => null);
    },
    [createTestPromotionMutation, dispatch]
  );

  const [
    updatePromotionDetailsMutation,
    {
      data: updatePromotionDetailsData,
      isLoading: updatePromotionDetailsIsLoading,
      error: updatePromotionDetailsError,
    },
  ] = usePutPromotionsV6PromotionByIdDetailsMutation();

  const updatePromotionDetails = useCallback(
    async (data: UpdatePromotionDetailsRequestDto) => {
      await updatePromotionDetailsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        updatePromotionDetailsRequestDto: data,
      })
        .unwrap()
        .catch((err) => null);
    },
    [updatePromotionDetailsMutation, params]
  );

  const [
    createNewOfferMutation,
    {
      data: createNewOfferData,
      isLoading: createNewOfferIsLoading,
      error: createNewOfferError,
    },
  ] = usePostPromotionsV6PromotionByIdOffersMutation();

  const [
    updateOfferTemplateMutation,
    {
      data: updateOfferTemplateMutationData,
      isLoading: updateOfferTemplateMutationIsLoading,
      error: updateOfferTemplateMutationError,
    },
  ] = usePutPromotionsV6PromotionOffersByIdTypeMutation();

  const createNewOffer = useCallback(
    async (type: OfferTypeDto) => {
      await createNewOfferMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
        initializeOfferRequestDto: {
          type: type,
        },
      })
        .unwrap()
        .then(
          (response) =>
            response.id &&
            dispatch(
              setToastMessages({
                error: false,
                message: 'OfferCreated',
              })
            )
        )
        .catch((err) => null);
    },
    [createNewOfferMutation, params]
  );

  const updateOfferTemplate = useCallback(
    async (
      type: OfferTypeDto,
      offerId: string,
      updateBackground?: (message: any) => void
    ) => {
      await updateOfferTemplateMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: offerId as string,
        changeOfferTypeRequestDto: {
          type: type,
        },
      })
        .unwrap()
        .then(() => {
          dispatch(
            setSelectedOfferType(
              OfferTemplatesList.find(
                (item) => item.type === type
              ) as OfferTypesFromCatalogDataType
            )
          );
          updateBackground?.({
            type: PromotionFetchTypeEnum.CHANGE_OFFER_TEMPLATE,
          });
          dispatch(
            setToastMessages({
              error: false,
              message: 'OfferUpdated',
            })
          );
        })
        .catch((err) => null);
    },
    [updateOfferTemplateMutation]
  );

  const [
    getPromotionListMutation,
    {
      error: promotionListError,
      isLoading: promotionListIsLoading,
      data: promotionListData,
    },
  ] = usePostPromotionsV6PromotionListMutation();

  const getPromotionListData = useCallback(
    async (setup: GetPromotionsRequestDto) => {
      await getPromotionListMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        getPromotionsRequestDto: setup,
      })
        .unwrap()
        .catch((err) => null);
    },
    [getPromotionListMutation]
  );

  const [
    archivePromotionMutation,
    {
      error: archivePromotionError,
      isLoading: archivePromotionIsLoading,
      data: archivePromotionData,
    },
  ] = usePostPromotionsV6PromotionByIdArchiveMutation();
  const archivePromotion = useCallback(
    async (id: string, refetchData: () => void) => {
      await archivePromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => {
          refetchData();
          dispatch(
            setToastMessages({
              error: false,
              message: 'PromotionArchived',
            })
          );
        })
        .catch((err) => null);
    },
    [archivePromotionMutation]
  );

  const [
    duplicatePromotionMutation,
    {
      error: duplicatePromotionError,
      isLoading: duplicatePromotionIsLoading,
      data: duplicatePromotionData,
    },
  ] = usePostPromotionsV6PromotionByIdDuplicateMutation();
  const duplicatePromotion = useCallback(
    async (id: string) => {
      await duplicatePromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => {
          dispatch(
            setToastMessages({
              error: false,
              message: 'PromotionDuplicated',
            })
          );
        })
        .catch((err) => null);
    },
    [duplicatePromotionMutation]
  );

  const [
    deletePromotionMutation,
    {
      error: deletePromotionError,
      isLoading: deletePromotionIsLoading,
      data: deletePromotionData,
    },
  ] = useDeletePromotionsV6PromotionByIdMutation();
  const deletePromotion = useCallback(
    async (id: string, navigate: () => void) => {
      await deletePromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => {
          navigate();
          dispatch(
            setToastMessages({
              error: false,
              message: 'PromotionDeleted',
            })
          );
        })
        .catch((err) => null);
    },
    [deletePromotionMutation]
  );
  const [
    schedulePromotionMutation,
    {
      error: schedulePromotionError,
      isLoading: schedulePromotionIsLoading,
      data: schedulePromotionData,
    },
  ] = usePostPromotionsV6PromotionByIdScheduleMutation();

  const schedulePromotion = useCallback(
    async (
      data: SchedulePromotionRequestDto,
      id: string,
      onSuccess: () => void
    ) => {
      await schedulePromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        schedulePromotionRequestDto: data,
        id: id,
      })
        .unwrap()
        .then(onSuccess)
        .catch((err) => null);
    },
    [schedulePromotionMutation]
  );

  const [
    discardPromotionMutation,
    {
      error: discardPromotionError,
      isLoading: discardPromotionIsLoading,
      data: discardPromotionData,
    },
  ] = usePostPromotionsV6PromotionByIdDiscardUnpublishedMutation();

  const discardPromotion = useCallback(
    async (refetchData: () => void) => {
      await discardPromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
      })
        .unwrap()
        .then(() => refetchData())
        .catch((err) => null);
    },
    [discardPromotionMutation, params]
  );

  const [
    publishPromotionMutation,
    {
      error: publishPromotionError,
      isLoading: publishPromotionIsLoading,
      data: publishPromotionData,
    },
  ] = usePostPromotionsV6PromotionByIdPublishMutation();

  const publishPromotion = useCallback(
    async (refetchData: () => void) => {
      await publishPromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId as string,
      })
        .unwrap()
        .then(() => refetchData())
        .catch((err) => null);
    },
    [publishPromotionMutation, params]
  );

  const [
    deactivatePromotionMutation,
    {
      error: deactivatePromotionError,
      isLoading: deactivatePromotionIsLoading,
      data: deactivatePromotionData,
    },
  ] = usePostPromotionsV6PromotionByIdUnpublishMutation();
  const deactivatePromotion = useCallback(
    async (id: string, refetchData: () => void) => {
      await deactivatePromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => {
          refetchData();
          dispatch(
            setToastMessages({
              error: false,
              message: 'PromotionDeactivated',
            })
          );
        })
        .catch((err) => null);
    },
    [deactivatePromotionMutation]
  );

  const [
    restorePromotionMutation,
    {
      error: restorePromotionError,
      isLoading: restorePromotionIsLoading,
      data: restorePromotionData,
    },
  ] = usePostPromotionsV6PromotionByIdRestoreMutation();
  const restorePromotion = useCallback(
    async (id: string, refetchData: () => void) => {
      await restorePromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => {
          refetchData();
          dispatch(
            setToastMessages({
              error: false,
              message: 'PromotionRestored',
            })
          );
        })
        .catch((err) => null);
    },
    [restorePromotionMutation]
  );

  const [
    actionOnBatchOfPromotionMutation,
    {
      error: actionOnBatchOfPromotionError,
      isLoading: actionOnBatchOfPromotionIsLoading,
      data: actionOnBatchOfPromotionData,
    },
  ] = usePostPromotionsV6PromotionBatchMutation();
  const actionOnBatchOfPromotion = useCallback(
    async (data: PromotionBatchOperationRequestDto) => {
      const response = await actionOnBatchOfPromotionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        promotionBatchOperationRequestDto: data,
      })
        .unwrap()
        .catch((err) => null);
      if (response && !actionOnBatchOfPromotionError) {
        return response;
      }
    },
    [actionOnBatchOfPromotionMutation]
  );

  const [
    getPromotionOperationProgressMutation,
    {
      error: promotionOperationProgressError,
      isLoading: promotionOperationProgressIsLoading,
      data: promotionOperationProgressData,
    },
  ] = usePostPromotionsV6PromotionOperationProgressMutation();

  const getPromotionOperationProgress = useCallback(
    async (ids: string[]) => {
      await getPromotionOperationProgressMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        getPromotionOperationProgressRequestDto: {
          promotionIds: ids,
        },
      })
        .unwrap()
        .catch((err) => null);
    },
    [getPromotionOperationProgressMutation]
  );

  const [
    pausePromotionProgressMutation,
    {
      error: pausePromotionProgressError,
      isLoading: pausePromotionProgressIsLoading,
      data: pausePromotionProgressData,
    },
  ] = usePostPromotionsV6PromotionByIdPauseMutation();

  const pausePromotionProgress = useCallback(
    async (id: string) => {
      await pausePromotionProgressMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .catch((err) => null);
    },
    [pausePromotionProgressMutation]
  );

  const [
    resumePromotionProgressMutation,
    {
      error: resumePromotionProgressError,
      isLoading: resumePromotionProgressIsLoading,
      data: resumePromotionProgressData,
    },
  ] = usePostPromotionsV6PromotionByIdResumeMutation();

  const resumePromotionProgress = useCallback(
    async (id: string) => {
      await resumePromotionProgressMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .catch((err) => null);
    },
    [resumePromotionProgressMutation]
  );

  const [
    rollbackPromotionProgressMutation,
    {
      error: rollbackPromotionProgressError,
      isLoading: rollbackPromotionProgressIsLoading,
      data: rollbackPromotionProgressData,
    },
  ] = usePostPromotionsV6PromotionByIdRollbackMutation();

  const rollbackPromotionProgress = useCallback(
    async (id: string) => {
      await rollbackPromotionProgressMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .catch((err) => null);
    },
    [rollbackPromotionProgressMutation]
  );

  return {
    promotionOverviewData,
    promotionOverviewIsFetching,
    refetchPromotionOverviewData,
    offersTemplateListData,
    offersTemplateListIsFetching,
    offersTemplateListError,
    refetchOfferTemplateListData,
    createPromotionData,
    createPromotionIsLoading,
    createPromotionError,
    createPromotion,
    promotionListError,
    promotionListIsLoading,
    promotionListData,
    getPromotionListData,
    updatePromotionDetailsData,
    updatePromotionDetailsIsLoading,
    updatePromotionDetailsError,
    updatePromotionDetails,
    promotionTemplateListData,
    promotionTemplateListIsFetching,
    promotionTemplateListError,
    refetchPromotionTemplateListData,
    createNewOfferData,
    createNewOfferIsLoading,
    createNewOfferError,
    createNewOffer,
    schedulePromotionError,
    schedulePromotionIsLoading,
    schedulePromotionData,
    schedulePromotion,
    promotionSettingsData,
    promotionSettingsIsFetching,
    promotionSettingsError,
    refetchPromotionSettingsData,
    archivePromotionError,
    archivePromotionIsLoading,
    archivePromotionData,
    archivePromotion,
    duplicatePromotionError,
    duplicatePromotionIsLoading,
    duplicatePromotionData,
    duplicatePromotion,
    deletePromotionError,
    deletePromotionIsLoading,
    deletePromotionData,
    deletePromotion,
    updateOfferTemplate,
    updateOfferTemplateMutationData,
    updateOfferTemplateMutationIsLoading,
    updateOfferTemplateMutationError,
    deactivatePromotionError,
    deactivatePromotionIsLoading,
    deactivatePromotionData,
    deactivatePromotion,
    restorePromotionError,
    restorePromotionIsLoading,
    restorePromotionData,
    restorePromotion,
    actionOnBatchOfPromotionError,
    actionOnBatchOfPromotionIsLoading,
    actionOnBatchOfPromotionData,
    actionOnBatchOfPromotion,
    updatePromotionSettings,
    updatePromotionSettingsError,
    updatePromotionSettingsIsLoading,
    updatePromotionSettingsIsError,
    promotionOperationProgressError,
    promotionOperationProgressIsLoading,
    promotionOperationProgressData,
    getPromotionOperationProgress,
    pausePromotionProgressError,
    pausePromotionProgressIsLoading,
    pausePromotionProgressData,
    pausePromotionProgress,
    resumePromotionProgressError,
    resumePromotionProgressIsLoading,
    resumePromotionProgressData,
    resumePromotionProgress,
    rollbackPromotionProgressError,
    rollbackPromotionProgressIsLoading,
    rollbackPromotionProgressData,
    rollbackPromotionProgress,
    changeOfferTemplateListData,
    changeOfferTemplateListIsFetching,
    changeOfferTemplateListDataError,
    refetchChangeOfferTemplateListData,
    discountLinkListData,
    discountLinkListIsFetching,
    discountLinkListDataError,
    refetchDiscountLinkList,
    createDiscountLinkData,
    createDiscountLinkIsLoading,
    createDiscountLinkError,
    createDiscountLink,
    updateDiscountLinkData,
    updateDiscountLinkIsLoading,
    updateDiscountLinkError,
    updateDiscountLink,
    deleteDiscountLinkData,
    deleteDiscountLinkIsLoading,
    deleteDiscountLinkError,
    deleteDiscountLink,
    generatePromotionCodesData,
    generatePromotionCodesIsLoading,
    generatePromotionCodesError,
    generatePromotionCodes,
    batchPromotionCodesData,
    batchPromotionCodesIsLoading,
    batchPromotionCodesError,
    batchPromotionCodes,
    importPromotionCodesData,
    importPromotionCodesIsLoading,
    importPromotionCodesError,
    importPromotionCodes,
    promotionCodesListError,
    promotionCodesListIsLoading,
    promotionCodesListData,
    getPromotionCodesList,
    promotionTestLinkData,
    promotionTestLinkIsFetching,
    promotionTestLinkError,
    refetchpromotionTestLink,
    publishPromotionError,
    publishPromotionIsLoading,
    publishPromotionData,
    publishPromotion,
    discardPromotionError,
    discardPromotionIsLoading,
    discardPromotionData,
    discardPromotion,
    marketListData,
    marketListFetching,
    marketListError,
    refetchMarketList,
    createTestPromotion,
    createTestPromotionData,
    createTestPromotionIsLoading,
    createTestPromotionError,
    importPromotionListData,
    importPromotionListIsLoading,
    importPromotionListError,
    importPromotionList,
    changePromotionTriggerTypeError,
    changePromotionTriggerTypeIsLoading,
    changePromotionTriggerType,
    changePromotionBudgetError,
    changePromotionBudgetIsLoading,
    changePromotionBudget,
  };
};
