import React from 'react';
import { MediaCard } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';

export const DeveloperHub = () => {
  const [i18n] = useI18n();

  return (
    <MediaCard
      title={i18n.translate('DeveloperHub')}
      primaryAction={{
        content: i18n.translate('LearnMore'),
        onAction: () =>
          window.open('https://developers.discountninja.io/ ', '_blank'),
      }}
      description={i18n.translate('HubDescription')}
    >
      <img
        alt=''
        width='100%'
        height='100%'
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
        }}
        src='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
      />
    </MediaCard>
  );
};
