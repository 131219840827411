import React, { useCallback } from 'react';
import { Button, ButtonProps, IconSource } from '@shopify/polaris';
import { DuplicateIcon } from '@shopify/polaris-icons';
import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { Tooltip } from '../ControlLibrary';
type CopyIconProps = {
  copyText?: string | null;
  tooltip?: string;
  icon?: IconSource;
  buttonText?: string;
  variant?: ButtonProps['variant'];
};
export const CopyIcon: React.FC<CopyIconProps> = ({
  tooltip,
  copyText,
  icon,
  buttonText,
  variant,
}) => {
  const dispatch = useAppDispatch();

  const onCopyText = useCallback(() => {
    navigator.clipboard.writeText(copyText || '');
    dispatch(
      setToastMessages({
        error: false,
        message: 'CopiedToClipboard',
      })
    );
  }, [dispatch, copyText]);

  return (
    <div style={{ maxHeight: 20 }} className='CopyIcon'>
      <Tooltip content={tooltip ? tooltip : ''}>
        <Button
          icon={buttonText ? undefined : icon ? icon : DuplicateIcon}
          variant={variant ? variant : 'monochromePlain'}
          onClick={onCopyText}
        >
          {buttonText}
        </Button>
      </Tooltip>
    </div>
  );
};
