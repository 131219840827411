import { useCallback, useState } from 'react';
import { GetPromotionsRequestDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { setToastMessages } from 'core/store/offersWizardSlice';
import resolveEnvVar from 'env-var-resolver';
import { useAppDispatch } from 'core/hooks';
import { ExportFileModalTypeEnum } from 'core/components';

const apiURL = `${resolveEnvVar('REACT_APP_PROMOTIONS_ADMIN_API_URL')}`;

export const useExportPromotionList = () => {
  const dispatch = useAppDispatch();
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
  const [exportListIsFetching, setExportListIsFetching] =
    useState<boolean>(false);

  const downloadFile = useCallback((blob: Blob, fileName: string) => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  }, []);

  const exportPromotionList = async (
    setup: GetPromotionsRequestDto,
    exportType?: ExportFileModalTypeEnum
  ) => {
    setExportListIsFetching(true);

    let body: GetPromotionsRequestDto;
    switch (exportType) {
      case ExportFileModalTypeEnum.ALL:
        body = {
          page: 1,
          itemsPerPage: 0,
          sortBy: setup.sortBy,
          sortDirection: setup.sortDirection,
        };
        break;
      case ExportFileModalTypeEnum.SEARCH_MATCH:
        body = { ...setup, page: 1, itemsPerPage: 0, selectedItems: null };
        break;
      case ExportFileModalTypeEnum.CURRENT_PAGE:
        body = { ...setup, selectedItems: null };
        break;
      default:
        body = setup;
    }

    await fetch(`${apiURL}/promotions/v6/promotion/export`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        const filename =
          response.headers.get('content-disposition')?.split('"')[1] || '';
        response.blob().then((myBlob) => downloadFile(myBlob, filename));
      })
      .catch(() => {
        dispatch(
          setToastMessages({
            error: true,
            message: 'ExportFailed',
          })
        );
      })
      .finally(() => setExportListIsFetching(false));
  };

  return {
    exportPromotionList,
    exportListIsFetching,
  };
};
