import { NumberFormatValues } from 'react-number-format';
import {
  ShippingValueTypeEnum,
  ShippingValueTypeEnumUX,
} from '../../../enums/ShippingValueType';
import { OfferFreeShippingRateTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { getCurrencySymbol } from 'core/utils';

export function getInitialActiveShippingValueType(
  specificationType: OfferFreeShippingRateTypeDto | undefined
): ShippingValueTypeEnum {
  if (specificationType) {
    return ShippingValueTypeEnumUX[
      specificationType
    ] as unknown as ShippingValueTypeEnum;
  } else {
    return ShippingValueTypeEnum.FreeShipping;
  }
}
export function getInitialPriceTextfieldValue(
  value: number
): NumberFormatValues {
  return {
    floatValue: value || 0,
    formattedValue: `${getCurrencySymbol()} ` + (value?.toString() || '0'),
    value: value?.toString() || '0',
  };
}
