import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  InlineStack,
  Link,
  RadioButton,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OptionDtoTriggerVisitorBehaviorsDto,
  TriggerVisitorBehaviorsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { TriggerVisitorBehaviorCriteriaTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
type SettingsVisitorBehaviorProps = {
  visitorBehavior?: OptionDtoTriggerVisitorBehaviorsDto;
  showValidation: boolean;
  onFormValidityChange: (isValid: boolean) => void;
  updateSettingsState: (data: OptionDtoTriggerVisitorBehaviorsDto) => void;
};
export const SettingsVisitorBehavior: React.FC<
  SettingsVisitorBehaviorProps
> = ({
  visitorBehavior,
  showValidation,
  updateSettingsState,
  onFormValidityChange,
}) => {
  const [i18n] = useI18n();

  const checkIsValid = useCallback(
    (
      field:
        | 'timeSpentOnPage'
        | 'numberOfPagesVisited'
        | 'percentageOfPageViewed'
    ) =>
      (visitorBehavior?.value?.[field]?.enabled &&
        (visitorBehavior?.value?.[field]?.value as number) > 0) ||
      !visitorBehavior?.value?.[field]?.enabled,
    [visitorBehavior?.value]
  );

  const isAllValid =
    checkIsValid('numberOfPagesVisited') &&
    checkIsValid('percentageOfPageViewed') &&
    checkIsValid('timeSpentOnPage');

  const handleUpdateSetup = useCallback(
    (
      field: keyof TriggerVisitorBehaviorsDto,
      data: TriggerVisitorBehaviorsDto[keyof TriggerVisitorBehaviorsDto]
    ) => {
      updateSettingsState({
        ...visitorBehavior,
        value: {
          ...visitorBehavior?.value,
          [field]: data,
        },
      });
    },
    [visitorBehavior, updateSettingsState]
  );

  const rulesOptions = useMemo(
    () =>
      Object.values(TriggerVisitorBehaviorCriteriaTypeDtoEnum).map((value) => (
        <RadioButton
          key={value}
          label={i18n.translate(value)}
          checked={visitorBehavior?.value?.criteriaType === value}
          onChange={() => handleUpdateSetup('criteriaType', value)}
        />
      )),
    [i18n, visitorBehavior?.value?.criteriaType, handleUpdateSetup]
  );

  const validateInt = useCallback(
    (
      option:
        | 'timeSpentOnPage'
        | 'numberOfPagesVisited'
        | 'percentageOfPageViewed'
    ) => {
      const value = visitorBehavior?.value?.[option]?.value || 1;
      handleUpdateSetup(option, {
        ...visitorBehavior?.value?.[option],
        value:
          value > 100 && option === 'percentageOfPageViewed'
            ? 100
            : value < 1
            ? 1
            : value,
      });
    },
    [visitorBehavior]
  );

  useEffect(() => {
    onFormValidityChange(isAllValid);
  }, [isAllValid]);

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack blockAlign='start' wrap={false} align='space-between'>
          <Box width='70%'>
            <BlockStack gap='100'>
              <InlineStack align='start' gap='200'>
                <Text fontWeight='semibold' as='h2'>
                  {i18n.translate('VisitorBehaviorTitle')}
                </Text>
                <Badge tone={visitorBehavior?.enabled ? 'success' : 'enabled'}>
                  {i18n.translate(visitorBehavior?.enabled ? 'On' : 'Off')}
                </Badge>
              </InlineStack>
              <Text as='p' tone='subdued'>
                {i18n.translate('VisitorBehaviorSubtitle')}
              </Text>
            </BlockStack>
          </Box>
          <Button
            onClick={() =>
              updateSettingsState({
                ...visitorBehavior,
                enabled: !visitorBehavior?.enabled,
              })
            }
          >
            {i18n.translate(visitorBehavior?.enabled ? 'TurnOff' : 'TurnOn')}
          </Button>
        </InlineStack>
        {visitorBehavior?.enabled && (
          <>
            <InlineStack blockAlign='center' gap='400'>
              <Text as='p'>{i18n.translate('VisitorsMustMeet')}</Text>
              {rulesOptions}
            </InlineStack>
            <Box
              borderColor='border'
              borderWidth='025'
              borderRadius='300'
              paddingBlock='400'
            >
              <BlockStack gap='300'>
                <Box paddingInline='400'>
                  <Text as='p'>{i18n.translate('Rules')}</Text>
                </Box>

                <Box paddingInline='400'>
                  <InlineStack gap='500' wrap={false} align='space-between'>
                    <Checkbox
                      checked={visitorBehavior?.value?.timeSpentOnPage?.enabled}
                      onChange={(value) =>
                        handleUpdateSetup('timeSpentOnPage', {
                          ...visitorBehavior?.value?.timeSpentOnPage,
                          enabled: value,
                        })
                      }
                      label={i18n.translate('TimeSpent')}
                      helpText={i18n.translate('TimeSpentSubtitle')}
                    />
                    <Box width='160px'>
                      <TextField
                        label=''
                        value={visitorBehavior?.value?.timeSpentOnPage?.value?.toString()}
                        onChange={(value) =>
                          handleUpdateSetup('timeSpentOnPage', {
                            ...visitorBehavior?.value?.timeSpentOnPage,
                            value: +value,
                          })
                        }
                        error={
                          checkIsValid('timeSpentOnPage')
                            ? false
                            : i18n.translate('NotValid')
                        }
                        disabled={
                          !visitorBehavior?.value?.timeSpentOnPage?.enabled
                        }
                        type='number'
                        suffix={i18n.translate('Seconds')}
                        autoComplete=''
                        onBlur={() => validateInt('timeSpentOnPage')}
                      />
                    </Box>
                  </InlineStack>
                </Box>
                <Divider />
                <Box paddingInline='400'>
                  <InlineStack gap='500' wrap={false} align='space-between'>
                    <Checkbox
                      checked={
                        visitorBehavior?.value?.numberOfPagesVisited?.enabled
                      }
                      onChange={(value) =>
                        handleUpdateSetup('numberOfPagesVisited', {
                          ...visitorBehavior?.value?.numberOfPagesVisited,
                          enabled: value,
                        })
                      }
                      label={i18n.translate('NumberOfPages')}
                      helpText={i18n.translate('NumberOfPagesSubtitle')}
                    />
                    <Box width='160px'>
                      <TextField
                        label=''
                        value={visitorBehavior?.value?.numberOfPagesVisited?.value?.toString()}
                        onChange={(value) =>
                          handleUpdateSetup('numberOfPagesVisited', {
                            ...visitorBehavior?.value?.numberOfPagesVisited,
                            value: +value,
                          })
                        }
                        error={
                          checkIsValid('numberOfPagesVisited')
                            ? false
                            : i18n.translate('NotValid')
                        }
                        disabled={
                          !visitorBehavior?.value?.numberOfPagesVisited?.enabled
                        }
                        type='number'
                        suffix={i18n.translate('Pages')}
                        autoComplete=''
                        onBlur={() => validateInt('numberOfPagesVisited')}
                      />
                    </Box>
                  </InlineStack>
                </Box>
                <Divider />
                <Box paddingInline='400'>
                  <InlineStack gap='500' wrap={false} align='space-between'>
                    <Checkbox
                      checked={
                        visitorBehavior?.value?.percentageOfPageViewed?.enabled
                      }
                      onChange={(value) =>
                        handleUpdateSetup('percentageOfPageViewed', {
                          ...visitorBehavior?.value?.percentageOfPageViewed,
                          enabled: value,
                        })
                      }
                      label={i18n.translate('PercentageOfPage')}
                      helpText={i18n.translate('PercentageOfPageSubtitle')}
                    />
                    <Box width='160px'>
                      <TextField
                        label=''
                        value={visitorBehavior?.value?.percentageOfPageViewed?.value?.toString()}
                        onChange={(value) =>
                          handleUpdateSetup('percentageOfPageViewed', {
                            ...visitorBehavior?.value?.percentageOfPageViewed,
                            value: +value,
                          })
                        }
                        error={
                          checkIsValid('percentageOfPageViewed')
                            ? false
                            : i18n.translate('NotValid')
                        }
                        disabled={
                          !visitorBehavior?.value?.percentageOfPageViewed
                            ?.enabled
                        }
                        type='number'
                        suffix={'%'}
                        autoComplete=''
                        onBlur={() => validateInt('percentageOfPageViewed')}
                      />
                    </Box>
                  </InlineStack>
                </Box>
                <Divider />
                <Box paddingInline='400'>
                  <Checkbox
                    checked={visitorBehavior?.value?.exitIntentEnabled}
                    onChange={(value) =>
                      handleUpdateSetup('exitIntentEnabled', value)
                    }
                    label={i18n.translate('ExitIntent')}
                  />
                </Box>
              </BlockStack>
            </Box>
          </>
        )}
      </BlockStack>
    </Card>
  );
};
