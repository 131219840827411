import React, { useCallback, useEffect, useState } from 'react';
import {
  BlockStack,
  Box,
  Card,
  Divider,
  Page,
  Text,
  ResourceList,
  Bleed,
  InlineStack,
  Button,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  AuditLogEntryDto,
  useGetSettingsV6AccountAuditLogsQuery,
} from 'core/api/adminSettings/adminSettingsApi';
import { useLocation, useSearchParams } from 'react-router-dom';
const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
import './ActivityLogPage.scss';
import resolveEnvVar from 'env-var-resolver';
import { SettingsSkeleton } from '../../../components/SettingsSkeleton/SettingsSkeleton';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { SettingsConfirmationModal } from 'features/settings/components/SettingsConfirmationModal/SettingsConfirmationModal';
import { Loader } from 'core/components';
import { NavPaths } from 'core/enums/NavPathsEnum';

export const ActivityLogPage = () => {
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();
  const location = useLocation();
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const [_, setSearchParams] = useSearchParams();

  const { isUnsavedChanges } = useAppSelector((state) => state.settings);

  const [page, setPage] = useState<number>(1);
  const [activityLogState, setActivityLogState] = useState<
    AuditLogEntryDto[] | undefined
  >([]);
  const [modalActive, setModalActive] = useState<boolean>(false);

  const handleRedirectBack = useCallback(() => {
    setSearchParams((params) => {
      params.delete('subPath');
      return params;
    });
    setModalActive(false);
  }, [setSearchParams, setModalActive]);

  const handleModalClose = useCallback(() => {
    setModalActive(false);
  }, [setModalActive]);

  const onBackActionClick = useCallback(async () => {
    if (!isDebugOrLocal && isUnsavedChanges) {
      if (location.pathname.includes(`/${NavPaths.Settings}`)) {
        await shopify.saveBar.leaveConfirmation();
        handleRedirectBack();
      } else {
        setModalActive(true);
      }
    } else {
      handleRedirectBack();
    }
  }, [
    isDebugOrLocal,
    isUnsavedChanges,
    location,
    handleRedirectBack,
    setModalActive,
  ]);

  const {
    data: activityLogData,
    isFetching: activityLogIsFetching,
    refetch: refetchActivityLog,
  } = useGetSettingsV6AccountAuditLogsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      itemsPerPage: 10,
      page: page,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const convertDateString = useCallback((dateString: string): string => {
    const date = new Date(dateString);
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZoneName: 'short',
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(
      date
    );
    const formattedTime = new Intl.DateTimeFormat('en-US', timeOptions).format(
      date
    );
    return `${formattedDate} at ${formattedTime}`;
  }, []);

  useEffect(() => {
    if (activityLogData && page === 1) {
      setActivityLogState(activityLogData.items);
    } else if (activityLogData) {
      setActivityLogState((prev) => [
        ...(prev || []),
        ...(activityLogData.items || []),
      ]);
    }
  }, [activityLogData]);

  return (
    <>
      <div className='ActivityLogPage'>
        {activityLogData ? (
          <Page
            title={i18n.translate(`ActivityLogTitle`)}
            subtitle={i18n.translate(`ActivityLogSubtitle`)}
            secondaryActions={[
              {
                content: i18n.translate('Refresh'),
                onAction: () => {
                  setPage(1);
                  refetchActivityLog();
                },
              },
            ]}
            backAction={{ content: 'Account', onAction: onBackActionClick }}
          >
            <Card padding='0'>
              <ResourceList
                resourceName={{ singular: 'auditLog', plural: 'auditLog' }}
                items={activityLogState || []}
                emptyState={!activityLogState?.length}
                renderItem={(item) => {
                  const { date, performedBy, action } = item;
                  return (
                    <div className='logItem'>
                      <Box padding='400' width='100%'>
                        <BlockStack gap='100'>
                          <Text as='p' variant='bodyMd'>
                            {performedBy +
                              ' ' +
                              action?.charAt(0).toLowerCase() +
                              action?.slice(1)}
                          </Text>
                          <Text as='p' tone='subdued'>
                            {convertDateString(date as string)}
                          </Text>
                        </BlockStack>
                      </Box>
                      <Divider />
                    </div>
                  );
                }}
              />
              {activityLogIsFetching && (
                <InlineStack align='center'>
                  <Box paddingBlock='500'>
                    <Loader />
                  </Box>
                </InlineStack>
              )}
              <Bleed marginInline='400' marginBlockEnd='400'>
                <Box background='bg-surface-secondary' padding='400'>
                  <InlineStack gap='100' align='center'>
                    <Button
                      variant='plain'
                      onClick={() => setPage((prev) => prev + 1)}
                      disabled={
                        !((activityLogData?.totalItems as number) / page > 10)
                      }
                    >
                      {i18n.translate('LoadMore')}
                    </Button>
                  </InlineStack>
                </Box>
              </Bleed>
            </Card>
          </Page>
        ) : (
          <SettingsSkeleton />
        )}
      </div>
      <SettingsConfirmationModal
        modalActive={modalActive}
        handleModalClose={handleModalClose}
        handleLeavePage={handleRedirectBack}
      />
    </>
  );
};
