import React, { useState } from 'react';
import {
  BlockStack,
  Button,
  Card,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import ChangeTriggerModal from '../ChangeTriggerModal/ChangeTriggerModal';
import { TriggerTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useI18n } from '@shopify/react-i18n';
import { TriggerTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';

type ChangeTriggerSectionProps = {
  triggerType?: TriggerTypeDto;
  setTriggerType: (value: TriggerTypeDtoEnum) => void;
};
export const ChangeTriggerSection: React.FC<ChangeTriggerSectionProps> = ({
  triggerType,
  setTriggerType,
}) => {
  const [i18n] = useI18n();
  const [triggerModalOpen, setTriggerModalOpen] = useState<boolean>(false);

  return (
    <Card>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='center'>
          <BlockStack gap='100'>
            <Text as='h2' fontWeight='semibold'>
              {i18n.translate('Trigger', {
                selected: `: ${i18n.translate(`${triggerType}_TRIGGER`)}`,
              })}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('TriggerSubtitle')}
            </Text>
          </BlockStack>
          <Button onClick={() => setTriggerModalOpen(true)}>
            {i18n.translate('Change')}
          </Button>
        </InlineStack>
      </BlockStack>
      {triggerModalOpen && (
        <ChangeTriggerModal
          selectedTrigger={triggerType as TriggerTypeDtoEnum}
          isOpen={triggerModalOpen}
          onClose={() => setTriggerModalOpen(false)}
          onSave={(value) => {
            setTriggerType(value);
            setTriggerModalOpen(false);
          }}
        />
      )}
    </Card>
  );
};
