import React, { useCallback, useMemo, useState } from 'react';
import {
  BlockStack,
  Box,
  Checkbox,
  Link,
  Modal,
  Text,
  TextField,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useConfigureSettings } from 'features/settings/hooks/useConfigureSettings';
import { CancelSubscriptionRequestDto } from 'core/api/adminSettings/adminSettingsApi';
import { CancelSubscriptionPlanReasonDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
type CancelSubscriptionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  refetchCurrentPlanData: () => void;
};
export const CancelSubscriptionModal: React.FC<
  CancelSubscriptionModalProps
> = ({ isOpen, onClose, refetchCurrentPlanData }) => {
  const { cancelSubscriptionIsLoading, cancelSubscription } =
    useConfigureSettings();
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [requestSetup, setRequestSetup] =
    useState<CancelSubscriptionRequestDto>({});
  const [i18n] = useI18n();

  const handleReasons = useCallback(
    (value: CancelSubscriptionPlanReasonDtoEnum) => {
      const exist = requestSetup.reasons?.includes(value);
      setRequestSetup({
        ...requestSetup,
        reasons: exist
          ? requestSetup.reasons?.filter((reason) => reason !== value)
          : [...(requestSetup.reasons || []), value],
      });
    },
    [requestSetup.reasons]
  );

  const handleContactClick = useCallback(() => {
    window.open('mailto:support@discountninja.io', '_blank');
  }, []);

  const reasons = useMemo(
    () =>
      Object.values(CancelSubscriptionPlanReasonDtoEnum).map((value) => {
        const isSelected = requestSetup.reasons?.includes(value);
        return (
          <Box
            borderRadius='200'
            key={value}
            padding='300'
            borderColor={isSelected ? 'border-tertiary' : 'border'}
            background={isSelected ? 'bg-surface-selected' : 'bg-surface'}
            borderWidth='025'
          >
            <BlockStack gap='400'>
              <Checkbox
                checked={isSelected}
                onChange={() => handleReasons(value)}
                label={i18n.translate(`${value}_TITLE`)}
              />
              {requestSetup.reasons?.includes(
                CancelSubscriptionPlanReasonDtoEnum.OTHER
              ) &&
                value === CancelSubscriptionPlanReasonDtoEnum.OTHER && (
                  <TextField
                    autoComplete=''
                    label={i18n.translate('SpecifyTheReason')}
                    value={requestSetup.otherReason || ''}
                    onChange={(value) =>
                      setRequestSetup({ ...requestSetup, otherReason: value })
                    }
                  />
                )}
            </BlockStack>
          </Box>
        );
      }),
    [requestSetup]
  );

  return (
    <Modal
      open={isOpen}
      title={i18n.translate(
        nextStep ? 'ConfirmCancelation' : 'CancelSubscription'
      )}
      onClose={onClose}
      primaryAction={
        nextStep
          ? {
              destructive: true,
              content: i18n.translate('CancelSubscription'),
              onAction: () =>
                cancelSubscription(requestSetup, refetchCurrentPlanData),
              loading: cancelSubscriptionIsLoading,
            }
          : {
              content: i18n.translate('Next'),
              disabled: !requestSetup.reasons?.length,
              onAction: () => setNextStep(true),
            }
      }
      secondaryActions={
        nextStep
          ? [
              {
                content: i18n.translate('KeepSubscription'),
                onAction: onClose,
                disabled: cancelSubscriptionIsLoading,
              },
            ]
          : []
      }
    >
      {!nextStep ? (
        <Modal.Section>
          <BlockStack gap='400'>
            <Text as='p'>{i18n.translate('LetUsKnow')}</Text>
            <BlockStack gap='300'>
              <Text as='p' fontWeight='semibold'>
                {i18n.translate('WhatMainReason')}
              </Text>
              {reasons}
            </BlockStack>
          </BlockStack>
        </Modal.Section>
      ) : (
        <Modal.Section>
          <BlockStack gap='400'>
            <Text as='p'>
              {i18n.translate('CancelingMeaning1', {
                unpublished: (
                  <Text as='span' fontWeight='medium'>
                    {i18n.translate('AllPromotionsUnpublished')}
                  </Text>
                ),
              })}
            </Text>
            <Box>
              <Text as='p' fontWeight='medium'>
                {i18n.translate('CancelingMeaning2')}
              </Text>
              <Text as='p'>
                {i18n.translate('CancelingMeaning3', {
                  contactUs: (
                    <Link onClick={handleContactClick}>
                      {i18n.translate('ContactUs')}
                    </Link>
                  ),
                })}
              </Text>
            </Box>
          </BlockStack>
        </Modal.Section>
      )}
    </Modal>
  );
};
