import React, { useCallback, useMemo, useState } from 'react';
import { BlockStack, Modal, TextField, Text, Banner } from '@shopify/polaris';
import {
  PromotionOverviewDetailsDto,
  PromotionStatusDto,
  TriggerTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useConfigurePromotions } from 'features/promotions/hooks/useConfigurePromotion';
import {
  PromotionStatusDtoEnum,
  TagCategoryDtoEnum,
  TriggerTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useI18n } from '@shopify/react-i18n';
import { MultiselectTagCombobox } from 'core/components/MultiSelectComboBox/MultiSelectComboBox';
import { useTags } from 'core/hooks/useTags';

type EditDetailsModalProps = {
  isOpen: boolean;
  promotionDetails: PromotionOverviewDetailsDto;
  triggerType?: TriggerTypeDto;
  status?: PromotionStatusDto;
  refetchPromotionOverview: () => void;
  onClose: () => void;
};
const EditDetailsModal: React.FC<EditDetailsModalProps> = ({
  isOpen,
  promotionDetails,
  triggerType,
  status,
  refetchPromotionOverview,
  onClose,
}) => {
  const [i18n] = useI18n();
  const { updatePromotionDetails, updatePromotionDetailsIsLoading } =
    useConfigurePromotions();
  const { tagList: availableTags } = useTags(
    false,
    TagCategoryDtoEnum.PROMOTION
  );

  const [details, setDetails] =
    useState<PromotionOverviewDetailsDto>(promotionDetails);

  const showSecretDealWarning = useMemo(
    () =>
      triggerType === TriggerTypeDtoEnum.PRIVATE &&
      details.token !== promotionDetails.token,
    [details.token, promotionDetails.token, triggerType]
  );

  const isTokenEditable = useMemo(
    () => status === PromotionStatusDtoEnum.DRAFT,
    [status]
  );
  const onSavePromotionDetails = useCallback(() => {
    updatePromotionDetails({
      ...details,
      description: details.description ? details.description : '',
    }).then(() => {
      onClose();
      refetchPromotionOverview();
    });
  }, [details, updatePromotionDetails]);

  return (
    <Modal
      title={i18n.translate('EditPromotionDetails')}
      open={isOpen}
      primaryAction={{
        content: i18n.translate('Save'),
        disabled: !details.name || !details.token,
        onAction: onSavePromotionDetails,
        loading: updatePromotionDetailsIsLoading,
        id: 'SavePromotionDetailsButton',
      }}
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        },
      ]}
      onClose={onClose}
    >
      <Modal.Section>
        <BlockStack gap='500'>
          <TextField
            autoComplete=''
            label={i18n.translate('PromotionName')}
            placeholder={i18n.translate('NamePlaceholder')}
            value={details.name}
            helpText={i18n.translate('ForInternal')}
            onChange={(value) =>
              setDetails((prev) => ({ ...prev, name: value }))
            }
          />
          <TextField
            autoComplete=''
            maxLength={20}
            label={i18n.translate('Token')}
            readOnly={!isTokenEditable}
            id='PromotionTokenInput'
            helpText={
              isTokenEditable ? (
                <BlockStack gap='200'>
                  <Text as='p' tone='subdued'>
                    {i18n.translate('TokenHelpText')}
                  </Text>
                  {showSecretDealWarning && (
                    <Banner tone='warning'>
                      {i18n.translate('SecretDealTokenNote')}
                    </Banner>
                  )}
                </BlockStack>
              ) : null
            }
            value={details.token}
            onChange={(value) => {
              if (!/\s/.test(value)) {
                setDetails((prev) => ({ ...prev, token: value }));
              }
            }}
          />
          <TextField
            maxHeight={120}
            autoComplete=''
            label={i18n.translate('Description')}
            placeholder={i18n.translate('DescriptionPlaceholder')}
            multiline
            value={details.description || ''}
            showCharacterCount
            maxLength={500}
            onChange={(value) =>
              setDetails((prev) => ({ ...prev, description: value }))
            }
          />
          <MultiselectTagCombobox
            id='PromotionTags'
            requiredIndicator={false}
            label={i18n.translate('Tags')}
            suggestions={availableTags}
            placeholder={i18n.translate('SearchTags')}
            labelHidden={false}
            selectedTags={details?.tags || []}
            setSelectedTags={(tags: string[]) =>
              setDetails((prev) => ({ ...prev, tags }))
            }
          />
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};

export default EditDetailsModal;
