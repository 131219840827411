import React, { useCallback } from 'react';
import {
  Background,
  CycleOffers,
  Icon,
  Indicators,
  ShopSettingsWrapper,
  WidgetOptionHeader,
} from '../../../../../components';
import {
  AnnouncementBarPrimaryBannerGeneralPromotionDto,
  AnnouncementBarPrimaryBannerSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import {
  BorderSideDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { AnnouncementBarAnimations } from '../../../../../EditPromotionRightBar/components/PromotionAnnouncementBar/components/AnnouncementBarGeneral/components/AnnouncementBarAnimations/AnnouncementBarAnimations';
import { isEqual } from 'lodash';
import { useAppDispatch } from 'core/hooks';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';

type AnnoucementBarBannerProps = {
  banner: AnnouncementBarPrimaryBannerGeneralPromotionDto;
  defaultBanner: AnnouncementBarPrimaryBannerGeneralPromotionDto;
  shopDefaultBanner: AnnouncementBarPrimaryBannerSystemDefaultsDto;
  isPresetEditor?: boolean;
  isPrimary?: boolean;
  onShowHide?: () => void;
  setBanner: (data: AnnouncementBarPrimaryBannerGeneralPromotionDto) => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const AnnoucementBarBanner: React.FC<AnnoucementBarBannerProps> = ({
  banner,
  defaultBanner,
  shopDefaultBanner,
  isPresetEditor,
  isPrimary,
  onShowHide,
  setBanner,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const handleBannerOptions = useCallback(
    (
      field: keyof AnnouncementBarPrimaryBannerGeneralPromotionDto,
      data: AnnouncementBarPrimaryBannerGeneralPromotionDto[keyof AnnouncementBarPrimaryBannerGeneralPromotionDto]
    ) => {
      setBanner({
        ...banner,
        [field]: data,
      });
    },
    [banner, setBanner]
  );

  const resetAllToSystemDefault = useCallback(() => {
    const { background, chevronsColor, closeButtonColor, indicatorsColor } =
      shopDefaultBanner;
    setBanner({
      ...banner,
      background,
      ...(isPrimary && {
        shopSettings: {
          ...banner.shopSettings,
          chevrons: {
            ...banner.shopSettings?.chevrons,
            value: {
              ...banner.shopSettings?.chevrons?.value,
              color: chevronsColor,
            },
          },
          closeButton: {
            ...banner.shopSettings?.closeButton,
            value: {
              ...banner.shopSettings?.closeButton?.value,
              color: closeButtonColor,
            },
          },
          indicators: {
            ...banner.shopSettings?.indicators,
            value: {
              ...banner.shopSettings?.indicators,
              color: indicatorsColor,
            },
          },
        },
      }),
    });
  }, [shopDefaultBanner, banner]);

  return (
    <div className='AnnoucementBarPrimary'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setBanner(defaultBanner)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.PRIMARY_MULTI_SLOT}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Background
          background={banner.background || {}}
          inherited={
            !isPresetEditor
              ? isEqual(banner.background, defaultBanner.background)
              : undefined
          }
          borderSides={
            isPrimary
              ? [BorderSideDtoEnum.TOP, BorderSideDtoEnum.BOTTOM]
              : [BorderSideDtoEnum.BOTTOM, BorderSideDtoEnum.MIDDLE]
          }
          setBackground={(data) => handleBannerOptions('background', data)}
          onSystemReset={() =>
            handleBannerOptions('background', shopDefaultBanner.background)
          }
          allowMultipleSides
        />
      </div>
      {isPrimary && (
        <ShopSettingsWrapper
          onOpen={() =>
            dispatch(
              setToggleSettingsConfig({
                action: 'open',
                path: SettingsModalPathsEnums.AnnouncementBar,
              })
            )
          }
        >
          <div className='RightSideSection'>
            <CycleOffers
              cycling={banner.shopSettings?.cycling || {}}
              disabled
            />
          </div>
          <div className='RightSideSection'>
            <AnnouncementBarAnimations
              animations={banner.shopSettings?.animations || {}}
              disabled
            />
          </div>
          <div className='RightSideSection'>
            <Icon
              icon={banner.shopSettings?.chevrons?.value || {}}
              onlyColor
              showIcon
              inherited={
                !isPresetEditor
                  ? isEqual(
                      banner.shopSettings?.chevrons,
                      defaultBanner.shopSettings?.chevrons
                    )
                  : undefined
              }
              title={i18n.translate('Chevrons')}
              groupKey={ResourceGroupKeyDtoEnum.CHEVRONS}
              setIcon={(data) =>
                handleBannerOptions('shopSettings', {
                  ...banner.shopSettings,
                  chevrons: {
                    ...banner.shopSettings?.chevrons,
                    value: data,
                  },
                })
              }
              onSystemReset={() =>
                handleBannerOptions('shopSettings', {
                  ...banner.shopSettings,
                  chevrons: {
                    ...banner.shopSettings?.chevrons,
                    value: {
                      ...banner.shopSettings?.chevrons?.value,
                      color: shopDefaultBanner.chevronsColor,
                    },
                  },
                })
              }
            />
          </div>
          <div className='RightSideSection'>
            <Icon
              icon={banner.shopSettings?.closeButton?.value || {}}
              onlyColor
              showIcon
              inherited={
                !isPresetEditor
                  ? isEqual(
                      banner.shopSettings?.closeButton,
                      defaultBanner.shopSettings?.closeButton
                    )
                  : undefined
              }
              title={i18n.translate('CloseButton')}
              groupKey={ResourceGroupKeyDtoEnum.CLOSE_BUTTON}
              setIcon={(data) =>
                handleBannerOptions('shopSettings', {
                  ...banner.shopSettings,
                  closeButton: {
                    ...banner.shopSettings?.closeButton,
                    value: data,
                  },
                })
              }
              onSystemReset={() =>
                handleBannerOptions('shopSettings', {
                  ...banner.shopSettings,
                  closeButton: {
                    ...banner.shopSettings?.closeButton,
                    value: {
                      ...banner.shopSettings?.closeButton?.value,
                      color: shopDefaultBanner.closeButtonColor,
                    },
                  },
                })
              }
            />
          </div>
          <div className='RightSideSection'>
            <Indicators
              indicators={banner.shopSettings?.indicators || {}}
              inherited={
                !isPresetEditor
                  ? isEqual(
                      banner.shopSettings?.indicators,
                      defaultBanner.shopSettings?.indicators
                    )
                  : undefined
              }
              onSystemReset={() =>
                handleBannerOptions('shopSettings', {
                  ...banner.shopSettings,
                  indicators: {
                    ...banner.shopSettings?.indicators,
                    value: {
                      ...banner.shopSettings?.indicators?.value,
                      color: shopDefaultBanner.indicatorsColor,
                    },
                  },
                })
              }
              onlyColor
              setIndicators={(data) =>
                handleBannerOptions('shopSettings', {
                  ...banner.shopSettings,
                  indicators: data,
                })
              }
            />
          </div>
        </ShopSettingsWrapper>
      )}
    </div>
  );
};

export default AnnoucementBarBanner;
