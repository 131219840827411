import { BlockStack, Box, Checkbox, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCombinationDto,
  OfferCombinationDetailsDto,
  OfferCombinationsDto,
  OfferCombinationStackingBehaviorDto,
  OfferRelationalCombinationTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferRelationalCombinationTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CanCombineWithModal } from '../CanCombineWithModal/CanCombineWithModal';
import { MultipleDiscountsHelp } from '../MultipleDiscountsHelp/MultipleDiscountsHelp';

export type CombinationsOrderDiscountProps = {
  onCombinationsDraftStateUpdate: (data: OfferCombinationsDto) => void;
  shippingDiscounts?: OfferCombinationDetailsDto;
  productDiscounts?: OfferCombinationDetailsDto;
  orderDiscounts?: OfferCombinationDetailsDto;
  setShowBanner: (val: boolean) => void;
};

export const CombinationsOrderDiscount: React.FC<
  CombinationsOrderDiscountProps
> = (props) => {
  const {
    productDiscounts,
    shippingDiscounts,
    orderDiscounts,
    onCombinationsDraftStateUpdate,
    setShowBanner,
  } = props;
  const [i18n] = useI18n();

  const productDiscountsCheckedDefault = useMemo(
    () => !!(productDiscounts?.isEnforced || productDiscounts?.enabled),
    [productDiscounts?.isEnforced, productDiscounts?.enabled]
  );
  const shippingDiscountsCheckedDefault = useMemo(
    () => !!(shippingDiscounts?.isEnforced || shippingDiscounts?.enabled),
    [shippingDiscounts?.isEnforced, shippingDiscounts?.enabled]
  );
  const orderDiscountsCheckedDefault = useMemo(
    () => !!(orderDiscounts?.isEnforced || orderDiscounts?.enabled),
    [orderDiscounts?.isEnforced, orderDiscounts?.enabled]
  );

  const [productDiscountsState, setProductDiscountsState] =
    useState<OfferCombinationDto>({
      enabled: productDiscountsCheckedDefault,
      stackingBehavior: productDiscounts?.stackingBehavior,
    });

  const [shippingDiscountsState, setShippingDiscountsState] =
    useState<OfferCombinationDto>({
      enabled: shippingDiscountsCheckedDefault,
      stackingBehavior: shippingDiscounts?.stackingBehavior,
    });

  const [orderDiscountsState, setOrderDiscountsState] =
    useState<OfferCombinationDto>({
      enabled: orderDiscountsCheckedDefault,
      stackingBehavior: orderDiscounts?.stackingBehavior,
    });

  useEffect(() => {
    setShowBanner(
      !!productDiscountsState.enabled &&
        [orderDiscountsState.enabled, shippingDiscountsState.enabled].includes(
          true
        )
    );
  }, [orderDiscountsState, shippingDiscountsState, productDiscountsState]);

  const handleOrderDiscountsChange = useCallback(
    (newChecked: boolean) => {
      setOrderDiscountsState((prevState) => ({
        ...prevState,
        enabled: newChecked,
      }));
    },
    [setOrderDiscountsState]
  );

  const handleProductDiscountsChange = useCallback(
    (newChecked: boolean) => {
      setProductDiscountsState((prevState) => ({
        ...prevState,
        enabled: newChecked,
      }));
    },
    [setProductDiscountsState]
  );

  const handleShippingDiscountsChange = useCallback(
    (newChecked: boolean) => {
      setShippingDiscountsState((prevState) => ({
        ...prevState,
        enabled: newChecked,
      }));
    },
    [setShippingDiscountsState]
  );

  const handleTypeStackingChange = useCallback(
    (
      type: OfferRelationalCombinationTypeDto,
      value: OfferCombinationStackingBehaviorDto
    ) => {
      if (
        type === OfferRelationalCombinationTypeDtoEnum.PRODUCT ||
        type === OfferRelationalCombinationTypeDtoEnum.OTHER_PRODUCT
      ) {
        setProductDiscountsState((prevState) => ({
          ...prevState,
          stackingBehavior: value,
        }));
      } else if (
        type === OfferRelationalCombinationTypeDtoEnum.ORDER ||
        type === OfferRelationalCombinationTypeDtoEnum.OTHER_ORDER
      ) {
        setOrderDiscountsState((prevState) => ({
          ...prevState,
          stackingBehavior: value,
        }));
      } else {
        setShippingDiscountsState((prevState) => ({
          ...prevState,
          stackingBehavior: value,
        }));
      }
    },
    [setOrderDiscountsState, setProductDiscountsState]
  );

  useEffect(() => {
    onCombinationsDraftStateUpdate({
      shipping: {
        ...shippingDiscountsState,
      },
      product: {
        ...productDiscountsState,
      },
      order: {
        ...orderDiscountsState,
      },
    });
  }, [
    productDiscountsState,
    orderDiscountsState,
    shippingDiscountsState,
    onCombinationsDraftStateUpdate,
  ]);

  return (
    <>
      <Box paddingBlockEnd='300'>
        <Text as='h2'>{i18n.translate('CombinationsOrderDiscount.Title')}</Text>
      </Box>{' '}
      <BlockStack>
        <div>
          <Checkbox
            id='CombinationsProductDiscountCheckbox'
            label={i18n.translate('CombinationsOrderDiscount.ProductDiscounts')}
            checked={productDiscountsState.enabled}
            disabled={productDiscounts?.isEnforced}
            onChange={handleProductDiscountsChange}
          />
          {productDiscountsState.enabled && (
            <>
              <Box paddingInlineStart='600'>
                <Text as='span' tone='subdued'>
                  <CanCombineWithModal
                    type={productDiscounts?.type}
                    count={productDiscounts?.combinedCount || 0}
                    text={i18n.translate(
                      'CombinationsOrderDiscount.CheckboxInfo.ProductDiscounts'
                    )}
                    modalTitle={i18n.translate(
                      'CombinationsOrderDiscount.ProductDiscountModalTitle'
                    )}
                  />
                  {i18n.translate(
                    'CombinationsOrderDiscount.CheckboxInfo.SetToCombine',
                    {
                      irregular:
                        (productDiscounts?.combinedCount || 0) > 1
                          ? i18n.translate(
                              'CombinationsOrderDiscount.CheckboxInfo.are'
                            )
                          : i18n.translate(
                              'CombinationsOrderDiscount.CheckboxInfo.is'
                            ),
                    }
                  )}
                </Text>
              </Box>
              {/* <MultipleDiscountsHelp
                defaultValue={productDiscountsState.stackingBehavior}
                handleTypeStackingChange={handleTypeStackingChange}
              /> */}
            </>
          )}
        </div>
        <div>
          <Checkbox
            id='CombinationsOrderDiscountCheckbox'
            label={i18n.translate('CombinationsOrderDiscount.OrderDiscounts')}
            checked={orderDiscountsState.enabled}
            disabled={orderDiscounts?.isEnforced}
            onChange={handleOrderDiscountsChange}
          />
          {orderDiscountsState.enabled && (
            <>
              <Box paddingInlineStart='600'>
                <Text as='span' tone='subdued'>
                  <CanCombineWithModal
                    type={orderDiscounts?.type}
                    count={orderDiscounts?.combinedCount || 0}
                    text={i18n.translate(
                      'CombinationsOrderDiscount.CheckboxInfo.OrderDiscounts'
                    )}
                    modalTitle={i18n.translate(
                      'CombinationsOrderDiscount.OrderDiscountModalTitle'
                    )}
                  />
                  {i18n.translate(
                    'CombinationsOrderDiscount.CheckboxInfo.SetToCombine',
                    {
                      irregular:
                        (orderDiscounts?.combinedCount || 0) > 1
                          ? i18n.translate(
                              'CombinationsOrderDiscount.CheckboxInfo.are'
                            )
                          : i18n.translate(
                              'CombinationsOrderDiscount.CheckboxInfo.is'
                            ),
                    }
                  )}
                </Text>
              </Box>
              <MultipleDiscountsHelp
                defaultValue={orderDiscountsState.stackingBehavior}
                handleTypeStackingChange={handleTypeStackingChange}
                isOtherOrderDiscount
              />
            </>
          )}
        </div>
        <div>
          <Checkbox
            id='CombinationsShippingDiscountCheckbox'
            label={i18n.translate(
              'CombinationsOrderDiscount.ShippingDiscounts'
            )}
            checked={shippingDiscountsState.enabled}
            disabled={shippingDiscounts?.isEnforced}
            onChange={handleShippingDiscountsChange}
          />
          {shippingDiscountsState.enabled && (
            <>
              <Box paddingInlineStart='600'>
                <Text as='span' tone='subdued'>
                  <CanCombineWithModal
                    type={shippingDiscounts?.type}
                    count={shippingDiscounts?.combinedCount || 0}
                    text={i18n.translate(
                      'CombinationsOrderDiscount.CheckboxInfo.ShippingDiscounts'
                    )}
                    modalTitle={i18n.translate(
                      'CombinationsOrderDiscount.ShippingDiscountModalTitle'
                    )}
                  />
                  {i18n.translate(
                    'CombinationsOrderDiscount.CheckboxInfo.SetToCombine',
                    {
                      irregular:
                        (shippingDiscounts?.combinedCount || 0) > 1
                          ? i18n.translate(
                              'CombinationsOrderDiscount.CheckboxInfo.are'
                            )
                          : i18n.translate(
                              'CombinationsOrderDiscount.CheckboxInfo.is'
                            ),
                    }
                  )}
                </Text>
              </Box>
              {/* <MultipleDiscountsHelp
                defaultValue={shippingDiscountsState.stackingBehavior}
                handleTypeStackingChange={handleTypeStackingChange}
              /> */}
            </>
          )}
        </div>
      </BlockStack>
    </>
  );
};
