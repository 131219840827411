import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  SchedulePromotionRequestDto,
  PromotionOverviewScheduleDto,
  PromotionTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { DateTimePicker } from 'core/components';
import { Banner, BlockStack, Modal, Text } from '@shopify/polaris';
import { PromotionTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import moment from 'moment';

export enum ScheduleModalEnum {
  END_DATE = 'END_DATE',
  START_DATE = 'START_DATE',
}

type SchedulePromotionModalProps = {
  type: ScheduleModalEnum | null;
  promotionType: PromotionTypeDto;
  schedule: PromotionOverviewScheduleDto;
  timezone: string;
  onClose: () => void;
  onSchedulePromotion: (data: SchedulePromotionRequestDto) => void;
};
export const SchedulePromotionModal: React.FC<SchedulePromotionModalProps> = ({
  type,
  promotionType,
  schedule,
  timezone,
  onClose,
  onSchedulePromotion,
}) => {
  const [i18n] = useI18n();
  const [schedulePromotionSetup, setSchedulePromotionSetup] =
    useState<SchedulePromotionRequestDto>({});

  const isStartDateValid = useMemo(
    () =>
      type === ScheduleModalEnum.START_DATE && schedule.endsAt
        ? new Date(schedulePromotionSetup.startDate?.value as number) <
          new Date(schedule.endsAt as number)
        : true,
    [schedulePromotionSetup.startDate, schedule.endsAt]
  );

  const isEndDateValid = useMemo(
    () =>
      type === ScheduleModalEnum.END_DATE && schedule.startsAt
        ? new Date(schedule.startsAt as number) <
          new Date(schedulePromotionSetup.endsDate?.value as number)
        : true,
    [schedule.startsAt, schedulePromotionSetup.endsDate]
  );

  const isDateInThePast = useMemo(() => {
    return type === ScheduleModalEnum.END_DATE
      ? new Date(schedulePromotionSetup.endsDate?.value || 0) < new Date()
      : new Date(schedulePromotionSetup.startDate?.value || 0) < new Date();
  }, [schedulePromotionSetup]);

  const isDateValid = useMemo(
    () =>
      (type === ScheduleModalEnum.END_DATE
        ? isEndDateValid
        : isStartDateValid) && !isDateInThePast,
    [isStartDateValid, isEndDateValid, isDateInThePast]
  );

  const conclusionText = useMemo(() => {
    if (isDateValid) {
      return type === ScheduleModalEnum.START_DATE
        ? i18n.translate('PromotionWillStart', {
            date: moment(
              schedulePromotionSetup.startDate?.value || schedule.startsAt
            ).fromNow(),
          })
        : i18n.translate('PromotionWillEnd', {
            date: moment(schedulePromotionSetup.endsDate?.value).fromNow(),
          });
    }
    if (!isEndDateValid || !isStartDateValid) {
      return (
        <Text fontWeight='semibold' as='span' tone='critical'>
          {i18n.translate(
            !isEndDateValid ? 'InvalidEndDate' : 'InvalidStartDate'
          )}
        </Text>
      );
    }
  }, [
    isEndDateValid,
    isStartDateValid,
    isDateValid,
    schedulePromotionSetup,
    schedule.startsAt,
  ]);

  const bottomSectionText = useMemo(
    () =>
      promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
      !schedulePromotionSetup.startDate
        ? null
        : conclusionText,
    [schedulePromotionSetup, promotionType]
  );
  const initialDate = useMemo(() => {
    const currentDate = new Date();
    return currentDate.setHours(currentDate.getHours() + 24);
  }, []);

  const handleSaveScheduling = useCallback(() => {
    onSchedulePromotion(schedulePromotionSetup);
    onClose();
  }, [schedulePromotionSetup]);

  const handleScheduleDate = useCallback(
    (field: keyof SchedulePromotionRequestDto, value: number) =>
      setSchedulePromotionSetup((prev) => ({
        ...prev,
        [field]: {
          ...prev?.[field],
          value: value,
        },
      })),
    [setSchedulePromotionSetup]
  );

  useEffect(() => {
    setSchedulePromotionSetup({
      ...(type === ScheduleModalEnum.START_DATE && {
        startDate: {
          enabled: true,
          value: schedule.startsAt,
        },
      }),
      ...(type === ScheduleModalEnum.END_DATE && {
        endsDate: {
          enabled: true,
          value: schedule.endsAt || schedulePromotionSetup.endsDate?.value,
        },
      }),
    });
  }, [schedule.endsAt, schedule.startsAt]);

  return (
    <Modal
      title={i18n.translate(`${type}_TITLE`)}
      open={!!type}
      footer={bottomSectionText}
      primaryAction={{
        content: i18n.translate('Save'),
        disabled: !isDateValid,
        onAction: handleSaveScheduling,
      }}
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        },
      ]}
      onClose={onClose}
    >
      <div className='SchedulePromotionModalContent'>
        {promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
        !schedulePromotionSetup.startDate ? null : (
          <Modal.Section>
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                {promotionType !==
                  PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE && (
                  <Text tone='subdued' as='p'>
                    {i18n.translate(`${type}_SUBTITLE`)}
                  </Text>
                )}
                {promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
                  !schedulePromotionSetup.endsDate?.value && (
                    <Text tone='subdued' as='p'>
                      {i18n.translate('NoEndDateConfigured')}
                    </Text>
                  )}
              </BlockStack>
              {type === ScheduleModalEnum.START_DATE ? (
                <DateTimePicker
                  dateLabel={i18n.translate('Date')}
                  timeLabel={i18n.translate('Time')}
                  disabled={
                    promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE
                  }
                  timestampValue={
                    schedulePromotionSetup.startDate?.value || initialDate
                  }
                  error={
                    isDateInThePast ? i18n.translate('DateInPastError') : ''
                  }
                  setTimestampValue={(value) =>
                    handleScheduleDate('startDate', value)
                  }
                />
              ) : (
                <DateTimePicker
                  dateLabel={i18n.translate('Date')}
                  timeLabel={i18n.translate('Time')}
                  error={
                    isDateInThePast ? i18n.translate('DateInPastError') : ''
                  }
                  minDate={schedulePromotionSetup.startDate?.value || undefined}
                  timestampValue={initialDate}
                  disabled={
                    promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE
                  }
                  setTimestampValue={(value) =>
                    handleScheduleDate('endsDate', value)
                  }
                />
              )}
              <Text as='p' tone='subdued'>
                {i18n.translate('Timezone', {
                  timezone: timezone,
                })}
              </Text>
            </BlockStack>
          </Modal.Section>
        )}

        {promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE && (
          <Modal.Section>
            <Banner tone='info'>{i18n.translate('EditDiscountCode')}</Banner>
          </Modal.Section>
        )}
      </div>
    </Modal>
  );
};
