import React, { useCallback, useMemo } from 'react';
import { BlockStack, Card, Text } from '@shopify/polaris';
import './PromotionStatus.scss';
import {
  PromotionOverviewDto,
  PromotionStatusDto,
  SchedulePromotionRequestDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionStatusDtoEnum,
  PromotionTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import ProgressStatus from './components/ProgressStatus/ProgressStatus';
import DefaultStatus from './components/DefaultStatus/DefaultStatus';
import { ScheduleModalEnum } from '../SchedulePromotionModal/SchedulePromotionModal';

type PromotionStatusProps = {
  promotionId: string;
  promotionOverviewData: PromotionOverviewDto;
  scheduleIsLoading: boolean;
  isPromotionEditable: boolean;
  hasOffers: boolean;
  setScheduleModalType: (value: ScheduleModalEnum) => void;
  onSchedulePromotion: (data: SchedulePromotionRequestDto) => void;
};

export const PromotionStatus: React.FC<PromotionStatusProps> = ({
  promotionOverviewData,
  promotionId,
  scheduleIsLoading,
  hasOffers,
  isPromotionEditable,
  setScheduleModalType,
  onSchedulePromotion,
}) => {
  const [i18n] = useI18n();

  const onDeleteEndDate = useCallback(() => {
    onSchedulePromotion({
      endsDate: {
        enabled: false,
        value: null,
      },
    });
  }, [promotionOverviewData.schedule?.startsAt]);

  const onDeleteStartDate = useCallback(() => {
    onSchedulePromotion({
      startDate: {
        enabled: false,
        value: null,
      },
    });
  }, []);

  const SDCScheduleForbidden = useMemo(() => {
    const {
      shopifyDiscountApp,
      shopifyDiscountCode,
      shopifyDiscountGroup,
      promotionType,
    } = promotionOverviewData.settings || {};

    return (
      promotionType === PromotionTypeDtoEnum.SHOPIFY_DISCOUNT_CODE &&
      (shopifyDiscountGroup ||
        shopifyDiscountCode ||
        (!shopifyDiscountGroup && !shopifyDiscountCode && !shopifyDiscountApp))
    );
  }, [promotionOverviewData.settings]);

  const statusType = useMemo(() => {
    switch (promotionOverviewData.status) {
      case PromotionStatusDtoEnum.IN_PROGRESS_PUBLISHING:
      case PromotionStatusDtoEnum.IN_PROGRESS_UNPUBLISHING:
      case PromotionStatusDtoEnum.IN_PROGRESS_ROLLING_BACK:
        return (
          <ProgressStatus
            operationProgress={promotionOverviewData.operationProgress || {}}
            promotionId={promotionId}
          />
        );
      default:
        return (
          <DefaultStatus
            promotionStatus={promotionOverviewData.status as PromotionStatusDto}
            schedule={promotionOverviewData.schedule || {}}
            scheduleIsLoading={scheduleIsLoading}
            isPromotionEditable={
              isPromotionEditable && !SDCScheduleForbidden && hasOffers
            }
            setScheduleModalType={setScheduleModalType}
            onDeleteEndDate={onDeleteEndDate}
            onDeleteStartDate={onDeleteStartDate}
          />
        );
    }
  }, [i18n, promotionOverviewData, scheduleIsLoading, isPromotionEditable]);

  return (
    <Card>
      <BlockStack gap='400'>
        <Text fontWeight='semibold' as='p'>
          {i18n.translate(`Schedule`)}
        </Text>
        {statusType}
      </BlockStack>
    </Card>
  );
};
