import {
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  List,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import React, { useCallback, useMemo, useState } from 'react';
import BudgetModal from './components/BudgetModal/BudgetModal';
import { TriggerMaximumUsesFilterDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { ChartuUnitOfMeasurementEnum } from 'pages';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
type SettingBudgetProps = {
  budget?: TriggerMaximumUsesFilterDto;
  updateSettingsState: (data: TriggerMaximumUsesFilterDto) => void;
};
export const SettingBudget: React.FC<SettingBudgetProps> = ({
  budget,
  updateSettingsState,
}) => {
  const [i18n] = useI18n();
  const { formatValue } = useNumericFormatter();

  const [budgetModalOpen, setBudgetModalOpen] = useState<boolean>(false);

  const isNotConfigured = useMemo(
    () =>
      !budget?.limitedUseByAll?.enabled &&
      !budget?.limitedUsePerCustomerEnabled &&
      !budget?.orderCap?.enabled,
    [budget]
  );

  const toggleBudgetModal = useCallback(
    () => setBudgetModalOpen((prev) => !prev),
    []
  );

  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' fontWeight='semibold'>
            {i18n.translate('SettingBudget')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('SettingBudgetSubtitle')}
          </Text>
        </BlockStack>
        <Box
          borderRadius='200'
          padding='400'
          borderWidth='025'
          borderColor='border-brand'
        >
          <InlineStack align='space-between'>
            {!isNotConfigured ? (
              <List type='bullet'>
                {budget?.limitedUseByAll?.enabled && (
                  <List.Item>
                    {i18n.translate('LimitManyTimes', {
                      ordersCount: budget.limitedUseByAll.value,
                    })}
                  </List.Item>
                )}
                {budget?.orderCap?.enabled && (
                  <List.Item>
                    {i18n.translate('CapCount', {
                      capCount: formatValue(
                        budget.orderCap.value,
                        ChartuUnitOfMeasurementEnum.CURRENCY
                      ),
                    })}
                  </List.Item>
                )}
                {budget?.limitedUsePerCustomerEnabled && (
                  <List.Item>{i18n.translate('LimitToOne')}</List.Item>
                )}
              </List>
            ) : (
              <Text as='p' tone='subdued'>
                {i18n.translate('NoLimits')}
              </Text>
            )}
            <Button onClick={toggleBudgetModal} variant='plain'>
              {i18n.translate('Manage')}
            </Button>
          </InlineStack>
        </Box>
      </BlockStack>
      <BudgetModal
        isOpen={budgetModalOpen}
        budget={budget}
        onClose={toggleBudgetModal}
        onSave={(data) => {
          updateSettingsState(data);
          toggleBudgetModal();
        }}
      />
    </Card>
  );
};
