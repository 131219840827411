import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Banner,
  BlockStack,
  Card,
  ChoiceList,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  CountdownClockShopSettingsDto,
  WidgetSettingsDtoCountdownClockShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import { useI18n } from '@shopify/react-i18n';
import { CountdownClockTimerUrgencyTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

export const CountdownClockSettings: React.FC = () => {
  const dispatch = useDispatch();
  const {
    countdownClockData,
    saveCountdownClock,
    countdownClockDataIsFetching,
    refetchCountdownClockData,
  } = useConfigureSettings(SettingsFetchTypeEnum.COUNTDOWN_CLOCK);
  const [i18n] = useI18n();
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoCountdownClockShopSettingsDto>({});
  const [countdownClockState, setCountdownClockState] =
    useState<WidgetSettingsDtoCountdownClockShopSettingsDto>({});

  const updateCountdownClockState = useCallback(
    (
      field: keyof CountdownClockShopSettingsDto,
      data: CountdownClockShopSettingsDto[keyof CountdownClockShopSettingsDto]
    ) =>
      setCountdownClockState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setCountdownClockState]
  );

  const urgencyTypeOptions = useMemo(
    () =>
      Object.values(CountdownClockTimerUrgencyTypeDtoEnum).map((value) => ({
        label: (
          <InlineStack gap='200'>
            {i18n.translate(value)}
            {value === CountdownClockTimerUrgencyTypeDtoEnum.REAL && (
              <Badge>{i18n.translate('Recommended')}</Badge>
            )}
          </InlineStack>
        ),
        value: value,
        helpText: i18n.translate(`${value}_HELP`),
      })),
    []
  );

  useEffect(() => {
    if (!isEmpty(countdownClockData)) {
      setCountdownClockState(countdownClockData);
      setSavedData(countdownClockData);
    }
  }, [countdownClockData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='CountdownClockSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...countdownClockState,
          widgetType: WidgetTypeSettingsWrapperEnum.COUNTDOWN_CLOCK,
        }}
        isLoading={countdownClockDataIsFetching || !countdownClockData}
        refetchWidget={refetchCountdownClockData}
      >
        <BlockStack gap='600'>
          <StyleSetting styled={!!countdownClockData?.isShopLevelWidget} />
          <Card>
            <BlockStack gap='400'>
              <BlockStack gap='100'>
                <Text as='p' fontWeight='bold'>
                  {i18n.translate('UrgencySettings')}
                </Text>
                <Text as='p' tone='subdued'>
                  {i18n.translate('UrgencySubtitle')}
                </Text>
              </BlockStack>
              <ChoiceList
                title=''
                choices={urgencyTypeOptions}
                selected={[
                  countdownClockState.settings
                    ?.timerUrgencyType as CountdownClockTimerUrgencyTypeDtoEnum,
                ]}
                onChange={(value) =>
                  updateCountdownClockState(
                    'timerUrgencyType',
                    value[0] as CountdownClockTimerUrgencyTypeDtoEnum
                  )
                }
              />
              <Banner tone='info'>
                {i18n.translate('BannerText')}
                <Link>{i18n.translate('Here')}</Link>
              </Banner>
            </BlockStack>
          </Card>
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
