import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './ResourceListCountry.scss';
import { useI18n } from '@shopify/react-i18n';
import { ShippingCountryProvinceLookupDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { CheckedResourceProvinceItem } from '../../ShippingCountries';
import {
  Button,
  Checkbox,
  Collapsible,
  InlineStack,
  SkeletonThumbnail,
  Text,
} from '@shopify/polaris';
import { countryRegionMap, RegionType } from '../../ShippingCountriesEnums';

type CheckedProvince = boolean | 'indeterminate';

type ResourceListCountryProps = {
  countryName: string;
  countryCode: string;
  id?: string;
  source: JSX.Element;
  provinces?: ShippingCountryProvinceLookupDto[];
  initialChecked?: boolean;
  initialCheckedProvinces: string[];
  onChecked: (checked: CheckedResourceProvinceItem) => void;
};

export const ResourceListCountry: React.FC<ResourceListCountryProps> = (
  props
) => {
  const {
    countryName,
    countryCode,
    id,
    source,
    provinces,
    initialChecked,
    initialCheckedProvinces,
    onChecked,
  } = props;

  const [i18n] = useI18n();

  const [checked, setChecked] = useState<CheckedProvince>(
    initialChecked || false
  );

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const [checkedProvinces, setCheckedProvinces] = useState<
    (string | undefined)[]
  >(initialCheckedProvinces || []);

  const parsedProvinces = useMemo(
    () => provinces?.map((province) => province.id) || [],
    [provinces]
  );

  const totalProvincesCount = useMemo(
    () => provinces?.length || 0,
    [provinces]
  );
  const selectedProvincesCount = useMemo(
    () => checkedProvinces.length,
    [checkedProvinces]
  );

  const getRegionType = useCallback(
    (countryCode: string): RegionType => {
      return countryRegionMap[countryCode] || RegionType.PROVINCE;
    },
    [countryRegionMap]
  );

  const getRegionTypeTranslation = useCallback(
    (regionType: RegionType): string => {
      return i18n.translate(`regionTypes.${regionType.toLowerCase()}`);
    },
    [i18n]
  );

  const regionType = getRegionTypeTranslation(getRegionType(countryCode));

  const overviewText = useMemo(
    () =>
      selectedProvincesCount
        ? `(${selectedProvincesCount} ${i18n.translate(
            'of'
          )} ${totalProvincesCount} ${regionType} ${i18n.translate(
            'selected'
          )})`
        : '',
    [selectedProvincesCount, totalProvincesCount, regionType, i18n]
  );

  const toggleActive = useCallback((e?: React.MouseEvent<HTMLDivElement>) => {
    e?.stopPropagation();
    setCollapsed((active) => !active);
  }, []);

  const toggleCheck = useCallback(() => {
    setChecked((prev) => {
      if (parsedProvinces.length === 0) {
        return !prev;
      }
      if (!checkedProvinces.length) {
        setCheckedProvinces(parsedProvinces);
        return true;
      } else {
        setCheckedProvinces([]);
        return false;
      }
    });
  }, [checkedProvinces, parsedProvinces, setChecked, setCheckedProvinces]);

  const toggleProvinceCheck = useCallback(
    (provinceId: string) => {
      setCheckedProvinces((prev) =>
        prev.find((el) => el === provinceId)
          ? prev.filter((el) => el !== provinceId)
          : [...prev, provinceId]
      );
    },
    [setCheckedProvinces]
  );

  const isProvinceChecked = useCallback(
    (id: string) => checkedProvinces.includes(id) || false,
    [checkedProvinces]
  );

  useEffect(() => {
    onChecked?.({
      parent: {
        id: id!,
        checked: !!checked,
      },
      provinces: parsedProvinces?.map((province) => ({
        id: province!,
        checked: checked && isProvinceChecked(province!),
      })),
    });
  }, [
    checked,
    checkedProvinces,
    id,
    parsedProvinces,
    onChecked,
    isProvinceChecked,
  ]);

  useEffect(() => {
    if (
      parsedProvinces?.length !== checkedProvinces.length &&
      checkedProvinces.length > 0
    ) {
      setChecked('indeterminate');
      return;
    }

    if (
      !!parsedProvinces?.length &&
      parsedProvinces?.length === checkedProvinces.length
    ) {
      setChecked(true);
      return;
    }

    if (!checkedProvinces.length && !!parsedProvinces?.length) {
      setChecked(false);
      return;
    }
  }, [parsedProvinces, checkedProvinces]);

  return (
    <div className='ResourceListCountry'>
      <div className='ResourceListCountry__Parent' onClick={toggleCheck}>
        <InlineStack blockAlign='center'>
          <InlineStack blockAlign='center' gap='100'>
            <Checkbox label='' checked={checked} />
            <InlineStack blockAlign='center' gap='300'>
              {source ? source : <SkeletonThumbnail size='small' />}
              <Text as='span'>{countryName}</Text>
            </InlineStack>
          </InlineStack>
          {!!provinces?.length && (
            <div className='rightContent'>
              <Text as='span' tone='subdued'>
                {overviewText}
              </Text>
              <div onClick={toggleActive}>
                <Button
                  variant='tertiary'
                  disclosure={collapsed ? 'up' : 'down'}
                />
              </div>
            </div>
          )}
        </InlineStack>
      </div>
      <Collapsible id='provincesCollapse' open={collapsed}>
        {provinces?.map((province) => (
          <div
            className='ResourceListCountry__Variant'
            key={province.id}
            onClick={() => toggleProvinceCheck(province.id!)}
          >
            <InlineStack blockAlign='center' gap='100'>
              <Checkbox label='' checked={isProvinceChecked(province.id!)} />
              <InlineStack blockAlign='center' gap='300'>
                <Text as='span'>{province.name}</Text>
              </InlineStack>
            </InlineStack>
          </div>
        ))}
      </Collapsible>
    </div>
  );
};
