import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import {
  ActionList,
  BlockStack,
  Icon,
  IconSource,
  InlineStack,
  Page,
  Popover,
  Text,
} from '@shopify/polaris';
import { ChevronDownIcon } from '@shopify/polaris-icons';
import './TranslationsSettingPage.scss';
import { TranslateSection } from './components/TranslateSection/TranslateSection';
import {
  TranslationsSettingsDto,
  useGetSettingsV6TranslationsByLocaleQuery,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from '../../../../../hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';

import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import { useDispatch } from 'react-redux';
import resolveEnvVar from 'env-var-resolver';
import { SettingsSkeleton } from '../../../components/SettingsSkeleton/SettingsSkeleton';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { SettingsConfirmationModal } from 'features/settings/components/SettingsConfirmationModal/SettingsConfirmationModal';
import { NavPaths } from 'core/enums/NavPathsEnum';
import { SettingsSavebarRef } from 'features/settings/Settings';

export interface ITranslationSection {
  title: string;
  description: string;
  icon?: boolean;
  infoBadge?: string;
  fields: ILabel[];
}

export interface ILabel {
  label: string;
  value: string;
  field: string;
}

export interface INewFieldData {
  section: string;
  value: string;
  field: string;
}

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const TranslationSettingPage = forwardRef<SettingsSavebarRef>(
  (_, ref) => {
    const APP_PASSWORD =
      localStorage.getItem('passwordDevLogin') || 'limonidev';
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    const isDebugOrLocal = useIsDebugOrLocal();
    const location = useLocation();

    const { isUnsavedChanges } = useAppSelector((state) => state.settings);

    const { translationMainData } = useConfigureSettings(
      SettingsFetchTypeEnum.TRANSLATION_MAIN
    );

    const { translationLocaleDataChange } = useConfigureSettings(
      SettingsFetchTypeEnum.TRANSLATION_LOCALE
    );

    const {
      data: translationLocaleData,
      isFetching: translationLocaleIsFetching,
    } = useGetSettingsV6TranslationsByLocaleQuery(
      {
        locale: `${searchParams.get('language')}`,
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    );

    const [i18n] = useI18n();

    const [active, setActive] = useState<boolean>(false);
    const [modalActive, setModalActive] = useState<boolean>(false);

    const [translationLocaleState, setTranslationLocaleState] =
      useState<TranslationsSettingsDto>({});
    const [savedData, setSavedData] = useState<TranslationsSettingsDto>({});

    const handleSaveChanges = useCallback(() => {
      const onSuccess = () => setSavedData(translationLocaleState);
      return translationLocaleDataChange(
        translationLocaleState,
        `${searchParams.get('language')}`,
        onSuccess
      );
    }, [translationLocaleState, searchParams]);

    const handleRedirectBack = useCallback(() => {
      setSearchParams((params) => {
        params.delete('subPath');
        params.delete('language');
        return params;
      });
      setModalActive(false);
    }, [setSearchParams, setModalActive]);

    const handleModalClose = useCallback(() => {
      setModalActive(false);
    }, [setModalActive]);

    const onBackActionClick = useCallback(async () => {
      if (!isDebugOrLocal && isUnsavedChanges) {
        if (location.pathname.includes(`/${NavPaths.Settings}`)) {
          await shopify.saveBar.leaveConfirmation();
          handleRedirectBack();
        } else {
          setModalActive(true);
        }
      } else {
        handleRedirectBack();
      }
    }, [
      isDebugOrLocal,
      isUnsavedChanges,
      location,
      handleRedirectBack,
      setModalActive,
    ]);

    const toggleActive = useCallback(() => setActive((active) => !active), []);

    const onLevelClick = useCallback(
      (language: string) => {
        setSearchParams((params) => {
          params.set('language', language);
          toggleActive();
          return params;
        });
      },
      [setSearchParams]
    );

    const handleTranslationLocaleState = useCallback(
      (section: string, field: string, value: string) => {
        setTranslationLocaleState((prevState) => ({
          ...prevState,
          [section]: {
            ...prevState[section as keyof typeof prevState],
            [field]: value,
          },
        }));
      },
      [translationLocaleState]
    );

    const activator = useMemo(
      () => (
        <div onClick={toggleActive} className='Link'>
          <InlineStack align='center'>
            <Text as='h1' variant='headingLg' fontWeight='bold'>
              {i18n.translate(`${searchParams.get('language')}`)}
            </Text>
            <Icon source={ChevronDownIcon as IconSource} />
          </InlineStack>
        </div>
      ),
      [toggleActive, i18n, searchParams]
    );

    const items = useMemo(
      () =>
        translationMainData?.locales?.map((locale) => ({
          content: locale.name,
          onAction: () => onLevelClick(`${locale.locale}`),
          disabled:
            searchParams.get('language') === locale.locale ||
            !translationMainData?.planSupportsMultilanguage,
        })) || [],
      [searchParams, translationMainData]
    );
    useImperativeHandle(ref, () => ({
      discardChanges: () => setTranslationLocaleState(savedData),
      saveChanges: handleSaveChanges,
    }));

    useEffect(() => {
      dispatch(
        setIsUnsavedChanges(!isEqual(savedData, translationLocaleState))
      );
    }, [translationLocaleState, savedData]);

    useEffect(() => {
      if (!isEmpty(translationLocaleData)) {
        setTranslationLocaleState(translationLocaleData);
        setSavedData(translationLocaleData);
      }
    }, [translationLocaleData]);

    useEffect(() => {
      return () => {
        dispatch(setIsUnsavedChanges(false));
      };
    }, []);

    return (
      <>
        {!translationLocaleIsFetching && translationLocaleData ? (
          <Page
            backAction={{
              content: 'Translations',
              onAction: onBackActionClick,
            }}
            title={i18n.translate('Translations')}
            titleMetadata={
              <Popover
                active={active}
                activator={activator}
                autofocusTarget='first-node'
                onClose={toggleActive}
              >
                <ActionList actionRole='menuitem' items={items} />
              </Popover>
            }
          >
            <BlockStack gap='600'>
              {translationLocaleState &&
                Object.keys(translationLocaleState).map(
                  (section: string, index: number) => (
                    <TranslateSection
                      key={index}
                      item={
                        translationLocaleState[
                          section as keyof TranslationsSettingsDto
                        ]
                      }
                      section={section}
                      handleTranslationLocaleState={
                        handleTranslationLocaleState
                      }
                    />
                  )
                )}
            </BlockStack>
          </Page>
        ) : (
          <SettingsSkeleton />
        )}
        <SettingsConfirmationModal
          modalActive={modalActive}
          handleModalClose={handleModalClose}
          handleLeavePage={handleRedirectBack}
        />
      </>
    );
  }
);
TranslationSettingPage.displayName = 'TranslationSettingPage';
