import React, { useEffect, useState } from 'react';
import './CreateTestPromotion.scss';
import { useI18n } from '@shopify/react-i18n';
import {
  TestPromotionStatusDto,
  TestPromotionTypeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  ActionList,
  BlockStack,
  Button,
  Card,
  Icon,
  InlineStack,
  MediaCard,
  Popover,
  Text,
  Image,
  InlineGrid,
} from '@shopify/polaris';
import { MenuHorizontalIcon, XIcon, ImagesIcon } from '@shopify/polaris-icons';
import { useNavigate } from 'react-router-dom';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';
import { NavPaths } from 'core/enums/NavPathsEnum';

type CreateTestPromotionProps = {
  testPromotionStatus?: TestPromotionStatusDto;
  onDismiss: () => void;
  setCreatingTestPromotion: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateTestPromotion: React.FC<CreateTestPromotionProps> = (
  props
) => {
  const { testPromotionStatus, onDismiss, setCreatingTestPromotion } = props;

  const [i18n] = useI18n();
  const navigate = useNavigate();

  const {
    createTestPromotion,
    createTestPromotionData,
    createTestPromotionIsLoading,
  } = useConfigurePromotions(PromotionFetchTypeEnum.CREATE_TEST_PROMOTION);

  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  useEffect(() => {
    if (createTestPromotionData?.promotionId) {
      navigate(
        `/${NavPaths.Promotions}/${createTestPromotionData.promotionId}`
      );
    }
  }, [navigate, createTestPromotionData]);

  useEffect(() => {
    setCreatingTestPromotion(createTestPromotionIsLoading);
  }, [createTestPromotionIsLoading]);

  return (
    <div className='createTestPromotion'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <InlineStack align='space-between'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('title')}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('description')}
              </Text>
            </BlockStack>
            <Popover
              active={popoverActive}
              onClose={() => setPopoverActive((prev) => !prev)}
              activator={
                <Button
                  onClick={() => setPopoverActive((prev) => !prev)}
                  variant='tertiary'
                  icon={MenuHorizontalIcon}
                />
              }
            >
              <ActionList
                actionRole='menuitem'
                items={[
                  {
                    content: i18n.translate('dismiss'),
                    onAction: onDismiss,
                    disabled: !testPromotionStatus?.alreadyCreated,
                    prefix: (
                      <div
                        style={{
                          height: '1rem',
                          width: '1rem',
                          paddingTop: '.05rem',
                        }}
                      >
                        <Icon tone='subdued' source={XIcon} />
                      </div>
                    ),
                  },
                ]}
              />
            </Popover>
          </InlineStack>
          <InlineGrid gap='400' columns={3}>
            {testPromotionStatus?.types?.map((promotion) => (
              <div
                key={promotion.type}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <MediaCard
                  portrait
                  title={i18n.translate(`${promotion.type}_TITLE`)}
                  primaryAction={{
                    disabled: createTestPromotionIsLoading,
                    content: i18n.translate('createPromotion'),
                    onAction: () =>
                      createTestPromotion(
                        promotion.type as TestPromotionTypeDto
                      ),
                  }}
                  description={i18n.translate(`${promotion.type}_DESCRIPTION`)}
                >
                  {promotion.imageUrl ? (
                    <Image
                      className='imageCard'
                      source={promotion.imageUrl || ''}
                      alt=''
                    />
                  ) : (
                    <div className='placeholder'>
                      <Icon source={ImagesIcon} tone='subdued' />
                    </div>
                  )}
                </MediaCard>
              </div>
            ))}
          </InlineGrid>
        </BlockStack>
      </Card>
    </div>
  );
};
