import React, { useEffect, useMemo, useRef, useState } from 'react';
import './SelectedProductList.scss';
import { IndexTable } from '@shopify/polaris';
import {
  GrayBoxResourceType,
  ResourceSelectionProps,
} from '../../SearchFieldWithGrayBoxOfferWizard';
import { ShopifyObjectTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  ShopifyDetailedObjectDto,
  ShopifyDetailedObjectVariantDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import { useI18n } from '@shopify/react-i18n';
import { SelectedProductListRow } from './components/ProductListRow.tsx/SelectedProductListRow';

type SelectedProductListProps = {
  currentResourceType: string;
  resourceType: GrayBoxResourceType;
  selectedGrayBoxElements: ResourceSelectionProps[];
};
export type ExpandedShopifyDetailedObjectDto = ShopifyDetailedObjectDto & {
  variant?: string;
};
export type ResourceElementsDto =
  | ResourceSelectionProps
  | ExpandedShopifyDetailedObjectDto;

export const SelectedProductList: React.FC<SelectedProductListProps> = ({
  currentResourceType,
  resourceType,
  selectedGrayBoxElements,
}) => {
  const [i18n] = useI18n();
  const [pageNumber, setPageNumber] = useState(1);
  const listRef = useRef<any | null>(null);
  const [listWidth, setListWidth] = useState(0);

  const productVariants: ShopifyDetailedObjectDto & { variant?: string }[] =
    useMemo(
      () =>
        selectedGrayBoxElements?.flatMap(
          (product: ShopifyDetailedObjectDto) =>
            product?.variants?.map(
              (variant: ShopifyDetailedObjectVariantDto) => ({
                id: variant.id,
                title: product.title,
                imageUrl: product.imageUrl,
                featuredImageUrl: product.featuredImageUrl,
                variant: variant.title,
                status: product.status,
                salesChannels: product.salesChannels,
              })
            ) || []
        ) || [],
      [selectedGrayBoxElements]
    );

  const resourceElementsData: ResourceElementsDto[] = useMemo(
    () =>
      currentResourceType === ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
        ? productVariants
        : selectedGrayBoxElements,
    [currentResourceType, productVariants, selectedGrayBoxElements]
  );

  const productListIds = useMemo(() => {
    return resourceElementsData.map((item: ResourceElementsDto) => ({
      id: item.id,
    }));
  }, [resourceElementsData]);

  const headingVariant: NonEmptyArray<IndexTableHeading> = useMemo(
    () =>
      currentResourceType === ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
        ? [
            { title: i18n.translate(`${resourceType}`) },
            { title: i18n.translate('Variant'), alignment: 'start' },
            { title: i18n.translate('Status'), alignment: 'start' },
            { title: i18n.translate('SalesChannels'), alignment: 'start' },
          ]
        : [
            { title: i18n.translate(`${resourceType}`) },
            { title: i18n.translate('Status'), alignment: 'start' },
            { title: i18n.translate('SalesChannels'), alignment: 'start' },
          ],
    [i18n, resourceType, currentResourceType]
  );

  const rowMarkup = useMemo(() => {
    return productListIds.map((item, index) => {
      const data = resourceElementsData.find(
        (resource) => resource.id === item.id
      );
      if (index >= pageNumber * 5 - 5 && index < pageNumber * 5) {
        return (
          <SelectedProductListRow
            key={item.id}
            data={data as ShopifyDetailedObjectDto}
            index={index}
            isLoading={false}
            currentResourceType={currentResourceType}
            listWidth={listWidth}
          />
        );
      }
    });
  }, [
    resourceElementsData,
    productListIds,
    pageNumber,
    currentResourceType,
    listWidth,
  ]);

  useEffect(() => {
    const updateWidth = () => {
      setListWidth(listRef.current?.clientWidth || 0);
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <div ref={listRef} className='SelectedProductList'>
      <IndexTable
        itemCount={productListIds?.length || 0}
        headings={headingVariant}
        selectable={false}
        pagination={{
          hasPrevious: pageNumber >= 2,
          onPrevious: () => setPageNumber((prev) => prev - 1),
          hasNext: resourceElementsData.length / 5 > pageNumber,
          onNext: () => setPageNumber((prev) => prev + 1),
        }}
      >
        {rowMarkup}
      </IndexTable>
    </div>
  );
};
