import React from 'react';
import {
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionFiltersSettingsDto,
  TriggerTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { FilterSettingsSummary } from './components/FilterSettingsSummary/FilterSettingsSummary';
type SettingsFiltersProps = {
  filters?: PromotionFiltersSettingsDto;
  triggerType?: TriggerTypeDto;
  navigateThrough: (path?: string) => void;
};
export const SettingsFilters: React.FC<SettingsFiltersProps> = ({
  filters,
  triggerType,
  navigateThrough,
}) => {
  const [i18n] = useI18n();
  return (
    <Card>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' fontWeight='semibold'>
            {i18n.translate('Audience')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('AudienceSubtitle')}
          </Text>
        </BlockStack>
        <Box
          borderRadius='200'
          padding='400'
          borderWidth='025'
          borderColor='border-brand'
        >
          <InlineStack align='space-between'>
            <FilterSettingsSummary
              filters={filters}
              triggerType={triggerType}
            />
            <Button onClick={() => navigateThrough('audience')} variant='plain'>
              {i18n.translate('Configure')}
            </Button>
          </InlineStack>
        </Box>
      </BlockStack>
    </Card>
  );
};
