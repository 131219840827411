import React, { useCallback, useEffect, useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  OptionDtoTriggerExclusivityDto,
  PromotionFiltersSettingsDto,
  TriggerTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  InlineStack,
  Link,
  Text,
  TextField,
} from '@shopify/polaris';
import { Element } from 'react-scroll';
import { TriggerTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { Tooltip } from 'core/components';

type SettingExclusivityProps = {
  exclusivity?: OptionDtoTriggerExclusivityDto;
  filters?: PromotionFiltersSettingsDto;
  triggerType?: TriggerTypeDto;
  updateSettingsState: (data: OptionDtoTriggerExclusivityDto) => void;
};

export const SettingExclusivity: React.FC<SettingExclusivityProps> = ({
  exclusivity,
  filters,
  triggerType,
  updateSettingsState,
}) => {
  const [i18n] = useI18n();

  const isExclusivityAllowed = useMemo(() => {
    return (
      ((filters?.marketFilter?.enabled ||
        filters?.geolocationFilter?.enabled ||
        filters?.customerFilter?.enabled) &&
        triggerType === TriggerTypeDtoEnum.PUBLIC) ||
      triggerType === TriggerTypeDtoEnum.PRIVATE
    );
  }, [filters, triggerType]);

  const validatePriorityOnBlur = useCallback(() => {
    const value = exclusivity?.value?.priority || 0;
    updateSettingsState({
      ...exclusivity,
      value: {
        ...exclusivity?.value,
        priority: value < 0 ? 0 : value,
      },
    });
  }, [exclusivity, updateSettingsState]);

  useEffect(() => {
    if (!isExclusivityAllowed) {
      updateSettingsState({
        ...exclusivity,
        enabled: false,
      });
    }
  }, [isExclusivityAllowed]);

  return (
    <Element name='SettingExclusivity' className='SettingExclusivity'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack blockAlign='start' wrap={false} align='space-between'>
            <Box width='80%'>
              <BlockStack gap='100'>
                <InlineStack align='start' gap='200'>
                  <Text fontWeight='semibold' as='h2'>
                    {i18n.translate('Exclusivity')}
                  </Text>
                  <Badge tone={exclusivity?.enabled ? 'success' : 'enabled'}>
                    {i18n.translate(exclusivity?.enabled ? 'On' : 'Off')}
                  </Badge>
                </InlineStack>
                <Text as='p' tone='subdued'>
                  {i18n.translate('ExclusivitySubtitle')}
                </Text>
              </BlockStack>
            </Box>
            <Tooltip
              content={
                !isExclusivityAllowed
                  ? i18n.translate('ExclusivityDisabled')
                  : ''
              }
            >
              <Button
                disabled={!isExclusivityAllowed}
                onClick={() =>
                  updateSettingsState({
                    ...exclusivity,
                    enabled: !exclusivity?.enabled,
                  })
                }
              >
                {i18n.translate(exclusivity?.enabled ? 'TurnOff' : 'TurnOn')}
              </Button>
            </Tooltip>
          </InlineStack>
          <Collapsible id='collapsible' open={!!exclusivity?.enabled}>
            <BlockStack gap='400'>
              <Banner tone='warning'>
                <Text as='p'>{i18n.translate('BannerWarning1')}</Text>
                <Text as='p'>{i18n.translate('BannerWarning2')}</Text>
                <Text as='p'>
                  {i18n.translate('BannerWarning3', {
                    here: <Link>{i18n.translate('here')}</Link>,
                  })}
                </Text>
              </Banner>
              <Box width='280px'>
                <TextField
                  type='integer'
                  inputMode='numeric'
                  value={exclusivity?.value?.priority?.toString() || ''}
                  onChange={(value) =>
                    updateSettingsState({
                      ...exclusivity,
                      value: {
                        ...exclusivity?.value,
                        priority: value ? parseInt(value, 10) : 0,
                      },
                    })
                  }
                  label={i18n.translate('PriorityLabel')}
                  helpText={i18n.translate('PriorityHelperText')}
                  onBlur={validatePriorityOnBlur}
                  autoComplete='off'
                />
              </Box>
            </BlockStack>
          </Collapsible>
        </BlockStack>
      </Card>
    </Element>
  );
};
