import React from 'react';
import { BlockStack, Text } from '@shopify/polaris';
import ColorSelectorBox from 'core/components/WidgetEditor/ColorSelectorBox/ColorSelectorBox';

type ColorSelector = {
  color?: string | null;
  onColorChange: (value: string) => void;
  label?: string;
};

export const ColorSelector: React.FC<ColorSelector> = ({
  onColorChange,
  color,
  label,
}) => {
  return (
    <BlockStack gap='100'>
      <Text as='p'>{label || 'Color'}</Text>
      <ColorSelectorBox
        color={`${color}`}
        setColor={(value) => onColorChange(value || '#00000')}
      />
    </BlockStack>
  );
};
