import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SettingsLayout } from './components/SettingsLayout/SettingsLayout';
import { useLocation, useSearchParams } from 'react-router-dom';
import './Settings.scss';
import { SettingsNavbar } from './components/SettingsNavbar/SettingsNavbar';
import {
  CheckoutOptions,
  ManageWidgets,
  StrikethroughPricing,
} from './components';
import { AnnouncementBarSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/AnnouncementBarSettings/AnnouncementBarSettings';
import { ProductBannerSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/ProductBannerSettings/ProductBannerSettings';
import { NotificationSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/NotificationSettings/NotificationSettings';
import { PromotionalBadgeSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/PromotionalBadgeSettings/PromotionalBadgeSettings';
import { DiscountNinjaCodesSetting } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/DiscountNinjaCodesSetting/DiscountNinjaCodesSetting';
import { ShopifyDiscountCodesSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/ShopifyDiscountCodesSettings/ShopifyDiscountCodesSettings';
import { PromotionSummarySetting } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/PromotionSummarySetting/PromotionSummarySetting';
import { OfferRulePopupSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/OfferRulePopupSettings/OfferRulePopupSettings';
import { CountdownClockSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/CountdownClockSettings/CountdownClockSettings';
import { scroller } from 'react-scroll';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import {
  setCurrentAnchor,
  setIsSettingsSaving,
  setIsUnsavedChanges,
  setIsValidationError,
} from 'core/store/settingsSlice';
import {
  setPromotionLevelStylePresets,
  setShopLevelStylePresets,
  setToggleSettingsConfig,
} from 'core/store/widgetsSlice';
import { ShopLevelStylePresetsPage } from './components/GeneralSettings/Style/components/ShopLevelStylePresetsPage/ShopLevelStylePresetsPage';
import { PromotionLevelStylePresetsPage } from './components/GeneralSettings/Style/components/PromotionLevelStylePresetsPage/PromotionLevelStylePresetsPage';
import { StrikethroughCartSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/StrikethroughCartSettings/StrikethroughCartSettings';
import { StrikethroughProductSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/StrikethroughProductSettings/StrikethroughProductSettings';
import { Translations } from './components/GeneralSettings/Translations/Translations';
import { Plan } from './components/GeneralSettings/Plan/Plan';
import { CartBehavior } from './components/GeneralSettings/CartBehavior/CartBehavior';
import { TagsMetadata } from './components/GeneralSettings/TagsMetadata/TagsMetadata';
import { DiscountLinks } from './components/GeneralSettings/DiscountLinks/DiscountLinks';
import { Gifts } from './components/GeneralSettings/Gifts/Gifts';
import { ImportExport } from './components/GeneralSettings/ImportExport/ImportExport';
import { Style } from './components/GeneralSettings/Style/Style';
import { TranslationSettingPage } from './components/GeneralSettings/Translations/components/TranslationSettingPage/TranslationSettingPage';
import { useI18n } from '@shopify/react-i18n';
import { Button } from '@shopify/polaris';
import { Integrations } from './components/GeneralSettings/Integrations/Integrations';
import { Account } from './components/GeneralSettings/Account/Account';
import { ActivityLogPage } from './components/GeneralSettings/Account/components/ActivityLogPage/ActivityLogPage';
import { CartTextSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/CartTextSettings/CartTextSettings';
import { DiscountLabelSettings } from './components/GeneralSettings/ManageWidgets/components/WidgetSettings/DiscountLabelSettings/DiscountLabelSettings';
import { MessageEventData } from 'pages/SettingsPage';
import resolveEnvVar from 'env-var-resolver';
import useFetchPresetList from './hooks/useFetchPresetList/useFetchPresetList';
import { PastBills } from './components/GeneralSettings/Plan/pages/PastBills/PastBills';
import { PlanDetails } from './components/GeneralSettings/Plan/pages/PlanDetails/PlanDetails';
import classNames from 'classnames';
import { NavPaths } from 'core/enums/NavPathsEnum';
import {
  SettingsModalPathsEnums,
  SettingsModalSubPathsEnums,
} from 'pages/enums/PagesEnums';
import { CustomShippingRates } from './components/GeneralSettings/CustomShippingRates/CustomShippingRates';
import { Notifications } from './components/GeneralSettings/Notifications/Notifications';

type SettingsProps = {
  isMaxModal?: boolean;
};

export type SettingsSavebarRef = {
  saveChanges: () => Promise<any>;
  discardChanges: () => void;
};

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const Settings: React.FC<SettingsProps> = ({ isMaxModal }) => {
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();
  const savebarRef = useRef<SettingsSavebarRef>(null);

  const { fetchBrandColors } = useFetchPresetList();

  const {
    currentAnchor,
    isUnsavedChanges,
    isSettingsSaving,
    isValidationError,
  } = useAppSelector((state) => state.settings);
  const { toggleSettingsConfig } = useAppSelector((store) => store.widgets);

  const currentLocation = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const [targetElementRendered, setTargetElementRendered] =
    useState<boolean>(false);

  const hasPath = useMemo(
    () => searchParams.has('path'),
    [currentLocation.search]
  );

  const path = searchParams.get('path');
  const subPath = searchParams.get('subPath');
  const tab = searchParams.get('tab');
  const language = searchParams.get('language');

  const hideFooterSaveButton = useMemo(
    () =>
      (path === SettingsModalPathsEnums.Account &&
        subPath === SettingsModalSubPathsEnums.ActivityLog) ||
      path === SettingsModalPathsEnums.Plan ||
      path === SettingsModalPathsEnums.CustomShippingRates,
    [path, subPath]
  );

  const hideNavBar = useMemo(
    () =>
      path === SettingsModalPathsEnums.Plan &&
      subPath === SettingsModalSubPathsEnums.PickYourPlan,
    [path, subPath]
  );
  const onDiscardChanges = useCallback(
    () => savebarRef.current?.discardChanges(),
    [savebarRef.current]
  );

  const renderSettingComponent = useMemo(() => {
    switch (true) {
      case path === SettingsModalPathsEnums.CustomShippingRates && !subPath:
        return <CustomShippingRates />;
      case path === SettingsModalPathsEnums.Style && !subPath:
        return <Style ref={savebarRef} />;
      case path === SettingsModalPathsEnums.Style &&
        subPath === SettingsModalSubPathsEnums.ShopLevelStylePresets:
        return <ShopLevelStylePresetsPage />;
      case path === SettingsModalPathsEnums.Style &&
        subPath === SettingsModalSubPathsEnums.PromotionLevelStylePresets:
        return <PromotionLevelStylePresetsPage />;
      case path === SettingsModalPathsEnums.Translations && !subPath:
        return <Translations isMaxModal={!!isMaxModal} />;
      case path === SettingsModalPathsEnums.Translations &&
        subPath === SettingsModalSubPathsEnums.TranslationsSettings:
        return <TranslationSettingPage ref={savebarRef} />;
      case path === SettingsModalPathsEnums.Plan && !subPath:
        return <Plan isMaxModal={!!isMaxModal} />;
      case path === SettingsModalPathsEnums.Integrations:
        return <Integrations ref={savebarRef} />;
      case path === SettingsModalPathsEnums.CartBehavior && !subPath:
        return <CartBehavior ref={savebarRef} />;
      case path === SettingsModalPathsEnums.TagsMetadata && !subPath:
        return <TagsMetadata ref={savebarRef} />;
      case path === SettingsModalPathsEnums.Notifications:
        return <Notifications ref={savebarRef} />;
      //The page is obsolete. Probably need to be removed
      // case path === 'testing' && !subPath:
      //   return <Testing />;
      case path === SettingsModalPathsEnums.DiscountLinks && !subPath:
        return <DiscountLinks ref={savebarRef} />;
      case path === SettingsModalPathsEnums.ImportExport && !subPath:
        return <ImportExport />;
      case path === SettingsModalPathsEnums.Gifts && !subPath:
        return <Gifts ref={savebarRef} />;
      case path === SettingsModalPathsEnums.Account && !subPath:
        return <Account ref={savebarRef} />;
      case path === SettingsModalPathsEnums.Account &&
        subPath === SettingsModalSubPathsEnums.ActivityLog:
        return <ActivityLogPage />;
      case path === SettingsModalPathsEnums.Plan &&
        subPath === SettingsModalSubPathsEnums.PastBills:
        return <PastBills />;
      case path === SettingsModalPathsEnums.Plan &&
        subPath === SettingsModalSubPathsEnums.PickYourPlan:
        return <PlanDetails isMaxModal={!!isMaxModal} />;
      case path === SettingsModalPathsEnums.CheckoutOptions:
        return <CheckoutOptions ref={savebarRef} />;
      case path === SettingsModalPathsEnums.ManageWidgets && !subPath:
        return <ManageWidgets isMaxModal={!!isMaxModal} />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.AnnouncementBar:
        return <AnnouncementBarSettings />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.ProductBanner:
        return <ProductBannerSettings />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.Notification:
        return <NotificationSettings />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.PromotionalBadge:
        return <PromotionalBadgeSettings />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.DiscountNinjaPromotionCodes:
        return <DiscountNinjaCodesSetting />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.ShopifyDiscountCodes:
        return <ShopifyDiscountCodesSettings />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.PromotionSummary:
        return <PromotionSummarySetting />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.OfferRulesPopup:
        return <OfferRulePopupSettings />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.CountdownClock:
        return <CountdownClockSettings />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.CartText:
        return <CartTextSettings />;
      case path === SettingsModalPathsEnums.ManageWidgets &&
        subPath === SettingsModalSubPathsEnums.DiscountLabel:
        return <DiscountLabelSettings />;
      case path === SettingsModalPathsEnums.StrikethroughPricing && !subPath:
        return <StrikethroughPricing ref={savebarRef} />;
      case (path === SettingsModalPathsEnums.StrikethroughPricing ||
        path === SettingsModalPathsEnums.ManageWidgets) &&
        subPath === SettingsModalSubPathsEnums.CartStrikethroughPricing:
        return <StrikethroughCartSettings />;
      case (path === SettingsModalPathsEnums.StrikethroughPricing ||
        path === SettingsModalPathsEnums.ManageWidgets) &&
        subPath === SettingsModalSubPathsEnums.ProductStrikethroughPricing:
        return <StrikethroughProductSettings ref={savebarRef} />;

      default:
        return null;
    }
  }, [path, subPath, isMaxModal, savebarRef]);

  const sendMessageToMainApp = (message: any) => {
    window.opener?.postMessage(message, location.origin);
  };

  const handleSaveChanges = useCallback(() => {
    dispatch(setIsSettingsSaving(true));
    savebarRef.current
      ?.saveChanges()
      .then(() => {
        isMaxModal &&
          sendMessageToMainApp({
            type: 'IS_UNSAVED_CHANGES',
            isUnsavedChanges: false,
          });
        if (toggleSettingsConfig) {
          dispatch(setToggleSettingsConfig({ action: 'save' }));
          setSearchParams({});
        }
      })
      .finally(() => {
        dispatch(setIsSettingsSaving(false));
      });
  }, [savebarRef.current, toggleSettingsConfig, isMaxModal, dispatch]);

  const handleMessageFromMainApp = (ev: MessageEvent) => {
    const { type } = ev.data as MessageEventData;
    if (type === 'NAVIGATE_TO_PICK_YOUR_PLAN') {
      setSearchParams((params) => {
        params.set('path', SettingsModalPathsEnums.Plan);
        params.set('subPath', SettingsModalSubPathsEnums.PickYourPlan);
        return params;
      });
    }
    if (type === 'TOGGLE_SETTINGS_CONFIG') {
      dispatch(setToggleSettingsConfig(ev.data.toggleSettingsConfig));
    }
    if (type === 'IS_UNSAVED_CHANGES') {
      dispatch(setIsUnsavedChanges(ev.data.isUnsavedChanges));
    }
    if (type === 'IS_VALIDATION_ERROR') {
      dispatch(setIsValidationError(ev.data.isValidationError));
    }
    if (type === 'IS_SETTINGS_SAVING') {
      dispatch(setIsSettingsSaving(ev.data.isSettingsSaving));
    }
    if (type === 'SAVING_FUNC') {
      handleSaveChanges();
    }
    if (type === 'DISCARD_FUNC') {
      onDiscardChanges?.();
    }
    if (type === 'UPDATE_ANCHOR') {
      dispatch(setCurrentAnchor(ev.data.currentAnchor));
    }
    if (type === 'UPDATE_SEARCH_PARAMS') {
      if (ev.data.searchParams?.path) {
        setSearchParams((params) => {
          params.set('path', ev.data.searchParams.path);
          ev.data.searchParams?.subPath
            ? params.set('subPath', ev.data.searchParams.subPath)
            : params.delete('subPath');
          ev.data.searchParams?.tab
            ? params.set('tab', ev.data.searchParams.tab)
            : params.delete('tab');
          ev.data.searchParams?.language
            ? params.set('language', ev.data.searchParams.language)
            : params.delete('language');
          return params;
        });
        ev.data.searchParams?.currentAnchor &&
          dispatch(setCurrentAnchor(ev.data.searchParams?.currentAnchor));
      } else {
        searchParams.set('path', SettingsModalPathsEnums.Account);
        setSearchParams(searchParams);
        ev.data.searchParams?.currentAnchor &&
          dispatch(setCurrentAnchor(ev.data.searchParams?.currentAnchor));
      }
    }
  };

  const handleFetchBrandColors = useCallback(async () => {
    await fetchBrandColors({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    });
  }, [APP_NAME, APP_PASSWORD]);

  if (isMaxModal) {
    sendMessageToMainApp({
      type: 'IS_UNSAVED_CHANGES',
      isUnsavedChanges,
    });
  }

  // redirect to 'account' by default if there is no 'path' and not maxModal
  useEffect(() => {
    if (!hasPath && !isMaxModal) {
      searchParams.set('path', SettingsModalPathsEnums.Account);
      setSearchParams(searchParams);
    }
  }, [hasPath]);

  useEffect(() => {
    isMaxModal &&
      path &&
      sendMessageToMainApp({
        type: 'UPDATE_SEARCH_PARAMS',
        searchParams: { path, subPath, tab, language },
      });
    if (
      subPath !== SettingsModalSubPathsEnums.PromotionCodeField &&
      path !== SettingsModalPathsEnums.ManageWidgets
    ) {
      searchParams.delete('tab');
      setSearchParams(searchParams);
    }
    if (path !== SettingsModalPathsEnums.Translations) {
      searchParams.delete('language');
      setSearchParams(searchParams);
    }
    if (path !== SettingsModalPathsEnums.Style) {
      dispatch(setShopLevelStylePresets(null));
      dispatch(setPromotionLevelStylePresets(null));
    }
  }, [path, subPath, tab, language]);

  useEffect(() => {
    isMaxModal && window.addEventListener('message', handleMessageFromMainApp);
    return () => {
      window.removeEventListener('message', handleMessageFromMainApp);
    };
  }, [handleMessageFromMainApp]);

  useEffect(() => {
    const presetId = currentLocation.pathname.includes(
      NavPaths.ConfigureWidgets
    )
      ? currentLocation.pathname.slice(19)
      : '';

    if (isMaxModal && presetId) {
      sendMessageToMainApp({
        type: 'NAVIGATE_TO_EDITOR',
        presetId,
      });
    }
  }, [currentLocation.pathname]);

  useEffect(() => {
    !isMaxModal &&
      !currentLocation.pathname.includes(NavPaths.ConfigureWidgets) &&
      localStorage.setItem(
        'settingsLocation',
        `${currentLocation.pathname}${path ? `?path=${path}` : ''}${
          subPath ? `&subPath=${subPath}` : ''
        }${tab ? `&tab=${tab}` : ''}${language ? `&language=${language}` : ''}`
      );
  }, [currentLocation, path, subPath, language, tab]);

  useEffect(() => {
    isMaxModal &&
      sendMessageToMainApp({
        type: 'TOGGLE_SETTINGS_CONFIG',
        toggleSettingsConfig,
      });
  }, [toggleSettingsConfig]);

  useEffect(() => {
    isMaxModal &&
      sendMessageToMainApp({ type: 'IS_VALIDATION_ERROR', isValidationError });
  }, [isValidationError, sendMessageToMainApp]);

  useEffect(() => {
    isMaxModal &&
      sendMessageToMainApp({ type: 'IS_SETTINGS_SAVING', isSettingsSaving });
  }, [isSettingsSaving, sendMessageToMainApp]);

  useEffect(() => {
    isMaxModal && sendMessageToMainApp({ type: 'MAX_MODAL_RENDERED' });
  }, [isMaxModal]);

  // workaround for now ...react-scroll package update needed
  useEffect(() => {
    const checkIfRendered = () => {
      const element = document.querySelector(`.${currentAnchor}`);
      if (element) {
        setTargetElementRendered(true);
      } else {
        setTargetElementRendered(false);
      }
    };

    // Initial check
    checkIfRendered();

    // Optional: Add a mutation observer to detect changes in the DOM
    const observer = new MutationObserver(checkIfRendered);
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer on component unmount
    return () => observer.disconnect();
  }, [currentAnchor]);

  // scroll to element needed (addjust/change props if needed)
  useEffect(() => {
    if (targetElementRendered && currentAnchor) {
      scroller.scrollTo(currentAnchor, {
        containerId: 'SettingsLayout',
        offset: -20,
        duration: 1000,
        delay: 100,
        smooth: true,
      });
      dispatch(setCurrentAnchor(null));
    }
  }, [targetElementRendered]);

  useEffect(() => {
    APP_NAME && APP_PASSWORD && handleFetchBrandColors();
  }, [APP_NAME, APP_PASSWORD]);

  return (
    <SettingsLayout
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={handleSaveChanges}
      isMaxModal={isMaxModal}
    >
      <div className='Settings'>
        {!hideNavBar && <SettingsNavbar />}
        <div
          className={classNames('SettingsPages', {
            HideNavBar: hideNavBar,
          })}
        >
          {renderSettingComponent}

          {!hideFooterSaveButton && (
            <div className='SettingFooterSaveButton'>
              <Button
                variant='primary'
                disabled={!isUnsavedChanges || isValidationError}
                loading={isSettingsSaving}
                onClick={() =>
                  !isMaxModal
                    ? handleSaveChanges()
                    : sendMessageToMainApp({
                        type: 'PAGE_SAVE',
                      })
                }
              >
                {i18n.translate('Save')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </SettingsLayout>
  );
};
