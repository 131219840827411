import { TrendIndicator as Indicator } from '@shopify/polaris-viz';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
import { ChartuUnitOfMeasurementEnum } from 'pages';
import React from 'react';
type TrendIndicatorProps = {
  value: number;
};
export const TrendIndicator: React.FC<TrendIndicatorProps> = ({ value }) => {
  const { formatValue } = useNumericFormatter();
  return (
    <Indicator
      direction={value > 0 ? 'upward' : 'downward'}
      trend={value > 0 ? 'positive' : 'negative'}
      value={formatValue(value, ChartuUnitOfMeasurementEnum.PERCENTAGE)}
    />
  );
};
