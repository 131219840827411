import React from 'react';
import { InlineStack, ButtonGroup, Text, Button } from '@shopify/polaris';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useI18n } from '@shopify/react-i18n';
import { DiscountValueTypeEnum } from 'features/promotions/components/SelectedOfferTypeSettings/enums/DiscountValueType';

type ApplyButtonsType = {
  offerType: OfferTypeDtoEnum;
  onDiscountTypeClick: (data: string) => void;
  activeDiscountValueType: DiscountValueTypeEnum;
};

export const ApplyButtons: React.FC<ApplyButtonsType> = ({
  offerType,
  activeDiscountValueType,
  onDiscountTypeClick,
}) => {
  const [i18n] = useI18n();

  return (
    <InlineStack gap='100' blockAlign='center'>
      <Text as='p'>{i18n.translate(`${offerType}.start`)}</Text>
      <span className='ApplyButtons'>
        <ButtonGroup variant='segmented'>
          <Button
            pressed={
              activeDiscountValueType === DiscountValueTypeEnum.Percentage
            }
            onClick={() =>
              onDiscountTypeClick(DiscountValueTypeEnum.Percentage)
            }
          >
            {i18n.translate(`${DiscountValueTypeEnum.Percentage}`)}
          </Button>
          <Button
            pressed={
              activeDiscountValueType === DiscountValueTypeEnum.FixedAmount
            }
            onClick={() =>
              onDiscountTypeClick(DiscountValueTypeEnum.FixedAmount)
            }
          >
            {i18n.translate(`${DiscountValueTypeEnum.FixedAmount}`)}
          </Button>
        </ButtonGroup>
      </span>
      <Text as='p'>{i18n.translate(`${offerType}.end`)}</Text>{' '}
    </InlineStack>
  );
};
