import { useAppDispatch, useAppSelector } from 'core/hooks';
import {
  setCombinationsOffersListIsFetching,
  setCombinedWithList,
  setCurrentCombination,
  setCurrentCombinationIsFetching,
  setCurrentCombinationIsLoaded,
  setIsUpdateOfferDraftError,
  setIsUpdatePromotionOfferDraftIsFetching,
  setLastNotCombinedWithTypeData,
  setNotCombinedWithList,
  setSmartTagsList,
  setTagsList,
  setToastMessages,
  setTypeListIsFetching,
} from 'core/store/offersWizardSlice';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Query,
  SaveOfferRequestDtoOfferBogoDto,
  SaveOfferRequestDtoOfferCrossSellDto,
  SaveOfferRequestDtoOfferFreeShippingDto,
  SaveOfferRequestDtoOfferGiftDto,
  SaveOfferRequestDtoOfferMultiTierBogoDto,
  SaveOfferRequestDtoOfferSalesDto,
  SaveOfferRequestDtoOfferShopifyDiscountCodeDto,
  SaveOfferRequestDtoOfferTieredSpendXGetYDto,
  SaveOfferRequestBase,
  usePostPromotionsV6LookupShopifyDetailedObjectsMutation,
  OfferTypeDto,
  useGetPromotionsV6LookupTagsQuery,
  usePutPromotionsV6PromotionOffersByIdMutation,
  usePostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOffersMutation,
  usePostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOfTypeMutation,
  useDeletePromotionsV6PromotionByIdOffersAndOfferIdMutation,
  usePostPromotionsV6PromotionByIdSettingsDiscountLinkPreviewMutation,
  TriggerDiscountLinkDto,
  GetPromotionOfferResponseDto,
  usePostPromotionsV6PromotionByIdOffersAndOfferIdDuplicateMutation,
  OfferCombinationTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferTypeDtoEnum,
  TagCategoryDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import resolveEnvVar from 'env-var-resolver';

export type OfferDraftDataDTOType =
  | SaveOfferRequestDtoOfferBogoDto
  | SaveOfferRequestDtoOfferCrossSellDto
  | SaveOfferRequestDtoOfferFreeShippingDto
  | SaveOfferRequestDtoOfferGiftDto
  | SaveOfferRequestDtoOfferMultiTierBogoDto
  | SaveOfferRequestDtoOfferSalesDto
  | SaveOfferRequestDtoOfferShopifyDiscountCodeDto
  | SaveOfferRequestDtoOfferTieredSpendXGetYDto
  | SaveOfferRequestBase;

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const recordsPerPage = 5;

export const useConfigureOffers = (
  offerType?: OfferTypeDto,
  fetchTags?: boolean
) => {
  const dispatch = useAppDispatch();
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const params = useParams();
  const {
    lastCombinedWithTypeFetched,
    combinedWithList,
    notCombinedWithList,
    lastCombinedWithTypePage,
    lastNotCombinedWithTypeData,
    selectedOfferType,
    currentOfferData,
    currentCombination,
  } = useAppSelector((state) => state.offersWizard);

  const {
    excludedOffers,
    // excludedDiscountCodes
  } = currentCombination;

  const excludedOffersIds = useMemo(
    () =>
      currentOfferData?.template?.combinations?.excludedOffersIds ||
      excludedOffers?.map((offer) => offer.id),
    [
      currentOfferData?.template?.combinations?.excludedOffersIds,
      excludedOffers,
    ]
  );
  const excludedShopifyDiscountCodesIds = useMemo(
    () =>
      currentOfferData?.template?.combinations?.excludedShopifyDiscountCodesIds,
    //   ||
    // excludedDiscountCodes?.map((offer) => offer.id),
    [
      currentOfferData?.template?.combinations?.excludedShopifyDiscountCodesIds,
      // excludedDiscountCodes,
    ]
  );

  const offerTypeNeeded = useMemo(
    () => (offerType as OfferTypeDtoEnum) || selectedOfferType?.type,
    [offerType, selectedOfferType?.type]
  );

  const hasCombinations = useMemo(
    () =>
      offerTypeNeeded &&
      [
        OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP,
        OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP,
        OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR,
        OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER,
        OfferTypeDtoEnum.ORDER_BUMP,
      ].indexOf(offerTypeNeeded) === -1,
    [offerTypeNeeded]
  );

  const {
    isLoading: tagsListIsLoading,
    isError: tagsListIsError,
    isSuccess: tagsListIsSuccess,
    data: tagsListData,
    error: tagsListError,
    refetch: fetchTagsList,
  } = useGetPromotionsV6LookupTagsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      category: TagCategoryDtoEnum.PROMOTION,
    },
    { skip: !fetchTags }
  );

  useEffect(() => {
    if (tagsListData?.length) {
      dispatch(setTagsList(tagsListData));
    }
  }, [tagsListData, dispatch]);

  const [
    postLookupShopifyDetailedObjectsMutation,
    {
      isLoading: lookupShopifyDetailedObjectsIsLoading,
      isError: lookupShopifyDetailedObjectsIsError,
      isSuccess: lookupShopifyDetailedObjectsIsSuccess,
      data: lookupShopifyDetailedObjectsData,
      error: lookupShopifyDetailedObjectsError,
    },
  ] = usePostPromotionsV6LookupShopifyDetailedObjectsMutation();

  const getLookupShopifyDetailedObjectsDetails = useCallback(
    async (data: Query, setIsLoading?: Dispatch<SetStateAction<boolean>>) => {
      const response = await postLookupShopifyDetailedObjectsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        query: data,
      }).unwrap();
      if (response && !lookupShopifyDetailedObjectsError) {
        setIsLoading?.(false);
        return response;
      } else {
        setIsLoading?.(false);
      }
    },
    [postLookupShopifyDetailedObjectsMutation]
  );

  const [offerData, setOfferData] = useState<
    GetPromotionOfferResponseDto | undefined
  >(undefined);
  const [offerIsLoading, setOfferIsLoading] = useState<boolean>(false);

  const fetchSmartTags = useCallback(async () => {
    try {
      const response = await fetch(
        `${resolveEnvVar(
          'REACT_APP_PROMOTIONS_ADMIN_API_URL'
        )}/promotions/v6/lookup/smartTags/${params.promotionId ?? 'example'}`,
        {
          method: 'GET',
          headers: {
            'X-LimoniApps-AppName': APP_NAME,
            'X-LimoniApps-AppSecret': APP_PASSWORD,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const data = await response.json();
      dispatch(setSmartTagsList(data));
    } catch (error) {
      dispatch(setSmartTagsList([]));
      dispatch(
        setToastMessages({
          error: true,
          message: 'FetchSmartTagsError',
        })
      );
    }
  }, [params.promotionId, dispatch]);

  const fetchOffer = useCallback(
    async (offerId: string) => {
      setOfferIsLoading(true);
      const response = await fetch(
        `${resolveEnvVar(
          'REACT_APP_PROMOTIONS_ADMIN_API_URL'
        )}/promotions/v6/promotion/offers/${offerId}`,
        {
          method: 'GET',
          headers: {
            'X-LimoniApps-AppName': APP_NAME,
            'X-LimoniApps-AppSecret': APP_PASSWORD,
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      response
        .json()
        .then((data) => {
          setOfferData(data);
          setOfferIsLoading(false);
        })
        .catch(() => {
          dispatch(
            setToastMessages({
              error: true,
              message: 'OfferFetchingFailed',
            })
          );
        });
    },
    [setOfferData, setOfferIsLoading]
  );

  const [
    getCombinationsOffersList,
    {
      error: combinationsOffersError,
      isLoading: combinationsOffersIsLoading,
      data: combinationsOffersData,
    },
  ] =
    usePostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOffersMutation();

  const getCurrentCombinationsOffersListData = useCallback(async () => {
    if (hasCombinations) {
      dispatch(setCombinationsOffersListIsFetching(true));
      await getCombinationsOffersList({
        'X-LimoniApps-AppName': APP_NAME,
        'Accept-Language': navigator.language || 'en',
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId || '',
        offerId: params.id || '',
        getSelectableCombinationsListRequestDto: {
          search: lastNotCombinedWithTypeData.offers.search,
          page: lastNotCombinedWithTypeData.offers.page,
          itemsPerPage: recordsPerPage,
          excludedCombinations: {
            excludedOffersIds: excludedOffersIds,
            level: currentOfferData?.template?.level || null,
          },
        },
      })
        .unwrap()
        .then(() => dispatch(setCombinationsOffersListIsFetching(false)));
    }
  }, [
    params,
    getCombinationsOffersList,
    lastNotCombinedWithTypeData.offers,
    recordsPerPage,
    excludedOffersIds,
    excludedShopifyDiscountCodesIds,
    hasCombinations,
    dispatch,
  ]);

  const fetchCombinationsOffersOnPickerClick = useCallback(() => {
    if (!('offers' in notCombinedWithList)) {
      dispatch(
        setLastNotCombinedWithTypeData({
          ...lastNotCombinedWithTypeData,
          offers: {
            ...lastNotCombinedWithTypeData.offers,
            shouldFetch: true,
          },
        })
      );
    }
  }, [notCombinedWithList, dispatch]);

  // const fetchShopifyDiscountCodesOnPickerClick = useCallback(() => {
  //   if (!('shopifyDiscountCodes' in notCombinedWithList)) {
  //     dispatch(
  //       setLastNotCombinedWithTypeData({
  //         ...lastNotCombinedWithTypeData,
  //         shopifyDiscountCodes: {
  //           ...lastNotCombinedWithTypeData.shopifyDiscountCodes,
  //           shouldFetch: true,
  //         },
  //       })
  //     );
  //   }
  // }, [notCombinedWithList, dispatch]);

  useEffect(() => {
    combinationsOffersData &&
      !combinationsOffersError &&
      dispatch(
        setNotCombinedWithList({
          ...notCombinedWithList,
          offers: combinationsOffersData,
        })
      );
  }, [combinationsOffersData, dispatch]);

  const [
    getTypeOfList,
    {
      error: getOfTypeListError,
      isLoading: getOfTypeListIsLoading,
      isSuccess: getOfTypeListIsSuccess,
      data: getOfTypeListData,
    },
  ] =
    usePostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsOfTypeMutation();

  const getCurrentTypeOfList = useCallback(async () => {
    if (hasCombinations) {
      dispatch(setTypeListIsFetching(true));
      await getTypeOfList({
        'X-LimoniApps-AppName': APP_NAME,
        'Accept-Language': navigator.language || 'en',
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params.promotionId || '',
        offerId: params.id || '',
        getOfferCombinationsListRequestDto: {
          type: lastCombinedWithTypeFetched,
          page: lastCombinedWithTypePage,
          itemsPerPage: recordsPerPage,
          excludedCombinations: {
            excludedOffersIds: excludedOffersIds,
            // excludedDiscountCodes: excludedShopifyDiscountCodesIds,
            level: currentOfferData?.template?.level || null,
          },
        },
      })
        .unwrap()
        .then(() => dispatch(setTypeListIsFetching(false)));
    }
  }, [
    params,
    getTypeOfList,
    lastCombinedWithTypeFetched,
    recordsPerPage,
    lastCombinedWithTypePage,
    excludedOffersIds,
    excludedShopifyDiscountCodesIds,
    hasCombinations,
    dispatch,
  ]);

  useEffect(() => {
    getOfTypeListData &&
      !getOfTypeListError &&
      dispatch(
        setCombinedWithList({
          ...combinedWithList,
          [`${lastCombinedWithTypeFetched}`]: getOfTypeListData,
        })
      );
  }, [getOfTypeListData, dispatch]);

  // const [
  //   getShopifyDiscountCodesList,
  //   {
  //     error: shopifyDiscountCodesError,
  //     isLoading: shopifyDiscountCodesIsLoading,
  //     data: shopifyDiscountCodesData,
  //   },
  // ] =
  //   usePostPromotionsV6PromotionByIdOffersAndOfferIdCombinationsShopifyDiscountCodesMutation();

  // const getCurrentShopifyDiscountCodesData = useCallback(async () => {
  //   if (hasCombinations) {
  //     dispatch(setShopifyDiscountCodesIsFetching(true));
  //     await getShopifyDiscountCodesList({
  //       'X-LimoniApps-AppName': APP_NAME,
  //       'X-LimoniApps-AppSecret': APP_PASSWORD,
  //       id: params.promotionId || '',
  //       offerId: params.id || '',
  //       getSelectableCombinationsListRequestDto: {
  //         search: lastNotCombinedWithTypeData.shopifyDiscountCodes.search,
  //         page: lastNotCombinedWithTypeData.shopifyDiscountCodes.page,
  //         itemsPerPage: recordsPerPage,
  //         excludedCombinations: {
  //           excludedOffersIds: excludedOffersIds,
  //           excludedDiscountCodes: excludedShopifyDiscountCodesIds,
  //           level: currentOfferData?.template?.level || null,
  //         },
  //       },
  //     })
  //       .unwrap()
  //       .then(() => dispatch(setShopifyDiscountCodesIsFetching(false)));
  //   }
  // }, [
  //   params,
  //   getShopifyDiscountCodesList,
  //   lastNotCombinedWithTypeData.shopifyDiscountCodes,
  //   recordsPerPage,
  //   excludedOffersIds,
  //   excludedShopifyDiscountCodesIds,
  //   hasCombinations,
  //   dispatch,
  // ]);

  // useEffect(() => {
  //   shopifyDiscountCodesData &&
  //     !shopifyDiscountCodesError &&
  //     dispatch(
  //       setNotCombinedWithList({
  //         ...notCombinedWithList,
  //         shopifyDiscountCodes: shopifyDiscountCodesData,
  //       })
  //     );
  // }, [shopifyDiscountCodesData, dispatch]);

  const getCurrentCombinationOfferDraft = useCallback(
    async (signal: AbortSignal) => {
      if (hasCombinations) {
        try {
          const response = await fetch(
            `${resolveEnvVar(
              'REACT_APP_PROMOTIONS_ADMIN_API_URL'
            )}/promotions/v6/promotion/${params.promotionId}/offers/${
              params.id
            }/combinations/summary`,
            {
              method: 'POST',
              headers: {
                'X-LimoniApps-AppName': APP_NAME,
                'X-LimoniApps-AppSecret': APP_PASSWORD,
                'Accept-Language': navigator.language || 'en',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                excludedOffersIds: excludedOffersIds,
                excludedDiscountCodes: excludedShopifyDiscountCodesIds,
                level: currentOfferData?.template?.level || null,
              }),
              signal,
            }
          );
          response
            .json()
            .then((response) => {
              !signal.aborted && dispatch(setCurrentCombination(response));
              dispatch(setCurrentCombinationIsFetching(false));
            })
            .catch(() => {
              dispatch(setCurrentCombinationIsFetching(false));
              dispatch(
                setToastMessages({
                  error: true,
                  message: 'CantLoadCombination',
                })
              );
            });
        } catch (error) {
          dispatch(setCurrentCombinationIsFetching(false));
        }
      } else {
        dispatch(setCurrentCombinationIsFetching(false));
      }
    },
    [
      params,
      dispatch,
      excludedOffersIds,
      excludedShopifyDiscountCodesIds,
      hasCombinations,
    ]
  );

  const [
    updateOfferMutation,
    {
      error: updateOfferMutationError,
      isLoading: updateOfferMutationIsLoading,
      isSuccess: updateOfferMutationIsSuccess,
      isError: updateOfferMutationIsError,
      data: updateOfferMutationData,
    },
  ] = usePutPromotionsV6PromotionOffersByIdMutation();

  useEffect(() => {
    if (updateOfferMutationError || updateOfferMutationIsError) {
      dispatch(setIsUpdateOfferDraftError(true));
    }
  }, [updateOfferMutationIsError, updateOfferMutationError, dispatch]);

  const updatePromotionOfferDraft = useCallback(
    async (
      offerId: string,
      body: OfferDraftDataDTOType,
      handleDataSave: () => void
    ) => {
      dispatch(setIsUpdatePromotionOfferDraftIsFetching(true));
      await updateOfferMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: offerId,
        body: body,
      })
        .unwrap()
        .then(() => {
          handleDataSave();
        })
        .catch(() => {
          dispatch(setIsUpdatePromotionOfferDraftIsFetching(false));
          dispatch(setCurrentCombinationIsLoaded(true));
        });
    },
    [updateOfferMutation, dispatch]
  );

  const [
    getDiscountLinkPreviewMutation,
    {
      data: discountLinkPreviewData,
      error: discountLinkPreviewError,
      isLoading: discountLinkPreviewIsLoading,
    },
  ] = usePostPromotionsV6PromotionByIdSettingsDiscountLinkPreviewMutation();

  const getDiscountLinkPreview = useCallback(
    async (
      draftId: string,
      data: TriggerDiscountLinkDto,
      currentLink: TriggerDiscountLinkDto,
      setDiscountLinkPreviewError: Dispatch<SetStateAction<boolean>>
    ) => {
      await getDiscountLinkPreviewMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: draftId,
        triggerDiscountLinkDto: data,
      })
        .unwrap()
        .then(() => {
          setDiscountLinkPreviewError(false);
        })
        .catch((err) => {
          const invalidLink =
            err.data.errors[0] ===
            'TRIGGER_DISCOUNT_LINK_INVALID_VANITY_LINK_PATH';
          if (
            err.status === 400 &&
            (currentLink?.vanityLinkOption?.path !==
              data?.vanityLinkOption?.path ||
              invalidLink)
          ) {
            setDiscountLinkPreviewError(true);
          }
        });
    },
    [getDiscountLinkPreviewMutation]
  );

  const [
    duplicateOfferMutation,
    {
      error: duplicateOfferError,
      isLoading: duplicateOfferIsLoading,
      data: duplicateOfferData,
    },
  ] = usePostPromotionsV6PromotionByIdOffersAndOfferIdDuplicateMutation();
  const duplicateOffer = useCallback(
    async (promotionId: string, offerId: string, refetchData: () => void) => {
      await duplicateOfferMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: promotionId,
        offerId: offerId,
      })
        .unwrap()
        .then(() => {
          refetchData();
          dispatch(
            setToastMessages({
              error: false,
              message: 'OfferDuplicated',
            })
          );
        })
        .catch(() => null);
    },
    [duplicateOfferMutation]
  );

  const [
    deleteOfferMutation,
    {
      error: deleteOfferError,
      isLoading: deleteOfferIsLoading,
      data: deleteOfferData,
    },
  ] = useDeletePromotionsV6PromotionByIdOffersAndOfferIdMutation();
  const deleteOffer = useCallback(
    async (promotionId: string, offerId: string, refetchData: () => void) => {
      await deleteOfferMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: promotionId,
        offerId: offerId,
      })
        .unwrap()
        .then(() => {
          refetchData();
          dispatch(
            setToastMessages({
              error: false,
              message: 'OfferDeleted',
            })
          );
        })
        .catch(() => null);
    },
    [deleteOfferMutation]
  );

  return {
    tagsListIsLoading,
    tagsListIsError,
    tagsListIsSuccess,
    tagsListData,
    tagsListError,
    fetchTagsList,
    lookupShopifyDetailedObjectsIsLoading,
    lookupShopifyDetailedObjectsIsError,
    lookupShopifyDetailedObjectsIsSuccess,
    lookupShopifyDetailedObjectsData,
    lookupShopifyDetailedObjectsError,
    getLookupShopifyDetailedObjectsDetails,
    getDiscountLinkPreview,
    discountLinkPreviewData,
    discountLinkPreviewError,
    discountLinkPreviewIsLoading,
    fetchOffer,
    offerIsLoading,
    offerData,
    updateOfferMutationError,
    updateOfferMutationIsLoading,
    updateOfferMutationData,
    updateOfferMutationIsSuccess,
    updateOfferMutationIsError,
    updatePromotionOfferDraft,
    getCurrentCombinationOfferDraft,
    getOfTypeListIsLoading,
    getCurrentTypeOfList,
    getOfTypeListIsSuccess,
    getOfTypeListData,
    // shopifyDiscountCodesData,
    // fetchShopifyDiscountCodesOnPickerClick,
    fetchCombinationsOffersOnPickerClick,
    // shopifyDiscountCodesIsLoading,
    combinationsOffersIsLoading,
    getCombinationsOffersList,
    // getCurrentShopifyDiscountCodesData,
    getCurrentCombinationsOffersListData,
    duplicateOfferError,
    duplicateOfferIsLoading,
    duplicateOfferData,
    duplicateOffer,
    deleteOfferError,
    deleteOfferIsLoading,
    deleteOfferData,
    deleteOffer,
    fetchSmartTags,
  };
};
