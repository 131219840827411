import React, { useMemo } from 'react';
import {
  Banner,
  BlockStack,
  Card,
  Checkbox,
  ChoiceList,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { GiftsSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import {
  AllowCustomersToRemoveGiftsTypeDtoEnum,
  CheckoutModeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';

type RemovingFreeGiftsProps = {
  giftsState?: GiftsSettingsDto;
  handleUpdateGifts: (
    field: keyof GiftsSettingsDto,
    data: GiftsSettingsDto[keyof GiftsSettingsDto]
  ) => void;
};

export const RemovingFreeGifts: React.FC<RemovingFreeGiftsProps> = ({
  giftsState,
  handleUpdateGifts,
}) => {
  const [i18n] = useI18n();

  const allowOptions = useMemo(
    () =>
      Object.values(AllowCustomersToRemoveGiftsTypeDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value,
      })),
    [i18n]
  );

  return (
    <Card padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('RemovingFreeGiftsTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('RemovingFreeGiftsSubtitle')}
          </Text>
        </BlockStack>
        <ChoiceList
          title={''}
          choices={allowOptions}
          selected={[giftsState?.allowCustomersToRemoveGifts as string]}
          onChange={(value) =>
            handleUpdateGifts(
              'allowCustomersToRemoveGifts',
              value[0] as AllowCustomersToRemoveGiftsTypeDtoEnum
            )
          }
        />
        <BlockStack gap='100'>
          <Checkbox
            label={i18n.translate('RemoveFreeGifts')}
            checked={!!giftsState?.automaticallyRemoveFreeGifts}
            onChange={(value) =>
              handleUpdateGifts('automaticallyRemoveFreeGifts', value)
            }
          />
          {giftsState?.checkoutMode === CheckoutModeDtoEnum.DRAFT_ORDER && (
            <Checkbox
              label={i18n.translate('RemoveExcessGifts')}
              checked={!!giftsState?.automaticallyRemoveExcessGifts}
              onChange={(value) =>
                handleUpdateGifts('automaticallyRemoveExcessGifts', value)
              }
            />
          )}
        </BlockStack>
        <Banner>
          {i18n.translate(`${giftsState?.allowCustomersToRemoveGifts}_Banner`)}
        </Banner>
      </BlockStack>
    </Card>
  );
};
