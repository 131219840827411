import React, { useMemo, useState } from 'react';
import { BlockStack, ChoiceList, Link, Modal, Text } from '@shopify/polaris';
import { I18n } from '@shopify/react-i18n';

export enum ExportFileModalTypeEnum {
  CURRENT_PAGE = 'CURRENT_PAGE',
  ALL = 'ALL',
  SELECTED_ONLY = 'SELECTED_ONLY',
  SEARCH_MATCH = 'SEARCH_MATCH',
}

type ExportFileModalProps = {
  isOpen: boolean;
  i18n: I18n;
  selectedAmount?: number;
  filteredAmount: number;
  exportLoading: boolean;
  handleExportCodes: (exportType: ExportFileModalTypeEnum) => void;
  onClose: () => void;
};
export const ExportFileModal: React.FC<ExportFileModalProps> = ({
  isOpen,
  i18n,
  exportLoading,
  selectedAmount,
  filteredAmount,
  onClose,
  handleExportCodes,
}) => {
  const [exportType, setExportType] = useState<ExportFileModalTypeEnum>(
    ExportFileModalTypeEnum.ALL
  );
  const exportOptions = useMemo(
    () =>
      Object.values(ExportFileModalTypeEnum).map((value) => ({
        label: i18n.translate(`ExportModal.${value}_LABEL`, {
          amount:
            value === ExportFileModalTypeEnum.SELECTED_ONLY
              ? selectedAmount
              : filteredAmount,
        }),
        disabled:
          (value === ExportFileModalTypeEnum.SELECTED_ONLY &&
            !selectedAmount) ||
          (value === ExportFileModalTypeEnum.SEARCH_MATCH && !filteredAmount),
        value: value,
      })),
    [i18n, selectedAmount, filteredAmount]
  );

  return (
    <Modal
      primaryAction={{
        content: i18n.translate('ExportModal.Export'),
        loading: exportLoading,
        onAction: () => handleExportCodes(exportType),
      }}
      secondaryActions={[
        {
          content: i18n.translate('ExportModal.Cancel'),
          onAction: onClose,
        },
      ]}
      title={i18n.translate('ExportModal.ModalTitle')}
      open={isOpen}
      onClose={onClose}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <Text as='p'>
            {i18n.translate('ExportModal.ModalSubtitle', {
              learnMore: <Link>{i18n.translate('ExportModal.LearnMore')}</Link>,
            })}
          </Text>
          <ChoiceList
            title=''
            selected={[exportType]}
            choices={exportOptions}
            onChange={(value) =>
              setExportType(value[0] as ExportFileModalTypeEnum)
            }
          />
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
