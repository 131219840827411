import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './LearnMoreModal.scss';
import YouTube, { YouTubeProps } from 'react-youtube';
import { validate, oembed } from '@loomhq/loom-embed';
import { Modal } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OEmbedInterface } from '@loomhq/loom-embed/dist/.types/oembed';

type LearnMoreModalProps = {
  title?: string;
  url?: string;
  setDuration?: React.Dispatch<React.SetStateAction<number>>;
  setCurrentTime?: React.Dispatch<React.SetStateAction<number>>;
  onModalClose: () => void;
};

export const LearnMoreModal: React.FC<LearnMoreModalProps> = (props) => {
  const { title, url, onModalClose, setDuration, setCurrentTime } = props;

  const [i18n] = useI18n();
  const [loomEmbedCode, setLoomEmbedCode] = useState<string | null>(null);

  const opts: YouTubeProps['opts'] = {
    width: '100%',
    height: '100%',
    playerVars: {
      controls: 1,
      autoplay: 0,
    },
  };

  const youtubePattern = /[?&]v=([^&]+)/;

  const currentUrl = useMemo(
    () => url || 'https://www.loom.com/share/cd98f3811dcf418aa0d2104b3c567421',
    [url]
  );
  const isLoom = useMemo(() => validate.isLoomUrl(currentUrl), [currentUrl]);

  const videoId = useMemo(
    () => currentUrl.match(youtubePattern)?.[1],
    [currentUrl]
  );

  const onStateChange = useCallback((event: { target: any }) => {
    const player = event.target;
    const duration = player.getDuration();
    const currentTime = player.getCurrentTime();
    setDuration?.(duration);
    setCurrentTime?.(currentTime);
  }, []);

  // Fetch Loom embed code when the URL changes and it's a valid Loom URL
  useEffect(() => {
    if (isLoom) {
      oembed(currentUrl)
        .then((data: OEmbedInterface) => {
          setDuration?.(data.duration);
          setCurrentTime?.(Math.floor(Math.random() * (data.duration + 1)));
          setLoomEmbedCode(data.html);
        })
        .catch((error) => {
          console.error('Error fetching Loom oembed:', error);
        });
    } else {
      setLoomEmbedCode(null);
    }
  }, [currentUrl, isLoom]);

  return (
    <Modal open={true} onClose={onModalClose} title={title} size='large'>
      <Modal.Section>
        {isLoom ? (
          <div className='videoWrapper'>
            {loomEmbedCode && (
              <div
                dangerouslySetInnerHTML={{ __html: loomEmbedCode }}
                style={{ height: '100%', width: '100%' }}
              />
            )}
          </div>
        ) : videoId ? (
          <div className='videoWrapper'>
            <YouTube
              videoId={videoId}
              style={{ height: '63.5vh' }}
              opts={opts}
              onStateChange={onStateChange}
            />
          </div>
        ) : (
          i18n.translate('invalidVideoUrl')
        )}
      </Modal.Section>
    </Modal>
  );
};
