import React, { useMemo, useState } from 'react';
import { BlockStack, ChoiceList, Modal, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { DateTimePicker } from 'core/components';
import {
  OptionDtoNullableDateTime,
  SchedulePromotionRequestDto,
} from 'core/api/adminPromotions/adminPromotionsApi';

enum PublishTypeEnum {
  PUBLISH_NOW = 'PUBLISH_NOW',
  SCHEDULE_LATER = 'SCHEDULE_LATER',
}

type PublishOrScheduleModalProps = {
  isOpen: boolean;
  timezone: string;
  onClose: () => void;
  onPublish: () => void;
  onSchedule: (data: SchedulePromotionRequestDto, publish?: boolean) => void;
};
export const PublishOrScheduleModal: React.FC<PublishOrScheduleModalProps> = ({
  isOpen,
  timezone,
  onClose,
  onPublish,
  onSchedule,
}) => {
  const [i18n] = useI18n();
  const [publishType, setPublishType] = useState<PublishTypeEnum>(
    PublishTypeEnum.PUBLISH_NOW
  );
  const [schedulePromotionSetup, setSchedulePromotionSetup] =
    useState<OptionDtoNullableDateTime>({ enabled: true });

  const publishOptions = useMemo(
    () =>
      Object.values(PublishTypeEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
      })),
    [i18n]
  );

  const isDateInThePast = useMemo(() => {
    return new Date(schedulePromotionSetup.value || 0) < new Date();
  }, [schedulePromotionSetup.value]);

  const initialDate = useMemo(() => {
    const currentDate = new Date();
    return currentDate.setHours(currentDate.getHours() + 24);
  }, []);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={i18n.translate('PublishOrSchedulePromotion')}
      primaryAction={
        publishType === PublishTypeEnum.PUBLISH_NOW
          ? {
              content: i18n.translate('Publish'),
              onAction: () => {
                onPublish();
                onClose();
              },
            }
          : {
              content: i18n.translate('Schedule'),
              disabled: isDateInThePast,
              onAction: () => {
                onSchedule({ startDate: schedulePromotionSetup }, true);
                onClose();
              },
            }
      }
      secondaryActions={[
        {
          content: i18n.translate('Cancel'),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <ChoiceList
            choices={publishOptions}
            title=''
            selected={[publishType]}
            onChange={(value) => setPublishType(value[0] as PublishTypeEnum)}
          />
          {publishType === PublishTypeEnum.SCHEDULE_LATER && (
            <BlockStack gap='400'>
              <Text as='p'>{i18n.translate('ScheduleDescription')}</Text>
              <DateTimePicker
                dateLabel={i18n.translate('Date')}
                timeLabel={i18n.translate('Time')}
                timestampValue={schedulePromotionSetup?.value || initialDate}
                error={isDateInThePast ? i18n.translate('DateInPastError') : ''}
                setTimestampValue={(date) =>
                  setSchedulePromotionSetup((prev) => ({
                    ...prev,
                    value: date,
                  }))
                }
              />
              <Text as='p' tone='subdued'>
                {i18n.translate('Timezone', {
                  timezone: timezone,
                })}
              </Text>
            </BlockStack>
          )}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
