import React, { useCallback, useMemo, useState } from 'react';
import { ActionList, Button, Icon, Popover } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { AnalyticsQueryPeriodDtoEnum } from 'core/api/adminAnalytics/adminAnalyticsEnums';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { setAnalyticsPeriod } from 'core/store/settingsSlice';
import { CheckSmallIcon, CalendarIcon } from '@shopify/polaris-icons';

export const PeriodSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const [i18n] = useI18n();
  const { analyticsPeriod } = useAppSelector((store) => store.settings);
  const [periodActive, setPeriodActive] = useState<boolean>(false);

  const periodOptions = useMemo(
    () =>
      Object.values(AnalyticsQueryPeriodDtoEnum).map((value) => ({
        content: i18n.translate(value),
        active: value === analyticsPeriod,
        onAction: () => {
          dispatch(setAnalyticsPeriod(value));
          setPeriodActive(false);
        },
        suffix:
          value === analyticsPeriod ? <Icon source={CheckSmallIcon} /> : null,
      })),
    [analyticsPeriod, i18n]
  );

  const togglePeriod = useCallback(
    () => setPeriodActive(!periodActive),
    [periodActive]
  );
  return (
    <Popover
      active={periodActive}
      activator={
        <Button onClick={togglePeriod} icon={CalendarIcon}>
          {i18n.translate(`${analyticsPeriod}`)}
        </Button>
      }
      autofocusTarget='first-node'
      onClose={togglePeriod}
    >
      <ActionList actionRole='menuitem' items={periodOptions} />
    </Popover>
  );
};
