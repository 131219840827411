import React from 'react';
import { Element } from 'react-scroll';

import { useI18n } from '@shopify/react-i18n';
import {
  Badge,
  BlockStack,
  Card,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { ChevronRightIcon, DiscountIcon } from '@shopify/polaris-icons';

import './PromotionLevelStylePreset.scss';
import { GetPresetListResponseDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { SettingsModalSubPathsEnums } from 'pages/enums/PagesEnums';

type PromotionLevelStylePreset = {
  onLevelClick: (subPath: string) => void;
  promotionLevelStylePresets: GetPresetListResponseDto | null;
};

export const PromotionLevelStylePreset: React.FC<PromotionLevelStylePreset> = ({
  onLevelClick,
  promotionLevelStylePresets,
}) => {
  const [i18n] = useI18n();

  return (
    <Element name='PromotionLevel'>
      <div className='cardWrapper'>
        <Card roundedAbove='sm'>
          <div className='level'>
            <InlineStack gap={'300'} blockAlign='center'>
              <Icon tone='base' source={DiscountIcon} />
              <BlockStack gap='0'>
                <Text as='p'>{i18n.translate('promotionLevelTitle')}</Text>
                <Text tone='subdued' as='p'>
                  {i18n.translate('promotionLevelSubtitle')}
                </Text>
              </BlockStack>
            </InlineStack>
            <div
              className='navigate'
              onClick={() =>
                onLevelClick(
                  SettingsModalSubPathsEnums.PromotionLevelStylePresets
                )
              }
            >
              <Badge>
                {`${promotionLevelStylePresets?.totalItems} ${
                  promotionLevelStylePresets?.totalItems === 1
                    ? i18n.translate('style')
                    : i18n.translate('styles')
                }`}
              </Badge>
              <Icon tone='base' source={ChevronRightIcon} />
            </div>
          </div>
        </Card>
      </div>
    </Element>
  );
};
