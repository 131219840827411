import React, { useEffect, useMemo } from 'react';
import { BlockStack, Card, Checkbox, Text, TextField } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferPrerequisiteEntitledNameFiltersDto,
  OptionDtoString,
} from 'core/api/adminPromotions/adminPromotionsApi';
import './NameFilters.scss';
import { OfferTargetTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useAppSelector } from 'core/hooks';

export type NameFilterProps = {
  type: OfferTargetTypeDtoEnum;
  data?: OfferPrerequisiteEntitledNameFiltersDto;
  setIsNameFilterValid:
    | React.Dispatch<React.SetStateAction<boolean>>
    | ((formIsInvalid: boolean) => void);
  handleUpdateNameFilters: (
    filterName: keyof OfferPrerequisiteEntitledNameFiltersDto,
    field: keyof OptionDtoString,
    value: string | boolean | null
  ) => void;
};

export const NameFilters: React.FC<NameFilterProps> = (props) => {
  const { type, data, handleUpdateNameFilters, setIsNameFilterValid } = props;

  const { showValidation } = useAppSelector((store) => store.promotions);

  const [i18n] = useI18n();

  const isError = useMemo(
    () =>
      !!(
        (data?.productNameFilter?.enabled &&
          (!data?.productNameFilter?.value ||
            (data?.productNameFilter?.value?.length || 0) < 1 ||
            (data?.productNameFilter.value?.length || 0) > 50)) ||
        (data?.productVariantNameFilter?.enabled &&
          (!data?.productVariantNameFilter?.value ||
            (data?.productVariantNameFilter?.value?.length || 0) < 1 ||
            (data?.productVariantNameFilter?.value?.length || 0) > 50))
      ),
    [data]
  );

  useEffect(() => {
    setIsNameFilterValid(!isError);
  }, [isError]);

  return type !== OfferTargetTypeDtoEnum.VARIANTS ? (
    <Card>
      <BlockStack gap='400'>
        <Text as='h2' variant='headingSm'>
          {i18n.translate('Filters')}
        </Text>
        <div className='NameFilterContentContainer'>
          {[
            OfferTargetTypeDtoEnum.ALL,
            OfferTargetTypeDtoEnum.COLLECTIONS,
          ].includes(type) && (
            <>
              <Checkbox
                label={i18n.translate('RequireProductName')}
                checked={data?.productNameFilter?.enabled}
                onChange={(value) =>
                  handleUpdateNameFilters('productNameFilter', 'enabled', value)
                }
              />
              {data?.productNameFilter?.enabled && (
                <div className='textFieldWrapper'>
                  <TextField
                    label=''
                    labelHidden
                    value={data?.productNameFilter.value || ''}
                    onChange={(value) =>
                      handleUpdateNameFilters(
                        'productNameFilter',
                        'value',
                        value
                      )
                    }
                    placeholder={i18n.translate('placeHolder')}
                    autoComplete='off'
                    error={
                      showValidation &&
                      (data?.productNameFilter?.value?.length || 0) < 1
                        ? i18n.translate('lessThanOne')
                        : showValidation &&
                          (data?.productNameFilter.value?.length || 0) > 50
                        ? i18n.translate('moreThanOne')
                        : false
                    }
                  />
                </div>
              )}
            </>
          )}
          {[
            OfferTargetTypeDtoEnum.ALL,
            OfferTargetTypeDtoEnum.COLLECTIONS,
            OfferTargetTypeDtoEnum.PRODUCTS,
          ].includes(type) && (
            <>
              <Checkbox
                label={i18n.translate('RequireProductVariantName')}
                checked={data?.productVariantNameFilter?.enabled}
                onChange={(value) =>
                  handleUpdateNameFilters(
                    'productVariantNameFilter',
                    'enabled',
                    value
                  )
                }
              />
              {data?.productVariantNameFilter?.enabled && (
                <div className='textFieldWrapper'>
                  <TextField
                    label=''
                    labelHidden
                    value={data?.productVariantNameFilter.value || ''}
                    onChange={(value) =>
                      handleUpdateNameFilters(
                        'productVariantNameFilter',
                        'value',
                        value
                      )
                    }
                    placeholder={i18n.translate('placeHolder')}
                    autoComplete='off'
                    error={
                      showValidation &&
                      (data?.productVariantNameFilter?.value?.length || 0) < 1
                        ? i18n.translate('lessThanOne')
                        : showValidation &&
                          (data?.productVariantNameFilter.value?.length || 0) >
                            50
                        ? i18n.translate('moreThanOne')
                        : false
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </BlockStack>
    </Card>
  ) : null;
};
