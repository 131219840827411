import React, { useCallback, useEffect, useState } from 'react';
import { Banner, BlockStack, Modal, TextField } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { CustomShippingRateDto } from 'core/api/adminSettings/adminSettingsApi';

type ConfigureShippingRateModalProps = {
  targetRate: CustomShippingRateDto | null;
  isLoading: boolean;
  onUpdateRate: (data: CustomShippingRateDto) => void;
  onClose: () => void;
};
export const ConfigureShippingRateModal: React.FC<
  ConfigureShippingRateModalProps
> = ({ targetRate, onClose, onUpdateRate, isLoading }) => {
  const [i18n] = useI18n();
  const [label, setLabel] = useState<string>('');

  const onPrimaryAction = useCallback(
    () =>
      onUpdateRate({
        label: label,
        ...(targetRate?.id ? { id: targetRate?.id } : {}),
      }),
    [label, targetRate?.id]
  );

  useEffect(() => {
    if (targetRate?.label) {
      setLabel(targetRate?.label);
    }
  }, []);

  return (
    <Modal
      primaryAction={{
        content: i18n.translate('Done'),
        onAction: onPrimaryAction,
        disabled: !label || label === targetRate?.label,
        loading: isLoading,
      }}
      secondaryActions={[
        { content: i18n.translate('Cancel'), onAction: onClose },
      ]}
      open={!!targetRate}
      title={i18n.translate(targetRate?.id ? 'EditRate' : 'AddRate')}
      onClose={onClose}
    >
      <Modal.Section>
        <BlockStack gap='400'>
          <Banner>{i18n.translate('AddRateBanner')}</Banner>
          <TextField
            label={i18n.translate('Label')}
            value={label}
            autoComplete=''
            onChange={setLabel}
          />
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
