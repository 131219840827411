export const enum NavTabs {
  Home = 'HOME',
  CreatePromotion = 'CREATE PROMOTION',
  Promotions = 'PROMOTIONS',
  AlertCenter = 'ALERT CENTER',
  ReportingPromotionActivity = 'PROMOTION ACTIVITY',
  ReportingResultsByPromotion = 'RESULTS BY PROMOTION',
  ReportingResultsByOrder = 'RESULTS BY ORDER',
  ReportingResultsByPromotionCode = 'RESULTS BY PROMOTION CODE',
  BlockSettings = 'BLOCK SETTINGS',
  Themes = 'Themes',
  News = "WHAT'S NEW",
  FeatureRequest = 'FEATURE REQUEST',
  Help = 'HELP',
  Settings = 'SETTINGS',
  Analytics = 'ANALYTICS',
}
