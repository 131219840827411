import { I18n } from '@shopify/react-i18n';
import { format, formatDistanceToNow, parse } from 'date-fns';
import { upperFirst } from 'lodash';

export const getCurrencySymbol = (): string => {
  // Use Intl.NumberFormat to format currency
  const formatter = new Intl.NumberFormat(navigator.language || 'en', {
    style: 'currency',
    currency: localStorage.getItem('currencyCode') || 'USD',
  });

  // The currency symbol is usually the first character in the formatted string
  const parts = formatter.formatToParts(0);
  const currencySymbol = parts.find((part) => part.type === 'currency')?.value;

  return currencySymbol || '';
};

export const parseCurrencyString = (
  value?: string | number | null
): number | undefined => {
  if (!value) {
    return undefined;
  }
  if (typeof value === 'number') {
    return value;
  }
  // Use Intl.NumberFormat with currency formatting to parse the value
  const formatter = new Intl.NumberFormat(navigator.language || 'en', {
    style: 'currency',
    currency: localStorage.getItem('currencyCode') || 'USD',
  });

  // Extract the parts used by the formatter to identify decimal and group separators
  const parts = formatter.formatToParts(12345.6);
  const groupSeparator =
    parts.find((part) => part.type === 'group')?.value ?? '';
  const decimalSeparator =
    parts.find((part) => part.type === 'decimal')?.value ?? '.';

  // Remove group separators and replace decimal separator with a dot
  const normalizedValue = value
    .replace(new RegExp(`\\${groupSeparator}`, 'g'), '')
    .replace(decimalSeparator, '.');

  // Parse as a float
  const number = parseFloat(normalizedValue);

  return isNaN(number) ? undefined : number;
};

export const formatCurrencyNumber = (value: number): string => {
  if (isNaN(value)) {
    return '';
  }

  const formatter = new Intl.NumberFormat(navigator.language || 'en', {
    style: 'currency',
    currency: localStorage.getItem('currencyCode') || 'USD',
  });

  return formatter.format(value);
};

export const formatDate = (date: Date, i18n?: I18n) => {
  return i18n ? i18n.formatDate(date) : format(date, 'dd/MM/yyyy');
};

export const formatDateFromString = (date: string) =>
  parse(date, 'dd/MM/yyyy', new Date());

export const formatPercentage = (i18n: I18n, value?: number) =>
  i18n.formatNumber((value || 0) * 0.01, {
    precision: 2,
    as: 'percent',
  });

export const formatNumber = (i18n: I18n, value?: number) =>
  i18n.formatNumber(value || 0, {
    precision: 2,
  });

export const toLowerCaseAndCapitalizeFirstLetter = (text: string): string => {
  return upperFirst(text.toLowerCase());
};

export const formatNameEllipsis = (name: string, maxCharacteres: number) =>
  name.length <= maxCharacteres
    ? name
    : name.substring(0, maxCharacteres) + '...';

export const formatDateWithTimezone = (timestamp: number) => {
  const date = new Date(timestamp);
  return format(date, 'dd.MM.yyyy HH:mm zzz');
};
export const formatDateAndTime = (timestamp: number) => {
  const date = new Date(timestamp);
  return format(date, 'dd.MM.yyyy HH:mm');
};
export const formatTimeDistance = (timestamp: number) => {
  const date = new Date(timestamp);
  const now = new Date();
  const diffInMinutes = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60)
  );
  if (diffInMinutes < 60) {
    return formatDistanceToNow(date, { addSuffix: true });
  } else if (diffInMinutes < 24 * 60) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours} hours ago`;
  } else {
    const diffInDays = Math.floor(diffInMinutes / (24 * 60));
    return `${diffInDays} days ago`;
  }
};

export const formatDateWithOrdinalCET = (timestamp: string) => {
  const date = new Date(parseInt(timestamp));
  const formattedDate = format(date, 'MMMM do yyyy, HH:mm');
  return `${formattedDate} CET`;
};
