import React, { useMemo } from 'react';
import {
  GetCurrentPlanResponseDtoRead,
  PlanLevelDto,
  SubscriptionPlanSetupStatusEntryDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useI18n } from '@shopify/react-i18n';
import { Banner, BlockStack, Button, Link, Text } from '@shopify/polaris';
import { PlanLevelDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { ChangePlanModalTypeEnum } from 'features/settings/components/GeneralSettings/Plan/components/ChangePlanModal/ChangePlanModal';
import { PlanIntervalDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { ChangePlanConfigDto } from 'features/settings/components/GeneralSettings/Plan/Plan';

type SubscriptionPlanBannerProps = {
  subscriptionPlan?: SubscriptionPlanSetupStatusEntryDto;
  currentPlanData?: GetCurrentPlanResponseDtoRead;
  stepCompleted: boolean;
  handleOpenPlans: () => void; // For the select banner
  setChangePlanConfig: React.Dispatch<
    React.SetStateAction<ChangePlanConfigDto | null>
  >; // For the upgrade banner
};

export const SubscriptionPlanBanner: React.FC<SubscriptionPlanBannerProps> = ({
  subscriptionPlan,
  stepCompleted,
  currentPlanData,
  handleOpenPlans,
  setChangePlanConfig,
}) => {
  const [i18n] = useI18n();

  const { freeTrialDays, isCompleted } = subscriptionPlan || {};
  const { usage, plan } = currentPlanData || {};

  const thresholdPercentage = useMemo(() => {
    const usageValue = usage?.mainUsageDetails?.usage ?? 0;
    const thresholdValue = usage?.mainUsageDetails?.threshold ?? 1; // Default to 1 to avoid division by 0

    return Math.round((100 * usageValue) / thresholdValue);
  }, [usage?.mainUsageDetails?.threshold, usage?.mainUsageDetails?.usage]);

  const isEnterprise = useMemo(
    () => plan?.level === PlanLevelDtoEnum.ENTERPRISE,
    [plan?.level]
  );

  const shouldShowUpgradeBanner = useMemo(() => {
    if (!isCompleted) return false; // Skip upgrade banner logic if the setup is not complete
    return (
      (thresholdPercentage >= 60 && !isEnterprise) || thresholdPercentage > 110
    );
  }, [thresholdPercentage, isEnterprise, isCompleted]);

  const shouldShowSelectBanner = useMemo(
    () => !isCompleted && stepCompleted,
    [isCompleted, stepCompleted]
  );

  const remainingSessions =
    (usage?.mainUsageDetails?.threshold ?? 0) -
    (usage?.mainUsageDetails?.usage ?? 0);

  let message;
  let title = '';
  let buttonLabel = '';
  let handleBtnClick;

  // For the select banner
  if (shouldShowSelectBanner && freeTrialDays) {
    message = i18n.translate('Banner.subscription.selectWithTrial', {
      freeTrialDays,
      link: (
        <Link url='/'>{i18n.translate('Banner.subscription.learnMore')}</Link>
      ),
    });
    title = i18n.translate('Banner.subscription.selectTitle');
    buttonLabel = i18n.translate('Banner.subscription.explorePlans');
    handleBtnClick = handleOpenPlans;
  } else if (shouldShowSelectBanner && !freeTrialDays) {
    message = i18n.translate('Banner.subscription.selectWithoutTrial', {
      link: (
        <Link url='/'>{i18n.translate('Banner.subscription.learnMore')}</Link>
      ),
    });
    title = i18n.translate('Banner.subscription.selectTitle');
    buttonLabel = i18n.translate('Banner.subscription.explorePlans');
    handleBtnClick = handleOpenPlans;
  }

  // For the upgrade banner
  if (shouldShowUpgradeBanner) {
    message = i18n.translate('Banner.subscription.upgrade', {
      remainingSessions,
      threshold: usage?.mainUsageDetails?.threshold ?? 0,
      link: (
        <Link url='/'>{i18n.translate('Banner.subscription.learnMore')}</Link>
      ),
    });
    title = i18n.translate('Banner.subscription.upgradeTitle');
    buttonLabel = i18n.translate('Banner.subscription.upgradeToPro');
    handleBtnClick = () => {
      setChangePlanConfig({
        interval: plan?.interval as PlanIntervalDtoEnum,
        modalType: ChangePlanModalTypeEnum.CHANGE_PLAN,
        targetPlanLevel: plan?.upgradeDetails?.nextHigherTierPlan
          ?.level as PlanLevelDto,
      });
    };
  }

  if (!message) {
    return null; // Return nothing if no message is needed
  }

  return (
    <div>
      <Banner tone='info' title={title}>
        <BlockStack gap='200' inlineAlign='start'>
          <Text as='p'>{message}</Text>
          <Button onClick={handleBtnClick}>{buttonLabel}</Button>
        </BlockStack>
      </Banner>
    </div>
  );
};
