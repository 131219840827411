import React from 'react';
import {
  Offset,
  Position,
  ShopSettingsWrapper,
  Stacking,
} from '../../../../../components';
import { PromotionalBadgePresetGeneralDto } from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { useAppDispatch } from 'core/hooks';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';

type PromotionalBadgeGeneralProps = {
  general: PromotionalBadgePresetGeneralDto;
  isPresetEditor?: boolean;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionalBadgeGeneral: React.FC<PromotionalBadgeGeneralProps> = ({
  general,
  isPresetEditor,
  onShowHide,
  onRemove,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  return (
    <div className='PromotionalBadgeGeneral'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onRemove={onRemove}
        name={WidgetListTypeDtoEnum.PROMOTIONAL_BADGE}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <ShopSettingsWrapper
        onOpen={() =>
          dispatch(
            setToggleSettingsConfig({
              action: 'open',
              path: SettingsModalPathsEnums.PromotionalBadge,
            })
          )
        }
      >
        <div className='RightSideSection'>
          <Position position={general.shopSettings?.position || {}} disabled />
        </div>
        <div className='RightSideSection'>
          <Offset
            offset={general.shopSettings?.offset || {}}
            supportCustom
            disabled
          />
        </div>
        <div className='RightSideSection'>
          <Stacking
            stacking={general.shopSettings?.stacking || {}}
            disabled
            allowDirection
            note={!isPresetEditor ? i18n.translate('StackingNote') : ''}
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default PromotionalBadgeGeneral;
