import React, { ReactNode, useMemo } from 'react';
import {
  Button,
  Layout,
  Page,
  Icon,
  IconSource,
  Text,
  ButtonGroup,
} from '@shopify/polaris';
import { SettingsIcon, AlertTriangleIcon, XIcon } from '@shopify/polaris-icons';
import './SettingsLayout.scss';
import { useAppSelector } from 'core/hooks';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import { SaveBar } from '@shopify/app-bridge-react';
import { NavPaths } from 'core/enums/NavPathsEnum';

type SettingsLayoutProps = {
  children?: ReactNode;
  isMaxModal?: boolean;
  onDiscardChanges: () => void;
  onSaveChanges: () => void;
};

export const SettingsLayout: React.FC<SettingsLayoutProps> = (props) => {
  const { children, isMaxModal, onDiscardChanges, onSaveChanges } = props;
  const { isUnsavedChanges, isValidationError, isSettingsSaving } =
    useAppSelector((state) => state.settings);

  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const location = useLocation();

  const widthNeeded = useMemo(
    () =>
      isMaxModal
        ? '100vw'
        : location.pathname.includes(`/${NavPaths.Settings}`)
        ? '100%'
        : 'calc(100% - 20px)',
    [isMaxModal, location]
  );

  return (
    <div
      className='SettingsLayout'
      id='SettingsLayout'
      style={{
        width: widthNeeded,
      }}
    >
      <Page>
        <Layout>
          {!isMaxModal && !location.pathname.includes(`/${NavPaths.Settings}`) && (
            <Layout.Section>
              <div className='SettingsLayoutHeader'>
                <div className='HeaderTitle'>
                  {isUnsavedChanges ? (
                    <>
                      <Icon
                        source={AlertTriangleIcon as IconSource}
                        tone='base'
                      />
                      <Text fontWeight='semibold' as='h1'>
                        {i18n.translate(`UnsavedChanges`)}
                      </Text>
                    </>
                  ) : (
                    <>
                      <Icon source={SettingsIcon as IconSource} tone='base' />
                      <Text fontWeight='semibold' as='h1'>
                        {i18n.translate(`Settings`)}
                      </Text>
                    </>
                  )}
                </div>
                <div className='HeaderActions'>
                  {isUnsavedChanges || isSettingsSaving ? (
                    <ButtonGroup>
                      <Button onClick={onDiscardChanges} variant='tertiary'>
                        {i18n.translate(`Discard`)}
                      </Button>
                      <Button
                        variant='primary'
                        disabled={isValidationError}
                        loading={isSettingsSaving}
                        onClick={onSaveChanges}
                      >
                        {i18n.translate(`Save`)}
                      </Button>
                    </ButtonGroup>
                  ) : (
                    <>
                      <div
                        onClick={() => setSearchParams({})}
                        className='CloseSettingsIcon'
                      >
                        <Icon tone='base' source={XIcon} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Layout.Section>
          )}
          {location.pathname.includes(`/${NavPaths.Settings}`) && !isMaxModal && (
            <SaveBar
              id='general-settings-page-save-bar'
              open={isUnsavedChanges}
            >
              <button
                // eslint-disable-next-line react/no-unknown-property
                variant='primary'
                onClick={onSaveChanges}
                loading={isSettingsSaving}
                disabled={isValidationError}
              />
              <button onClick={onDiscardChanges} />
            </SaveBar>
          )}
          <Layout.Section>{children}</Layout.Section>
        </Layout>
      </Page>
    </div>
  );
};
