import React, { useCallback } from 'react';
import {
  PromotionCodeFieldGeneralPresetDto,
  PromotionCodeFieldPromotionDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import {
  Background,
  CornerRadius,
  ShopSettingsWrapper,
  SingleInputSelection,
  Size,
  TextEntries,
  TextStyle,
} from '../../../../../components';
import {
  PromotionCodeFieldDisplayModeDtoEnum,
  PromotionCodeFieldHeaderStyleDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { Link, Text } from '@shopify/polaris';
import { isEqual } from 'lodash';
import { useAppDispatch } from 'core/hooks';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';

type PromotionCodeGeneralProps = {
  general: PromotionCodeFieldPromotionDto;
  defaultGeneral: PromotionCodeFieldPromotionDto;
  shopDefaultGeneral: PromotionCodeFieldGeneralPresetDto;
  defaultLanguage: string;
  isPresetEditor?: boolean;
  type:
    | WidgetListTypeDtoEnum.DRAWER_CART_PROMOTION_CODE_FIELD
    | WidgetListTypeDtoEnum.CART_PROMOTION_CODE_FIELD;
  setGeneral: (data: PromotionCodeFieldPromotionDto) => void;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionCodeGeneral: React.FC<PromotionCodeGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  defaultLanguage,
  isPresetEditor,
  type,
  setGeneral,
  onShowHide,
  onRemove,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const resetAllToSystemDefault = useCallback(() => {
    const { background, cornerRadius, headerStyle, headerText } =
      shopDefaultGeneral;
    setGeneral({
      ...general,
      background,
      cornerRadius,
      headerStyle,
      headerText,
    });
  }, [shopDefaultGeneral, general]);

  const handleUpdateGeneral = useCallback(
    (
      field: keyof PromotionCodeFieldPromotionDto,
      data: PromotionCodeFieldPromotionDto[keyof PromotionCodeFieldPromotionDto]
    ) => setGeneral({ ...general, [field]: data }),
    [general]
  );

  const headerStyleOptions = Object.values(
    PromotionCodeFieldHeaderStyleDtoEnum
  ).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });

  const displayModeOptions = Object.values(
    PromotionCodeFieldDisplayModeDtoEnum
  ).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });
  return (
    <div className='PromotionCodeGeneral'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onRemove={onRemove}
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={resetAllToSystemDefault}
        name={type}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />

      <div className='RightSideSection'>
        <SingleInputSelection
          options={headerStyleOptions}
          label={i18n.translate('HeaderStyle')}
          inherited={
            !isPresetEditor
              ? isEqual(general.headerStyle, defaultGeneral.headerStyle)
              : undefined
          }
          selectedOption={
            general.headerStyle || PromotionCodeFieldHeaderStyleDtoEnum.STYLE_1
          }
          setOption={(value) =>
            handleUpdateGeneral(
              'headerStyle',
              value as PromotionCodeFieldHeaderStyleDtoEnum
            )
          }
          onSystemReset={() =>
            handleUpdateGeneral('headerStyle', shopDefaultGeneral.headerStyle)
          }
        />
      </div>
      <div className='RightSideSection'>
        <TextEntries
          textMessage={general.headerText?.message || {}}
          defaultLanguage={defaultLanguage || ''}
          inherited={
            !isPresetEditor
              ? isEqual(general.headerText, defaultGeneral.headerText)
              : undefined
          }
          disableGoal
          disableDevice
          disableColor={(general.headerText?.style || {}).color !== undefined}
          disableCollapsed
          title={i18n.translate('HeaderText')}
          setTextMessage={(data) =>
            handleUpdateGeneral('headerText', {
              ...general.headerText,
              message: data,
            })
          }
          onSystemReset={() =>
            handleUpdateGeneral('headerText', shopDefaultGeneral.headerText)
          }
        />
        <TextStyle
          textStyle={general.headerText?.style || {}}
          onSystemReset={() =>
            handleUpdateGeneral('headerText', {
              ...general.headerText,
              style: shopDefaultGeneral.headerText?.style,
            })
          }
          hideHeader
          setTextStyle={(data) =>
            handleUpdateGeneral('headerText', {
              ...general.headerText,
              style: data,
            })
          }
        />
      </div>
      <div className='RightSideSection'>
        <CornerRadius
          cornerRadius={general.cornerRadius || {}}
          inherited={
            !isPresetEditor
              ? isEqual(general.cornerRadius, defaultGeneral.cornerRadius)
              : undefined
          }
          onSystemReset={() =>
            handleUpdateGeneral('cornerRadius', shopDefaultGeneral.cornerRadius)
          }
          setCornerRadius={(data) => handleUpdateGeneral('cornerRadius', data)}
        />
      </div>
      <div className='RightSideSection'>
        <Background
          background={general.background || {}}
          inherited={
            !isPresetEditor
              ? isEqual(general.background, defaultGeneral.background)
              : undefined
          }
          setBackground={(data) => handleUpdateGeneral('background', data)}
          onSystemReset={() =>
            handleUpdateGeneral('background', shopDefaultGeneral.background)
          }
        />
      </div>
      <ShopSettingsWrapper
        onOpen={() =>
          //IMPROVE PATH IN FUTURE (for Andriy)
          dispatch(
            setToggleSettingsConfig({
              action: 'open',
              path: SettingsModalPathsEnums.PromotionCodeField,
            })
          )
        }
      >
        <div className='RightSideSection'>
          <Size size={general.shopSettings?.size || {}} disableWidth disabled />
        </div>
        <div className='RightSideSection'>
          <SingleInputSelection
            options={displayModeOptions}
            label={i18n.translate('DisplayMode')}
            selectedOption={
              general.shopSettings?.displayMode ||
              PromotionCodeFieldDisplayModeDtoEnum.COLLAPSED
            }
            disabled
            noteContent={
              <Text tone='subdued' as='p'>
                {i18n.translate('DisplayModeNote')}
                <Link
                  onClick={() =>
                    //IMPROVE PATH IN FUTURE (for Andriy)
                    dispatch(
                      setToggleSettingsConfig({
                        action: 'open',
                        path: SettingsModalPathsEnums.PromotionCodeField,
                      })
                    )
                  }
                >
                  {i18n.translate('OpenShopSettings')}
                </Link>
              </Text>
            }
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default PromotionCodeGeneral;
