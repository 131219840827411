import React, { useState } from 'react';
import {
  BlockStack,
  Button,
  Card,
  InlineStack,
  Tag,
  Text,
} from '@shopify/polaris';
import {
  PromotionOverviewDetailsDto,
  PromotionStatusDto,
  TriggerTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import EditDetailsModal from './components/EditDetailsModal/EditDetailsModal';
import { useI18n } from '@shopify/react-i18n';
import { EditIcon } from '@shopify/polaris-icons';
import './PromotionDetails.scss';
import { CopyIcon } from 'core/components';

type PromotionDetailsProps = {
  promotionDetails: PromotionOverviewDetailsDto;
  triggerType?: TriggerTypeDto;
  status?: PromotionStatusDto;
  isPromotionEditable: boolean;
  refetchPromotionOverview: () => void;
};

export const PromotionDetails: React.FC<PromotionDetailsProps> = ({
  promotionDetails,
  triggerType,
  status,
  isPromotionEditable,
  refetchPromotionOverview,
}) => {
  const [i18n] = useI18n();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  return (
    <div className='PromotionDetails'>
      <Card>
        <BlockStack gap='400'>
          <InlineStack align='space-between' blockAlign='center'>
            <Text as='p' fontWeight='semibold'>
              {i18n.translate('Details')}
            </Text>
            <Button
              onClick={() => setIsEditModalOpen(true)}
              variant='tertiary'
              disabled={!isPromotionEditable}
              id='PromotionDetailsModalButton'
              icon={EditIcon}
            />
          </InlineStack>
          <BlockStack gap='300'>
            <InlineStack gap='100'>
              <Text as='p' fontWeight='medium'>
                {i18n.translate('Name')}
              </Text>
              <Text as='p'>{promotionDetails.name}</Text>
            </InlineStack>
            <div className='TokenHover'>
              <InlineStack gap='100'>
                <Text as='p' fontWeight='medium'>
                  {i18n.translate('Token')}
                </Text>
                <Text as='p'>{promotionDetails.token}</Text>
                <CopyIcon
                  copyText={promotionDetails.token}
                  tooltip={i18n.translate('CopyToken')}
                />
              </InlineStack>
            </div>
            {!!promotionDetails.tags?.length && (
              <InlineStack gap='200'>
                {promotionDetails.tags?.map((tag, index) => (
                  <Tag key={index}>{tag}</Tag>
                ))}
              </InlineStack>
            )}
          </BlockStack>
        </BlockStack>
      </Card>
      {isEditModalOpen && (
        <EditDetailsModal
          promotionDetails={promotionDetails}
          isOpen={isEditModalOpen}
          triggerType={triggerType}
          status={status}
          refetchPromotionOverview={refetchPromotionOverview}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}
    </div>
  );
};
