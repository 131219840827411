import React from 'react';
import { Box, IndexTable, SkeletonDisplayText, Text } from '@shopify/polaris';
import { PromotionActivityAnalyticsEntryDto } from 'core/api/adminAnalytics/adminAnalyticsApi';

type PromotionActivityRowProps = {
  index: number;
  data: PromotionActivityAnalyticsEntryDto;
  listWidth: number;
  isLoading: boolean;
};

export const PromotionActivityRow: React.FC<PromotionActivityRowProps> = ({
  index,
  data,
  listWidth,
  isLoading,
}) => {
  return (
    <IndexTable.Row id={data.id as string} position={index}>
      <IndexTable.Cell>
        <Box width={`${(listWidth / 100) * 50}px`}>
          {!isLoading ? (
            <Text as='p' truncate>
              {data.promotionName}
            </Text>
          ) : (
            <SkeletonDisplayText maxWidth='100%' />
          )}
        </Box>
      </IndexTable.Cell>
      <IndexTable.Cell className='SessionsColumn'>
        {!isLoading ? (
          <Text alignment='end' as='p'>
            {data.sessions}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='AddToCartColumn'>
        {!isLoading ? (
          <Text alignment='end' as='p'>
            {data.addToCart}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='CheckoutColumn'>
        {!isLoading ? (
          <Text alignment='end' as='p'>
            {data.checkoutInitiated}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='OrdersColumn'>
        {!isLoading ? (
          <Text alignment='end' as='p'>
            {data.orders}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
      <IndexTable.Cell className='RevenueColumn'>
        {!isLoading ? (
          <Text alignment='end' as='p'>
            ${data.revenue}
          </Text>
        ) : (
          <SkeletonDisplayText />
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  );
};
