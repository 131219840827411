import {
  AnalyticsQueryPeriodDto,
  useGetAnalyticsV6DashboardOverviewQuery,
} from 'core/api/adminAnalytics/adminAnalyticsApi';
import {
  InstallWidgetRequestDto,
  useGetSettingsV6DashboardQuery,
  useGetSettingsV6PlansCurrentQuery,
  usePutSettingsV6ThemesByThemeTypeWidgetInstallationInstallMutation,
} from 'core/api/adminSettings/adminSettingsApi';
import { ThemeTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import resolveEnvVar from 'env-var-resolver';
import { useCallback } from 'react';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';
export const useDashboard = (period: AnalyticsQueryPeriodDto) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const {
    data: dashBoardData,
    isLoading: dashBoardDataIsLoading,
    isFetching: dashBoardDataIsFetching,
    refetch,
  } = useGetSettingsV6DashboardQuery({
    'X-LimoniApps-AppName': APP_NAME,
    'X-LimoniApps-AppSecret': APP_PASSWORD,
  });

  const {
    data: currentPlanData,
    isFetching: currentPlanDataIsFetching,
    isLoading: currentPlanDataIsLoading,
  } = useGetSettingsV6PlansCurrentQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !dashBoardData?.setupStatus?.subscriptionPlan?.isCompleted,
    }
  );

  const {
    data: promotionInsightsData,
    isFetching: promotionInsightsDataIsFetching,
    isLoading: promotionInsightsDataIsLoading,
  } = useGetAnalyticsV6DashboardOverviewQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      period,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !dashBoardData?.arePromotionInsightsAvailable,
    }
  );

  const [
    turnWidgetOnManuallyMutation,
    {
      isLoading: turnWidgetOnManuallyIsLoading,
      isSuccess: turnWidgetOnManuallyIsSuccess,
    },
  ] = usePutSettingsV6ThemesByThemeTypeWidgetInstallationInstallMutation();

  const turnOnWidget = useCallback(
    async (data: InstallWidgetRequestDto) => {
      const response = await turnWidgetOnManuallyMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        themeType: ThemeTypeDtoEnum.CURRENT,
        body: data,
      }).unwrap();
      return response;
    },
    [turnWidgetOnManuallyMutation]
  );

  return {
    dashBoardData,
    dashBoardDataIsLoading,
    dashBoardDataIsFetching,
    currentPlanData,
    currentPlanDataIsFetching,
    currentPlanDataIsLoading,
    turnWidgetOnManuallyIsLoading,
    turnWidgetOnManuallyIsSuccess,
    promotionInsightsData,
    promotionInsightsDataIsFetching,
    promotionInsightsDataIsLoading,
    refetch,
    turnOnWidget,
  };
};
