import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import resolveEnvVar from 'env-var-resolver';

// initialize an empty api service that we'll inject endpoints into later as needed
export const emptySplitAdminWidgetsApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: resolveEnvVar('REACT_APP_WIDGETS_ADMIN_API_URL'),
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');
      const appName = resolveEnvVar('REACT_APP_APP_NAME');
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      // If we have an appName set in env, let's assume that we should be passing it.
      if (appName) {
        headers.set('x-limoniapps-appname', appName);
      }

      return headers;
    },
  }),
  reducerPath: 'adminWidgetsApi',
  endpoints: () => ({}),
});
