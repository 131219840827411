export enum RegionType {
  PROVINCE = 'PROVINCE',
  STATE = 'STATE',
  REGION = 'REGION',
  PREFECTURE = 'PREFECTURE',
  CANTON = 'CANTON',
  AUTONOMOUS_COMMUNITY = 'AUTONOMOUS_COMMUNITY',
  DEPARTMENT = 'DEPARTMENT',
  TERRITORY = 'TERRITORY',
  COUNTY = 'COUNTY',
}

export const countryRegionMap: { [key: string]: RegionType } = {
  US: RegionType.STATE,
  CA: RegionType.PROVINCE,
  GB: RegionType.COUNTY,
  DE: RegionType.STATE,
  FR: RegionType.REGION,
  IT: RegionType.REGION,
  ES: RegionType.AUTONOMOUS_COMMUNITY,
  AU: RegionType.STATE,
  IN: RegionType.STATE,
  RU: RegionType.REGION,
  CN: RegionType.PROVINCE,
  BR: RegionType.STATE,
  MX: RegionType.STATE,
  JP: RegionType.PREFECTURE,
  CH: RegionType.CANTON,
  // Add more countries here
};
