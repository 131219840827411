import React, { useMemo, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import {
  BlockStack,
  Card,
  InlineStack,
  Popover,
  Text,
  Button,
  ActionList,
  Icon,
} from '@shopify/polaris';
import { MenuHorizontalIcon, XIcon } from '@shopify/polaris-icons';
import { VideoCard } from './components/VideoCard/VideoCard';

type ExploreCardProps = {
  onDismiss: () => void;
};

export const ExploreCard: React.FC<ExploreCardProps> = (props) => {
  const { onDismiss } = props;

  const [i18n] = useI18n();

  const [popoverActive, setPopoverActive] = useState<boolean>(false);

  const cards = useMemo(
    () => [
      {
        title: i18n.translate('FirstCard.title'),
        description: i18n.translate('FirstCard.description'),
        btn: i18n.translate('FirstCard.btn'),
        videoUrl: '',
        link: 'http://www.google.com',
      },
      {
        title: i18n.translate('SecondCard.title'),
        description: i18n.translate('SecondCard.description'),
        btn: i18n.translate('SecondCard.btn'),
        videoUrl: '',
        link: 'http://www.google.com',
      },
      {
        title: i18n.translate('ThirdCard.title'),
        description: i18n.translate('ThirdCard.description'),
        btn: i18n.translate('ThirdCard.btn'),
        videoUrl: '',
        link: 'http://www.google.com',
      },
    ],
    [i18n]
  );

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <InlineStack align='space-between'>
          <BlockStack gap='100'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('title')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('description')}
            </Text>
          </BlockStack>
          <Popover
            active={popoverActive}
            onClose={() => setPopoverActive((prev) => !prev)}
            activator={
              <Button
                onClick={() => setPopoverActive((prev) => !prev)}
                variant='tertiary'
                icon={MenuHorizontalIcon}
              />
            }
          >
            <ActionList
              actionRole='menuitem'
              items={[
                {
                  content: i18n.translate('dismiss'),
                  onAction: onDismiss,
                  prefix: (
                    <div
                      style={{
                        height: '1rem',
                        width: '1rem',
                        paddingTop: '.05rem',
                      }}
                    >
                      <Icon tone='subdued' source={XIcon} />
                    </div>
                  ),
                },
              ]}
            />
          </Popover>
        </InlineStack>
        <div>
          {cards.map((card, idx) => (
            <VideoCard key={idx} cardData={card} />
          ))}
        </div>
      </BlockStack>
    </Card>
  );
};
