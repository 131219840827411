import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  useSetIndexFiltersMode,
  IndexTable,
  Card,
  Bleed,
  Divider,
  InlineStack,
  Text,
  Tooltip,
  Icon,
  Button,
  Link,
  BlockStack,
  IndexFilters,
  Pagination,
} from '@shopify/polaris';
import { InfoIcon, SearchResourceIcon } from '@shopify/polaris-icons';
import {
  IndexTableHeading,
  IndexTableSortDirection,
} from '@shopify/polaris/build/ts/src/components/IndexTable/IndexTable';
import { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import {
  OrderAnalyticsQueryDto,
  PagedResponseDtoOrderAnalyticsEntryDtoRead,
  PagedResponseDtoPromotionActivityAnalyticsEntryDto,
  PagedResponseDtoPromotionCodeAnalyticsEntryDto,
  PromotionActivityAnalyticsQueryDto,
  PromotionCodeAnalyticsQueryDto,
} from 'core/api/adminAnalytics/adminAnalyticsApi';
import {
  AnalyticsQueryPeriodDtoEnum,
  OrderAnalyticsSortFieldDtoEnum,
  PromotionActivityAnalyticsSortFieldDtoEnum,
  PromotionCodeAnalyticsSortFieldDtoEnum,
  SortDirectionDtoEnum,
} from 'core/api/adminAnalytics/adminAnalyticsEnums';
import { useConfigureAnalytics } from 'features/analytics/hooks/useConfigureAnalytics';
import { PromotionActivityRow } from './components/PromotionActivityRow/PromotionActivityRow';
import { DetailedOrderRow } from './components/DetailedOrderRow/DetailedOrderRow';
import { DetailedCodeRow } from './components/DetailedCodeRow/DetailedCodeRow';
import { useI18n } from '@shopify/react-i18n';
import { debounce, isEmpty } from 'lodash';
import { ReportListTypeEnum } from 'pages';
import { useNavigate } from 'react-router-dom';
import {
  sortColumnOptions,
  sortPopoverOptions,
} from 'features/analytics/utils/utils';
import {
  ExportFileModal,
  ExportFileModalTypeEnum,
  Loader,
} from 'core/components';
import './AnalyticsReportList.scss';
import useExportListReports from 'features/analytics/utils/useExportListReports';
import { useAppSelector } from 'core/hooks';

type AnalyticsReportListStateDto =
  | PagedResponseDtoPromotionActivityAnalyticsEntryDto
  | PagedResponseDtoOrderAnalyticsEntryDtoRead
  | PagedResponseDtoPromotionCodeAnalyticsEntryDto;

export type AnalyticsReportListRequestDto =
  | PromotionActivityAnalyticsQueryDto
  | PromotionCodeAnalyticsQueryDto
  | OrderAnalyticsQueryDto;

type AnalyticsReportListProps = {
  listType: ReportListTypeEnum;
  simplified?: boolean;
  tooltip?: string;
};
export type AnalyticsReportListRef = {
  refresh: (period?: AnalyticsQueryPeriodDtoEnum) => void;
  export: () => void;
};

export const AnalyticsReportList = forwardRef<
  AnalyticsReportListRef,
  AnalyticsReportListProps
>((props, ref) => {
  const { listType, simplified, tooltip } = props;
  const { exportReportIsFetching, exportReportList } = useExportListReports();
  const { analyticsPeriod } = useAppSelector((store) => store.settings);
  const [i18n] = useI18n();
  const navigate = useNavigate();
  const { reportListIsLoading, reportListData, getReportList } =
    useConfigureAnalytics(listType as any);
  const { mode, setMode } = useSetIndexFiltersMode();

  const listRef = useRef<any | null>(null);
  const itemsPerPage = useMemo(() => (simplified ? 5 : 20), [simplified]);
  const [listWidth, setListWidth] = useState(0);
  const [sortSelected, setSortSelected] = useState<string[]>([
    sortPopoverOptions(i18n, listType)?.[0].value || '',
  ]);
  const [listRequestSetup, setListRequestSetup] =
    useState<AnalyticsReportListRequestDto>({
      search: '',
      page: 1,
      period: analyticsPeriod,
      itemsPerPage: itemsPerPage,
      sortDirection: SortDirectionDtoEnum.ASC,
      sortBy:
        listType === ReportListTypeEnum.PROMOTION_ACTIVITY
          ? PromotionActivityAnalyticsSortFieldDtoEnum.PROMOTION_NAME
          : listType === ReportListTypeEnum.DETAILED_CODE
          ? PromotionCodeAnalyticsSortFieldDtoEnum.PROMOTION_CODE
          : OrderAnalyticsSortFieldDtoEnum.ORDER,
    });
  const [listState, setListState] = useState<AnalyticsReportListStateDto>();
  const [columnSortDirection, setColumnSortDirection] =
    useState<IndexTableSortDirection>('descending');
  const [currentSortIndex, setCurrentSortIndex] = useState<number>();
  const [querySearchValue, setQuerySearchValue] = useState<string>('');
  const [exportModal, setExportModal] = useState<boolean>(false);

  const exportLink = useMemo(() => {
    return listType === ReportListTypeEnum.DETAILED_CODE
      ? 'promotionCode/export'
      : listType === ReportListTypeEnum.DETAILED_ORDER
      ? 'order/export'
      : 'promotionActivity/export';
  }, [listType]);

  const itemsIds = useMemo(
    () =>
      listState?.items
        ?.map((item: any) => {
          return {
            id: item.id || '1',
          };
        })
        .slice(0, itemsPerPage),
    [listState]
  );

  const headingVariant: NonEmptyArray<IndexTableHeading> = useMemo(() => {
    switch (listType) {
      case ReportListTypeEnum.PROMOTION_ACTIVITY:
        return [
          { title: i18n.translate('PromotionName') },
          { title: i18n.translate('Sessions'), alignment: 'end' },
          { title: i18n.translate('AddedToCart'), alignment: 'end' },
          { title: i18n.translate('ReachedCheckout'), alignment: 'end' },
          { title: i18n.translate('Orders'), alignment: 'end' },
          { title: i18n.translate('Revenue'), alignment: 'end' },
        ];
      case ReportListTypeEnum.DETAILED_CODE:
        return [
          { title: i18n.translate('PromotionCode') },
          { title: i18n.translate('PromotionsApplied') },
          { title: i18n.translate('Status') },
          { title: i18n.translate('Usage'), alignment: 'end' },
          { title: i18n.translate('Total'), alignment: 'end' },
        ];
      case ReportListTypeEnum.DETAILED_ORDER:
        return [
          { title: i18n.translate('Order') },
          { title: i18n.translate('Date') },
          { title: i18n.translate('Customer') },
          { title: i18n.translate('OrderValue') },
          { title: i18n.translate('PromotionCodes') },
          { title: i18n.translate('PromotionsApplied') },
        ];
    }
  }, [i18n]);

  const rowVariants = useCallback(
    (id: string, index: number, reportListIsLoading: boolean) => {
      switch (listType) {
        case ReportListTypeEnum.PROMOTION_ACTIVITY:
          return (
            <PromotionActivityRow
              key={id}
              index={index}
              isLoading={reportListIsLoading}
              data={
                (
                  listState as PagedResponseDtoPromotionActivityAnalyticsEntryDto
                )?.items?.find((item) => item.id === id) || {}
              }
              listWidth={listWidth}
            />
          );
        case ReportListTypeEnum.DETAILED_ORDER:
          return (
            <DetailedOrderRow
              key={id}
              index={index}
              data={
                (
                  listState as PagedResponseDtoOrderAnalyticsEntryDtoRead
                )?.items?.find((item) => item.id === id) || {}
              }
              isLoading={reportListIsLoading}
              listWidth={listWidth}
            />
          );
        case ReportListTypeEnum.DETAILED_CODE:
          return (
            <DetailedCodeRow
              key={id}
              index={index}
              data={
                (
                  listState as PagedResponseDtoPromotionCodeAnalyticsEntryDto
                )?.items?.find((item) => item.id === id) || {}
              }
              isLoading={reportListIsLoading}
              listWidth={listWidth}
            />
          );
      }
    },
    [listState, listType, listWidth]
  );

  const showSkeleton = useMemo(
    () => !itemsIds && !listState,
    [listState, itemsIds]
  );

  const rowMarkup = useMemo(() => {
    const generateSkeletonIds = (n: number) => {
      return Array.from({ length: n }, (_, i) => ({ id: i.toString() }));
    };
    return (!showSkeleton ? itemsIds : generateSkeletonIds(itemsPerPage))?.map(
      (item, index) => {
        return rowVariants(item.id, index, showSkeleton);
      }
    );
  }, [itemsIds, rowVariants, showSkeleton, itemsPerPage]);

  const isListEmpty = useMemo(
    () =>
      !isEmpty(listState) && !listState?.totalItems && !listRequestSetup.search,
    [listState, listRequestSetup.search]
  );

  const hasNextPage = useMemo(
    () =>
      listState?.totalItems && listRequestSetup.page
        ? listState.totalItems / listRequestSetup.page > 10
        : false,
    [listState?.totalItems, listRequestSetup.page]
  );

  const filteredAmount = useMemo(() => {
    if (listRequestSetup.search && itemsIds?.length) {
      return itemsIds.length;
    }

    return listState?.totalItems || 0;
  }, [listRequestSetup.search, itemsIds, listState?.totalItems]);

  const handleExportModal = useCallback(
    () => setExportModal((prev) => !prev),
    []
  );

  const handleExportList = useCallback(
    (exportType: ExportFileModalTypeEnum) => {
      exportReportList(exportLink, listRequestSetup, exportType).then(
        () => exportModal && handleExportModal
      );
    },
    [exportLink, listRequestSetup, exportModal, handleExportModal]
  );

  const handleDebounceQueryChange = useCallback(
    (value: string) => {
      setListRequestSetup((prev) => ({
        ...prev,
        search: value,
        page: 1,
      }));
    },
    [setListRequestSetup]
  );

  const debounceSearchQuery = useCallback(
    debounce(handleDebounceQueryChange, 500),
    []
  );
  const handleSearchQueryChange = useCallback(
    (value: string) => {
      if (value !== listRequestSetup.search) {
        setQuerySearchValue(value);
        debounceSearchQuery(value);
      }
    },
    [listRequestSetup.search]
  );

  const handleDebounceSortQuery = useCallback(
    (value: string[]) => {
      const isASC = value[0].includes('asc');
      const sortBy = isASC ? value[0].slice(0, -4) : value[0].slice(0, -5);
      setListRequestSetup((prev) => ({
        ...prev,
        sortBy: sortBy as any,
        sortDirection: isASC
          ? SortDirectionDtoEnum.ASC
          : SortDirectionDtoEnum.DESC,
      }));
    },
    [setListRequestSetup]
  );

  const debounceSortQuery = useCallback(
    debounce(handleDebounceSortQuery, 800),
    []
  );

  const handleSortQuery = useCallback((value: string[]) => {
    setSortSelected(value);
    debounceSortQuery(value);
  }, []);

  const onPrevPage = useCallback(() => {
    setListState(undefined);
    setListRequestSetup((prev) => ({
      ...prev,
      page: prev.page === 1 ? 1 : (prev.page as number) - 1,
    }));
  }, [setListRequestSetup]);

  const onNextPage = useCallback(() => {
    setListState(undefined);
    setListRequestSetup((prev) => ({
      ...prev,
      page: (prev.page as number) + 1,
    }));
  }, [setListRequestSetup]);

  const handleSortColumn = useCallback(
    (index: number) => {
      const changedDirection =
        columnSortDirection === 'ascending' ? 'descending' : 'ascending';
      let sortDirection = columnSortDirection;

      if (currentSortIndex === index) {
        setColumnSortDirection(changedDirection);
        sortDirection = changedDirection;
      }

      setCurrentSortIndex(index);
      const option = sortColumnOptions(index, sortDirection, listType);
      setSortSelected([option as string]);

      const isASC = option?.includes('asc');
      const sortBy = isASC ? option?.slice(0, -4) : option?.slice(0, -5);
      setListRequestSetup((prev) => ({
        ...prev,
        sortBy: sortBy as any,
        sortDirection: isASC
          ? SortDirectionDtoEnum.ASC
          : SortDirectionDtoEnum.DESC,
      }));
    },
    [setListRequestSetup, columnSortDirection, currentSortIndex, listType]
  );

  useImperativeHandle(ref, () => ({
    refresh: () => {
      getReportList(listRequestSetup as any);
    },
    export: handleExportModal,
  }));

  useEffect(() => {
    getReportList(listRequestSetup as any);
  }, [listRequestSetup]);

  useEffect(() => {
    if (analyticsPeriod !== listRequestSetup.period) {
      setListRequestSetup((prev) => ({ ...prev, period: analyticsPeriod }));
    }
  }, [analyticsPeriod, listRequestSetup.period]);

  useEffect(() => {
    if (reportListData) {
      setListState(reportListData);
    }
  }, [reportListData]);

  useEffect(() => {
    const updateWidth = () => {
      setListWidth(listRef.current?.clientWidth || 0);
    };
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <div ref={listRef} className='AnalyticsReportList'>
      <Card>
        <BlockStack gap='400'>
          {simplified && (
            <Box paddingBlockEnd='400' paddingBlockStart='100'>
              <InlineStack align='space-between' blockAlign='center'>
                <InlineStack gap='100'>
                  <Text as='p' fontWeight='medium'>
                    {i18n.translate(`${listType}_TITLE`)}
                  </Text>
                  {tooltip && (
                    <Tooltip content={tooltip}>
                      <Icon tone='base' source={InfoIcon} />
                    </Tooltip>
                  )}
                </InlineStack>
                <InlineStack blockAlign='center' gap='200'>
                  {reportListIsLoading && <Loader size='small' />}
                  <Button
                    onClick={() => navigate(i18n.translate(`${listType}_LINK`))}
                    icon={SearchResourceIcon}
                    variant='tertiary'
                  />
                </InlineStack>
              </InlineStack>
            </Box>
          )}

          {isListEmpty ? (
            <Box paddingBlockEnd='1000' paddingBlockStart='800'>
              <Text alignment='center' as='p' tone='subdued'>
                {i18n.translate('EmptyState')}
              </Text>
            </Box>
          ) : (
            <Bleed marginInline='400' marginBlock='400'>
              <Divider borderColor='border-brand' />
              {!simplified && (
                <IndexFilters
                  sortOptions={sortPopoverOptions(i18n, listType)}
                  sortSelected={sortSelected}
                  queryValue={querySearchValue}
                  queryPlaceholder={i18n.translate(`SearchingInAll`)}
                  onQueryChange={handleSearchQueryChange}
                  onQueryClear={() => handleSearchQueryChange('')}
                  onSort={(value) => handleSortQuery(value)}
                  cancelAction={{
                    onAction: () => handleSearchQueryChange(''),
                  }}
                  loading={reportListIsLoading}
                  tabs={[{ content: i18n.translate('All'), id: '1' }]}
                  selected={0}
                  canCreateNewView={false}
                  filters={[]}
                  appliedFilters={[]}
                  onClearAll={() => null}
                  mode={mode}
                  setMode={setMode}
                  hideFilters
                  filteringAccessibilityTooltip={i18n.translate(`SearchF`)}
                />
              )}

              <IndexTable
                itemCount={
                  (!reportListIsLoading ? itemsIds?.length : itemsPerPage) || 0
                }
                headings={headingVariant}
                selectable={false}
                defaultSortDirection={columnSortDirection}
                onSort={(index) => handleSortColumn(index)}
                sortable={[true, true, true, true, true, true]}
              >
                {rowMarkup}
              </IndexTable>
              <Box
                borderBlockStartWidth='025'
                borderColor='border-brand'
                background='bg-surface-secondary'
                padding='300'
              >
                <InlineStack
                  align={simplified ? 'center' : 'space-between'}
                  blockAlign='center'
                >
                  {!showSkeleton ? (
                    <Text as='p' tone='subdued'>
                      {i18n.translate('ShowXofY', {
                        from: ((listRequestSetup.page || 1) - 1) * 20 + 1,
                        to: Math.min(
                          (listRequestSetup.page || 1) * 20,
                          listState?.totalItems as number
                        ),
                        total: listState?.totalItems,
                        viewAll: simplified ? (
                          <Link
                            removeUnderline
                            onClick={() =>
                              navigate(i18n.translate(`${listType}_LINK`))
                            }
                          >
                            {i18n.translate('ViewAll')}
                          </Link>
                        ) : null,
                      })}
                    </Text>
                  ) : (
                    <Box>...</Box>
                  )}
                  {!simplified && (
                    <Pagination
                      onPrevious={onPrevPage}
                      onNext={onNextPage}
                      hasNext={hasNextPage && !showSkeleton}
                      hasPrevious={
                        (listRequestSetup.page as number) > 1 && !showSkeleton
                      }
                    />
                  )}
                </InlineStack>
              </Box>
            </Bleed>
          )}
        </BlockStack>
      </Card>
      {exportModal && (
        <ExportFileModal
          isOpen={exportModal}
          i18n={i18n}
          filteredAmount={filteredAmount}
          exportLoading={exportReportIsFetching}
          onClose={handleExportModal}
          handleExportCodes={handleExportList}
        />
      )}
    </div>
  );
});
AnalyticsReportList.displayName = 'AnalyticsReportList';
