import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferBogoSpecificationDto,
  OfferBogoTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import './BogoOfferSection.scss';
import {
  Button,
  ButtonGroup,
  Icon,
  Tooltip,
  Card,
  BlockStack,
  Text,
  InlineStack,
  Page,
  Box,
  RadioButton,
  Banner,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferBogoCustomRuleTypeDtoEnum,
  OfferBogoTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { usePrevious } from 'core/hooks/usePrevious';
import {
  Controller,
  ControllerRenderProps,
  useController,
  useForm,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import NumberFormat from 'react-number-format';
import {
  QuestionCircleIcon,
  CaretDownIcon,
  CaretUpIcon,
  DiscountIcon,
} from '@shopify/polaris-icons';
import { useAppDispatch } from 'core/hooks';
import { setCurrentConfigPage } from 'core/store/offersWizardSlice';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
import { ChartuUnitOfMeasurementEnum } from 'pages';
import { getCurrencySymbol, parseCurrencyString } from 'core/utils';

export type BogoOfferSectionProps = {
  specification: OfferBogoSpecificationDto;
  savedData?: OfferBogoSpecificationDto;
  onFormValidityChange(formIsValid: boolean): void;
  onSpecificationUpdate: (specification: OfferBogoSpecificationDto) => void;
  setConfigureComponent: (data: boolean) => void;
  configureComponent: boolean;
};

type BogoTypesContentItemValueType = 'GENERAL' | 'CROSSED_OUT' | 'INFO';

type BogoTypesContentItemValuesProps = {
  type: BogoTypesContentItemValueType;
  value: string;
};

type BogoTypesContentItemsProps = {
  itemName: string;
  itemValues: BogoTypesContentItemValuesProps[];
};

type BogoTypesContentProps = {
  bogoType: OfferBogoTypeDto;
  bogoTitle: string;
  bogoDescription: string;
  items?: BogoTypesContentItemsProps[];
};

type FormFields = {
  maximumItemsEnable: boolean;
  maximumItemsValue: number;
  customOffValue: number;
  customYvalue: number;
  customXvalue: number;
};

const initialSpecificationValues: OfferBogoSpecificationDto = {
  customRule: {
    amountOfProducts: 1,
    fixedAmount: 10,
    percentage: 50,
    requiredAmount: 3,
    type: 'PERCENTAGE',
  },
  limit: {
    enabled: false,
    value: 1,
  },
  type: 'ONE_PLUS_ONE',
};

export const BogoOfferSection: React.FC<BogoOfferSectionProps> = (props) => {
  const {
    specification,
    onFormValidityChange,
    onSpecificationUpdate,
    savedData,
    configureComponent,
    setConfigureComponent,
  } = props;

  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const { formatValue } = useNumericFormatter();

  const [currentSpecification, setCurrentSpecification] =
    useState<OfferBogoSpecificationDto>(
      specification || initialSpecificationValues
    );

  const prevSpecification = usePrevious(currentSpecification);

  const moreThanY: number = useMemo(
    () => currentSpecification.customRule?.amountOfProducts || 0,
    [currentSpecification.customRule?.amountOfProducts]
  );

  const form = yup.object({
    maximumItemsEnable: yup.boolean(),
    maximumItemsValue: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable()
      .when('maximumItemsEnable', {
        is: (value: boolean) => value,
        then: yup
          .number()
          .typeError(i18n.translate('BogoOffer.MaximumRequiredError'))
          .min(1, i18n.translate('BogoOffer.MaximumMinError'))
          .integer()
          .required()
          .test(
            'is-remainder-equal-to-zero',
            `${i18n.translate('BogoOffer.RemainderCheck')} ${
              currentSpecification?.customRule?.amountOfProducts
            }`,
            (value) =>
              !!(
                value &&
                value %
                  (currentSpecification?.customRule?.amountOfProducts || 1) ===
                  0
              )
          ),
      }),
    customOffValue: yup.number().when([], {
      is: () => currentSpecification.type === OfferBogoTypeDtoEnum.CUSTOM,
      then: yup
        .number()
        .transform((value, originalValue) => parseCurrencyString(originalValue))
        .when([], {
          is: () =>
            currentSpecification.customRule?.type ===
            OfferBogoCustomRuleTypeDtoEnum.FIXED_AMOUNT,
          then: yup
            .number()
            .typeError(
              i18n.translate('BogoOffer.CustomRuleFixedAmountRequired')
            )
            .min(0.01, i18n.translate('BogoOffer.CustomRuleFixedAmountMin'))
            .required(),
          otherwise: yup
            .number()
            .typeError(i18n.translate('BogoOffer.CustomRulePercentageRequired'))
            .min(1, i18n.translate('BogoOffer.CustomRulePercentageMin'))
            .max(100, i18n.translate('BogoOffer.CustomRulePercentageMax'))
            .integer()
            .required(),
        }),
      otherwise: yup.number().notRequired(),
    }),
    customYvalue: yup.number().when([], {
      is: () => currentSpecification.type === OfferBogoTypeDtoEnum.CUSTOM,
      then: yup
        .number()
        .typeError(i18n.translate('BogoOffer.CustomRuleYrequired'))
        .min(1, i18n.translate('BogoOffer.CustomRuleYMin'))
        .integer()
        .required(),
      otherwise: yup.number().notRequired(),
    }),
    customXvalue: yup.number().when([], {
      is: () => currentSpecification.type === OfferBogoTypeDtoEnum.CUSTOM,
      then: yup
        .number()
        .typeError(i18n.translate('BogoOffer.CustomRuleXrequired'))
        .min(1, i18n.translate('BogoOffer.CustomRuleXMin'))
        .integer()
        .moreThan(moreThanY, i18n.translate('BogoOffer.CustomRuleXmoreThan'))
        .required(),
      otherwise: yup.number().notRequired(),
    }),
  });

  const initialCustomOffValue: number = useMemo(
    () =>
      currentSpecification.customRule?.type ===
      OfferBogoCustomRuleTypeDtoEnum.FIXED_AMOUNT
        ? (currentSpecification.customRule.fixedAmount as number)
        : (currentSpecification.customRule?.percentage as number),
    [currentSpecification.customRule?.type]
  );

  const {
    control,
    formState,
    watch,
    trigger,
    resetField,
    reset,
    getValues,
    setValue,
  } = useForm<FormFields>({
    defaultValues: {
      maximumItemsEnable: currentSpecification.limit?.enabled,
      maximumItemsValue: currentSpecification.limit?.value,
      customOffValue: initialCustomOffValue || undefined,
      customYvalue:
        currentSpecification.customRule?.amountOfProducts || undefined,
      customXvalue:
        currentSpecification.customRule?.requiredAmount || undefined,
    },
    mode: 'onChange',
    resolver: yupResolver(form),
  });

  const isFixedAmountType: boolean = useMemo(
    () =>
      currentSpecification.customRule?.type ===
      OfferBogoCustomRuleTypeDtoEnum.FIXED_AMOUNT,
    [currentSpecification.customRule?.type]
  );
  const conditionalInputName: string = useMemo(
    () =>
      currentSpecification.customRule?.type ===
      OfferBogoCustomRuleTypeDtoEnum.FIXED_AMOUNT
        ? 'fixedAmount'
        : 'percentage',
    [currentSpecification.customRule?.type]
  );

  const bogoTypesContent: BogoTypesContentProps[] = useMemo(
    () => [
      {
        bogoType: OfferBogoTypeDtoEnum.ONE_PLUS_ONE,
        bogoTitle: i18n.translate('BogoOffer.BogoOnePlusOne.Title'),
        bogoDescription: i18n.translate('BogoOffer.BogoOnePlusOne.Description'),
        items: [
          {
            itemName: i18n.translate('BogoOffer.GrayBoxItems.ItemName1'),
            itemValues: [
              {
                type: 'GENERAL',
                value: '$100.00',
              },
            ],
          },
          {
            itemName: i18n.translate('BogoOffer.GrayBoxItems.ItemName2'),
            itemValues: [
              {
                type: 'GENERAL',
                value: i18n.translate('BogoOffer.GrayBoxItems.Free'),
              },
              {
                type: 'CROSSED_OUT',
                value: '$50.00',
              },
            ],
          },
        ],
      },
      {
        bogoType: OfferBogoTypeDtoEnum.ONE_PLUS_HALF,
        bogoTitle: i18n.translate('BogoOffer.BogoOnePlusHalf.Title'),
        bogoDescription: i18n.translate(
          'BogoOffer.BogoOnePlusHalf.Description'
        ),
        items: [
          {
            itemName: i18n.translate('BogoOffer.GrayBoxItems.ItemName1'),
            itemValues: [
              {
                type: 'GENERAL',
                value: '$100.00',
              },
            ],
          },
          {
            itemName: i18n.translate('BogoOffer.GrayBoxItems.ItemName2'),
            itemValues: [
              {
                type: 'INFO',
                value: '$50.00',
              },
              {
                type: 'GENERAL',
                value: '$25.00',
              },
              {
                type: 'GENERAL',
                value: '-50%',
              },
            ],
          },
        ],
      },
      {
        bogoType: OfferBogoTypeDtoEnum.THREE_FOR_TWO,
        bogoTitle: i18n.translate('BogoOffer.BogoThreeForTwo.Title'),
        bogoDescription: i18n.translate(
          'BogoOffer.BogoThreeForTwo.Description'
        ),
        items: [
          {
            itemName: i18n.translate('BogoOffer.GrayBoxItems.ItemName1'),
            itemValues: [
              {
                type: 'GENERAL',
                value: '$100.00',
              },
            ],
          },
          {
            itemName: i18n.translate('BogoOffer.GrayBoxItems.ItemName2'),
            itemValues: [
              {
                type: 'GENERAL',
                value: '$80.00',
              },
            ],
          },
          {
            itemName: i18n.translate('BogoOffer.GrayBoxItems.ItemName3'),
            itemValues: [
              {
                type: 'INFO',
                value: '$50.00',
              },
              {
                type: 'GENERAL',
                value: '$25.00',
              },
              {
                type: 'GENERAL',
                value: '-50%',
              },
            ],
          },
        ],
      },
      {
        bogoType: OfferBogoTypeDtoEnum.CUSTOM,
        bogoTitle: i18n.translate('BogoOffer.BogoCustom.Title'),
        bogoDescription: i18n.translate('BogoOffer.BogoCustom.Description'),
      },
    ],
    [i18n]
  );

  const customRuleValidationMessages = useMemo(() => {
    const errors = cloneDeep(formState.errors);
    const fieldsNeeded = ['customOffValue', 'customXvalue', 'customYvalue'];
    Object.keys(errors).forEach((item) => {
      if (fieldsNeeded.indexOf(item) === -1) {
        delete errors[item as keyof typeof errors];
      }
    });

    return Object.values(errors).map((error) => error?.message)?.length ? (
      <div className='BogoCustomErrorBoxWrapper'>
        {Object.values(errors)
          .map((error) => error?.message)
          .map((errorMessage, idx) => (
            <Banner tone='critical' key={idx}>
              {errorMessage}
            </Banner>
          ))}
      </div>
    ) : null;
  }, [formState]);

  const changeValue = useCallback(
    (field: ControllerRenderProps<FormFields>, value: string) => {
      if (field.value !== parseFloat(value)) {
        field.onChange(parseFloat(value));
      }
    },
    []
  );

  const updateCurrentSpecification = useCallback(
    (
      field: keyof OfferBogoSpecificationDto,
      data: OfferBogoSpecificationDto[keyof OfferBogoSpecificationDto]
    ) => {
      setCurrentSpecification((prevState: OfferBogoSpecificationDto) => ({
        ...prevState,
        [field]: data,
      }));
    },
    [setCurrentSpecification]
  );

  useEffect(() => {
    if (
      !isEmpty(currentSpecification) &&
      !isEmpty(prevSpecification) &&
      !isEqual(prevSpecification, currentSpecification)
    ) {
      onSpecificationUpdate(currentSpecification);
    }
  }, [currentSpecification, prevSpecification]);

  useEffect(() => {
    trigger('maximumItemsValue');
  }, [
    currentSpecification.customRule?.amountOfProducts,
    currentSpecification.limit?.enabled,
    trigger,
  ]);

  useEffect(() => {
    trigger('customXvalue');
  }, [currentSpecification.customRule?.amountOfProducts, trigger]);

  useEffect(() => {
    if (currentSpecification.type !== OfferBogoTypeDtoEnum.CUSTOM) {
      setCurrentSpecification((prevState: OfferBogoSpecificationDto) => ({
        ...prevState,
        customRule: savedData?.customRule,
      }));
      reset();
    }

    trigger('customOffValue');
    trigger('customYvalue');
    trigger('customXvalue');
  }, [currentSpecification.type]);

  useEffect(() => {
    resetField('customOffValue', {
      defaultValue:
        (currentSpecification.customRule?.[
          conditionalInputName as keyof typeof currentSpecification.customRule
        ] as number) || 0,
    });
    trigger('customOffValue');
    trigger('customYvalue');
    trigger('customXvalue');
  }, [conditionalInputName]);

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        setCurrentSpecification((prevState: OfferBogoSpecificationDto) => ({
          ...prevState,
          limit: {
            enabled: value.maximumItemsEnable,
            value: value.maximumItemsValue,
          },
          customRule: {
            ...prevState.customRule,

            [conditionalInputName]: parseCurrencyString(value.customOffValue),
            amountOfProducts: value.customYvalue,
            requiredAmount: value.customXvalue,
          },
        }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, conditionalInputName]);

  useEffect(() => {
    onFormValidityChange(formState.isValid);
  }, [formState.isValid]);

  const incrementDecrementValue = useCallback(
    (
      field: ControllerRenderProps<FormFields>,
      type: 'increment' | 'decrement'
    ) => {
      const currentValue =
        typeof getValues(field.name) === 'number' ? getValues(field.name) : 1;
      if (type === 'increment' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue + 1}`);
      } else if (type === 'decrement' && typeof currentValue === 'number') {
        changeValue(field, `${currentValue - 1}`);
      }
    },
    [setValue, getValues]
  );

  const incrementDecrementButtons = useCallback(
    (field: ControllerRenderProps<FormFields>) => {
      return (
        <div className='Polaris-TextField__Spinner' aria-hidden='true'>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'increment');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretUpIcon} />
              </span>
            </div>
          </div>
          <div
            role='button'
            className='Polaris-TextField__Segment'
            onClick={() => {
              incrementDecrementValue(field, 'decrement');
            }}
          >
            <div className='Polaris-TextField__SpinnerIcon'>
              <span className='Polaris-Icon'>
                <Icon source={CaretDownIcon} />
              </span>
            </div>
          </div>
        </div>
      );
    },
    [incrementDecrementValue]
  );

  const { field: customOffValueField } = useController({
    name: 'customOffValue',
    control,
  });
  const { field: customYvalueField } = useController({
    name: 'customYvalue',
    control,
  });
  const { field: customXvalueField } = useController({
    name: 'customXvalue',
    control,
  });

  const configuredDiscount = useMemo(() => {
    const { amountOfProducts, fixedAmount, percentage, requiredAmount, type } =
      specification.customRule || {};

    switch (specification.type) {
      case OfferBogoTypeDtoEnum.CUSTOM:
        return i18n.translate(`BogoOffer.${specification.type}`, {
          amount:
            type === OfferBogoCustomRuleTypeDtoEnum.FIXED_AMOUNT
              ? formatValue(fixedAmount, ChartuUnitOfMeasurementEnum.CURRENCY)
              : formatValue(percentage, ChartuUnitOfMeasurementEnum.PERCENTAGE),
          products: amountOfProducts,
          items: requiredAmount,
        });
      case null:
      case undefined:
        return '';
      default:
        return i18n.translate(`BogoOffer.${specification.type}`);
    }
  }, [specification, i18n]);

  useEffect(() => {
    configureComponent && dispatch(setCurrentConfigPage('specification'));
    return () => {
      dispatch(setCurrentConfigPage(null));
    };
  }, [configureComponent]);

  return (
    <>
      {!configureComponent ? (
        <Card roundedAbove='sm' padding='400'>
          <BlockStack gap='400'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate(`BogoOffer.Title`)}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate(`BogoOffer.Subtitle`)}
              </Text>
            </BlockStack>
            <Card roundedAbove='sm' padding='400'>
              <InlineStack
                align='space-between'
                wrap={false}
                blockAlign='center'
              >
                {configuredDiscount ? (
                  <InlineStack wrap={false} gap='200' blockAlign='center'>
                    <div style={{ width: 20, height: 20 }}>
                      <Icon source={DiscountIcon} tone='base' />
                    </div>
                    {configuredDiscount}
                  </InlineStack>
                ) : (
                  <Text as='p' tone='subdued'>
                    {i18n.translate('BogoOffer.NoDiscountConfigured')}
                  </Text>
                )}
                <BlockStack align='center'>
                  <Button
                    variant='plain'
                    onClick={() => setConfigureComponent(true)}
                  >
                    {i18n.translate('BogoOffer.Configure')}
                  </Button>
                </BlockStack>
              </InlineStack>
            </Card>
          </BlockStack>
        </Card>
      ) : (
        <Page
          backAction={{
            content: 'Cross sell',
            onAction: () => setConfigureComponent(false),
          }}
          title={i18n.translate('BogoOffer.ConfigureTitle')}
        >
          <Card>
            <BlockStack gap='400'>
              {bogoTypesContent?.map((bogo: BogoTypesContentProps) => (
                <Box
                  padding='400'
                  borderColor={
                    currentSpecification.type === bogo.bogoType
                      ? 'border-inverse'
                      : 'border-hover'
                  }
                  borderRadius='400'
                  borderWidth='025'
                  key={bogo.bogoType}
                  width='100%'
                >
                  <InlineStack gap='600' wrap={false}>
                    <Box width='70%'>
                      <RadioButton
                        label={
                          <Text as='p' fontWeight='medium'>
                            {bogo.bogoTitle}
                          </Text>
                        }
                        helpText={bogo.bogoDescription}
                        value={bogo.bogoType as string}
                        checked={currentSpecification.type === bogo.bogoType}
                        onChange={() =>
                          updateCurrentSpecification('type', bogo.bogoType)
                        }
                      />
                    </Box>
                    {bogo.bogoType !== OfferBogoTypeDtoEnum.CUSTOM && (
                      <Box
                        width='30%'
                        background='bg-surface-secondary'
                        borderRadius='400'
                        borderWidth='025'
                        borderColor='border-hover'
                        padding='300'
                      >
                        {bogo?.items?.map((item) => (
                          <InlineStack
                            key={item.itemName}
                            align='space-between'
                          >
                            <Text as='p'>{item.itemName}</Text>
                            <InlineStack gap='100'>
                              {item.itemValues.map((item, idx) => (
                                <Text
                                  as='p'
                                  key={idx}
                                  textDecorationLine={
                                    item.type !== 'GENERAL'
                                      ? 'line-through'
                                      : undefined
                                  }
                                  tone={
                                    item.type === 'INFO' ? 'subdued' : undefined
                                  }
                                >
                                  {item.value}
                                </Text>
                              ))}
                            </InlineStack>
                          </InlineStack>
                        ))}
                      </Box>
                    )}
                  </InlineStack>
                  {bogo.bogoType === OfferBogoTypeDtoEnum.CUSTOM && (
                    <Box
                      background='bg-surface-secondary'
                      borderRadius='200'
                      padding='400'
                    >
                      <div
                        onClickCapture={() =>
                          currentSpecification.type !==
                            OfferBogoTypeDtoEnum.CUSTOM &&
                          updateCurrentSpecification(
                            'type',
                            OfferBogoTypeDtoEnum.CUSTOM
                          )
                        }
                      >
                        <BlockStack gap='300'>
                          <InlineStack blockAlign='center' gap='200'>
                            <Text as='p'>
                              {i18n.translate('BogoOffer.Offer')}
                            </Text>
                            <ButtonGroup variant='segmented'>
                              {Object.keys(OfferBogoCustomRuleTypeDtoEnum).map(
                                (key: string) => (
                                  <Button
                                    key={key}
                                    pressed={
                                      currentSpecification.customRule?.type ===
                                      key
                                    }
                                    onClick={() => {
                                      if (
                                        currentSpecification.customRule
                                          ?.type !== key
                                      ) {
                                        setCurrentSpecification(
                                          (
                                            prevState: OfferBogoSpecificationDto
                                          ) => ({
                                            ...prevState,
                                            customRule: {
                                              ...prevState.customRule,
                                              type: key as OfferBogoCustomRuleTypeDtoEnum,
                                            },
                                          })
                                        );
                                      }
                                    }}
                                  >
                                    {i18n.translate(`BogoOffer.${key}`)}
                                  </Button>
                                )
                              )}
                            </ButtonGroup>
                            <div
                              className={
                                'Polaris-TextField Polaris-TextField--hasValue'
                              }
                              style={{
                                width: '100px',
                              }}
                            >
                              <Controller<FormFields>
                                name='customOffValue'
                                control={control}
                                render={({ field }) => (
                                  <NumberFormat
                                    {...field}
                                    className='Polaris-TextField__Input'
                                    value={field.value as number}
                                    decimalScale={isFixedAmountType ? 2 : 0}
                                    decimalSeparator={
                                      isFixedAmountType
                                        ? i18n.numberSymbols().decimalSymbol
                                        : undefined
                                    }
                                    thousandSeparator={
                                      isFixedAmountType
                                        ? i18n.numberSymbols().thousandSymbol
                                        : undefined
                                    }
                                    suffix={
                                      isFixedAmountType
                                        ? getCurrencySymbol()
                                        : '%'
                                    }
                                    placeholder={
                                      isFixedAmountType
                                        ? getCurrencySymbol()
                                        : '%'
                                    }
                                    allowNegative={false}
                                    autoComplete='off'
                                    onValueChange={({ floatValue }) =>
                                      field.onChange(floatValue ?? undefined)
                                    }
                                  />
                                )}
                              />
                              {incrementDecrementButtons(customOffValueField)}

                              <div className='Polaris-TextField__Backdrop'></div>
                            </div>
                            <Text as='p'>
                              {i18n.translate('BogoOffer.OffOn')}
                            </Text>
                            <div
                              className={
                                'Polaris-TextField Polaris-TextField--hasValue'
                              }
                              style={{
                                width: '64px',
                              }}
                            >
                              <Controller<FormFields>
                                name='customYvalue'
                                control={control}
                                render={({ field }) => (
                                  <NumberFormat
                                    {...field}
                                    className='Polaris-TextField__Input'
                                    inputMode='numeric'
                                    value={field.value as number}
                                    decimalScale={0}
                                    placeholder='Y'
                                    allowNegative={false}
                                    autoComplete='off'
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => changeValue(field, e.target.value)}
                                  />
                                )}
                              />
                              {incrementDecrementButtons(customYvalueField)}

                              <div className='Polaris-TextField__Backdrop'></div>
                            </div>
                            <InlineStack gap='200'>
                              <Text as='p'>
                                {i18n.translate('BogoOffer.Products')}
                              </Text>
                              <Tooltip
                                preferredPosition='mostSpace'
                                content={i18n.translate(
                                  'BogoOffer.HoverProducts'
                                )}
                              >
                                <Icon source={QuestionCircleIcon} />
                              </Tooltip>
                            </InlineStack>
                          </InlineStack>
                          <InlineStack blockAlign='center' gap='200'>
                            <Text as='p'>
                              {i18n.translate('BogoOffer.When')}
                            </Text>
                            <div
                              className={
                                'Polaris-TextField Polaris-TextField--hasValue'
                              }
                              style={{
                                width: '64px',
                              }}
                            >
                              <Controller<FormFields>
                                name='customXvalue'
                                control={control}
                                render={({ field }) => (
                                  <NumberFormat
                                    {...field}
                                    className='Polaris-TextField__Input'
                                    inputMode='numeric'
                                    value={field.value as number}
                                    decimalScale={0}
                                    placeholder='X'
                                    allowNegative={false}
                                    autoComplete='off'
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => changeValue(field, e.target.value)}
                                  />
                                )}
                              />
                              {incrementDecrementButtons(customXvalueField)}

                              <div className='Polaris-TextField__Backdrop'></div>
                            </div>

                            <Text as='p'>
                              {' '}
                              {i18n.translate('BogoOffer.ToTheCart')}
                            </Text>
                          </InlineStack>
                        </BlockStack>
                        {customRuleValidationMessages}
                      </div>
                    </Box>
                  )}
                </Box>
              ))}
            </BlockStack>
          </Card>
        </Page>
      )}
    </>
  );
};
