import React from 'react';
import './AnalyticsLineChart.scss';
import {
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  SkeletonBodyText,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { SearchResourceIcon, InfoIcon } from '@shopify/polaris-icons';
import {
  ChartState,
  DataSeries,
  LineChart,
  SparkLineChart,
} from '@shopify/polaris-viz';
import '@shopify/polaris-viz/build/esm/styles.css';
import { AnalyticsDataDto } from 'core/api/adminAnalytics/adminAnalyticsApi';
import { TrendIndicator } from 'core/components';
import { ChartuUnitOfMeasurementEnum } from 'pages';
import classNames from 'classnames';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';

type AnalyticsLineChartProps = {
  title: string;
  tooltip?: string;
  analyticsData: AnalyticsDataDto;
  isLoading?: boolean;
  unit: ChartuUnitOfMeasurementEnum;
  onNavigate?: () => void;
  minimize?: boolean;
};
export const AnalyticsLineChart: React.FC<AnalyticsLineChartProps> = ({
  title,
  tooltip,
  isLoading,
  analyticsData,
  unit,
  minimize = false,
  onNavigate,
}) => {
  const { formatValue } = useNumericFormatter();

  return (
    <Card>
      <BlockStack gap='300'>
        <InlineStack align='space-between' blockAlign='center'>
          <InlineStack gap='200'>
            <Text tone='base' as='h1' fontWeight='medium'>
              {title}
            </Text>
            {tooltip && (
              <Tooltip content={tooltip}>
                <Icon tone='base' source={InfoIcon} />
              </Tooltip>
            )}
          </InlineStack>
          {onNavigate && (
            <Button
              onClick={onNavigate}
              icon={SearchResourceIcon}
              variant='tertiary'
            />
          )}
        </InlineStack>
        {!isLoading ? (
          <InlineStack blockAlign='center' gap='200'>
            {typeof analyticsData.totalValue === 'number' && (
              <Text
                variant='headingLg'
                tone='base'
                as='h1'
                fontWeight='semibold'
              >
                {formatValue(analyticsData.totalValue, unit)}
              </Text>
            )}
            {typeof analyticsData.totalValue === 'number' &&
              !!analyticsData.trendValue && (
                <TrendIndicator value={analyticsData.trendValue as number} />
              )}
          </InlineStack>
        ) : (
          <Box width={'100px'} minHeight='24px'>
            <SkeletonBodyText lines={1} />
          </Box>
        )}
        <div
          className={classNames('LineChartWrapper', {
            minimize: minimize,
          })}
        >
          {minimize ? (
            <SparkLineChart
              state={(isLoading ? 'Loading' : 'Success') as ChartState}
              data={(analyticsData.dataSeries as DataSeries[]) || []}
            />
          ) : (
            <LineChart
              state={(isLoading ? 'Loading' : 'Success') as ChartState}
              data={(analyticsData.dataSeries as DataSeries[]) || []}
              tooltipOptions={{
                valueFormatter: (value) => formatValue(value as number, unit),
              }}
              yAxisOptions={{
                labelFormatter: (value) => formatValue(value as number, unit),
              }}
            />
          )}
        </div>
      </BlockStack>
    </Card>
  );
};
