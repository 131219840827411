import React, { useCallback } from 'react';
import './PromotionalBadgeOffer.scss';
import {
  DeviceDisplayDto,
  PromotionalBadgeGeneralOfferDto,
  PromotionalBadgeSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  DeviceDisplay,
  Order,
  ShopSettingsWrapper,
} from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { useAppDispatch } from 'core/hooks';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';

type PromotionalBadgeOfferProps = {
  offer: PromotionalBadgeGeneralOfferDto;
  defaultOffer: PromotionalBadgeGeneralOfferDto;
  shopDefaultOffer: PromotionalBadgeSystemDefaultsDto;
  offerName: string;
  setOffer: (data: PromotionalBadgeGeneralOfferDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const PromotionalBadgeOffer: React.FC<PromotionalBadgeOfferProps> = ({
  offer,
  defaultOffer,
  offerName,
  shopDefaultOffer,
  setOffer,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();
  const resetAllToSystemDefault = useCallback(() => {
    const { order } = shopDefaultOffer;
    setOffer({
      ...offer,
      order,
    });
  }, [shopDefaultOffer]);

  return (
    <div className='PromotionalBadgeOffer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setOffer(defaultOffer)}
        onResetToSystem={resetAllToSystemDefault}
        name={offerName}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      <div className='RightSideSection'>
        <Order
          order={offer.order || {}}
          onSystemReset={() =>
            setOffer({
              ...offer,
              order: shopDefaultOffer.order,
            })
          }
          setOrder={(data) =>
            setOffer({
              ...offer,
              order: data,
            })
          }
        />
      </div>
      <ShopSettingsWrapper
        onOpen={() =>
          dispatch(
            setToggleSettingsConfig({
              action: 'open',
              path: SettingsModalPathsEnums.PromotionalBadge,
            })
          )
        }
      >
        <div className='RightSideSection'>
          <DeviceDisplay
            deviceDisplay={
              offer.shopSettings?.deviceDisplay as DeviceDisplayDto
            }
            disabled
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default PromotionalBadgeOffer;
