import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { BlockStack, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { TagComponent } from './components/TagComponent/TagComponent';
import { tagMetadata, tagsMetafields } from './components/TagMetadata.data';
import { CouponCheckbox } from './components/CouponCheckbox/CouponCheckbox';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { TagsMetadataSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { useDispatch } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { SettingsSavebarRef } from 'features/settings/Settings';
import { TagMediaCard } from './components/TagMediaCard/TagMediaCard';

export const TagsMetadata = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();

  const {
    tagsMetadataDataChange,
    tagsMetadataDataIsFetching,
    tagsMetadataData,
  } = useConfigureSettings(SettingsFetchTypeEnum.TAGS_METADATA);
  const [tagsMetadataState, setTagsMetadataState] =
    useState<TagsMetadataSettingsDto>({});
  const [savedData, setSavedData] = useState<TagsMetadataSettingsDto>({});
  const dispatch = useDispatch();

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(tagsMetadataState);
    return tagsMetadataDataChange(tagsMetadataState, onSuccess);
  }, [tagsMetadataState]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => setTagsMetadataState(savedData),
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, tagsMetadataState)));
  }, [tagsMetadataState, savedData]);

  useEffect(() => {
    if (!isEmpty(tagsMetadataData)) {
      setTagsMetadataState(tagsMetadataData);
      setSavedData(tagsMetadataData);
    }
    !isEmpty(tagsMetadataData) && setTagsMetadataState(tagsMetadataData);
  }, [tagsMetadataData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  const handleTagsMetadataLocaleState = useCallback(
    (section: string, value: string | boolean, field?: string) => {
      if (field) {
        setTagsMetadataState(
          (
            prevState: Omit<
              TagsMetadataSettingsDto,
              'addTagFlagCouponFraud' | 'storeDiscountAppDataInOrderMetafields'
            >
          ) => ({
            ...prevState,
            [section]: {
              ...(prevState[section as keyof typeof prevState] || {}),
              [field]: value,
            },
          })
        );
      } else {
        setTagsMetadataState((prevState) => ({
          ...prevState,
          [section]: value,
        }));
      }
    },
    [tagsMetadataState]
  );

  return (
    <>
      {!tagsMetadataDataIsFetching && tagsMetadataState ? (
        <Page
          title={i18n.translate(`TagsMetadata`)}
          subtitle={i18n.translate(`TagsMetadataSubtitle`)}
        >
          <BlockStack gap='800'>
            <TagMediaCard />
            {tagMetadata.map((item, index) => (
              <TagComponent
                mapData={item}
                key={index}
                data={tagsMetadataState}
                handleTagsMetadataLocaleState={handleTagsMetadataLocaleState}
              />
            ))}
            {tagsMetafields.map((item, index) => (
              <CouponCheckbox
                mapData={item}
                key={index}
                data={tagsMetadataState}
                handleTagsMetadataLocaleState={handleTagsMetadataLocaleState}
              />
            ))}
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
    </>
  );
});
TagsMetadata.displayName = 'TagsMetadata';
