import React, { useState } from 'react';
import { Banner, BlockStack, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ActionListInPopover } from 'features/promotions/components/ActionListInPopover/ActionListInPopover';
import { ReturnIcon, PlayIcon } from '@shopify/polaris-icons';
import moment from 'moment';

type UnpublishedBannerProps = {
  publishedAtDate?: number | null;
  disabled: boolean;
  onPublish: () => void;
  onDiscard: () => void;
};

export const UnpublishedBanner: React.FC<UnpublishedBannerProps> = ({
  publishedAtDate,
  disabled,
  onPublish,
  onDiscard,
}) => {
  const [i18n] = useI18n();

  const [activePopoverId, setActivePopoverId] = useState<string>('');
  return (
    <Banner tone='warning' title={i18n.translate('UnpublishedChanges')}>
      <BlockStack gap='200'>
        <Text as='p'>
          {i18n.translate('LastUnpublished', {
            date: moment(publishedAtDate).fromNow(),
          })}
        </Text>
        <ActionListInPopover
          id='UnpublishedBannerId'
          activePopoverId={activePopoverId}
          setActivePopoverId={setActivePopoverId}
          fullWidth={false}
          width='fit-content'
          disable={disabled}
          btnContent={i18n.translate('PublishChanges')}
          actionList={[
            {
              content: i18n.translate('PublishChanges'),
              icon: PlayIcon,
              onAction: onPublish,
            },
            {
              content: i18n.translate('RollbackChanges'),
              icon: ReturnIcon,
              onAction: onDiscard,
            },
          ]}
        />
      </BlockStack>
    </Banner>
  );
};
