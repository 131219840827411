import React from 'react';
import {
  Badge,
  Box,
  Icon,
  IconSource,
  InlineStack,
  Text,
} from '@shopify/polaris';
type HeaderProps = {
  icon?: IconSource;
  title: string;
  tag?: string;
  button?: React.ReactNode;
};
type BoxWithSubduedHeaderProps = {
  header: HeaderProps;
  children: React.ReactNode;
};

export const BoxWithSubduedHeader: React.FC<BoxWithSubduedHeaderProps> = ({
  header,
  children,
}) => {
  const { button, icon, title, tag } = header;
  return (
    <Box borderColor='border' borderWidth='025' borderRadius='200'>
      <Box
        background='bg-surface-secondary'
        borderBlockEndWidth='025'
        borderColor='border'
        paddingBlock='200'
        paddingInline='300'
        borderStartEndRadius='200'
        borderStartStartRadius='200'
      >
        <InlineStack align='space-between' blockAlign='center'>
          <InlineStack gap='200'>
            {icon && <Icon tone='base' source={icon}></Icon>}
            <Text fontWeight='medium' as='p'>
              {title}
            </Text>
            {tag && <Badge>{tag}</Badge>}
          </InlineStack>
          {button && button}
        </InlineStack>
      </Box>
      <Box paddingBlockStart='300' paddingBlockEnd='400' paddingInline='400'>
        {children}
      </Box>
    </Box>
  );
};
