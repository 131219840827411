import React, { useCallback } from 'react';
import './TogglePromotionEngineModal.scss';
import { ModalCustom } from 'core/components/ModalCustom/ModalCustom';
import { useI18n } from '@shopify/react-i18n';
import { Banner, BlockStack, Text } from '@shopify/polaris';
import { useAppDispatch } from 'core/hooks';
import { setIsRefreshModalOpen } from 'core/store/settingsSlice';
type TogglePromotionEngineModalProps = {
  isOpen: boolean;
  link: string;
  hasActiveWidgets?: boolean;
  isEngineOn: boolean;
  onClose: () => void;
  setCheckConfiguration?: React.Dispatch<React.SetStateAction<boolean>>;
};
const TogglePromotionEngineModal: React.FC<TogglePromotionEngineModalProps> = ({
  isOpen,
  link,
  hasActiveWidgets,
  isEngineOn,
  onClose,
  setCheckConfiguration,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const openThemeEditor = useCallback(() => {
    window.open(link, '_blank');
    setCheckConfiguration?.(true);
    dispatch(setIsRefreshModalOpen(true));
    onClose();
  }, [link]);

  return (
    <ModalCustom
      title={i18n.translate(isEngineOn ? 'TurnOffEngine' : 'TurnOnEngine')}
      isOpen={isOpen}
      buttons={[
        {
          content: i18n.translate(`Cancel`),
          primary: false,
          action: onClose,
        },
        {
          content: i18n.translate('OpenThemeEditor'),
          primary: true,
          action: openThemeEditor,
        },
      ]}
      onClose={onClose}
    >
      <div className='TogglePromotionEngineModalContent'>
        <BlockStack gap='300'>
          <div>
            <Text as='p'>{i18n.translate('MustBeCompleted')}</Text>
            <Text as='p' fontWeight='medium'>
              {i18n.translate('DontForgetToSave')}
            </Text>
          </div>
          {isEngineOn && hasActiveWidgets && (
            <Banner tone='warning'>
              {i18n.translate('YouHaveActiveWidgets')}
            </Banner>
          )}
        </BlockStack>
      </div>
    </ModalCustom>
  );
};

export default TogglePromotionEngineModal;
