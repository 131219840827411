import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import { DeviceDisplayDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  ProductBannerShopSettingsDto,
  WidgetSettingsDtoProductBannerShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  DeviceDisplaySetting,
  StackingSetting,
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import { useI18n } from '@shopify/react-i18n';

export const ProductBannerSettings: React.FC = () => {
  const dispatch = useDispatch();
  const {
    productBannerData,
    saveProductBanner,
    productBannerDataIsFetching,
    refetchProductBannerData,
  } = useConfigureSettings(SettingsFetchTypeEnum.PRODUCT_BANNER);
  const [i18n] = useI18n();
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoProductBannerShopSettingsDto>({});
  const [productBannerState, setProductBannerState] =
    useState<WidgetSettingsDtoProductBannerShopSettingsDto>({});

  const updateProductBannerState = useCallback(
    (
      field: keyof ProductBannerShopSettingsDto,
      data: ProductBannerShopSettingsDto[keyof ProductBannerShopSettingsDto]
    ) =>
      setProductBannerState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setProductBannerState]
  );

  useEffect(() => {
    if (!isEmpty(productBannerData)) {
      setProductBannerState(productBannerData);
      setSavedData(productBannerData);
    }
  }, [productBannerData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='ProductBannerSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...productBannerState,
          widgetType: WidgetTypeSettingsWrapperEnum.PRODUCT_BANNER,
        }}
        isLoading={productBannerDataIsFetching && !productBannerData}
        refetchWidget={refetchProductBannerData}
      >
        <BlockStack gap='600'>
          <StyleSetting styled={!!productBannerData?.isShopLevelWidget} />
          <DeviceDisplaySetting
            deviceDisplay={
              productBannerState.settings?.deviceDisplay as DeviceDisplayDtoEnum
            }
            setDeviceDisplay={(value) =>
              updateProductBannerState('deviceDisplay', value)
            }
          />
          <StackingSetting
            stacking={productBannerState.settings?.stacking || {}}
            setStacking={(data) => updateProductBannerState('stacking', data)}
            maxCustomSpacing={20}
            subtitle={i18n.translate('AllowMoreOffers')}
          />
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
