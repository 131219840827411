import React, { useCallback } from 'react';
import {
  MessageStyleComponentDto,
  PromotionSummaryDiscountPresetDto,
  PromotionSummaryShippingPresetDto,
  PromotionSummaryTaxesAndDutiesPresetDto,
  PromotionSummaryTotalPresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  SectionDivider,
  SimplifiedTextEntries,
  SingleRadioSelection,
  TextStyle,
  ShopSettingsWrapper,
} from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { useI18n } from '@shopify/react-i18n';
import { Box, Link, Text } from '@shopify/polaris';
import { PromotionSummaryShippingAmountCalculationBehaviorDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import StyleComponentHeader from '../../../../../components/StyleComponentHeader/StyleComponentHeader';
import DiscountShopSettings from './components/DiscountShopSettings/DiscountShopSettings';
import { isEqual } from 'lodash';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useAppDispatch } from 'core/hooks';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';

type PromotionSummaryCombinedComponentDto = PromotionSummaryTotalPresetDto &
  PromotionSummaryShippingPresetDto &
  PromotionSummaryTaxesAndDutiesPresetDto &
  PromotionSummaryDiscountPresetDto;

export type PromotionSummaryCombinedComponentTypes =
  | WidgetListOptionsDtoEnum.TOTAL
  | WidgetListOptionsDtoEnum.SUBTOTAL
  | WidgetListOptionsDtoEnum.SHIPPING
  | WidgetListOptionsDtoEnum.DISCOUNT
  | WidgetListOptionsDtoEnum.TAXES_AND_DUTIES;

type PromotionSummaryCombinedComponentProps = {
  sectionType: PromotionSummaryCombinedComponentTypes;
  general: PromotionSummaryCombinedComponentDto;
  defaultGeneral: PromotionSummaryCombinedComponentDto;
  shopDefaultGeneral: PromotionSummaryCombinedComponentDto;
  defaultLanguage?: string;
  textStyle: MessageStyleComponentDto;
  defaultTextStyle: MessageStyleComponentDto;
  shopDefaultTextStyle: MessageStyleComponentDto;
  isPresetEditor?: boolean;
  setGeneral: (
    data: PromotionSummaryCombinedComponentDto,
    textStyle?: MessageStyleComponentDto
  ) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const PromotionSummaryCombinedComponent: React.FC<
  PromotionSummaryCombinedComponentProps
> = ({
  sectionType,
  general,
  defaultGeneral,
  shopDefaultGeneral,
  defaultLanguage,
  textStyle,
  shopDefaultTextStyle,
  isPresetEditor,
  defaultTextStyle,
  setGeneral,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();
  const resetAllToSystemDefault = useCallback(() => {
    setGeneral({
      ...general,
      ...shopDefaultGeneral,
    });
  }, [shopDefaultGeneral, sectionType]);

  const handleUpdateGeneral = useCallback(
    (
      field: keyof PromotionSummaryCombinedComponentDto,
      data: PromotionSummaryCombinedComponentDto[keyof PromotionSummaryCombinedComponentDto]
    ) =>
      setGeneral({
        ...general,
        [field]: data,
      }),
    [general]
  );

  const amountCalculationOptions = Object.values(
    PromotionSummaryShippingAmountCalculationBehaviorDtoEnum
  ).map((value) => {
    return {
      value: value,
      label: i18n.translate(`${value}`),
    };
  });

  return (
    <div className='NotificationFooter'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => {
          setGeneral(defaultGeneral, defaultTextStyle);
        }}
        onResetToSystem={resetAllToSystemDefault}
        name={sectionType}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />

      <div className='RightSideSection'>
        {sectionType !== WidgetListOptionsDtoEnum.TOTAL ? (
          <SimplifiedTextEntries
            message={general.label || {}}
            inherited={
              !isPresetEditor
                ? isEqual(general.label, defaultGeneral?.label)
                : undefined
            }
            title={i18n.translate('Label')}
            defaultLanguage={defaultLanguage || ''}
            setMessage={(data) => setGeneral({ ...general, label: data })}
            onSystemReset={() =>
              setGeneral({ ...general, label: shopDefaultGeneral.label })
            }
          />
        ) : (
          <SimplifiedTextEntries
            message={general.label?.message || {}}
            inherited={
              !isPresetEditor
                ? isEqual(
                    general.label?.message,
                    defaultGeneral?.label?.message
                  )
                : undefined
            }
            title={i18n.translate('Label')}
            defaultLanguage={defaultLanguage || ''}
            setMessage={(data) =>
              handleUpdateGeneral('label', { ...general.label, message: data })
            }
            onSystemReset={() =>
              handleUpdateGeneral('label', {
                ...general.label,
                message: shopDefaultGeneral.label?.message,
              })
            }
          />
        )}
      </div>

      <div className='RightSideSection'>
        {sectionType !== WidgetListOptionsDtoEnum.TOTAL ? (
          <TextStyle
            textStyle={textStyle}
            inherited={
              !isPresetEditor ? isEqual(textStyle, defaultTextStyle) : undefined
            }
            title={i18n.translate('LabelStyle')}
            note={i18n.translate('TextStyleNote')}
            onSystemReset={() => setGeneral(general, shopDefaultTextStyle)}
            setTextStyle={(data) => setGeneral(general, data)}
          />
        ) : (
          <TextStyle
            textStyle={general.label?.style || {}}
            inherited={
              !isPresetEditor
                ? isEqual(general.label?.style, defaultGeneral.label?.style)
                : undefined
            }
            title={i18n.translate('LabelStyle')}
            setTextStyle={(data) =>
              handleUpdateGeneral('label', { ...general.label, style: data })
            }
            onSystemReset={() =>
              handleUpdateGeneral('label', {
                ...general.label,
                style: shopDefaultGeneral.label?.style,
              })
            }
          />
        )}
      </div>

      {sectionType === WidgetListOptionsDtoEnum.TAXES_AND_DUTIES && (
        <>
          <div className='RightSideSection'>
            <SimplifiedTextEntries
              message={general.description?.message || {}}
              inherited={
                !isPresetEditor
                  ? isEqual(
                      general.description?.message,
                      defaultGeneral.description?.message
                    )
                  : undefined
              }
              title={i18n.translate('DescriptionText')}
              defaultLanguage={defaultLanguage || ''}
              setMessage={(data) =>
                handleUpdateGeneral('description', {
                  ...general.description,
                  message: data,
                })
              }
              onSystemReset={() =>
                handleUpdateGeneral('description', {
                  ...general.description,
                  message: shopDefaultGeneral.description?.message,
                })
              }
            />
          </div>
          <div className='RightSideSection'>
            <TextStyle
              textStyle={general.description?.style || {}}
              inherited={
                !isPresetEditor
                  ? isEqual(
                      general.description?.style,
                      defaultGeneral.description?.style
                    )
                  : undefined
              }
              title={i18n.translate('DescriptionStyle')}
              setTextStyle={(data) =>
                handleUpdateGeneral('description', {
                  ...general.description,
                  style: data,
                })
              }
              onSystemReset={() =>
                handleUpdateGeneral('description', {
                  ...general.description,
                  style: shopDefaultGeneral.description?.style,
                })
              }
            />
          </div>
        </>
      )}

      {sectionType === WidgetListOptionsDtoEnum.TOTAL && (
        <div className='RightSideSection'>
          <SectionDivider
            sectionDivider={general.sectionDivider || {}}
            inherited={
              !isPresetEditor
                ? isEqual(general.sectionDivider, defaultGeneral.sectionDivider)
                : undefined
            }
            setSectionDivider={(data) =>
              handleUpdateGeneral('sectionDivider', data)
            }
            onSystemReset={() =>
              handleUpdateGeneral(
                'sectionDivider',
                shopDefaultGeneral.sectionDivider
              )
            }
          />
        </div>
      )}
      {sectionType !== WidgetListOptionsDtoEnum.TAXES_AND_DUTIES && (
        <div className='RightSideSection'>
          <StyleComponentHeader
            buttons={[]}
            label={i18n.translate('PriceStyle')}
          >
            <Text tone='subdued' as='p'>
              {i18n.translate('ToCustomizeGo')}
              {sectionType === WidgetListOptionsDtoEnum.TOTAL ? (
                <Link>{i18n.translate('TotalPrice')}</Link>
              ) : (
                <Link>{i18n.translate('BreakdownPrice')}</Link>
              )}
              {i18n.translate('Widget')}
            </Text>
          </StyleComponentHeader>
        </div>
      )}
      {sectionType === WidgetListOptionsDtoEnum.SUBTOTAL && (
        <div className='RightSideSection'>
          <Box padding='500'>
            <Text tone='subdued' as='p'>
              {i18n.translate('SubtotalRules1')}
              <Link
                onClick={() =>
                  dispatch(
                    setToggleSettingsConfig({
                      action: 'open',
                      path: SettingsModalPathsEnums.PromotionSummary,
                      element: 'TotalDiscount',
                    })
                  )
                }
              >
                {i18n.translate('ShopSettings')}
              </Link>
              {i18n.translate('SubtotalRules2')}
            </Text>
          </Box>
        </div>
      )}

      {sectionType === WidgetListOptionsDtoEnum.SHIPPING && (
        <ShopSettingsWrapper
          onOpen={() =>
            dispatch(
              setToggleSettingsConfig({
                action: 'open',
                path: SettingsModalPathsEnums.PromotionSummary,
              })
            )
          }
        >
          <div className='RightSideSection'>
            <SingleRadioSelection
              label={i18n.translate('Amount')}
              options={amountCalculationOptions}
              disabled
              selectedOption={
                general.shopSettings?.amount?.calculation as string
              }
            />
            {general.shopSettings?.amount?.calculation ===
              PromotionSummaryShippingAmountCalculationBehaviorDtoEnum.CALCULATED_AT_CHECKOUT && (
              <SimplifiedTextEntries
                message={general.shopSettings?.amount.displayText || {}}
                title={i18n.translate('DisplayText')}
                hideHeader
                messageLabel={i18n.translate('DisplayText')}
                defaultLanguage={defaultLanguage || ''}
                disabled
                setMessage={(data) =>
                  handleUpdateGeneral('shopSettings', {
                    ...general.shopSettings,
                    amount: {
                      ...general.shopSettings?.amount,
                      displayText: data,
                    },
                  })
                }
                onSystemReset={() =>
                  handleUpdateGeneral('shopSettings', {
                    ...general.shopSettings,
                    amount: {
                      ...general.shopSettings?.amount,
                      displayText:
                        shopDefaultGeneral.shopSettings?.amount?.displayText,
                    },
                  })
                }
              />
            )}
          </div>
        </ShopSettingsWrapper>
      )}
      {sectionType === WidgetListOptionsDtoEnum.DISCOUNT && (
        <DiscountShopSettings
          shopSettings={general.shopSettings || {}}
          disabled
        />
      )}
    </div>
  );
};

export default PromotionSummaryCombinedComponent;
