import React, { useMemo } from 'react';
import {
  BlockStack,
  Box,
  ButtonGroup,
  DataTable,
  InlineStack,
  Page,
  SkeletonDisplayText,
} from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { useI18n } from '@shopify/react-i18n';
import { useConfigureAnalytics } from 'features/analytics/hooks/useConfigureAnalytics';
import { ChartuUnitOfMeasurementEnum, DetailedChartTypeEnum } from 'pages';
import { AnalyticsLineChart } from '../Charts';
import './DetailedChartPage.scss';
import useExportAnalytics from 'features/analytics/utils/useExportAnalytics';
import { PeriodSelector } from '../PeriodSelector/PeriodSelector';
import { useAppSelector } from 'core/hooks';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
const APP_NAME = process.env.REACT_APP_APP_NAME || '';
type DetailedChartPageProps = {
  chartType: DetailedChartTypeEnum;
  unit: ChartuUnitOfMeasurementEnum;
};
export const DetailedChartPage: React.FC<DetailedChartPageProps> = ({
  chartType,
  unit,
}) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const [i18n] = useI18n();
  const navigate = useNavigate();
  const { exportAnalytics, exportAnalyticsIsFetching } =
    useExportAnalytics(chartType);
  const { formatValue } = useNumericFormatter();
  const { analyticsPeriod } = useAppSelector((store) => store.settings);
  const { chartDetailsQuery } = useConfigureAnalytics(chartType as any);

  const {
    data: analyticDetailsData,
    isFetching: analyticDetailsIsFetching,
    refetch: refetchAnalyticDetails,
  } = chartDetailsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      period: analyticsPeriod,
    },
    { refetchOnMountOrArgChange: true }
  );
  const dataSeries = analyticDetailsData?.dataSeries;

  const headings = useMemo(() => {
    switch (chartType) {
      case DetailedChartTypeEnum.DN_CONVERSION_RATE:
        return [
          i18n.translate('Sessions'),
          i18n.translate('AddedToCart'),
          i18n.translate('ReachedCheckout'),
          i18n.translate('Orders'),
          i18n.translate('ConversionRate'),
        ];
      case DetailedChartTypeEnum.DN_SESSIONS:
        return [i18n.translate('Sessions')];
      case DetailedChartTypeEnum.TOTAL_DN_ORDERS:
        return [i18n.translate('Orders')];
      case DetailedChartTypeEnum.TOTAL_DN_REVENUE:
        return [i18n.translate('Orders'), i18n.translate('Revenue')];
    }
  }, [chartType]);

  const listDataRows = useMemo(() => {
    //Upgrade in the next iteration
    const dataSerie = dataSeries?.[0];
    return dataSerie?.data?.map((rowData: any) => {
      switch (chartType) {
        case DetailedChartTypeEnum.DN_CONVERSION_RATE:
          return [
            rowData.key,
            rowData.sessions,
            rowData.addToCart,
            rowData.checkoutInitiated,
            rowData.orders,
            formatValue(rowData.value, ChartuUnitOfMeasurementEnum.PERCENTAGE),
          ];
        case DetailedChartTypeEnum.DN_SESSIONS:
        case DetailedChartTypeEnum.TOTAL_DN_ORDERS:
          return [rowData.key, rowData.value];
        case DetailedChartTypeEnum.TOTAL_DN_REVENUE:
          return [rowData.key, rowData.orders, rowData.value];
      }
    });
  }, [dataSeries]);

  const totals = useMemo(() => {
    switch (chartType) {
      case DetailedChartTypeEnum.DN_CONVERSION_RATE:
        return [
          '',
          analyticDetailsData?.sessionsTotal?.current,
          analyticDetailsData?.addToCartTotal?.current,
          analyticDetailsData?.checkoutInitiatedTotal?.current,
          analyticDetailsData?.ordersTotal?.current,
          formatValue(
            dataSeries?.[0].totalValue,
            ChartuUnitOfMeasurementEnum.PERCENTAGE
          ),
        ];
      case DetailedChartTypeEnum.DN_SESSIONS:
      case DetailedChartTypeEnum.TOTAL_DN_ORDERS:
        return ['', dataSeries?.[0].totalValue];
      case DetailedChartTypeEnum.TOTAL_DN_REVENUE:
        return [
          '',
          analyticDetailsData?.ordersTotal?.current,
          analyticDetailsData?.revenueTotal?.current,
        ];
    }
  }, [chartType, analyticDetailsData]);

  const skeletonRows = useMemo(() => {
    return Array.from({ length: 10 }, (_, i) => i).map(() =>
      ['', ...headings].map((_, index) => (
        <InlineStack key={index} align={index === 0 ? 'start' : 'end'}>
          <SkeletonDisplayText size='small' />
        </InlineStack>
      ))
    );
  }, [headings]);
  const skeletonTotals = useMemo(
    () => [
      <SkeletonDisplayText size='small' key={101} />,
      ...headings.map((_, index) => (
        <InlineStack key={index} align='end'>
          <SkeletonDisplayText size='small' />
        </InlineStack>
      )),
    ],
    [headings]
  );

  return (
    <div className='DetailedChartPage'>
      <Page
        backAction={{ onAction: () => navigate(-1) }}
        fullWidth
        title={i18n.translate(`${chartType}_PAGE`)}
        secondaryActions={[
          {
            content: i18n.translate('Refresh'),
            onAction: refetchAnalyticDetails,
          },
          {
            content: i18n.translate('Export'),
            loading: exportAnalyticsIsFetching,
            onAction: exportAnalytics,
          },
        ]}
      >
        <Box paddingBlockEnd='500'>
          <BlockStack gap='400'>
            <ButtonGroup>
              <PeriodSelector />
              {/*Develop in next iteration */}
              {/* <Button>Compare to: Jun1, 2022-May 31,2023</Button> */}
            </ButtonGroup>
            <AnalyticsLineChart
              title={i18n.translate(`${chartType}_TITLE`)}
              analyticsData={{
                dataSeries: analyticDetailsData?.dataSeries,
              }}
              isLoading={analyticDetailsIsFetching}
              unit={unit}
            />
            <DataTable
              columnContentTypes={[
                'text',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
                'numeric',
              ]}
              headings={[
                i18n.translate(`${analyticsPeriod}_HEADING`),
                ...headings,
              ]}
              rows={
                analyticDetailsIsFetching || !analyticDetailsData
                  ? skeletonRows
                  : listDataRows
              }
              totals={
                analyticDetailsIsFetching || !analyticDetailsData
                  ? skeletonTotals
                  : totals
              }
              footerContent={i18n.translate('FooterContent', {
                current: 1,
                total: listDataRows?.length,
              })}
              totalsName={{
                singular: dataSeries?.[0]?.name,
                plural: dataSeries?.[0]?.name,
              }}
            />
          </BlockStack>
        </Box>
      </Page>
    </div>
  );
};
