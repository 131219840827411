import React from 'react';
import './SettingsSkeleton.scss';
import {
  SkeletonPage,
  Card,
  SkeletonBodyText,
  SkeletonDisplayText,
  BlockStack,
} from '@shopify/polaris';

type SettingsSkeletonProps = {
  cardsCount?: number;
};

export const SettingsSkeleton: React.FC<SettingsSkeletonProps> = ({
  cardsCount,
}) => {
  return (
    <div className='SettingsSkeleton'>
      <SkeletonPage primaryAction>
        <BlockStack gap='500'>
          {Array.from({ length: cardsCount || 4 }, (_, i) => i).map((value) => (
            <Card key={value}>
              <BlockStack gap='400'>
                <SkeletonDisplayText size='small' />
                <SkeletonBodyText lines={6} />
              </BlockStack>
            </Card>
          ))}
        </BlockStack>
      </SkeletonPage>
    </div>
  );
};
