import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import { PositionDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  PromotionalBadgeShopSettingsDto,
  WidgetSettingsDtoPromotionalBadgeShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  OffsetSetting,
  PositionSetting,
  StackingSetting,
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import { useI18n } from '@shopify/react-i18n';

export const PromotionalBadgeSettings: React.FC = () => {
  const dispatch = useDispatch();
  const {
    promotionalBadgeData,
    savePromotionalBadge,
    promotionalBadgeDataIsFetching,
    savePromotionalBadgeIsLoading,
    refetchPromotionalBadgeData,
  } = useConfigureSettings(SettingsFetchTypeEnum.PROMOTIONAL_BADGE);
  const [i18n] = useI18n();
  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoPromotionalBadgeShopSettingsDto>({});
  const [promotionalBadgeState, setPromotionalBadgeState] =
    useState<WidgetSettingsDtoPromotionalBadgeShopSettingsDto>({});

  const updatePromotionalBadgeState = useCallback(
    (
      field: keyof PromotionalBadgeShopSettingsDto,
      data: PromotionalBadgeShopSettingsDto[keyof PromotionalBadgeShopSettingsDto]
    ) =>
      setPromotionalBadgeState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [setPromotionalBadgeState]
  );

  useEffect(() => {
    if (!isEmpty(promotionalBadgeData)) {
      setPromotionalBadgeState(promotionalBadgeData);
      setSavedData(promotionalBadgeData);
    }
  }, [promotionalBadgeData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='PromotionalBadgeSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...promotionalBadgeState,
          widgetType: WidgetTypeSettingsWrapperEnum.PROMOTIONAL_BADGE,
        }}
        isLoading={promotionalBadgeDataIsFetching || !promotionalBadgeData}
        refetchWidget={refetchPromotionalBadgeData}
      >
        <BlockStack gap='600'>
          <StyleSetting styled={!!promotionalBadgeData?.isShopLevelWidget} />

          <PositionSetting
            position={
              promotionalBadgeState.settings?.position as PositionDtoEnum
            }
            positionSides={[
              PositionDtoEnum.BOTTOM_LEFT,
              PositionDtoEnum.BOTTOM_RIGHT,
              PositionDtoEnum.TOP_LEFT,
              PositionDtoEnum.TOP_RIGHT,
            ]}
            title={i18n.translate('Position')}
            subtitle={i18n.translate('AdjustPosition')}
            setPosition={(value) =>
              updatePromotionalBadgeState('position', value)
            }
          />
          <OffsetSetting
            offset={promotionalBadgeState.settings?.offset || {}}
            setOffset={(data) => updatePromotionalBadgeState('offset', data)}
          />
          <StackingSetting
            stacking={promotionalBadgeState.settings?.stacking || {}}
            setStacking={(data) =>
              updatePromotionalBadgeState('stacking', data)
            }
            subtitle={i18n.translate('AllowMoreOffers')}
            checkboxSubtitle={i18n.translate('SupportedForRectangle')}
            maxCustomSpacing={20}
          />
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
