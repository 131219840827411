import {
  BlockStack,
  Box,
  Card,
  DatePicker,
  Icon,
  InlineStack,
  Popover,
  TextField,
  Text,
} from '@shopify/polaris';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectIcon, CalendarIcon, ClockIcon } from '@shopify/polaris-icons';
import { addMinutes, format } from 'date-fns';
import './DateTimePicker.scss';
import SelectOptions from '../SelectOptions/SelectOptions';
import { InfoIcon } from '@shopify/polaris-icons';

type DateTimePickerProps = {
  timestampValue: number;
  dateLabel?: string;
  timeLabel?: string;
  disabled?: boolean;
  minDate?: number;
  error?: string;
  setTimestampValue: (date: number) => void;
};
export const DateTimePicker: React.FC<DateTimePickerProps> = ({
  timestampValue,
  dateLabel,
  timeLabel,
  disabled,
  minDate,
  error,
  setTimestampValue,
}) => {
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [{ month, year }, setDate] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
  });
  const timeToSelect = useMemo(
    () =>
      Array.from({ length: 48 }, (_, index) => {
        const hour = Math.floor(index / 2);
        const minute = (index % 2) * 30;
        const ampm = hour < 12 ? 'am' : 'pm';
        const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
        return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
      }),
    []
  );
  const yesterdayDate = useMemo(() => {
    let currentDate;
    if (minDate) {
      currentDate = new Date(minDate);
    } else {
      currentDate = new Date();
    }
    const yesterdayDate = new Date(currentDate);
    yesterdayDate.setDate(currentDate.getDate() - 1);
    return yesterdayDate;
  }, [minDate]);

  const formattedValue = format(selectedDate, 'MMMM do yyyy');

  const timeOptions = useMemo(() => {
    return timeToSelect?.map((el: string) => ({
      value: el,
      label: el,
    }));
  }, [timeToSelect]);

  const handleMonthChange = useCallback((month: number, year: number) => {
    setDate({ month, year });
  }, []);

  const handleDateSelection = useCallback(
    ({ end: newSelectedDate }: { end: Date }) => {
      setSelectedDate(newSelectedDate);
      setVisible(false);
    },
    []
  );

  const roundToNearestHalfHour = useCallback((date: Date) => {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.floor(minutes / 30) * 30;
    const roundedDate = addMinutes(date, roundedMinutes - minutes);
    return format(roundedDate, 'h:mm a').replace(/\b([APM]+)\b/g, (match) =>
      match.toLowerCase()
    );
  }, []);

  const getCurrentTimeStamp = useCallback(() => {
    const [time, ampm] = selectedTime.split(' ');
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':').map(Number);
    if (ampm === 'pm' && hours !== 12) {
      hours += 12;
    } else if (ampm === 'am' && hours === 12) {
      hours = 0;
    }
    const newTimestamp = new Date(selectedDate);
    newTimestamp.setHours(hours);
    newTimestamp.setMinutes(minutes);
    setTimestampValue(newTimestamp.getTime());
  }, [selectedTime, selectedDate]);

  useEffect(() => {
    getCurrentTimeStamp();
  }, [selectedDate, selectedTime]);

  useEffect(() => {
    if (selectedDate) {
      setDate({
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (timestampValue) {
      const dateFormat = new Date(timestampValue);
      setSelectedDate(dateFormat);
      setSelectedTime(roundToNearestHalfHour(dateFormat));
    }
  }, []);

  return (
    <BlockStack gap='200'>
      <div className='DateTimePicker'>
        <Popover
          active={visible && !disabled}
          autofocusTarget='none'
          preferredAlignment='left'
          preferInputActivator={false}
          preferredPosition='below'
          preventCloseOnChildOverlayClick
          onClose={() => setVisible(false)}
          activator={
            <TextField
              role='combobox'
              label={dateLabel ? dateLabel : ''}
              suffix={<Icon source={SelectIcon} />}
              value={formattedValue}
              onFocus={() => setVisible(true)}
              autoComplete='off'
              disabled={disabled}
              error={!!error}
              prefix={<Icon source={CalendarIcon} />}
            />
          }
        >
          <Card>
            <DatePicker
              month={month}
              year={year}
              selected={selectedDate}
              disableDatesBefore={yesterdayDate}
              onMonthChange={handleMonthChange}
              onChange={handleDateSelection}
            />
          </Card>
        </Popover>
        <SelectOptions
          options={timeOptions}
          onOptionSelect={setSelectedTime}
          selectedOption={selectedTime}
          label={timeLabel ? timeLabel : ''}
          disabled={disabled}
          error={!!error}
          prefix={<Icon source={ClockIcon} />}
        />
      </div>
      {error && (
        <InlineStack gap='100'>
          <Box>
            <Icon tone='textCritical' source={InfoIcon} />
          </Box>
          <Text as='p' tone='critical'>
            {error}
          </Text>
        </InlineStack>
      )}
    </BlockStack>
  );
};
