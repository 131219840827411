import React, { useCallback, useMemo } from 'react';
import './ProductBannerOffer.scss';
import {
  DeviceDisplayDto,
  ProductBannerGeneralMainOfferDto,
  ProductBannerOfferDtoRead,
  ProductBannerPresetGeneralDto,
  ProductBannerSystemDefaultsDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  Background,
  CornerRadius,
  DeviceDisplay,
  EntitledVsPrerequisite,
  Link,
  Order,
  ShopSettingsWrapper,
  Size,
} from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { isEqual } from 'lodash';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { useAppDispatch } from 'core/hooks';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';

type ProductBannerOfferProps = {
  offer: ProductBannerOfferDtoRead;
  defaultOffer: ProductBannerOfferDtoRead;
  shopDefaultOffer: ProductBannerSystemDefaultsDto;
  offerName: string;
  presetSetup?: ProductBannerPresetGeneralDto;
  defaultPresetSetup?: ProductBannerPresetGeneralDto;
  setOffer: (data: ProductBannerOfferDtoRead) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const ProductBannerOffer: React.FC<ProductBannerOfferProps> = ({
  offer,
  defaultOffer,
  shopDefaultOffer,
  offerName,
  presetSetup,
  defaultPresetSetup,
  setOffer,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const dispatch = useAppDispatch();
  const presetSetupData = useMemo(
    () => ({
      background: presetSetup?.background,
      cornerRadius: presetSetup?.cornerRadius,
    }),
    [presetSetup]
  );

  const handleOfferComponent = useCallback(
    (
      field: keyof ProductBannerGeneralMainOfferDto,
      data: ProductBannerGeneralMainOfferDto[keyof ProductBannerGeneralMainOfferDto]
    ) => {
      setOffer({
        ...offer,
        general: {
          ...offer.general,
          ...presetSetupData,
          [field]: data,
        },
      });
    },
    [offer, setOffer, presetSetup]
  );

  const resetAllToSystemDefault = useCallback(() => {
    const { background, cornerRadius, link, order, size } = shopDefaultOffer;
    setOffer({
      ...offer,
      general: {
        ...offer.general,
        background,
        cornerRadius,
        link,
        order,
        size,
        supportEntitledAndPrerequisiteProducts:
          defaultOffer.general?.supportEntitledAndPrerequisiteProducts,
      },
    });
  }, [shopDefaultOffer, offer, defaultOffer]);

  const onDiscardChanges = useCallback(() => {
    setOffer({
      ...defaultOffer,
      general: {
        ...defaultOffer.general,
        background: defaultPresetSetup?.background,
        cornerRadius: defaultPresetSetup?.cornerRadius,
      },
    });
  }, [defaultOffer, defaultPresetSetup]);

  return (
    <div className='ProductBannerOffer'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={onDiscardChanges}
        onResetToSystem={resetAllToSystemDefault}
        name={offerName}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {offer.general?.isEntitledAndPrerequisiteProductsSupported && (
        <>
          <div className='RightSideSection'>
            <EntitledVsPrerequisite
              entitledVsPrerequisite={
                !!offer.general?.supportEntitledAndPrerequisiteProducts
              }
              onSystemReset={() =>
                handleOfferComponent(
                  'supportEntitledAndPrerequisiteProducts',
                  defaultOffer.general?.supportEntitledAndPrerequisiteProducts
                )
              }
              setEntitledVsPrerequisite={(value) =>
                setOffer({
                  ...offer,
                  currentEntries: value
                    ? ['ENTITLED', 'PREREQUISITE']
                    : ['ICON', 'MESSAGE'],
                  general: {
                    ...offer.general,
                    ...presetSetupData,
                    supportEntitledAndPrerequisiteProducts: value,
                  },
                })
              }
            />
          </div>
          <div className='RightSideSection'>
            <Order
              order={offer.general?.order || {}}
              setOrder={(data) => handleOfferComponent('order', data)}
              onSystemReset={() =>
                handleOfferComponent('order', shopDefaultOffer.order)
              }
            />
          </div>
        </>
      )}

      {!offer.general?.supportEntitledAndPrerequisiteProducts && (
        <>
          <div className='RightSideSection'>
            <Size
              onSystemReset={() =>
                handleOfferComponent('size', shopDefaultOffer.size)
              }
              size={offer.general?.size || {}}
              setSize={(data) => handleOfferComponent('size', data)}
              disableWidth
            />
          </div>
          <div className='RightSideSection'>
            <Link
              link={offer.general?.link || {}}
              setLink={(data) => handleOfferComponent('link', data)}
              onSystemReset={() =>
                handleOfferComponent('link', shopDefaultOffer.link)
              }
            />
          </div>
          <div className='RightSideSection'>
            <Background
              background={presetSetup?.background || {}}
              inherited={isEqual(
                presetSetup?.background,
                defaultPresetSetup?.background
              )}
              setBackground={(data) => handleOfferComponent('background', data)}
              onSystemReset={() =>
                handleOfferComponent('background', shopDefaultOffer.background)
              }
            />
          </div>
          <div className='RightSideSection'>
            <CornerRadius
              cornerRadius={presetSetup?.cornerRadius || {}}
              inherited={isEqual(
                presetSetup?.cornerRadius,
                defaultPresetSetup?.cornerRadius
              )}
              setCornerRadius={(data) =>
                handleOfferComponent('cornerRadius', data)
              }
              onSystemReset={() =>
                handleOfferComponent(
                  'cornerRadius',
                  shopDefaultOffer.cornerRadius
                )
              }
            />
          </div>
        </>
      )}

      <ShopSettingsWrapper
        onOpen={() =>
          dispatch(
            setToggleSettingsConfig({
              action: 'open',
              path: SettingsModalPathsEnums.ProductBanner,
            })
          )
        }
      >
        <div className='RightSideSection'>
          <DeviceDisplay
            disabled
            deviceDisplay={
              offer.general?.shopSettings?.deviceDisplay as DeviceDisplayDto
            }
          />
        </div>
      </ShopSettingsWrapper>
    </div>
  );
};

export default ProductBannerOffer;
