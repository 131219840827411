import { emptySplitAdminAnalyticsApi as api } from './emptyAdminAnalyticsApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAnalyticsV6Overview: build.query<
      GetAnalyticsV6OverviewApiResponse,
      GetAnalyticsV6OverviewApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/overview`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
    getAnalyticsV6DashboardOverview: build.query<
      GetAnalyticsV6DashboardOverviewApiResponse,
      GetAnalyticsV6DashboardOverviewApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/dashboardOverview`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
    postAnalyticsV6PromotionActivity: build.mutation<
      PostAnalyticsV6PromotionActivityApiResponse,
      PostAnalyticsV6PromotionActivityApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/promotionActivity`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postAnalyticsV6PromotionCode: build.mutation<
      PostAnalyticsV6PromotionCodeApiResponse,
      PostAnalyticsV6PromotionCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/promotionCode`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postAnalyticsV6Order: build.mutation<
      PostAnalyticsV6OrderApiResponse,
      PostAnalyticsV6OrderApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/order`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getAnalyticsV6TotalRevenue: build.query<
      GetAnalyticsV6TotalRevenueApiResponse,
      GetAnalyticsV6TotalRevenueApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/totalRevenue`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
    getAnalyticsV6ConversionRate: build.query<
      GetAnalyticsV6ConversionRateApiResponse,
      GetAnalyticsV6ConversionRateApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/conversionRate`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
    getAnalyticsV6TotalOrders: build.query<
      GetAnalyticsV6TotalOrdersApiResponse,
      GetAnalyticsV6TotalOrdersApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/totalOrders`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
    getAnalyticsV6Sessions: build.query<
      GetAnalyticsV6SessionsApiResponse,
      GetAnalyticsV6SessionsApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/sessions`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
    postAnalyticsV6OrderExport: build.mutation<
      PostAnalyticsV6OrderExportApiResponse,
      PostAnalyticsV6OrderExportApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/order/export`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postAnalyticsV6PromotionCodeExport: build.mutation<
      PostAnalyticsV6PromotionCodeExportApiResponse,
      PostAnalyticsV6PromotionCodeExportApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/promotionCode/export`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    postAnalyticsV6PromotionActivityExport: build.mutation<
      PostAnalyticsV6PromotionActivityExportApiResponse,
      PostAnalyticsV6PromotionActivityExportApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/promotionActivity/export`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
      }),
    }),
    getAnalyticsV6SessionsExport: build.query<
      GetAnalyticsV6SessionsExportApiResponse,
      GetAnalyticsV6SessionsExportApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/sessions/export`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
    getAnalyticsV6TotalOrdersExport: build.query<
      GetAnalyticsV6TotalOrdersExportApiResponse,
      GetAnalyticsV6TotalOrdersExportApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/totalOrders/export`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
    getAnalyticsV6ConversionRateExport: build.query<
      GetAnalyticsV6ConversionRateExportApiResponse,
      GetAnalyticsV6ConversionRateExportApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/conversionRate/export`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
    getAnalyticsV6TotalRevenueExport: build.query<
      GetAnalyticsV6TotalRevenueExportApiResponse,
      GetAnalyticsV6TotalRevenueExportApiArg
    >({
      query: (queryArg) => ({
        url: `/analytics/v6/totalRevenue/export`,
        headers: {
          'X-LimoniApps-AppName': queryArg['X-LimoniApps-AppName'],
          'X-LimoniApps-AppSecret': queryArg['X-LimoniApps-AppSecret'],
        },
        params: {
          period: queryArg.period,
          comparisonPeriod: queryArg.comparisonPeriod,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as adminAnalyticsApi };
export type GetAnalyticsV6OverviewApiResponse =
  /** status 200 Success */ AnalyticsOverviewResponseDtoRead;
export type GetAnalyticsV6OverviewApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetAnalyticsV6DashboardOverviewApiResponse =
  /** status 200 Success */ AnalyticsDashboardOverviewResponseDto;
export type GetAnalyticsV6DashboardOverviewApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostAnalyticsV6PromotionActivityApiResponse =
  /** status 200 Success */ PagedResponseDtoPromotionActivityAnalyticsEntryDto;
export type PostAnalyticsV6PromotionActivityApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: PromotionActivityAnalyticsQueryDto;
};
export type PostAnalyticsV6PromotionCodeApiResponse =
  /** status 200 Success */ PagedResponseDtoPromotionCodeAnalyticsEntryDto;
export type PostAnalyticsV6PromotionCodeApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: PromotionCodeAnalyticsQueryDto;
};
export type PostAnalyticsV6OrderApiResponse =
  /** status 200 Success */ PagedResponseDtoOrderAnalyticsEntryDtoRead;
export type PostAnalyticsV6OrderApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: OrderAnalyticsQueryDto;
};
export type GetAnalyticsV6TotalRevenueApiResponse =
  /** status 200 Success */ TotalRevenueDetailedResponseDto;
export type GetAnalyticsV6TotalRevenueApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetAnalyticsV6ConversionRateApiResponse =
  /** status 200 Success */ ConversionRateDetailedResponseDto;
export type GetAnalyticsV6ConversionRateApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetAnalyticsV6TotalOrdersApiResponse =
  /** status 200 Success */ TotalOrdersDetailedResponseDto;
export type GetAnalyticsV6TotalOrdersApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetAnalyticsV6SessionsApiResponse =
  /** status 200 Success */ SessionsDetailedResponseDto;
export type GetAnalyticsV6SessionsApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type PostAnalyticsV6OrderExportApiResponse =
  /** status 200 Success */ Blob;
export type PostAnalyticsV6OrderExportApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: OrderAnalyticsQueryDto;
};
export type PostAnalyticsV6PromotionCodeExportApiResponse =
  /** status 200 Success */ Blob;
export type PostAnalyticsV6PromotionCodeExportApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: PromotionCodeAnalyticsQueryDto;
};
export type PostAnalyticsV6PromotionActivityExportApiResponse =
  /** status 200 Success */ Blob;
export type PostAnalyticsV6PromotionActivityExportApiArg = {
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
  body: PromotionActivityAnalyticsQueryDto;
};
export type GetAnalyticsV6SessionsExportApiResponse =
  /** status 200 Success */ Blob;
export type GetAnalyticsV6SessionsExportApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetAnalyticsV6TotalOrdersExportApiResponse =
  /** status 200 Success */ Blob;
export type GetAnalyticsV6TotalOrdersExportApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetAnalyticsV6ConversionRateExportApiResponse =
  /** status 200 Success */ Blob;
export type GetAnalyticsV6ConversionRateExportApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type GetAnalyticsV6TotalRevenueExportApiResponse =
  /** status 200 Success */ Blob;
export type GetAnalyticsV6TotalRevenueExportApiArg = {
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto;
  /** Name of the app to authenticate against */
  'X-LimoniApps-AppName': string;
  /** Secret of the app */
  'X-LimoniApps-AppSecret': string;
};
export type DataPointDto = {
  key?: string;
  value?: number;
  trendValue?: number;
};
export type DataSeriesDto = {
  name?: string;
  data?: DataPointDto[];
  isComparison?: boolean;
  totalValue?: number | null;
};
export type AnalyticsDataDto = {
  dataSeries?: DataSeriesDto[];
  totalValue?: number;
  trendValue?: number;
};
export type ConversionRateDataEntryOverviewDto = {
  conversionRate?: number;
  trendValue?: number;
  sessionsCount?: number;
};
export type ConversionRateDataOverviewDto = {
  comparisonValueTotal?: number | null;
  addToCart?: ConversionRateDataEntryOverviewDto;
  checkoutInitiated?: ConversionRateDataEntryOverviewDto;
  sessions?: ConversionRateDataEntryOverviewDto;
};
export type ConversionRateDataOverviewDtoRead = {
  total?: number;
  comparisonValueTotal?: number | null;
  addToCart?: ConversionRateDataEntryOverviewDto;
  checkoutInitiated?: ConversionRateDataEntryOverviewDto;
  sessions?: ConversionRateDataEntryOverviewDto;
};
export type AnalyticsOverviewResponseDto = {
  totalRevenue?: AnalyticsDataDto;
  conversionRate?: ConversionRateDataOverviewDto;
  revenueByPromotion?: AnalyticsDataDto;
  ordersByPromotion?: AnalyticsDataDto;
  totalOrders?: AnalyticsDataDto;
  sessions?: AnalyticsDataDto;
};
export type AnalyticsOverviewResponseDtoRead = {
  totalRevenue?: AnalyticsDataDto;
  conversionRate?: ConversionRateDataOverviewDtoRead;
  revenueByPromotion?: AnalyticsDataDto;
  ordersByPromotion?: AnalyticsDataDto;
  totalOrders?: AnalyticsDataDto;
  sessions?: AnalyticsDataDto;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type AnalyticsQueryPeriodDto =
  | (
      | 'TODAY'
      | 'YESTERDAY'
      | 'LAST_7_DAYS'
      | 'LAST_30_DAYS'
      | 'LAST_90_DAYS'
      | 'LAST_365_DAYS'
      | 'LAST_MONTH'
      | 'LAST_12_MONTHS'
      | 'LAST_YEAR'
    )
  | null;
export type AnalyticsQueryComparisonPeriodDto =
  | ('PREVIOUS_PERIOD' | 'PREVIOUS_YEAR')
  | null;
export type AnalyticsDashboardOverviewResponseDto = {
  totalRevenue?: AnalyticsDataDto;
  revenueByPromotion?: AnalyticsDataDto;
  totalOrders?: AnalyticsDataDto;
};
export type PromotionActivityAnalyticsEntryDto = {
  id?: string;
  promotionName?: string;
  sessions?: number;
  addToCart?: number;
  checkoutInitiated?: number;
  orders?: number;
  revenue?: number;
};
export type PagedResponseDtoPromotionActivityAnalyticsEntryDto = {
  totalItems?: number;
  items?: PromotionActivityAnalyticsEntryDto[];
};
export type SortDirectionDto = ('ASC' | 'DESC') | null;
export type PromotionActivityAnalyticsSortFieldDto =
  | (
      | 'PROMOTION_NAME'
      | 'SESSIONS'
      | 'ADD_TO_CART'
      | 'CHECKOUT_INITIATED'
      | 'ORDERS'
      | 'REVENUE'
    )
  | null;
export type PromotionActivityAnalyticsQueryDto = {
  search?: string | null;
  page?: number;
  itemsPerPage?: number | null;
  sortDirection?: SortDirectionDto | null;
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto | null;
  sortBy?: PromotionActivityAnalyticsSortFieldDto | null;
};
export type PromotionCodeStatusDto = ('ACTIVE' | 'USED') | null;
export type PromotionCodeAnalyticsEntryDto = {
  id?: string;
  promotionCode?: string;
  promotionsAppliedTo?: string;
  status?: PromotionCodeStatusDto | null;
  usageCount?: number;
  limit?: number;
};
export type PagedResponseDtoPromotionCodeAnalyticsEntryDto = {
  totalItems?: number;
  items?: PromotionCodeAnalyticsEntryDto[];
};
export type PromotionCodeAnalyticsSortFieldDto =
  | (
      | 'PROMOTION_CODE'
      | 'PROMOTIONS_APPLIED'
      | 'STATUS'
      | 'USAGE_COUNT'
      | 'LIMIT'
    )
  | null;
export type PromotionCodeAnalyticsQueryDto = {
  search?: string | null;
  page?: number;
  itemsPerPage?: number | null;
  sortDirection?: SortDirectionDto | null;
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto | null;
  sortBy?: PromotionCodeAnalyticsSortFieldDto | null;
};
export type OrderAnalyticsEntryDto = {
  order?: string;
  date?: number;
  customer?: string;
  orderValue?: number;
  promotionCodes?: string[];
  promotionsApplied?: string[];
};
export type OrderAnalyticsEntryDtoRead = {
  id?: string;
  order?: string;
  date?: number;
  customer?: string;
  orderValue?: number;
  promotionCodes?: string[];
  promotionsApplied?: string[];
};
export type PagedResponseDtoOrderAnalyticsEntryDto = {
  totalItems?: number;
  items?: OrderAnalyticsEntryDto[];
};
export type PagedResponseDtoOrderAnalyticsEntryDtoRead = {
  totalItems?: number;
  items?: OrderAnalyticsEntryDtoRead[];
};
export type OrderAnalyticsSortFieldDto =
  | (
      | 'ORDER'
      | 'DATE'
      | 'CUSTOMER'
      | 'ORDER_VALUE'
      | 'PROMOTION_CODES'
      | 'PROMOTIONS_APPLIED'
    )
  | null;
export type OrderAnalyticsQueryDto = {
  search?: string | null;
  page?: number;
  itemsPerPage?: number | null;
  sortDirection?: SortDirectionDto | null;
  period?: AnalyticsQueryPeriodDto | null;
  comparisonPeriod?: AnalyticsQueryComparisonPeriodDto | null;
  sortBy?: OrderAnalyticsSortFieldDto | null;
};
export type TotalRevenueDataPointDto = {
  key?: string;
  value?: number;
  trendValue?: number;
  orders?: number;
};
export type TotalRevenueDataSeriesDto = {
  name?: string;
  data?: TotalRevenueDataPointDto[];
  isComparison?: boolean;
  totalValue?: number | null;
};
export type CompareableTotalValueDto = {
  current?: number;
  compared?: number | null;
};
export type TotalRevenueDetailedResponseDto = {
  dataSeries?: TotalRevenueDataSeriesDto[];
  ordersTotal?: CompareableTotalValueDto;
  revenueTotal?: CompareableTotalValueDto;
};
export type ConversionRateDataPointDto = {
  key?: string;
  value?: number;
  trendValue?: number;
  sessions?: number;
  addToCart?: number;
  checkoutInitiated?: number;
  orders?: number;
};
export type ConversionRateDataSeriesDto = {
  name?: string;
  data?: ConversionRateDataPointDto[];
  isComparison?: boolean;
  totalValue?: number | null;
};
export type ConversionRateDetailedResponseDto = {
  dataSeries?: ConversionRateDataSeriesDto[];
  sessionsTotal?: CompareableTotalValueDto;
  addToCartTotal?: CompareableTotalValueDto;
  checkoutInitiatedTotal?: CompareableTotalValueDto;
  ordersTotal?: CompareableTotalValueDto;
};
export type TotalOrdersDetailedResponseDto = {
  dataSeries?: DataSeriesDto[];
  ordersTotal?: CompareableTotalValueDto;
};
export type SessionsDetailedResponseDto = {
  dataSeries?: DataSeriesDto[];
  sessionsTotal?: CompareableTotalValueDto;
};
export const {
  useGetAnalyticsV6OverviewQuery,
  useGetAnalyticsV6DashboardOverviewQuery,
  usePostAnalyticsV6PromotionActivityMutation,
  usePostAnalyticsV6PromotionCodeMutation,
  usePostAnalyticsV6OrderMutation,
  useGetAnalyticsV6TotalRevenueQuery,
  useGetAnalyticsV6ConversionRateQuery,
  useGetAnalyticsV6TotalOrdersQuery,
  useGetAnalyticsV6SessionsQuery,
  usePostAnalyticsV6OrderExportMutation,
  usePostAnalyticsV6PromotionCodeExportMutation,
  usePostAnalyticsV6PromotionActivityExportMutation,
  useGetAnalyticsV6SessionsExportQuery,
  useGetAnalyticsV6TotalOrdersExportQuery,
  useGetAnalyticsV6ConversionRateExportQuery,
  useGetAnalyticsV6TotalRevenueExportQuery,
} = injectedRtkApi;
