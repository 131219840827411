import React, { useCallback, useMemo } from 'react';
import {
  MessageDto,
  NotificationCollapsedStateHeaderPresetDto,
  NotificationExpandedStateHeaderPresetDto,
  OptionDtoIconComponentDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import {
  ShopSettingsWrapper,
  TextEntries,
  TextStyle,
} from '../../../../../components';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { Icon } from '../../../../../components/Icon/Icon';
import { useI18n } from '@shopify/react-i18n';
import { useAppDispatch } from 'core/hooks';
import {
  setNotificationHeaderMessages,
  setToggleSettingsConfig,
} from 'core/store/widgetsSlice';
import {
  NotificationStatesPromotionEntryTypeDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { isEqual } from 'lodash';
import { WidgetListOptionsDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { Box, Link, Text } from '@shopify/polaris';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';
type StateHeaderPresetDto = NotificationCollapsedStateHeaderPresetDto &
  NotificationExpandedStateHeaderPresetDto;
type NotificationHeaderProps = {
  header: StateHeaderPresetDto;
  defaultHeader: StateHeaderPresetDto;
  shopDefaultHeader: StateHeaderPresetDto;
  defaultLanguage?: string;
  isPresetEditor?: boolean;
  offerId?: string;
  notificationState: NotificationStatesPromotionEntryTypeDtoEnum;
  setHeader: (data: StateHeaderPresetDto) => void;
  onShowHide?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};

const NotificationHeader: React.FC<NotificationHeaderProps> = ({
  header,
  defaultHeader,
  shopDefaultHeader,
  defaultLanguage,
  isPresetEditor,
  notificationState,
  offerId,
  setHeader,
  onShowHide,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const isCollapsed = useMemo(
    () =>
      notificationState ===
      NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE,
    [notificationState]
  );

  const handleUpdateHeader = useCallback(
    (
      field: keyof StateHeaderPresetDto,
      data: StateHeaderPresetDto[keyof StateHeaderPresetDto]
    ) => {
      setHeader({
        ...header,
        [field]: data,
      });
    },
    [header]
  );

  const handleHeadlineText = useCallback(
    (field: keyof MessageDto, data: MessageDto[keyof MessageDto]) =>
      handleUpdateHeader('headlineText', {
        ...header.headlineText,
        value: { ...header.headlineText?.value, [field]: data },
      }),
    [header, handleUpdateHeader]
  );

  const handleExpandButton = useCallback(
    (
      field: keyof OptionDtoIconComponentDto,
      data: OptionDtoIconComponentDto[keyof OptionDtoIconComponentDto]
    ) =>
      handleUpdateHeader('icon', {
        ...header.icon,
        [field]: data,
      }),
    [header, handleUpdateHeader]
  );

  const resetAllToSystemDefault = useCallback(() => {
    const { headlineText, icon } = shopDefaultHeader;
    setHeader({
      ...header,
      icon,
      headlineText,
    });
  }, [shopDefaultHeader, header]);

  return (
    <div className='NotificationHeader'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onDiscardChanges={() => setHeader(defaultHeader)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListOptionsDtoEnum.HEADER}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {isCollapsed && (
        <div className='RightSideSection'>
          <Icon
            icon={header.icon?.value || {}}
            enabled={header.icon?.enabled}
            enabledText={i18n.translate('EnableIcon')}
            setEnabled={(value) => handleExpandButton('enabled', value)}
            title={i18n.translate('Icon')}
            inherited={
              !isPresetEditor
                ? isEqual(header.icon, defaultHeader.icon)
                : undefined
            }
            showIcon
            groupKey={ResourceGroupKeyDtoEnum.OFFER}
            setIcon={(data) => handleExpandButton('value', data)}
            onSystemReset={() =>
              handleUpdateHeader('icon', shopDefaultHeader.icon)
            }
          />
        </div>
      )}

      <div className='RightSideSection'>
        <TextEntries
          textMessage={header.headlineText?.value?.message || {}}
          inherited={
            !isPresetEditor
              ? isEqual(header.headlineText, defaultHeader.headlineText) &&
                header.sameAsExpandedState === defaultHeader.sameAsExpandedState
              : undefined
          }
          sameAsExpanded={
            isCollapsed ? !!header.sameAsExpandedState : undefined
          }
          setSameAsExpanded={(value) =>
            handleUpdateHeader('sameAsExpandedState', value)
          }
          offerId={offerId}
          defaultLanguage={defaultLanguage || ''}
          enableText={i18n.translate('EnableHeadlineText')}
          enabled={header.headlineText?.enabled}
          title={i18n.translate('HeadlineText')}
          disableColor={header.headlineText?.value?.style?.color !== undefined}
          disableGoal
          disableCollapsed={isCollapsed}
          setEnabled={(value) =>
            handleUpdateHeader('headlineText', {
              ...header.headlineText,
              enabled: value,
            })
          }
          setTextMessage={(data) => handleHeadlineText('message', data)}
          onSystemReset={() =>
            handleHeadlineText(
              'message',
              shopDefaultHeader?.headlineText?.value?.message
            )
          }
          setPreviewEntry={(value) =>
            dispatch(
              setNotificationHeaderMessages({
                pageType: notificationState,
                data: value,
              })
            )
          }
        />
        {((isCollapsed && !header.sameAsExpandedState) ||
          (!isCollapsed && header.headlineText?.enabled)) && (
          <TextStyle
            textStyle={header.headlineText?.value?.style || {}}
            hideHeader
            setTextStyle={(data) => handleHeadlineText('style', data)}
            onSystemReset={() =>
              handleHeadlineText(
                'style',
                shopDefaultHeader.headlineText?.value?.style
              )
            }
          />
        )}
      </div>
      {isCollapsed && (
        <div className='RightSideSection'>
          <Box padding='500'>
            <Text as='p' tone='subdued'>
              {i18n.translate('InheritedFromExpanded', {
                expandedState: <Link>{i18n.translate('ExpandedState')}</Link>,
              })}
            </Text>
          </Box>
        </div>
      )}
      {!isCollapsed && (
        <ShopSettingsWrapper
          onOpen={() =>
            dispatch(
              setToggleSettingsConfig({
                action: 'open',
                path: SettingsModalPathsEnums.Notification,
                element: 'IconSetting',
              })
            )
          }
        >
          <div className='RightSideSection'>
            <Icon
              groupKey={ResourceGroupKeyDtoEnum.CHEVRONS}
              showIcon
              onlyColor
              icon={header.shopSettings?.minimizingIcon?.value || {}}
              title={i18n.translate('MinimizingIcon')}
              inherited={
                header.shopSettings?.minimizingIcon?.value?.color ===
                defaultHeader.shopSettings?.minimizingIcon?.value?.color
              }
              setIcon={(data) =>
                handleUpdateHeader('shopSettings', {
                  ...header.shopSettings,
                  minimizingIcon: {
                    ...header.shopSettings?.minimizingIcon,
                    value: data,
                  },
                })
              }
              onSystemReset={() =>
                handleUpdateHeader('shopSettings', {
                  ...header.shopSettings,
                  minimizingIcon: {
                    ...header.shopSettings?.minimizingIcon,
                    value: {
                      ...header.shopSettings?.minimizingIcon?.value,
                      color:
                        shopDefaultHeader.shopSettings?.minimizingIcon?.value
                          ?.color,
                    },
                  },
                })
              }
            />
          </div>
        </ShopSettingsWrapper>
      )}
    </div>
  );
};

export default NotificationHeader;
