import React, { useCallback, useMemo } from 'react';
import './NotificationGeneral.scss';
import {
  Background,
  CornerRadius,
  CustomCSS,
  DeviceDisplay,
  MessageCount,
  Offset,
  Order,
  PageDisplay,
  Position,
  Shadow,
  ShopSettingsWrapper,
  Size,
} from '../../../../../components';
import {
  DeviceDisplayDto,
  NotificationExpandedStateGeneralPresetDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { WidgetOptionHeader } from '../../../../../components/WidgetOptionHeader/WidgetOptionHeader';
import { WidgetListTypeDtoEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { useI18n } from '@shopify/react-i18n';
import MinimizingNotification from './components/MinimizingNotification/MinimizingNotification';
import NotificationAnimations from './components/NotificationAnimations/NotificationAnimations';
import { isEqual } from 'lodash';
import { useAppDispatch } from 'core/hooks';
import { setToggleSettingsConfig } from 'core/store/widgetsSlice';
import { NotificationStatesPromotionEntryTypeDtoEnum } from 'core/api/adminWidgets/adminWidgetsEnums';
import { Box, Link, Text } from '@shopify/polaris';
import { SettingsModalPathsEnums } from 'pages/enums/PagesEnums';

type NotificationGeneralProps = {
  general: NotificationExpandedStateGeneralPresetDto;
  defaultGeneral: NotificationExpandedStateGeneralPresetDto;
  shopDefaultGeneral: NotificationExpandedStateGeneralPresetDto;
  isPresetEditor?: boolean;
  presetSetup?: NotificationExpandedStateGeneralPresetDto;
  notificationState: NotificationStatesPromotionEntryTypeDtoEnum;
  setGeneral: (data: NotificationExpandedStateGeneralPresetDto) => void;
  onShowHide?: () => void;
  onRemove?: () => void;
  handleLeftMenuSelection: (openWidget?: boolean) => void;
};
const NotificationGeneral: React.FC<NotificationGeneralProps> = ({
  general,
  defaultGeneral,
  shopDefaultGeneral,
  isPresetEditor,
  presetSetup,
  notificationState,
  setGeneral,
  onShowHide,
  onRemove,
  handleLeftMenuSelection,
}) => {
  const [i18n] = useI18n();
  const dispatch = useAppDispatch();

  const isCollapsed = useMemo(
    () =>
      notificationState ===
      NotificationStatesPromotionEntryTypeDtoEnum.COLLAPSED_STATE,
    [notificationState]
  );

  const resetAllToSystemDefault = useCallback(() => {
    const { stylePriority, size, background, cornerRadius, shadow } =
      shopDefaultGeneral;
    setGeneral({
      ...general,
      ...(!isPresetEditor && { stylePriority }),
      size,
      cornerRadius,
      background,
      shadow,
      customCss: defaultGeneral.customCss,
    });
  }, [isPresetEditor, shopDefaultGeneral]);

  const updateNotification = useCallback(
    (
      field: keyof NotificationExpandedStateGeneralPresetDto,
      data: NotificationExpandedStateGeneralPresetDto[keyof NotificationExpandedStateGeneralPresetDto]
    ) => {
      const { background, cornerRadius, shadow, size } = presetSetup || {};
      setGeneral({
        ...general,
        ...(!isPresetEditor && { background, cornerRadius, shadow, size }),
        [field]: data,
      });
    },
    [general, presetSetup]
  );

  return (
    <div className='NotificationGeneral'>
      <WidgetOptionHeader
        onShowHide={onShowHide}
        onRemove={onRemove}
        onDiscardChanges={() => setGeneral(defaultGeneral)}
        onResetToSystem={resetAllToSystemDefault}
        name={WidgetListTypeDtoEnum.NOTIFICATION}
        handleLeftMenuSelection={handleLeftMenuSelection}
      />
      {!isCollapsed && (
        <>
          {!isPresetEditor && (
            <div className='RightSideSection'>
              <Order
                title={i18n.translate('StylePriority')}
                order={general.stylePriority || {}}
                onSystemReset={() =>
                  updateNotification(
                    'stylePriority',
                    shopDefaultGeneral.stylePriority
                  )
                }
                setOrder={(data) => updateNotification('stylePriority', data)}
              />
            </div>
          )}
          <div className='RightSideSection'>
            <Size
              inherited={
                !isPresetEditor
                  ? isEqual(presetSetup?.size, defaultGeneral.size)
                  : undefined
              }
              size={(!isPresetEditor ? presetSetup?.size : general.size) || {}}
              setSize={(data) => updateNotification('size', data)}
              disableWidth
              onSystemReset={() =>
                updateNotification('size', shopDefaultGeneral.size)
              }
            />
          </div>
          <div className='RightSideSection'>
            <Background
              inherited={
                !isPresetEditor
                  ? isEqual(presetSetup?.background, defaultGeneral.background)
                  : undefined
              }
              background={
                (!isPresetEditor
                  ? presetSetup?.background
                  : general.background) || {}
              }
              allowIndicatorLine
              setBackground={(data) => updateNotification('background', data)}
              onSystemReset={() =>
                updateNotification('background', shopDefaultGeneral.background)
              }
            />
          </div>
          <div className='RightSideSection'>
            <CornerRadius
              cornerRadius={
                (!isPresetEditor
                  ? presetSetup?.cornerRadius
                  : general.cornerRadius) || {}
              }
              inherited={
                !isPresetEditor
                  ? isEqual(
                      presetSetup?.cornerRadius,
                      defaultGeneral.cornerRadius
                    )
                  : undefined
              }
              setCornerRadius={(data) =>
                updateNotification('cornerRadius', data)
              }
              onSystemReset={() =>
                updateNotification(
                  'cornerRadius',
                  shopDefaultGeneral.cornerRadius
                )
              }
            />
          </div>
          <div className='RightSideSection'>
            <Shadow
              shadow={
                (!isPresetEditor ? presetSetup?.shadow : general.shadow) || {}
              }
              inherited={
                !isPresetEditor
                  ? isEqual(presetSetup?.shadow, defaultGeneral.shadow)
                  : undefined
              }
              setShadow={(data) => updateNotification('shadow', data)}
              onSystemReset={() =>
                updateNotification('shadow', shopDefaultGeneral.shadow)
              }
            />
          </div>
        </>
      )}

      <div className='RightSideSection'>
        <CustomCSS
          css={general.customCss || ''}
          preview={!isPresetEditor ? (presetSetup?.customCss as string) : ''}
          setCSS={(value) => updateNotification('customCss', value)}
          note={i18n.translate('WillBeInherited')}
        />
      </div>

      {isCollapsed && (
        <div className='RightSideSection'>
          <Box padding='500'>
            <Text as='p' tone='subdued'>
              {i18n.translate('InheritedFromExpanded', {
                expandedState: <Link>{i18n.translate('ExpandedState')}</Link>,
              })}
            </Text>
          </Box>
        </div>
      )}

      {!isCollapsed && (
        <ShopSettingsWrapper
          onOpen={() =>
            dispatch(
              setToggleSettingsConfig({
                action: 'open',
                path: SettingsModalPathsEnums.Notification,
              })
            )
          }
        >
          <div className='RightSideSection'>
            <DeviceDisplay
              deviceDisplay={
                general.shopSettings?.deviceDisplay as DeviceDisplayDto
              }
              disabled
            />
          </div>
          <div className='RightSideSection'>
            <PageDisplay
              pageDisplay={general.shopSettings?.pageDisplay || {}}
              disabled
            />
          </div>
          <div className='RightSideSection'>
            <Position
              position={general.shopSettings?.pagePosition || {}}
              disabled
            />
          </div>
          <div className='RightSideSection'>
            <Offset
              offset={general.shopSettings?.offset || {}}
              disabled
              supportCustom={true}
            />
          </div>
          <div className='RightSideSection'>
            <MinimizingNotification
              minimizing={general.shopSettings?.minimizingBehavior || {}}
              disabled
            />
          </div>
          <div className='RightSideSection'>
            <NotificationAnimations
              animations={general.shopSettings?.animations || {}}
              disabled
            />
          </div>
          <div className='RightSideSection'>
            <MessageCount
              messageCount={general.shopSettings?.messageCount || {}}
              disabled
              setMessageCount={(data) =>
                updateNotification('shopSettings', {
                  ...general.shopSettings,
                  messageCount: data,
                })
              }
              onSystemReset={() =>
                updateNotification('shopSettings', {
                  ...general.shopSettings,
                  messageCount: defaultGeneral.shopSettings?.messageCount,
                })
              }
            />
          </div>
        </ShopSettingsWrapper>
      )}
    </div>
  );
};

export default NotificationGeneral;
