import React from 'react';
import {
  BlockStack,
  Box,
  Card,
  InlineStack,
  Page,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { CustomShippingRateList } from './components/CustomShippingRateList/CustomShippingRateList';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import { Loader } from 'core/components';

export const CustomShippingRates: React.FC = () => {
  const [i18n] = useI18n();
  const { shippingRatesData, shippingRatesIsFetching } = useConfigureSettings(
    SettingsFetchTypeEnum.CUSTOM_SHIPPING_RATES
  );

  return (
    <>
      {shippingRatesData ? (
        <Page title={i18n.translate(`CustomShippingRates`)}>
          <Card>
            <BlockStack gap='400'>
              <InlineStack align='space-between'>
                <BlockStack gap='100'>
                  <Text as='h2' variant='headingSm'>
                    {i18n.translate('CustomShippingRates')}
                  </Text>
                  <Text as='p' tone='subdued'>
                    {i18n.translate('CustomShippingRatesSubtitle')}
                  </Text>
                </BlockStack>
                {shippingRatesIsFetching && (
                  <Box>
                    <Loader size='small' />
                  </Box>
                )}
              </InlineStack>
              <CustomShippingRateList
                shippingRatesData={shippingRatesData}
                shippingRatesIsFetching={shippingRatesIsFetching}
              />
            </BlockStack>
          </Card>
        </Page>
      ) : (
        <SettingsSkeleton cardsCount={1} />
      )}
    </>
  );
};
