import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  BlockStack,
  InlineStack,
  Badge,
  Banner,
  Text,
  Button,
  TextField,
  ButtonGroup,
  Icon,
} from '@shopify/polaris';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { OptionDtoOfferMinimumSpendDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { usePrevious } from 'core/hooks/usePrevious';
import { isEmpty, isEqual } from 'lodash';
import { getCurrencySymbol } from 'core/utils';

type MinimumSpendProps = {
  minimumSpend: OptionDtoOfferMinimumSpendDto;
  minimumSpendUpdate: (minimumSpend: OptionDtoOfferMinimumSpendDto) => void;
};

const minimumSpendInitialValues: OptionDtoOfferMinimumSpendDto = {
  enabled: false,
  value: {
    value: 100,
    applyAfterDiscounts: false,
  },
};

const types = [
  { key: 'BEFORE', value: false },
  { key: 'AFTER', value: true },
];

export const MinimumSpend: FC<MinimumSpendProps> = ({
  minimumSpend,
  minimumSpendUpdate,
}) => {
  const [i18n] = useI18n();

  const initialExceptionState = useMemo(
    () => minimumSpend || minimumSpendInitialValues,
    [minimumSpendInitialValues, minimumSpend]
  );

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [minimumSpendState, setMinimumSpendState] =
    useState<OptionDtoOfferMinimumSpendDto>(initialExceptionState);
  const prevMinimumSpendState = usePrevious(minimumSpendState);
  const [initialValue] = useState<OptionDtoOfferMinimumSpendDto | undefined>(
    minimumSpendState
  );
  useEffect(() => {
    if (
      !isEmpty(minimumSpendState) &&
      !isEmpty(prevMinimumSpendState) &&
      !isEqual(minimumSpendState, prevMinimumSpendState)
    ) {
      setIsChanged(true);
    }
  }, [minimumSpendState, prevMinimumSpendState, setIsChanged]);

  useEffect(() => {
    if (isChanged) {
      minimumSpendUpdate({
        ...minimumSpendState,
      });
      setIsChanged(false);
    }
  }, [
    minimumSpendState,
    isChanged,
    setIsChanged,
    minimumSpendUpdate,
    initialValue,
  ]);

  const handleUpdateValue = useCallback(
    (value: number) => {
      setMinimumSpendState({
        ...minimumSpend,
        value: {
          ...minimumSpend?.value,
          value: value,
        },
      } as OptionDtoOfferMinimumSpendDto);
    },
    [minimumSpend?.enabled, minimumSpend?.value]
  );

  const handleUpdateStatus = useCallback(
    (value: boolean) => {
      setMinimumSpendState({
        ...minimumSpend,
        enabled: value,
      } as OptionDtoOfferMinimumSpendDto);
    },
    [minimumSpend?.enabled, minimumSpend?.value]
  );
  const handleUpdateDiscount = useCallback(
    (value: boolean) => {
      setMinimumSpendState({
        ...minimumSpend,
        value: { ...minimumSpend?.value, applyAfterDiscounts: value },
      } as OptionDtoOfferMinimumSpendDto);
    },
    [minimumSpend?.enabled, minimumSpend?.value]
  );

  return (
    <>
      <Card padding='400' roundedAbove='sm'>
        <BlockStack gap='400'>
          <BlockStack gap='100'>
            <InlineStack align='space-between' blockAlign='center'>
              <InlineStack gap='100'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate(`Title`)}
                </Text>
                <Badge
                  tone={minimumSpendState?.enabled ? 'success' : 'enabled'}
                >
                  {i18n.translate(minimumSpendState?.enabled ? 'On' : 'Off')}
                </Badge>
              </InlineStack>
              <Button
                onClick={() => handleUpdateStatus(!minimumSpendState?.enabled)}
              >
                {i18n.translate(
                  minimumSpendState?.enabled ? 'TurnOff' : 'TurnOn'
                )}
              </Button>
            </InlineStack>
            <Text as='span' tone='subdued'>
              {i18n.translate(`Subtitle`)}
            </Text>
          </BlockStack>

          {minimumSpendState?.enabled && (
            <>
              <Banner tone='warning'>{i18n.translate('Banner')}</Banner>
              <InlineStack gap='200' blockAlign='center'>
                <Text as='p'>{i18n.translate('RequireAtLeast')}</Text>
                <TextField
                  autoComplete='off'
                  value={minimumSpendState?.value?.value?.toString()}
                  onChange={(value) => handleUpdateValue(Number(value))}
                  type='number'
                  min={'1'}
                  autoSize
                  label=''
                  labelHidden
                  prefix={getCurrencySymbol()}
                />
                <Text as='p'>{i18n.translate('SpentAtCheckout')}</Text>
                <ButtonGroup variant='segmented'>
                  {types.map((item) => (
                    <Button
                      key={item.key}
                      pressed={
                        item.value ===
                        minimumSpendState.value?.applyAfterDiscounts
                      }
                      onClick={() => handleUpdateDiscount(item.value)}
                    >
                      {i18n.translate(item.key)}
                    </Button>
                  ))}
                </ButtonGroup>
                <span>
                  <Icon source={QuestionCircleIcon} />
                </span>
              </InlineStack>
            </>
          )}
        </BlockStack>
      </Card>
    </>
  );
};
