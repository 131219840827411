import React from 'react';
import {
  BlockStack,
  Button,
  Card,
  Icon,
  InlineStack,
  Text,
  Tooltip,
} from '@shopify/polaris';
import { SearchResourceIcon, InfoIcon } from '@shopify/polaris-icons';
import { ChartState, DataSeries, SimpleBarChart } from '@shopify/polaris-viz';
import '@shopify/polaris-viz/build/esm/styles.css';
import { AnalyticsDataDto } from 'core/api/adminAnalytics/adminAnalyticsApi';
import { ChartuUnitOfMeasurementEnum } from 'pages';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';

type AnalyticsBarChartProps = {
  analyticsData: AnalyticsDataDto;
  title: string;
  tooltip?: string;
  isLoading?: boolean;
  unit: ChartuUnitOfMeasurementEnum;
  onNavigate?: () => void;
};
export const AnalyticsBarChart: React.FC<AnalyticsBarChartProps> = ({
  analyticsData,
  title,
  tooltip,
  isLoading,
  unit,
  onNavigate,
}) => {
  const { formatValue } = useNumericFormatter();
  return (
    <Card>
      <BlockStack gap='300'>
        <InlineStack align='space-between' blockAlign='center'>
          <InlineStack gap='200'>
            <Text tone='base' as='h1' fontWeight='medium'>
              {title}
            </Text>
            {tooltip && (
              <Tooltip content={tooltip}>
                <Icon tone='base' source={InfoIcon} />
              </Tooltip>
            )}
          </InlineStack>
          {onNavigate && (
            <Button
              onClick={onNavigate}
              icon={SearchResourceIcon}
              variant='tertiary'
            />
          )}
        </InlineStack>
        <div style={{ height: 300 }}>
          <SimpleBarChart
            state={(isLoading ? 'Loading' : 'Success') as ChartState}
            xAxisOptions={{
              labelFormatter: (value) => formatValue(value as number, unit),
            }}
            data={(analyticsData.dataSeries as DataSeries[]) || []}
            showLegend={false}
          />
        </div>
      </BlockStack>
    </Card>
  );
};
