import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BlockStack, Box, Card, Link, Modal, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { CreatePromotionModal } from 'features/promotions/components/CreatePromotionModal/CreatePromotionModal';
import { useDashboard } from 'core/hooks/useDashboard';
import { SetupGuide } from './components/SetupGuide/SetupGuide';
import {
  dismissComponent,
  DismissedEnum,
  getDismissedComponents,
  removeDismissedComponent,
} from './utils/utils';
import { useSearchParams } from 'react-router-dom';
import TogglePromotionEngineModal from 'features/settings/components/GeneralSettings/ManageWidgets/components/TogglePromotionEngineModal/TogglePromotionEngineModal';
import { CreateTestPromotion } from './components/CreateTestPromotion/CreateTestPromotion';
import { DiscountNinjaTutorial } from './components/DiscountNinjaTutorial/DiscountNinjaTutorial';
import { CardsSkeleton } from 'core/components';
import { WarningAlertBanner } from './components/WarningAlertBanner/WarningAlertBanner';
import { SubscriptionPlanBanner } from './components/SubscriptionPlanBanner/SubscriptionPlanBanner';
import { ChangePlanConfigDto } from 'features/settings/components/GeneralSettings/Plan/Plan';
import { ChangePlanModal } from 'features/settings/components/GeneralSettings/Plan/components/ChangePlanModal/ChangePlanModal';
import { ThresholdUsage } from 'features/settings/components/GeneralSettings/Plan/components/UserSubscriptionPlan/components/ThresholdUsage/ThresholdUsage';
import {
  WidgetStatusDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import RequestWidgetSupport from 'features/settings/components/GeneralSettings/ManageWidgets/components/WidgetSettings/components/WidgetSettingsWrapper/components/ToggleWidgetModal/components/RequestWidgetSupport/RequestWidgetSupport';
import { ThreeCardsLayout } from './components/ThreeCardsLayout/ThreeCardsLayout';
import { ExploreCard } from './components/Explore/ExploreCard';
import { PromotionInsights } from './components/PromotionInsights/PromotionInsights';
import { AnalyticsQueryPeriodDto } from 'core/api/adminAnalytics/adminAnalyticsApi';
import { AnalyticsQueryPeriodDtoEnum } from 'core/api/adminAnalytics/adminAnalyticsEnums';
import { NavPaths } from 'core/enums/NavPathsEnum';
import {
  SettingsModalPathsEnums,
  SettingsModalSubPathsEnums,
} from 'pages/enums/PagesEnums';

export type StepProp = {
  id: number;
  title: string;
  description: ReactElement<any, string | JSXElementConstructor<any>>;
  completed: boolean;
  primaryButton: string;
  primaryButtonDisabled?: boolean;
  secondaryButton?: string;
  banner?: string;
  imageUrl?: string | undefined;
  action: () => void;
};

export const Dashboard = () => {
  const [i18n] = useI18n();

  const [currentPeriod, setCurrentPeriod] = useState<AnalyticsQueryPeriodDto>(
    AnalyticsQueryPeriodDtoEnum.LAST_365_DAYS
  );

  const {
    dashBoardData,
    currentPlanData,
    currentPlanDataIsFetching,
    currentPlanDataIsLoading,
    dashBoardDataIsLoading,
    dashBoardDataIsFetching,
    turnWidgetOnManuallyIsLoading,
    turnWidgetOnManuallyIsSuccess,
    promotionInsightsData,
    promotionInsightsDataIsFetching,
    promotionInsightsDataIsLoading,
    refetch,
    turnOnWidget,
  } = useDashboard(currentPeriod);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isEngineModalOpen, setIsEngineModalOpen] = useState<boolean>(false);
  const [checkConfiguration, setCheckConfiguration] = useState<boolean>(false);
  const [steps, setSteps] = useState<StepProp[]>([]);
  const [, setSearchParams] = useSearchParams();
  const [dismissedComponents, setDismissedComponents] = useState<string[]>(
    getDismissedComponents(DismissedEnum.HOME_PAGE_DISMISSED_COMPONENTS)
  );
  const [creatingTestPromotion, setCreatingTestPromotion] =
    useState<boolean>(false);
  const [changePlanConfig, setChangePlanConfig] =
    useState<ChangePlanConfigDto | null>(null);
  const [installationModalOpen, setInstallationModalOpen] =
    useState<boolean>(false);
  const [isWidgetRequestSent, setIsWidgetRequestSent] =
    useState<boolean>(false);

  const loading = useMemo(
    () =>
      dashBoardDataIsLoading ||
      dashBoardDataIsFetching ||
      turnWidgetOnManuallyIsLoading,
    [
      dashBoardDataIsFetching,
      dashBoardDataIsLoading,
      turnWidgetOnManuallyIsLoading,
    ]
  );

  const toggleCreatePromotionModal = useCallback(
    () => setIsCreateModalOpen((prev) => !prev),
    []
  );

  const toggleWidgetInstallation = useCallback(
    () => setInstallationModalOpen((prev) => !prev),
    []
  );

  const handleDismissComponent = useCallback(
    (componentId: string, appVersion?: string) => {
      dismissComponent(
        DismissedEnum.HOME_PAGE_DISMISSED_COMPONENTS,
        componentId,
        appVersion
      );
      setDismissedComponents((prev) => [...prev, componentId]);
    },
    []
  );

  const handleRemoveDismissedComponent = useCallback((componentId: string) => {
    removeDismissedComponent(
      DismissedEnum.HOME_PAGE_DISMISSED_COMPONENTS,
      componentId
    );
    setDismissedComponents((prev) => prev.filter((id) => id !== componentId));
  }, []);

  const isComponentDismissed = useCallback(
    (componentId: string) => dismissedComponents.includes(componentId),
    [dismissedComponents]
  );

  const handleOpenPlans = useCallback(() => {
    setSearchParams({
      modal: NavPaths.SettingsModal,
      path: SettingsModalPathsEnums.Plan,
      subPath: SettingsModalSubPathsEnums.PickYourPlan,
    });
  }, []);

  const toggleEngineModal = useCallback(
    () => setIsEngineModalOpen((prev) => !prev),
    []
  );

  useEffect(() => {
    if (dashBoardData && !loading) {
      const { supportRequestStatus } = dashBoardData;
      const { alreadySubmitted, submissionContainsStrikethroughPricing } =
        supportRequestStatus || {};
      const showBanner =
        (alreadySubmitted && submissionContainsStrikethroughPricing) ||
        isWidgetRequestSent;
      setSteps([
        {
          id: 0,
          title: i18n.translate('SetupGuide.PromotionEngineTitle'),
          description: i18n.translate('SetupGuide.PromotionEngineDescription', {
            link: <Link url='/'>{i18n.translate('SetupGuide.LearnMore')}</Link>,
          }),
          completed: !!dashBoardData.setupStatus?.promotionEngine?.enabled,
          primaryButton: i18n.translate('SetupGuide.TurnOn'),
          imageUrl: dashBoardData.setupStatus?.promotionEngine?.imageUrl,
          action: toggleEngineModal,
        },
        {
          id: 1,
          title: i18n.translate('SetupGuide.NinjaCartTitle'),
          description: i18n.translate('SetupGuide.NinjaCartDescription', {
            link: <Link url='/'>{i18n.translate('SetupGuide.LearnMore')}</Link>,
          }),
          completed:
            turnWidgetOnManuallyIsSuccess ||
            !!dashBoardData.setupStatus?.ninjaCart?.enabled,
          primaryButton: i18n.translate('SetupGuide.TurnOn'),
          primaryButtonDisabled:
            !dashBoardData.setupStatus?.promotionEngine?.enabled,
          imageUrl: dashBoardData.setupStatus?.ninjaCart?.imageUrl,
          action: () =>
            turnOnWidget({
              type: WidgetTypeDtoEnum.NINJA_CART,
              status: WidgetStatusDtoEnum.ENABLED,
            }),
        },
        {
          id: 2,
          title: i18n.translate('SetupGuide.StrikethroughPricingTitle'),
          description: i18n.translate(
            'SetupGuide.StrikethroughPricingDescription',
            {
              link: (
                <Link url='/'>{i18n.translate('SetupGuide.LearnMore')}</Link>
              ),
            }
          ),
          completed:
            !!dashBoardData.setupStatus?.strikethroughPricing?.isCompleted,
          primaryButton: i18n.translate('SetupGuide.RequestSupport'),
          // secondaryButton: i18n.translate('SetupGuide.TurnOn'),
          ...(showBanner && {
            banner: i18n.translate('SetupGuide.StrikethroughPricingBanner'),
          }),
          imageUrl: dashBoardData.setupStatus?.strikethroughPricing?.imageUrl,
          action: toggleWidgetInstallation,
        },
        {
          id: 3,
          title: i18n.translate('SetupGuide.SubscriptionPlanTitle'),
          description: dashBoardData.setupStatus?.subscriptionPlan
            ?.freeTrialDays
            ? i18n.translate('SetupGuide.SubscriptionPlanDescription', {
                quantity:
                  dashBoardData.setupStatus?.subscriptionPlan?.freeTrialDays,
                link: (
                  <Link url='/'>{i18n.translate('SetupGuide.LearnMore')}</Link>
                ),
              })
            : i18n.translate(
                'SetupGuide.NoFreeTrialDaysSubscriptionPlanDescription',
                {
                  link: (
                    <Link url='/'>
                      {i18n.translate('SetupGuide.LearnMore')}
                    </Link>
                  ),
                }
              ),
          completed: !!dashBoardData.setupStatus?.subscriptionPlan?.isCompleted,
          primaryButton: i18n.translate('SetupGuide.ExplorePlans'),
          imageUrl: dashBoardData.setupStatus?.subscriptionPlan?.imageUrl,
          action: handleOpenPlans,
        },
      ]);
    }
  }, [
    dashBoardData,
    turnWidgetOnManuallyIsSuccess,
    loading,
    isWidgetRequestSent,
    i18n,
  ]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && checkConfiguration) {
        refetch();
        setCheckConfiguration(false);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [checkConfiguration, refetch]);

  return (
    <Box width='100%' paddingBlockEnd='2000'>
      <Page
        title={i18n.translate('PageTitle')}
        primaryAction={{
          loading: creatingTestPromotion,
          content: i18n.translate('CreatePromotion'),
          onAction: toggleCreatePromotionModal,
        }}
      >
        {dashBoardData ? (
          <>
            <BlockStack gap='600'>
              <WarningAlertBanner alertStatus={dashBoardData.alertStatus} />
              {currentPlanDataIsFetching || currentPlanDataIsLoading ? (
                <CardsSkeleton size='large' cardsCount={1} />
              ) : (
                <SubscriptionPlanBanner
                  stepCompleted={steps
                    .slice(0, 3)
                    .some((item) => item.completed)}
                  subscriptionPlan={dashBoardData.setupStatus?.subscriptionPlan}
                  currentPlanData={currentPlanData}
                  handleOpenPlans={handleOpenPlans}
                  setChangePlanConfig={setChangePlanConfig}
                />
              )}

              {!isComponentDismissed('CreateTestPromotion') && (
                <CreateTestPromotion
                  testPromotionStatus={dashBoardData.testPromotionStatus}
                  onDismiss={() =>
                    handleDismissComponent('CreateTestPromotion')
                  }
                  setCreatingTestPromotion={setCreatingTestPromotion}
                />
              )}
              {!isComponentDismissed('DiscountNinjaTutorial') && (
                <DiscountNinjaTutorial
                  onDismiss={() =>
                    handleDismissComponent('DiscountNinjaTutorial')
                  }
                  tutorialVideoUrl={dashBoardData.tutorialVideoUrl}
                  isDismissable={
                    dashBoardData.testPromotionStatus?.alreadyCreated
                  }
                />
              )}
              {!isComponentDismissed('SetupGuide') && (
                <SetupGuide
                  onDismiss={() => handleDismissComponent('SetupGuide')}
                  items={steps}
                  loading={loading}
                />
              )}
              {currentPlanDataIsFetching || currentPlanDataIsLoading ? (
                <CardsSkeleton size='large' cardsCount={1} />
              ) : currentPlanData ? (
                <Card roundedAbove='sm' padding='0'>
                  <ThresholdUsage
                    currentPlanData={currentPlanData}
                    setChangePlanConfig={setChangePlanConfig}
                    asCard
                  />
                </Card>
              ) : null}
              {dashBoardData.arePromotionInsightsAvailable && (
                <PromotionInsights
                  promotionInsightsData={promotionInsightsData}
                  currentPeriod={currentPeriod}
                  isLoading={
                    promotionInsightsDataIsFetching ||
                    promotionInsightsDataIsLoading
                  }
                  setCurrentPeriod={setCurrentPeriod}
                />
              )}
              {(steps.every((step) => step.completed) ||
                dashBoardData.setupStatus?.isCompleted ||
                dashBoardData.testPromotionStatus?.alreadyCreated) &&
                !isComponentDismissed('ExploreCard') && (
                  <ExploreCard
                    onDismiss={() => handleDismissComponent('ExploreCard')}
                  />
                )}
              {(steps.every((step) => step.completed) ||
                dashBoardData.setupStatus?.isCompleted ||
                dashBoardData.testPromotionStatus?.alreadyCreated) &&
                ['NinjaCart', 'GetTipsAndTricks', 'appVersionCard'].some(
                  (item) => !dismissedComponents.includes(item)
                ) && (
                  <ThreeCardsLayout
                    appVersion={dashBoardData.appVersion}
                    onDismiss={handleDismissComponent}
                    handleRemoveDismissedComponent={
                      handleRemoveDismissedComponent
                    }
                    isComponentDismissed={isComponentDismissed}
                  />
                )}
            </BlockStack>
            {isCreateModalOpen && (
              <CreatePromotionModal
                isOpen={isCreateModalOpen}
                onClose={toggleCreatePromotionModal}
                externalPage
              />
            )}
            {installationModalOpen && (
              <Modal
                title={i18n.translate('RequestWidgetInstallationSupport')}
                open={installationModalOpen}
                onClose={toggleWidgetInstallation}
              >
                <div className='WidgetInstallationModalContent'>
                  <RequestWidgetSupport
                    onClose={toggleWidgetInstallation}
                    setIsWidgetRequestSent={setIsWidgetRequestSent}
                    skipUnnecessaryRequests={true}
                  />
                </div>
              </Modal>
            )}
            {changePlanConfig && (
              <ChangePlanModal
                {...changePlanConfig}
                isOpen={!!changePlanConfig}
                onClose={() => setChangePlanConfig(null)}
                isMaxModal={false}
              />
            )}
            <TogglePromotionEngineModal
              isEngineOn={!!dashBoardData.setupStatus?.promotionEngine?.enabled}
              link={
                dashBoardData.setupStatus?.promotionEngine
                  ?.installationDeepLink || ''
              }
              isOpen={isEngineModalOpen}
              onClose={toggleEngineModal}
              setCheckConfiguration={setCheckConfiguration}
            />
          </>
        ) : (
          <CardsSkeleton size='large' cardsCount={10} />
        )}
      </Page>
    </Box>
  );
};
