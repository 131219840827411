import React, { useCallback, useState } from 'react';
import {
  Badge,
  Banner,
  BlockStack,
  Button,
  Card,
  Icon,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { InfoIcon } from '@shopify/polaris-icons';
import { Element } from 'react-scroll';
import { useI18n } from '@shopify/react-i18n';
import TogglePromotionEngineModal from '../TogglePromotionEngineModal/TogglePromotionEngineModal';
import { PromotionEngineInstallationDto } from 'core/api/adminSettings/adminSettingsApi';
import { Tooltip } from 'core/components';

type SettingsEngineProps = {
  promotionEngine?: PromotionEngineInstallationDto;
  multipleVersionsSupport: boolean;
  allWidgetsDisabled: boolean;
};

export const SettingsEngine: React.FC<SettingsEngineProps> = ({
  promotionEngine,
  multipleVersionsSupport,
  allWidgetsDisabled,
}) => {
  const [i18n] = useI18n();
  const { enabled, installationDeepLink } = promotionEngine || {};
  const [isEngineModalOpen, setIsEngineModalOpen] = useState<boolean>(false);

  const toggleEngineModal = useCallback(
    () => setIsEngineModalOpen(!isEngineModalOpen),
    [isEngineModalOpen]
  );
  return (
    <>
      <Element name='PromotionEngine'>
        <Card roundedAbove='sm'>
          <BlockStack gap='200'>
            <InlineStack align='space-between' blockAlign='center'>
              <InlineStack gap={'100'}>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate('PromotionEngine')}
                </Text>
                <Badge tone={enabled ? 'success' : undefined}>
                  {i18n.translate(enabled ? 'On' : 'Off')}
                </Badge>
                <Tooltip content='tooltip'>
                  <Icon tone='base' source={InfoIcon} />
                </Tooltip>
              </InlineStack>
              <Button onClick={toggleEngineModal}>
                {i18n.translate(enabled ? 'TurnOff' : 'TurnOn')}
              </Button>
            </InlineStack>
            <Text as='p' tone='subdued'>
              {i18n.translate('ResponsibleForOffering')}
            </Text>
            {!enabled && (
              <Banner tone='warning'>
                {i18n.translate('SpecialOffersUpsell')}
              </Banner>
            )}
            {enabled && multipleVersionsSupport && (
              <Banner tone='info'>
                {i18n.translate('MultipleVersions', {
                  here: (
                    <Link url='http://www.google.com' target='_blank'>
                      {i18n.translate('Here')}
                    </Link>
                  ),
                })}
              </Banner>
            )}
          </BlockStack>
        </Card>
      </Element>
      {isEngineModalOpen && (
        <TogglePromotionEngineModal
          isEngineOn={!!enabled}
          link={installationDeepLink || ''}
          isOpen={isEngineModalOpen}
          hasActiveWidgets={!allWidgetsDisabled}
          onClose={toggleEngineModal}
        />
      )}
    </>
  );
};
