import React, { useCallback, useState, useEffect } from 'react';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { FreeShippingToAnyCountryOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { isEqual } from 'lodash';
import {
  OfferCombinationsDto,
  OfferFreeShippingDto,
  OfferFreeShippingSpecificationDto,
  OfferCartRulesDto,
  OptionDtoOfferMinimumSpendDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { Combinations } from '../../Combinations/Combinations';
import { ShippingRate } from '../../ShippingRate/ShippingRate';
import { BlockStack, Page } from '@shopify/polaris';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate } from 'react-router-dom';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { CartRules } from '../../CartRules/CartRules';

export const FreeShipping: React.FC<OfferParentComponentProps> = (props) => {
  const { offerTemplate, offerType, handleOfferDataUpdate, savedData } = props;
  const [offerData, setOfferData] = useState<OfferFreeShippingDto | null>(
    offerTemplate?.template as OfferFreeShippingDto
  );
  const { validityChangeHandler } = useOfferFormValidity();

  const { currentCombination } = useAppSelector((state) => state.offersWizard);

  const [i18n] = useI18n();

  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [configureCartRules, setConfigureCartRules] = useState<boolean>(false);
  const [ruleIndex, setRuleIndex] = useState<number>(0);
  const [groupIndex, setGroupIndex] = useState<number>(0);

  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  const shippingSpecificationDataUpdate = useCallback(
    (specification: OfferFreeShippingSpecificationDto) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        freeShippingSpecification: specification,
      }));
    },
    [setOfferData]
  );

  const cartRulesUpdate = useCallback(
    (data: OfferCartRulesDto) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        cartRules: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  useEffect(() => {
    currentCombination.currentCombinationType &&
      setOfferData((prevState: OfferFreeShippingDto | null) => ({
        ...prevState,
        combinationType: currentCombination.currentCombinationType,
      }));
  }, [currentCombination.currentCombinationType]);

  return (
    <>
      {configureCartRules && (
        <CartRules
          savedData={(savedData?.template as OfferFreeShippingDto)?.cartRules}
          onFormValidityChange={validityChangeHandler(
            FreeShippingToAnyCountryOfferTabsEnum.CartRules
          )}
          onCartRulesChange={cartRulesUpdate}
          setConfigureComponent={setConfigureCartRules}
          configureComponent={configureCartRules}
          groupIndex={groupIndex}
          ruleIndex={ruleIndex}
          setRuleIndex={setRuleIndex}
          rulesSaved={offerData?.cartRules || {}}
          setGroupIndex={setGroupIndex}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      )}
      {!configureCartRules && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            content: i18n.translate('CreateOffer'),
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
              discountType={
                (offerTemplate?.template as OfferFreeShippingDto)
                  .combinationType
              }
            />
            <ShippingRate
              onValidityStateChange={validityChangeHandler(
                FreeShippingToAnyCountryOfferTabsEnum.Shipping
              )}
              specification={
                (offerTemplate?.template as OfferFreeShippingDto)
                  ?.freeShippingSpecification
              }
              onSpecificationUpdate={shippingSpecificationDataUpdate}
              offerType={offerType}
            />
            <CartRules
              savedData={
                (savedData?.template as OfferFreeShippingDto)?.cartRules
              }
              onFormValidityChange={validityChangeHandler(
                FreeShippingToAnyCountryOfferTabsEnum.CartRules
              )}
              onCartRulesChange={cartRulesUpdate}
              setConfigureComponent={setConfigureCartRules}
              configureComponent={configureCartRules}
              groupIndex={groupIndex}
              ruleIndex={ruleIndex}
              setRuleIndex={setRuleIndex}
              rulesSaved={offerData?.cartRules || {}}
              setGroupIndex={setGroupIndex}
              setIsChanged={setIsChanged}
              isChanged={isChanged}
            />
            <Combinations onCombinationsChange={combinationsUpdate} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferFreeShippingDto)
                    ?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
            {showHowModal && (
              <LearnMoreModal
                onModalClose={toggleShowHowModal}
                // url='https://youtu.be/9htdypfdlBM'
                title={i18n.translate('Tutorial')}
              />
            )}
          </BlockStack>
        </Page>
      )}
    </>
  );
};
