import React from 'react';
import { I18n } from '@shopify/react-i18n';
import {
  OfferCartContentRequirementProductMatchTypeDtoEnum,
  OfferCartRuleGroupConnectorDtoEnum,
  OfferCrossSellRequirementRuleTypeDtoEnum,
  OfferPrerequisiteEntitledCompareAtPriceBehaviorDtoEnum,
  OfferPrerequisiteEntitledPurchaseTypeDtoEnum,
  OfferRuleTypeDtoEnum,
  OfferRuleValueTypeDtoEnum,
  OfferTargetTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { formatPercentage } from 'core/utils';
import { OfferCartRuleGroupConnectorDto } from 'core/api/adminPromotions/adminPromotionsApi';

export enum SectionTypeEnum {
  PRODUCTS = 'PRODUCTS',
  CART_RULES = 'CART_RULES',
  GIFT_SECTION = 'GIFT_SECTION',
  DISCOUNT = 'DISCOUNT',
}

export const boldMessageParts = (message: string): JSX.Element => {
  const boldedMessage = message.replace(
    /<bold>(.*?)<\/bold>/g,
    (_, p1) => `<strong>${p1}</strong>`
  );
  return <span dangerouslySetInnerHTML={{ __html: boldedMessage }} />;
};

export const generateMessage = (
  section: SectionTypeEnum,
  rule: any,
  index: number,
  currentSelections: string,
  i18n: I18n,
  connector?: OfferCartRuleGroupConnectorDto,
  singleRule?: boolean
) => {
  const {
    type,
    value,
    appliesTo,
    applyAfterDiscounts,
    fixedAmount,
    percentage,
    count,
  } = rule;

  const appliesToTypeTranslation = appliesTo?.type
    ? i18n.translate(`OfferRule.${appliesTo.type}`)
    : '';

  const beforeAfter = applyAfterDiscounts
    ? i18n.translate('OfferRule.After')
    : i18n.translate('OfferRule.Before');

  let message = '';

  if (section === SectionTypeEnum.PRODUCTS) {
    message = `${i18n.translate(`Products.${appliesTo?.type}_RULE_TYPE`)}`;
  }

  if (section === SectionTypeEnum.GIFT_SECTION) {
    message = `${i18n.translate('Offer', {
      amount: rule?.rule?.amount || rule?.limit?.value,
      type: i18n.translate(`${rule.rule?.type}_type`),
    })}`;
  }

  if (section === SectionTypeEnum.DISCOUNT) {
    message = `${i18n.translate('DiscountRule', {
      discountType:
        type === OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT
          ? i18n.formatCurrency(fixedAmount)
          : formatPercentage(i18n, percentage),
      yValue: count,
      type: appliesToTypeTranslation,
      condition:
        value?.type === OfferRuleValueTypeDtoEnum.BETWEEN
          ? i18n.translate('MinimumItemsBetween', {
              from: value.from,
              to: value.to,
            })
          : value?.type === OfferRuleValueTypeDtoEnum.AT_LEAST
          ? i18n.translate('MinimumItemsAtLeast', {
              count: value.from,
            })
          : value?.type === OfferRuleValueTypeDtoEnum.AT_MOST
          ? i18n.translate('MinimumItemsAtMost', {
              count: value.to,
            })
          : i18n.translate('MinimumItemsExactly', {
              count: value.exactly,
            }),
    })}`;
  }

  if (
    type === OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY &&
    section === SectionTypeEnum.CART_RULES
  ) {
    switch (value?.type) {
      case OfferRuleValueTypeDtoEnum.BETWEEN:
        message = `${i18n.translate(
          `OfferRule.MinimumItemsBetween${singleRule ? 'SingleRule' : ''}`,
          {
            from: value.from,
            to: value.to,
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.AT_LEAST:
        message = `${i18n.translate(
          `OfferRule.MinimumItemsAtLeast${singleRule ? 'SingleRule' : ''}`,
          {
            count: value.from,
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.AT_MOST:
        message = `${i18n.translate(
          `OfferRule.MinimumItemsAtMost${singleRule ? 'SingleRule' : ''}`,
          {
            count: value.to,
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.EXACTLY:
        message = `${i18n.translate(
          `OfferRule.MinimumItemsExactly${singleRule ? 'SingleRule' : ''}`,
          {
            count: value.exactly,
            type: appliesToTypeTranslation,
          }
        )}`;
        break;
    }
  }

  if (
    type === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT &&
    section === SectionTypeEnum.CART_RULES
  ) {
    switch (value?.type) {
      case OfferRuleValueTypeDtoEnum.BETWEEN:
        message = `${i18n.translate(
          `OfferRule.MinimumPurchaseAmountBetween${
            singleRule ? 'SingleRule' : ''
          }`,
          {
            from: i18n.formatCurrency(value.from),
            to: i18n.formatCurrency(value.to),
            type: appliesToTypeTranslation,
            beforeAfter,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.AT_LEAST:
        message = `${i18n.translate(
          `OfferRule.MinimumPurchaseAmountAtLeast${
            singleRule ? 'SingleRule' : ''
          }`,
          {
            amount: i18n.formatCurrency(value.from),
            type: appliesToTypeTranslation,
            beforeAfter,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.AT_MOST:
        message = `${i18n.translate(
          `OfferRule.MinimumPurchaseAmountAtMost${
            singleRule ? 'SingleRule' : ''
          }`,
          {
            amount: i18n.formatCurrency(value.to),
            type: appliesToTypeTranslation,
            beforeAfter,
          }
        )}`;
        break;
      case OfferRuleValueTypeDtoEnum.EXACTLY:
        message = `${i18n.translate(
          `OfferRule.MinimumPurchaseAmountExactly${
            singleRule ? 'SingleRule' : ''
          }`,
          {
            amount: i18n.formatCurrency(value.exactly),
            type: appliesToTypeTranslation,
            beforeAfter,
          }
        )}`;
        break;
    }
  }

  if (index === 0 && !connector) {
    message = `${i18n.translate(
      `OfferRule.Apply${singleRule ? '' : 'If'}`
    )} ${message}`;
  }

  if (connector === OfferCartRuleGroupConnectorDtoEnum.BUT_NOT && index === 0) {
    message = `${i18n.translate('OfferRule.Unless')} ${message}`;
  }

  if (index > 0) {
    message = `${i18n.translate('OfferRule.Or')} ${message
      .charAt(0)
      .toLowerCase()}${message.slice(1)}`;
  }

  return `${message} ${currentSelections}`;
};

export const generateRules = (
  cartRule: any,
  collectionExclusions: string,
  i18n: I18n,
  eligibleForSubscription?: boolean
): JSX.Element[] => {
  const rules: JSX.Element[] = [];

  // Rule for product match type
  const productMatchType = cartRule?.appliesTo?.productMatchType;
  if (productMatchType?.enabled) {
    if (
      productMatchType.value ===
      OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT
    ) {
      rules.push(
        <span>
          {i18n.translate(
            `OfferRule.${OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT}`
          )}
        </span>
      );
    } else if (
      productMatchType.value ===
      OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_VARIANT
    ) {
      rules.push(
        <span>
          {i18n.translate(
            `OfferRule.${OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_VARIANT}`
          )}
        </span>
      );
    }
  }

  // Name filters rules
  const nameFilters = cartRule?.nameFilters;

  if (cartRule?.appliesTo?.type !== OfferTargetTypeDtoEnum.VARIANTS) {
    if (
      nameFilters?.productNameFilter?.enabled &&
      [OfferTargetTypeDtoEnum.ALL, OfferTargetTypeDtoEnum.COLLECTIONS].includes(
        cartRule?.appliesTo?.type as OfferTargetTypeDtoEnum
      )
    ) {
      rules.push(
        <span>
          {boldMessageParts(
            `${i18n.translate('OfferRule.ProductNameFilter')} <bold>${
              nameFilters?.productNameFilter.value || ''
            }</bold>`
          )}
        </span>
      );
    }

    if (
      nameFilters?.productVariantNameFilter?.enabled &&
      [
        OfferTargetTypeDtoEnum.ALL,
        OfferTargetTypeDtoEnum.COLLECTIONS,
        OfferTargetTypeDtoEnum.PRODUCTS,
      ].includes(cartRule?.appliesTo?.type as OfferTargetTypeDtoEnum)
    ) {
      rules.push(
        <span>
          {boldMessageParts(
            `${i18n.translate('OfferRule.ProductVariantNameFilter')} <bold>${
              nameFilters?.productVariantNameFilter.value || ''
            }</bold>`
          )}
        </span>
      );
    }
  }

  // Exclusion rules
  const exclusions = cartRule?.exclusions;

  if (
    exclusions?.collections?.enabled &&
    collectionExclusions &&
    [OfferTargetTypeDtoEnum.ALL, OfferTargetTypeDtoEnum.COLLECTIONS].includes(
      cartRule?.appliesTo?.type as OfferTargetTypeDtoEnum
    )
  ) {
    rules.push(
      <span>
        {i18n.translate('OfferRule.Collections')}{' '}
        {boldMessageParts(collectionExclusions)}
      </span>
    );
  }

  // Advanced settings
  const advancedSettings = cartRule?.advancedSettings;

  if (
    advancedSettings?.purchaseType &&
    advancedSettings?.purchaseType !==
      OfferPrerequisiteEntitledPurchaseTypeDtoEnum.ONE_TIME_PURCHASE_AND_SUBSCRIPTION &&
    eligibleForSubscription
  ) {
    rules.push(
      <span>{`${i18n.translate(
        `OfferRule.${advancedSettings?.purchaseType}`
      )}`}</span>
    );
  }

  if (
    advancedSettings?.compareAtPrice &&
    advancedSettings?.compareAtPrice !==
      OfferPrerequisiteEntitledCompareAtPriceBehaviorDtoEnum.ALL_VARIANTS &&
    cartRule?.appliesTo?.type !== OfferTargetTypeDtoEnum.VARIANTS
  ) {
    rules.push(
      <span>{`${i18n.translate(
        `OfferRule.${advancedSettings?.compareAtPrice}`
      )}`}</span>
    );
  }

  return rules;
};
