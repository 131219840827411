import React, { useEffect, useState } from 'react';
import moment from 'moment';
// import 'moment/locale/de';
import { Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
type Timestamp = {
  timestamp: number;
  prefix?: string;
  tone?:
    | 'base'
    | 'disabled'
    | 'inherit'
    | 'success'
    | 'critical'
    | 'caution'
    | 'subdued'
    | 'text-inverse'
    | 'text-inverse-secondary'
    | 'magic'
    | 'magic-subdued';
};

const MomentAgo: React.FC<Timestamp> = ({ timestamp, prefix, tone }) => {
  const [i18n] = useI18n();

  const [label, setLabel] = useState('');

  useEffect(() => {
    // moment.locale('de');

    const updateLabel = () => {
      if (timestamp) {
        const datetime = moment.utc(timestamp);
        const duration = moment.duration(moment().diff(datetime)).humanize();
        setLabel(duration);
      }
    };

    updateLabel();

    const intervalId = setInterval(updateLabel, 60000);

    return () => clearInterval(intervalId);
  }, [timestamp]);

  return (
    <Text tone={tone ? tone : 'subdued'} as='span'>
      {(prefix !== undefined ? prefix : i18n.translate('Edited')) +
        ' ' +
        label +
        ' ' +
        i18n.translate('ago')}
    </Text>
  );
};

export default MomentAgo;
