import { Popover, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import ColorSelectorPopover from '../ColorSelectorPopover/ColorSelectorPopover';
import './ColorSelectorBox.scss';
type ColorSelectorBoxProps = {
  color: string;
  setColor: (value: string) => void;
  disabled?: boolean;
};
const ColorSelectorBox: React.FC<ColorSelectorBoxProps> = ({
  color,
  setColor,
  disabled,
}) => {
  const [colorPopover, setColorPopover] = useState<boolean>(false);
  const validateHexOnBlur = useCallback(() => {
    const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
    if (!hexRegex.test(color)) {
      setColor('#fff');
    }
  }, [color, setColor]);

  return (
    <div className='ColorSelectorBox'>
      <Popover
        active={colorPopover}
        fullHeight
        activator={
          <div
            onClick={() => (disabled ? null : setColorPopover(true))}
            className='ColorBox'
            style={{ background: color }}
          />
        }
        autofocusTarget='first-node'
        onClose={() => setColorPopover(false)}
      >
        <ColorSelectorPopover
          onClosePopover={() => {
            setColorPopover(false);
          }}
          allowAlpha={false}
          color={{ hex: color, alpha: 1 }}
          onColorChange={(value) => setColor(value.hex || '')}
        />
      </Popover>

      <TextField
        onChange={(value) => {
          const newValue = value.replace(/[^a-zA-Z0-9]/g, '');
          setColor(`#${newValue}`);
        }}
        value={color.replace(/^#/, '')}
        disabled={disabled}
        autoComplete=''
        label=''
        onBlur={validateHexOnBlur}
      />
    </div>
  );
};

export default ColorSelectorBox;
