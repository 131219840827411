import React, { ReactNode } from 'react';
import { Button, Card } from '@shopify/polaris';
import { I18n } from '@shopify/react-i18n';
import { PlusIcon } from '@shopify/polaris-icons';
import { OfferCartRuleGroupConnectorDto } from 'core/api/adminPromotions/adminPromotionsApi';
import {
  ActionItem,
  ActionListInPopover,
} from 'features/promotions/components/ActionListInPopover/ActionListInPopover';
import './RuleSummary.scss';

type RuleSummaryProps = {
  id: string;
  children: ReactNode;
  connector?: OfferCartRuleGroupConnectorDto;
  i18n: I18n;
  actionList: ActionItem[];
  disableBtn: boolean;
  hideBtn: boolean;
  activePopoverId: string;
  setActivePopoverId: (id: string) => void;
  handleAddRule: () => void;
};
export const RuleSummary: React.FC<RuleSummaryProps> = ({
  id,
  children,
  connector,
  i18n,
  actionList,
  disableBtn,
  hideBtn,
  activePopoverId,
  setActivePopoverId,
  handleAddRule,
}) => {
  return (
    <>
      {connector && (
        <ActionListInPopover
          id={id}
          activePopoverId={activePopoverId}
          setActivePopoverId={setActivePopoverId}
          width='96px'
          btnContent={i18n.translate(`CartRules.${connector}`)}
          actionList={actionList}
          selectType
        />
      )}
      <div className='RuleSummary'>
        <Card padding='0' roundedAbove='xs'>
          {children}
          {!hideBtn && (
            <div className='OrRuleBtn'>
              <Button
                variant='plain'
                icon={PlusIcon}
                onClick={handleAddRule}
                disabled={disableBtn}
              >
                {i18n.translate('CartRules.OrRule')}
              </Button>
            </div>
          )}
        </Card>
      </div>
    </>
  );
};
