import React from 'react';
import { NavTabs } from 'core/enums/NavTabsEnum';
import { usePageName } from 'core/hooks';
import { Route, Routes } from 'react-router-dom';
import { Analytics } from 'features/analytics/Analytics';
import { DetailedChartPage } from 'features/analytics/components/DetailedChartPage/DetailedChartPage';
import { PolarisVizProvider } from '@shopify/polaris-viz';
import { DetailedListPage } from 'features/analytics/components/DetailedListPage/DetailedListPage';
import { AnalyticsPageSubPathsEnums } from './enums/PagesEnums';

export enum ReportListTypeEnum {
  PROMOTION_ACTIVITY = 'PROMOTION_ACTIVITY',
  DETAILED_ORDER = 'DETAILED_ORDER',
  DETAILED_CODE = 'DETAILED_CODE',
}

export enum DetailedChartTypeEnum {
  TOTAL_DN_REVENUE = 'TOTAL_DN_REVENUE',
  DN_CONVERSION_RATE = 'DN_CONVERSION_RATE',
  TOTAL_DN_ORDERS = 'TOTAL_DN_ORDERS',
  DN_SESSIONS = 'DN_SESSIONS',
}

export enum ChartuUnitOfMeasurementEnum {
  PERCENTAGE = 'PERCENTAGE',
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
}

export const AnalyticsPage = () => {
  usePageName(NavTabs.Analytics);

  return (
    <PolarisVizProvider>
      <div
        style={{
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Routes>
          <Route index element={<Analytics />} />
          <Route
            path={AnalyticsPageSubPathsEnums.TotalDnRevenue}
            element={
              <DetailedChartPage
                chartType={DetailedChartTypeEnum.TOTAL_DN_REVENUE}
                unit={ChartuUnitOfMeasurementEnum.CURRENCY}
              />
            }
          />
          <Route
            path={AnalyticsPageSubPathsEnums.DnConversionRate}
            element={
              <DetailedChartPage
                chartType={DetailedChartTypeEnum.DN_CONVERSION_RATE}
                unit={ChartuUnitOfMeasurementEnum.PERCENTAGE}
              />
            }
          />
          <Route
            path={AnalyticsPageSubPathsEnums.TotalDnOrders}
            element={
              <DetailedChartPage
                chartType={DetailedChartTypeEnum.TOTAL_DN_ORDERS}
                unit={ChartuUnitOfMeasurementEnum.NUMBER}
              />
            }
          />
          <Route
            path={AnalyticsPageSubPathsEnums.DnSessions}
            element={
              <DetailedChartPage
                chartType={DetailedChartTypeEnum.DN_SESSIONS}
                unit={ChartuUnitOfMeasurementEnum.NUMBER}
              />
            }
          />
          <Route
            path={AnalyticsPageSubPathsEnums.PromotionActivityReport}
            element={
              <DetailedListPage
                listType={ReportListTypeEnum.PROMOTION_ACTIVITY}
              />
            }
          />
          <Route
            path={AnalyticsPageSubPathsEnums.OrderReport}
            element={
              <DetailedListPage listType={ReportListTypeEnum.DETAILED_ORDER} />
            }
          />
          <Route
            path={AnalyticsPageSubPathsEnums.PromotionCodeReport}
            element={
              <DetailedListPage listType={ReportListTypeEnum.DETAILED_CODE} />
            }
          />
        </Routes>
      </div>
    </PolarisVizProvider>
  );
};
