import React, { useState } from 'react';
import { MediaCard, Modal } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import OrderTagsImage from './assets/OrderTagsBig.png';

export const TagMediaCard = () => {
  const [i18n] = useI18n();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <MediaCard
        title={i18n.translate('MediaTitle')}
        primaryAction={{
          content: i18n.translate('LearnMore'),
          onAction: () => null,
        }}
        description={i18n.translate('MediaDescription')}
        popoverActions={[
          { content: i18n.translate('View'), onAction: () => setIsOpen(true) },
        ]}
        size='small'
      >
        <img
          alt=''
          width='100%'
          height='100%'
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          src={OrderTagsImage}
        />
      </MediaCard>
      <Modal
        onClose={() => setIsOpen(false)}
        open={isOpen}
        title={i18n.translate('MediaTitle')}
        secondaryActions={[
          {
            content: i18n.translate('Cancel'),
          },
        ]}
      >
        <Modal.Section>
          <img
            alt=''
            width='100%'
            height='100%'
            style={{
              borderRadius: 'var(--p-space-300)',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
            src={OrderTagsImage}
          />
        </Modal.Section>
      </Modal>
    </>
  );
};
