import {
  PresetDtoRead,
  PromotionSetupDtoRead,
  WidgetTypeDto,
  useSystemDefaultsQuery,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
import { setIsPromotionEditorDataLoading } from 'core/store/widgetsSlice';
import resolveEnvVar from 'env-var-resolver';
import { useState } from 'react';

const promotionPresetApiURL = `${resolveEnvVar(
  'REACT_APP_WIDGETS_ADMIN_API_URL'
)}/widgets/v6/promotion`;
const presetApiURL = `${resolveEnvVar(
  'REACT_APP_WIDGETS_ADMIN_API_URL'
)}/widgets/v6/presets`;
const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

type GetPresetArg = {
  id: string;
  widgets?: WidgetTypeDto[] | undefined;
  transactionId?: string | undefined;
};

const useFetchPresetEditor = (supportedWidgets?: WidgetTypeDto[]) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const dispatch = useAppDispatch();
  const [promotionPresetData, setPromotionPresetData] =
    useState<PromotionSetupDtoRead>({});

  const [editPresetData, setEditPresetData] = useState<PresetDtoRead>();
  const [isPresetEditorDataLoading, setIsPresetEditorDataLoading] =
    useState<boolean>(false);

  const fetchPromotionPreset = async (
    { id, transactionId }: GetPresetArg,
    updateDataState: (data: any) => void
  ) => {
    dispatch(setIsPromotionEditorDataLoading(true));
    const transactionIdParams = transactionId
      ? `transactionId=${transactionId}`
      : '';

    const response = await fetch(
      `${promotionPresetApiURL}/${id}?${transactionIdParams}`,
      {
        method: 'GET',
        headers: {
          'X-LimoniApps-AppName': APP_NAME,
          'X-LimoniApps-AppSecret': APP_PASSWORD,
          'Accept-Language': navigator.language || 'en',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    )
      .then((data) => {
        return data.json();
      })
      .catch(() => {
        dispatch(
          setToastMessages({
            error: true,
            message: 'WidgetEditorLoadFailed',
          })
        );
      })
      .finally(() => dispatch(setIsPromotionEditorDataLoading(false)));
    if (response) {
      updateDataState(response);
      setPromotionPresetData(response);
    }
    return response;
  };

  const fetchEditPreset = async (
    { id }: GetPresetArg,
    updateDataState: (data: any) => void
  ) => {
    setIsPresetEditorDataLoading(true);

    const response = await fetch(`${presetApiURL}/${id}`, {
      method: 'GET',
      headers: {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((data) => {
        return data.json();
      })
      .catch(() => {
        dispatch(
          setToastMessages({
            error: true,
            message: 'PresetLoadFailed',
          })
        );
      })
      .finally(() => setIsPresetEditorDataLoading(false));
    if (response) {
      updateDataState(response);
      setEditPresetData(response);
    }
    return response;
  };

  const { data: shopDefaultData } = useSystemDefaultsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      widgets: supportedWidgets,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !supportedWidgets?.length,
    }
  );

  return {
    fetchPromotionPreset,
    fetchEditPreset,
    shopDefaultData,
    editPresetData,
    promotionPresetData,
    isPresetEditorDataLoading,
  };
};

export default useFetchPresetEditor;
