import React from 'react';
import { BlockStack } from '@shopify/polaris';
import { TriggerPromotionSettingsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { TriggerTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { ChangeTriggerSection } from './components/ChangeTriggerSection/ChangeTriggerSection';
import { SharePromotionSection } from './components/SharePromotionSection/SharePromotionSection';

type SettingTriggerProps = {
  trigger?: TriggerPromotionSettingsDto;
  updateSettingsState: (data: TriggerPromotionSettingsDto) => void;
  navigateThrough: (path?: string) => void;
};

export const SettingTrigger: React.FC<SettingTriggerProps> = ({
  trigger,
  updateSettingsState,
  navigateThrough,
}) => {
  return (
    <BlockStack gap='500'>
      <ChangeTriggerSection
        triggerType={trigger?.triggerType}
        setTriggerType={(value) =>
          updateSettingsState({ ...trigger, triggerType: value })
        }
      />
      {trigger?.triggerType === TriggerTypeDtoEnum.PRIVATE && (
        <SharePromotionSection
          trigger={trigger}
          updateSettingsState={updateSettingsState}
          navigateThrough={navigateThrough}
        />
      )}
    </BlockStack>
  );
};
