import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GiftOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { isEqual } from 'lodash';
import { Combinations } from '../../Combinations/Combinations';
import {
  OfferCombinationsDto,
  OfferGiftDto,
  OfferGiftSpecificationDto,
  OfferCartRulesDto,
  OptionDtoOfferMinimumSpendDto,
  OptionDtoInt32,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { FreeGiftSection } from '../../FreeGiftSectionNew/FreeGiftSection';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { DiscountLimits } from '../../DiscountLimits/DiscountLimits';
import { BlockStack, Page } from '@shopify/polaris';
import { useNavigate } from 'react-router-dom';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { useI18n } from '@shopify/react-i18n';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { CartRules } from '../../CartRules/CartRules';

export const FreeGiftOffer: React.FC<OfferParentComponentProps> = (props) => {
  const { offerTemplate, handleOfferDataUpdate, savedData, offerType } = props;

  const { validityChangeHandler } = useOfferFormValidity();

  const { currentCombination } = useAppSelector((state) => state.offersWizard);

  const [offerData, setOfferData] = useState<OfferGiftDto | null>(
    offerTemplate?.template as OfferGiftDto
  );

  const [i18n] = useI18n();

  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [configureGift, setConfigureGift] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [ruleIndex, setRuleIndex] = useState<number>(0);
  const [groupIndex, setGroupIndex] = useState<number>(0);
  const [configureCartRules, setConfigureCartRules] = useState<boolean>(false);

  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();

  const maxQuantity = useMemo(
    () => offerData?.specification?.rule?.amount || 1,
    [offerData?.specification?.rule?.amount]
  );

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  const cartRulesUpdate = useCallback(
    (data: OfferCartRulesDto) => {
      setOfferData((prevState: OfferGiftDto | null) => ({
        ...prevState,
        cartRules: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const handleSpecificationDataUpdate = useCallback(
    (specification: OfferGiftSpecificationDto) => {
      setOfferData((prevState: OfferGiftDto | null) => ({
        ...prevState,
        specification: specification,
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferGiftDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferGiftDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdate = useCallback(
    (data: OptionDtoInt32) => {
      setOfferData((prevState: OfferGiftDto | null) => ({
        ...prevState,
        specification: {
          ...prevState?.specification,
          limit: {
            ...data,
          },
        },
      }));
    },
    [setOfferData]
  );

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  useEffect(() => {
    currentCombination.currentCombinationType &&
      setOfferData((prevState: OfferGiftDto | null) => ({
        ...prevState,
        combinationType: currentCombination.currentCombinationType,
      }));
  }, [currentCombination.currentCombinationType]);

  return (
    <>
      {configureCartRules && (
        <CartRules
          savedData={(savedData?.template as OfferGiftDto)?.cartRules}
          onFormValidityChange={validityChangeHandler(
            GiftOfferTabsEnum.CartRules
          )}
          onCartRulesChange={cartRulesUpdate}
          setConfigureComponent={setConfigureCartRules}
          configureComponent={configureCartRules}
          groupIndex={groupIndex}
          ruleIndex={ruleIndex}
          setRuleIndex={setRuleIndex}
          rulesSaved={offerData?.cartRules || {}}
          setGroupIndex={setGroupIndex}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      )}
      {configureGift && (
        <FreeGiftSection
          specification={
            (offerTemplate?.template as OfferGiftDto)?.specification
          }
          onFormValidityChange={validityChangeHandler(
            GiftOfferTabsEnum.FreeGift
          )}
          onSpecificationUpdate={handleSpecificationDataUpdate}
          configureComponent={configureGift}
          setConfigureComponent={setConfigureGift}
        />
      )}

      {!configureCartRules && !configureGift && (
        <Page
          title={i18n.translate(`ConfigureOffer`)}
          backAction={{
            content: i18n.translate('CreateOffer'),
            onAction: async () => {
              !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
              navigate('../..', { relative: 'path' });
            },
          }}
        >
          <BlockStack gap='600'>
            <OfferTemplate
              type={offerType}
              toggleShowHowModal={toggleShowHowModal}
              discountType={
                (offerTemplate?.template as OfferGiftDto).combinationType
              }
            />
            <FreeGiftSection
              specification={
                (offerTemplate?.template as OfferGiftDto)?.specification
              }
              onFormValidityChange={validityChangeHandler(
                GiftOfferTabsEnum.FreeGift
              )}
              onSpecificationUpdate={handleSpecificationDataUpdate}
              configureComponent={configureGift}
              setConfigureComponent={setConfigureGift}
            />
            <CartRules
              savedData={(savedData?.template as OfferGiftDto)?.cartRules}
              onFormValidityChange={validityChangeHandler(
                GiftOfferTabsEnum.CartRules
              )}
              onCartRulesChange={cartRulesUpdate}
              setConfigureComponent={setConfigureCartRules}
              configureComponent={configureCartRules}
              groupIndex={groupIndex}
              ruleIndex={ruleIndex}
              setRuleIndex={setRuleIndex}
              rulesSaved={offerData?.cartRules || {}}
              setGroupIndex={setGroupIndex}
              setIsChanged={setIsChanged}
              isChanged={isChanged}
            />
            <DiscountLimits
              type='Gifts'
              noCartRules={!offerData?.cartRules?.groups?.length}
              limits={
                (offerTemplate?.template as OfferGiftDto)?.specification?.limit
              }
              limitUpdate={limitUpdate}
              onFormValidityChange={validityChangeHandler(
                GiftOfferTabsEnum.Limit
              )}
              maxQuantity={maxQuantity}
            />
            <Combinations onCombinationsChange={combinationsUpdate} />
            {offerTemplate?.minimumSpendSupported && (
              <MinimumSpend
                minimumSpend={
                  (offerTemplate?.template as OfferGiftDto)?.minimumSpend || {}
                }
                minimumSpendUpdate={minimumSpendUpdate}
              />
            )}
            {showHowModal && (
              <LearnMoreModal
                onModalClose={toggleShowHowModal}
                // url='https://youtu.be/9htdypfdlBM'
                title={i18n.translate('Tutorial')}
              />
            )}
          </BlockStack>
        </Page>
      )}
    </>
  );
};
