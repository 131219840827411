import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  password: string;
}

const initialState: AuthState = {
  password: localStorage.getItem('passwordDevLogin') || '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPassword: (state, action: PayloadAction<{ password: string }>) => {
      state.password = action.payload.password;
    },
  },
});

export const { setPassword } = authSlice.actions;

export default authSlice.reducer;
