import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { usePageName } from 'core/hooks';
import { NavTabs } from 'core/enums/NavTabsEnum';
import { AlertCenter } from 'features/alertcenter/AlertCenter';

export const AlertCenterPage = () => {
  usePageName(NavTabs.AlertCenter);

  return (
    <Routes>
      <Route index element={<AlertCenter />} />
    </Routes>
  );
};
