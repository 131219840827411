import React, { useCallback, useRef } from 'react';
import { BlockStack, Box, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate } from 'react-router-dom';
import { ReportListTypeEnum } from 'pages';
import {
  AnalyticsReportList,
  AnalyticsReportListRef,
} from '../Charts/AnalyticsReportList/AnalyticsReportList';
import { PeriodSelector } from '../PeriodSelector/PeriodSelector';
import { AnalyticsQueryPeriodDtoEnum } from 'core/api/adminAnalytics/adminAnalyticsEnums';

type DetailedListPageProps = {
  listType: ReportListTypeEnum;
};
export const DetailedListPage: React.FC<DetailedListPageProps> = ({
  listType,
}) => {
  const [i18n] = useI18n();
  const listRef = useRef<AnalyticsReportListRef>(null);
  const navigate = useNavigate();

  const handleRefreshList = useCallback(
    (period?: AnalyticsQueryPeriodDtoEnum) => listRef.current?.refresh(period),
    [listRef.current]
  );
  const handleExportModal = useCallback(
    () => listRef.current?.export(),
    [listRef.current]
  );

  return (
    <Page
      backAction={{ onAction: () => navigate(-1) }}
      fullWidth
      title={i18n.translate(`${listType}_TITLE`)}
      secondaryActions={[
        {
          content: i18n.translate('Refresh'),
          onAction: handleRefreshList,
        },
        {
          content: i18n.translate('Export'),
          onAction: handleExportModal,
        },
      ]}
    >
      <Box paddingBlockEnd='1000'>
        <BlockStack gap='400'>
          {listType !== ReportListTypeEnum.DETAILED_CODE && <PeriodSelector />}
          <AnalyticsReportList ref={listRef} listType={listType} />
        </BlockStack>
      </Box>
    </Page>
  );
};
