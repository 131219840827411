import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useIsDebugOrLocal } from 'core/hooks';
import { EditPresetTypeEnum } from 'features/settings/components/EditPresetSkeleton/enums/enums';
import { EditPresetSkeleton } from 'features/settings/components/EditPresetSkeleton/EditPresetSkeleton';
import { Modal, SaveBar, TitleBar } from '@shopify/app-bridge-react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MessageEventData } from './SettingsPage';
import { useI18n } from '@shopify/react-i18n';
import { setCurrentAnchor } from 'core/store/settingsSlice';
import { NavPaths } from 'core/enums/NavPathsEnum';

export type EditPresetSkeletonProps = {
  skeletonType: EditPresetTypeEnum;
};

export const WidgetEditor: React.FC<EditPresetSkeletonProps> = ({
  skeletonType,
}) => {
  const [i18n] = useI18n();
  const settingsLocation = localStorage.getItem('settingsLocation');
  const dispatch = useAppDispatch();
  const isDebugOrLocal = useIsDebugOrLocal();
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useParams();

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [changes, setChanges] = useState<boolean>(false);

  const modalType = searchParams.get('modal');

  const sendMessageToModal = useCallback((message: any) => {
    const iframe = document.getElementById(
      'widgetEditorModal'
    ) as HTMLIFrameElement | null;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(message, window.location.origin);
    }
  }, []);

  const handleRedirect = useCallback(
    (data: any) => {
      if (changes) {
        setShowConfirmModal(true);
      } else {
        if (data) {
          shopify.modal.hide('widgetEditorModal').then(() => {
            setSearchParams((params) => {
              params.set('modal', NavPaths.SettingsModal);
              params.set('path', data.path);
              data?.subPath
                ? params.set('subPath', data.subPath)
                : params.delete('subPath');
              data?.tab ? params.set('tab', data.tab) : params.delete('tab');
              data?.language
                ? params.set('language', data.language)
                : params.delete('language');
              return params;
            });
          });
        }
      }
    },
    [changes, setSearchParams, setShowConfirmModal]
  );

  const handleCancel = useCallback(() => {
    sendMessageToModal({
      type: 'CLEAR_SEARCH_PARAMS',
    });
    setShowConfirmModal(false);
  }, []);

  useEffect(() => {
    function handleMessageFromModal(ev: MessageEvent) {
      const { type } = ev.data as MessageEventData;

      if (type === 'MAX_MODAL_RENDERED') {
        sendMessageToModal({
          type: 'PARAMS_UPDATE',
          params,
        });
      }

      if (type === 'DISABLE_SAVE') {
        setSaveDisabled(ev.data.saveDisabled);
      }

      if (type === 'EDITOR_CHANGES') {
        setChanges(ev.data.anyChangesInEditor);
      }

      if (type === 'UPDATE_SEARCH_PARAMS') {
        if (ev.data.searchParams?.path) {
          handleRedirect(ev.data.searchParams);
        }
      }

      if (type === 'UPDATE_ANCHOR') {
        dispatch(setCurrentAnchor(ev.data.currentAnchor));
      }
    }

    !isDebugOrLocal &&
      window.addEventListener('message', handleMessageFromModal);

    return () => {
      window.removeEventListener('message', handleMessageFromModal);
    };
  }, [handleRedirect]);

  useEffect(() => {
    modalType !== NavPaths.SettingsModal && setIsOpen(true);
  }, [modalType]);

  useEffect(() => {
    !isDebugOrLocal && localStorage.setItem('skeletonType', skeletonType);
  }, [isDebugOrLocal, skeletonType]);

  return isDebugOrLocal ? (
    <EditPresetSkeleton key={skeletonType} skeletonType={skeletonType} />
  ) : (
    <>
      <Modal
        id='widgetEditorModal'
        open={isOpen}
        variant='max'
        onHide={() => {
          if (skeletonType === EditPresetTypeEnum.PRESET_EDITOR) {
            navigate(`${settingsLocation}`);
          } else {
            navigate(`/${NavPaths.Promotions}/${params.promotionId}`);
          }
          setIsOpen(false);
        }}
        src='/modals/widgetEditor.html'
      >
        <TitleBar
          title={
            skeletonType === EditPresetTypeEnum.PRESET_EDITOR
              ? i18n.translate('EditPreset')
              : i18n.translate('EditPromotion')
          }
        />
        <SaveBar id='widgetEditorModalSavebar' open={changes}>
          <button
            // eslint-disable-next-line react/no-unknown-property
            variant='primary'
            onClick={() =>
              sendMessageToModal({
                type: 'SAVE_ACTION',
              })
            }
            disabled={saveDisabled}
            loading={saveDisabled}
          />
          <button
            onClick={() =>
              sendMessageToModal({
                type: 'DISCARD_ACTION',
              })
            }
          />
        </SaveBar>
      </Modal>
      <Modal
        id='settingsConfirmationModal'
        open={showConfirmModal}
        onHide={handleCancel}
      >
        <div style={{ padding: 'var(--p-height-400)' }}>
          {i18n.translate('ConfirmRedirect')}
        </div>
        <TitleBar title={i18n.translate('Warning')}>
          <button
            // eslint-disable-next-line react/no-unknown-property
            variant='primary'
            onClick={handleCancel}
          >
            {i18n.translate('Understand')}
          </button>
        </TitleBar>
      </Modal>
    </>
  );
};
