import React, { useCallback, useEffect, useState } from 'react';
import { BlockStack, Card, Checkbox, Link, Text } from '@shopify/polaris';
import { useDispatch } from 'react-redux';
import {
  DeviceDisplayDtoEnum,
  ResourceGroupKeyDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty, isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  NotificationShopSettingsDto,
  WidgetSettingsDtoNotificationShopSettingsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  DeviceDisplaySetting,
  IconSetting,
  MessageCountSetting,
  NotificationAnimationsSetting,
  OffsetSetting,
  PageDisplaySetting,
  PositionSetting,
  StyleSetting,
  WidgetSettingsWrapper,
  WidgetTypeSettingsWrapperEnum,
} from '../components';
import { useI18n } from '@shopify/react-i18n';
import { PositionDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

export const NotificationSettings: React.FC = () => {
  const dispatch = useDispatch();
  const [i18n] = useI18n();
  const {
    notificationData,
    saveNotification,
    saveNotificationIsLoading,
    notificationDataIsFetching,
    refetchNotificationData,
  } = useConfigureSettings(SettingsFetchTypeEnum.NOTIFICATION);

  const [savedData, setSavedData] =
    useState<WidgetSettingsDtoNotificationShopSettingsDto>({});
  const [notificationState, setNotificationState] =
    useState<WidgetSettingsDtoNotificationShopSettingsDto>({});

  const updateNotificationState = useCallback(
    (
      field: keyof NotificationShopSettingsDto,
      data: NotificationShopSettingsDto[keyof NotificationShopSettingsDto]
    ) =>
      setNotificationState((prev) => ({
        ...prev,
        settings: {
          ...prev.settings,
          [field]: data,
        },
      })),
    [notificationState]
  );

  useEffect(() => {
    if (!isEmpty(notificationData)) {
      setNotificationState(notificationData);
      setSavedData(notificationData);
    }
  }, [notificationData]);

  useEffect(() => {
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <div className='NotificationSettings'>
      <WidgetSettingsWrapper
        widgetState={{
          ...notificationState,
          widgetType: WidgetTypeSettingsWrapperEnum.NOTIFICATIONS,
        }}
        isLoading={notificationDataIsFetching && !notificationData}
        refetchWidget={refetchNotificationData}
      >
        <BlockStack gap='600'>
          <StyleSetting styled={!!notificationData?.isShopLevelWidget} />
          <DeviceDisplaySetting
            deviceDisplay={
              notificationState.settings?.deviceDisplay as DeviceDisplayDtoEnum
            }
            setDeviceDisplay={(value) =>
              updateNotificationState('deviceDisplay', value)
            }
          />
          <PageDisplaySetting
            pageDisplay={notificationState.settings?.pageDisplay || {}}
            setPageDisplay={(data) =>
              updateNotificationState('pageDisplay', data)
            }
          />
          <PositionSetting
            position={notificationState.settings?.position as PositionDtoEnum}
            positionSides={[
              PositionDtoEnum.BOTTOM_LEFT,
              PositionDtoEnum.BOTTOM_RIGHT,
              PositionDtoEnum.TOP_LEFT,
              PositionDtoEnum.TOP_RIGHT,
            ]}
            title={i18n.translate('PagePosition')}
            subtitle={i18n.translate('AdjustPage')}
            setPosition={(value) => updateNotificationState('position', value)}
          />
          <OffsetSetting
            offset={notificationState.settings?.offset || {}}
            setOffset={(data) => updateNotificationState('offset', data)}
          />
          <div className='OffsetSetting'>
            <Card>
              <BlockStack gap='400'>
                <BlockStack gap='100'>
                  <Text as='p' fontWeight='bold'>
                    {i18n.translate('MinimizingNotification')}
                  </Text>
                  <Text as='p' tone='subdued'>
                    {i18n.translate('DefineIfTheNotification')}
                  </Text>
                </BlockStack>
                <Checkbox
                  checked={
                    !!notificationState.settings?.minimizingBehavior
                      ?.minimizeAfterFirstView
                  }
                  onChange={(value) =>
                    updateNotificationState('minimizingBehavior', {
                      ...notificationState.settings?.minimizingBehavior,
                      minimizeAfterFirstView: value,
                    })
                  }
                  label={i18n.translate('MinimizeAfterFirstView')}
                  helpText={i18n.translate('MinimizeAfterFirstViewDescription')}
                />
                <Checkbox
                  checked={
                    !!notificationState.settings?.minimizingBehavior
                      ?.automicallyMinimize
                  }
                  onChange={(value) =>
                    updateNotificationState('minimizingBehavior', {
                      ...notificationState.settings?.minimizingBehavior,
                      automicallyMinimize: value,
                    })
                  }
                  label={i18n.translate('AutomicallyMinimize')}
                  helpText={i18n.translate('AutomicallyMinimizeDescription')}
                />
              </BlockStack>
            </Card>
          </div>
          <IconSetting
            icon={notificationState.settings?.minimizingIcon || {}}
            setIcon={(data) => updateNotificationState('minimizingIcon', data)}
            bannerContent={
              <>
                {i18n.translate('MinimizingIconBannerText')}
                <Link>{i18n.translate('PresetEditor')}</Link>.
              </>
            }
            enableText={i18n.translate('EnableMinimizingIcon')}
            groupKey={ResourceGroupKeyDtoEnum.CHEVRONS}
            title={i18n.translate('MinimizingIcon')}
            subtitle={i18n.translate('MinimizingIconSubtitle')}
          />
          <NotificationAnimationsSetting
            animations={notificationState.settings?.animations || {}}
            setAnimations={(data) =>
              updateNotificationState('animations', data)
            }
          />
          <MessageCountSetting
            messageCount={!!notificationState.settings?.messageCountEnabled}
            setMessageCount={(value) =>
              updateNotificationState('messageCountEnabled', value)
            }
          />
        </BlockStack>
      </WidgetSettingsWrapper>
    </div>
  );
};
