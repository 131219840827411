import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Icon, InlineStack, Text } from '@shopify/polaris';
import {
  GrayBoxResourceType,
  GrayBoxResourceTypeEnum,
  ResourceSelectionProps,
} from '../../SearchFieldWithGrayBoxOfferWizard';
import { useI18n } from '@shopify/react-i18n';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CollectionIcon,
  AlertTriangleIcon,
} from '@shopify/polaris-icons';
import { headerTitle } from '../../utils/utils';
import { SelectedProductList } from '../SelectedProductList/SelectedProductList';
import { CustomSekeletonBox } from 'core/components/ControlLibrary';

type SearchFieldPlaceholderProps = {
  currentResourceType: string;
  selectedGrayBoxElements: ResourceSelectionProps[];
  showVariants?: boolean;
  isOrderBumpType?: boolean;
  searchFieldType?: string;
  resourceType: GrayBoxResourceType;
  resourceIsLoading: boolean;
  toggleShowGrayBoxState: (breakCondition?: boolean) => void;
};
export const SearchFieldPlaceholder: React.FC<SearchFieldPlaceholderProps> = ({
  currentResourceType,
  isOrderBumpType,
  searchFieldType,
  selectedGrayBoxElements,
  showVariants,
  resourceType,
  resourceIsLoading,
  toggleShowGrayBoxState,
}) => {
  const [i18n] = useI18n();
  const [showList, setShowList] = useState<boolean>(false);

  const isEmptyResourceList = useMemo(
    () => !selectedGrayBoxElements?.length && !resourceIsLoading,
    [selectedGrayBoxElements, resourceIsLoading]
  );

  const placeholderTextLabel: string = useMemo(
    () =>
      resourceType === GrayBoxResourceTypeEnum.Product &&
      showVariants &&
      !isOrderBumpType
        ? `SearchFieldPlaceholderProductVariants${searchFieldType || ''}`
        : resourceType === GrayBoxResourceTypeEnum.Product && !showVariants
        ? `SearchFieldPlaceholderProducts${searchFieldType || ''}`
        : resourceType === GrayBoxResourceTypeEnum.Product &&
          showVariants &&
          isOrderBumpType
        ? `SearchFieldPlaceholderProduct${searchFieldType || ''}`
        : `SearchFieldPlaceholder${searchFieldType || ''}`,
    [resourceType, showVariants, isOrderBumpType]
  );
  const toggleShowList = useCallback(() => setShowList((prev) => !prev), []);

  useEffect(() => {
    setShowList(false);
  }, [currentResourceType]);

  return (
    <Box borderColor='border' borderRadius='200' borderWidth='025'>
      <Box
        padding='400'
        borderColor='border'
        borderBlockEndWidth={showList ? '025' : '0'}
      >
        <InlineStack align='space-between' wrap={false} gap={'200'}>
          <InlineStack gap='200'>
            <InlineStack gap='200' blockAlign='center' wrap={false}>
              <Box>
                <Icon
                  tone={isEmptyResourceList ? 'textCaution' : 'base'}
                  source={
                    isEmptyResourceList ? AlertTriangleIcon : CollectionIcon
                  }
                />
              </Box>
              {isEmptyResourceList && (
                <Text as='p' tone='caution'>
                  {i18n.translate(placeholderTextLabel)}
                </Text>
              )}
              {!!selectedGrayBoxElements?.length && !resourceIsLoading && (
                <Text as='p' fontWeight='regular'>
                  {resourceType === 'Collection'
                    ? i18n.translate('Collections')
                    : i18n.translate('Products')}
                  {': '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: headerTitle(
                        selectedGrayBoxElements,
                        currentResourceType,
                        i18n
                      ),
                    }}
                  ></span>
                </Text>
              )}
              {resourceIsLoading && (
                <CustomSekeletonBox height='8px' width='200px' />
              )}
            </InlineStack>
          </InlineStack>
          <InlineStack gap='200' wrap={false}>
            <Button
              variant='plain'
              id='SearchFieldEditButton'
              disabled={resourceIsLoading}
              onClick={
                selectedGrayBoxElements.length === 0
                  ? () => toggleShowGrayBoxState()
                  : () => toggleShowGrayBoxState(true)
              }
            >
              {i18n.translate('Select')}
            </Button>
            {(resourceIsLoading || selectedGrayBoxElements.length > 0) && (
              <Button
                icon={!showList ? ChevronDownIcon : ChevronUpIcon}
                onClick={() => toggleShowList()}
                variant='monochromePlain'
                disabled={resourceIsLoading}
              />
            )}
          </InlineStack>
        </InlineStack>
      </Box>
      {showList && (
        <SelectedProductList
          currentResourceType={currentResourceType}
          resourceType={resourceType}
          selectedGrayBoxElements={selectedGrayBoxElements}
        />
      )}
    </Box>
  );
};
