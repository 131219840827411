import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  OfferCombinationsDto,
  OfferLevelDto,
  OfferProductLimitDto,
  OfferPrerequisiteEntitledAppliesToDto,
  OfferTierDto,
  OfferTieredSpendXGetYDto,
  OfferDiscountLimitDto,
  OptionDtoOfferMinimumSpendDto,
  ShopifyObjectDto,
  OfferPrerequisiteEntitledNameFiltersDto,
  OptionDtoString,
  OfferPrerequisiteEntitledAdvancedSettingsDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferTieredDiscountTypeDtoEnum,
  OfferTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import { useAppDispatch, useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import {
  setCombinedWithList,
  setCurrentCombinationIsLoaded,
  setLastCombinedWithTypeFetched,
  setSelectedOfferType,
} from 'core/store/offersWizardSlice';
import { OfferTypesFromCatalogData } from '../../../consts/OfferTypesFromCatalogData';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { usePrevious } from 'core/hooks/usePrevious';
import { cloneDeep, isEqual } from 'lodash';
import { SpendXGetYTieredOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { Combinations } from '../../Combinations/Combinations';
import { Discount } from '../../DiscountNew/Discount';
import {
  prerequisiteProductsInitialValues,
  Products,
} from '../../ProductsNew/Products';
import { Tiers } from '../../TiersNew/Tiers';
import { DiscountLimits } from '../../DiscountLimits/DiscountLimits';
import { BlockStack, Page } from '@shopify/polaris';
import { MinimumSpend } from '../../MinimumSpend/MinimumSpend';
import { useI18n } from '@shopify/react-i18n';
import { useNavigate } from 'react-router-dom';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { OfferTierUpdateDto } from '../types/OfferTierUpdateDto';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { v4 as uuidv4 } from 'uuid';

export const SpendAmountOffer: React.FC<OfferParentComponentProps> = (
  props
) => {
  const { offerType, offerTemplate, handleOfferDataUpdate, savedData } = props;

  const dispatch = useAppDispatch();
  const isDebugOrLocal = useIsDebugOrLocal();
  const { validityChangeHandler } = useOfferFormValidity();

  const { currentCombination } = useAppSelector((state) => state.offersWizard);

  const [i18n] = useI18n();

  const [offerData, setOfferData] = useState<OfferTieredSpendXGetYDto | null>(
    offerTemplate?.template as OfferTieredSpendXGetYDto
  );
  const [currentTiers, setCurrentTiers] = useState<OfferTierDto[]>(
    (offerTemplate?.template as OfferTieredSpendXGetYDto)?.tiers || []
  );
  const [isTiersUpdated, setIsTiersUpdated] = useState<boolean>(false);
  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [configureTiers, setConfigureTiers] = useState<boolean>(false);

  const [configureEntitledProducts, setConfigureEntitledProducts] =
    useState<boolean>(false);
  const [configurePrerequisiteProducts, setConfigurePrerequisiteProducts] =
    useState<boolean>(false);

  const prevTiers = usePrevious(currentTiers);
  const navigate = useNavigate();

  const maxAmount = useMemo(
    () =>
      (currentTiers || []).reduce((max, tier) => {
        return tier?.entitledPreciseValue !== null &&
          tier?.entitledPreciseValue !== undefined
          ? Math.max(max, tier.entitledPreciseValue)
          : max;
      }, 0),
    [currentTiers]
  );

  const maxQuantity = useMemo(() => {
    return (currentTiers || []).reduce((max, tier) => {
      return tier?.affectedItems !== null && tier?.affectedItems !== undefined
        ? Math.max(max, tier.affectedItems)
        : max;
    }, 0);
  }, [currentTiers]);

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  const discountTypeDataUpdate = useCallback(
    (type: OfferTieredDiscountTypeDtoEnum) => {
      const offer = OfferTypesFromCatalogData['TieredDiscounts'][0];

      offerType !== OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y &&
        dispatch(setSelectedOfferType(offer));
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        type: type,
      }));
    },
    [setOfferData, dispatch, offerType]
  );

  useEffect(() => {
    if (
      (offerTemplate?.template &&
        offerData &&
        !isEqual(offerData, offerTemplate?.template)) ||
      isTiersUpdated
    ) {
      handleOfferDataUpdate(offerData);
      setIsTiersUpdated(false);
    }
  }, [offerData, offerTemplate, isTiersUpdated]);

  useEffect(() => {
    const tempTiers = cloneDeep(currentTiers);
    if (
      offerData?.type === OfferTieredDiscountTypeDtoEnum.FIXED_AMOUNT &&
      currentTiers[0].entitledPreciseValue === null
    ) {
      tempTiers.map((tier) => {
        tier.entitledPreciseValue = tier.entitledWholeValue;
        tier.entitledWholeValue = null;
        return tier;
      });
      setCurrentTiers(tempTiers);
    } else if (
      offerData?.type === OfferTieredDiscountTypeDtoEnum.PERCENTAGE &&
      currentTiers[0].entitledWholeValue === null
    ) {
      tempTiers.map((tier) => {
        tier.entitledWholeValue = tier.entitledPreciseValue;
        tier.entitledPreciseValue = null;
        return tier;
      });
      setCurrentTiers(tempTiers);
    }
  }, [prevTiers, currentTiers, offerData?.type]);

  useEffect(() => {
    if (prevTiers && currentTiers && !isEqual(currentTiers, prevTiers)) {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        tiers: currentTiers,
      }));
      setIsTiersUpdated(true);
    }
  }, [prevTiers, currentTiers]);

  const levelDataUpdate = useCallback(
    (level: OfferLevelDto) => {
      dispatch(setCurrentCombinationIsLoaded(false));
      dispatch(setCombinedWithList({}));
      dispatch(setLastCombinedWithTypeFetched(null));
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        level: level,
      }));
    },
    [setOfferData, dispatch]
  );

  const appliesToDataUpdate = useCallback(
    (data: OfferPrerequisiteEntitledAppliesToDto) => {
      if (!configurePrerequisiteProducts) {
        // Update products.appliesTo
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          products: {
            ...prevState?.products,
            appliesTo: data,
          },
        }));
      } else {
        // Update appliesTo for the first rule in the first group
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          cartRules: {
            ...prevState?.cartRules,
            groups: prevState?.cartRules?.groups
              ? [
                  {
                    ...prevState?.cartRules?.groups[0],
                    rules: prevState?.cartRules?.groups[0]?.rules
                      ? [
                          {
                            ...prevState?.cartRules?.groups[0]?.rules[0],
                            appliesTo: data,
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          },
        }));
      }
    },
    [setOfferData, configurePrerequisiteProducts]
  );

  const exclusionsUpdate = useCallback(
    (data: boolean) => {
      if (!configurePrerequisiteProducts) {
        // Update products.exclusions.collections.enabled
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          products: {
            ...prevState?.products,
            exclusions: {
              ...prevState?.products?.exclusions,
              collections: {
                ...prevState?.products?.exclusions?.collections,
                enabled: data,
              },
            },
          },
        }));
      } else {
        // Update exclusions.collections.enabled for the first rule in the first group
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          cartRules: {
            ...prevState?.cartRules,
            groups: prevState?.cartRules?.groups
              ? [
                  {
                    ...prevState?.cartRules?.groups[0],
                    rules: prevState?.cartRules?.groups[0]?.rules
                      ? [
                          {
                            ...prevState?.cartRules?.groups[0]?.rules[0],
                            exclusions: {
                              ...prevState?.cartRules?.groups[0]?.rules[0]
                                ?.exclusions,
                              collections: {
                                ...prevState?.cartRules?.groups[0]?.rules[0]
                                  ?.exclusions?.collections,
                                enabled: data,
                              },
                            },
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          },
        }));
      }
    },
    [setOfferData, configurePrerequisiteProducts]
  );

  const excludedResourcesUpdate = useCallback(
    (data: ShopifyObjectDto[]) => {
      if (!configurePrerequisiteProducts) {
        // Update products.exclusions.collections.value
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          products: {
            ...prevState?.products,
            exclusions: {
              ...prevState?.products?.exclusions,
              collections: {
                ...prevState?.products?.exclusions?.collections,
                value: data,
              },
            },
          },
        }));
      } else {
        // Update exclusions.collections.value for the first rule in the first group
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          cartRules: {
            ...prevState?.cartRules,
            groups: prevState?.cartRules?.groups
              ? [
                  {
                    ...prevState?.cartRules?.groups[0],
                    rules: prevState?.cartRules?.groups[0]?.rules
                      ? [
                          {
                            ...prevState?.cartRules?.groups[0]?.rules[0],
                            exclusions: {
                              ...prevState?.cartRules?.groups[0]?.rules[0]
                                ?.exclusions,
                              collections: {
                                ...prevState?.cartRules?.groups[0]?.rules[0]
                                  ?.exclusions?.collections,
                                value: data,
                              },
                            },
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          },
        }));
      }
    },
    [setOfferData, configurePrerequisiteProducts]
  );

  const limitUpdate = useCallback(
    (data: OfferProductLimitDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        products: {
          ...prevState?.products,
          limit: data,
        },
      }));
    },
    [setOfferData]
  );

  const limitUpdateData = useCallback(
    (data: OfferDiscountLimitDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        discountLimit: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const combinationsUpdate = useCallback(
    (data: OfferCombinationsDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        combinations: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const advancedSettingsUpdate = useCallback(
    (
      field: keyof OfferPrerequisiteEntitledAdvancedSettingsDto,
      value: string
    ) => {
      if (!configurePrerequisiteProducts) {
        // Update products.advancedSettings
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          products: {
            ...prevState?.products,
            advancedSettings: {
              ...prevState?.products?.advancedSettings,
              [field]: value,
            },
          },
        }));
      } else {
        // Update advancedSettings for the first rule in the first group
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          cartRules: {
            ...prevState?.cartRules,
            groups: prevState?.cartRules?.groups
              ? [
                  {
                    ...prevState?.cartRules?.groups[0],
                    rules: prevState?.cartRules?.groups[0]?.rules
                      ? [
                          {
                            ...prevState?.cartRules?.groups[0]?.rules[0],
                            advancedSettings: {
                              ...prevState?.cartRules?.groups[0]?.rules[0]
                                ?.advancedSettings,
                              [field]: value,
                            },
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          },
        }));
      }
    },
    [setOfferData, configurePrerequisiteProducts]
  );

  const nameFiltersUpdate = useCallback(
    (
      filterName: keyof OfferPrerequisiteEntitledNameFiltersDto,
      field: keyof OptionDtoString,
      value: string | boolean | null
    ) => {
      if (!configurePrerequisiteProducts) {
        // Update products.nameFilters
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          products: {
            ...prevState?.products,
            nameFilters: {
              ...prevState?.products?.nameFilters,
              [filterName]: {
                ...prevState?.products?.nameFilters?.[filterName],
                [field]: value,
              },
            },
          },
        }));
      } else {
        // Update nameFilters for the first rule in the first group
        setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
          ...prevState,
          cartRules: {
            ...prevState?.cartRules,
            groups: prevState?.cartRules?.groups
              ? [
                  {
                    ...prevState?.cartRules?.groups[0],
                    rules: prevState?.cartRules?.groups[0]?.rules
                      ? [
                          {
                            ...prevState?.cartRules?.groups[0]?.rules[0],
                            nameFilters: {
                              ...prevState?.cartRules?.groups[0]?.rules[0]
                                ?.nameFilters,
                              [filterName]: {
                                ...prevState?.cartRules?.groups[0]?.rules[0]
                                  ?.nameFilters?.[filterName],
                                [field]: value,
                              },
                            },
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          },
        }));
      }
    },
    [setOfferData, configurePrerequisiteProducts]
  );

  const minimumSpendUpdate = useCallback(
    (data: OptionDtoOfferMinimumSpendDto) => {
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        minimumSpend: {
          ...data,
        },
      }));
    },
    [setOfferData]
  );

  const tierUpdate = useCallback(
    (data: OfferTierUpdateDto) => {
      const { tierIndex, tier } = data;
      const tiersCopy = currentTiers ? cloneDeep(currentTiers) : [];
      tiersCopy[tierIndex] = {
        ...tiersCopy[tierIndex],
        ...tier,
      };
      setCurrentTiers(tiersCopy);
    },
    [currentTiers]
  );

  const tierDelete = useCallback(
    (index: number) => {
      const tiersCopy = cloneDeep(currentTiers)?.filter(
        (tier, idx) => idx !== index
      );
      tiersCopy && setCurrentTiers(tiersCopy);
    },
    [currentTiers]
  );

  const clearPrerequisiteProducts = useCallback(() => {
    setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
      ...prevState,
      cartRules: {
        connectors: [],
        applySameSetAsTargetProducts: null,

        groups: [
          {
            order: 0,
            rules: [{ ...prerequisiteProductsInitialValues, id: uuidv4() }],
          },
        ],
      },
    }));
  }, [prerequisiteProductsInitialValues]);

  useEffect(() => {
    currentCombination.currentCombinationType &&
      setOfferData((prevState: OfferTieredSpendXGetYDto | null) => ({
        ...prevState,
        combinationType: currentCombination.currentCombinationType,
      }));
  }, [currentCombination.currentCombinationType]);

  return (
    <>
      {configureTiers && (
        <Tiers
          tiers={currentTiers}
          level={(offerTemplate?.template as OfferTieredSpendXGetYDto)?.level}
          discountType={
            offerData?.type ||
            (offerTemplate?.template as OfferTieredSpendXGetYDto)?.type
          }
          offerType={offerType}
          onFormValidityChange={validityChangeHandler(
            SpendXGetYTieredOfferTabsEnum.Tiers
          )}
          setCurrentTiers={setCurrentTiers}
          setConfigureComponent={setConfigureTiers}
          configureComponent={configureTiers}
          onTierUpdate={tierUpdate}
          onTierDelete={tierDelete}
        />
      )}
      {configureEntitledProducts && (
        <Products
          products={
            (offerTemplate?.template as OfferTieredSpendXGetYDto)?.products
          }
          onAppliesToUpdate={appliesToDataUpdate}
          onExclusionsUpdate={exclusionsUpdate}
          onExcludedResourcesUpdate={excludedResourcesUpdate}
          onLimitUpdate={limitUpdate}
          onFormValidityChange={validityChangeHandler(
            SpendXGetYTieredOfferTabsEnum.Products
          )}
          onNameFiltersUpdate={nameFiltersUpdate}
          onAdvancedSettingsUpdate={advancedSettingsUpdate}
          savedData={
            (savedData?.template as OfferTieredSpendXGetYDto)?.products
          }
          setConfigureComponent={setConfigureEntitledProducts}
          configureComponent={configureEntitledProducts}
          type={
            offerType === OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y
              ? 'Entitled'
              : undefined
          }
        />
      )}
      {offerType === OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y &&
        configurePrerequisiteProducts && (
          <Products
            products={
              (offerTemplate?.template as OfferTieredSpendXGetYDto)?.cartRules
                ?.groups?.[0]?.rules?.[0] ?? undefined
            }
            onAppliesToUpdate={appliesToDataUpdate}
            onExclusionsUpdate={exclusionsUpdate}
            onExcludedResourcesUpdate={excludedResourcesUpdate}
            onLimitUpdate={limitUpdate}
            onFormValidityChange={validityChangeHandler(
              SpendXGetYTieredOfferTabsEnum.CartRules
            )}
            onNameFiltersUpdate={nameFiltersUpdate}
            onAdvancedSettingsUpdate={advancedSettingsUpdate}
            savedData={
              (savedData?.template as OfferTieredSpendXGetYDto)?.cartRules
                ?.groups?.[0]?.rules?.[0] ?? undefined
            }
            setConfigureComponent={setConfigurePrerequisiteProducts}
            configureComponent={configurePrerequisiteProducts}
            type='Prerequisite'
          />
        )}
      {!configureTiers &&
        !configureEntitledProducts &&
        !configurePrerequisiteProducts && (
          <Page
            title={i18n.translate(`ConfigureOffer`)}
            backAction={{
              onAction: async () => {
                !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
                navigate('../..', { relative: 'path' });
              },
            }}
          >
            <BlockStack gap='600'>
              <OfferTemplate
                type={offerType}
                toggleShowHowModal={toggleShowHowModal}
                discountType={
                  (offerTemplate?.template as OfferTieredSpendXGetYDto)
                    .combinationType
                }
              />
              <Discount
                level={
                  (offerTemplate?.template as OfferTieredSpendXGetYDto)?.level
                }
                onLevelUpdate={levelDataUpdate}
                offerType={offerType}
                type={
                  (offerTemplate?.template as OfferTieredSpendXGetYDto).type
                }
                onTypeUpdate={discountTypeDataUpdate}
              />

              <Tiers
                tiers={currentTiers}
                level={
                  (offerTemplate?.template as OfferTieredSpendXGetYDto)?.level
                }
                discountType={offerData?.type}
                offerType={offerType}
                onFormValidityChange={validityChangeHandler(
                  SpendXGetYTieredOfferTabsEnum.Tiers
                )}
                setCurrentTiers={setCurrentTiers}
                setConfigureComponent={setConfigureTiers}
                configureComponent={configureTiers}
                onTierUpdate={tierUpdate}
                onTierDelete={tierDelete}
              />
              {(offerTemplate?.template as OfferTieredSpendXGetYDto)
                ?.products !== undefined && (
                <Products
                  products={
                    (offerTemplate?.template as OfferTieredSpendXGetYDto)
                      ?.products
                  }
                  onAppliesToUpdate={appliesToDataUpdate}
                  onExclusionsUpdate={exclusionsUpdate}
                  onExcludedResourcesUpdate={excludedResourcesUpdate}
                  onLimitUpdate={limitUpdate}
                  onNameFiltersUpdate={nameFiltersUpdate}
                  onAdvancedSettingsUpdate={advancedSettingsUpdate}
                  onFormValidityChange={validityChangeHandler(
                    SpendXGetYTieredOfferTabsEnum.Products
                  )}
                  savedData={
                    (savedData?.template as OfferTieredSpendXGetYDto)?.products
                  }
                  setConfigureComponent={setConfigureEntitledProducts}
                  configureComponent={configureEntitledProducts}
                  type={
                    offerType === OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y
                      ? 'Entitled'
                      : undefined
                  }
                />
              )}
              {offerType === OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y && (
                <Products
                  products={
                    (offerTemplate?.template as OfferTieredSpendXGetYDto)
                      ?.cartRules?.groups?.[0]?.rules?.[0] ?? undefined
                  }
                  onAppliesToUpdate={appliesToDataUpdate}
                  onExclusionsUpdate={exclusionsUpdate}
                  onExcludedResourcesUpdate={excludedResourcesUpdate}
                  onLimitUpdate={limitUpdate}
                  onFormValidityChange={validityChangeHandler(
                    SpendXGetYTieredOfferTabsEnum.CartRules
                  )}
                  onNameFiltersUpdate={nameFiltersUpdate}
                  onAdvancedSettingsUpdate={advancedSettingsUpdate}
                  savedData={
                    (savedData?.template as OfferTieredSpendXGetYDto)?.cartRules
                      ?.groups?.[0]?.rules?.[0] ?? undefined
                  }
                  onClearPrerequisiteProducts={clearPrerequisiteProducts}
                  setConfigureComponent={setConfigurePrerequisiteProducts}
                  configureComponent={configurePrerequisiteProducts}
                  type='Prerequisite'
                />
              )}
              <DiscountLimits
                type='Discount'
                discountLimit={
                  (offerTemplate?.template as OfferTieredSpendXGetYDto)
                    ?.discountLimit
                }
                maxAmount={maxAmount}
                maxQuantity={maxQuantity}
                discountLimitUpdate={limitUpdateData}
                onFormValidityChange={validityChangeHandler(
                  SpendXGetYTieredOfferTabsEnum.Limit
                )}
              />
              <Combinations onCombinationsChange={combinationsUpdate} />
              {offerTemplate?.minimumSpendSupported && (
                <MinimumSpend
                  minimumSpend={
                    (offerTemplate?.template as OfferTieredSpendXGetYDto)
                      ?.minimumSpend || {}
                  }
                  minimumSpendUpdate={minimumSpendUpdate}
                />
              )}
              {showHowModal && (
                <LearnMoreModal
                  onModalClose={toggleShowHowModal}
                  // url='https://youtu.be/9htdypfdlBM'
                  title={i18n.translate('Tutorial')}
                />
              )}
            </BlockStack>
          </Page>
        )}
    </>
  );
};
