// import React, { useCallback, useEffect, useMemo, useState } from 'react';
// import {
//   Badge,
//   BlockStack,
//   Card,
//   ChoiceList,
//   InlineStack,
//   Text,
// } from '@shopify/polaris';
// import { useDispatch } from 'react-redux';
// import {
//   SettingsFetchTypeEnum,
//   useConfigureSettings,
// } from 'features/settings/hooks/useConfigureSettings';
// import { isEmpty, isEqual } from 'lodash';
// import {
//   setIsUnsavedChanges,
//   setOnDiscardChanges,
//   setOnSaveChanges,
// } from 'core/store/settingsSlice';
// import {
//   DiscountNinjaPromotionCodesShopSettingsDto,
//   WidgetSettingsDtoDiscountNinjaPromotionCodesShopSettingsDto,
// } from 'core/api/adminSettings/adminSettingsApi';
// import {
//   CodesSupportSetting,
//   WidgetSettingsWrapper,
//   WidgetTypeSettingsWrapperEnum,
// } from '../components';
// import { useI18n } from '@shopify/react-i18n';
// import {
//   DiscountNinjaPromotionCodesActivationModeDtoEnum,
//   DiscountNinjaPromotionCodesExpirationModeDtoEnum,
// } from 'core/api/adminSettings/adminSettingsEnums';

// export const DiscountNinjaCodesSetting: React.FC = () => {
//   const dispatch = useDispatch();
//   const {
//     discountNinjaCodesData,
//     saveDiscountNinjaCodes,
//     discountNinjaCodesDataIsFetching,
//     saveDiscountNinjaCodesIsLoading,
//   } = useConfigureSettings(
//     SettingsFetchTypeEnum.DISCOUNT_NINJA_PROMOTION_CODES
//   );
//   const [i18n] = useI18n();
//   const [savedData, setSavedData] =
//     useState<WidgetSettingsDtoDiscountNinjaPromotionCodesShopSettingsDto>({});
//   const [discountNinjaCodesState, setDiscountNinjaCodesState] =
//     useState<WidgetSettingsDtoDiscountNinjaPromotionCodesShopSettingsDto>({});

//   const updateDiscountNinjaCodesState = useCallback(
//     (
//       field: keyof DiscountNinjaPromotionCodesShopSettingsDto,
//       data: DiscountNinjaPromotionCodesShopSettingsDto[keyof DiscountNinjaPromotionCodesShopSettingsDto]
//     ) =>
//       setDiscountNinjaCodesState((prev) => ({
//         ...prev,
//         settings: {
//           ...prev.settings,
//           [field]: data,
//         },
//       })),
//     [setDiscountNinjaCodesState]
//   );

//   const activationOptions = useMemo(
//     () =>
//       Object.values(DiscountNinjaPromotionCodesActivationModeDtoEnum).map(
//         (item) => ({
//           label: (
//             <InlineStack gap='200'>
//               {i18n.translate(`${item}`)}
//               {item ===
//                 DiscountNinjaPromotionCodesActivationModeDtoEnum.ANY_TAB && (
//                 <Badge>{i18n.translate('Recommended')}</Badge>
//               )}
//             </InlineStack>
//           ),
//           value: item,
//           helpText: i18n.translate(`${item}_SUBTITLE`),
//         })
//       ),
//     []
//   );

//   const expirationOptions = useMemo(
//     () =>
//       Object.values(DiscountNinjaPromotionCodesExpirationModeDtoEnum).map(
//         (item) => ({
//           label: i18n.translate(`${item}`),
//           value: item,
//         })
//       ),
//     []
//   );

//   useEffect(() => {
//     if (
//       !isEmpty(discountNinjaCodesState) &&
//       !isEqual(savedData, discountNinjaCodesState)
//     ) {
//       dispatch(setIsUnsavedChanges(true));
//       dispatch(
//         setOnDiscardChanges(
//           () => !isEmpty(savedData) && setDiscountNinjaCodesState(savedData)
//         )
//       );
//       dispatch(
//         setOnSaveChanges(() => {
//           setSavedData(discountNinjaCodesState);
//           return saveDiscountNinjaCodes(discountNinjaCodesState.settings || {});
//         })
//       );
//     } else if (isEqual(savedData, discountNinjaCodesState)) {
//       dispatch(setIsUnsavedChanges(false));
//     }
//   }, [discountNinjaCodesState, savedData, dispatch]);

//   useEffect(() => {
//     if (!isEmpty(discountNinjaCodesData)) {
//       setDiscountNinjaCodesState(discountNinjaCodesData);
//       setSavedData(discountNinjaCodesData);
//     }
//   }, [discountNinjaCodesData]);

//   useEffect(() => {
//     return () => {
//       dispatch(setIsUnsavedChanges(false));
//     };
//   }, []);

//   return (
//     <div className='DiscountNinjaCodesSetting'>
//       <WidgetSettingsWrapper
//         widgetState={{
//           ...discountNinjaCodesState,
//           widgetType:
//             WidgetTypeSettingsWrapperEnum.DISCOUNT_NINJA_PROMOTION_CODES,
//         }}
//         isLoading={discountNinjaCodesDataIsFetching || !discountNinjaCodesData}
//         setEnabled={(value) =>
//           setDiscountNinjaCodesState({
//             ...discountNinjaCodesState,
//             status: value,
//           })
//         }
//       >
//         <BlockStack gap='600'>
//           <CodesSupportSetting
//             allow={!!discountNinjaCodesState.settings?.allowMultipleCodes}
//             setAllow={(value) =>
//               updateDiscountNinjaCodesState('allowMultipleCodes', value)
//             }
//           />
//           <Card>
//             <BlockStack gap='400'>
//               <BlockStack gap='100'>
//                 <Text as='p' fontWeight='bold'>
//                   {i18n.translate('PromotionCodeActivation')}
//                 </Text>
//                 <Text as='p' tone='subdued'>
//                   {i18n.translate('ConfigureHowPromotion')}
//                 </Text>
//               </BlockStack>
//               <ChoiceList
//                 title=''
//                 choices={activationOptions}
//                 selected={[
//                   discountNinjaCodesState.settings?.activation
//                     ?.activationMode ||
//                     DiscountNinjaPromotionCodesActivationModeDtoEnum.ANY_TAB,
//                 ]}
//                 onChange={(value) =>
//                   updateDiscountNinjaCodesState('activation', {
//                     ...discountNinjaCodesState.settings?.activation,
//                     activationMode:
//                       value[0] as DiscountNinjaPromotionCodesActivationModeDtoEnum,
//                   })
//                 }
//               />
//               {discountNinjaCodesState.settings?.activation?.activationMode ===
//                 DiscountNinjaPromotionCodesActivationModeDtoEnum.WHERE_ENTERED_TAB && (
//                 <ChoiceList
//                   title={i18n.translate('PromotionCodeExpiration')}
//                   choices={expirationOptions}
//                   selected={[
//                     discountNinjaCodesState.settings?.activation
//                       ?.expirationMode ||
//                       DiscountNinjaPromotionCodesExpirationModeDtoEnum.NEVER,
//                   ]}
//                   onChange={(value) =>
//                     updateDiscountNinjaCodesState('activation', {
//                       ...discountNinjaCodesState.settings?.activation,
//                       expirationMode:
//                         value[0] as DiscountNinjaPromotionCodesExpirationModeDtoEnum,
//                     })
//                   }
//                 />
//               )}
//             </BlockStack>
//           </Card>
//         </BlockStack>
//       </WidgetSettingsWrapper>
//     </div>
//   );
// };
import React from 'react';

export const DiscountNinjaCodesSetting = () => {
  return <div>DiscountNinjaCodesSetting</div>;
};
