import React, { useMemo } from 'react';
import { List, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  PromotionFiltersSettingsDto,
  TriggerTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  FilterTypeDtoEnum,
  TriggerIntendedAudienceTypeDtoEnum,
  TriggerTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  PromotionFetchTypeEnum,
  useConfigurePromotions,
} from 'features/promotions/hooks/useConfigurePromotion';

type FilterSettingsSummaryProps = {
  filters?: PromotionFiltersSettingsDto;
  triggerType?: TriggerTypeDto;
};
export const FilterSettingsSummary: React.FC<FilterSettingsSummaryProps> = ({
  filters,
  triggerType,
}) => {
  const [i18n] = useI18n();
  const {
    geolocationFilter,
    marketFilter,
    customerFilter,
    referrerFilter,
    visitorBehavior,
  } = filters || {};

  const { intendedAudienceFilter, purchaseHistoryFilter } =
    customerFilter?.value || {};

  const { marketListData: availableMarkets } = useConfigurePromotions(
    marketFilter?.enabled ? PromotionFetchTypeEnum.MARKETS : undefined
  );

  const marketList = useMemo(
    () =>
      availableMarkets
        ?.filter((market) =>
          marketFilter?.value?.marketIds?.includes(market.id as string)
        )
        .map((value) => value.name),
    [availableMarkets, marketFilter?.value?.marketIds]
  );

  const isVisitorBehaviorRule = useMemo(
    () =>
      triggerType === TriggerTypeDtoEnum.PUBLIC &&
      visitorBehavior?.enabled &&
      (visitorBehavior.value?.exitIntentEnabled ||
        visitorBehavior.value?.numberOfPagesVisited?.enabled ||
        visitorBehavior.value?.percentageOfPageViewed?.enabled ||
        visitorBehavior.value?.timeSpentOnPage?.enabled),
    [visitorBehavior, triggerType]
  );

  const showFilters = useMemo(
    () =>
      isVisitorBehaviorRule ||
      customerFilter?.enabled ||
      (marketFilter?.enabled && !!marketList?.length) ||
      (geolocationFilter?.enabled &&
        !!geolocationFilter.value?.countries?.length) ||
      (referrerFilter?.enabled && !!referrerFilter.value?.domainNames?.length),
    [
      isVisitorBehaviorRule,
      customerFilter?.enabled,
      marketFilter?.enabled,
      marketList,
      geolocationFilter,
      referrerFilter,
    ]
  );

  return showFilters ? (
    <List type='bullet'>
      {isVisitorBehaviorRule && (
        <List.Item>{i18n.translate('VisitorBehaviorRuleSet')}</List.Item>
      )}
      {customerFilter?.enabled && (
        <List.Item>
          {i18n.translate('Audience', {
            type: i18n.translate(`${intendedAudienceFilter?.type}`),
          })}
          {!!intendedAudienceFilter?.tags?.length &&
            intendedAudienceFilter.type !==
              TriggerIntendedAudienceTypeDtoEnum.GUESTS_ONLY && (
              <Text as='span'>{`(${intendedAudienceFilter?.tags?.join(
                ', '
              )})`}</Text>
            )}
        </List.Item>
      )}
      {customerFilter?.enabled && (
        <List.Item>
          {i18n.translate('PurchaseHistory', {
            type: i18n.translate(`${purchaseHistoryFilter?.type}`, {
              from: purchaseHistoryFilter?.from,
              to: purchaseHistoryFilter?.to,
            }),
          })}
        </List.Item>
      )}
      {marketFilter?.enabled && !!marketList?.length && (
        <List.Item>
          {i18n.translate('Market')}
          <Text as='span'>
            {marketFilter.value?.type === FilterTypeDtoEnum.EXCLUDED &&
              i18n.translate('Excluding')}
            {marketList.join(', ')}
          </Text>
        </List.Item>
      )}
      {geolocationFilter?.enabled &&
        !!geolocationFilter.value?.countries?.length && (
          <List.Item>
            {i18n.translate('Countries')}
            <Text as='span'>
              {geolocationFilter.value?.type === FilterTypeDtoEnum.EXCLUDED &&
                i18n.translate('Excluding')}
              {geolocationFilter.value?.countries.join(', ')}
            </Text>
          </List.Item>
        )}
      {referrerFilter?.enabled && !!referrerFilter.value?.domainNames?.length && (
        <List.Item>
          {i18n.translate('Referrer')}
          <Text as='span'>
            {referrerFilter.value?.type === FilterTypeDtoEnum.EXCLUDED &&
              i18n.translate('Excluding')}
            {referrerFilter.value?.domainNames.join(', ')}
          </Text>
        </List.Item>
      )}
    </List>
  ) : (
    <Text as='p' tone='subdued'>
      {i18n.translate('NotConfigured')}
    </Text>
  );
};
