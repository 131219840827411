import React from 'react';
import { Banner, BlockStack, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PromotionStatusDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { formatDateWithOrdinalCET } from 'core/utils';

type ExpiredBannerProps = {
  status: PromotionStatusDto;
  disabled: boolean;
  usedDate?: number | null;
  onClose: () => void;
  onUnpublish: () => void;
};
export const ExpiredBanner: React.FC<ExpiredBannerProps> = ({
  status,
  usedDate,
  disabled,
  onClose,
  onUnpublish,
}) => {
  const [i18n] = useI18n();
  return (
    <Banner
      onDismiss={onClose}
      tone='info'
      title={i18n.translate(`${status}_BANNER_TITLE`)}
      action={{
        onAction: onUnpublish,
        disabled: disabled,
        content: i18n.translate('MoveToDraft'),
      }}
    >
      <BlockStack gap='200'>
        <Text as='p'>
          {i18n.translate(`${status}_BANNER_SUBTITLE`, {
            usedDate: (
              <Text as='span' fontWeight='medium'>
                {usedDate && formatDateWithOrdinalCET(usedDate?.toString())}
              </Text>
            ),
          })}
        </Text>
      </BlockStack>
    </Banner>
  );
};
