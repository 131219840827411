import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Element } from 'react-scroll';
import {
  ActionList,
  Badge,
  Banner,
  BlockStack,
  Button,
  Card,
  ChoiceList,
  Icon,
  IconSource,
  InlineGrid,
  InlineStack,
  LegacyCard,
  Link,
  Modal,
  Page,
  Pagination,
  Popover,
  ResourceList,
  Tabs,
  Text,
  TextField,
  Select as SelectShopify,
} from '@shopify/polaris';
import {
  InfoIcon,
  MenuHorizontalIcon,
  SelectIcon,
} from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import useFetchPresetList from 'features/settings/hooks/useFetchPresetList/useFetchPresetList';
import {
  PresetBoundariesTypeDtoEnum,
  PresetTypeDtoEnum,
} from 'core/api/adminWidgets/adminWidgetsEnums';
import { Loader, Select } from 'core/components';
import ColorBlock from '../ColorBlock/ColorBlock';
import './ShopLevelStylePresetsPage.scss';
import {
  LookupWithPreselectedItemPagedResponseDtoPresetLookupDto,
  PresetPreviewDto,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { presetsPerPage } from '../../Style';
import { useConfigureWidgets } from 'features/settings/hooks/useConfigureWidgets';
import { usePrevious } from 'core/hooks/usePrevious';
import { PresetsListModal } from '../PresetsListModal/PresetsListModal';
import MomentAgo from 'core/components/MomentAgo/MomentAgo';
import resolveEnvVar from 'env-var-resolver';
import { SettingsSkeleton } from '../../../components/SettingsSkeleton/SettingsSkeleton';
import { SettingsConfirmationModal } from 'features/settings/components/SettingsConfirmationModal/SettingsConfirmationModal';
import { NavPaths } from 'core/enums/NavPathsEnum';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const ShopLevelStylePresetsPage: React.FC = () => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();
  const location = useLocation();

  const {
    fetchList,
    getCurrentShopLevelDefaultStylePreview,
    stylePresetsLibraryIsFetching,
    fetchAllListForSelector,
  } = useFetchPresetList();

  const {
    createNewStylePreset,
    deletePreset,
    duplicatePreset,
    renamePreset,
    setAsDefaultPreset,
  } = useConfigureWidgets();

  const { defaultShopLevelStylePreset, shopLevelStylePresets } = useAppSelector(
    (state) => state.widgets
  );
  const { isUnsavedChanges } = useAppSelector((state) => state.settings);

  const tabs = [
    {
      id: 'allPresets',
      content: i18n.translate('allPresets'),
    },
    {
      id: 'systemPresets',
      content: i18n.translate('systemPresets'),
    },
    {
      id: 'myPresets',
      content: i18n.translate('myPresets'),
    },
  ];

  const [isBannerOpened, setIsBannerOpened] = useState<boolean>(true);
  const [changeDefaultPresetModalOpen, setChangeDefaultPresetModalOpen] =
    useState<boolean>(false);
  const [selected, setSelected] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [activePopover, setActivePopover] = useState<string>('');
  const [createStylePresetModalOpen, setCreateStylePresetModalOpen] =
    useState<boolean>(false);
  const [
    createStylePresetRenameModalOpen,
    setCreateStylePresetRenameModalOpen,
  ] = useState<boolean>(false);
  const [textFieldValue, setTextFieldValue] = useState<string>('');
  const [selectedTypeToCreate, setSelectedTypeToCreate] = useState<string[]>([
    'startFromScratch',
  ]);
  const [refreshList, setRefreshList] = useState<number>(0);
  const [refreshCurrentDefaultPreset, setRefreshCurrentDefaultPreset] =
    useState<number>(0);
  const [currentPreset, setCurrentPreset] = useState<PresetPreviewDto | null>(
    null
  );
  const [presetOptions, setPresetOptions] =
    useState<LookupWithPreselectedItemPagedResponseDtoPresetLookupDto | null>(
      null
    );
  const [selectedPreset, setSelectedPreset] = useState<string>('');
  const [shopLevelStylePresetsItemsState, setShopLevelStylePresetsItemsState] =
    useState<PresetPreviewDto[] | undefined>([]);
  const [selectedOption, setSelectedOption] = useState('default');
  const [modalActive, setModalActive] = useState<boolean>(false);

  const currentBasePresetId = useMemo(
    () =>
      selectedTypeToCreate[0] === 'startFromScratch' ? null : selectedPreset,
    [selectedPreset, selectedTypeToCreate]
  );

  const currentType = useMemo(() => {
    if (tabs[selected].id === 'allPresets') {
      return undefined;
    } else if (tabs[selected].id === 'systemPresets') {
      return PresetTypeDtoEnum.SYSTEM;
    } else if (tabs[selected].id === 'myPresets') {
      return PresetTypeDtoEnum.CUSTOM;
    }
  }, [tabs, selected]);

  const totalPages: number | undefined = useMemo(
    () => Math.ceil((shopLevelStylePresets?.totalItems || 1) / presetsPerPage),
    [shopLevelStylePresets?.totalItems, presetsPerPage]
  );
  const prevType = usePrevious(currentType);
  const prevPage = usePrevious(page);

  const options = [
    { label: i18n.translate('default'), value: 'default' },
    { label: i18n.translate('newestUpdate'), value: 'newestUpdate' },
    { label: i18n.translate('oldestUpdate'), value: 'oldestUpdate' },
    { label: i18n.translate('nameAlpha'), value: 'nameAlpha' },
    { label: i18n.translate('nameReverseAlpha'), value: 'nameReverseAlpha' },
  ];

  const handleTabChange = useCallback((selectedTabIndex: number) => {
    setPage(1);
    setSelected(selectedTabIndex);
  }, []);

  const handleSetDefault = useCallback(() => {
    setRefreshCurrentDefaultPreset((prev) => prev + 1);
    setRefreshList((prev) => prev + 1);
  }, [setRefreshCurrentDefaultPreset, setRefreshList]);

  const handleRedirectBack = useCallback(() => {
    setSearchParams((params) => {
      params.delete('subPath');
      return params;
    });
    setModalActive(false);
  }, [setSearchParams, setModalActive]);

  const handleModalClose = useCallback(() => {
    setModalActive(false);
  }, [setModalActive]);

  const onBackActionClick = useCallback(async () => {
    if (!isDebugOrLocal && isUnsavedChanges) {
      if (location.pathname.includes(`/${NavPaths.Settings}`)) {
        await shopify.saveBar.leaveConfirmation();
        handleRedirectBack();
      } else {
        setModalActive(true);
      }
    } else {
      handleRedirectBack();
    }
  }, [
    isDebugOrLocal,
    isUnsavedChanges,
    location,
    handleRedirectBack,
    setModalActive,
  ]);

  const handleCloseCreateModal = useCallback(() => {
    setCreateStylePresetModalOpen(!createStylePresetModalOpen);
    setTextFieldValue('');
    setSelectedTypeToCreate(['startFromScratch']);
    setSelectedPreset(presetOptions?.preselectedItem?.id || '');
    setPresetOptions(null);
  }, [createStylePresetModalOpen, presetOptions]);

  const handleCloseRenameModal = useCallback(() => {
    setCreateStylePresetRenameModalOpen(!createStylePresetRenameModalOpen);
    setCurrentPreset(null);
  }, [createStylePresetRenameModalOpen]);

  const navigateToEditor = useCallback(
    async (id: string) => {
      !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
      navigate(`/${NavPaths.ConfigureWidgets}/${id}`);
    },
    [navigate, isDebugOrLocal]
  );

  useEffect(() => {
    !shopLevelStylePresets &&
      fetchList({
        type: currentType,
        boundaries: PresetBoundariesTypeDtoEnum.SHOP_LEVEL,
        page: page,
        search: '',
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        itemsPerPage: presetsPerPage,
      });
    !defaultShopLevelStylePreset &&
      getCurrentShopLevelDefaultStylePreview({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      });
  }, []);

  useEffect(() => {
    if (currentType !== prevType || (prevPage && page !== prevPage)) {
      fetchList({
        type: currentType,
        boundaries: PresetBoundariesTypeDtoEnum.SHOP_LEVEL,
        page: page,
        search: '',
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        itemsPerPage: presetsPerPage,
      });
    }
  }, [currentType, page]);

  useEffect(() => {
    refreshList &&
      fetchList({
        type: currentType,
        boundaries: PresetBoundariesTypeDtoEnum.SHOP_LEVEL,
        page: page,
        search: '',
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        itemsPerPage: presetsPerPage,
      });
  }, [refreshList]);

  useEffect(() => {
    refreshCurrentDefaultPreset &&
      getCurrentShopLevelDefaultStylePreview({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      });
  }, [refreshCurrentDefaultPreset]);

  const handleSelectChange = useCallback(
    (value: string) => setSelectedOption(value),
    []
  );

  useEffect(() => {
    if (shopLevelStylePresets?.items?.length) {
      setShopLevelStylePresetsItemsState(shopLevelStylePresets?.items);
    }
  }, [shopLevelStylePresets?.items, selected]);

  useEffect(() => {
    if (
      shopLevelStylePresets?.items &&
      shopLevelStylePresetsItemsState?.length
    ) {
      switch (selectedOption) {
        case 'default':
          setShopLevelStylePresetsItemsState(shopLevelStylePresets?.items);
          break;
        case 'newestUpdate':
          setShopLevelStylePresetsItemsState(
            [...(shopLevelStylePresets?.items || [])].sort(
              (a: PresetPreviewDto, b: PresetPreviewDto) =>
                (b.lastModifiedAt || 0) - (a.lastModifiedAt || 0)
            )
          );
          break;
        case 'oldestUpdate':
          setShopLevelStylePresetsItemsState(
            [...(shopLevelStylePresets?.items || [])].sort(
              (a: PresetPreviewDto, b: PresetPreviewDto) =>
                (a.lastModifiedAt || 0) - (b.lastModifiedAt || 0)
            )
          );
          break;
        case 'nameAlpha':
          setShopLevelStylePresetsItemsState(
            [...(shopLevelStylePresets?.items || [])].sort(
              (a: PresetPreviewDto, b: PresetPreviewDto) =>
                (a.name || '').localeCompare(b.name || '')
            )
          );
          break;
        case 'nameReverseAlpha':
          setShopLevelStylePresetsItemsState(
            [...(shopLevelStylePresets?.items || [])].sort(
              (a: PresetPreviewDto, b: PresetPreviewDto) =>
                (b.name || '').localeCompare(a.name || '')
            )
          );
          break;
        default:
          setShopLevelStylePresetsItemsState(shopLevelStylePresets?.items);
          break;
      }
    }
  }, [selectedOption, shopLevelStylePresets, shopLevelStylePresetsItemsState]);

  return (
    <>
      {shopLevelStylePresets && defaultShopLevelStylePreset ? (
        <Page
          backAction={{ content: 'StylePresets', onAction: onBackActionClick }}
          title={i18n.translate('shopLevelStylePresets')}
          primaryAction={
            <Button
              variant='primary'
              onClick={() =>
                setCreateStylePresetModalOpen(!createStylePresetModalOpen)
              }
            >
              {i18n.translate('createStylePreset')}
            </Button>
          }
        >
          <BlockStack gap='600'>
            <Element name='DefaultStyle'>
              <Card roundedAbove='sm'>
                <BlockStack gap='200'>
                  <InlineGrid columns='1fr auto'>
                    <InlineStack gap={'100'}>
                      <Text as='h2' variant='headingSm'>
                        {i18n.translate('defaultStyle')}
                      </Text>
                      <div>
                        <Icon tone='subdued' source={InfoIcon} />
                      </div>
                    </InlineStack>
                    <Button
                      variant='plain'
                      onClick={() =>
                        setChangeDefaultPresetModalOpen((prev) => !prev)
                      }
                    >
                      {i18n.translate('changeDefaultStyle')}
                    </Button>
                  </InlineGrid>
                  <Text as='p' variant='bodyMd' tone='subdued'>
                    {i18n.translate('defaultStyleSubtitle', {
                      globalWidgets: (
                        <Link
                          url='http://www.google.com'
                          monochrome
                          target='_blank'
                        >
                          {i18n.translate('globalWidgets')}
                        </Link>
                      ),
                    })}
                  </Text>
                </BlockStack>
                <div style={{ marginTop: 16 }}>
                  <Card roundedAbove='sm'>
                    <div className='level'>
                      <InlineStack gap={'300'} blockAlign='center'>
                        <ColorBlock
                          colors={defaultShopLevelStylePreset?.colors || []}
                        />
                        <BlockStack gap='0'>
                          <Text as='p'>
                            {defaultShopLevelStylePreset?.name}
                          </Text>
                          <InlineStack gap='100'>
                            <Text tone='subdued' as='p'>
                              {`${
                                defaultShopLevelStylePreset.type &&
                                i18n.translate(defaultShopLevelStylePreset.type)
                              }`}
                            </Text>
                            {defaultShopLevelStylePreset.lastModifiedAt && (
                              <MomentAgo
                                timestamp={
                                  defaultShopLevelStylePreset.lastModifiedAt
                                }
                              />
                            )}
                          </InlineStack>
                        </BlockStack>
                      </InlineStack>
                      <Button
                        onClick={() =>
                          navigateToEditor(
                            defaultShopLevelStylePreset?.id as string
                          )
                        }
                        size='medium'
                      >
                        {i18n.translate('configure')}
                      </Button>
                    </div>
                  </Card>
                </div>
                {isBannerOpened && (
                  <div style={{ marginTop: 16 }}>
                    <Banner
                      tone='info'
                      onDismiss={() => setIsBannerOpened(false)}
                    >
                      {i18n.translate('defaultStyleBanner')}
                    </Banner>
                  </div>
                )}
              </Card>
            </Element>
            <Element name='PresetsList'>
              <LegacyCard>
                <div className='presetsListWrapper'>
                  <Tabs
                    tabs={tabs}
                    selected={selected}
                    onSelect={handleTabChange}
                  />
                  <div
                    style={{
                      marginTop: 16,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text as='p'>
                      {`${shopLevelStylePresets.totalItems} ${
                        shopLevelStylePresets.totalItems === 1
                          ? i18n.translate('stylePreset')
                          : i18n.translate('stylePresets')
                      }`}
                    </Text>
                    <SelectShopify
                      label={i18n.translate('sortBy')}
                      labelInline
                      options={options}
                      onChange={handleSelectChange}
                      value={selectedOption}
                    />
                  </div>
                </div>
                {shopLevelStylePresetsItemsState && (
                  <ResourceList
                    items={shopLevelStylePresetsItemsState}
                    loading={stylePresetsLibraryIsFetching}
                    renderItem={(item: PresetPreviewDto) => {
                      const {
                        colors,
                        name,
                        type,
                        lastModifiedAt,
                        isDefault,
                        id,
                      } = item;
                      return (
                        <div className='listItem'>
                          <InlineStack gap={'300'} blockAlign='center'>
                            <ColorBlock colors={colors || []} />
                            <BlockStack gap='0'>
                              <InlineStack gap='200'>
                                <Text as='p'>{name}</Text>
                                {isDefault && (
                                  <Badge>{i18n.translate('default')}</Badge>
                                )}
                              </InlineStack>
                              <InlineStack gap='100'>
                                <Text tone='subdued' as='p'>
                                  {`${type && i18n.translate(type)}`}
                                </Text>
                                {lastModifiedAt && (
                                  <MomentAgo timestamp={lastModifiedAt} />
                                )}
                              </InlineStack>
                            </BlockStack>
                          </InlineStack>
                          <InlineStack gap={'400'} blockAlign='center'>
                            <div className='menuHorizontalIcon'>
                              <Popover
                                active={id === activePopover}
                                activator={
                                  <Button
                                    onClick={() =>
                                      setActivePopover(id as string)
                                    }
                                    icon={MenuHorizontalIcon}
                                  />
                                }
                                autofocusTarget='first-node'
                                onClose={() => setActivePopover('')}
                              >
                                <ActionList
                                  actionRole='menuitem'
                                  items={[
                                    {
                                      content: i18n.translate('publish'),
                                      disabled: isDefault,
                                      onAction: () =>
                                        setAsDefaultPreset(
                                          id as string,
                                          handleSetDefault
                                        ),
                                    },
                                    {
                                      content: i18n.translate('duplicate'),
                                      onAction: () =>
                                        duplicatePreset(
                                          id as string,
                                          setRefreshList
                                        ),
                                    },
                                    {
                                      content: i18n.translate('rename'),
                                      onAction: () => {
                                        setCreateStylePresetRenameModalOpen(
                                          !createStylePresetRenameModalOpen
                                        );
                                        setCurrentPreset(item);
                                      },
                                    },
                                    {
                                      content: i18n.translate('delete'),
                                      destructive: true,
                                      onAction: () =>
                                        deletePreset(
                                          id as string,
                                          setRefreshList
                                        ),
                                      disabled: !isDefault,
                                    },
                                  ]}
                                  onActionAnyItem={() => setActivePopover('')}
                                />
                              </Popover>
                            </div>
                            <Button size='medium' disabled={isDefault}>
                              {i18n.translate('publish')}
                            </Button>
                            <Button
                              onClick={() => navigateToEditor(id as string)}
                              size='medium'
                            >
                              {i18n.translate('configure')}
                            </Button>
                          </InlineStack>
                        </div>
                      );
                    }}
                  />
                )}
                {!shopLevelStylePresets.totalItems && (
                  <div className='emptyState'>
                    {!stylePresetsLibraryIsFetching ? (
                      <Text as='p' variant='bodyMd' tone='subdued'>
                        {i18n.translate('emptyState', {
                          type: `“${tabs[selected].content}”`,
                        })}
                      </Text>
                    ) : (
                      <Loader fullWidth />
                    )}
                  </div>
                )}
                <div className='presetsListPagination'>
                  <Pagination
                    hasPrevious={page > 1}
                    onPrevious={() => {
                      setPage((prev) => prev - 1);
                    }}
                    hasNext={page < totalPages}
                    onNext={() => {
                      setPage((prev) => prev + 1);
                    }}
                  />
                </div>
              </LegacyCard>
              <div style={{ marginTop: 16 }}>
                <Text as='h3' alignment='center'>
                  {i18n.translate(`learnMoreAbout`)}{' '}
                  <Link url='http://www.google.com' target='_blank'>
                    {i18n.translate(`StylePresets`)}
                  </Link>
                </Text>
              </div>
            </Element>
          </BlockStack>
        </Page>
      ) : (
        <SettingsSkeleton />
      )}
      {createStylePresetModalOpen && (
        <Modal
          open={createStylePresetModalOpen}
          onClose={handleCloseCreateModal}
          title={i18n.translate('createShopLevelStyle')}
          primaryAction={{
            content: i18n.translate('create'),
            onAction: () => {
              createNewStylePreset(
                {
                  basePresetId: currentBasePresetId,
                  name: textFieldValue,
                  boundaries: PresetBoundariesTypeDtoEnum.SHOP_LEVEL,
                },
                setRefreshList
              );
              handleCloseCreateModal();
            },
            disabled: !textFieldValue,
          }}
          secondaryActions={[
            {
              content: i18n.translate('cancel'),
              onAction: handleCloseCreateModal,
            },
          ]}
        >
          <Modal.Section>
            <BlockStack gap='400'>
              <TextField
                label={i18n.translate('textFieldLabel')}
                value={textFieldValue}
                onChange={(value: string) => setTextFieldValue(value)}
                placeholder={i18n.translate('textFieldPlaceHolder')}
                autoComplete='off'
                error={!textFieldValue && i18n.translate('error')}
              />
              <ChoiceList
                title=''
                titleHidden
                choices={[
                  {
                    label: i18n.translate('startFromScratch'),
                    value: 'startFromScratch',
                  },
                  {
                    label: i18n.translate('useOtherStylePresetAsStartingPoint'),
                    value: 'useOtherStylePresetAsStartingPoint',
                  },
                ]}
                selected={selectedTypeToCreate}
                onChange={async (value: string[]) => {
                  setSelectedTypeToCreate(value);
                  if (
                    value[0] === 'useOtherStylePresetAsStartingPoint' &&
                    !presetOptions
                  ) {
                    const data = await fetchAllListForSelector({
                      boundaries: PresetBoundariesTypeDtoEnum.SHOP_LEVEL,
                      'X-LimoniApps-AppName': APP_NAME,
                      'X-LimoniApps-AppSecret': APP_PASSWORD,
                    });
                    setPresetOptions(data);
                    setSelectedPreset(
                      data?.preselectedItem?.id || data?.items[0]?.id
                    );
                  }
                }}
              />
              {selectedTypeToCreate[0] ===
                'useOtherStylePresetAsStartingPoint' && (
                <Select
                  label={i18n.translate('selectPreset')}
                  options={
                    presetOptions?.items?.map((item) => ({
                      label: item.name || '',
                      value: item.id || '',
                    })) || []
                  }
                  selectedOptions={[selectedPreset]}
                  onChange={(value) => setSelectedPreset(value[0])}
                  hideLabel={false}
                  hidePlaceholder
                  suffix={<Icon source={SelectIcon as IconSource} />}
                />
              )}
            </BlockStack>
          </Modal.Section>
        </Modal>
      )}
      {createStylePresetRenameModalOpen && (
        <Modal
          open={createStylePresetRenameModalOpen}
          onClose={handleCloseRenameModal}
          title={i18n.translate('renamePreset')}
          primaryAction={{
            content: i18n.translate('rename'),
            onAction: () => {
              renamePreset(
                currentPreset?.id as string,
                currentPreset?.name as string,
                setRefreshList
              );
              handleCloseRenameModal();
            },
            disabled: !currentPreset?.name,
          }}
          secondaryActions={[
            {
              content: i18n.translate('cancel'),
              onAction: handleCloseRenameModal,
            },
          ]}
        >
          <Modal.Section>
            <TextField
              label={''}
              labelHidden
              value={currentPreset?.name as string}
              onChange={(value: string) =>
                setCurrentPreset((item) => ({
                  ...item,
                  name: value,
                }))
              }
              autoComplete='off'
              error={!currentPreset?.name && i18n.translate('error')}
            />
          </Modal.Section>
        </Modal>
      )}
      {changeDefaultPresetModalOpen && (
        <PresetsListModal
          presetsListModalOpen={changeDefaultPresetModalOpen}
          handleSetDefault={handleSetDefault}
          handleClosePresetsListModal={() =>
            setChangeDefaultPresetModalOpen((prev) => !prev)
          }
          boundaries={PresetBoundariesTypeDtoEnum.SHOP_LEVEL}
        />
      )}
      <SettingsConfirmationModal
        modalActive={modalActive}
        handleModalClose={handleModalClose}
        handleLeavePage={handleRedirectBack}
      />
    </>
  );
};
