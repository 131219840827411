import React, { useMemo } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { AlertDto } from 'core/api/adminSettings/adminSettingsApi';
import { BlockStack, Box, Card, InlineGrid, Text } from '@shopify/polaris';
import { AlertLevelDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

type SummarizeBannerProps = {
  alerts?: AlertDto[];
};

export const SummarizeBanner: React.FC<SummarizeBannerProps> = (props) => {
  const { alerts } = props;

  const [i18n] = useI18n();

  const info = useMemo(
    () => [
      {
        title: i18n.translate('Errors'),
        quantity: alerts?.filter(
          (alert) => alert.level === AlertLevelDtoEnum.CRITICAL
        ).length,
      },
      {
        title: i18n.translate('Warning'),
        quantity: alerts?.filter(
          (alert) => alert.level === AlertLevelDtoEnum.WARNING
        ).length,
      },
      {
        title: i18n.translate('Information'),
        quantity: alerts?.filter(
          (alert) => alert.level === AlertLevelDtoEnum.INFORMATION
        ).length,
      },
    ],
    [alerts, i18n]
  );

  return (
    <Card roundedAbove='sm' padding='400'>
      <InlineGrid columns={3}>
        {info.map((data, key) => (
          <Box
            key={key}
            paddingInlineStart={key ? '600' : '0'}
            paddingInlineEnd='600'
            borderInlineStartWidth={key ? '025' : '0'}
            borderColor='border-brand'
          >
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm' fontWeight='medium'>
                {data.title}
              </Text>
              <Text as='h2' variant='headingLg'>
                {data.quantity}
              </Text>
            </BlockStack>
          </Box>
        ))}
      </InlineGrid>
    </Card>
  );
};
