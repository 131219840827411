import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BlockStack,
  Box,
  Card,
  EmptySearchResult,
  IndexFilters,
  IndexTable,
  InlineStack,
  Page,
  Pagination,
  Text,
  useSetIndexFiltersMode,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useSearchParams } from 'react-router-dom';
import { NonEmptyArray } from '@shopify/polaris/build/ts/src/types';
import { IndexTableHeading } from '@shopify/polaris/build/ts/src/components/IndexTable';
import { useConfigureSettings } from 'features/settings/hooks/useConfigureSettings';
import {
  BillingEntryDto,
  GetBillingsRequestDto,
  PagedResponseDtoBillingEntryDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { PastBillsRow } from './components/PastBillsRow/PastBillsRow';
import './PastBills.scss';

export const PastBills = () => {
  const [i18n] = useI18n();
  const { mode, setMode } = useSetIndexFiltersMode();
  const [, setSearchParams] = useSearchParams();

  const { billsListData, billsListIsLoading, getBillsList } =
    useConfigureSettings();
  const [listState, setListState] = useState<PagedResponseDtoBillingEntryDto>();
  const [listRequestSetup, setListRequestSetup] =
    useState<GetBillingsRequestDto>({
      itemsPerPage: 10,
      page: 1,
    });

  const itemsIds = useMemo(
    () =>
      listState?.items?.map((item: BillingEntryDto) => {
        return {
          id: item.issuedAt || '1',
        };
      }) || [],
    [listState]
  );
  const showSkeleton = useMemo(
    () => !itemsIds.length && !listState,
    [listState, itemsIds]
  );
  const rowMarkup = useMemo(() => {
    const generateSkeletonIds = (n: number) => {
      return Array.from({ length: n }, (_, i) => ({ id: i.toString() }));
    };
    return (!showSkeleton ? itemsIds : generateSkeletonIds(10))?.map(
      (item, index) => {
        return (
          <PastBillsRow
            key={item.id}
            index={index}
            isLoading={showSkeleton}
            data={
              (listState as PagedResponseDtoBillingEntryDto)?.items?.find(
                (bill) => bill.issuedAt === item.id
              ) || {}
            }
          />
        );
      }
    );
  }, [itemsIds, listState, showSkeleton, billsListIsLoading]);

  const headings: NonEmptyArray<IndexTableHeading> = useMemo(
    () => [
      { title: i18n.translate('DateIssued') },
      { title: i18n.translate('BillNumber') },
      { title: i18n.translate('BillType') },
      { title: i18n.translate('PaymentStatus') },
      { title: i18n.translate('Amount'), alignment: 'end' },
    ],
    []
  );

  const hasNextPage = useMemo(
    () =>
      listState?.totalItems && listRequestSetup.page
        ? listState.totalItems / listRequestSetup.page > 10
        : false,
    [listState?.totalItems, listRequestSetup.page]
  );

  const emptyStateMarkup = (
    <EmptySearchResult
      title={i18n.translate('NoItemsFound')}
      withIllustration
    />
  );

  const handleRedirectBack = useCallback(() => {
    setSearchParams((params) => {
      params.delete('subPath');
      return params;
    });
  }, [setSearchParams]);

  const onPrevPage = useCallback(() => {
    setListState(undefined);
    setListRequestSetup((prev) => ({
      ...prev,
      page: prev.page === 1 ? 1 : (prev.page as number) - 1,
    }));
  }, [setListRequestSetup]);

  const onNextPage = useCallback(() => {
    setListState(undefined);
    setListRequestSetup((prev) => ({
      ...prev,
      page: (prev.page as number) + 1,
    }));
  }, [setListRequestSetup]);

  useEffect(() => {
    getBillsList(listRequestSetup as any);
  }, [listRequestSetup]);

  useEffect(() => {
    if (billsListData) {
      setListState(billsListData);
    }
  }, [billsListData]);

  return (
    <div className='PastBills'>
      <Page
        backAction={{ onAction: handleRedirectBack }}
        title={i18n.translate('PastBills')}
      >
        <Card>
          <BlockStack gap='400'>
            <Text as='h2' fontWeight='semibold'>
              {i18n.translate('PastBills')}
            </Text>
            <Box borderColor='border' borderWidth='025' borderRadius='200'>
              <IndexFilters
                onQueryChange={() => null}
                onQueryClear={() => null}
                tabs={[{ content: i18n.translate('All'), id: '1' }]}
                selected={0}
                canCreateNewView={false}
                filters={[]}
                appliedFilters={[]}
                onClearAll={() => null}
                mode={mode}
                setMode={setMode}
                hideQueryField
                hideFilters
              />

              <IndexTable
                itemCount={!billsListIsLoading ? itemsIds?.length : 10}
                headings={headings}
                selectable={false}
                emptyState={emptyStateMarkup}
              >
                {rowMarkup}
              </IndexTable>
              {(listState?.totalItems || 0) > 5 && (
                <Box
                  borderBlockStartWidth='025'
                  borderColor='border-brand'
                  background='bg-surface-secondary'
                  padding='300'
                >
                  <InlineStack align='space-between' blockAlign='center'>
                    {!showSkeleton ? (
                      <Text as='p' tone='subdued'>
                        {i18n.translate('ShowXOfY', {
                          from:
                            ((listRequestSetup.page as number) - 1) * 10 + 1,
                          to: Math.min(
                            (listRequestSetup.page as number) * 10,
                            listState?.totalItems as number
                          ),
                          total: listState?.totalItems,
                        })}
                      </Text>
                    ) : (
                      <Box>...</Box>
                    )}
                    <Pagination
                      onPrevious={onPrevPage}
                      onNext={onNextPage}
                      hasNext={hasNextPage && !showSkeleton}
                      hasPrevious={
                        (listRequestSetup.page as number) > 1 && !showSkeleton
                      }
                    />
                  </InlineStack>
                </Box>
              )}
            </Box>
          </BlockStack>
        </Card>
      </Page>
    </div>
  );
};
