import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { useConfigureSettings } from 'features/settings/hooks/useConfigureSettings';
import { CardsSkeleton } from 'core/components';
import { PlanFeatureDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import {
  ChangePlanModal,
  ChangePlanModalTypeEnum,
} from '../../../Plan/components/ChangePlanModal/ChangePlanModal';
import { PlanLevelDto } from 'core/api/adminSettings/adminSettingsApi';

type MultiLanguageFeatureProps = {
  isMaxModal: boolean;
};
export const MultiLanguageFeature: React.FC<MultiLanguageFeatureProps> = ({
  isMaxModal,
}) => {
  const [i18n] = useI18n();
  const { getPlansSupportingFeature, plansSupportingFeatureData } =
    useConfigureSettings();
  const [changePlanModal, setChangePlanModal] = useState<boolean>(false);

  const handleChangePlanModal = useCallback(
    () => setChangePlanModal((prev) => !prev),
    []
  );

  useEffect(() => {
    getPlansSupportingFeature({
      features: [PlanFeatureDtoEnum.MULTI_LANGUAGE],
    });
  }, []);

  return (
    <>
      {plansSupportingFeatureData ? (
        <Card roundedAbove='sm' padding='400' background='bg-surface-secondary'>
          <InlineStack align='space-between' wrap={false} gap='400'>
            <BlockStack gap='100'>
              <InlineStack align='start' gap='200'>
                <Text as='h2' variant='headingSm'>
                  {i18n.translate('MultiLanguageFeature')}
                </Text>
                <Badge tone='attention'>
                  {plansSupportingFeatureData.name || ''}
                </Badge>
              </InlineStack>
              <Text as='p' tone='subdued'>
                {i18n.translate('MultiLanguageFeatureDescription')}
              </Text>
            </BlockStack>
            <BlockStack align='start'>
              <Box width='max-content'>
                <Button onClick={handleChangePlanModal} variant='primary'>
                  {i18n.translate('GetTheFeature')}
                </Button>
              </Box>
            </BlockStack>
          </InlineStack>
        </Card>
      ) : (
        <CardsSkeleton cardsCount={1} size='small' />
      )}
      {changePlanModal && (
        <ChangePlanModal
          targetPlanLevel={plansSupportingFeatureData?.level as PlanLevelDto}
          modalType={ChangePlanModalTypeEnum.GET_FEATURE}
          highlightedFeatures={[PlanFeatureDtoEnum.MULTI_LANGUAGE]}
          isOpen={changePlanModal}
          isMaxModal={isMaxModal}
          onClose={handleChangePlanModal}
        />
      )}
    </>
  );
};
