import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  Tooltip,
  BlockStack,
  InlineStack,
  Icon,
} from '@shopify/polaris';
import { QuestionCircleIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import styles from './GrayBoxExclusions.module.scss';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SearchFieldWithGrayBox } from 'core/components/SearchFieldWithGrayBoxOfferWizard';
import {
  OfferCartRuleDto,
  ShopifyObjectDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { formateList } from 'core/utils/offerTargetTypeUtils';
import { ResourcePickerOptions, ResourceSelection } from 'core/api/appBridge';

type FormFields = {
  excludeProductsSpecificCollections: boolean;
};

const form = yup.object({
  excludeProductsSpecificCollections: yup.boolean(),
});

type ResourceSelectionProps = ResourceSelection<
  ResourcePickerOptions['type']
> & {
  featuredImageUrl: string;
};

export type GrayBoxExclusionsProps = {
  data: OfferCartRuleDto;
  updateValue: (data: OfferCartRuleDto) => void;
  onFormValidityChange?(
    isFormInvalid: boolean,
    isExclusionsField: boolean
  ): void;
  setExcludedCollections?: (excludedCollections: string) => void;
  hideComponent?: boolean;
};

export const GrayBoxExclusions: React.FC<GrayBoxExclusionsProps> = (props) => {
  const {
    data,
    updateValue,
    onFormValidityChange,
    setExcludedCollections,
    hideComponent = false,
  } = props;

  const [excludeSelectList, setExcludeSelectList] = useState<
    ShopifyObjectDto[]
  >(data.exclusions?.collections?.value || []);

  const [i18n] = useI18n();

  const initialExcludeSelectList = useMemo(
    () => data.exclusions?.collections?.value || [],
    [data.exclusions?.collections?.value]
  );

  const excludeProductsSpecificCollectionsValue = useMemo(
    () => data.exclusions?.collections?.enabled || false,
    [data.exclusions?.collections?.enabled]
  );

  const { control, watch } = useForm<FormFields>({
    defaultValues: {
      excludeProductsSpecificCollections:
        excludeProductsSpecificCollectionsValue,
    },
    resolver: yupResolver(form),
  });

  const handleExcludeListChange = useCallback(
    (list: ResourceSelectionProps[]) => {
      setExcludeSelectList(list);
      updateValue({
        ...data,
        exclusions: {
          ...data.exclusions,
          collections: {
            ...data.exclusions?.collections,
            value: formateList(
              list,
              data.appliesTo?.type
            ) as ShopifyObjectDto[],
          },
        },
      });
    },
    [setExcludeSelectList, updateValue, formateList, data]
  );

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        updateValue({
          ...data,
          exclusions: {
            collections: {
              value: value.excludeProductsSpecificCollections
                ? data.exclusions?.collections?.value
                : initialExcludeSelectList,
              enabled: value.excludeProductsSpecificCollections,
            },
          },
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, updateValue, data]);

  const isExcludeSpecificCollectionsChecked = watch(
    'excludeProductsSpecificCollections'
  );

  const onSearchFieldValidityChange = useCallback((formIsInvalid: boolean) => {
    onFormValidityChange?.(formIsInvalid, true);
  }, []);

  useEffect(() => {
    setExcludeSelectList(data.exclusions?.collections?.value || []);
  }, [data.exclusions?.collections?.value]);

  return (
    <>
      {!hideComponent ? (
        <BlockStack gap='400'>
          <div className={styles.GrayBoxAnyCollectionContainer}>
            <Controller<FormFields>
              control={control}
              name='excludeProductsSpecificCollections'
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  value=''
                  label={
                    <InlineStack gap='100'>
                      {i18n.translate(
                        'GrayBoxAnyCollection.ProductsSpecificCollections'
                      )}
                      <Tooltip
                        preferredPosition='mostSpace'
                        content={
                          i18n.translate(
                            'GrayBoxAnyCollection.ProductsSpecificCollectionsHover'
                          ) ||
                          'Allows you to exclude products that are in one or more specified collections. These products will be excluded when evaluating the cart rules for this offer. Use this feature in combination with “products from any collection” to specify that all products are eligible for this offer except those in the specified “exclude” collections. Use it in combination with “products from specific collections” to specify that all products from collections that A and B that are not in the “exclude” collections are eligible.'
                        }
                      >
                        <Icon source={QuestionCircleIcon} />
                      </Tooltip>
                    </InlineStack>
                  }
                />
              )}
            />
          </div>
          {isExcludeSpecificCollectionsChecked && (
            <SearchFieldWithGrayBox
              resourceType='Collection'
              onSelectedChange={handleExcludeListChange}
              selectList={excludeSelectList as ResourceSelectionProps[]}
              onFormValidityChange={onSearchFieldValidityChange}
              searchFieldType='Exclude'
              setConfiguredCollections={setExcludedCollections}
            ></SearchFieldWithGrayBox>
          )}
        </BlockStack>
      ) : (
        <>
          {isExcludeSpecificCollectionsChecked && (
            <SearchFieldWithGrayBox
              resourceType='Collection'
              onSelectedChange={handleExcludeListChange}
              selectList={excludeSelectList as ResourceSelectionProps[]}
              onFormValidityChange={onSearchFieldValidityChange}
              searchFieldType='Exclude'
              setConfiguredCollections={setExcludedCollections}
              hideComponent={hideComponent}
            ></SearchFieldWithGrayBox>
          )}
        </>
      )}
    </>
  );
};
