import { Spinner } from '@shopify/polaris';
import styles from './Loader.module.scss';
import React from 'react';

type LoaderProps = {
  fullWidth?: boolean;
  size?: 'small' | 'large';
  type?: 'page' | 'component';
};

export const Loader: React.FC<LoaderProps> = (props) => {
  const { fullWidth, size, type = 'component' } = props;

  return fullWidth ? (
    <div
      className={`${
        type === 'page'
          ? styles['LoaderWrapper--page']
          : styles['LoaderWrapper--component']
      }`}
    >
      <Spinner size={size} />
    </div>
  ) : (
    <Spinner size={size} />
  );
};
