import { RadioButton, BlockStack, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import React, { useMemo } from 'react';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';
import { OfferRuleTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferRuleTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';

export type CartRulesRadiosProps = {
  selectedOption?: OfferRuleTypeDto;
  handleSetSelectedOption: (
    checked: boolean,
    value: OfferRuleTypeDtoEnum
  ) => void;
  handleIsCrossSellCheckedChange?: (newChecked: boolean) => void;
  specialOfferType?: OfferTypeSpecialCases;
  isOrderBump?: boolean;
};

export const CartRulesRadios: React.FC<CartRulesRadiosProps> = (props) => {
  const {
    handleSetSelectedOption,
    selectedOption,
    handleIsCrossSellCheckedChange,
    specialOfferType,
    isOrderBump,
  } = props;

  const [i18n] = useI18n();

  const content = useMemo(() => {
    switch (specialOfferType) {
      case OfferTypeSpecialCases.BogoMixMatch:
      case OfferTypeSpecialCases.BogoStrictMatch:
      case OfferTypeSpecialCases.SpendXGetX:
        return <></>;
      default:
        return (
          <BlockStack gap='200'>
            <RadioButton
              label={i18n.translate('ContentRuleBasedOn', {
                rule: (
                  <Text as='span' fontWeight='medium'>
                    {i18n.translate('ContentRule')}
                  </Text>
                ),
              })}
              checked={
                selectedOption === OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY
              }
              id={OfferRuleTypeDtoEnum.MINIMUM_ITEMS_QUANTITY}
              onChange={handleSetSelectedOption}
            />
            <RadioButton
              label={i18n.translate('ValueRuleBasedOn', {
                rule: (
                  <Text as='span' fontWeight='medium'>
                    {i18n.translate('ValueRule')}
                  </Text>
                ),
              })}
              checked={
                selectedOption === OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT
              }
              id={OfferRuleTypeDtoEnum.MINIMUM_PURCHASE_AMOUNT}
              onChange={handleSetSelectedOption}
            />
          </BlockStack>
        );
    }
  }, [
    selectedOption,
    i18n,
    specialOfferType,
    handleSetSelectedOption,
    handleIsCrossSellCheckedChange,
    isOrderBump,
  ]);

  return content;
};
