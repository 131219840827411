import { I18n } from '@shopify/react-i18n';
import { DiscountValueTypeEnum } from 'features/promotions/components/SelectedOfferTypeSettings/enums/DiscountValueType';
import React, { useEffect, useMemo } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import './DiscountApplySection.scss';
import { ApplyInputNumber } from './components/ApplyInputNumber';
import { ApplyButtons } from './components/ApplyButtons';

type DiscountTypeSectionProps = {
  i18n: I18n;
  offerType: OfferTypeDtoEnum;
  activeDiscountValueType: DiscountValueTypeEnum;
  spendAmountOffer: boolean;
  textFieldValue: NumberFormatValues;
  onDiscountTypeClick: (data: string) => void;
  setTextFieldValue: (data: NumberFormatValues) => void;
  onValidityStateChange(invalid: boolean): void;
};

export const DiscountApplySection: React.FC<DiscountTypeSectionProps> = (
  props
) => {
  const {
    i18n,
    offerType,
    activeDiscountValueType,
    spendAmountOffer,
    textFieldValue,
    onDiscountTypeClick,
    setTextFieldValue,
    onValidityStateChange,
  } = props;

  const inputValueNotValid: boolean = useMemo(
    () => !textFieldValue.floatValue,
    [textFieldValue.floatValue]
  );

  useEffect(() => {
    onValidityStateChange(inputValueNotValid);
  }, [inputValueNotValid]);

  const numberInputSuffix: string = useMemo(
    () =>
      activeDiscountValueType !== DiscountValueTypeEnum.Percentage ? '$' : '%',
    [activeDiscountValueType]
  );

  const sectionApplyType = useMemo(() => {
    switch (offerType) {
      case OfferTypeDtoEnum.SALES_FIXED_AMOUNT:
        return (
          <ApplyInputNumber
            suffix={numberInputSuffix}
            offerType={offerType}
            setTextFieldValue={setTextFieldValue}
            textFieldValue={textFieldValue}
            activeDiscountValueType={activeDiscountValueType}
          />
        );
      case OfferTypeDtoEnum.SALES_PERCENTAGE:
        return (
          <ApplyInputNumber
            suffix={numberInputSuffix}
            offerType={offerType}
            setTextFieldValue={setTextFieldValue}
            textFieldValue={textFieldValue}
            activeDiscountValueType={activeDiscountValueType}
          />
        );
      case OfferTypeDtoEnum.SALES_FIXED_PRICE:
        return (
          <ApplyInputNumber
            suffix={numberInputSuffix}
            offerType={offerType}
            setTextFieldValue={setTextFieldValue}
            textFieldValue={textFieldValue}
            activeDiscountValueType={activeDiscountValueType}
          />
        );
      case OfferTypeDtoEnum.TIERED_BOGO:
        return (
          <ApplyButtons
            offerType={offerType}
            onDiscountTypeClick={onDiscountTypeClick}
            activeDiscountValueType={activeDiscountValueType}
          />
        );
      case OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y:
        return (
          <ApplyButtons
            offerType={offerType}
            onDiscountTypeClick={onDiscountTypeClick}
            activeDiscountValueType={activeDiscountValueType}
          />
        );
      case OfferTypeDtoEnum.VOLUME_DISCOUNT:
        return (
          <ApplyButtons
            offerType={offerType}
            onDiscountTypeClick={onDiscountTypeClick}
            activeDiscountValueType={activeDiscountValueType}
          />
        );
      case OfferTypeDtoEnum.SHIPPING_PERCENTAGE:
        return (
          <ApplyInputNumber
            offerType={offerType}
            suffix='%'
            setTextFieldValue={setTextFieldValue}
            textFieldValue={textFieldValue}
            activeDiscountValueType={activeDiscountValueType}
          />
        );
      case OfferTypeDtoEnum.FREE_SHIPPING ||
        OfferTypeDtoEnum.SHIPPING_FIXED_AMOUNT:
        return (
          <ApplyInputNumber
            offerType={offerType}
            suffix='$'
            setTextFieldValue={setTextFieldValue}
            textFieldValue={textFieldValue}
            activeDiscountValueType={activeDiscountValueType}
          />
        );
      default:
        return null;
    }
  }, [
    offerType,
    activeDiscountValueType,
    i18n,
    numberInputSuffix,
    onDiscountTypeClick,
    textFieldValue.value,
    setTextFieldValue,
    spendAmountOffer,
  ]);

  return <>{sectionApplyType}</>;
};
