export const enum NavPaths {
  Promotions = 'promotion-list',
  CreatePromotion = 'create-promotion-1',
  AlertCenter = 'alert-center',
  PromotionActivity = 'promotion-activity',
  ResultsByPromotion = 'results-by-promotion',
  ResultsByOrder = 'results-by-order',
  ConfigureWidgets = 'configure-widgets',
  ResultsByPromotionCode = 'results-by-promotion-code',
  Settings = 'general-settings',
  SettingsModal = 'settings',
  News = 'news',
  FeatureRequest = 'feature-request',
  Help = 'help',
  Analytics = 'analytics',
}
