import React, { useCallback, useState } from 'react';
import { useI18n } from '@shopify/react-i18n';
import { Icon, MediaCard, VideoThumbnail } from '@shopify/polaris';
import { LearnMoreModal } from 'features/promotions/components/SelectedOfferTypeSettings/components/LearnMoreModal/LearnMoreModal';
import { XIcon } from '@shopify/polaris-icons';
import { getVideoThumbnail } from 'core/utils/getThumbnail';

type DiscountNinjaTutorialProps = {
  tutorialVideoUrl?: string;
  isDismissable?: boolean;
  onDismiss: () => void;
};

export const DiscountNinjaTutorial: React.FC<DiscountNinjaTutorialProps> = (
  props
) => {
  const { tutorialVideoUrl, isDismissable, onDismiss } = props;

  const [i18n] = useI18n();

  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const thumbnailUrl = getVideoThumbnail(
    tutorialVideoUrl ||
      'https://www.loom.com/share/cd98f3811dcf418aa0d2104b3c567421'
  );

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState) => !prevState);
  }, [setShowHowModal]);

  return (
    <>
      <MediaCard
        title={i18n.translate('title')}
        primaryAction={{
          content: i18n.translate('button'),
          onAction: toggleShowHowModal,
        }}
        description={i18n.translate('description')}
        popoverActions={[
          {
            content: i18n.translate('dismiss'),
            onAction: onDismiss,
            disabled: !isDismissable,
            prefix: (
              <div
                style={{
                  height: '1rem',
                  width: '1rem',
                  paddingTop: '.05rem',
                }}
              >
                <Icon tone='subdued' source={XIcon} />
              </div>
            ),
          },
        ]}
        size='small'
      >
        <VideoThumbnail
          videoLength={duration}
          videoProgress={currentTime}
          showVideoProgress={!!duration}
          thumbnailUrl={thumbnailUrl}
          onClick={toggleShowHowModal}
        />
      </MediaCard>
      {showHowModal && (
        <LearnMoreModal
          onModalClose={toggleShowHowModal}
          url={tutorialVideoUrl || ''}
          title={i18n.translate('tutorial')}
          setDuration={setDuration}
          setCurrentTime={setCurrentTime}
        />
      )}
    </>
  );
};
