import { Modal, Scrollable } from '@shopify/polaris';
import { Loader } from 'core/components/Loader/Loader';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Checked,
  CheckedResourceListItem,
  ResourceListItem,
} from './components/ResourceListItem';
import styles from './ResourcePickerCustom.module.scss';
import { ProductVariant } from 'core/api/appBridge';
import { useResourcePickerResources } from '../../hooks/useResourcePickerResources';

type ResourceSelectionExtended = any & {
  variants?: Partial<ProductVariant>[];
  featuredImageUrl?: string;
  imageUrl?: string;
};

type ResourcePickerCustomProps = any & {
  showVariants?: boolean;
  multiple?: boolean | number;
};

export const ResourcePickerCustom: React.FC<ResourcePickerCustomProps> = (
  props
) => {
  const {
    open,
    type,
    showVariants,
    multiple,
    selectionIds,
    onCancel,
    onSelection,
  } = props;

  const [selectPayload, setSelectPayload] = useState<any>();
  const [selectedItems, setSelectedItems] = useState<CheckedResourceListItem[]>(
    []
  );

  const {
    collectionListData,
    productsListData,
    productVariantsListData,
    productVariantsListListIsLoading,
    productsListListIsLoading,
    collectionListListIsLoading,
  } = useResourcePickerResources();

  const isLoading = useMemo(
    () =>
      productVariantsListListIsLoading ||
      productsListListIsLoading ||
      collectionListListIsLoading,
    [
      productVariantsListListIsLoading,
      productsListListIsLoading,
      collectionListListIsLoading,
    ]
  );

  const parsedInitialSelectionIds = useMemo(
    () =>
      selectionIds?.map((parent: { id: any; variants: any[] }) => ({
        id: parent.id,
        variants:
          parent.variants?.map((variant) => ({
            id: variant.id,
          })) || [],
      })),
    [selectionIds]
  );

  useEffect(() => {
    if (
      !productVariantsListData ||
      !productsListData ||
      !collectionListData ||
      !type
    ) {
      return;
    }

    switch (type) {
      case 'collection':
        setSelectPayload({
          selection: collectionListData,
        });
        break;
      case 'product':
        setSelectPayload({
          selection: productsListData,
        });
        break;
    }
  }, [collectionListData, productsListData, productVariantsListData, type]);

  const selection = useMemo(() => {
    if (type === 'product') {
      const flatVariants = selectedItems.reduce(
        (prev: Checked[], curr: CheckedResourceListItem) =>
          (prev = [...prev, ...(curr.variants || [])]),
        []
      );
      return (
        (selectPayload?.selection
          .map((selectionItem: any) => {
            if (selectionItem) {
              return {
                ...selectionItem,
                variants: (
                  selectionItem as ResourceSelectionExtended
                ).variants?.filter((variant: { id: string }) =>
                  flatVariants.find(
                    (flatVariant) =>
                      flatVariant.id === variant.id && flatVariant.checked
                  )
                ),
              };
            }
          })
          .filter((selectionItem: { id: string }) =>
            selectedItems.find(
              (el) => el.parent.id === selectionItem?.id && el.parent.checked
            )
          ) as ResourceSelectionExtended[]) || []
      );
    } else {
      return selectPayload?.selection.filter((selectionItem: { id: string }) =>
        selectedItems.find(
          (el) => el.parent.id === selectionItem?.id && el.parent.checked
        )
      ) as ResourceSelectionExtended[];
    }
  }, [selectPayload, selectedItems, type]);

  const items = useMemo(
    () => (selectPayload?.selection as ResourceSelectionExtended[]) || [],
    [selectPayload]
  );

  const title: string = useMemo(() => {
    const phrase = 'Add';
    if (type === 'collection') {
      return `${phrase} collections`;
    }
    if (type === 'product') {
      return showVariants
        ? `${phrase} product with variants`
        : `${phrase} products`;
    }
    return phrase;
  }, [type, showVariants]);

  const handleCloseModal = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  const handleAdd = useCallback(() => {
    onSelection?.({ selection });
  }, [selection, onSelection]);

  const handleSelectionChange = useCallback(
    (checkedResourceListItem: CheckedResourceListItem) => {
      setSelectedItems((prev) => {
        const selectedItemIdx = prev.findIndex(
          (item) => item.parent.id === checkedResourceListItem.parent.id
        );
        if (multiple) {
          return selectedItemIdx === -1
            ? [...prev, checkedResourceListItem]
            : prev.map((item, idx) =>
                idx === selectedItemIdx ? checkedResourceListItem : item
              );
        } else {
          return [checkedResourceListItem];
        }
      });
    },
    [setSelectedItems, multiple]
  );

  const getSelectedVariantsByParentId = useCallback(
    (id: string) => {
      return (
        parsedInitialSelectionIds
          ?.find((selection: { id: string }) => selection.id === id)
          ?.variants.map((variant: { id: any }) => variant.id) || []
      );
    },
    [parsedInitialSelectionIds]
  );

  const getSelectedParentById = useCallback(
    (id: string) => {
      return !!parsedInitialSelectionIds?.find(
        (selection: { id: string }) => selection.id === id
      );
    },
    [parsedInitialSelectionIds]
  );

  return (
    <Modal
      title={title}
      open={open}
      onClose={handleCloseModal}
      primaryAction={{
        content: 'Add',
        onAction: handleAdd,
        id: 'ResourcePickerCustomAddButton',
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: handleCloseModal,
        },
      ]}
    >
      {isLoading ? (
        <Loader size='large' fullWidth />
      ) : (
        /*eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        <Scrollable className={styles.ResourcePickerCustom__ModalBody}>
          {items.map((item: ResourceSelectionExtended) => (
            <ResourceListItem
              title={item.title}
              id={item.id}
              key={item.id}
              source={item?.imageUrl || item?.featuredImageUrl}
              showVariants={showVariants}
              resourceType={type}
              variants={item?.variants}
              initialChecked={getSelectedParentById(item.id)}
              initialCheckedVariants={getSelectedVariantsByParentId(item.id)}
              onChecked={handleSelectionChange}
            />
          ))}
        </Scrollable>
      )}
    </Modal>
  );
};
