import {
  BlockStack,
  Combobox,
  Icon,
  InlineStack,
  Tag,
  Text,
} from '@shopify/polaris';
import React, { useCallback, useMemo, useState } from 'react';
import './SearchFieldWithTags.scss';
import { SearchIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { formatNameEllipsis } from 'core/utils';
import { SearchField } from 'features/promotions/components/SelectedOfferTypeSettings/components/Combinations/components/DoNotCombineWith/DoNotCombineWith';

export type tagDataProps = {
  id?: string;
  name?: string;
};

export type SearchFieldWithTagsProps = {
  type: SearchField;
  title?: string;
  data?: tagDataProps[];
  placeholder?: string;
  openAction?: () => void;
  removeTag: (tag: tagDataProps, type: SearchField) => void;
};

export const SearchFieldWithTags: React.FC<SearchFieldWithTagsProps> = (
  props
) => {
  const [i18n] = useI18n();
  const { title, data, openAction, placeholder, removeTag, type } = props;
  const [showMoreThanFiveTags, setShowMoreThanFiveTags] =
    useState<boolean>(false);

  const toggleTagsVisibilityState = useCallback(() => {
    setShowMoreThanFiveTags((prevState: boolean) => !prevState);
  }, [setShowMoreThanFiveTags]);

  const tagsMarkup = data
    ?.filter((_el: tagDataProps, index: number) =>
      !showMoreThanFiveTags ? index < 5 : true
    )
    .map((tag: tagDataProps) => (
      <Tag
        key={`option-${tag.id}`}
        onRemove={() => removeTag(tag, type)}
        size='large'
      >
        {tag.name ? formatNameEllipsis(tag.name, 40) : ''}
      </Tag>
    ));

  const searchFieldFilledWithTags = useMemo(() => {
    return (
      <BlockStack gap='300'>
        <div onClick={openAction}>
          <Combobox
            allowMultiple
            activator={
              <Combobox.TextField
                prefix={<Icon source={SearchIcon} />}
                label='Search offers'
                labelHidden
                placeholder={placeholder}
                autoComplete='off'
              />
            }
          ></Combobox>
        </div>
        <InlineStack gap='100'>{tagsMarkup}</InlineStack>
      </BlockStack>
    );
  }, [
    data,
    showMoreThanFiveTags,
    removeTag,
    openAction,
    toggleTagsVisibilityState,
    i18n,
    tagsMarkup,
  ]);

  return (
    <div className='SearchFieldWithTags'>
      <div className='Title'>
        <Text as='span'>{title}</Text>
      </div>
      <div className='ShopifyAppBridgeModalInitiatorContainer'>
        {searchFieldFilledWithTags}
      </div>
    </div>
  );
};
