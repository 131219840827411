import { useGetAuthenticateQuery } from 'core/api/auth';
import { useEffect, useRef } from 'react';
import { useIsDebugOrLocal } from '.';
import resolveEnvVar from 'env-var-resolver';
import { useLocation } from 'react-router-dom';

export const useAuthentication = () => {
  const isLocal = useIsDebugOrLocal();
  const { search } = useLocation();
  const hasFetched = useRef(false); // Flag to track if we have fetched on initial load

  const { data, isSuccess, isLoading, isError, refetch } =
    useGetAuthenticateQuery({
      shopifyAccessToken: resolveEnvVar('REACT_APP_SHOPIFY_ACCESS_TOKEN'),
      appName: resolveEnvVar('REACT_APP_APP_NAME'),
      uri: resolveEnvVar('REACT_APP_HOST_URI'),
      debugShop: resolveEnvVar('REACT_APP_DEBUG_SHOP'),
    });

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      localStorage.setItem(
        'lastTokenFetchTime',
        new Date().getTime().toString()
      );
      return;
    }

    const checkTokenExpiry = () => {
      const lastFetchTime = localStorage.getItem('lastTokenFetchTime');
      const currentTime = new Date().getTime();

      // Use the expiresIn value from the data (in milliseconds)
      // const expiresIn = 10000;
      const expiresIn = data?.expiresIn ? data.expiresIn * 1000 : 0;

      if (
        expiresIn &&
        (!lastFetchTime || currentTime - Number(lastFetchTime) >= expiresIn)
      ) {
        // Refetch token if it has expired
        refetch();
        localStorage.setItem('lastTokenFetchTime', currentTime.toString());
      }
    };

    // Set an interval to check token expiry every second
    const intervalId = setInterval(checkTokenExpiry, 1000);

    // Store or update the token in local storage
    const token = isLocal ? data?.Token : data?.discountNinjaSessionToken;

    if (token) {
      localStorage.setItem('token', token);

      if (!isLocal) {
        localStorage.setItem(
          'passwordDevLogin',
          resolveEnvVar('REACT_APP_API_SECRET')
        );

        const params = new URLSearchParams(search);
        const host = params.get('host');
        if (host) {
          localStorage.setItem('encodedHost', host);
        }
      }
    }

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [data, isLocal, search, refetch]);

  return { isAuthenticated: isSuccess, isError, isLoading };
};
