export const enum AnalyticsPageSubPathsEnums {
  TotalDnRevenue = 'total-dn-revenue',
  DnConversionRate = 'dn-conversion-rate',
  TotalDnOrders = 'total-dn-orders',
  DnSessions = 'dn-sessions',
  PromotionActivityReport = 'promotion-activity-report',
  OrderReport = 'order-report',
  PromotionCodeReport = 'promotion-code-report',
}

export const enum PromotionPageSubPathsEnums {
  Settings = 'settings',
  SettingsDiscountLinks = 'settings/discount-links',
  SettingsPromotionCodes = 'settings/promotion-codes',
  SettingsAudience = 'settings/audience',
  CreateOfferTemplate = 'create-offer-template',
}

export const enum SettingsModalPathsEnums {
  Account = 'account',
  Plan = 'plan',
  Style = 'style',
  Translations = 'translations',
  CartBehavior = 'cart-behavior',
  Gifts = 'gifts',
  CheckoutOptions = 'checkout-options',
  CustomShippingRates = 'custom-shipping-rates',
  Notifications = 'notifications',
  ManageWidgets = 'manage-widgets',
  StrikethroughPricing = 'strikethrough-pricing',
  Integrations = 'integrations',
  TagsMetadata = 'tags-metadata',
  DiscountLinks = 'discount-links',
  ImportExport = 'import-export',
  Testing = 'testing',
  AnnouncementBar = 'announcement-bar',
  Notification = 'notification',
  OfferRulesPopup = 'offer-rules-popup',
  CollectionStrikethroughPricing = 'collection-strikethrough-pricing',
  ProductStrikethroughPricing = 'product-strikethrough-pricing',
  ProductBanner = 'product-banner',
  PromotionalBadge = 'promotional-badge',
  PromotionCodeField = 'promotion-code-field',
  PromotionSummary = 'promotion-summary',
}

export const enum SettingsModalSubPathsEnums {
  ActivityLog = 'activity-log',
  PickYourPlan = 'pick-your-plan',
  CountdownClock = 'countdown-clock',
  PromotionCodeField = 'promotion-code-field',
  DiscountNinjaPromotionCodes = 'discount-ninja-promotion-codes',
  ShopifyDiscountCodes = 'shopify-discount-codes',
  ShopLevelStylePresets = 'shop-level_style_presets',
  PromotionLevelStylePresets = 'promotion-level_style_presets',
  PastBills = 'past-bills',
  TranslationsSettings = 'translations-settings',
  AnnouncementBar = 'announcement-bar',
  ProductBanner = 'product-banner',
  Notification = 'notification',
  PromotionalBadge = 'promotional-badge',
  PromotionSummary = 'promotion-summary',
  OfferRulesPopup = 'offer-rules-popup',
  CartText = 'cart-text',
  DiscountLabel = 'discount-label',
  DrawerCartStrikethroughPricing = 'drawer-cart-strikethrough-pricing',
  CartStrikethroughPricing = 'cart-strikethrough-pricing',
  CollectionStrikethroughPricing = 'collection-strikethrough-pricing',
  ProductStrikethroughPricing = 'product-strikethrough-pricing',
}
