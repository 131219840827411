import { usePostSettingsV6AlertsMutation } from 'core/api/adminSettings/adminSettingsApi';
import resolveEnvVar from 'env-var-resolver';
import { useCallback } from 'react';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const useAlertCenter = () => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const [getAlertsData, { isLoading: alertsDataIsLoading, data: alertsData }] =
    usePostSettingsV6AlertsMutation();

  const getAlertsPageData = useCallback(async () => {
    await getAlertsData({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      body: {},
    })
      .unwrap()
      .catch((err) => null);
  }, [getAlertsData]);

  return {
    alertsData,
    alertsDataIsLoading,
    getAlertsPageData,
  };
};
