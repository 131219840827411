import React, { useId, useMemo, useState } from 'react';
import './SetupGuide.scss';
import { StepProp } from 'features/dashboard/Dashboard';
import {
  ActionList,
  Badge,
  BlockStack,
  Button,
  ButtonGroup,
  Card,
  Collapsible,
  Icon,
  InlineStack,
  Popover,
  ProgressBar,
  Text,
} from '@shopify/polaris';
import {
  MenuHorizontalIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon,
  XIcon,
} from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { SetupItem } from './components/SetupItem/SetupItem';

type SetupGuideProps = {
  items: StepProp[];
  loading: boolean;
  onDismiss: () => void;
};

export const SetupGuide: React.FC<SetupGuideProps> = (props) => {
  const { items, loading, onDismiss } = props;

  const [i18n] = useI18n();
  const accessId = useId();

  const [popoverActive, setPopoverActive] = useState<boolean>(false);
  const [isGuideOpen, setIsGuideOpen] = useState<boolean>(true);
  const [expanded, setExpanded] = useState(
    items.findIndex((item) => !item.completed)
  );

  const completedItemsLength = useMemo(
    () => items.filter((item) => item.completed).length,
    [items]
  );

  const isDismissible = useMemo(() => {
    return items.slice(0, 3).every((item) => item.completed);
  }, [items]);

  return (
    <div className='SetupGuide'>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <InlineStack align='space-between'>
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('title')}
              </Text>
              <Text as='p' tone='subdued'>
                {i18n.translate('description')}
              </Text>
            </BlockStack>
            <div className='sectionBtns'>
              <ButtonGroup gap='tight' noWrap>
                <Popover
                  active={popoverActive}
                  onClose={() => setPopoverActive((prev) => !prev)}
                  activator={
                    <Button
                      onClick={() => setPopoverActive((prev) => !prev)}
                      variant='tertiary'
                      icon={MenuHorizontalIcon}
                    />
                  }
                >
                  <ActionList
                    actionRole='menuitem'
                    items={[
                      {
                        content: i18n.translate('dismiss'),
                        onAction: onDismiss,
                        disabled: !isDismissible,
                        prefix: (
                          <div
                            style={{
                              height: '1rem',
                              width: '1rem',
                              paddingTop: '.05rem',
                            }}
                          >
                            <Icon tone='subdued' source={XIcon} />
                          </div>
                        ),
                      },
                    ]}
                  />
                </Popover>
                <Button
                  variant='tertiary'
                  icon={isGuideOpen ? ChevronUpIcon : ChevronDownIcon}
                  onClick={() => {
                    setIsGuideOpen((prev) => {
                      if (!prev)
                        setExpanded(items.findIndex((item) => !item.completed));
                      return !prev;
                    });
                  }}
                  ariaControls={accessId}
                />
              </ButtonGroup>
            </div>
          </InlineStack>
          <InlineStack blockAlign='center' gap='200'>
            {completedItemsLength === items.length ? (
              <div style={{ maxHeight: '1rem' }}>
                <InlineStack wrap={false} gap='100'>
                  <Icon
                    source={CheckIcon}
                    tone='subdued'
                    accessibilityLabel={i18n.translate('accessibilityLabel')}
                  />
                  <Text as='p' variant='bodySm' tone='subdued'>
                    {i18n.translate('done')}
                  </Text>
                </InlineStack>
              </div>
            ) : (
              <Badge>
                {`${completedItemsLength} / ${items.length} ${i18n.translate(
                  'completed'
                )}`}
              </Badge>
            )}
            {completedItemsLength !== items.length ? (
              <div style={{ width: '100px' }}>
                <ProgressBar
                  progress={
                    (items.filter((item) => item.completed).length /
                      items.length) *
                    100
                  }
                  size='small'
                  tone='primary'
                  animated
                />
              </div>
            ) : null}
          </InlineStack>
          <Collapsible
            open={isGuideOpen}
            id={accessId}
            transition={{ animateIn: true }}
          >
            <BlockStack gap='100'>
              {items.map((item) => {
                return (
                  <SetupItem
                    key={item.id}
                    expanded={expanded === item.id}
                    loading={loading}
                    setExpanded={() => setExpanded(item.id)}
                    {...item}
                  />
                );
              })}
            </BlockStack>
          </Collapsible>
        </BlockStack>
      </Card>
    </div>
  );
};
