import {
  CloseEditorTransactionRequestDto,
  CreatePresetRequestDto,
  PromotionSetupDto,
  SavePresetRequestDto,
  useCreateMutation,
  useDeleteWidgetsV6PresetsByIdMutation,
  useDeleteWidgetsV6ResourcesIconByIdMutation,
  useDeleteWidgetsV6ResourcesIconsAllMutation,
  useDeleteWidgetsV6ResourcesImagesAllMutation,
  useDeleteWidgetsV6ResourcesImagesByIdMutation,
  useFontsQuery,
  useGetWidgetsV6LookupIconsCustomQuery,
  useGetWidgetsV6LookupImagesCustomQuery,
  useGetWidgetsV6PresetsByIdAffectedPromotionsQuery,
  useGetWidgetsV6PromotionByIdOverviewQuery,
  useIconsQuery,
  useImagesQuery,
  usePostWidgetsV6PresetsByIdApplyToAllPromotionsMutation,
  usePostWidgetsV6PresetsByIdDefaultMutation,
  usePostWidgetsV6PresetsByIdDuplicateMutation,
  usePostWidgetsV6PromotionByIdTransactionCloseMutation,
  usePostWidgetsV6ResourcesUploadIconMutation,
  usePostWidgetsV6ResourcesUploadImageMutation,
  usePutWidgetsV6PresetsByIdMutation,
  usePutWidgetsV6PresetsByIdRenameMutation,
  usePutWidgetsV6PromotionByIdChangePresetMutation,
  usePutWidgetsV6PromotionByIdMutation,
} from 'core/api/adminWidgets/adminWidgetsApi';
import { useAppDispatch, useAppSelector } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
import {
  setCustomIconLibary,
  setCustomImageLibary,
  setDefaultIconLibary,
  setDefaultImageLibary,
} from 'core/store/widgetsSlice';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const APP_NAME = process.env.REACT_APP_APP_NAME || '';
export enum WidgetsFetchTypeEnum {
  STYLE_PRESETS_LIBRARY = 'STYLE_PRESETS_LIBRARY',
  EDIT_PRESET_SKELETON = 'EDIT_PRESET_SKELETON',
  PRESET_FONT_LIST = 'FONT_LIST',
  AFFECTED_PROMOTIONS = 'AFFECTED_PROMOTIONS',
  PROMOTION_OVERVIEW = 'PROMOTION_OVERVIEW',
}
export const useConfigureWidgets = (
  fetchType?: WidgetsFetchTypeEnum,
  skipUnnecessaryRequests?: boolean
) => {
  const dispatch = useAppDispatch();
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || '';
  const { promotionTransactionId, currentParams } = useAppSelector(
    (store) => store.widgets
  );
  const globalParams = useParams();

  const params = useMemo(
    () => (currentParams ? currentParams : globalParams),
    [globalParams, currentParams]
  );

  const { data: defaultImageLibary, isFetching: isDefaultImageLibaryFetching } =
    useImagesQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: skipUnnecessaryRequests }
    );

  const {
    data: customImageLibary,
    isFetching: isCustomImageLibaryFetching,
    refetch: refetchCustomImageLibary,
  } = useGetWidgetsV6LookupImagesCustomQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: skipUnnecessaryRequests }
  );

  useEffect(() => {
    if (defaultImageLibary) {
      dispatch(setDefaultImageLibary(defaultImageLibary));
    }
  }, [defaultImageLibary]);

  useEffect(() => {
    if (customImageLibary) {
      dispatch(setCustomImageLibary(customImageLibary));
    }
  }, [customImageLibary]);

  const { data: defaultIconLibary, isFetching: isDefaultIconLibaryFetching } =
    useIconsQuery(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      { skip: skipUnnecessaryRequests }
    );
  const {
    data: customIconLibary,
    isFetching: isCustomIconLibaryFetching,
    refetch: refetchCustomIconLibary,
  } = useGetWidgetsV6LookupIconsCustomQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    },
    { skip: skipUnnecessaryRequests }
  );

  useEffect(() => {
    if (defaultIconLibary) {
      dispatch(setDefaultIconLibary(defaultIconLibary));
    }
  }, [defaultIconLibary]);

  useEffect(() => {
    if (customIconLibary) {
      dispatch(setCustomIconLibary(customIconLibary));
    }
  }, [customIconLibary]);

  const {
    data: fontListData,
    isFetching: fontListIsFetching,
    error: fontListError,
    refetch: refetchFontList,
  } = useFontsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
    },
    { skip: fetchType !== WidgetsFetchTypeEnum.PRESET_FONT_LIST }
  );

  const {
    data: promotionWidgetsData,
    isFetching: promotionWidgetsIsFetching,
    error: promotionWidgetsError,
    refetch: refetchPromotionWidgets,
  } = useGetWidgetsV6PromotionByIdOverviewQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: params?.promotionId || '',
    },
    {
      skip:
        fetchType !== WidgetsFetchTypeEnum.PROMOTION_OVERVIEW ||
        !params?.promotionId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    changePromotionPresetMutation,
    { isLoading: changePromotionPresetIsLoading },
  ] = usePutWidgetsV6PromotionByIdChangePresetMutation();

  const changePromotionPreset = useCallback(
    async (presetId: string, refetchData: () => void) => {
      await changePromotionPresetMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: params?.promotionId as string,
        body: {
          presetId: presetId,
        },
      })
        .unwrap()
        .then(() => refetchData())
        .catch(() => null);
    },
    [changePromotionPresetMutation, params]
  );

  const {
    data: affectedPromotions,
    isFetching: affectedPromotionsIsFetching,
    error: affectedPromotionsError,
    refetch: refetchAffectedPromotions,
  } = useGetWidgetsV6PresetsByIdAffectedPromotionsQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: params?.presetId || '',
    },
    {
      skip:
        fetchType !== WidgetsFetchTypeEnum.EDIT_PRESET_SKELETON ||
        !params?.presetId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    createNewStylePresetMutation,
    { isLoading: createNewStylePresetIsLoading },
  ] = useCreateMutation();

  const [deletePresetMutation] = useDeleteWidgetsV6PresetsByIdMutation();

  const [duplicatePresetMutation] =
    usePostWidgetsV6PresetsByIdDuplicateMutation();

  const [renamePresetMutation] = usePutWidgetsV6PresetsByIdRenameMutation();

  const [setAsDefaultPresetMutation] =
    usePostWidgetsV6PresetsByIdDefaultMutation();

  const [applyToAllPromotionsMutation] =
    usePostWidgetsV6PresetsByIdApplyToAllPromotionsMutation();

  const applyToAllPromotionsAction = useCallback(
    async (
      id: string,
      setRefreshList: React.Dispatch<React.SetStateAction<number>>
    ) => {
      await applyToAllPromotionsMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => {
          dispatch(
            setToastMessages({
              error: false,
              message: 'AppliedToAllPromotions',
            })
          );
          setRefreshList((prevCount) => prevCount + 1);
        })
        .catch(() => null);
    },
    [applyToAllPromotionsMutation]
  );

  const deletePreset = useCallback(
    async (
      id: string,
      setRefreshList: React.Dispatch<React.SetStateAction<number>>
    ) => {
      await deletePresetMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => {
          dispatch(
            setToastMessages({
              error: false,
              message: 'PresetDeleted',
            })
          );
          setRefreshList((prevCount) => prevCount + 1);
        })
        .catch(() => null);
    },
    [deletePresetMutation]
  );

  const setAsDefaultPreset = useCallback(
    async (id: string, setRefreshList: () => void) => {
      await setAsDefaultPresetMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => {
          setRefreshList();
        });
    },
    [setAsDefaultPresetMutation]
  );

  const renamePreset = useCallback(
    async (
      id: string,
      name: string,
      setRefreshList: React.Dispatch<React.SetStateAction<number>>
    ) => {
      await renamePresetMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
        body: { name: name },
      })
        .unwrap()
        .then(() => {
          dispatch(
            setToastMessages({
              error: false,
              message: 'PresetRenamed',
            })
          );
          setRefreshList((prevCount) => prevCount + 1);
        });
    },
    [renamePresetMutation]
  );

  const duplicatePreset = useCallback(
    async (
      id: string,
      setRefreshList: React.Dispatch<React.SetStateAction<number>>
    ) => {
      await duplicatePresetMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .then(() => {
          dispatch(
            setToastMessages({
              error: false,
              message: 'PresetDuplicated',
            })
          );
          setRefreshList((prevCount) => prevCount + 1);
        });
    },
    [duplicatePresetMutation]
  );

  const createNewStylePreset = useCallback(
    async (
      data: CreatePresetRequestDto,
      setRefreshList: React.Dispatch<React.SetStateAction<number>>
    ) => {
      await createNewStylePresetMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
      })
        .unwrap()
        .then(() => {
          dispatch(
            setToastMessages({
              error: false,
              message: 'StylePresetCreated',
            })
          );
          setRefreshList((prevCount) => prevCount + 1);
        })
        .catch(() => null);
    },
    [createNewStylePresetMutation]
  );

  const [uploadImageMutation, { isLoading: uploadImageIsLoading }] =
    usePostWidgetsV6ResourcesUploadImageMutation();

  const uploadImage = useCallback(
    async (files: any) => {
      await uploadImageMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: files,
      })
        .unwrap()
        .catch(() => null);
    },
    [uploadImageMutation]
  );

  const [uploadIconMutation, { isLoading: uploadIconIsLoading }] =
    usePostWidgetsV6ResourcesUploadIconMutation();

  const uploadIcon = useCallback(
    async (files: any) => {
      await uploadIconMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: files,
      })
        .unwrap()
        .catch(() => null);
    },
    [uploadIconMutation]
  );

  const [deleteIconMutation, { isLoading: deleteIconIsLoading }] =
    useDeleteWidgetsV6ResourcesIconByIdMutation();

  const deleteIcon = useCallback(
    async (id: string) => {
      await deleteIconMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .catch(() => null);
    },
    [deleteIconMutation]
  );

  const [deleteImageMutation, { isLoading: deleteImageIsLoading }] =
    useDeleteWidgetsV6ResourcesImagesByIdMutation();

  const deleteImage = useCallback(
    async (id: string) => {
      await deleteImageMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        id: id,
      })
        .unwrap()
        .catch(() => null);
    },
    [deleteImageMutation]
  );

  const [deleteAllImagesMutation, { isLoading: deleteAllImagesIsLoading }] =
    useDeleteWidgetsV6ResourcesImagesAllMutation();

  const deleteAllImages = useCallback(async () => {
    await deleteAllImagesMutation({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    })
      .unwrap()
      .catch(() => null);
  }, [deleteAllImagesMutation]);

  const [deleteAllIconsMutation, { isLoading: deleteAllIconsIsLoading }] =
    useDeleteWidgetsV6ResourcesIconsAllMutation();

  const deleteAllIcons = useCallback(async () => {
    await deleteAllIconsMutation({
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
    })
      .unwrap()
      .catch(() => null);
  }, [deleteAllIconsMutation]);

  const [
    saveEditPresetChangesMutation,
    { isLoading: saveEditPresetChangesIsLoading },
  ] = usePutWidgetsV6PresetsByIdMutation();

  const saveEditPresetChanges = useCallback(
    async (data: SavePresetRequestDto) => {
      await saveEditPresetChangesMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        body: data,
        id: params?.presetId || '',
      })
        .unwrap()
        .then(() =>
          dispatch(
            setToastMessages({
              error: false,
              message: 'PresetSaved',
            })
          )
        )
        .catch(() => null);
    },
    [saveEditPresetChangesMutation, params]
  );

  const [
    closeEditorTransactionMutation,
    { isLoading: closeEditorTransactionIsLoading },
  ] = usePostWidgetsV6PromotionByIdTransactionCloseMutation();

  const closeEditorTransaction = useCallback(
    async (
      transactionId: string,
      closeTransactionSetup: CloseEditorTransactionRequestDto
    ) => {
      await closeEditorTransactionMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        'X-DiscountNinja-Transaction': transactionId,
        id: params?.promotionId || '',
        body: closeTransactionSetup,
      })
        .unwrap()
        .then(() =>
          dispatch(
            setToastMessages({
              error: false,
              message: 'PromotionPresetSaved',
            })
          )
        )
        .catch(() => null);
    },
    [closeEditorTransactionMutation, params]
  );

  const [
    saveEditPromotionChangesMutation,
    { isLoading: saveEditPromotionChangesIsLoading },
  ] = usePutWidgetsV6PromotionByIdMutation();

  const saveEditPromotionChanges = useCallback(
    async (data: PromotionSetupDto) => {
      await saveEditPromotionChangesMutation({
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        'X-DiscountNinja-Transaction': promotionTransactionId || '',
        body: data,
        id: params?.promotionId || '',
      })
        .unwrap()
        .catch(() => null);
    },
    [saveEditPromotionChangesMutation, promotionTransactionId, params]
  );

  return {
    createNewStylePresetIsLoading,
    createNewStylePreset,
    fontListData,
    fontListIsFetching,
    fontListError,
    refetchFontList,
    saveEditPresetChangesIsLoading,
    saveEditPresetChanges,
    affectedPromotions,
    affectedPromotionsIsFetching,
    affectedPromotionsError,
    refetchAffectedPromotions,
    saveEditPromotionChangesIsLoading,
    saveEditPromotionChanges,
    closeEditorTransactionIsLoading,
    closeEditorTransaction,
    uploadImageIsLoading,
    uploadImage,
    uploadIconIsLoading,
    uploadIcon,
    deleteIconIsLoading,
    deleteIcon,
    deleteImageIsLoading,
    deleteImage,
    deleteAllImagesIsLoading,
    deleteAllImages,
    deleteAllIconsIsLoading,
    deleteAllIcons,
    defaultImageLibary,
    isDefaultImageLibaryFetching,
    customImageLibary,
    isCustomImageLibaryFetching,
    refetchCustomImageLibary,
    defaultIconLibary,
    isDefaultIconLibaryFetching,
    customIconLibary,
    isCustomIconLibaryFetching,
    refetchCustomIconLibary,
    deletePreset,
    duplicatePreset,
    renamePreset,
    setAsDefaultPreset,
    applyToAllPromotionsAction,
    promotionWidgetsData,
    promotionWidgetsIsFetching,
    promotionWidgetsError,
    refetchPromotionWidgets,
    changePromotionPresetIsLoading,
    changePromotionPreset,
  };
};
