import React, { useMemo, useState } from 'react';
import { BlockStack, Box, Page } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { SettingsSkeleton } from '../components/SettingsSkeleton/SettingsSkeleton';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { UserSubscriptionPlan } from './components/UserSubscriptionPlan/UserSubscriptionPlan';
import { UserBillingSummary } from './components/UserBillingSummary/UserBillingSummary';
import { PlanIntervalDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';
import { CancelSubscription } from './components/CancelSubscription/CancelSubscription';
import {
  ChangePlanModal,
  ChangePlanModalTypeEnum,
} from './components/ChangePlanModal/ChangePlanModal';
import { PlanLevelDto } from 'core/api/adminSettings/adminSettingsApi';

export type ChangePlanConfigDto = {
  modalType: ChangePlanModalTypeEnum;
  targetPlanLevel: PlanLevelDto;
  threshold?: number;
  interval: PlanIntervalDtoEnum;
  nextCycle?: number;
};
type PlanProps = {
  isMaxModal: boolean;
};
export const Plan: React.FC<PlanProps> = ({ isMaxModal }) => {
  const [i18n] = useI18n();
  const { currentPlansIsLoading, currentPlanData, refetchCurrentPlanData } =
    useConfigureSettings(SettingsFetchTypeEnum.PLAN);

  const [changePlanConfig, setChangePlanConfig] =
    useState<ChangePlanConfigDto | null>(null);

  const showCancelSubsrciption = useMemo(
    () =>
      currentPlanData?.plan?.name && !currentPlanData?.plan?.cancellationDate,
    [currentPlanData?.plan]
  );

  return (
    <>
      {currentPlansIsLoading ? (
        <SettingsSkeleton />
      ) : (
        <Page title={i18n.translate('Plan')}>
          <Box paddingBlockEnd='1000'>
            <BlockStack gap='600'>
              <UserSubscriptionPlan
                currentPlanData={currentPlanData}
                isMaxModal={isMaxModal}
                setChangePlanConfig={setChangePlanConfig}
                refetchCurrentPlanData={refetchCurrentPlanData}
              />
              {currentPlanData?.billing && currentPlanData.plan?.name && (
                <UserBillingSummary
                  currentPlanData={currentPlanData}
                  setChangePlanConfig={setChangePlanConfig}
                />
              )}
              {showCancelSubsrciption && (
                <CancelSubscription
                  refetchCurrentPlanData={refetchCurrentPlanData}
                />
              )}
            </BlockStack>
          </Box>
        </Page>
      )}
      {changePlanConfig && (
        <ChangePlanModal
          {...changePlanConfig}
          isOpen={!!changePlanConfig}
          onClose={() => setChangePlanConfig(null)}
          isMaxModal={isMaxModal}
        />
      )}
    </>
  );
};
