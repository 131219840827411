import React, { useCallback, useState } from 'react';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris';
import { ChevronRightIcon, ThemeIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  PageTypeDtoEnum,
  WidgetStatusDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  PlanLevelDto,
  WidgetInstallationDto,
} from 'core/api/adminSettings/adminSettingsApi';
import { useSearchParams } from 'react-router-dom';
import './SettingsWidget.scss';
import {
  ChangePlanModal,
  ChangePlanModalTypeEnum,
} from 'features/settings/components/GeneralSettings/Plan/components/ChangePlanModal/ChangePlanModal';

type SettingsWidgetProps = {
  widget: WidgetInstallationDto;
  isMaxModal: boolean;
};
export const SettingsWidget: React.FC<SettingsWidgetProps> = ({
  widget,
  isMaxModal,
}) => {
  const [i18n] = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();
  const [changePlanModal, setChangePlanModal] = useState<boolean>(false);

  const onWidgetClick = useCallback(
    (widgetPath: string) => {
      searchParams.set('subPath', widgetPath);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  const convertWidgetPages = (arr: PageTypeDtoEnum[]) => {
    const formattedArray = arr?.map((item) => {
      return i18n.translate(`${item}_PAGE`);
    });
    return formattedArray.length ? ` • ${formattedArray?.join(' & ')}` : null;
  };

  const handleChangePlanModal = useCallback(
    (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e?.stopPropagation();
      setChangePlanModal((prev) => !prev);
    },
    [setChangePlanModal]
  );

  const isEnabled =
    widget.status === WidgetStatusDtoEnum.ENABLED ||
    widget.status === WidgetStatusDtoEnum.ENABLED_VIA_CODE ||
    widget.status === WidgetStatusDtoEnum.ENABLED_VIA_CSS;

  return (
    <>
      <div
        className='WidgetListItem'
        onClick={() =>
          onWidgetClick(i18n.translate(`WidgetLinks.${widget.type}_LINK`))
        }
      >
        <InlineStack gap={'300'} blockAlign='center'>
          <Icon tone='base' source={ThemeIcon} />
          <BlockStack gap='100'>
            <Text as='p'>
              {i18n.translate(`Widgets.${widget.type}`)}{' '}
              <Badge tone={isEnabled ? 'success' : undefined}>
                {i18n.translate(isEnabled ? 'On' : 'Off')}
              </Badge>
              {widget.planSupportingType && (
                <Badge tone='attention'>
                  {widget.planSupportingType?.name}
                </Badge>
              )}
            </Text>
            <Text tone='subdued' as='p'>
              {i18n.translate(`${widget.installationMode}`)}
              {convertWidgetPages((widget.pages || []) as PageTypeDtoEnum[])}
            </Text>
          </BlockStack>
        </InlineStack>
        <InlineStack blockAlign='center' gap='300'>
          {widget.status === WidgetStatusDtoEnum.LOCKED &&
            !widget.isSupportedByCurrentPlan && (
              <div onClick={handleChangePlanModal}>
                <Button variant='primary'>
                  {i18n.translate('GetTheFeature')}
                </Button>
              </div>
            )}
          <Box>
            <Icon tone='base' source={ChevronRightIcon} />
          </Box>
        </InlineStack>
      </div>
      {changePlanModal && (
        <ChangePlanModal
          targetPlanLevel={widget.planSupportingType?.level as PlanLevelDto}
          modalType={ChangePlanModalTypeEnum.GET_FEATURE}
          isOpen={changePlanModal}
          onClose={handleChangePlanModal}
          isMaxModal={isMaxModal}
        />
      )}
    </>
  );
};
