import { useCallback, useState } from 'react';
import { ExportFileModalTypeEnum } from 'core/components';
import { useAppDispatch } from 'core/hooks';
import { setToastMessages } from 'core/store/offersWizardSlice';
import resolveEnvVar from 'env-var-resolver';
import { AnalyticsReportListRequestDto } from '../components/Charts/AnalyticsReportList/AnalyticsReportList';

const analyticsApiURL = `${resolveEnvVar(
  'REACT_APP_ANALYTICS_ADMIN_API_URL'
)}/analytics/v6/`;

const useExportListReports = () => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const dispatch = useAppDispatch();
  const [exportReportIsFetching, setExportReportIsFetching] =
    useState<boolean>(false);

  const downloadFile = useCallback((blob: Blob, fileName: string) => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(url);
  }, []);

  const exportReportList = async (
    exportLink: string,
    setup: AnalyticsReportListRequestDto,
    exportType: ExportFileModalTypeEnum
  ) => {
    setExportReportIsFetching(true);

    let body: AnalyticsReportListRequestDto;
    switch (exportType) {
      case ExportFileModalTypeEnum.ALL:
        body = {
          page: 1,
          itemsPerPage: 0,
          sortBy: setup.sortBy,
          sortDirection: setup.sortDirection,
        };
        break;
      case ExportFileModalTypeEnum.SEARCH_MATCH:
        body = { ...setup, page: 1, itemsPerPage: 0 };
        break;
      default:
        body = setup;
    }

    await fetch(`${analyticsApiURL}/${exportLink}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-LimoniApps-AppName': 'DiscountNinja',
        'X-LimoniApps-AppSecret': APP_PASSWORD,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        const filename =
          response.headers.get('content-disposition')?.split('"')[1] || '';
        response.blob().then((myBlob) => downloadFile(myBlob, filename));
      })
      .catch(() =>
        dispatch(
          setToastMessages({
            error: true,
            message: 'ReportExportFailed',
          })
        )
      )
      .finally(() => setExportReportIsFetching(false));
  };
  return {
    exportReportList,
    exportReportIsFetching,
  };
};

export default useExportListReports;
