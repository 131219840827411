import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Card,
  BlockStack,
  Text,
  InlineStack,
  Icon,
  Box,
  Button,
  Badge,
  Popover,
  ActionList,
  Modal,
  TextField,
  Checkbox,
} from '@shopify/polaris';
import {
  EmailIcon,
  MenuHorizontalIcon,
  PlusIcon,
  ToggleOffIcon,
  EditIcon,
  DeleteIcon,
} from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  AccountNotificationSettingsDto,
  AccountNotificationsRecipientDto,
} from 'core/api/adminSettings/adminSettingsApi';
import * as yup from 'yup';

type AccountNotificationsProps = {
  data: AccountNotificationSettingsDto;
  handleAccountLocaleState: (data: AccountNotificationSettingsDto) => void;
};

export const AccountNotifications: React.FC<AccountNotificationsProps> = ({
  data,
  handleAccountLocaleState,
}) => {
  const [i18n] = useI18n();

  const [activePopover, setActivePopover] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [openModal, setOpenModal] = useState<{
    open: boolean;
    type: 'EditRecipient' | 'AddRecipient' | '';
    emailAddress?: string;
    id: string;
  }>({
    open: false,
    type: '',
    id: '',
  });

  const handleCloseModal = useCallback(() => {
    setOpenModal({ open: false, type: '', emailAddress: '', id: '' });
    setEmailError('');
  }, [openModal]);

  const [recipientsModify, setRecipientsModify] =
    useState<AccountNotificationsRecipientDto>({});

  const changeRecipientByEmail = useCallback(
    (
      emailAddress: string,
      recipientModify: AccountNotificationsRecipientDto
    ): AccountNotificationsRecipientDto[] | undefined => {
      return data?.recipients?.map((recipient) =>
        recipient.emailAddress === emailAddress ? recipientModify : recipient
      );
    },
    [recipientsModify, data?.recipients]
  );

  const removeRecipientByEmail = useCallback(
    (emailAddress: string): AccountNotificationsRecipientDto[] | undefined => {
      return data?.recipients?.filter(
        (recipient) => recipient.emailAddress !== emailAddress
      );
    },
    [data?.recipients]
  );

  const handleEditRecipients = useCallback(
    (
      recipientsModify: AccountNotificationsRecipientDto,
      openModal: { open: boolean; type: string; emailAddress?: string }
    ) => {
      const updatedRecipients = changeRecipientByEmail(
        openModal.emailAddress || '',
        recipientsModify
      );

      handleAccountLocaleState({ ...data, recipients: updatedRecipients });
    },
    [recipientsModify, openModal, data]
  );

  const changeIsEnabledByEmail = useCallback(
    (emailAddress: string): AccountNotificationsRecipientDto[] | undefined => {
      return data?.recipients?.map((recipient) =>
        recipient.emailAddress === emailAddress
          ? { ...recipient, isEnabled: !recipient.isEnabled }
          : recipient
      );
    },
    [data?.recipients]
  );

  const currentRecipients = useMemo(
    () =>
      openModal.type === 'EditRecipient' && openModal.id
        ? data?.recipients?.filter((_, idx) => idx !== +openModal.id)
        : data?.recipients,
    [data?.recipients, openModal]
  );

  const isAnyChecked = useMemo(
    () =>
      !!(
        recipientsModify.releaseNotes ||
        recipientsModify.statusUpdates ||
        recipientsModify.system
      ),
    [recipientsModify]
  );

  const emailValidation = yup
    .string()
    .email('EmailInvalid')
    .required('EmailRequired')
    .test('unique', 'EmailExist', (value: string | undefined) => {
      const emailExists = currentRecipients?.some(
        (recipient) => recipient.emailAddress === value
      );
      return !emailExists;
    });

  const handleEmailValidate = async () => {
    await emailValidation
      .validate(recipientsModify.emailAddress, { abortEarly: false })
      .then(() => {
        setEmailError('');
      })
      .catch((error) => {
        setEmailError(error.message);
        return error.message;
      });
  };

  useEffect(() => {
    handleEmailValidate();
  }, [recipientsModify.emailAddress]);

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('NotificationsTitle')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('NotificationsSubtitle')}
          </Text>
        </BlockStack>
        <Box borderWidth='025' borderColor='border' borderRadius='200'>
          {data.recipients?.map((recipient, index) => {
            const {
              emailAddress,
              isEnabled,
              statusUpdates,
              system,
              releaseNotes,
            } = recipient;
            const subText = [
              statusUpdates && i18n.translate('statusUpdates'),
              system && i18n.translate('system'),
              releaseNotes && i18n.translate('releaseNotes'),
            ]
              .filter(Boolean)
              .join(', ');
            return (
              <Box
                key={emailAddress}
                padding='300'
                borderColor='border'
                borderBlockEndWidth='025'
              >
                <InlineStack align='space-between' blockAlign='center'>
                  <InlineStack gap='300'>
                    <BlockStack align='center' inlineAlign='center'>
                      <Icon source={EmailIcon} />
                    </BlockStack>
                    <BlockStack gap='025'>
                      <Text as='p' variant='bodyMd' tone='base'>
                        {emailAddress}
                      </Text>
                      <Text as='p' tone='subdued'>
                        {subText.charAt(0).toUpperCase() +
                          subText.slice(1).toLowerCase()}
                      </Text>
                    </BlockStack>
                  </InlineStack>
                  <InlineStack gap='300'>
                    {!isEnabled && <Badge>{i18n.translate('Off')}</Badge>}
                    <Popover
                      active={emailAddress === activePopover}
                      activator={
                        <Button
                          onClick={() =>
                            setActivePopover(
                              activePopover ? '' : emailAddress || ''
                            )
                          }
                          icon={MenuHorizontalIcon}
                          variant='tertiary'
                        />
                      }
                      autofocusTarget='first-node'
                      onClose={() => {
                        setActivePopover('');
                        setRecipientsModify({});
                      }}
                    >
                      <ActionList
                        actionRole='menuitem'
                        onActionAnyItem={() => setActivePopover('')}
                        items={[
                          {
                            content: isEnabled
                              ? i18n.translate('TurnOff')
                              : i18n.translate('TurnOn'),
                            prefix: <Icon source={ToggleOffIcon} />,
                            onAction: () => {
                              const updatedRecipients = changeIsEnabledByEmail(
                                emailAddress || ''
                              );
                              handleAccountLocaleState({
                                ...data,
                                recipients: updatedRecipients,
                              });
                            },
                          },
                          {
                            content: i18n.translate('Edit'),
                            prefix: <Icon source={EditIcon} />,
                            onAction: () => {
                              setOpenModal({
                                open: true,
                                type: 'EditRecipient',
                                emailAddress: emailAddress,
                                id: index.toString(),
                              });
                              setRecipientsModify(recipient);
                            },
                          },
                          {
                            content: i18n.translate('Remove'),
                            prefix: <Icon source={DeleteIcon} />,
                            destructive: true,
                            onAction: () => {
                              const updatedRecipients = removeRecipientByEmail(
                                emailAddress || ''
                              );
                              handleAccountLocaleState({
                                ...data,
                                recipients: updatedRecipients,
                              });
                            },
                          },
                        ]}
                      />
                    </Popover>
                  </InlineStack>
                </InlineStack>
              </Box>
            );
          })}
          <Box padding='300' width='100%'>
            <Button
              variant='tertiary'
              disabled={data.recipients && data?.recipients?.length >= 20}
              icon={PlusIcon}
              onClick={() => {
                setOpenModal({ open: true, type: 'AddRecipient', id: '' });
              }}
            >
              {i18n.translate('AddRecipient')}
            </Button>
          </Box>
        </Box>
      </BlockStack>
      <Modal
        open={openModal.open}
        onClose={() => {
          handleCloseModal();
          setRecipientsModify({});
        }}
        title={openModal.type ? i18n.translate(openModal.type) : ''}
        primaryAction={{
          content: i18n.translate('Save'),
          disabled: !!(emailError || !isAnyChecked),
          onAction:
            openModal.type === 'AddRecipient'
              ? () => {
                  handleAccountLocaleState({
                    ...data,
                    recipients: [
                      ...(data.recipients || []),
                      { ...recipientsModify, isEnabled: true },
                    ],
                  });
                  handleCloseModal();
                  setRecipientsModify({});
                }
              : () => {
                  handleEditRecipients(recipientsModify, openModal);
                  handleCloseModal();
                  setRecipientsModify({});
                },
        }}
        secondaryActions={[
          {
            content: i18n.translate('Cancel'),
            onAction: () => {
              handleCloseModal();
            },
          },
        ]}
      >
        <Modal.Section>
          <BlockStack gap='400'>
            <TextField
              label={i18n.translate('EmailAddress')}
              name='email'
              type='email'
              autoComplete='email'
              value={recipientsModify.emailAddress}
              error={emailError && i18n.translate(`${emailError}`)}
              onChange={(value) => {
                setRecipientsModify((prevState) => ({
                  ...prevState,
                  emailAddress: value,
                }));
              }}
            />
            <BlockStack gap='100'>
              <Text as='h2' variant='headingSm'>
                {i18n.translate('SelectNotifications')}
              </Text>
              <Checkbox
                label={i18n.translate('statusUpdates')}
                checked={recipientsModify.statusUpdates}
                onChange={(value) => {
                  setRecipientsModify((prevState) => ({
                    ...prevState,
                    statusUpdates: value,
                  }));
                }}
              />
              <Checkbox
                label={i18n.translate('system')}
                checked={recipientsModify.system}
                onChange={(value) => {
                  setRecipientsModify((prevState) => ({
                    ...prevState,
                    system: value,
                  }));
                }}
              />
              <Checkbox
                label={i18n.translate('releaseNotes')}
                checked={recipientsModify.releaseNotes}
                onChange={(value) => {
                  setRecipientsModify((prevState) => ({
                    ...prevState,
                    releaseNotes: value,
                  }));
                }}
              />
            </BlockStack>
          </BlockStack>
        </Modal.Section>
      </Modal>
    </Card>
  );
};
