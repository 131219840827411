import React from 'react';
import {
  Banner,
  BlockStack,
  Box,
  Icon,
  InlineStack,
  Link,
  MediaCard,
  Text,
  VideoThumbnail,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { ChevronRightIcon } from '@shopify/polaris-icons';
type WidgetInstallationStepProps = {
  onNextStep: () => void;
  isWidgetRequestSent: boolean;
};
export const WidgetInstallationStep: React.FC<WidgetInstallationStepProps> = ({
  onNextStep,
  isWidgetRequestSent,
}) => {
  const [i18n] = useI18n();
  return (
    <Box paddingBlock='400'>
      <BlockStack gap='400'>
        <InlineStack align='space-between' blockAlign='start'>
          <BlockStack gap='200'>
            <Text as='h1' variant='headingXl' fontWeight='semibold'>
              {i18n.translate('Title')}
            </Text>
            <Text as='p' tone='subdued'>
              {i18n.translate('Subtitle')}
            </Text>
          </BlockStack>
          {!isWidgetRequestSent && (
            <Box paddingBlockStart='150'>
              <Link removeUnderline onClick={onNextStep}>
                <InlineStack blockAlign='center'>
                  {i18n.translate('SkipAndFinish')}
                  <Icon source={ChevronRightIcon} />
                </InlineStack>
              </Link>
            </Box>
          )}
        </InlineStack>
        <MediaCard
          title={i18n.translate('VideoTitle')}
          primaryAction={{
            content: i18n.translate('LearnMore'),
          }}
          description={i18n.translate('VideoSubitle')}
          size='small'
        >
          <VideoThumbnail
            videoLength={80}
            thumbnailUrl='https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850'
            onClick={() =>
              window.open(
                'https://www.youtube.com/watch?v=RVmG_d3HKBA',
                '_blank'
              )
            }
          />
        </MediaCard>
        {isWidgetRequestSent && (
          <Banner tone='success'>{i18n.translate('RequestBanner')}</Banner>
        )}
      </BlockStack>
    </Box>
  );
};
