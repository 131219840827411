import React from 'react';
import styles from './SetupItem.module.scss';
import { StepProp } from 'features/dashboard/Dashboard';
import {
  BlockStack,
  Box,
  Button,
  InlineStack,
  Text,
  Collapsible,
  ButtonGroup,
  Icon,
  Image,
  Banner,
} from '@shopify/polaris';
import { CheckIcon, ImagesIcon } from '@shopify/polaris-icons';
import { Loader } from 'core/components';
import { outlineSvg } from './assets/icons';

type SetupItemProps = StepProp & {
  expanded: boolean;
  loading: boolean;
  setExpanded: () => void;
};

export const SetupItem: React.FC<SetupItemProps> = (props) => {
  const {
    completed,
    expanded,
    title,
    description,
    banner,
    primaryButton,
    secondaryButton,
    imageUrl,
    id,
    loading,
    action,
    setExpanded,
  } = props;

  return (
    <Box
      borderRadius='200'
      background={expanded ? 'bg-surface-active' : 'bg-surface'}
    >
      <div
        className={`${styles.setupItem} ${
          expanded ? styles.setupItemExpanded : ''
        }`}
        style={{ cursor: expanded ? 'default' : 'pointer' }}
        onClick={expanded ? () => null : setExpanded}
      >
        <InlineStack gap='200' align='start' blockAlign='start' wrap={false}>
          <div className={styles.completeButton}>
            {loading && expanded ? (
              <Loader fullWidth size='small' />
            ) : completed ? (
              <CheckIcon
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                  borderRadius: '100%',
                  background: 'var(--p-color-bg-fill-brand)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fill: 'white',
                }}
              />
            ) : (
              outlineSvg
            )}
          </div>
          <div
            className={styles.itemContent}
            style={{
              width: '100%',
            }}
          >
            <div style={{ width: '75%' }}>
              <BlockStack gap='100' id={`${id}`}>
                <div style={{ paddingTop: '.15rem' }}>
                  <Text as='h4' variant={expanded ? 'headingSm' : 'bodyMd'}>
                    {title}
                  </Text>
                </div>
                <Collapsible open={expanded} id={`${id}`}>
                  <BlockStack gap='400'>
                    <Text as='p' variant='bodyMd' tone='subdued'>
                      {description}
                    </Text>
                    {banner && <Banner tone='success'>{banner}</Banner>}
                    {!completed && !banner ? (
                      <ButtonGroup gap='loose'>
                        {primaryButton ? (
                          <Button variant='primary' onClick={action}>
                            {primaryButton}
                          </Button>
                        ) : null}
                        {secondaryButton ? (
                          <Button variant='tertiary'>{secondaryButton}</Button>
                        ) : null}
                      </ButtonGroup>
                    ) : null}
                  </BlockStack>
                </Collapsible>
              </BlockStack>
            </div>
            <Collapsible
              open={expanded}
              id={`img_${id}`}
              transition={{ animateIn: true }}
            >
              {imageUrl ? (
                <Image className={styles.itemImage} source={imageUrl} alt='' />
              ) : (
                <div className={styles.itemImage}>
                  <Icon source={ImagesIcon} tone='subdued' />
                </div>
              )}
            </Collapsible>
          </div>
        </InlineStack>
      </div>
    </Box>
  );
};
