import React, { useCallback, useState } from 'react';
import { MediaCard, VideoThumbnail } from '@shopify/polaris';
import { getVideoThumbnail } from 'core/utils/getThumbnail';
import { LearnMoreModal } from 'features/promotions/components/SelectedOfferTypeSettings/components/LearnMoreModal/LearnMoreModal';

type VideoCardProps = {
  cardData: {
    title: string;
    description: string;
    btn: string;
    videoUrl: string;
    link: string;
  };
};

export const VideoCard: React.FC<VideoCardProps> = (props) => {
  const { cardData } = props;

  const [showHowModal, setShowHowModal] = useState<boolean>(false);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);

  const thumbnailUrl = getVideoThumbnail(
    cardData.videoUrl ||
      'https://www.loom.com/share/cd98f3811dcf418aa0d2104b3c567421'
  );

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState) => !prevState);
  }, [setShowHowModal]);

  return (
    <>
      <MediaCard
        title={cardData.title}
        primaryAction={{
          content: cardData.btn,
          onAction: () =>
            window.open(cardData.link, '_blank', 'noopener,noreferrer'),
        }}
        description={cardData.description}
        size='small'
      >
        <VideoThumbnail
          videoLength={duration}
          videoProgress={currentTime}
          showVideoProgress={!!duration}
          thumbnailUrl={thumbnailUrl}
          onClick={toggleShowHowModal}
        />
      </MediaCard>
      {showHowModal && (
        <LearnMoreModal
          onModalClose={toggleShowHowModal}
          url={
            cardData.videoUrl ||
            'https://www.loom.com/share/cd98f3811dcf418aa0d2104b3c567421'
          }
          title={cardData.title}
          setDuration={setDuration}
          setCurrentTime={setCurrentTime}
        />
      )}
    </>
  );
};
