// import React, { useCallback, useEffect, useState } from 'react';
// import { BlockStack } from '@shopify/polaris';
// import { useDispatch } from 'react-redux';
// import {
//   SettingsFetchTypeEnum,
//   useConfigureSettings,
// } from 'features/settings/hooks/useConfigureSettings';
// import { isEmpty, isEqual } from 'lodash';
// import {
//   setIsUnsavedChanges,
//   setOnDiscardChanges,
//   setOnSaveChanges,
// } from 'core/store/settingsSlice';
// import {
//   ShopifyPromotionCodesShopSettingsDto,
//   WidgetSettingsDtoShopifyPromotionCodesShopSettingsDto,
// } from 'core/api/adminSettings/adminSettingsApi';
// import {
//   CodesSupportSetting,
//   WidgetSettingsWrapper,
//   WidgetTypeSettingsWrapperEnum,
// } from '../components';
// import { useI18n } from '@shopify/react-i18n';
// import { ShopifyDiscountCodesFilters } from './components/ShopifyDiscountCodesFilters/ShopifyDiscountCodesFilters';
// import { CodesPattern } from './components/CodesPattern/CodesPattern';
// import { CodesCombinations } from './components/CodesCombinations/CodesCombinations';

// export const ShopifyDiscountCodesSettings: React.FC = () => {
//   const dispatch = useDispatch();
//   const {
//     shopifyDiscountCodesData,
//     saveShopifyDiscountCodes,
//     saveShopifyDiscountCodesIsLoading,
//     shopifyDiscountCodesDataIsFetching,
//   } = useConfigureSettings(SettingsFetchTypeEnum.SHOPIFY_DISCOUNT_CODES);
//   const [i18n] = useI18n();
//   const [savedData, setSavedData] =
//     useState<WidgetSettingsDtoShopifyPromotionCodesShopSettingsDto>({});
//   const [shopifyDiscountCodesState, setShopifyDiscountCodesState] =
//     useState<WidgetSettingsDtoShopifyPromotionCodesShopSettingsDto>({});

//   const updateShopifyCodesState = useCallback(
//     (
//       field: keyof ShopifyPromotionCodesShopSettingsDto,
//       data: ShopifyPromotionCodesShopSettingsDto[keyof ShopifyPromotionCodesShopSettingsDto]
//     ) =>
//       setShopifyDiscountCodesState((prev) => ({
//         ...prev,
//         settings: {
//           ...prev.settings,
//           [field]: data,
//         },
//       })),
//     [setShopifyDiscountCodesState]
//   );

//   useEffect(() => {
//     if (
//       !isEmpty(shopifyDiscountCodesState) &&
//       !isEqual(savedData, shopifyDiscountCodesState)
//     ) {
//       dispatch(setIsUnsavedChanges(true));
//       dispatch(
//         setOnDiscardChanges(
//           () => !isEmpty(savedData) && setShopifyDiscountCodesState(savedData)
//         )
//       );
//       dispatch(
//         setOnSaveChanges(() => {
//           setSavedData(shopifyDiscountCodesState);
//           return saveShopifyDiscountCodes(
//             shopifyDiscountCodesState.settings || {}
//           );
//         })
//       );
//     } else if (isEqual(savedData, shopifyDiscountCodesState)) {
//       dispatch(setIsUnsavedChanges(false));
//     }
//   }, [shopifyDiscountCodesState, savedData, dispatch]);

//   useEffect(() => {
//     if (!isEmpty(shopifyDiscountCodesData)) {
//       setShopifyDiscountCodesState(shopifyDiscountCodesData);
//       setSavedData(shopifyDiscountCodesData);
//     }
//   }, [shopifyDiscountCodesData]);

//   useEffect(() => {
//     return () => {
//       dispatch(setIsUnsavedChanges(false));
//     };
//   }, []);

//   return (
//     <div className='ShopifyDiscountCodesSettings'>
//       <WidgetSettingsWrapper
//         widgetState={{
//           ...shopifyDiscountCodesState,
//           widgetType: WidgetTypeSettingsWrapperEnum.SHOPIFY_DISCOUNT_CODES,
//         }}
//         isLoading={
//           shopifyDiscountCodesDataIsFetching || !shopifyDiscountCodesData
//         }
//         setEnabled={(value) =>
//           setShopifyDiscountCodesState({
//             ...shopifyDiscountCodesState,
//             status: value,
//           })
//         }
//       >
//         <BlockStack gap='600'>
//           <CodesSupportSetting
//             allow={!!shopifyDiscountCodesState.settings?.allowMultipleCodes}
//             setAllow={(value) =>
//               updateShopifyCodesState('allowMultipleCodes', value)
//             }
//           />
//           <ShopifyDiscountCodesFilters
//             overrideCountries={
//               !!shopifyDiscountCodesState.settings
//                 ?.overrideSelectedCountriesFilter
//             }
//             setOverrideCountries={(value) =>
//               updateShopifyCodesState('overrideSelectedCountriesFilter', value)
//             }
//             overrideEligibility={
//               !!shopifyDiscountCodesState.settings
//                 ?.overrideCustomerEligibilityFilter
//             }
//             setOverrideEligibility={(value) =>
//               updateShopifyCodesState(
//                 'overrideCustomerEligibilityFilter',
//                 value
//               )
//             }
//           />
//           <CodesPattern
//             pattern={shopifyDiscountCodesState.settings?.patterns || {}}
//             setPattern={(data) => updateShopifyCodesState('patterns', data)}
//           />
//           <CodesCombinations
//             combinations={
//               shopifyDiscountCodesState.settings?.combinations || {}
//             }
//             setCombinations={(data) =>
//               updateShopifyCodesState('combinations', data)
//             }
//           />
//         </BlockStack>
//       </WidgetSettingsWrapper>
//     </div>
//   );
// };
import React from 'react';

export const ShopifyDiscountCodesSettings = () => {
  return <div>ShopifyDiscountCodesSettings</div>;
};
