// import React, { useCallback, useEffect, useState } from 'react';
// import { BlockStack } from '@shopify/polaris';
// import { useDispatch } from 'react-redux';
// import {
//   SettingsFetchTypeEnum,
//   useConfigureSettings,
// } from 'features/settings/hooks/useConfigureSettings';
// import { isEmpty, isEqual } from 'lodash';
// import {
//   setIsUnsavedChanges,
//   setOnDiscardChanges,
//   setOnSaveChanges,
// } from 'core/store/settingsSlice';
// import {
//   CartStrikethroughPricingShopSettingsDto,
//   StrikethroughPricingFreeGiftsQuantitySpinnerModeDto,
//   WidgetSettingsDtoCartStrikethroughPricingShopSettingsDto,
// } from 'core/api/adminSettings/adminSettingsApi';
// import {
//   SpinnerModeSetting,
//   StyleSetting,
//   WidgetSettingsWrapper,
//   WidgetTypeSettingsWrapperEnum,
// } from '../components';

// export const StrikethroughCartSettings: React.FC = () => {
//   const dispatch = useDispatch();
//   const {
//     strikethroughCartData,
//     saveStrikethroughCart,
//     strikethroughCartIsFetching,
//     saveStrikethroughCartIsLoading,
//     refetchStrikethroughCartData,
//   } = useConfigureSettings(SettingsFetchTypeEnum.STRIKE_THROUGH_CART);
//   const [savedData, setSavedData] =
//     useState<WidgetSettingsDtoCartStrikethroughPricingShopSettingsDto>({});
//   const [strikethroughCartState, setStrikethroughCartState] =
//     useState<WidgetSettingsDtoCartStrikethroughPricingShopSettingsDto>({});

//   const updateCartState = useCallback(
//     (
//       field: keyof CartStrikethroughPricingShopSettingsDto,
//       data: CartStrikethroughPricingShopSettingsDto[keyof CartStrikethroughPricingShopSettingsDto]
//     ) =>
//       setStrikethroughCartState((prev) => ({
//         ...prev,
//         settings: {
//           ...prev.settings,
//           [field]: data,
//         },
//       })),
//     [setStrikethroughCartState]
//   );

//   useEffect(() => {
//     if (
//       !isEmpty(strikethroughCartState) &&
//       !isEqual(savedData, strikethroughCartState)
//     ) {
//       dispatch(setIsUnsavedChanges(true));
//       dispatch(
//         setOnDiscardChanges(
//           () => !isEmpty(savedData) && setStrikethroughCartState(savedData)
//         )
//       );
//       dispatch(
//         setOnSaveChanges(() => {
//           setSavedData(strikethroughCartState);
//           return saveStrikethroughCart(strikethroughCartState.settings || {});
//         })
//       );
//     } else if (isEqual(savedData, strikethroughCartState)) {
//       dispatch(setIsUnsavedChanges(false));
//     }
//   }, [strikethroughCartState, savedData, dispatch]);

//   useEffect(() => {
//     if (!isEmpty(strikethroughCartData)) {
//       setStrikethroughCartState(strikethroughCartData);
//       setSavedData(strikethroughCartData);
//     }
//   }, [strikethroughCartData]);

//   useEffect(() => {
//     return () => {
//       dispatch(setIsUnsavedChanges(false));
//     };
//   }, []);

//   return (
//     <div className='StrikethroughCartSettings'>
//       <WidgetSettingsWrapper
//         widgetState={{
//           ...strikethroughCartState,
//           widgetType: WidgetTypeSettingsWrapperEnum.CART_STRIKETHROUGH_PRICING,
//         }}
//         isLoading={strikethroughCartIsFetching || !strikethroughCartData}
//         refetchWidget={refetchStrikethroughCartData}
//       >
//         <BlockStack gap='600'>
//           <StyleSetting styled={!!strikethroughCartData?.isShopLevelWidget} />
//           <SpinnerModeSetting
//             spinnerMode={
//               strikethroughCartState.settings
//                 ?.spinnerMode as StrikethroughPricingFreeGiftsQuantitySpinnerModeDto
//             }
//             setSpinnerMode={(value) => updateCartState('spinnerMode', value)}
//           />
//         </BlockStack>
//       </WidgetSettingsWrapper>
//     </div>
//   );
// };
import React from 'react';

export const StrikethroughCartSettings = () => {
  return <div>StrikethroughCartSettings</div>;
};
