import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './SearchFieldWithGrayBoxOfferWizard.scss';
import { useI18n } from '@shopify/react-i18n';
import { Banner, BlockStack } from '@shopify/polaris';
import { ResourcePickerAdapter } from 'core/components/ResourcePickerAdapter';
import { useConfigureOffers } from 'features/promotions/hooks/useConfigureOffers';
import {
  ShopifyObjectDto,
  ShopifyObjectTypeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import {
  OfferTargetTypeDtoEnum,
  ShopifyObjectTypeDtoEnum,
} from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  Product,
  ProductVariant,
  Resource,
  ResourcePickerOptions,
  ResourcePickerResourceType,
  ResourceSelection,
} from 'core/api/appBridge';
import { useIsDebugOrLocal } from 'core/hooks';
import { ResourcePickerCustom } from '../ResourcePickerAdapter/components/ResourcePickerCustom';
import { headerTitle } from './utils/utils';
import { formateList } from 'core/utils';
import { SearchFieldPlaceholder } from './components/SearchFieldPlaceholder/SearchFieldPlaceholder';

export type ResourceSelectionProps = ResourceSelection<
  ResourcePickerOptions['type']
> & {
  featuredImageUrl: string;
  subtitle?: string;
};

export enum GrayBoxResourceTypeEnum {
  Product = 'Product',
  Collection = 'Collection',
}

export type GrayBoxResourceType = 'Product' | 'Collection';

type SelectSectionProps = {
  selection: ResourceSelectionProps[];
};

type SearchFieldWithGrayBoxProps = {
  selectList: ResourceSelectionProps[];
  resourceType: GrayBoxResourceType;
  showVariants?: boolean;
  isFreeGiftSearchField?: boolean;
  isDiscountLinkSearchField?: boolean;
  searchFieldType?: string;
  preventFromOpening?: boolean;
  isCrossSell?: boolean;
  productVariantId?: string;
  showLabel?: boolean;
  isLimitReached?: boolean;
  isFBTtype?: boolean;
  isOrderBumpType?: boolean;
  ignoreValidation?: boolean;
  uniqueLabel?: string;
  hideComponent?: boolean;
  onSelectedChange: (list: ResourceSelectionProps[]) => void;
  setConfiguredCollections?: (value: string) => void;
  onFormValidityChange?(isFormInvalid: boolean | null): void;
  getOnMount?: (list: ResourceSelectionProps[]) => void;
  changeSuggestedItemOrder?: (startIndex: number, endIndex: number) => void;
};

export const SearchFieldWithGrayBox: React.FC<SearchFieldWithGrayBoxProps> = (
  props
) => {
  const {
    resourceType,
    showVariants,
    isFreeGiftSearchField,
    isDiscountLinkSearchField,
    selectList,
    searchFieldType,
    preventFromOpening,
    isCrossSell,
    productVariantId,
    isLimitReached,
    isFBTtype,
    isOrderBumpType,
    ignoreValidation,
    hideComponent = false,
    setConfiguredCollections,
    onFormValidityChange,
    getOnMount,
    onSelectedChange,
  } = props;

  const isDebugOrLocal = useIsDebugOrLocal();
  const [i18n] = useI18n();

  const {
    getLookupShopifyDetailedObjectsDetails,
    lookupShopifyDetailedObjectsIsLoading,
  } = useConfigureOffers();

  const [showGrayBox, setShowGrayBox] = useState<boolean>(false);
  const [selectedGrayBoxElements, setSelectedGrayBoxElements] =
    useState<ResourceSelectionProps[]>(selectList);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const extraValidityCondition = useMemo(
    () => (isFBTtype ? isLimitReached : !selectedGrayBoxElements?.length),
    [isFBTtype, selectedGrayBoxElements, isLimitReached]
  );

  const formIsInvalid = useMemo(
    () => !!extraValidityCondition && !isLoading && !isOrderBumpType,
    [extraValidityCondition, isLoading, isOrderBumpType]
  );

  const currentResourceType = useMemo(
    () =>
      isOrderBumpType
        ? resourceType === GrayBoxResourceTypeEnum.Product && !!productVariantId
          ? ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
          : resourceType.toUpperCase()
        : resourceType === GrayBoxResourceTypeEnum.Product && !!showVariants
        ? ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
        : resourceType.toUpperCase(),
    [
      resourceType,
      showVariants,
      productVariantId,
      isFreeGiftSearchField,
      isOrderBumpType,
    ]
  );

  const helpTextLabel: string = useMemo(() => {
    switch (resourceType) {
      case GrayBoxResourceTypeEnum.Collection:
        return isFreeGiftSearchField
          ? 'FreeGiftSearchFieldHelpText'
          : 'SearchFieldHelpText';
      case GrayBoxResourceTypeEnum.Product:
        return isFreeGiftSearchField
          ? 'FreeGiftSearchFieldHelpText2'
          : 'SearchFieldHelpText2';
      default:
        return 'SearchFieldHelpText';
    }
  }, [resourceType, isFreeGiftSearchField]);

  const selectedVariants: Resource[] = useMemo(
    () =>
      selectedGrayBoxElements?.map((el: any) => ({
        id: el.id,
        variants:
          (el as Product).variants?.map((variant: Partial<ProductVariant>) => ({
            id: variant.id || '',
          })) || [],
      })),
    [selectedGrayBoxElements]
  );

  const toggleShowGrayBoxState = useCallback(
    (breakCondition?: boolean) => {
      if (!selectedGrayBoxElements?.length || breakCondition) {
        setShowGrayBox((prevState: boolean) => !prevState);
      }
    },
    [selectedGrayBoxElements, setShowGrayBox]
  );

  const getResourceType = useCallback(
    (grayBoxType: GrayBoxResourceType): ResourcePickerResourceType => {
      switch (grayBoxType) {
        case GrayBoxResourceTypeEnum.Product:
          return 'product';
        case GrayBoxResourceTypeEnum.Collection:
          return 'collection';
        default:
          return 'variant';
      }
    },
    []
  );

  const onSelection = useCallback(
    (data: SelectSectionProps) => {
      if (data.selection.length) {
        setShowGrayBox(false);
        onSelectedChange(data.selection);
        getLookupShopifyDetailedObjectsDetails(
          {
            type: currentResourceType as ShopifyObjectTypeDto,
            objects: formateList(
              data.selection,
              currentResourceType === ShopifyObjectTypeDtoEnum.PRODUCT_VARIANT
                ? OfferTargetTypeDtoEnum.VARIANTS
                : undefined
            ) as unknown as ShopifyObjectDto[],
          },
          setIsLoading
        ).then((res) =>
          setSelectedGrayBoxElements(res as ResourceSelectionProps[])
        );
      } else {
        setShowGrayBox(false);
        setSelectedGrayBoxElements([]);
      }
    },
    [onSelectedChange]
  );

  useEffect(() => {
    if (selectList?.length) {
      setIsLoading(true);
      setShowGrayBox(false);
      getLookupShopifyDetailedObjectsDetails(
        {
          type: currentResourceType as ShopifyObjectTypeDto,
          objects: selectList as unknown as ShopifyObjectDto[],
        },
        setIsLoading
      ).then((res) => {
        getOnMount?.(res as ResourceSelectionProps[]);
        setSelectedGrayBoxElements(res as ResourceSelectionProps[]);
      });
    } else {
      setShowGrayBox(false);
    }

    return () => {
      setShowGrayBox(false);
      setSelectedGrayBoxElements([]);
      setIsLoading(false);
    };
  }, [
    currentResourceType,
    productVariantId,
    getLookupShopifyDetailedObjectsDetails,
    getOnMount,
  ]);

  useEffect(() => {
    if (setConfiguredCollections) {
      setConfiguredCollections(
        headerTitle(selectedGrayBoxElements, currentResourceType, i18n)
      );
    }
  }, [
    selectedGrayBoxElements,
    selectedGrayBoxElements.length,
    showGrayBox,
    i18n,
  ]);

  useEffect(() => {
    if (hideComponent && setConfiguredCollections) {
      setConfiguredCollections(
        headerTitle(selectedGrayBoxElements, currentResourceType, i18n)
      );
    }
  }, [hideComponent, selectedGrayBoxElements, currentResourceType]);

  useEffect(() => {
    onFormValidityChange?.(formIsInvalid);
    return () => {
      // change in future
      onFormValidityChange?.(null);
    };
  }, [formIsInvalid, onFormValidityChange]);

  return (
    <>
      {!hideComponent && (
        <>
          <BlockStack gap='200'>
            <SearchFieldPlaceholder
              currentResourceType={currentResourceType}
              resourceType={resourceType}
              selectedGrayBoxElements={selectedGrayBoxElements}
              searchFieldType={searchFieldType}
              isOrderBumpType={isOrderBumpType}
              showVariants={showVariants}
              resourceIsLoading={lookupShopifyDetailedObjectsIsLoading}
              toggleShowGrayBoxState={toggleShowGrayBoxState}
            />
            {isCrossSell && !formIsInvalid && !isDiscountLinkSearchField && (
              <Banner>{i18n.translate(helpTextLabel)}</Banner>
            )}
          </BlockStack>
          {showGrayBox && !preventFromOpening && !isDebugOrLocal && (
            <ResourcePickerAdapter
              type={getResourceType(resourceType)}
              onCancel={() => setShowGrayBox(false)}
              onSelection={onSelection}
              selectionIds={selectedVariants}
              showVariants={!!(showVariants && !isOrderBumpType)}
              multiple={
                (resourceType === GrayBoxResourceTypeEnum.Product &&
                  isOrderBumpType) ||
                ((resourceType === GrayBoxResourceTypeEnum.Collection ||
                  resourceType === GrayBoxResourceTypeEnum.Product) &&
                  isDiscountLinkSearchField)
                  ? false
                  : true
              }
            />
          )}
          {showGrayBox && !preventFromOpening && isDebugOrLocal && (
            <ResourcePickerCustom
              open={showGrayBox && !preventFromOpening}
              type={getResourceType(resourceType)}
              onCancel={() => setShowGrayBox(false)}
              onSelection={onSelection}
              selectionIds={selectedVariants}
              showVariants={!!(showVariants && !isOrderBumpType)}
              multiple={
                (resourceType === GrayBoxResourceTypeEnum.Product &&
                  isOrderBumpType) ||
                ((resourceType === GrayBoxResourceTypeEnum.Collection ||
                  resourceType === GrayBoxResourceTypeEnum.Product) &&
                  isDiscountLinkSearchField)
                  ? false
                  : true
              }
            />
          )}
        </>
      )}
    </>
  );
};
//===Code that maybe will be needed in future===
// const listForPreview = useMemo(
//   () =>
//     isFBTtype
//       ? (mergedSelectedGrayBoxElements as ResourceSelectionProps[])
//       : selectedGrayBoxElements,
//   [isFBTtype, mergedSelectedGrayBoxElements, selectedGrayBoxElements]
// );
// const removeSelectedTag = useCallback(
//   (el: ResourceSelectionProps) => {
//     const list = selectedGrayBoxElements?.filter(
//       (element: ResourceSelectionProps) => element.id !== el.id
//     );
//     setSelectedGrayBoxElements(list);
//     onSelectedChange(list);
//   },
//   [setSelectedGrayBoxElements, selectedGrayBoxElements, onSelectedChange]
// );
