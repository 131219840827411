import {
  OfferCombinationTypeDto,
  OfferShopifyDiscountCodeDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { OfferParentComponentProps } from '../../../types/OfferTypesFromCatalogTypes';
import { useOfferFormValidity } from 'features/promotions/hooks/useOfferFormValidity';
import { isEqual } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShopifyDiscountCodeOfferTabsEnum } from '../../../enums/OfferTypeTabs';
import { ShopifyDiscountCode } from '../../ShopifyDiscountCode/ShopifyDiscountCode';
import { BlockStack, Page } from '@shopify/polaris';
import { OfferTemplate } from '../../OfferTemplate/OfferTemplate';
import { LearnMoreModal } from '../../LearnMoreModal/LearnMoreModal';
import { useI18n } from '@shopify/react-i18n';
import { useIsDebugOrLocal } from 'core/hooks';

export const ShopifyDiscountCodeOffer: React.FC<OfferParentComponentProps> = (
  props
) => {
  const { offerTemplate, offerType, handleOfferDataUpdate } = props;
  const [offerData, setOfferData] =
    useState<OfferShopifyDiscountCodeDto | null>(
      offerTemplate?.template as OfferShopifyDiscountCodeDto
    );

  const { validityChangeHandler } = useOfferFormValidity();

  const [i18n] = useI18n();

  const [showHowModal, setShowHowModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const isDebugOrLocal = useIsDebugOrLocal();

  const toggleShowHowModal = useCallback(() => {
    setShowHowModal((prevState: boolean) => !prevState);
  }, [setShowHowModal]);

  useEffect(() => {
    if (
      offerTemplate?.template &&
      offerData &&
      !isEqual(offerData, offerTemplate?.template)
    ) {
      handleOfferDataUpdate(offerData);
    }
  }, [offerData, offerTemplate]);

  const updateDiscountType = useCallback(
    (type: OfferCombinationTypeDto) => {
      setOfferData((prevState: OfferShopifyDiscountCodeDto | null) => ({
        ...prevState,
        combinationType: type,
      }));
    },
    [setOfferData]
  );

  const onShopifyCodeUpdate = useCallback(
    (code: string | null) => {
      if (!code) {
        return;
      }

      switch (offerType) {
        case OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP:
          setOfferData((prevState: OfferShopifyDiscountCodeDto | null) => ({
            ...prevState,
            app: code,
          }));
          break;
        case OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP:
          setOfferData((prevState: OfferShopifyDiscountCodeDto | null) => ({
            ...prevState,
            group: code,
          }));
          break;
        default:
          setOfferData((prevState: OfferShopifyDiscountCodeDto | null) => ({
            ...prevState,
            code,
          }));
          break;
      }
    },
    [offerType, setOfferData]
  );

  return (
    <Page
      title={i18n.translate(`ConfigureOffer`)}
      backAction={{
        content: i18n.translate('CreateOffer'),
        onAction: async () => {
          !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
          navigate('../..', { relative: 'path' });
        },
      }}
    >
      <BlockStack gap='600'>
        <OfferTemplate
          type={offerType}
          toggleShowHowModal={toggleShowHowModal}
          discountType={
            (offerTemplate?.template as OfferShopifyDiscountCodeDto)
              .combinationType
          }
        />
        <ShopifyDiscountCode
          offerType={offerType}
          onSelectInputValueChange={onShopifyCodeUpdate}
          updateDiscountType={updateDiscountType}
          onValidityStateChange={validityChangeHandler(
            ShopifyDiscountCodeOfferTabsEnum.ShopifyDiscountCode
          )}
          draftData={offerTemplate?.template}
        />
        {showHowModal && (
          <LearnMoreModal
            onModalClose={toggleShowHowModal}
            // url='https://youtu.be/9htdypfdlBM'
            title={i18n.translate('Tutorial')}
          />
        )}
      </BlockStack>
    </Page>
  );
};
