import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import './BrandColors.scss';
import { BlockStack, Card, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { StyleSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { isEqual } from 'lodash';
import { setIsUnsavedChanges } from 'core/store/settingsSlice';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from '../../../../../hooks/useConfigureSettings';
import { useDispatch } from 'react-redux';
import { Loader } from 'core/components';
import useFetchPresetList from 'features/settings/hooks/useFetchPresetList/useFetchPresetList';
import resolveEnvVar from 'env-var-resolver';
import { ConfigureBrandColors } from './components/ConfigureBrandColors/ConfigureBrandColors';
import { SettingsSavebarRef } from 'features/settings/Settings';

const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME') || '';

export const BrandColors = forwardRef<SettingsSavebarRef>((_, ref) => {
  const [i18n] = useI18n();
  const dispatch = useDispatch();

  const { fetchBrandColors, brandColorsIsFetching } = useFetchPresetList();

  const { brandColorsDataChange, brandColorsDataIsLoading } =
    useConfigureSettings(SettingsFetchTypeEnum.BRAND_COLORS);

  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';

  const [brandColorsState, setBrandColorsState] = useState<StyleSettingsDto>(
    {}
  );
  const [savedData, setSavedData] = useState<StyleSettingsDto>({});

  const handleFetchBrandColors = useCallback(async () => {
    const onSuccess = (data: StyleSettingsDto) => {
      setBrandColorsState(data);
      setSavedData(data);
    };
    await fetchBrandColors(
      {
        'X-LimoniApps-AppName': APP_NAME,
        'X-LimoniApps-AppSecret': APP_PASSWORD,
      },
      onSuccess
    );
  }, [APP_PASSWORD, APP_NAME]);

  const handleSaveChanges = useCallback(() => {
    const onSuccess = () => setSavedData(brandColorsState);
    return brandColorsDataChange(brandColorsState, onSuccess);
  }, [brandColorsState]);

  useImperativeHandle(ref, () => ({
    discardChanges: () => {
      setBrandColorsState(savedData);
      handleFetchBrandColors();
    },
    saveChanges: handleSaveChanges,
  }));

  useEffect(() => {
    dispatch(setIsUnsavedChanges(!isEqual(savedData, brandColorsState)));
  }, [brandColorsState, savedData]);

  useEffect(() => {
    handleFetchBrandColors();
    return () => {
      dispatch(setIsUnsavedChanges(false));
    };
  }, []);

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('BrandColors')}
          </Text>
          <Text as='p' tone='subdued'>
            {i18n.translate('BrandColorsDescription')}
          </Text>
        </BlockStack>
        {!brandColorsIsFetching && !brandColorsDataIsLoading ? (
          <ConfigureBrandColors
            brandColors={brandColorsState.brandColors || []}
            setBrandColors={(data) =>
              setBrandColorsState({ brandColors: data })
            }
            bannerText={i18n.translate('BannerInfo')}
          />
        ) : (
          <BlockStack align='center' inlineAlign='center'>
            <Loader />
          </BlockStack>
        )}
      </BlockStack>
    </Card>
  );
});
BrandColors.displayName = 'BrandColors';
