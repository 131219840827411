import React, { useEffect, useMemo, useState } from 'react';
import { BlockStack } from '@shopify/polaris';
import './ShippingDeliveryMethods.scss';
import { NumberFormatValues } from 'react-number-format';
import { OfferShippingRateTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  OfferShippingDiscountSpecificationDto,
  OfferShippingRateTypeDto,
  OptionDtoShippingCountryDto,
  ShippingCountryDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { getInitialPriceTextfieldValue } from '../ShippingRate/utils/utils';
import useValidityUpdater, {
  ObjValidity,
} from 'features/promotions/hooks/useValidityUpdater';
import { ShippingCountries } from './components/ShippingCountries/ShippingCountries';
import { ShippingAppliesTo } from './components/ShippingAppliesTo/ShippingAppliesTo';
import { usePrevious } from 'core/hooks/usePrevious';
import { isEqual } from 'lodash';
import { parseCurrencyString } from 'core/utils';

type ShippingDeliveryMethodsProps = {
  specification?: OfferShippingDiscountSpecificationDto;
  // OfferFreeShippingDeliveryMethodsSpecificationDto;
  onValidityStateChange(invalid: boolean): void;
  onShippingCountriesDataUpdate: (data: OptionDtoShippingCountryDto) => void;
  onApplyToDataUpdate: (data: OfferShippingDiscountSpecificationDto) => void;
  // OfferFreeShippingDeliveryMethodsSpecificationDto
};

export const ShippingDeliveryMethods: React.FC<ShippingDeliveryMethodsProps> = (
  props
) => {
  const {
    onValidityStateChange,
    specification,
    onApplyToDataUpdate,
    onShippingCountriesDataUpdate,
  } = props;
  const [currentObjectsValidities, updateValidity] = useValidityUpdater(1);

  const [deliveryMethodsOption, setDeliveryMethodsOption] =
    useState<OfferShippingRateTypeDto>(
      specification?.shippingRateType || OfferShippingRateTypeDtoEnum.ALL
    );

  const [thresholdTextFieldValue, setThresholdTextFieldValue] =
    useState<NumberFormatValues>(
      getInitialPriceTextfieldValue(
        specification?.shippingRateThreshold as number
      )
    );

  const [selectedShippingRates, setSelectedShippingRates] = useState<string[]>(
    specification?.selectedShippingRates || []
  );

  const [selectedCountries, setSelectedCountries] = useState<
    ShippingCountryDto[]
  >(specification?.shippingCountries?.value || []);

  const prevSelectedCountries = usePrevious(selectedCountries || []);

  const isAllInputsValid = useMemo(
    () =>
      currentObjectsValidities?.every(
        (inputValidity: ObjValidity) => inputValidity.isValid
      ),
    [currentObjectsValidities]
  );

  useEffect(() => {
    if (
      prevSelectedCountries &&
      !isEqual(prevSelectedCountries, selectedCountries)
    ) {
      onShippingCountriesDataUpdate({
        enabled: !!selectedCountries.length,
        value: selectedCountries,
      });
    }
  }, [selectedCountries, prevSelectedCountries]);

  useEffect(() => {
    if (!deliveryMethodsOption) {
      return;
    }
    const getShippingValueRequestObject =
      (): OfferShippingDiscountSpecificationDto => {
        switch (deliveryMethodsOption) {
          case OfferShippingRateTypeDtoEnum.ALL:
            return {
              ...specification,
              shippingRateType: deliveryMethodsOption,
            };
          case OfferShippingRateTypeDtoEnum.ALL_THRESHOLD:
            return {
              ...specification,
              shippingRateType: deliveryMethodsOption,
              shippingRateThreshold: parseCurrencyString(
                thresholdTextFieldValue.floatValue
              ),
            };
          default:
            return {
              ...specification,
              shippingRateType: deliveryMethodsOption,
              selectedShippingRates,
            };
        }
      };
    onApplyToDataUpdate(getShippingValueRequestObject());
  }, [deliveryMethodsOption, thresholdTextFieldValue, selectedShippingRates]);

  useEffect(() => {
    if (onValidityStateChange) {
      onValidityStateChange(isAllInputsValid);
    }
  }, [isAllInputsValid]);

  return (
    <BlockStack gap='600'>
      <ShippingAppliesTo
        updateValidity={updateValidity}
        thresholdTextFieldValue={thresholdTextFieldValue}
        setThresholdTextFieldValue={setThresholdTextFieldValue}
        deliveryMethodsOption={deliveryMethodsOption}
        setDeliveryMethodsOption={setDeliveryMethodsOption}
        setSelectedShippingRates={setSelectedShippingRates}
        selectedShippingRates={selectedShippingRates}
      />
      <ShippingCountries
        selectedCountries={selectedCountries}
        setSelectedCountries={setSelectedCountries}
      />
    </BlockStack>
  );
};
