import React from 'react';
import { Banner, BlockStack, Button, Text } from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { PlanThresholdDetailsDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { PlanLevelDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import moment from 'moment';

type DowngradePlanBannerProps = {
  currentPlan?: PlanThresholdDetailsDto;
  downgradedPlan?: PlanThresholdDetailsDto;
  nextBillingCycleDate?: number | null;
  togglePlanModal: (level?: PlanLevelDtoEnum) => void;
};
export const DowngradePlanBanner: React.FC<DowngradePlanBannerProps> = ({
  currentPlan,
  downgradedPlan,
  nextBillingCycleDate,
  togglePlanModal,
}) => {
  const [i18n] = useI18n();
  return (
    <Banner tone='warning' title={i18n.translate('PromotionsWillDeactivated')}>
      <BlockStack gap='200' inlineAlign='start'>
        <Text as='p'>
          {i18n.translate('DowngradingInfo', {
            downgraded: (
              <Text fontWeight='medium' as='span'>
                {i18n.translate('DowngradedPlan', {
                  plan: downgradedPlan?.name,
                })}
              </Text>
            ),
            nextCycleDate: (
              <Text fontWeight='medium' as='span'>
                {i18n.translate('NextBillingCycle', {
                  date: moment(nextBillingCycleDate).fromNow(),
                })}
              </Text>
            ),
            date: moment(nextBillingCycleDate).format(
              'MMMM D, YYYY [at] h:mm A'
            ),
            publishedPromotions: downgradedPlan?.maxPublishedPromotions,
            unpublished: (
              <Text fontWeight='medium' as='span'>
                {i18n.translate('Unpublished')}
              </Text>
            ),
            upgardePlan: currentPlan?.name,
          })}
        </Text>
        <Button
          onClick={() =>
            togglePlanModal(currentPlan?.level as PlanLevelDtoEnum)
          }
        >
          {i18n.translate('UpgradeTo', {
            plan: currentPlan?.name || '',
          })}
        </Button>
      </BlockStack>
    </Banner>
  );
};
