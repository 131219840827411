import React, { useCallback, useMemo } from 'react';
import {
  Checkbox,
  RadioButton,
  Text,
  BlockStack,
  Box,
  Banner,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import {
  OfferCartRuleDto,
  OfferPrerequisiteEntitledAppliesToDto,
} from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferCartContentRequirementProductMatchTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import './GrayBoxBogoOptions.scss';
import { OfferTypeSpecialCases } from 'core/enums/GrayBoxEnum';

export type GrayBoxBogoOptionsProps = {
  data: OfferCartRuleDto;
  isCrossSell?: boolean;
  variant: OfferTypeSpecialCases | null;
  updateValue?: (data: OfferCartRuleDto) => void;
  productsUpdateValue?: (data: OfferPrerequisiteEntitledAppliesToDto) => void;
};

export const GrayBoxBogoOptions: React.FC<GrayBoxBogoOptionsProps> = (
  props
) => {
  const {
    data,
    isCrossSell = false,
    variant,
    updateValue,
    productsUpdateValue,
  } = props;

  const [i18n] = useI18n();

  const cartRule = useMemo(
    () =>
      data.appliesTo?.productMatchType?.value ||
      OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT,
    [data.appliesTo?.productMatchType?.value]
  );

  const handleCartRuleChange = useCallback(
    (
      _checked: boolean,
      newValue: OfferCartContentRequirementProductMatchTypeDtoEnum
    ) => {
      productsUpdateValue?.({
        ...data.appliesTo,
        productMatchType: {
          ...data.appliesTo?.productMatchType,
          value: newValue,
        },
      });
      updateValue?.({
        ...data,
        appliesTo: {
          ...data.appliesTo,
          productMatchType: {
            ...data.appliesTo?.productMatchType,
            value: newValue,
          },
        },
      });
    },
    [data, updateValue, productsUpdateValue]
  );

  const handleRequireProductsMatchChange = useCallback(
    (val: boolean) => {
      updateValue?.({
        ...data,
        appliesTo: {
          ...data.appliesTo,
          productMatchType: {
            ...data.appliesTo?.productMatchType,
            enabled: val,
          },
        },
      });
      productsUpdateValue?.({
        ...data.appliesTo,
        productMatchType: {
          ...data.appliesTo?.productMatchType,
          enabled: val,
        },
      });
    },
    [data, updateValue, productsUpdateValue]
  );

  return !isCrossSell ? (
    <BlockStack>
      {variant === OfferTypeSpecialCases.BogoMixMatch ? (
        <Banner>{i18n.translate('GrayBoxBogoOptions.Banner')}</Banner>
      ) : variant === OfferTypeSpecialCases.BogoStrictMatch ? (
        <Text as='p'>
          {i18n.translate('GrayBoxBogoOptions.RequireProducts')}
        </Text>
      ) : (
        <Checkbox
          label={i18n.translate('GrayBoxBogoOptions.RequireProducts')}
          checked={data.appliesTo?.productMatchType?.enabled}
          onChange={handleRequireProductsMatchChange}
        />
      )}
      {variant !== OfferTypeSpecialCases.BogoMixMatch &&
        data.appliesTo?.productMatchType?.enabled && (
          <Box
            paddingInlineStart={
              variant !== OfferTypeSpecialCases.BogoStrictMatch ? '600' : '0'
            }
            paddingBlockStart={
              variant === OfferTypeSpecialCases.BogoStrictMatch ? '100' : '0'
            }
          >
            <BlockStack gap='025'>
              <RadioButton
                label={i18n.translate('GrayBoxBogoOptions.SameProduct')}
                checked={
                  cartRule ===
                  OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT
                }
                id={
                  OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_PRODUCT
                }
                onChange={handleCartRuleChange}
              />
              <RadioButton
                label={i18n.translate('GrayBoxBogoOptions.SameProductVariant')}
                id={
                  OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_VARIANT
                }
                checked={
                  cartRule ===
                  OfferCartContentRequirementProductMatchTypeDtoEnum.SAME_VARIANT
                }
                onChange={handleCartRuleChange}
              />
            </BlockStack>
          </Box>
        )}
    </BlockStack>
  ) : null;
};
