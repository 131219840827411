import React from 'react';
import { Box } from '@shopify/polaris';

type CustomSekeletonBoxProps = {
  width: string;
  height: string;
};

export const CustomSekeletonBox: React.FC<CustomSekeletonBoxProps> = ({
  width,
  height,
}) => {
  return (
    <Box
      width={width}
      minHeight={height}
      borderRadius='150'
      background='bg-fill-tertiary'
    />
  );
};
