import React, { useEffect, useMemo } from 'react';
import './ThreeCardsLayout.scss';
import { useI18n } from '@shopify/react-i18n';
import { XIcon, ImagesIcon } from '@shopify/polaris-icons';
import { AppVersionDto } from 'core/api/adminSettings/adminSettingsApi';
import { InlineGrid, MediaCard, Image, Icon } from '@shopify/polaris';

type ThreeCardsLayoutProps = {
  appVersion?: AppVersionDto;
  isComponentDismissed: (componentId: string) => boolean;
  onDismiss: (componentId: string, appVersion?: string) => void;
  handleRemoveDismissedComponent: (componentId: string) => void;
};

export const ThreeCardsLayout: React.FC<ThreeCardsLayoutProps> = (props) => {
  const {
    appVersion,
    isComponentDismissed,
    onDismiss,
    handleRemoveDismissedComponent,
  } = props;

  const [i18n] = useI18n();

  const appVersionFromLocalStorage = localStorage.getItem('appVersion');

  const cards = useMemo(
    () => [
      {
        component: 'NinjaCart',
        title: i18n.translate('NinjaCartCard.title'),
        description: i18n.translate('NinjaCartCard.description'),
        btn: i18n.translate('NinjaCartCard.btn'),
        url: '',
        imageUrl: '',
      },
      {
        component: 'GetTipsAndTricks',
        title: i18n.translate('GetTipsAndTricksCard.title'),
        description: i18n.translate('GetTipsAndTricksCard.description'),
        btn: i18n.translate('GetTipsAndTricksCard.btn'),
        url: '',
        imageUrl: '',
      },
      {
        component: 'appVersionCard',
        title: i18n.translate('LatestUpdates.title'),
        description: i18n.translate('LatestUpdates.description', {
          date: appVersion?.name,
        }),
        btn: i18n.translate('LatestUpdates.btn'),
        url: appVersion?.releaseNotesUrl,
        imageUrl: appVersion?.imageUrl,
      },
    ],
    [i18n]
  );

  useEffect(() => {
    if (
      appVersion?.number &&
      appVersionFromLocalStorage &&
      appVersionFromLocalStorage! == appVersion?.number
    ) {
      handleRemoveDismissedComponent('appVersionCard');
    }
  }, [appVersionFromLocalStorage, appVersion?.number]);

  return (
    <div className='threeCardsLayout'>
      <InlineGrid gap='400' columns={3}>
        {cards.map(
          (card) =>
            !isComponentDismissed(card.component) && (
              <div
                key={card.component}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <MediaCard
                  portrait
                  title={card.title}
                  primaryAction={{
                    content: card.btn,
                    url: card.url,
                    external: true,
                  }}
                  description={card.description as string}
                  popoverActions={[
                    {
                      content: i18n.translate('dismiss'),
                      onAction: () =>
                        onDismiss(
                          card.component,
                          card.component === 'appVersionCard'
                            ? appVersion?.number
                            : undefined
                        ),
                      prefix: (
                        <div
                          style={{
                            height: '1rem',
                            width: '1rem',
                            paddingTop: '.05rem',
                          }}
                        >
                          <Icon tone='subdued' source={XIcon} />
                        </div>
                      ),
                    },
                  ]}
                >
                  {card.imageUrl ? (
                    <Image
                      className='imageCard'
                      source={card.imageUrl || ''}
                      alt=''
                    />
                  ) : (
                    <div className='placeholder'>
                      <Icon source={ImagesIcon} tone='subdued' />
                    </div>
                  )}
                </MediaCard>
              </div>
            )
        )}
      </InlineGrid>
    </div>
  );
};
