import resolveEnvVar from 'env-var-resolver';
import { useGetPromotionsV6PromotionImportByIdQuery } from 'core/api/adminPromotions/adminPromotionsApi';

export const usePromotionListImportTrack = (trackingId?: string) => {
  const APP_PASSWORD = localStorage.getItem('passwordDevLogin') || 'limonidev';
  const APP_NAME = resolveEnvVar('REACT_APP_APP_NAME');

  const {
    data: promotionListImportTrackData,
    error: promotionListImportTrackError,
    refetch: refetchPromotionListImportTrack,
  } = useGetPromotionsV6PromotionImportByIdQuery(
    {
      'X-LimoniApps-AppName': APP_NAME,
      'X-LimoniApps-AppSecret': APP_PASSWORD,
      id: trackingId || '',
    },
    {
      skip: !trackingId,
      refetchOnMountOrArgChange: true,
    }
  );

  return {
    promotionListImportTrackData,
    promotionListImportTrackError,
    refetchPromotionListImportTrack,
  };
};
