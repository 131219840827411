import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Banner,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Icon,
  InlineStack,
  Link,
  Modal,
  Text,
} from '@shopify/polaris';
import {
  InfoIcon,
  ArrowRightIcon,
  ChevronRightIcon,
} from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  PlanDtoRead,
  PlanLevelDto,
  PlanThresholdDetailsDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  PlanFeatureDtoEnum,
  PlanIntervalDtoEnum,
  PlanLevelDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import { Loader, Tooltip } from 'core/components';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { formatNumber } from 'core/utils';
import { PlanComparisonCard } from './components/PlanComparisonCard/PlanComparisonCard';
import { FeatureListDescription } from './components/FeatureListDescription/FeatureListDescription';
import './ChangePlanModal.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import {
  SettingsModalPathsEnums,
  SettingsModalSubPathsEnums,
} from 'pages/enums/PagesEnums';
import { useNumericFormatter } from 'core/hooks/useNumericFormatter';
import { ChartuUnitOfMeasurementEnum } from 'pages';

export enum ChangePlanModalTypeEnum {
  CHANGE_PLAN = 'CHANGE_PLAN',
  GET_FEATURE = 'GET_FEATURE',
  INCREASE_THRESHOLD = 'INCREASE_THRESHOLD',
  CHANGE_BILLING_CYCLE = 'CHANGE_BILLING_CYCLE',
}
export type ChangePlanModalProps = {
  isOpen: boolean;
  modalType: ChangePlanModalTypeEnum;
  targetPlanLevel: PlanLevelDto;
  threshold?: number;
  interval?: PlanIntervalDtoEnum;
  nextCycle?: number;
  highlightedFeatures?: PlanFeatureDtoEnum[];
  isMaxModal: boolean;
  onClose: () => void;
};
export const ChangePlanModal: React.FC<ChangePlanModalProps> = ({
  isOpen,
  modalType,
  targetPlanLevel,
  interval: selectedInterval,
  threshold,
  nextCycle,
  highlightedFeatures,
  isMaxModal,
  onClose,
}) => {
  const [i18n] = useI18n();
  const [, setSearchParams] = useSearchParams();
  const [targetPlan, setTargetPlan] = useState<PlanDtoRead>();
  const {
    planDetailsData,
    selectSubscription,
    selectSubscriptionIsLoading,
    selectSubscriptionData,
  } = useConfigureSettings(SettingsFetchTypeEnum.PLAN_DETAILS);
  const { formatValue } = useNumericFormatter();

  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [termsAndConds, setTermsAndConds] = useState<boolean>(false);
  const navigate = useNavigate();

  const currentPlan = useMemo(
    () => planDetailsData?.currentPlan,
    [planDetailsData?.currentPlan]
  );
  const interval = useMemo(
    () =>
      selectedInterval
        ? selectedInterval
        : currentPlan?.isCurrentAnnually
        ? PlanIntervalDtoEnum.ANNUAL
        : PlanIntervalDtoEnum.MONTHLY,
    [selectedInterval, currentPlan?.isCurrentAnnually]
  );

  const isAnnual = interval === PlanIntervalDtoEnum.ANNUAL;

  const targetLevelPlans = useMemo(
    () =>
      planDetailsData?.plans?.filter(
        (plan) => plan.level?.slice(0, -2) === targetPlanLevel?.slice(0, -2)
      ),
    [planDetailsData?.plans]
  );

  const isDowngrading = useMemo(
    () =>
      (currentPlan?.threshold?.perMonth || 0) >
      (targetPlan?.threshold?.perMonth || 0),
    [targetPlan?.threshold, currentPlan?.threshold?.perMonth]
  );

  const isFreeTrial = useMemo(
    () => !currentPlan && !!planDetailsData?.trialDays,
    [currentPlan, planDetailsData?.trialDays]
  );

  const freeTrialValid = useMemo(
    () => (isFreeTrial ? privacyPolicy && termsAndConds : true),
    [isFreeTrial, privacyPolicy, termsAndConds]
  );

  const isDifferentThreshold = useMemo(
    () =>
      !!currentPlan && currentPlan?.thresholdType !== targetPlan?.thresholdType,
    [currentPlan, targetPlan?.threshold]
  );

  const featureList = useMemo(() => {
    const targetFeatures = targetPlan?.features?.filter(
      (item) => !currentPlan?.features?.includes(item)
    );
    const currentFeatures = currentPlan?.features?.filter(
      (item) => !targetPlan?.features?.includes(item)
    );
    return (
      targetFeatures?.length ? targetFeatures : currentFeatures
    ) as PlanFeatureDtoEnum[];
  }, [targetPlan?.features, currentPlan?.features]);

  const showTargetLevels = useMemo(
    () =>
      targetPlan?.level !== PlanLevelDtoEnum.ENTERPRISE &&
      modalType !== ChangePlanModalTypeEnum.CHANGE_BILLING_CYCLE &&
      modalType !== ChangePlanModalTypeEnum.GET_FEATURE,
    [targetPlan?.level, modalType]
  );

  const showFeatureList = useMemo(
    () => !isFreeTrial && !isDifferentThreshold && !!featureList?.length,
    [isFreeTrial, isDifferentThreshold, featureList]
  );

  const sendMessageToMainApp = useCallback((message: any) => {
    window.opener?.postMessage(message, location.origin);
  }, []);

  const disableThersholds = useCallback(
    (thresholds?: PlanThresholdDetailsDto) => {
      const currentThreshold =
        (isAnnual ? thresholds?.perYear : thresholds?.perMonth) || 0;
      return threshold ? currentThreshold < threshold : false;
    },
    [threshold, isAnnual]
  );

  const navigateToPlansPage = useCallback(() => {
    if (isMaxModal) {
      sendMessageToMainApp({
        type: 'NAVIGATE_TO_PICK_YOUR_PLAN',
      });
    } else {
      setSearchParams((params) => {
        params.set('path', SettingsModalPathsEnums.Plan);
        params.set('subPath', SettingsModalSubPathsEnums.PickYourPlan);
        return params;
      });
    }
    onClose();
  }, [isMaxModal]);

  const confirmPlanSelection = useCallback(() => {
    selectSubscription({
      interval: interval,
      level: targetPlan?.level,
      thresholdType: targetPlan?.thresholdType,
    });
  }, [interval, targetPlan?.level, targetPlan?.thresholdType]);

  useEffect(() => {
    !targetPlan &&
      setTargetPlan(
        planDetailsData?.plans?.find((plan) => plan.level === targetPlanLevel)
      );
  }, [targetPlanLevel, planDetailsData]);

  useEffect(() => {
    if (selectSubscriptionData?.redirectUri) {
      isMaxModal
        ? sendMessageToMainApp({
            type: 'NAVIGATE_TO_PLAN_PAYMENT',
            redirectUri: selectSubscriptionData?.redirectUri,
          })
        : navigate(
            '/shopifyRedirect?redirectUri=' +
              selectSubscriptionData?.redirectUri
          );
    }
  }, [selectSubscriptionData]);

  return (
    <Modal
      title={i18n.translate(isFreeTrial ? 'SelectPlan' : `${modalType}_TITLE`)}
      open={isOpen}
      onClose={onClose}
      primaryAction={{
        content: i18n.translate(
          isFreeTrial ? 'StartFreeTrial' : `${modalType}_BUTTON`
        ),
        onAction: confirmPlanSelection,
        disabled: !freeTrialValid,
        loading: selectSubscriptionIsLoading,
      }}
      secondaryActions={[
        { content: i18n.translate('Cancel'), onAction: onClose },
      ]}
    >
      <div className='ChangePlanModal'>
        <Modal.Section>
          {planDetailsData ? (
            <BlockStack gap='600'>
              {isDowngrading && (
                <Banner tone='warning'>
                  {i18n.translate('DowngradingBanner', {
                    downgraded: (
                      <Text as='span' fontWeight='medium'>
                        {i18n.translate('Downgraded')}
                      </Text>
                    ),
                    nextCycle: (
                      <Text as='span' fontWeight='medium'>
                        {i18n.translate('NextCycle')}
                      </Text>
                    ),
                    unpublished: (
                      <Text as='span' fontWeight='medium'>
                        {i18n.translate('Unpublished')}
                      </Text>
                    ),
                    amount: targetPlan?.publishedPromotionsLimit,
                  })}
                </Banner>
              )}
              {nextCycle && (
                <Text as='p'>
                  {i18n.translate('SwitchToAnnual', {
                    save: (
                      <Text fontWeight='medium' as='span'>
                        {i18n.translate('SavePercentage', {
                          percentage: formatValue(
                            targetPlan?.pricing?.intervalPrices
                              ?.savingPercentage,
                            ChartuUnitOfMeasurementEnum.PERCENTAGE
                          ),
                        })}
                      </Text>
                    ),
                    nextCycle: (
                      <Text fontWeight='medium' as='span'>
                        {i18n.translate('NextBillingCycle')}
                      </Text>
                    ),
                    date: (
                      <Text fontWeight='medium' as='span'>
                        {moment(nextCycle).format('Do MMMM YYYY')}
                      </Text>
                    ),
                  })}
                </Text>
              )}
              {!!threshold && (
                <Text as='p'>
                  {i18n.translate('ThresholdAlmostReached', {
                    from: (
                      <Text as='span' fontWeight='medium'>
                        {threshold}
                      </Text>
                    ),
                    to: (
                      <Text as='span' fontWeight='medium'>
                        {isAnnual
                          ? currentPlan?.threshold?.perYear
                          : currentPlan?.threshold?.perMonth}
                      </Text>
                    ),
                    fee: (
                      <Text as='span' fontWeight='medium'>
                        $
                        {isAnnual
                          ? currentPlan?.pricing?.intervalPrices?.regular
                              ?.annualOverageUnitPrice
                          : currentPlan?.pricing?.intervalPrices?.regular
                              ?.monthlyOverageUnitPrice}
                      </Text>
                    ),
                  })}
                </Text>
              )}
              {showTargetLevels && (
                <BlockStack gap='100'>
                  <InlineStack gap='100' blockAlign='center'>
                    <Text as='p'>
                      {i18n.translate('DiscountedOrdersIncluded')}
                    </Text>
                    <Tooltip content='Description'>
                      <Icon tone='subdued' source={InfoIcon} />
                    </Tooltip>
                  </InlineStack>
                  <ButtonGroup fullWidth variant='segmented'>
                    {targetLevelPlans?.map((plan) => {
                      return (
                        <Button
                          key={plan.level}
                          disabled={disableThersholds(plan.threshold)}
                          pressed={plan.level === targetPlan?.level}
                          onClick={() => setTargetPlan(plan)}
                        >
                          {formatNumber(
                            i18n,
                            isAnnual
                              ? plan.threshold?.perYear
                              : plan.threshold?.perMonth
                          )?.toString()}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </BlockStack>
              )}

              <InlineStack wrap={false} align='space-between' gap='300'>
                <PlanComparisonCard
                  interval={interval}
                  planData={currentPlan || null}
                  comparedPlan={targetPlan || {}}
                  discount={planDetailsData?.discount}
                  isFreeTrial={isFreeTrial}
                  isDifferentThreshold={isDifferentThreshold}
                  modalType={modalType}
                  isSelected
                />
                <BlockStack>
                  <Icon tone='base' source={ArrowRightIcon} />
                </BlockStack>
                <PlanComparisonCard
                  interval={interval}
                  comparedPlan={currentPlan || {}}
                  planData={targetPlan || {}}
                  discount={planDetailsData?.discount}
                  isFreeTrial={isFreeTrial}
                  isDifferentThreshold={isDifferentThreshold}
                  modalType={modalType}
                  highlightedFeatures={highlightedFeatures}
                />
              </InlineStack>
              {showFeatureList && (
                <FeatureListDescription features={featureList} />
              )}
              {isFreeTrial && (
                <BlockStack gap='300'>
                  <Text fontWeight='semibold' as='p'>
                    {i18n.translate('PrivacyAndTerms')}
                  </Text>
                  <BlockStack gap='200'>
                    <Checkbox
                      checked={privacyPolicy}
                      label={i18n.translate('ReadPrivacy', {
                        privacyPolicy: (
                          <Link>{i18n.translate('PrivacyPolicy')}</Link>
                        ),
                      })}
                      onChange={() => setPrivacyPolicy(!privacyPolicy)}
                    />
                    <Checkbox
                      checked={termsAndConds}
                      label={i18n.translate('ReadTerms', {
                        termsAndConditions: (
                          <Link>{i18n.translate('TermsAndConditions')}</Link>
                        ),
                      })}
                      onChange={() => setTermsAndConds(!termsAndConds)}
                    />
                  </BlockStack>
                </BlockStack>
              )}
              {isDifferentThreshold && (
                <>
                  <Banner>{i18n.translate('AllFeaturesAreSame')}</Banner>
                  <div className='NavigationCard' onClick={navigateToPlansPage}>
                    <InlineStack align='space-between' blockAlign='center'>
                      <Text as='p'>{i18n.translate('ExploreOtherPlans')}</Text>
                      <Box>
                        <Icon source={ChevronRightIcon} />
                      </Box>
                    </InlineStack>
                  </div>
                </>
              )}
              {!!threshold && (
                <Text as='p'>
                  {i18n.translate('RequireMoreOrders', {
                    explore: (
                      <Link onClick={navigateToPlansPage}>
                        {i18n.translate('ExploreOtherPlans')}
                      </Link>
                    ),
                  })}
                </Text>
              )}
              {modalType === ChangePlanModalTypeEnum.GET_FEATURE && (
                <Link onClick={navigateToPlansPage}>
                  {i18n.translate('ExploreOtherPlans')}
                </Link>
              )}
            </BlockStack>
          ) : (
            <Loader fullWidth />
          )}
        </Modal.Section>
      </div>
    </Modal>
  );
};
