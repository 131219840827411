import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './RequestWidgetSupport.scss';
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  ChoiceList,
  Collapsible,
  InlineStack,
  Text,
  TextField,
} from '@shopify/polaris';
import { ChevronDownIcon, ChevronUpIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import {
  InstallationSupportRequestDto,
  WidgetTypeDto,
} from 'core/api/adminSettings/adminSettingsApi';
import {
  InstallationSupportRequestDemandDtoEnum as DemandDtoEnum,
  InstallationSupportRequestDemandDtoEnum,
  InstallationSupportRequestPriorityDtoEnum,
  WidgetInstallationModeDtoEnum,
  WidgetStatusDtoEnum,
  WidgetTypeDtoEnum,
} from 'core/api/adminSettings/adminSettingsEnums';
import {
  SettingsFetchTypeEnum,
  useConfigureSettings,
} from 'features/settings/hooks/useConfigureSettings';
import { isEmpty } from 'lodash';
import { ComboboxOption, Loader } from 'core/components';
import SelectOptions from 'core/components/SelectOptions/SelectOptions';

type RequestWidgetSupportProps = {
  widgetType?: WidgetTypeDtoEnum;
  skipUnnecessaryRequests?: boolean;
  setIsWidgetRequestSent?: (value: boolean) => void;
  onClose: () => void;
};

const RequestWidgetSupport: React.FC<RequestWidgetSupportProps> = ({
  widgetType,
  skipUnnecessaryRequests,
  setIsWidgetRequestSent,
  onClose,
}) => {
  const {
    createSupportRequest,
    createSupportRequestIsLoading,
    requestSupportData,
    requestSupportIsFetching,
    themesData,
    themesIsFetching,
    refetchThemesData,
  } = useConfigureSettings(
    SettingsFetchTypeEnum.WIDGET_SUPPORT_REQUEST,
    skipUnnecessaryRequests
  );
  const [i18n] = useI18n();
  const [requestSetup, setRequestSetup] =
    useState<InstallationSupportRequestDto>({});
  const [collapsedModes, setCollapsedModes] =
    useState<WidgetInstallationModeDtoEnum[]>();
  const [allWidgetCollapsed, setAllWidgetCollapsed] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const enabledStatuses = [
    WidgetStatusDtoEnum.ENABLED,
    WidgetStatusDtoEnum.ENABLED_VIA_CODE,
    WidgetStatusDtoEnum.ENABLED_VIA_CSS,
  ];

  const prioriyOptions = useMemo(
    () =>
      Object.values(InstallationSupportRequestPriorityDtoEnum).map((value) => ({
        label: i18n.translate(value),
        value: value,
        helpText: i18n.translate(`${value}_HELP`),
      })),
    [i18n]
  );
  const isLoading = !(
    requestSupportData &&
    !requestSupportIsFetching &&
    !isEmpty(requestSetup) &&
    themesData
  );

  const themesOptions = useMemo(
    () =>
      themesData?.map((theme) => ({
        value: theme.id?.toString(),
        label: theme.name || '',
      })) as ComboboxOption[],
    [themesData]
  );
  const availableWidgetTypes = useMemo(
    () =>
      requestSupportData?.widgets
        ?.filter((widget) => widget.status !== WidgetStatusDtoEnum.LOCKED)
        .map((widget) => widget.type) || [],
    [requestSupportData?.widgets]
  );

  const allWidgetsChecked = useMemo(
    () =>
      requestSetup.selectedWidgets?.length === availableWidgetTypes.length
        ? true
        : requestSetup.selectedWidgets?.length
        ? 'indeterminate'
        : false,
    [requestSetup.selectedWidgets, availableWidgetTypes]
  );

  const updateRequestSetup = useCallback(
    (
      field: keyof InstallationSupportRequestDto,
      data: InstallationSupportRequestDto[keyof InstallationSupportRequestDto]
    ) => setRequestSetup({ ...requestSetup, [field]: data }),
    [requestSetup]
  );

  const toggleModesCollapse = useCallback(
    (mode: WidgetInstallationModeDtoEnum) => {
      setCollapsedModes(
        collapsedModes?.includes(mode)
          ? collapsedModes.filter((item) => item !== mode)
          : [...(collapsedModes || []), mode]
      );
    },
    [collapsedModes]
  );

  const hasSelectedWigdet = useCallback(
    (mode: WidgetInstallationModeDtoEnum) =>
      !!requestSupportData?.widgets
        ?.filter((widget) => widget.installationMode === mode)
        .some((widget) =>
          requestSetup.selectedWidgets?.includes(
            widget.type as WidgetTypeDtoEnum
          )
        ),
    [requestSupportData, requestSetup]
  );

  const getSelectionCount = useCallback(
    (mode: WidgetInstallationModeDtoEnum) =>
      requestSupportData?.widgets?.filter(
        (widget) =>
          widget.installationMode === mode &&
          requestSetup.selectedWidgets?.includes(
            widget.type as WidgetTypeDtoEnum
          )
      ).length,
    [requestSupportData, requestSetup]
  );

  const getWidgetOptions = useCallback(
    (mode: WidgetInstallationModeDtoEnum) => {
      return (
        requestSupportData?.widgets
          ?.filter((widget) => widget.installationMode === mode)
          .map((widget) => {
            const isEnabled = enabledStatuses.includes(
              widget.status as WidgetStatusDtoEnum
            );
            return {
              value: widget.type as WidgetTypeDtoEnum,
              label: (
                <InlineStack gap='200'>
                  {i18n.translate(`${widget.type}`)}
                  <Badge tone={isEnabled ? 'success' : undefined}>
                    {i18n.translate(isEnabled ? 'On' : 'Off')}
                  </Badge>
                  {widget.planSupportingType && (
                    <Badge tone='attention'>
                      {widget.planSupportingType.name}
                    </Badge>
                  )}
                </InlineStack>
              ),
              disabled: widget.status === WidgetStatusDtoEnum.LOCKED,
            };
          }) || []
      );
    },
    [requestSupportData?.widgets]
  );

  const sendSupportRequest = useCallback(() => {
    createSupportRequest(requestSetup, () =>
      setIsWidgetRequestSent?.(true)
    ).then(() => onClose());
  }, [requestSetup]);

  const handleAllWidgetMode = useCallback(
    (mode: WidgetInstallationModeDtoEnum) => {
      const hasSelected = !!getSelectionCount(mode);
      const modeWidgets =
        requestSupportData?.widgets
          ?.filter(
            (widget) =>
              widget.installationMode === mode &&
              widget.status !== WidgetStatusDtoEnum.LOCKED
          )
          .map((widget) => widget.type) || [];

      const mergedSelection = Array.from(
        new Set([...(requestSetup.selectedWidgets || []), ...modeWidgets])
      ) as WidgetTypeDto[];

      const clearedSelection = requestSetup.selectedWidgets?.filter(
        (widget) => !modeWidgets.includes(widget)
      );

      updateRequestSetup(
        'selectedWidgets',
        hasSelected ? clearedSelection : mergedSelection
      );
    },
    [requestSupportData?.widgets, requestSetup.selectedWidgets]
  );

  const handleAllWidgtes = useCallback(() => {
    const hasSelected = !!requestSetup.selectedWidgets?.length;
    const mergedSelection = Array.from(
      new Set([
        ...(requestSetup.selectedWidgets || []),
        ...availableWidgetTypes,
      ])
    ) as WidgetTypeDto[];

    const clearedSelection = requestSetup.selectedWidgets?.filter(
      (widget) => !availableWidgetTypes.includes(widget)
    );
    updateRequestSetup(
      'selectedWidgets',
      hasSelected ? clearedSelection : mergedSelection
    );
  }, [requestSetup.selectedWidgets, availableWidgetTypes]);

  const widgetModeLists = useMemo(
    () =>
      Object.values(WidgetInstallationModeDtoEnum).map((mode) => {
        const options = getWidgetOptions(mode);
        const selectionCount = getSelectionCount(mode);
        const possibleModeOptions = options.filter(
          (option) => !option.disabled
        );
        const checked =
          possibleModeOptions.length === selectionCount
            ? true
            : selectionCount
            ? 'indeterminate'
            : false;
        return (
          <Box
            key={mode}
            borderBlockStartWidth='025'
            borderColor='border'
            paddingBlock='300'
          >
            <BlockStack gap='300'>
              <InlineStack align='space-between' blockAlign='center'>
                <InlineStack>
                  <Checkbox
                    checked={checked}
                    onChange={() => handleAllWidgetMode(mode)}
                    label={i18n.translate(`${mode}_TITLE`)}
                  />
                </InlineStack>
                <InlineStack gap='300'>
                  <Text as='p' tone='subdued'>
                    {selectionCount}/{options.length}{' '}
                    {i18n.translate('Selected')}
                  </Text>
                  <Button
                    onClick={() => toggleModesCollapse(mode)}
                    variant='monochromePlain'
                    icon={
                      collapsedModes?.includes(mode)
                        ? ChevronUpIcon
                        : ChevronDownIcon
                    }
                  />
                </InlineStack>
              </InlineStack>
              <Collapsible id={mode} open={!!collapsedModes?.includes(mode)}>
                <ChoiceList
                  title=''
                  onChange={(value) =>
                    updateRequestSetup(
                      'selectedWidgets',
                      value as WidgetTypeDto[]
                    )
                  }
                  choices={options}
                  selected={
                    (requestSetup.selectedWidgets || [widgetType]) as string[]
                  }
                  allowMultiple
                />
              </Collapsible>
            </BlockStack>
          </Box>
        );
      }),
    [getWidgetOptions, handleAllWidgetMode, requestSetup, collapsedModes]
  );

  useEffect(() => {
    if (!isEmpty(requestSetup) && !collapsedModes) {
      setCollapsedModes(
        Object.values(WidgetInstallationModeDtoEnum).filter((mode) =>
          hasSelectedWigdet(mode)
        )
      );
    }
  }, [requestSetup]);

  useEffect(() => {
    if (requestSupportData && isEmpty(requestSetup)) {
      setRequestSetup({
        ...requestSupportData.supportRequest,
        ...(widgetType && { selectedWidgets: [widgetType] }),
        priority: InstallationSupportRequestPriorityDtoEnum.NORMAL,
      });
    }
  }, [requestSupportData]);

  return (
    <div className='RequestWidgetSupport'>
      <div className='ToggleWidgetModalContent'>
        {!isLoading ? (
          <BlockStack gap='400'>
            <div className='ThemeSelectorInputBox'>
              <SelectOptions
                options={themesOptions}
                onOptionSelect={(value) => updateRequestSetup('themeId', value)}
                selectedOption={requestSetup.themeId?.toString() || ''}
                label=''
                isLoading={themesIsFetching}
                prefix={i18n.translate('Theme')}
                placeholder={i18n.translate('Select')}
              />
              <Button disabled={themesIsFetching} onClick={refetchThemesData}>
                {i18n.translate('Refresh')}
              </Button>
            </div>
            <BlockStack gap='200'>
              <Text as='p'>{i18n.translate('SelectWidgets')}</Text>{' '}
              <Box
                paddingInline='300'
                borderWidth='025'
                borderColor='border'
                borderRadius='300'
              >
                <Box paddingBlock='300'>
                  <InlineStack align='space-between' blockAlign='center'>
                    <InlineStack gap='200'>
                      <Checkbox
                        label={i18n.translate('AllWidgets')}
                        checked={allWidgetsChecked}
                        onChange={handleAllWidgtes}
                      />
                      <Badge>{i18n.translate('Recommended')}</Badge>
                    </InlineStack>
                    <InlineStack gap='300'>
                      <Text as='p' tone='subdued'>
                        {requestSetup?.selectedWidgets?.length}/
                        {requestSupportData?.widgets?.length}{' '}
                        {i18n.translate('Selected')}
                      </Text>
                      <Button
                        onClick={() => setAllWidgetCollapsed((prev) => !prev)}
                        variant='monochromePlain'
                        icon={
                          allWidgetCollapsed ? ChevronDownIcon : ChevronUpIcon
                        }
                      />
                    </InlineStack>
                  </InlineStack>
                </Box>
                <Collapsible id='WidgetModeLists' open={!allWidgetCollapsed}>
                  {widgetModeLists}
                </Collapsible>
              </Box>
            </BlockStack>
            {requestSupportData?.demand !==
            InstallationSupportRequestDemandDtoEnum.REGULAR ? (
              <Banner tone='warning'>
                {i18n.translate(`${requestSupportData?.demand}_BANNER`)}
              </Banner>
            ) : (
              <ChoiceList
                title={i18n.translate('Priority')}
                choices={prioriyOptions}
                selected={[requestSetup.priority] as string[]}
                onChange={(value) =>
                  updateRequestSetup(
                    'priority',
                    value[0] as InstallationSupportRequestPriorityDtoEnum
                  )
                }
              />
            )}
            <TextField
              autoComplete=''
              label={i18n.translate('OptionalNote')}
              placeholder={i18n.translate('TypeHere')}
              value={requestSetup.note || ''}
              onChange={(value) => updateRequestSetup('note', value)}
            />
            <Banner tone='info'>{i18n.translate('NoteBannerText')}</Banner>
            <Checkbox
              checked={isConfirmed}
              onChange={setIsConfirmed}
              label={i18n.translate('Confirmation')}
            />
          </BlockStack>
        ) : (
          <Loader fullWidth />
        )}
      </div>
      <div className='ModalFooterActions'>
        <ButtonGroup>
          <Button onClick={onClose}>{i18n.translate('Cancel')}</Button>
          <Button
            onClick={sendSupportRequest}
            variant='primary'
            disabled={!isConfirmed || !requestSetup.themeId}
            loading={createSupportRequestIsLoading}
          >
            {i18n.translate('SendRequest')}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default RequestWidgetSupport;
