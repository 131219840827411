import React, { useCallback, useMemo } from 'react';
import {
  Card,
  BlockStack,
  Text,
  ChoiceList,
  Link,
  Banner,
} from '@shopify/polaris';
import CodeMirror from '@uiw/react-codemirror';
import { javascript as JS } from '@codemirror/lang-javascript';
import { useI18n } from '@shopify/react-i18n';
import { CartBehaviorSettingsDto } from 'core/api/adminSettings/adminSettingsApi';
import { CartBehaviourRefreshBehaviourTypeDtoEnum } from 'core/api/adminSettings/adminSettingsEnums';

enum CartPageTypeEnum {
  CART_PAGE = 'cart',
  DRAWER_CART_PAGE = 'drawerCart',
}
type RefreshBehavior = {
  cartBehaviorState?: CartBehaviorSettingsDto;
  handleUpdateCartBehavior: (
    field: keyof CartBehaviorSettingsDto,
    data: CartBehaviorSettingsDto[keyof CartBehaviorSettingsDto]
  ) => void;
};

export const RefreshBehavior: React.FC<RefreshBehavior> = ({
  cartBehaviorState,
  handleUpdateCartBehavior,
}) => {
  const [i18n] = useI18n();

  const bannerText = useMemo(() => {
    const { cart, drawerCart } = cartBehaviorState || {};
    if (cart?.ninjaCartEnabled && drawerCart?.ninjaCartEnabled) {
      return i18n.translate('BothEnabledBanner');
    }
    if (!cart?.ninjaCartEnabled && drawerCart?.ninjaCartEnabled) {
      return i18n.translate('DrawerCartEnabledBanner');
    }
    if (cart?.ninjaCartEnabled && !drawerCart?.ninjaCartEnabled) {
      return i18n.translate('CartEnabledBanner');
    }
  }, [cartBehaviorState, i18n]);

  const getModeOptions = useCallback(
    (page: CartPageTypeEnum) =>
      Object.values(CartBehaviourRefreshBehaviourTypeDtoEnum).map((value) => ({
        label: i18n.translate(`${value}_${page}`),
        value: value,
      })),
    [i18n]
  );

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('RefreshBehaviorTitle')}
          </Text>
          <BlockStack>
            <Text as='p' tone='subdued'>
              {i18n.translate('RefreshBehaviorSubtitle1', {
                followingArticle: (
                  <Link>{i18n.translate('FollowingArticle')}</Link>
                ),
              })}
            </Text>
          </BlockStack>
        </BlockStack>
        {bannerText && <Banner>{bannerText}</Banner>}
        {Object.values(CartPageTypeEnum).map((page) => (
          <BlockStack key={page} gap='300'>
            <ChoiceList
              selected={[cartBehaviorState?.[page]?.mode as string]}
              title={i18n.translate(page)}
              choices={getModeOptions(page)}
              disabled={!!cartBehaviorState?.[page]?.ninjaCartEnabled}
              onChange={(value) =>
                handleUpdateCartBehavior(page, {
                  ...cartBehaviorState?.[page],
                  mode: value[0] as CartBehaviourRefreshBehaviourTypeDtoEnum,
                })
              }
            />
            {cartBehaviorState?.[page]?.mode ===
              CartBehaviourRefreshBehaviourTypeDtoEnum.REFRESH_JS && (
              <BlockStack gap='100'>
                <Text as='p'>{i18n.translate('JSFunction')}</Text>
                <div className='DN-CodeMirror'>
                  <CodeMirror
                    value={cartBehaviorState?.[page]?.code || ''}
                    height='120px'
                    extensions={[JS()]}
                    onChange={(value) =>
                      handleUpdateCartBehavior(page, {
                        ...cartBehaviorState?.[page],
                        code: value,
                      })
                    }
                    editable={true}
                  />
                </div>
              </BlockStack>
            )}
          </BlockStack>
        ))}
      </BlockStack>
    </Card>
  );
};
