import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import {
  CartDownIcon,
  DiscountIcon,
  CartDiscountIcon,
  DeliveryIcon,
  GiftCardIcon,
  DiscountCodeIcon,
} from '@shopify/polaris-icons';

export type OfferTemplateDto = {
  title: string;
  subtitle: string;
  example?: string;
  icon: any;
  types: OfferTypeDtoEnum[];
};
type OfferTemplatesFromTypeDto = {
  sectionTitle: string | null;
  offers: OfferTemplateDto[];
};
type OfferTemplatesListFromTypeDataDto = {
  CrossSell: OfferTemplatesFromTypeDto[];
  SpecialOffer: OfferTemplatesFromTypeDto[];
  MarkdownPromotion: OfferTemplatesFromTypeDto[];
  ShopifyDiscountCode: OfferTemplatesFromTypeDto[];
};

export const OfferTemplatesFromTypeData: OfferTemplatesListFromTypeDataDto = {
  SpecialOffer: [
    {
      sectionTitle: 'Sales',
      offers: [
        {
          title: 'PercentageDiscountTitle',
          subtitle: 'PercentageDiscountSubtitle',
          icon: DiscountIcon,
          types: [OfferTypeDtoEnum.SALES_PERCENTAGE],
        },
        {
          title: 'FixedAmountDiscountTitle',
          subtitle: 'FixedAmountDiscountSubtitle',
          icon: DiscountIcon,
          types: [OfferTypeDtoEnum.SALES_FIXED_AMOUNT],
        },
        {
          title: 'FixedPricePerUnitTitle',
          subtitle: 'FixedPricePerUnitSubtitle',
          icon: DiscountIcon,
          types: [OfferTypeDtoEnum.SALES_FIXED_PRICE],
        },
      ],
    },
    {
      sectionTitle: 'FreeGift',
      offers: [
        {
          title: 'OfferAFreeGiftAutomaticallyTitle',
          subtitle: 'OfferAFreeGiftAutomaticallySubtitle',
          icon: GiftCardIcon,
          types: [OfferTypeDtoEnum.GIFT_AUTOMATIC],
        },
        {
          title: 'PickAGiftTitle',
          subtitle: 'PickAGiftSubtitle',
          icon: GiftCardIcon,
          types: [OfferTypeDtoEnum.GIFT_MANUAL],
        },
      ],
    },
    {
      sectionTitle: 'CrossSellDiscount',
      offers: [
        {
          title: 'CrossSellTitle',
          subtitle: 'CrossSellSubtitle',
          icon: CartDiscountIcon,
          example: 'CrossSellExample',
          types: [OfferTypeDtoEnum.CROSS_SELL],
        },
      ],
    },
    {
      sectionTitle: 'BundleDiscount',
      offers: [
        {
          title: 'BundleOrderDiscountTitle',
          subtitle: 'BundleOrderDiscountSubtitle',
          example: 'BundleOrderDiscountExample',
          icon: CartDiscountIcon,
          types: [OfferTypeDtoEnum.BUNDLE_ORDER_DISCOUNT],
        },
      ],
    },
    {
      sectionTitle: 'BuyXGetY',
      offers: [
        {
          title: 'BOGO1Title',
          subtitle: 'BOGO1Subtitle',
          example: 'BOGO1Example',
          icon: CartDiscountIcon,
          types: [OfferTypeDtoEnum.BOGO_REGULAR],
        },
        {
          title: 'BOGO2Title',
          subtitle: 'BOGO2Subtitle',
          example: 'BOGO2Example',
          icon: CartDiscountIcon,
          types: [OfferTypeDtoEnum.BOGO_STRICT_MATCH],
        },
      ],
    },
    {
      sectionTitle: 'TieredDiscounts',
      offers: [
        {
          title: 'MultiTierBogoTitle',
          subtitle: 'MultiTierBogoSubtitle',
          example: 'MultiTierBogoExample',
          icon: DiscountIcon,
          types: [OfferTypeDtoEnum.TIERED_BOGO],
        },
        {
          title: 'SpendAmountTitle',
          subtitle: 'SpendAmountSubtitle',
          example: 'SpendAmountExample',
          icon: DiscountIcon,
          types: [OfferTypeDtoEnum.TIERED_SPEND_X_GET_Y],
        },
        {
          title: 'VolumeTitle',
          subtitle: 'VolumeSubtitle',
          example: 'VolumeExample',
          icon: DiscountIcon,
          types: [OfferTypeDtoEnum.VOLUME_DISCOUNT],
        },
      ],
    },
    {
      sectionTitle: 'FreeShipping',
      offers: [
        {
          title: 'ShippingPercentageTitle',
          subtitle: 'ShippingPercentageSubtitle',
          icon: DeliveryIcon,
          types: [OfferTypeDtoEnum.SHIPPING_PERCENTAGE],
        },
        {
          title: 'ShippingFixedAmountTitle',
          subtitle: 'ShippingFixedAmountSubtitle',
          icon: DeliveryIcon,
          types: [OfferTypeDtoEnum.SHIPPING_FIXED_AMOUNT],
        },
        {
          title: 'ShippingTitle',
          subtitle: 'ShippingSubtitle',
          icon: DeliveryIcon,
          types: [OfferTypeDtoEnum.FREE_SHIPPING],
        },
      ],
    },
  ],

  MarkdownPromotion: [
    {
      sectionTitle: null,
      offers: [
        {
          title: 'PercentageDiscountTitle',
          subtitle: 'PercentageDiscountSubtitle',
          icon: DiscountIcon,
          types: [OfferTypeDtoEnum.SALES_PERCENTAGE],
        },
        {
          title: 'FixedAmountDiscountTitle',
          subtitle: 'FixedAmountDiscountSubtitle',
          icon: DiscountIcon,
          types: [OfferTypeDtoEnum.SALES_FIXED_AMOUNT],
        },
        {
          title: 'FixedPricePerUnitTitle',
          subtitle: 'FixedPricePerUnitSubtitle',
          icon: DiscountIcon,
          types: [OfferTypeDtoEnum.SALES_FIXED_PRICE],
        },
      ],
    },
  ],

  ShopifyDiscountCode: [
    {
      sectionTitle: null,
      offers: [
        {
          title: 'ApplyAShopifyDiscountTitle',
          subtitle: 'ApplyAShopifyDiscountSubtitle',
          icon: DiscountCodeIcon,
          types: [OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_REGULAR],
        },
        {
          title: 'ApplyAUniqueShopifyDiscountTitle',
          subtitle: 'ApplyAUniqueShopifyDiscountSubtitle',
          icon: DiscountCodeIcon,
          types: [OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_GROUP],
        },
        {
          title: 'IntegrateWithOtherAppsTitle',
          subtitle: 'IntegrateWithOtherAppsSubtitle',
          icon: DiscountCodeIcon,
          types: [OfferTypeDtoEnum.SHOPIFY_DISCOUNT_CODE_APP],
        },
      ],
    },
  ],
  CrossSell: [
    {
      sectionTitle: 'Cross-sell/up-sell',
      offers: [
        {
          title: 'FrequentlyBoughtTogetherTitle',
          subtitle: 'FrequentlyBoughtTogetherSubtitle',
          icon: CartDownIcon,
          types: [OfferTypeDtoEnum.FREQUENTLY_BOUGHT_TOGETHER],
        },
        {
          title: 'OrderBumpTitle',
          subtitle: 'OrderBumpSubtitle',
          icon: CartDownIcon,
          types: [OfferTypeDtoEnum.ORDER_BUMP],
        },
      ],
    },
  ],
};
