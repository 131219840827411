import React, { useCallback, useMemo, useState } from 'react';
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Icon,
  InlineStack,
  Link,
  Text,
} from '@shopify/polaris';
import { ShopifyPlusIcon } from 'features/promotions/assets/icons';
import { CollectionIcon, OrderIcon } from '@shopify/polaris-icons';
import { useI18n } from '@shopify/react-i18n';
import { OfferTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { useAppSelector, useIsDebugOrLocal } from 'core/hooks';
import {
  SelectOfferModalTypeEnum,
  SelectOfferPage,
} from 'pages/SelectOfferPage';
import { OfferCombinationTypeDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferTemplatesList } from 'features/promotions/components/CreatePromotionModal/consts/OfferTemplatesList';

type OfferTemplate = {
  type: OfferTypeDtoEnum | undefined;
  discountType?: OfferCombinationTypeDto;
  toggleShowHowModal: () => void;
};

export const OfferTemplate: React.FC<OfferTemplate> = ({
  type,
  discountType,
  toggleShowHowModal,
}) => {
  const [i18n] = useI18n();
  const isDebugOrLocal = useIsDebugOrLocal();
  const { currentOfferData } = useAppSelector((store) => store.offersWizard);
  const [isChangeOfferTemplateModalOpen, setIsChangeOfferTemplateModalOpen] =
    useState<boolean>(false);

  const discountIcon = useMemo(
    () =>
      type === OfferTypeDtoEnum.BUNDLE_ORDER_DISCOUNT
        ? OrderIcon
        : CollectionIcon,
    [type]
  );
  const offerTemplateInfo = useMemo(() => {
    return OfferTemplatesList.find((offer) => offer.type === type);
  }, [OfferTemplatesList]);

  const toggleChangeOfferTemplateModalOpen = useCallback(() => {
    setIsChangeOfferTemplateModalOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Card roundedAbove='sm' padding='400'>
        <BlockStack gap='400'>
          <InlineStack align='space-between'>
            <Text as='h2' variant='headingSm'>
              {i18n.translate('OfferTemplate')}
            </Text>
            <Button
              variant='plain'
              onClick={async () => {
                !isDebugOrLocal && (await shopify.saveBar.leaveConfirmation());
                toggleChangeOfferTemplateModalOpen();
              }}
            >
              {i18n.translate('ChangeTemplate')}
            </Button>
          </InlineStack>
          <Box
            borderColor='border'
            borderWidth='025'
            borderRadius='200'
            padding='400'
          >
            <InlineStack align='space-between' wrap={false} gap='400'>
              <InlineStack gap='200' wrap={false}>
                <Box>
                  <Icon source={offerTemplateInfo?.icon} />
                </Box>
                <BlockStack gap='100'>
                  <InlineStack gap='100' align='start'>
                    <InlineStack gap='100'>
                      <Text as='h2' variant='headingSm'>
                        {i18n.translate(`${offerTemplateInfo?.title}`)}
                      </Text>
                    </InlineStack>
                    {discountType && (
                      <Badge tone='new' icon={discountIcon}>
                        {i18n.translate(discountType)}
                      </Badge>
                    )}
                    {currentOfferData.isNew && (
                      <Badge tone='success'>{i18n.translate('New')}</Badge>
                    )}
                  </InlineStack>
                  <Box>
                    <Text as='p' tone='subdued'>
                      {i18n.translate(`${offerTemplateInfo?.subtitle}`)}{' '}
                      {!offerTemplateInfo?.example && (
                        <span onClick={toggleShowHowModal}>
                          <Link removeUnderline url=''>
                            {i18n.translate('WatchTutorial')}
                          </Link>
                        </span>
                      )}
                    </Text>
                    {offerTemplateInfo?.example && (
                      <Text as='p' tone='subdued'>
                        {i18n.translate(offerTemplateInfo?.example)}{' '}
                        <span onClick={toggleShowHowModal}>
                          <Link removeUnderline url=''>
                            {i18n.translate('WatchTutorial')}
                          </Link>
                        </span>
                      </Text>
                    )}
                  </Box>
                </BlockStack>
              </InlineStack>
              {currentOfferData.isShopifyPlus && (
                <BlockStack align='end'>
                  <ShopifyPlusIcon />
                </BlockStack>
              )}
            </InlineStack>
          </Box>
        </BlockStack>
      </Card>
      {isChangeOfferTemplateModalOpen && (
        <SelectOfferPage
          isOpen={isChangeOfferTemplateModalOpen}
          onClose={toggleChangeOfferTemplateModalOpen}
          modalType={SelectOfferModalTypeEnum.CHANGE_TEMPLATE}
        />
      )}
    </>
  );
};
