import React, { useEffect, useMemo, useState } from 'react';
import './CrossSellDiscount.scss';
import {
  Card,
  BlockStack,
  Text,
  InlineStack,
  ButtonGroup,
  Button,
  Banner,
} from '@shopify/polaris';
import { useI18n } from '@shopify/react-i18n';
import { OfferCrossSellSpecificationDto } from 'core/api/adminPromotions/adminPromotionsApi';
import { OfferCrossSellRequirementRuleTypeDtoEnum } from 'core/api/adminPromotions/adminPromotionsEnums';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCurrencySymbol, parseCurrencyString } from 'core/utils';
import { isEmpty, isEqual } from 'lodash';
import { usePrevious } from 'core/hooks/usePrevious';
import classNames from 'classnames';

export type CrossSellOfferSectionProps = {
  specification?: OfferCrossSellSpecificationDto;
  onFormValidityChange(formIsValid: boolean): void;
  onSpecificationUpdate: (
    specification: OfferCrossSellSpecificationDto
  ) => void;
};

type FormFields = {
  customOffValue: number;
};

export const CrossSellDiscount: React.FC<CrossSellOfferSectionProps> = (
  props
) => {
  const { specification, onFormValidityChange, onSpecificationUpdate } = props;

  const [i18n] = useI18n();

  const [currentSpecification, setCurrentSpecification] = useState<
    OfferCrossSellSpecificationDto | undefined
  >(specification);

  const prevSpecification = usePrevious(currentSpecification);

  const isFixedAmountType = useMemo(
    () =>
      currentSpecification?.rule?.type ===
      OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT,
    [currentSpecification?.rule?.type]
  );

  const conditionalInputName: string = useMemo(
    () =>
      currentSpecification?.rule?.type ===
      OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT
        ? 'fixedAmount'
        : 'percentage',
    [currentSpecification?.rule?.type]
  );

  const form = yup.object({
    customOffValue: yup
      .number()
      .transform((value, originalValue) => parseCurrencyString(originalValue))
      .when([], {
        is: () =>
          currentSpecification?.rule?.type ===
          OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT,
        then: yup
          .number()
          .typeError(i18n.translate('FixedAmountRequired'))
          .min(0.01, i18n.translate('FixedAmountMin'))
          .required(),
        otherwise: yup
          .number()
          .typeError(i18n.translate('PercentageRequired'))
          .min(1, i18n.translate('PercentageMin'))
          .max(100, i18n.translate('PercentageMax'))
          .integer()
          .required(),
      }),
  });

  const initialCustomOffValue: number = useMemo(
    () =>
      currentSpecification?.rule?.type ===
      OfferCrossSellRequirementRuleTypeDtoEnum.FIXED_AMOUNT
        ? (currentSpecification.rule.fixedAmount as number)
        : (currentSpecification?.rule?.percentage as number),
    [currentSpecification?.rule?.type]
  );

  const { control, formState, watch, trigger, resetField } =
    useForm<FormFields>({
      defaultValues: {
        customOffValue: initialCustomOffValue || undefined,
      },
      mode: 'onChange',
      resolver: yupResolver(form),
    });

  const validationMessage = useMemo(
    () => Object.values(formState.errors).map((error) => error?.message)[0],
    [formState]
  );

  useEffect(() => {
    if (
      !isEmpty(currentSpecification) &&
      !isEmpty(prevSpecification) &&
      !isEqual(prevSpecification, currentSpecification)
    ) {
      onSpecificationUpdate(currentSpecification);
    }
  }, [currentSpecification, prevSpecification]);

  useEffect(() => {
    resetField('customOffValue', {
      defaultValue:
        (currentSpecification?.rule?.[
          conditionalInputName as keyof typeof currentSpecification.rule
        ] as number) || 0,
    });
    trigger('customOffValue');
  }, [conditionalInputName]);

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        setCurrentSpecification((prevState) => ({
          ...prevState,
          rule: {
            ...prevState?.rule,
            [conditionalInputName]: parseCurrencyString(value.customOffValue),
          },
        }));
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, conditionalInputName]);

  useEffect(() => {
    onFormValidityChange(formState.isValid);
  }, [formState.isValid]);

  return (
    <Card roundedAbove='sm' padding='400'>
      <BlockStack gap='400'>
        <BlockStack gap='100'>
          <Text as='h2' variant='headingSm'>
            {i18n.translate('Title')}
          </Text>
          <Text as='span' tone='subdued'>
            {i18n.translate('Subtitle')}
          </Text>
        </BlockStack>
        <BlockStack gap='300'>
          <InlineStack blockAlign='center' gap='200'>
            <Text as='p'>{i18n.translate('Apply')}</Text>
            <ButtonGroup variant='segmented'>
              {Object.keys(OfferCrossSellRequirementRuleTypeDtoEnum).map(
                (key: string) => (
                  <Button
                    key={key}
                    pressed={currentSpecification?.rule?.type === key}
                    onClick={() => {
                      if (currentSpecification?.rule?.type !== key) {
                        setCurrentSpecification((prevState) => ({
                          ...prevState,
                          rule: {
                            ...prevState?.rule,
                            type: key as OfferCrossSellRequirementRuleTypeDtoEnum,
                          },
                        }));
                      }
                    }}
                  >
                    {i18n.translate(key)}
                  </Button>
                )
              )}
            </ButtonGroup>
            <Text as='p'>{i18n.translate('DiscountOf')}</Text>
            <div
              className={classNames(
                'Polaris-TextField Polaris-TextField--hasValue',
                {
                  'Polaris-TextField--error': validationMessage,
                }
              )}
              style={{
                width: '100px',
              }}
            >
              {isFixedAmountType && (
                <div className={'Polaris-TextField__Prefix'}>
                  <span className='Polaris-Text--root Polaris-Text--bodyMd'>
                    {getCurrencySymbol()}
                  </span>
                </div>
              )}
              <Controller<FormFields>
                name='customOffValue'
                control={control}
                render={({ field }) => (
                  <NumberFormat
                    {...field}
                    className='Polaris-TextField__Input'
                    value={field.value as number}
                    decimalScale={isFixedAmountType ? 2 : 0}
                    decimalSeparator={
                      isFixedAmountType
                        ? i18n.numberSymbols().decimalSymbol
                        : undefined
                    }
                    thousandSeparator={
                      isFixedAmountType
                        ? i18n.numberSymbols().thousandSymbol
                        : undefined
                    }
                    allowNegative={false}
                    autoComplete='off'
                    onValueChange={({ floatValue }) =>
                      field.onChange(floatValue ?? undefined)
                    }
                  />
                )}
              />
              {!isFixedAmountType && (
                <div className='Polaris-TextField__Suffix'>
                  <span className='Polaris-Text--root Polaris-Text--bodyMd'>
                    %
                  </span>
                </div>
              )}
              <div className='Polaris-TextField__Backdrop'></div>
            </div>
          </InlineStack>
        </BlockStack>
        {validationMessage && (
          <Banner tone='critical'>{validationMessage}</Banner>
        )}
      </BlockStack>
    </Card>
  );
};
